import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import styles from './styles';
import i18n, {t} from '../../services/i18n';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {MEDIUM_ICON_SIZE} from "../../helpers/functions";
import {AppText} from "../Stateless/AppText";
import {globalStyles} from "../../styles/Global";


class MoreSectionListItem extends React.Component {
    render() {
        const {txt, onPress} = this.props;
        return (
            <TouchableOpacity onPress={onPress}>
                <View>
                    <View style={[styles.item, globalStyles.appPaddingHorizontal]}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Icons8Generator name={this.props.name} width={MEDIUM_ICON_SIZE} height={MEDIUM_ICON_SIZE}
                                             s/>
                            <AppText style={styles.text}>{txt}</AppText>
                        </View>
                        {
                            txt !== t('screens:More:logout') ?
                                (
                                    <Icons8Generator name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>
                                ) : null
                        }
                    </View>
                    <View style={styles.sepline}/>
                </View>
            </TouchableOpacity>
        );
    }
}

export default MoreSectionListItem;
