import React, {useCallback, useRef, useState} from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import {AppText} from "../AppText";
import {t} from "../../../services/i18n";
import {globalStyles} from "../../../styles/Global";
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";
import {Icons8Generator} from "../Icons8Generator";
import {IconSize} from "../../../constants/iconSize";
import date from "../../../services/i18n/date";
import UserAvatar from 'react-native-user-avatar';
import * as Linking from "expo-linking";
import {getUserFromFriendConnection} from "../../../helpers/functions";
import {store} from "../../../config/store";
import DrawerHandler from "../../DrawerHandler";
import FriendsListModal from "../../../modals/modalFlows/FriendsListModal";
import uuid from 'uuid';
import BookingError from "../../ScheduleFlow/Booking/error";
import {getCapacityTextByRestrictions} from "../../../helpers/DisablePages";


export const Slots = props => {
    const {data, textOverride, iconSize, rowOverride} = props;
    if(data.availability_id)
        return null;
    const text = getCapacityTextByRestrictions(data)

    return (
        <View style={[styles.row, rowOverride ? rowOverride : {}]}>
            <Icons8Generator size={iconSize ? iconSize : IconSize.XS} name={'full-tank'}/>
            <AppText style={[globalStyles.paddingStart, styles.text, textOverride ? textOverride : {}]}>{text}</AppText>
        </View>
    )

};

export const Date = props => {
    const {data, textOverride, iconSize, rowOverride} = props;
    return (
        <View style={[styles.row, rowOverride ? rowOverride : {}]}>
            <Icons8Generator size={iconSize ? iconSize : IconSize.XS} name={'today'}/>
            <AppText
                style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text, textOverride ? textOverride : {}]}>
                {date.format(data.date, 'ddd, ll', true)}
            </AppText>
        </View>
    )
}

export const Time = props => {
    const {data, textOverride, iconSize, rowOverride} = props;
    return (
        <View style={[styles.row, rowOverride ? rowOverride : {}]}>
            <Icons8Generator size={iconSize ? iconSize : IconSize.XS} name={'clock-outline'}/>
            <AppText
                style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text, textOverride ? textOverride : {}]}>
                {date.timeFormat(data.time, 'LT', false, false)} - {date.timeFormat(data.end_time, 'LT', false, false)}
            </AppText>
        </View>
    )
}

export const OnlineClass = props => {
    const {data, textOverride, iconSize, rowOverride} = props;
    if (data.live_link) {
        return (
            <View style={[styles.row, rowOverride ? rowOverride : {}]}>
                <Icons8Generator size={iconSize ? iconSize : IconSize.XS} name={'tv'}/>
                <View style={[globalStyles.flexDirectionRowSpaceBetween, globalStyles.flex]}>
                    <AppText
                        style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text, textOverride ? textOverride : {}]}>
                        {t('screens:Schedule:card:online-class', {})}
                    </AppText>
                    {data.user_booked !== null ? (
                        <TouchableOpacity onPress={() => Linking.openURL(data.live_link)}>
                            <AppText style={[globalStyles.heeboRegular, {color: Colors.blue}]}>
                                {t('screens:ScheduleSingle:watch', {})}
                            </AppText>
                        </TouchableOpacity>
                    ) : null}
                </View>
            </View>
        )
    } else {
        return null;
    }
}

export const Description = props => {
    const {data, textOverride, iconSize, rowOverride} = props;
    const box_categories = data.box_categories;
    if (!box_categories.bio)
        return null;
    const [textShown, setTextShown] = useState(false); //To show ur remaining Text
    const [lengthMore, setLengthMore] = useState(false); //to show the "Read more & Less Line"
    const toggleNumberOfLines = () => { //To toggle the show text or hide it
        setTextShown(!textShown);
    }
    const onTextLayout = useCallback(e => {
        setLengthMore(e.nativeEvent.lines.length >= 2); //to check the text is more than 4 lines or not
    }, []);

    return (
        <View style={[styles.row, rowOverride ? rowOverride : {}]}>
            <Icons8Generator size={iconSize ? iconSize : IconSize.XS} name={'info'}/>
            <View style={globalStyles.flexDirectionColumn}>
                <AppText
                    style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text, textOverride ? textOverride : {}]}
                    onTextLayout={onTextLayout}
                    numberOfLines={textShown ? undefined : 2}
                >{box_categories.bio}</AppText>
                {
                    lengthMore ? <AppText
                            onPress={toggleNumberOfLines}
                            style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text, textOverride ? textOverride : {}, {
                                color: Colors.blue,
                                marginTop: 5
                            }]}>{textShown ? t('screens:ScheduleSingle:readLess', {}) : t('screens:ScheduleSingle:readMore', {})}</AppText>
                        : null
                }
            </View>
        </View>
    )
}


export const UserAvatars = props => {
    const {data, displayAmount, size, marginBetweenAvatars,centerItems} = props;
    const disable = data.disable_pages_app ? data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'whoIsBooked') : null;
    if (disable || data.availability_id) {
        return null;
    }
    const userState = store.getState().user.data;
    const friendsIds = userState.friend_connection.map(item => {
        const user = getUserFromFriendConnection(item)
        return user.id;
    });

    let bookedUsers = data.booked_users.map(item => {
            item.is_friend = friendsIds.indexOf(item.id) > -1;
            return item;
        }
    )
    bookedUsers = bookedUsers.sort((a, b) => (a.is_friend === b.is_friend) ? 0 : a.is_friend ? -1 : 1);
    const usersToDisplay = bookedUsers && bookedUsers.slice(0, displayAmount);
    const moreAvatarsLength = bookedUsers && bookedUsers.length - usersToDisplay.length;
    let RBSheet = useRef(null);
    if (bookedUsers) {
        return (
            <TouchableOpacity style={[
                globalStyles.flexDirectionRow,
                centerItems ? globalStyles.centerItems : {},
                marginBetweenAvatars !== 0 ? globalStyles.flexWrap : {},
                {marginStart: scale(IconSize.M)}]}
                              onPress={() => {
                                  RBSheet.open();
                              }
                              }>
                <DrawerHandler key={uuid.v4()} noMargin={false}
                               title={t('common:drawer:booked', {})}
                               ref={el => RBSheet = el ? el.ref.current : null}>
                    <FriendsListModal
                        information={data}
                        closeDrawer={() => RBSheet ? RBSheet.close() : null}/>
                </DrawerHandler>
                <RenderUserAvatars usersToDisplay={usersToDisplay} marginBetweenAvatars={marginBetweenAvatars}
                                   size={size}/>
                {!!moreAvatarsLength && (
                    <View
                        style={[{marginHorizontal: scale(marginBetweenAvatars)}, marginBetweenAvatars === 0 ? {} : globalStyles.marginTopMedium]}>
                        <UserAvatar
                            size={scale(size)}
                            name={`+${moreAvatarsLength}`}
                            src={null}
                            rounded={true}
                        />
                    </View>
                )
                }
            </TouchableOpacity>
        );
    }
    return null;

}

export const ScheduleRowByType = props => {
    const {type, data} = props;
    switch (type) {
        case 'date':
            return <Date iconSize={IconSize.M} rowOverride={styles.scheduleSingleRow}
                         textOverride={styles.scheduleSingleText} data={data}/>;
        case 'time':
            return <Time iconSize={IconSize.M} rowOverride={styles.scheduleSingleRow}
                         textOverride={styles.scheduleSingleText} data={data}/>;
        case 'online':
            return <OnlineClass iconSize={IconSize.M} rowOverride={styles.scheduleSingleRow}
                                textOverride={styles.scheduleSingleText} data={data}/>;
        case 'description':
            return <Description iconSize={IconSize.M} rowOverride={styles.scheduleSingleRow}
                                textOverride={styles.scheduleSingleText} data={data}/>;
        case 'slots':
            return <Slots iconSize={IconSize.M} rowOverride={styles.scheduleSingleRow}
                          textOverride={styles.scheduleSingleText} data={data}/>
        case 'users':
            return <UserAvatars size={30} marginBetweenAvatars={5} displayAmount={5} iconSize={IconSize.M}
                                rowOverride={styles.scheduleSingleRow} textOverride={styles.scheduleSingleText}
                                data={data}/>
        default:
            return null;
    }

}


export const RenderUserAvatars = props => {
    const {usersToDisplay, size, marginBetweenAvatars} = props;
    if (usersToDisplay) {
        return (
            <React.Fragment>
                {usersToDisplay.map(user => {
                    return (
                        <View
                            style={[{marginHorizontal: scale(marginBetweenAvatars)}, marginBetweenAvatars === 0 ? {} : globalStyles.marginTopMedium]}
                            key={user.id}>
                            <UserAvatar
                                size={scale(size)}
                                name={`${user.full_name_shorten}`}
                                src={user.image ? user.image : null}
                                rounded={true}
                                key={uuid.v4()}
                            />
                        </View>
                    );
                })}
            </React.Fragment>
        );
    }
    return null;
}


const styles = StyleSheet.create({
    v2IndicatorWrap: {paddingHorizontal: scale(5), borderRadius: scale(5)},
    v2IndicatorText: {...globalStyles.heeboMedium, fontSize: scale(11), color: Colors.white},
    text: {...globalStyles.heeboLight, color: Colors.black, fontSize: scale(10)},
    moreContainer: {borderRadius: 100, backgroundColor: Colors.blue},
    row: {...globalStyles.flexDirectionRow, ...globalStyles.centerStartItems},
    scheduleSingleRow: {marginBottom: verticalScale(15)},
    scheduleSingleText: {fontSize: scale(13), ...globalStyles.paddingStartXLarge}
});