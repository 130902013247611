import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Icon } from 'native-base';
import styles from './styles';
import { t } from '../../services/i18n';
import {Colors} from "../../styles/Colors";
import {Icons8Generator} from "../Stateless/Icons8Generator";

class SimpleCheckbox extends React.PureComponent {
  render() {
    return (
      <TouchableOpacity style={styles.button} onPress={this.props.onPress}>
        <View style={[styles.box, this.props.checked
            ? styles.checked : {}]}>
          {this.props.checked ? (
              <Icons8Generator  name={'check'} fill={Colors.black}/>
          ) : null}
        </View>
        <Text style={styles.addToCalendarText}>{t('modals:BookClass:add-in-calendar')}</Text>
      </TouchableOpacity>
    );
  }
}

export default SimpleCheckbox;
