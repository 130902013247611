import {
    SCHEDULES,
    SCHEDULE_ACTION,
    USER_CLASSES,
    WEEKLY_SCHEDULES,
    SCHEDULE_BY_DATE,
    SCHEDULE_USERS_CLASSES,
    SCHEDULE_INSERTED,
    TOGGLE_SITE_REFETCH,
    SCHEDULE_REMOVED,
    APPOINTMENT_TYPES,
    AVAILABILITIES, AVAILABILITIES_PATCH, AVAILABILITIES_REMOVE_PATCH
} from '../constants';
import {CANCEL_SCHEDULE_USER} from "../constants/booking";

const initialState = {
    schedules: {
        isLoading: false,
        data: [],
        error: null,
    },
    weekly: {
        isLoading: false,
        data: [],
        error: null,
    },
    userClasses: {
        isLoading: false,
        data: [],
        error: null,
        direction: 'both'
    },
    action: {
        isLoading: false,
        error: {}
    },
    scheduleListener: {
        obj: {},
        update: true
    },
    appointmentTypes: {
        isLoading: false,
        data: [],
        error: null,
    },
    availabilities: {
        data: [],
        error: null,
    }
};

const scheduleReducer = (state = initialState, action) => {
    const schedules = SCHEDULES.toUpperCase(); // just MAKE SURE IT IS UPPER
    const scheduleAction = SCHEDULE_ACTION.toUpperCase();
    const userClasses = USER_CLASSES.toUpperCase();
    const appointmentTypes = APPOINTMENT_TYPES.toUpperCase();
    const weeklySchedules = WEEKLY_SCHEDULES.toUpperCase();
    const availabilities = AVAILABILITIES.toUpperCase();
    switch (action.type) {
        case `${schedules}_PENDING`:
            return {
                ...state,
                schedules: {isLoading: true},
            };
        case `${schedules}_SUCCESS`: {
            return {
                ...state,
                schedules: {
                    isLoading: false,
                    data: action.payload,
                    error: null
                }
            };
        }
        case `${schedules}_FAILED`:
            return {
                ...state,
                schedules: {
                    isLoading: false,
                    data: [],
                    error: action.payload
                }
            };
        case `${availabilities}_PENDING`:
            return {
                ...state,
            };
        case `${availabilities}_SUCCESS`: {
            return {
                ...state,
                availabilities: {
                    data: action.payload,
                    error: null
                }
            };
        }
        case `${availabilities}_FAILED`:
            return {
                ...state,
                availabilities: {
                    data: [],
                    error: action.payload
                }
            };
        // HANDLES ALL SCHEDULE ACTIONS !!!
        case `${scheduleAction}_PENDING`:
            return {
                ...state,
                action: {
                    isLoading: true,
                    error: {}
                }
            };
        case `${scheduleAction}_SUCCESS`: {
            // Need to find schedule in array and update
            return {
                ...state,
                action: {
                    isLoading: false,
                    error: {}
                },
            };
        }
        case `${scheduleAction}_FAILED`: {
            // biggest error handler
            return {
                ...state,
                action: {
                    isLoading: false,
                    error: action.payload
                }
            };
        }
        // HANDLES ALL SCHEDULE ACTIONS !!!
        case `${SCHEDULE_BY_DATE}_SUCCESS`: {
            return {
                ...state,
                schedules: {
                    data: state.schedules.data.map(item => item.id === action.payload.id ? {...action.payload} : item)
                }
            }
        }
        case `${SCHEDULE_USERS_CLASSES}_SUCCESS`: {
            return {
                ...state,
                userClasses: {
                    data: state.userClasses.data.filter(item => item.id !== action.payload.id)
                }
            }
        }
        case `${AVAILABILITIES_PATCH}_SUCCESS`: {
            return {
                ...state,
                availabilities: {
                    data: state.availabilities.data.map(item => {
                        if(item.id === action.payload.availability_id && item.coach.user_fk === action.payload.coach.id && item.time === action.payload.time){
                           return action.payload;
                        }
                        return item;
                    })
                }
            }
        }
        case `${AVAILABILITIES_REMOVE_PATCH}_SUCCESS`: {
            return {
                ...state,
                availabilities: {
                    data: state.availabilities.data.filter(item => item.id !== action.payload.id) //Note on remove, id is schedule, so it's unique
                }
            }
        }

        // HANDLES ALL SCHEDULE ACTIONS !!!

        // Dunno it look bad why do this again.. weird
        case `${userClasses}_PENDING`:
            return {
                ...state,
                userClasses: {
                    ...state.userClasses,
                    isLoading: true,
                    direction: action.payload.direction
                },
            };
        case `${userClasses}_SUCCESS`: {
            const direction = state.userClasses.direction;
            return {
                ...state,
                userClasses: {
                    ...state.userClasses,
                    isLoading: false,
                    data: direction === 'both' ? action.payload : direction === 'down' ? [...state.userClasses.data, ...action.payload] : [...action.payload, ...state.userClasses.data],
                    error: {},
                },
            };
        }
        case `${userClasses}_FAILED`:
            return {
                ...state,
                userClasses: {
                    ...state.userClasses,
                    isLoading: false,
                    data: [],
                    error: action.payload,
                }
            };
        case `${weeklySchedules}_PENDING`:
            return {
                ...state,
            };
        case `${weeklySchedules}_SUCCESS`: {
            return {
                ...state,
                weekly: {
                    ...state.weekly,
                    isLoading: false,
                    data: action.payload,
                    error: null,
                },
            };
        }

        case `${weeklySchedules}_FAILED`:
            return {
                ...state,
            };
        case `${SCHEDULE_INSERTED}_SUCCESS`:
        case `${SCHEDULE_REMOVED}_SUCCESS`: {
            return {
                ...state,
                scheduleListener: {
                    obj: action.payload,
                    update: !state.scheduleListener.update
                },
            };
        }
        case `${appointmentTypes}_PENDING`:
            return {
                ...state,
                appointmentTypes: {
                    ...state.appointmentTypes,
                    isLoading: true
                },
            };
        case `${appointmentTypes}_SUCCESS`: {
            return {
                ...state,
                appointmentTypes: {
                    ...state.userClasses,
                    isLoading: false,
                    data: action.payload,
                    error: {},
                },
            };
        }
        case `${appointmentTypes}_FAILED`:
            return {
                ...state,
                appointmentTypes: {
                    ...state.appointmentTypes,
                    isLoading: false,
                    data: [],
                    error: action.payload,
                }
            };
            default:
            return state;
    }
};

export default scheduleReducer;