import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {globalStyles} from "../../../styles/Global";
// import {withNavigation} from "react-navigation";
import {Field, reduxForm,change} from 'redux-form';
import {Form} from "native-base";
import FloatingTextInput from "../../Stateless/FloatingTextInput";
import {t} from "../../../services/i18n";
import {required} from "../../../helpers/Validators";
import {CountryPickerRedux} from "../../Stateless/CountryPickerRedux";
import {Platform, View} from "react-native";
import {isCountryCode, getAllCountries} from "react-native-country-picker-modal";
import {isMobile, isWeb} from "../../../helpers/functions";
import TextInputField from "../../Web/Common/TextInputField";
import Dropdown from "../../Web/Common/Dropdown";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import {verticalScale} from "react-native-size-matters";
//TODO Change naming, it is user digital form, not create token form.
// @withNavigation
class CreateTokenForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            countriesArray: null
        }
        this.onCountrySelect = this.onCountrySelect.bind(this);
    }

    async componentDidMount() {
        const res = await getAllCountries();
        this.setState({countriesArray: res});
        const refState = this.props.initialValues;
        let country = refState?.country.toUpperCase();
        if(!isCountryCode(country)){
           //TODO TEMP FIXER
            switch(country?.toLowerCase()){
                case 'israel':
                    country = 'IL';
                    break;
                case 'usa':
                    country = 'US';
                    break;
                default:
                    country = null;
                    break;
            }
        }
        this.props.change('country',country);

    }


    render() {
        const {handleSubmit, mobile} = this.props;
        return (
            <View>
                <Form onSubmit={handleSubmit} style={globalStyles.flex}>
                    {
                        isWeb() ?
                            <>
                                <View style={styles(mobile).countryCityWrapper}>
                                    <View style={{width: mobile ? '100%' : '50%'}}>
                                        <AppText style={[styles(mobile).countryLabel]}>{t('common:forms:createToken:country', {})}</AppText>
                                        <Field
                                            name={"country"}
                                            component={Dropdown}
                                            items={this.state.countriesArray}
                                            valuePropName='cca2'
                                            labelPropName='name'
                                            initValue={null}
                                            onSelection={this.onCountrySelect}
                                            style={{width: '100%', height: mobile ? '40px' : 'fit-content', alignSelf: 'center'}}
                                            validate={[required]}
                                        />
                                    </View>
                                    <Field
                                        name={"state"}
                                        component={TextInputField}
                                        placeHolder={t('common:forms:createToken:state', {})}
                                        nameLabel={t('common:forms:createToken:state', {})}
                                        isPassword={false}
                                    />
                                </View>
                                <Field
                                    name={"city"}
                                    component={TextInputField}
                                    placeHolder={t('common:forms:createToken:city', {})}
                                    nameLabel={t('common:forms:createToken:city', {})}
                                    isPassword={false}
                                    validate={[required]}
                                    overrideStyles={{width: mobile ? '100%' : '50%'}}
                                />
                                <Field
                                    name={"address"}
                                    component={TextInputField}
                                    placeHolder={t('common:forms:createToken:address', {})}
                                    nameLabel={t('common:forms:createToken:address', {})}
                                    isPassword={false}
                                />
                                <Field
                                    name={"zip"}
                                    component={TextInputField}
                                    placeHolder={t('common:forms:createToken:zipCode', {})}
                                    nameLabel={t('common:forms:createToken:zipCode', {})}
                                    isPassword={false}
                                    validate={[required]}
                                    overrideStyles={{width: mobile ? '100%' : '50%'}}
                                />
                            </>
                            :
                            <>
                                <Field
                                    name={"country"}
                                    component={CountryPickerRedux}
                                    onCountrySelect={this.onCountrySelect}
                                    showError={true}
                                    placeholder={t('common:forms:createToken:selectCountry',{})}
                                    validate={[required]}
                                />
                                <Field
                                    name={"state"}
                                    component={FloatingTextInput}
                                    placeholder={t('common:forms:createToken:state', {})}
                                />
                                <Field
                                    name={"city"}
                                    component={FloatingTextInput}
                                    placeholder={t('common:forms:createToken:city', {})}
                                    validate={[required]}
                                />
                                <Field
                                    name={"address"}
                                    component={FloatingTextInput}
                                    placeholder={t('common:forms:createToken:address', {})}
                                />
                                <Field
                                    name={"zip"}
                                    component={FloatingTextInput}
                                    placeholder={t('common:forms:createToken:zipCode', {})}
                                    validate={[required]}
                                />
                            </>
                    }
                </Form>
            </View>
        )
    }

    onCountrySelect(country){
        this.props.change('country',isWeb() ? country : country.cca2);
        this.forceUpdate();
    }
}



const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {change},
        dispatch,
    );
};

const mapStateToProps = state => ({
    cart: state.shop.cart,
    whiteLabelProperties: state.whiteLabelProperties,
    initialValues: state.user.activeUsersBoxes, // pull initial values from account reducer
});

CreateTokenForm = reduxForm({
    form: 'CreateTokenForm',
    enableReinitialize: true,
    // onSubmit: RemoteSubmit
})(CreateTokenForm);
// You have to connect() to any reducers that you wish to connect to yourself
CreateTokenForm = connect(
    mapStateToProps,
    mapActionsToProps
)(CreateTokenForm);

export default CreateTokenForm;


const styles = (mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            countryCityWrapper: {
                flexDirection: mobile ? 'column' : 'row',
                gap: '1em',
            },
            countryLabel: {
                fontSize: '0.8rem',
                color: Colors.labelColor,
                marginBottom: mobile ? verticalScale(5) : 0
            }
        }
    })
})