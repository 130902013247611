import * as React from "react"
import Svg, { Circle, Rect, Path } from "react-native-svg"

const SvgFullCalendar = (props) => (
    <Svg
        width={157}
        height={162}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Circle cx={71.787} cy={71.787} r={71.787} fill="#F0F0F0" />
        <Rect
            x={26.483}
            y={57.123}
            width={96.738}
            height={92.36}
            rx={16.415}
            fill="#fff"
            stroke="#4F4F52"
            strokeWidth={3.064}
        />
        <Path
            d="M137.34 134.403c0 14.436-11.701 26.136-26.137 26.136s-26.136-11.7-26.136-26.136 11.7-26.137 26.136-26.137 26.137 11.701 26.137 26.137Z"
            fill="#fff"
            stroke="#4F4F52"
            strokeWidth={2.922}
        />
        <Path
            d="M111.402 121.469v13.733l9.349 9.349"
            stroke="#4F4F52"
            strokeWidth={4.377}
            strokeLinecap="round"
        />
        <Path d="M123.439 74.414H26.701" stroke="#4F4F52" strokeWidth={3.064} />
        <Path
            d="M98.489 44.648v10.943M52.09 44.648v10.943"
            stroke="#4F4F52"
            strokeWidth={4.377}
        />
        <Path
            d="M75.14 83.168v50.612M49.979 100.232h57.264M42.46 117.006h39.91M91.626 83.168v24.294M59.812 83.168v50.612"
            stroke="#4F4F52"
            strokeWidth={2.189}
        />
        <Circle
            cx={21.449}
            cy={100.239}
            r={16.634}
            fill="#fff"
            stroke="#4F4F52"
            strokeWidth={2.626}
        />
        <Path
            d="M23.144 109.964H19.96v-3.071h3.183v3.071Zm-.028-21.078-.335 16.22h-2.457l-.39-16.22h3.182Z"
            fill="#4F4F52"
        />
        <Circle
            cx={138.759}
            cy={37.645}
            r={16.634}
            fill="#fff"
            stroke="#4F4F52"
            strokeWidth={2.626}
        />
        <Path
            d="M140.454 47.369h-3.182v-3.071h3.182v3.07Zm-.028-21.078-.335 16.22h-2.456l-.391-16.22h3.182ZM27.139 74.414c0-9.67 7.84-17.51 17.51-17.51h60.405c9.67 0 17.51 7.84 17.51 17.51H27.139Z"
            fill="#4F4F52"
        />
    </Svg>
)

export default SvgFullCalendar
