import React, {useEffect, useRef, useState} from 'react';
import {ActivityIndicator, View} from "react-native-web";
import {AppText} from "../../Stateless/AppText";
import {Collapse,CollapseHeader, CollapseBody} from 'accordion-collapse-react-native';
import {useDispatch, useSelector} from "react-redux";
import {apiAction} from "../../../helpers/HTTP";
import {groupBy} from 'lodash';
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {SingleWorkshopHeader, SingleWorkshopBody} from "./SingleWorkshop";
import {Colors} from "../../../styles/Colors";
import {isMobile} from "../../../helpers/functions";
import Dropdown from "../Common/Dropdown";
import SearchInput from "../Common/SearchInput";
import {t} from "../../../services/i18n";
import {EmptyState} from "../../Stateless/EmptyState";
import {IconSize} from "../../../constants/iconSize";
import {updateSiteSelectedLocation} from "../../../actions/minisite";
import GenericSuccessModal from "../Common/GenericSuccessModal";
import {useNavigate} from "react-router-dom";

const WorkshopPage = (props) => {
    const {tabsDisplayed} = props;
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const [expendedIndex, setExpendedIndex] = useState(null);
    const [allWorkshops, setAllWorkshops] = useState(null);
    const [filteredWorkshops, setFilteredWorkshops] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    let styles = getStyle(tabsDisplayed)
    const mobile = isMobile();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(location) {
            getWorkshops();
        }
    }, [location]);

    useEffect(() => {
        onSearchChanged(searchInput)
    }, [searchInput]);

    const getWorkshops = async () => {
        setIsLoading(true)
        setSearchInput('')
        setAllWorkshops(null);
        setFilteredWorkshops(null)
        let workshops = await apiAction(`getWorkshopsByLocation/${location.id}`, 'get');
        workshops = groupBy(workshops, 'box_categories.name')
        setAllWorkshops(workshops);
        setFilteredWorkshops(workshops)
        setIsLoading(false)
    }

    const collapseToggled = (index) => {
        if(index === expendedIndex) {
            setExpendedIndex(null)
        } else {
            setExpendedIndex(index)
        }
    }

    const onSearchChanged = () => {
        if(searchInput === '') {
            setFilteredWorkshops(allWorkshops)
        } else {
            const temp = Object.keys(allWorkshops).reduce((res, type) => {
                res[type] = allWorkshops[type].filter(workshop => workshop.series_name.toLowerCase().includes(searchInput.toLowerCase()))
                return res;
            }, {})
            setFilteredWorkshops(temp)
        }
    }

    const onLocationChanged = (locationId) => {
        const newLocation = box.locations_box.find(item => item.id == locationId)
        dispatch(updateSiteSelectedLocation(newLocation))
    }

    return (
        <>
            {showSuccess ?
                <GenericSuccessModal show={true}
                                     iconName={'shopaholic'}
                                     setClose={() => navigate({
                                         pathname: '/schedule',
                                         search: boxQueryString,
                                     })}
                                     title={t('modals:Buy:purchase-completed', {})}
                                     subHeader={t('screens:workshop:book-workshop-success',{workshop: showSuccess.series_name})}
                                     isPopup={mobile}/>
                :
                <View style={[styles.wrapper, mobile && styles.wrapperMobile]}>
                    {!mobile &&
                    <View style={[styles.filters]}>
                        {box?.locations_box.length > 1 &&
                        <Dropdown items={box?.locations_box}
                                  valuePropName='id'
                                  labelPropName='location'
                                  iconName={'location'}
                                  initValue={location.id}
                                  onSelection={(value) => onLocationChanged(value)}
                        />}
                        <SearchInput placeHolder={t('screens:Schedule:search-class', {})} value={searchInput}
                                     onChange={setSearchInput} overrideStyles={{height: '100%'}}/>
                    </View>
                    }
                    <View style={[mobile ? styles.workshopsWrapperMobile : styles.workshopsWrapper]}>
                        {filteredWorkshops ?
                            Object.keys(filteredWorkshops).map((categoryName, i) => {
                                return (
                                    <View style={[styles.categoryWrapper]}
                                          key={`workshop-type-collapse-${i}-${categoryName}`}>
                                        <AppText
                                            style={[styles.categoryName, mobile && styles.categoryNameMobile]}>{categoryName}</AppText>
                                        {
                                            filteredWorkshops[categoryName].map((workshop, j) => {
                                                return (
                                                    <Collapse isExpanded={expendedIndex === `collapse-${i}-${j}`}
                                                              onToggle={() => collapseToggled(`collapse-${i}-${j}`)}
                                                              key={`workshop-collapse-${j}-${categoryName}`}
                                                              style={[mobile && styles.mobileCollapse, mobile && expendedIndex === `collapse-${i}-${j}` && styles.collapseSelected]}>
                                                        <CollapseHeader>
                                                            <SingleWorkshopHeader workshop={workshop}
                                                                                  bottomSeparator={j === filteredWorkshops[categoryName].length - 1}
                                                                                  onViewMore={() => collapseToggled(`collapse-${i}-${j}`)}
                                                                                  showViewMore={expendedIndex !== `collapse-${i}-${j}`}
                                                                                  onSuccess={(workshop) => setShowSuccess(workshop)}/>
                                                        </CollapseHeader>
                                                        <CollapseBody
                                                            style={{marginBottom: j < filteredWorkshops[categoryName].length - 1 ? '4em' : '1em'}}>
                                                            <SingleWorkshopBody workshop={workshop}/>
                                                        </CollapseBody>
                                                    </Collapse>
                                                )
                                            })
                                        }
                                        {
                                            filteredWorkshops[categoryName].length === 0 &&
                                            <EmptyState text={t('screens:workshop:empty-state-msg', {})}
                                                        iconName={'ghost'}
                                                        iconSize={IconSize.XL}
                                            />
                                        }
                                    </View>
                                )
                            })
                            :
                            <ActivityIndicator color={Colors.brandedBlue}/>
                        }
                        {!isLoading && filteredWorkshops && Object.keys(filteredWorkshops).length === 0 &&
                        <EmptyState text={t('screens:workshop:empty-state-msg', {})} iconName={'ghost'}
                                    iconSize={IconSize.XL}/>}
                    </View>
                </View>
            }
            </>
    );
};

export default WorkshopPage;

let getStyle = function (tabsDisplayed= true) {
    return EStyleSheet.create({
        ...Platform.select({
            web: {
                wrapper: {
                    height: () => `100% - ${EStyleSheet.value('$desktopHeader')}`,
                },
                wrapperMobile: {
                    maxHeight: () => `100% - ${parseInt(tabsDisplayed ? EStyleSheet.value('$tabsMaxHeight') : 0) + parseInt(EStyleSheet.value('$mobileHeader'))}`,
                    height: '100%',
                    overflow: 'auto',
                    padding: 0
                },
                filters: {
                    flexDirection: 'row',
                    gap: '1em',
                    justifyContent: 'end',
                    padding: '2em',
                    height: 100
                },
                workshopsWrapper: {
                    gap: '3.2em',
                    overflow: 'auto',
                    paddingHorizontal: '2em',
                    height: () => `100% - ${EStyleSheet.value('$desktopHeader') + 100}`,
                    paddingBottom: '1em'
                },
                workshopsWrapperMobile: {
                    gap: '1.3em',
                    marginTop: '1em'
                },
                separator: {
                    height: 1,
                    width: '100%',
                    backgroundColor: Colors.graySeparator,
                },
                categoryName: {
                    fontSize: '1.2rem',
                    marginBottom: '0.5em',
                    fontWeight: 'bold',
                    width: 'fit-content'
                },
                categoryNameMobile: {
                    paddingHorizontal: '1em',
                    paddingVertical: '0.5em'
                },
                mobileCollapse: {
                    paddingHorizontal: '1em'
                },
                collapseSelected: {
                    backgroundColor: Colors.graySeparator,
                }
            }
        })
    })
}
