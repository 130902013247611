import React from "react";
import {isAMPMtimeFormat} from "./functions";
import moment from "moment";
import i18n, {t} from "../services/i18n";
import {store} from "../config/store";
import ScheduleMobileHeader from "../components/Web/Headers/ScheduleMobileHeader";
import ShopMobileHeader from "../components/Web/Headers/ShopMobileHeader";
import {AppText} from "../components/Stateless/AppText";
import {Colors} from "../styles/Colors";
import RegistrationMobileHeader from "../components/Web/Headers/RegistrationMobileHeader";
import {I18nManager as RNI18nManager} from "react-native";
import {updateBoxQueryString, updateHeaderConfig, updateLanguage} from "../actions/minisite";
import Toast from "react-native-root-toast";
import Coaches from "../svg/custom/Coaches";
import Spaces from "../svg/custom/Spaces";
import ScheduleOptions from "../components/Web/Schedule/Desktop/ScheduleOptions";
import AppointmentHandler from "../components/Web/Appointment/AppointmentHandler";

export function getTimeStringByFormat(startTime, endTime){
    const isGlobal = isAMPMtimeFormat();
    const start = moment(startTime, 'HH:mm:ss')
    const end = moment(endTime, 'HH:mm:ss')
    const startHour = start.hour()
    const endHour = end.hour()
    const startMin = moment(start.minutes(), 'minutes').format('mm')
    const endMin = moment(end.minutes(), 'minutes').format('mm')

    let text = '';
    if(isGlobal) {
        if(start.minutes() === 0 && end.minutes() === 0) {
            if(startHour < 12 && endHour >= 12) {
                text = `${startHour}am - ${getAMPMHour(endHour)}pm`
            } else {
                if(startHour < 12) {
                    text = `${startHour}-${endHour}am`
                } else {
                    text = `${getAMPMHour(startHour)}-${getAMPMHour(endHour)}pm`
                }
            }
        } else if (start.minutes() === 0 && end.minutes() !== 0){
            if(startHour < 12 && endHour >= 12) {
                text = `${startHour}am - ${getAMPMHour(endHour)}:${endMin}pm`
            } else {
                if(startHour < 12) {
                    text = `${startHour}-${endHour}:${endMin}am`
                } else {
                    text = `${getAMPMHour(startHour)}-${getAMPMHour(endHour)}:${endMin}pm`
                }
            }
        } else if (start.minutes() !== 0 && end.minutes() === 0){
            if(startHour < 12 && endHour >= 12) {
                text = `${startHour}:${startMin}am - ${getAMPMHour(endHour)}pm`
            } else {
                if(startHour < 12) {
                    text = `${startHour}:${startMin}-${endHour}am`
                } else {
                    text = `${getAMPMHour(startHour)}:${startMin}-${getAMPMHour(endHour)}pm`
                }
            }
        } else {
            if(startHour < 12 && endHour >= 12) {
                text = `${startHour}:${startMin}am - ${getAMPMHour(endHour)}:${endMin}pm`
            } else {
                if(startHour < 12) {
                    text = `${startHour}:${startMin}-${endHour}:${endMin}am`
                } else {
                    text = `${getAMPMHour(startHour)}:${startMin}-${getAMPMHour(endHour)}:${endMin}pm`
                }
            }
        }
    } else {
        text = `${start.format('HH:mm')}-${end.format('HH:mm')}`
    }
    return text
}

export function getAMPMHour(hour) {
    if(hour <= 12) {
        return hour
    } else {
        return hour -12
    }
}

export function getPageTitle(page) {
    switch (page) {
        case "/schedule":
            return t('screens:Schedule:title',{});
        default:
            return '';
    }
}

export function getPageHeaderOnPress(page) {
    if(page.includes('/shop')) {
        page = '/shop'
    }

    switch (page) {
        case "/schedule":
            return <ScheduleMobileHeader/>
        case "/shop":
            return <ShopMobileHeader/>
        case "/cart":
            return <AppText style={{fontSize: '1rem', color: Colors.lightBlack}} boldFont>{t('screens:Cart:title',{})}</AppText>
        case "/register":
            return <RegistrationMobileHeader/>
        default:
            return null;
    }
}

export function isSiteHebrew() {
    const lang = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.language
    return lang === 'he'
}

export async function changeSiteLang(lang, dispatch, shouldReload = false) {
    if(i18n.locale !== lang) {
        if (lang === 'en' || lang === 'he') {
            i18n.changeLanguage(lang, () => console.log('back'));
        }
    }
    const isLocaleRTL = i18n.dir === 'RTL';
    RNI18nManager.forceRTL(isLocaleRTL);
    RNI18nManager.allowRTL(isLocaleRTL);
    await dispatch(updateLanguage(lang))
    const newQueryString = await dispatch(updateBoxQueryString(null, null, null, lang))
    if(shouldReload) {
        location.href = location.origin + location.pathname + newQueryString
    }
}

export async function handleShareClick(text, url) {
    const shareData = {
        title: "Arbox",
        text: text,
        url: url
    }
    try {
        await navigator.share(shareData);
    } catch(err) {
        console.error("could not open navigation.share");
        await navigator.clipboard.writeText(url);
    }
}


export async function handleCopyLinkClick(url) {
    try {
        await navigator.clipboard.writeText(url);
        Toast.show(t('common:copy-success',{}), {
            duration: Toast.durations.SHORT,
            position: Toast.positions.TOP,
            animation: true,
            hideOnPress: true,
            backgroundColor: Colors.brandedGreen
        })
    } catch (err) {
        console.error("failed to copy link")
        Toast.show(t('common:copy-fail',{}), {
            duration: Toast.durations.SHORT,
            position: Toast.positions.TOP,
            animation: true,
            hideOnPress: true,
            backgroundColor: Colors.redPinkColor
        })
    }
}


export function handleShareOrCopy(mobile, shareLink, header = null) {
    if(mobile) {
        handleShareClick(header || shareLink, shareLink)
    } else {
        handleCopyLinkClick(shareLink)
    }
}

export const getCoachOrSpaceSelectionComponent = (showCoachAppointment, showSpaceAppointment) => {
    const options = [
        { img: Coaches, label: t('screens:Schedule:coach-availability', {}), onCardPress: showCoachAppointment},
        { img: Spaces, label: t('screens:Schedule:space-availability', {}), onCardPress: showSpaceAppointment}
    ]
    return <ScheduleOptions options={options} header={t('general:availability-type-selection-header',{})}/>
}

export const goBack = (headerConfig, dispatch) => {
    const stepIndex = headerConfig?.steps?.findIndex(step => step === headerConfig?.stepName)
    if(stepIndex > -1) {
        const prevStep = headerConfig.steps[stepIndex - 1]
        const newHeaderConfig = {...headerConfig, stepName: prevStep, fromBackPress: true}
        dispatch(updateHeaderConfig(newHeaderConfig))
    }
}