import * as React from "react"
import Svg, { Path,Defs } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgHelloArbox(props) {
    return (
        <Svg viewBox="0 0 189.44 205.49" {...props}>
            <Path
                d="M106.91 8.16h.09a13.11 13.11 0 0117 7.39l14 34.81"
                fill="none"
                stroke="#ed3b4a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M99.55 176a55.69 55.69 0 01-38-33.39h-.12l-16.67-42"
                fill="none"
                stroke="#f5c2d1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M150 78.84l.44-4.37a16.57 16.57 0 1133.05 2.46s-4.05 41.63-5 49.93c-2.58 23.75-20.66 38.12-32.8 42.79L135 173.87a59.15 59.15 0 01-21.09 3.91"
                fill="none"
                stroke="#0554a3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                fill="none"
                stroke="#ed3b4a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
                d="M145.6 122.14l2.45-24.33"
            />
            <Path
                d="M58.09 63.07l16.25 41.44M74 31.93l16 41.15M38.76 85.56l-5-12.74a13.14 13.14 0 017.37-17h.08a13.13 13.13 0 0116.93 7.32L49.57 41.6a13.14 13.14 0 017.36-17H57a13.11 13.11 0 0117 7.33l-3.57-9a13.13 13.13 0 017.37-17h.07a13.13 13.13 0 0117 7.39L117 69.94"
                fill="none"
                stroke="#4aba7a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M8.65 196.69a82.05 82.05 0 01-1.72-46.26 80.85 80.85 0 0114.12-29.26"
                fill="none"
                stroke="#0554a3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M25 191.55c-1.38-6-4-12.44-3.26-22.74.8-12 2.31-16.12 6-23.47"
                fill="none"
                stroke="#ed3b4a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
        </Svg>
    )
}

export default SvgHelloArbox
