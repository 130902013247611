import React from 'react';
import {scale} from "react-native-size-matters";
import {Colors} from "../../styles/Colors";
import ModalButton from "../ModalButton";
import {connect} from "react-redux";
import {globalStyles} from "../../styles/Global";
import {View} from 'react-native';

class LogBookIsRx extends React.PureComponent {
    render() {
        const styles = {marginTop: 0, width: scale(100), borderRadius: 0, borderColor: 'rgba(255,255,255,0.5)'};
        const {active,updateValue} = this.props;
        return (
            <View style={globalStyles.flexDirectionRow}>
                <ModalButton
                    onPress={() => {
                        updateValue('is_rx', 0)
                    }}
                    regular={true}
                    type={active.is_rx === 0 ? 'primary' : 'secondary'}
                    overrideStyle={[styles, active.is_rx === 0 ? {borderWidth: 0} : null]}
                    colors={active.is_rx === 0 ? Colors.white : this.props.whiteLabelProperties.logbookColor}
                    text={'Scale'}
                    textColor={active.is_rx === 0 ? this.props.whiteLabelProperties.logbookColor : 'rgba(255,255,255,0.5)'}
                    textStyles={{fontSize: scale(20)}}
                />
                <ModalButton
                    onPress={() => {
                        updateValue('is_rx', 1)
                    }}
                    regular={true}
                    type={active.is_rx === 1 ? 'primary' : 'secondary'}
                    overrideStyle={[styles, active.is_rx === 1 ? {borderWidth: 0} : null]}
                    colors={active.is_rx === 1 ? Colors.white : this.props.whiteLabelProperties.logbookColor}
                    text={'RX'}
                    textColor={active.is_rx === 1 ? this.props.whiteLabelProperties.logbookColor : 'rgba(255,255,255,0.5)'}
                    textStyles={{fontSize: scale(20)}}
                />
            </View>
        );
    }
}

const
    mapStateToProps = state => ({
        whiteLabelProperties: state.whiteLabelProperties,

    });


export default connect(
    mapStateToProps,
    null
)(LogBookIsRx);

