import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8About(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 1C11.223 1 0 10.879 0 23.188c0 6.046 2.773 11.476 7.219 15.5-.672 2.074-2.172 3.71-3.688 4.968-.816.676-1.597 1.254-2.219 1.813-.308.277-.59.558-.812.906-.223.348-.422.844-.281 1.375l.125.406.344.219c1.289.742 2.894.871 4.625.75 1.73-.121 3.617-.52 5.468-1.031 3.594-.992 6.969-2.407 8.75-3.188 1.758.367 3.524.594 5.375.594 13.778 0 25-9.879 25-22.188C49.906 11.008 38.781 1 25 1zm0 2c12.82 0 22.906 9.215 22.906 20.313 0 11.09-10.176 20.187-23 20.187-1.828 0-3.55-.18-5.281-.563l-.344-.093L19 43c-1.672.738-5.207 2.18-8.75 3.156-1.773.489-3.54.864-5.063.969-1.019.07-1.648-.172-2.343-.344.496-.426 1.176-.933 1.969-1.593 1.742-1.446 3.832-3.458 4.562-6.438l.156-.625-.5-.375C4.625 34.016 2 28.875 2 23.187 2 12.098 12.176 3 25 3zm-1.188 9.813c-.3 0-.406.175-.406.374v2.626c0 .3.207.374.407.374h2.375c.3 0 .406-.175.406-.375v-2.624c0-.301-.207-.376-.407-.376zm.094 7.28c-.3 0-.406.208-.406.407v13.406c0 .301.207.407.406.407v.093h2.282c.3 0 .406-.207.406-.406V20.5c0-.3-.207-.406-.407-.406z" />
    </Svg>
  );
}

export default SvgIcons8About;
