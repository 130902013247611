// @flow
import * as React from 'react';
import {Platform, StyleSheet, View} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import EmptyState from "./EmptyState";
import {useEffect, useState} from "react";
import calendarEvents from "../../../../services/calendarEvents";
import DayItemsList from "./DayItemsList";
import moment from "moment";
import {useSelector} from "react-redux";
import {isAMPMtimeFormat} from "../../../../helpers/functions";
import EStyleSheet from "react-native-extended-stylesheet";

const DayColumn = (props) => {
    const {classes, date, searchInput} = props
    const [isEmpty, setIsEmpty] = useState(false);
    const [classesByTime, setClassesByTime] = useState(null);
    const today = moment().format('YYYY-MM-DD');
    const isPast = date < today;

    useEffect(() => {
        const res = calendarEvents.groupClassesByTime(classes, true)
        setClassesByTime(res);
        setIsEmpty(res.length === 0)
    }, [classes]);

    return (
        <View style={[styles.wrapper, isEmpty ? styles.empty : null, isPast ? styles.past : null]}>
            {isEmpty ?
                <EmptyState isRestricted={false} date={'01/01'} time={'10:00'}/>
                :
                <View style={{width: '100%'}}>
                    {classesByTime && classesByTime.map((item, index) => {
                        let res = item[1].filter(classItem => {
                            return classItem.box_categories.name.toLowerCase().includes(searchInput.toLowerCase())
                        })
                        if(res.length > 0) {
                            let hour = item[0];
                            if(isAMPMtimeFormat()) {
                                hour = hour > 12 ? `${hour-12} pm` : `${parseInt(hour)} ${hour == 12 ? 'pm' : 'am'}`
                            }
                            return <DayItemsList key={`day-items-list-${hour}-${index}`}
                                                 classes={item[1]}
                                                 time={hour}
                                                 searchInput={searchInput}
                            />
                        }
                    })}
                </View>
            }
        </View>
    );
};
export default DayColumn;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                padding: '0.5em',
                alignItems: 'center',
                flex: 1,
                height: '100vh',
                backgroundColor: Colors.white,
                // borderEndColor: Colors.siteScheduleBorder,
                // borderEndWidth: 1,
                width: '100%',
            },
            empty: {
                justifyContent: 'center'
            },
            past: {
                backgroundColor: Colors.pastDateBg,
            }
        }
    })
})