import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Back(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#18181b" {...props}>
          <Path d="M34.96 2.98a2.01 2.01 0 00-1.374.606l-20 20a2 2 0 000 2.828l20 20c.5.523 1.246.734 1.95.55.698-.183 1.245-.73 1.429-1.429a1.997 1.997 0 00-.55-1.95L17.827 25 36.414 6.414c.59-.574.77-1.453.45-2.215A2.008 2.008 0 0034.96 2.98z" />
      </Svg>
  );
}

export default SvgIcons8Back;
