import EStyleSheet from 'react-native-extended-stylesheet';
import React, {useEffect} from 'react';
import {Text, TextInput,LogBox} from 'react-native';
import * as Icon from '@expo/vector-icons';
import * as Font from 'expo-font';
import {Asset} from 'expo-asset';
import {Provider} from 'react-redux';
import {I18nManager as RNI18nManager} from 'react-native';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './src/config/store';
import Root from './src/Root';
import i18n from './src/services/i18n';
import {ActionSheetProvider} from '@expo/react-native-action-sheet'
import {getProperties} from "./src/actions/whiteLabelProperties";
import ErrorBoundary from 'react-native-error-boundary';
import {CustomFallback} from "./src/components/Stateless/CustomFallback";
import * as Sentry from 'sentry-expo';
import * as SplashScreen from 'expo-splash-screen';
import { RootSiblingParent } from 'react-native-root-siblings';
import {Image} from 'react-native';
import {isWeb} from "./src/helpers/functions";
import {axiosInstance} from "./src/helpers/HTTP";

/* app.js */

EStyleSheet.build({
    $desktopHeader: 65,
    $calendarStripHeight: 100,
    $tabsMaxHeight: 45,
    $mobileHeader: 75,
    $AppointmentTextHeaderHeight: 70,
    $footerMaxHeight: 40,
    $mobileCartSummaryWithNote: 300,
    $progressBarMobile: 100,
    $mobileRegisterMembershipActions: 90,
    $bookingMobileHeader: 100,
    $bookingMobileFooter: 70,
    $mobileShopFooterBtns: 100,
    $desktopShopFooterBtns: 120
});

Sentry.init({
    dsn: 'https://907b48ed75214325b814f1130d30f4fb@o458189.ingest.sentry.io/5455287',
    enableInExpoDevelopment: true,
    debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});




export default class App extends React.Component {

    constructor() {
        super();
        this.state = {
            isLoadingComplete: false,
            isI18nInitialized: false,
            appReady: false
        }
    }

    async componentDidMount() {
        // Prevent native splash screen from autohiding
        try {
            await SplashScreen.preventAutoHideAsync();
        } catch (e) {
            await SplashScreen.hideAsync();
            console.warn(e);
        }
        this.prepareResources();

    }

    prepareResources = async () => {
        try {
            LogBox.ignoreAllLogs();
            Text.defaultProps = Text.defaultProps || {};
            Text.defaultProps.allowFontScaling = false;
            TextInput.defaultProps = TextInput.defaultProps || {};
            TextInput.defaultProps.allowFontScaling = false;
            await this._loadResourcesAsync();
            await this._handleFinishLoading();
        } catch (e) {
            console.log('error',e);
            console.warn(e);
        } finally {
            this.setState({ appReady: true }, async () => {
                await SplashScreen.hideAsync();
            });
        }
    };

    cacheImages(images) {
        return images.map(image => {
            if (typeof image === 'string') {
                return Image.prefetch(image);
            } else {
                return Asset.fromModule(image).downloadAsync();
            }
        });
    }




    _loadResourcesAsync = async () => {

        await store.dispatch(getProperties());
        const imageAssets = this.cacheImages([
            require('./assets/images/logo.png'),
            require('./assets/images/splash.png'),
        ]);
        return Promise.all([...imageAssets,
            Font.loadAsync({
                ...Icon.Ionicons.font,
                ...Icon.MaterialCommunityIcons.font,

                Roboto: require('native-base/Fonts/Roboto.ttf'),
                Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),

                'heebo-light': require('./assets/fonts/Heebo-Light.ttf'),
                'heebo-thin': require('./assets/fonts/Heebo-Thin.ttf'),
                'heebo': require('./assets/fonts/Heebo-Regular.ttf'),
                'heebo-medium': require('./assets/fonts/Heebo-Medium.ttf'),
                'heebo-bold': require('./assets/fonts/Heebo-Bold.ttf'),
                'heebo-extrabold': require('./assets/fonts/Heebo-ExtraBold.ttf'),
                'heebo-black': require('./assets/fonts/Heebo-Black.ttf'),

                'coolvetica-rg': require('./assets/fonts/coolvetica-rg.ttf'),
                'Helvetica': require('./assets/fonts/Helvetica.ttf'),
                'Helvetica-Light': require('./assets/fonts/Helvetica-Light.ttf'),
            }),
        ]);
    };


    _handleLoadingError = error => {
        // In this case, you might want to report the error to your error
        // reporting service, for example Sentry
        Sentry.Native.captureException(error)
        console.warn(error);
    };


    _handleFinishLoading = async () => {
        // On first load, none will exists, so application will try be
        const auth = store.getState().auth.data;
        const siteLang = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.language;
        let language = auth.language;
        if(isWeb()) {
            if(siteLang) {
                language = siteLang;
            } else {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if(params.hasOwnProperty('lang')) {
                    language = params.lang
                }
            }
        }

        await i18n
            .init(language)
            .then(async () => {
                const RNDir = i18n.isRTL ? 'RTL' : 'LTR';

                // RN doesn't always correctly identify native
                // locale direction, so we force it here.
                if (i18n.dir !== RNDir) {
                    const isLocaleRTL = i18n.dir === 'RTL';
                    RNI18nManager.forceRTL(isLocaleRTL);
                    // RN won't set the layout direction if we
                    // don't restart the app's JavaScript.
                    // Updates.reloadAsync();
                }

            })
            .catch(error => console.warn(error));
    };


    render() {
        if (!this.state.appReady) {
            return null;
        }
        return (
            // TODO next provider is implemented but not really used.
            <RootSiblingParent>
                <ErrorBoundary FallbackComponent={CustomFallback} onError={(e) => console.log(e)}>
                    <Provider store={store}>
                        <ActionSheetProvider>
                            <PersistGate loading={null} persistor={persistor}>
                                <Root/>
                            </PersistGate>
                        </ActionSheetProvider>
                    </Provider>
                </ErrorBoundary>
            </RootSiblingParent>
        );
    }

}

