import React from 'react';
import {Icon, View, Text, Item, Label} from 'native-base';
import {TextInput, Picker, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles, {genderSelectStyle} from './styles';
import {globalStyles} from "../../styles/Global";
import Modal from 'react-native-modal';
// import DatePicker from "react-native-datepicker";
import DatePicker from "../../components/DatePicker";

import i18n, {t} from "../../services/i18n";
import {Colors} from "../../styles/Colors";
import date from '../../services/i18n/date';
import {AppText} from "../Stateless/AppText";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {LARGE_ICON_SIZE} from "../../helpers/functions";


const metrics = {
    kilograms: 'kg',
    meters: 'm',
};

class ProfileFeature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.value ? new Date(this.props.value) : new Date(),
            editBirthday: false,
            focus: false
        };
    }

    renderEditMode(value) {
        const {name} = this.props;
        switch (name) {
            case 'birthday':
                return (
                    <TouchableOpacity onPress={() => this.setState({editBirthday: true})}>
                        <AppText style={[globalStyles.heeboMedium, {color: Colors.black}]}>{this.props.value ? date.format(this.props.value,'LL') : t('screens:Profile:features:birthday-placeholder',{})}</AppText>
                    </TouchableOpacity>
                );
            case 'phone':
                return (
                    <View style={styles.inputWrapper}>
                        <TextInput
                            underlineColorAndroid='transparent'
                            autoCorrect={false}
                            onChangeText={text => this.props.setValue(text, name)}
                            keyboardType="numeric"
                            returnKeyType='done'
                            direction='rtl'
                            onFocus={() => this.setState({focus: true})}
                            onBlur={() => this.setState({focus: false})}
                            style={[globalStyles.directionText,globalStyles.heeboMedium,styles.input,this.state.focus ? globalStyles.appFocusInputColor : globalStyles.appInputColor]}>
                            {value}
                        </TextInput>
                    </View>
                );
            default:
                return (
                    <View style={styles.inputWrapper}>
                        <TextInput
                            underlineColorAndroid='transparent'
                            autoCorrect={false}
                            direction='rtl'
                            onFocus={() => this.setState({focus: true})}
                            onBlur={() => this.setState({focus: false})}
                            onChangeText={text => this.props.setValue(text, name)}
                            style={[globalStyles.directionText,globalStyles.heeboMedium,styles.input,this.state.focus ? globalStyles.appFocusInputColor : globalStyles.appInputColor]}>
                            {value}
                        </TextInput>
                    </View>
                );
        }
    }

    renderValue(value) {
        const {name} = this.props;
        switch (name) {
            case 'birthday':
                return <AppText style={[globalStyles.heeboMedium, {color: Colors.black}]}>{value ? date.format(value,'LL') : t('screens:Profile:features:birthday-no-value',{})}</AppText>;
            default:
                return <AppText style={[globalStyles.heeboMedium, {color: Colors.black}]}>{value}</AppText>;
        }
    }

    render() {
        const {icon, iconType, label, value, isInEditMode} = this.props;
        if (this.state.editBirthday) {
            //FUCK THIS SHIT ;(
            if(Platform.OS.toLowerCase() === 'android'){
                return  <DatePicker
                    date={this.props.value ? this.props.value : new Date()}
                    onClose={(event, date) => {
                        this.setState({editBirthday: false},() =>{
                            if (date && Platform.OS.toLowerCase() !== 'ios') {
                                this.props.setValue(date, 'birthday');
                            } else {
                                if (event !== 'aborted') {
                                    this.props.setValue(date, 'birthday');
                                }
                            }
                        })
                    }}
                    onChange={d => {}}
                />
            }else{
                return <Modal
                        isVisible={this.state.editBirthday}
                        hideModalContentWhileAnimating
                        animationIn="fadeIn"
                        useNativeDriver={false}
                        animationOut="fadeOut"
                        animationInTiming={this.props.animationTiming || 500}
                        animationOutTiming={this.props.animationTiming || 500}
                        backdropTransitionInTiming={this.props.animationTiming || 500}
                        backdropTransitionOutTiming={this.props.animationTiming || 500}
                    >
                        <DatePicker
                            date={this.props.value ? this.props.value : new Date()}
                            onClose={(event, date) => {
                                if (date && Platform.OS.toLowerCase() !== 'ios') {
                                    this.setState({date});
                                    this.props.setValue(date, 'birthday');
                                } else {
                                    if (event !== 'aborted') {
                                        this.props.setValue(date, 'birthday');
                                    }
                                }
                                this.setState({editBirthday: false})
                            }}
                            onChange={d => {
                            }}
                        />
                    </Modal>
            }

        }
        return (
            <View style={[globalStyles.appListsPaddingHorizontal, styles.container]}>
                {isInEditMode ?
                    (null) :
                    (
                        <View style={styles.iconBorder}>
                            <Icons8Generator name={icon} width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE}/>
                        </View>)
                }

                <View style={[globalStyles.centerItems]}>
                    <Item stackedLabel style={[styles.value,this.props.isInEditMode ? styles.editableInput : styles.formItem]}>
                        {isInEditMode ? <Label style={styles.label}>{label.toUpperCase()}</Label> : null}
                        {isInEditMode ? this.renderEditMode(value) : this.renderValue(value)}
                    </Item>
                </View>
            </View>
        );
    }
}

ProfileFeature.propTypes = {
    icon: PropTypes.string,
    iconType: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    isInEditMode: PropTypes.bool,
    setValue: PropTypes.func,
};

export default ProfileFeature;

