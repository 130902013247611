// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native"
import {AppText} from "../../../Stateless/AppText";
import PopupModal from "../../Common/PopupModal";
import {Colors} from "../../../../styles/Colors";
import User from "../../../../svg/Icons8Gymnastics";
import {t} from "../../../../services/i18n";
import {useEffect, useState} from "react";
import moment from "moment";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {
    EXTRA_SM_ICON_SIZE, getPayForSlotMembershipType,
    isMobile,
    MEDIUM_ICON_SIZE, sendMessageToSentryBrowser,
    slotMockDataToSchedule,
    SMALL_ICON_SIZE
} from "../../../../helpers/functions";
import ReadMore from "../../Common/ReadMore";
import {useDispatch, useSelector} from "react-redux";
import ModalButton from "../../../ModalButton";
import {Picker} from "react-native-form-component";
import {deleteScheduleUser, deleteStandBy, insert} from "../../../../actions/schedule";
import ErrorModal from "../../Common/ErrorModal";
import {Linking} from "react-native-web";
import {SCHEDULE_ACTION} from "../../../../constants";
import ConfirmModal from "../../Common/ConfirmModal";
import {getCapacityTextByRestrictions, hasCoachRestriction} from "../../../../helpers/DisablePages";
import {apiAction} from "../../../../helpers/HTTP";
import {getTimeStringByFormat} from "../../../../helpers/ComponentFunctionality";
import EStyleSheet from "react-native-extended-stylesheet";
import Spinner from "react-native-loading-spinner-overlay";
import {toggleScheduleSiteReFetch} from "../../../../actions/minisite";
import {fetchUserMembershipsByBox} from "../../../../actions/memberships";
import PaymentHandlerContainer from "../../Shop/Payment/PaymentHandler";
import {emptyCart, setShopLocationsBox, addToCart, fetchCurrencySymbol} from "../../../../actions/shop";
import MembershipList from "../../../MembershipList";
import uuid from "uuid";
import CancellationPolicy from "../../Appointment/CancellationPolicy";

export const BookingCard = (props) => {
    const {classInfo, show, setClose, showBooked} = props;
    const [classDate, setClassDate] = useState('');
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const userDataForLog = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.data)
    const [showMembershipError, setShowMembershipError] = useState(false);
    const [selectedMembershipId, setSelectedMembershipId] = useState(null);
    const [selectedMembershipIndex, setSelectedMembershipIndex] = useState(0);
    const [isMembershipService, setIsMembershipService] = useState(false);
    const [membershipsForDropdown, setMembershipsForDropdown] = useState([]);
    const [noMemberships, setNoMemberships] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [lateCancel, setLateCancel] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [showPaymentProcess, setShowPaymentProcess] = useState(false);
    const [extras, setExtras] = useState(null);
    const [hasPaymentServices, setHasPaymentServices] = useState(null);
    const [noPaymentsFlag, setNoPaymentsFlag] = useState(null);
    const [zeroPurchase, setZeroPurchase] = useState(false);
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const dispatch = useDispatch();
    const mobile = isMobile();
    const isFuture = moment(classInfo.availability ? `${classInfo.date} ${classInfo.time}` : classInfo.date_time.date).isSameOrAfter(moment());

    useEffect(() => {
        if(box) {
            dispatch(fetchCurrencySymbol(`shop/currencySymbol/${location.id}`));
            fetchMemberships()
        }
    }, [box]);

    const fetchMemberships = async () => {
        const memberships = await dispatch(fetchUserMembershipsByBox(box.id, true))
        const membershipType = getPayForSlotMembershipType(classInfo);
        if(memberships.data && membershipType){
            memberships.data.unshift(membershipType);
        }
        if (memberships && memberships.data.length === 0 && !membershipType) {
            setNoMemberships(true);
        }else{
            setMembershipsForDropdown(memberships.data);
            setIsMembershipService(!!membershipType);
            setSelectedMembershipId(memberships.data[0].id);
        }
    }

    useEffect(() => {
        if(noPaymentsFlag && (!hasPaymentServices || zeroPurchase)) {
            payAndInsert(noPaymentsFlag);
        }
    }, [noPaymentsFlag, zeroPurchase]);

    useEffect(() => {
        if(classInfo) {
            let dateStr = moment(classInfo.date,'YYYY-MM-DD').format('ddd, MMM DD YYYY')
            dateStr = `${dateStr} ${getTimeStringByFormat(classInfo.time, classInfo.end_time)}`
            setClassDate(dateStr)
            handleClassHasLateCancel()
        }
    }, [classInfo, location]);

    useEffect(() => {
        if(location) {
            fetchLocationPaymentServices()
        }

    }, [location]);

    const fetchLocationPaymentServices = async () => {
        const res = await apiAction(`locationsBox/doesLocationHasPaymentService/${location.id}`, 'get', null);
        setHasPaymentServices(res)
    }


    const handleClassHasLateCancel = async () => {
        try {
            if(!classInfo.availability) {
                await apiAction(`scheduleUser/checkLateCancel`, 'post', {schedule_id: classInfo.id});
            }
        } catch (error) {
            if (error.error.code === 513) {
                setLateCancel(true)
            } else {
                setLateCancel(false)
            }
        }
    }

    const bookOrShowCancellation = () => {
        if(location.cancellation_policy && classInfo.availability && classInfo.box_categories.price > 0 && !showCancellationPolicy) {
            setShowCancellationPolicy(true);
        } else {
            setShowCancellationPolicy(false);
            bookUser()
        }
    }

    const bookUser = async () => {
        if(isLoggedIn) {
            let extrasTemp = null
            if (classInfo.availability === true) {
                extrasTemp = slotMockDataToSchedule(classInfo);
                setExtras(extrasTemp);
            }
            if (!selectedMembershipId) {
                setShowMembershipError(true);
            } else {
                if (isMembershipService) {
                    const payForSlotMembership = classInfo.box_categories.membership_types.find(membership => membership.location_box_fk === classInfo.locations_box_fk && membership.type === 'service')
                    if(hasPaymentServices && payForSlotMembership.price > 0) {
                        await dispatch(emptyCart());
                        await dispatch(setShopLocationsBox({locationsBoxId: classInfo.locations_box_fk, boxesId: classInfo.box_fk}));
                        payForSlotMembership.quantity = 0;
                        dispatch(addToCart(payForSlotMembership));
                        setShowPaymentProcess(true)
                    } else {
                        payForSlotMembership.quantity = 1;
                        let params = {
                            payment_object: {
                                payment_details: {},
                                payLater: true,
                            },
                            cart: [payForSlotMembership],
                            boxes_id: classInfo.box_fk
                        };
                        setZeroPurchase(payForSlotMembership.price === 0)
                        setNoPaymentsFlag(params)
                    }
                } else {
                    insertScheduleUser(extrasTemp)
                }
            }
        }
    }

    const deleteBookedUser = async () => {
        let res;
        if (classInfo.user_booked) {
            res = await dispatch(deleteScheduleUser(classInfo.user_booked, classInfo.id, SCHEDULE_ACTION, 'byDate', lateCancel));
        } else if(classInfo.user_in_standby){
            res = await dispatch(deleteStandBy(classInfo.user_in_standby, SCHEDULE_ACTION, 'byDate'));
        }

        if(res.error) {
            setShowError(true);
            setError(res.error)
        } else {
            dispatch(toggleScheduleSiteReFetch());
            setClose(res);
        }
    }

    const payAndInsert = async (purchaseData) => {
        setShowPaymentProcess(false);
        let tempExtras = extras;
        if(tempExtras === null){
            tempExtras = {};
        }
        await dispatch(emptyCart());
        tempExtras.purchaseData = purchaseData;
        tempExtras.payForSlot = true;
        setExtras(tempExtras)
        insertScheduleUser(tempExtras)
    }

    const updateSelectedMembership = (index, membershipId) => {
        setSelectedMembershipId(membershipId)
        setSelectedMembershipIndex(index)
        if(membershipsForDropdown[index].isService) {
            setIsMembershipService(true)
        } else {
            setIsMembershipService(false)
        }
    }

    const insertScheduleUser = async (extras = null) => {
        setFetching(true)
        setShowMembershipError(false);
        const messageId = uuid.v4()
        sendMessageToSentryBrowser("in-insertScheduleUser", JSON.stringify({user: userDataForLog,booking_option: classInfo.booking_option, classId: classInfo.id, selectedMembershipId, extras}), messageId)
        const res = await dispatch(insert(classInfo.booking_option, classInfo.id, selectedMembershipId, extras))
        sendMessageToSentryBrowser("in-insertScheduleUser-response", JSON.stringify(res), messageId)

        setFetching(false)
        if (res.id) {
            res.isBooked = true
            setClose(res);
        } else {
            setShowError(true);
            setError(res.error ?? t('common:errorHeader', {}))
        }
    }


    return (
        <>
        <PopupModal show={show} setClose={setClose} overrideStyles={[styles.modal, mobile && styles.mobileModal]} closeColor={Colors.lightBlack}>
            <Spinner visible={fetching}/>
            <View style={mobile && styles.wrapper}>
                <View style={[styles.header, {backgroundColor: classInfo.box_categories.category_color ? classInfo.box_categories.category_color : Colors.arboxBlue}, mobile && styles.headerMobile]}>
                    <AppText style={styles.headerTextBig}>{classInfo.box_categories.name}</AppText>
                    <AppText style={[styles.headerTextSmall, mobile && styles.headerTextSmallMobile]}>
                        {showBooked ? t(classInfo.user_in_standby ? 'screens:ScheduleSingle:inWaitList' : 'screens:Schedule:card:booked',{}) : classDate}
                        {showBooked && <Icons8Generator name={'check-circle'} size={SMALL_ICON_SIZE} fill={Colors.lightBlack}/>}
                    </AppText>
                </View>
                <View style={[mobile && styles.contentMobile, noMemberships && {height: 'auto'}]}>
                    {showCancellationPolicy ? <View style={[styles.content, {height: mobile ? 'calc(100% + 50px)': 300, marginBottom: mobile ? 0 : '2em'}]}><CancellationPolicy /></View>
                        :
                        <>
                            <View style={[styles.content]}>
                                {showBooked &&
                                <View style={styles.infoRow}>
                                    <Icons8Generator name={'clock'} size={MEDIUM_ICON_SIZE}/>
                                    <AppText
                                        style={[styles.contentText, mobile && styles.contentTextMobile]}>{classDate}</AppText>
                                </View>
                                }
                                {hasCoachRestriction(classInfo) &&
                                <View style={styles.infoRow}>
                                    <Icons8Generator name={'name-tag'} size={MEDIUM_ICON_SIZE}/>
                                    <AppText
                                        style={[styles.contentText, mobile && styles.contentTextMobile]}>{classInfo.coach?.full_name ? t('screens:Schedule:card:coach', {coach: classInfo.coach.full_name}) : '--'}</AppText>
                                </View>
                                }
                                <View style={styles.infoRow}>
                                    <Icons8Generator name={'location'} size={MEDIUM_ICON_SIZE}/>
                                    <AppText
                                        style={[styles.contentText, mobile && styles.contentTextMobile]}>{classInfo.locations_box.location}</AppText>
                                </View>
                                <View style={styles.infoRow}>
                                    <Icons8Generator name={'couple'} size={MEDIUM_ICON_SIZE}/>
                                    <AppText
                                        style={[styles.contentText, mobile && styles.contentTextMobile]}>{getCapacityTextByRestrictions(classInfo)}</AppText>
                                </View>
                                {classInfo.live_link &&
                                <View style={[styles.infoRow]}>
                                    <Icons8Generator name={'tv'} size={MEDIUM_ICON_SIZE}/>
                                    <View style={[showBooked ? styles.onlineClassBooked : null]}>
                                        <AppText
                                            style={[styles.contentText, mobile && styles.contentTextMobile]}>{t('screens:Schedule:card:online-class', {})}</AppText>
                                        {showBooked &&
                                        <TouchableOpacity onPress={() => Linking.openURL(classInfo.live_link)}>
                                            <AppText
                                                style={styles.openLink}>{t('screens:Schedule:live_link', {})}</AppText>
                                        </TouchableOpacity>
                                        }
                                    </View>
                                </View>
                                }
                                {classInfo.box_categories.bio &&
                                <View style={[styles.infoRow, styles.readMore]}>
                                    <Icons8Generator name={'info'} size={MEDIUM_ICON_SIZE}/>
                                    <ReadMore
                                        style={[styles.contentText, mobile && styles.contentTextMobile]}>{classInfo.box_categories.bio}</ReadMore>
                                </View>
                                }
                                {!showBooked && classInfo.box_categories.price > 0 &&
                                <View style={styles.infoRow}>
                                    <Icons8Generator name={'payCard'} size={MEDIUM_ICON_SIZE}/>
                                    <AppText
                                        style={[styles.contentText, mobile && styles.contentTextMobile]}>{location?.currency_symbol}{classInfo.box_categories.price}</AppText>
                                </View>
                                }
                            </View>
                            {!showBooked && isLoggedIn && membershipsForDropdown && isFuture &&
                            <View>
                                {!noMemberships && <AppText
                                    style={[styles.contentText, mobile && styles.contentTextMobile, {
                                        marginTop: '2em',
                                        marginBottom: '0.5em',
                                        paddingHorizontal: '2em'
                                    }]}>{t('modals:BookClass:chooseMembership', {})}</AppText>}
                                <View style={[!noMemberships && styles.membershipSection]}>
                                    {noMemberships ? <AppText
                                            style={[styles.contentText, mobile && styles.contentTextMobile, {
                                                marginVertical: '0.5em',
                                                paddingHorizontal: '2em'
                                            }]}>{t('modals:BookClass:no-memberships-found', {})}</AppText>
                                        :
                                        <MembershipList
                                            list={membershipsForDropdown}
                                            checkedIndex={selectedMembershipIndex}
                                            checkMembership={updateSelectedMembership}
                                            mobile={mobile}
                                        />
                                    }
                                </View>
                            </View>
                            }
                        </>
                    }
                </View>
                <View style={[styles.footerCta, showBooked ? styles.bookedFooterWrapper : null, mobile && styles.mobileFooter]}>
                    {
                        showBooked ?
                            <>
                                <TouchableOpacity style={styles.bookedFooterBtn} onPress={() => classInfo.user_in_standby ? deleteBookedUser(classInfo.user_booked, classInfo.id) : setShowConfirm(true)}>
                                    <Icons8Generator name={'trash'} width={'1.5em'} height={'1.5em'} fill={Colors.black}/>
                                    <AppText style={[styles.bookedFooterBtnText, {color: Colors.black}]}>{t(classInfo.user_in_standby ? 'modals:RemoveFromWaitlist:title' : 'modals:CancelClass:title', {})}</AppText>
                                </TouchableOpacity>
                            </>
                            :
                            <>
                                {!mobile && <ModalButton
                                    onPress={setClose}
                                    text={t('common:cancel', {})}
                                    textColor={Colors.arboxBlue}
                                    overrideStyle={[styles.footerBtn, styles.cancelBtn]}
                                    textStyles={{fontFamily: 'heebo', fontSize: '0.8rem'}}
                                />}
                                <ModalButton
                                    onPress={bookOrShowCancellation}
                                    text={t(showCancellationPolicy ? 'screens:Register:agree' : classInfo.booking_option === "insertStandby" ? 'modals:Card:add-to-waitlist' : 'modals:Card:book', {})}
                                    textColor={Colors.lightBlack}
                                    type='primary'
                                    colors={classInfo.box_categories.category_color ? classInfo.box_categories.category_color : Colors.arboxBlue}
                                    overrideStyle={[styles.footerBtn, styles.saveBtn, mobile && styles.mobileBookBtn, !isFuture && {opacity: '0.5'}]}
                                    textStyles={{fontFamily: 'heebo', fontSize: '0.8rem'}}
                                    disabled={!isFuture}
                                />
                            </>
                    }
                </View>
            </View>
        </PopupModal>
            {showError && error && <ErrorModal show={showError}
                                               setClose={() => setShowError(false)}
                                               title={t('common:toast:bookingFailed', {})}
                                               subHeader={Array.isArray(error.messageToUser) ? error.messageToUser[0].message : error.messageToUser}
            />}
            {showConfirm && <ConfirmModal show={showConfirm}
                                          setClose={() => setShowConfirm(false)}
                                          handleConfirm={() => deleteBookedUser(classInfo.user_booked, classInfo.id)}
                                          iconName={'calendar-delete'}
                                          title={t('modals:CancelClass:title', {})}
                                          subHeader={lateCancel ? t('modals:CancelClass:non-refundable', {}) : t('modals:CancelClass:are-you-sure-text', {})}
            />}
            {
                showPaymentProcess && <PaymentHandlerContainer show={showPaymentProcess} setClose={() => setShowPaymentProcess(false)} isPopup={true} onProcessSuccess={payAndInsert}/>
            }
        </>
    );
};

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingVertical: 0,
                paddingHorizontal: 0,
                width: '24em',
                height: 'fit-content',
                borderRadius: 10
            },
            mobileModal: {
                borderRadius: 0
            },
            wrapper: {
                flex: 1
            },
            header: {
                paddingTop: '2em',
                paddingBottom: '1em',
                alignItems: 'center',
                justifyContent: 'center',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10
            },
            headerMobile: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                height: '$bookingMobileHeader',
                alignItems: 'center',
                justifyContent: 'center',
            },
            content: {
                paddingTop: '2em',
                paddingHorizontal: '1.5em',
                gap: '1em',
            },
            headerTextBig: {
                color: Colors.lightBlack,
                fontSize: '1.1rem',
                fontWeight: 'bold',
                fontFamily: 'heebo-medium',
                marginBottom: '0.2em'
            },
            headerTextSmall: {
                color: Colors.lightBlack,
                fontSize: '0.9rem',
                fontWeight: 'bold',
                alignItems: 'center',
                display: 'flex',
                gap: '0.5em'
            },
            imgBg: {
                width: '4.2em',
                height: '4.2em',
                borderRadius: '50%',
                backgroundColor: Colors.white,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '0.8em'
            },
            coachImg: {
                backgroundColor: Colors.white,
                width: '3em',
                height: '3em',
            },
            contentText: {
                fontSize: '0.85rem',
                fontWeight: 300
            },
            contentTextMobile: {
                fontSize: '0.9rem',
            },
            infoRow: {
                flexDirection: 'row',
                gap: '1em',
                alignItems: 'center'
            },
            onlineClassBooked: {
                justifyContent: 'space-between',
                flexDirection: 'row',
                width: '100%',
                flexWrap: 'wrap',
                flexShrink: 1,
            },
            footerCta: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingVertical: '1em',
                paddingHorizontal: '1em',
                marginTop: '1em',
                borderTopWidth: 1,
                borderTopColor: Colors.brandedLightText
            },
            footerBtn: {
                minHeight: '2em',
                height: '2em',
                paddingVertical: '1em',
                paddingHorizontal: '2em',
                width: 'fit-content',
                marginTop: 0
            },
            footerBtnText: {
                fontFamily: 'heebo',
            },
            saveBtn: {
                borderRadius: 2,
            },
            bookedFooterWrapper: {
                justifyContent: 'space-evenly',
                alignItems: 'center',
                paddingVertical: '1.5em'
            },
            bookedFooterBtn: {
                gap: '0.5em',
                justifyContent: 'center',
                alignItems: 'center',
            },
            bookedFooterBtnText: {
                fontSize: '0.7rem',
                fontWeight: 300
            },
            openLink: {
                color: Colors.brandedBlue,
                fontSize: '0.8rem',
                fontWeight: 'bold'
            },
            mobileFooter: {
                position: 'fixed',
                bottom: 0,
                width: '100%',
                height: '$bookingMobileFooter',
                backgroundColor: Colors.white
            },
            mobileBookBtn: {
                width: '100%',
            },
            headerTextSmallMobile: {
                fontSize: '0.9rem',
            },
            readMore: {
                overflow: 'auto',
                maxHeight: 150,
            },
            membershipSection: {
                height: '12em',
                maxHeight: '12em',
                width: '100%',
                overflow: 'auto',
            },
            contentMobile: {
                height: () => `100% - ${EStyleSheet.value('$bookingMobileHeader') + EStyleSheet.value('$bookingMobileFooter')}`,
                maxHeight: () => `100% - ${EStyleSheet.value('$bookingMobileHeader') + EStyleSheet.value('$bookingMobileFooter')}`,
                justifyContent: 'space-between'
            }
        }
    })
})