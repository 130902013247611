import React from 'react';
import { TextInput, View, Text,StyleSheet } from 'react-native';
import {Colors} from "../../../styles/Colors";
import {t} from "../../../services/i18n";
import {isTextRTLStyles} from "../../../styles/Layout";
import i18n from "../../../services/i18n";

/**
 * to be wrapped with redux-form Field component
 */
export default function MyTextInput(props) {
    const { input, meta, ...inputProps } = props;

    // do not display warning if the field has not been touched or if it's currently being edited
    const validationStyles = meta.touched && meta.active
        ? styles.active : {}
    // const validationStyles = meta.touched && !meta.active
    //     ? meta.valid ? styles.valid : styles.invalid
    //     : null;
    return (
        <View style={[styles.inputContainer,validationStyles]}>
            <TextInput
                {...inputProps}
                onChangeText={input.onChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
                ref={input.ref}
                value={input.value}
                style={styles.input}
                placeholderTextColor={Colors.white}
            />
        </View>
    );
}


const styles = StyleSheet.create({
    input: {
        minHeight: 30,
        padding: 5,
        fontSize:18,
        color: Colors.white,
        textAlign: i18n.isRTL ? 'right' : 'left'
    },
    inputContainer: {
        borderBottomWidth: 2,
        borderColor: 'rgba(255,255,255,0.6)',
        marginTop: 20
    },
    valid: {
        // borderColor: Colors.green
    },
    active: {
        borderColor : Colors.white,

    },
    invalid: {
        // borderColor: Colors.redPinkColor
    },
    onFocus: {
        // borderColor: Colors.paleblue,
    }
});
