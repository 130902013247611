import {
    ADD_TO_CART,
    CURRENCY_SYMBOL,
    EMPTY_CART,
    PATCH_MEMBERSHIP_START_DATE,
    REMOVE_FROM_CART,
    SHOP, SUB_QUANTITY,
    TAXES, SET_SHOP_LOCATIONS_BOX, VALIDATE_PROMO_CODE
} from '../constants';
import {MEMBERSHIP_TYPES_CONST} from "../constants/membershipTypes";
import {compareTaxesBetweenMemberships} from "../helpers/functions";

const initialState = {
    isLoading: false,
    addingToCart: false,
    cart: [],
    items: {},
    error: null,
    currencySymbol: null,
    boxesId: null,
    locationsBoxId: null,
    activePromoCode: null
};

const shopReducer = (state = initialState, action) => {
    const shop = SHOP.toUpperCase(); // just MAKE SURE IT IS UPPER
    const addToCart = ADD_TO_CART.toUpperCase();
    const subQuantity = SUB_QUANTITY.toUpperCase();
    const removeFromCart = REMOVE_FROM_CART.toUpperCase();
    const currencySymbol = CURRENCY_SYMBOL.toUpperCase();
    const patchMembershipStartDate = PATCH_MEMBERSHIP_START_DATE.toUpperCase();
    const emptyCart = EMPTY_CART.toUpperCase();
    const setShopLocationsBox = SET_SHOP_LOCATIONS_BOX.toUpperCase();
    const validatePromoCode = VALIDATE_PROMO_CODE.toUpperCase();
    switch (action.type) {
        case `${shop}_PENDING`:
            return {
                ...state,
                isLoading: true,
            };
        case `${shop}_SUCCESS`: {
            return {
                ...state,
                isLoading: false,
                items: action.payload.data
            };
        }
        case `${shop}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case `${addToCart}_PENDING`: {
            //ALWAYS ADD QUANTITY
            if(state.cart.length > 0){
                const membership = state.cart[0];
                const currentMembership = action.payload;
                const isIdentical = compareTaxesBetweenMemberships(membership,currentMembership);
                if(!isIdentical){
                    throw 'taxes are different';
                }
            }
            return {
                ...state,
                addingToCart: true,
            };
        }
        case `${addToCart}_SUCCESS`: {
            //ALWAYS ADD QUANTITY
            action.payload.quantity = action.payload.quantity + 1;
            const exists = state.cart.find(item => item.id === action.payload.id);
            if (exists) {
                //TODO if exists we dont readd to cart, we add quantity.
                return {
                    ...state,
                    addingToCart: false,
                    cart: [...state.cart.map(item => item.id === action.payload.id ? {...action.payload} : {...item})]
                };
            }
            return {
                ...state,
                addingToCart: false,
                cart: [...state.cart, action.payload]
            };
        }
        case `${subQuantity}_SUCCESS`: {
            action.payload.quantity = action.payload.quantity - 1;
            const newCart = [...state.cart.map(item => item.id === action.payload.id ? {...action.payload} : {...item})]
            return {
                ...state,
                addingToCart: false,
                cart: newCart,
                activePromoCode: newCart.length && state.activePromoCode ? {...state.activePromoCode} : null
            };

        }
        case `${removeFromCart}_SUCCESS`: {
            const newCart = [...state.cart.filter(item => item.id !== action.payload.id)];
            return {
                ...state,
                cart: newCart,
                activePromoCode: newCart.length && state.activePromoCode ? {...state.activePromoCode} : null
            }
        }

        case `${currencySymbol}_SUCCESS`: {
            return {
                ...state,
                currencySymbol: action.payload
            }
        }
        case `${emptyCart}_SUCCESS`: {
            return {
                ...state,
                cart: [],
                activePromoCode: null
            }
        }
        case `${patchMembershipStartDate}_SUCCESS`: {
            return {
                ...state,
                cart: [...state.cart.map(item => item.id === action.payload.id ? {...item, ...action.payload} : {...item})],
                items: {
                    [MEMBERSHIP_TYPES_CONST.TYPE_ITEM]: [...state.items[MEMBERSHIP_TYPES_CONST.TYPE_ITEM]],
                    [MEMBERSHIP_TYPES_CONST.TYPE_SESSION]: [...state.items[MEMBERSHIP_TYPES_CONST.TYPE_SESSION].map(item => item.id === action.payload.id ? {...item, ...action.payload} : {...item})],
                    [MEMBERSHIP_TYPES_CONST.TYPE_PLAN]: [...state.items[MEMBERSHIP_TYPES_CONST.TYPE_PLAN].map(item => {
                        if (item.id === action.payload.id) {
                            return {...item, ...action.payload};
                        }
                        return {...item}
                    })],
                }
            }
        }
        case `${setShopLocationsBox}_SUCCESS`: {
            return {
                ...state,
                boxesId: action.payload.boxesId,
                locationsBoxId: action.payload.locationsBoxId,
            }
        }
        case `${validatePromoCode}_SUCCESS`: {
            return {
                ...state,
                isLoading: false,
                cart: JSON.parse(JSON.stringify(action.payload.cart)),
                activePromoCode: action.payload
            };
        }
        case `${validatePromoCode}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default shopReducer;