// @flow
import * as React from 'react';
import {Button, TouchableOpacity, View} from "react-native-web";
import {AppText} from "../../Stateless/AppText";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {useSelector} from "react-redux";
import {isMobile} from "../../../helpers/functions";
import {scale, verticalScale} from "react-native-size-matters";

const GroupButton = (props) => {
    const {buttons, selectedIndex, onSelectChanged, nameLabel} = props;
    const mobile = isMobile();

    return (
        <View>
            <AppText style={[styles(mobile).label]}>{nameLabel}</AppText>
            <View style={[styles().wrapper, props.style]}>
            {
                buttons.map((btn, index) => (
                    <TouchableOpacity onPress={() => onSelectChanged(index)} style={[styles().button, index !== 0 && styles().middleButton, (index === selectedIndex) && styles().selectedButton]} key={`group-btn-${btn}`}>
                        <AppText style={[styles().buttonText, (index === selectedIndex) && styles().selectedButtonText]}>{btn}</AppText>
                    </TouchableOpacity>
                ))
            }
            </View>
        </View>

    );
};


export default GroupButton;

const styles = (mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                flexDirection: 'row',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: Colors.lightBlack,
                borderWidth: 1,
                borderRadius: 5
            },
            button: {
                borderColor: Colors.lightBlack,
                flex: 1,
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
            },
            buttonText: {
                color: Colors.lightBlack,
                fontSize: '0.85rem',
            },
            middleButton: {
                borderColor: Colors.lightBlack,
                borderLeftWidth: 1,
            },
            selectedButton: {
                backgroundColor: Colors.lightBlack
            },
            selectedButtonText: {
                color: Colors.white,
                fontSize: '0.95rem',
                fontWeight: 'bold'
            },
            label: {
                fontSize: mobile ? scale(12) : '0.8rem',
                color: Colors.labelColor,
                marginBottom: mobile ? verticalScale(5) : 0
            },
        }
    })
})