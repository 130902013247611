import {
    FEED,
    REMOVE_FEED,
    MESSAGES_CENTER_COUNT, FEED_SCHEDULE_ACTION, REMOVE_ACTION_FROM_FEED, REMOVE_MESSAGES_CENTER_FROM_FEED
} from '../constants';
import omit from "lodash/omit";
import ACTIONS from "../components/FeedCard/constants";


const initialState = {
    isLoading: false,
    removingFeed: false,
    removeRef: null,
    data: {},
    error: null,
    messagesCount: 0
};

export default function homeReducer(state = initialState, action) {
    const feed = FEED.toUpperCase(); // just MAKE SURE IT IS UPPER
    const removeFeed = REMOVE_FEED.toUpperCase(); // just MAKE SURE IT IS UPPER
    const messagesCenterCount = MESSAGES_CENTER_COUNT.toUpperCase(); // just MAKE SURE IT IS UPPER
    const feedSchedule = FEED_SCHEDULE_ACTION.toUpperCase(); // just MAKE SURE IT IS UPPER
    const removeActionFromFeeds = REMOVE_ACTION_FROM_FEED.toUpperCase(); // just MAKE SURE IT IS UPPER
    const removeMessagesCenterFromFeed = REMOVE_MESSAGES_CENTER_FROM_FEED.toUpperCase(); // just MAKE SURE IT IS UPPER
    switch (action.type) {
        case `${feed}_PENDING`:
            return {
                ...state,
                isLoading: true
            };
        case `${feed}_SUCCESS`:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case `${feed}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                data: {}
            };
        case `${messagesCenterCount}_SUCCESS`:
            return {
                ...state,
                messagesCount: action.payload,
            };
        case `${messagesCenterCount}_FAILED`:
            return {
                ...state,
                messagesCount: 0,
            };
        case `${removeFeed}_PENDING`:
            return {
                ...state,
                removingFeed: true,
                //TODO REMOVE REF
                removeRef: action.payload
            };
        case `${removeFeed}_SUCCESS`: {
            return {
                ...state,
                removingFeed: false,
                data: Object.keys(state.data).reduce((obj, key) => {
                    if (Array.isArray(state.data[key])) {
                        obj[key] = state.data[key].filter((item => item.id !== action.payload))
                    } else if (state.data[key].id !== action.payload) {
                        obj[key] = {...state.data[key]}
                    }
                    return obj;
                }, {})
            };
        }
        case `${removeMessagesCenterFromFeed}_SUCCESS`: {
            return {
                ...state,
                removingFeed: false,
                data: Object.keys(state.data).reduce((obj, key) => {
                    if (Array.isArray(state.data[key])) {
                        obj[key] = state.data[key].filter((item => {
                                if (item.action === ACTIONS.ACTION_BOX_MESSAGE) {
                                    if (item.id !== action.payload)
                                        return item;
                                } else {
                                    return item;
                                }
                            }
                        ))
                    } else if (state.data[key].id !== action.payload) {
                        obj[key] = {...state.data[key]}
                    }
                    return obj;
                }, {})
            };
        }
        case `${removeFeed}_FAILED`:
            return {
                ...state,
                removingFeed: false,
            };

        case `${removeActionFromFeeds}_SUCCESS`:
            return {
                ...state,
                data: omit(state.data, action.payload)
            };

        default:
            return state;
    }
};
