import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8TvShow(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M0 7v32h50V7zm2 2h46v28H2zm18.625 7.594a.647.647 0 00-.625.656v12.719c0 .226.117.449.313.562a.578.578 0 00.312.063c.11 0 .246-.004.344-.063l11-6.375a.654.654 0 00.312-.562.603.603 0 00-.312-.532l-11-6.375a.768.768 0 00-.344-.093zM10.812 41c-.55.05-.957.543-.906 1.094.051.55.543.957 1.094.906h28c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496H10.812z" />
    </Svg>
  );
}

export default SvgIcons8TvShow;
