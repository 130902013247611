import {globalStyles} from "../../styles/Global";
import {scale, verticalScale} from "react-native-size-matters";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {Colors} from "../../styles/Colors";
import {AppText} from "../Stateless/AppText";
import React from "react";
import UserAvatar from 'react-native-user-avatar';
import {capitalize, isWeb, shortenName} from "../../helpers/functions";
import NavigationService from "../../helpers/NavigationService";
import date from "../../services/i18n/date";
import {t} from "../../services/i18n";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {DesktopAvatarImg} from "../Web/Common/DesktopAvatarImg";
import {COACH_AVAILABILITY_TYPE, SPACE_AVAILABILITY_TYPE} from "../../constants";

export const AvailabilityCard = props => {
    const {item,coachCount, handlePress, spaceCount, availabilityType} = props;
    const name = availabilityType === COACH_AVAILABILITY_TYPE ? item.coach.first_name + ' ' + item.coach.last_name : item.space.name;
    return (
        <TouchableOpacity
            onPress={handlePress}
            style={[globalStyles.flexDirectionRow, globalStyles.appMinimalPaddingHorizontal, {marginBottom: verticalScale(10)}]}>
            <View style={[globalStyles.cardsShadow,
                {
                    width: '100%',
                    backgroundColor: Colors.white,
                    borderRadius: 5,
                    elevation: 5,
                    height: 55,
                }, globalStyles.centerItems]}>
                <View style={[(coachCount > 1 || spaceCount > 1) ? globalStyles.flexDirectionRow : globalStyles.flexDirectionRowSpaceAround, {width: '100%'}]}>
                    <AppText style={[globalStyles.heeboRegular, globalStyles.appPaddingHorizontal, styles.text]}> {date.timeFormat(item.time, 'LT', false, false)}</AppText>
                    {(coachCount > 1 || spaceCount > 1) ? <React.Fragment>
                        <View style={{
                            borderStartColor: Colors.graySeparator,
                            borderStartWidth: 2,
                            paddingEnd: scale(20)
                        }}/>
                        {availabilityType === COACH_AVAILABILITY_TYPE &&
                        <View style={[globalStyles.flexDirectionRow, {maxWidth: '45%'}]}>
                            {isWeb() ?
                                <DesktopAvatarImg imgSrc={item.coach.image} size={scale(25)} credentials={capitalize(shortenName(item.coach.first_name)) + ' ' + capitalize(shortenName(item.coach.last_name))}/>
                                :
                                <UserAvatar
                                    size={scale(25)}
                                    name={name}
                                    src={item.coach.image}
                                    rounded={true}
                                />
                            }
                            <AppText numberOfLines={1} style={[styles.text, globalStyles.appMinimalPaddingHorizontal]}>{capitalize(item.coach.first_name)} {capitalize(shortenName(item.coach.last_name))}</AppText>
                        </View>}
                        { availabilityType === SPACE_AVAILABILITY_TYPE && spaceCount > 1 &&
                            <AppText numberOfLines={1} style={[styles.text, globalStyles.appMinimalPaddingHorizontal]}>{name}</AppText>
                        }
                    </React.Fragment> : null}
                    {item.user_booked ? (
                        <View style={[{position:'absolute',end:0},globalStyles.itemToFlexEnd,globalStyles.appMinimalPaddingHorizontal]}>
                            <Icons8Generator name={'check-circle'} fill={Colors.green}/>
                        </View>
                    ) : null}
                </View>
            </View>

        </TouchableOpacity>
    )
}


const styles = StyleSheet.create({
    text: {
        fontSize: scale(16),
        minWidth: scale(120)
    }
});
