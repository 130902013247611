// @flow
import * as React from 'react';
import {Image, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {globalStyles} from "../../../../styles/Global";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import ModalButton from "../../../ModalButton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {calcCartItems, isLocationIL, isMobile} from "../../../../helpers/functions";
import LoginOrRegisterWrapper from "../../Registration/LoginOrRegisterWrapper";
import PromoCodeField from "./PromoCodeField";
import moment from "moment/moment";
import Toast, {Positions} from "react-native-root-toast";
import {removeFromCart} from "../../../../actions/shop";

const CartSummary = (props) => {
    const {goToCheckout, isInPaymentProcess} = props;
    const cart = useSelector(state => state.shop.cart)
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const userCreatedTimePeriod = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.userCreatedTimePeriod)
    const activePromoCode = useSelector(state => state.shop.activePromoCode)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)

    const [calcNoRecurring, setCalcNoRecurring] = useState(null);
    const [calcWithRecurring, setCalcWithRecurring] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const mobile = isMobile()
    const isILBox = isLocationIL()
    const dispatch = useDispatch()

    useEffect(() => {
        if(cart.length) {
            const withRecurring = calcCartItems(cart,false)
            const onlyRecurring = calcCartItems(cart,true)
            setCalcNoRecurring({
                total_price: withRecurring.total_price - onlyRecurring.total_price,
                total_tax: withRecurring.total_tax - onlyRecurring.total_tax,
                sub_total: withRecurring.sub_total - onlyRecurring.sub_total})
            setCalcWithRecurring(withRecurring)
        } else {
            setCalcNoRecurring({total_price: 0, total_tax: 0, sub_total: 0})
            setCalcWithRecurring({total_price: 0, total_tax: 0, sub_total: 0})
        }
    }, [cart]);


    const getItemRow = () => {
        return cart.map(item => (
            <View style={[globalStyles.flexDirectionRowSpaceBetween]} key={`cart-summery-item-${item.id}`}>
                <View style={[styles.itemInfo]}>
                    {item.image ?
                        <Image style={[styles.itemImg]} source={item.image} alt=""/>
                        :
                        <View style={[styles.itemImg]}></View>
                    }
                    <AppText style={[styles.smallText, styles.textOverflow]}>{item.name}</AppText>
                </View>
                <AppText style={[styles.smallText]}>{currencySymbol}{typeof item.price_after_promo_code === 'object' ? item.price : item.price_after_promo_code ?? item.price} x {item.quantity}</AppText>
            </View>
        ))
    }

    const handleCheckout = () => {
        handlePromoCodeWithMultipleLocations()
        if(isLoggedIn) {
            if(moment(userCreatedTimePeriod) > moment()) {
                Toast.show(t('screens:Purchase:user-flag-msg', {}), {
                    backgroundColor: Colors.brandWarning,
                    textColor: Colors.lightBlack,
                    position: Toast.positions.CENTER,
                    duration: 10000,
                    hideOnPress: true
                });
            }
            setShowLogin(false)
            goToCheckout()
        } else {
            setShowLogin(true)
        }
    }

    const handlePromoCodeWithMultipleLocations = () => {
        if(activePromoCode && box.locations_box.length > 1) {
            const productFromDifferentLocation = cart.filter(item => item.location_box_fk !== activePromoCode.promoCode.location_id)
            if(productFromDifferentLocation.length > 0) {
                productFromDifferentLocation.map(item => dispatch(removeFromCart(item)))
                const promoCodeLocation = box.locations_box.find(item => item.id == activePromoCode.promoCode.location_id)
                Toast.show(t('screens:Purchase:promo-location-flag-msg', {promoCodeLocationName: promoCodeLocation.location}), {
                    backgroundColor: Colors.brandWarning,
                    textColor: Colors.lightBlack,
                    position: Toast.positions.BOTTOM,
                    duration: 10000,
                    hideOnPress: true
                });
            }
        }
    }

    return (
        <>
            <View style={[!mobile && globalStyles.cardsShadow, styles.wrapper, mobile && styles.mobileWrapper]}>
                {!mobile && <AppText style={[{fontSize: '1.1rem', fontWeight: 'bold'}]}>{t('screens:Purchase:cart-summary',{})}</AppText>}
                {isInPaymentProcess && <View style={[{paddingVertical: '1em', borderBottomWidth: 1, borderBottomColor: Colors.graySeparator, gap: '0.5em'}]}>{getItemRow()}</View>}
                {!isInPaymentProcess &&
                    <View style={[styles.promoCodeWrapper, mobile && styles.promoCodeWrapperMobile]}>
                        <PromoCodeField/>
                    </View>
                }
                <View style={[styles.infoWrap]}>
                    {!isILBox &&
                        <View style={[styles.info]}>
                            <AppText style={[styles.smallText]}>{t('screens:Purchase:subTotal',{})}</AppText>
                            <AppText style={[styles.smallText]}>{currencySymbol}{calcNoRecurring?.sub_total}</AppText>
                        </View>
                    }
                    {calcNoRecurring && calcNoRecurring.total_tax > 0 &&
                        <View style={[styles.info]}>
                            <AppText style={[styles.smallText]}>{t('screens:Purchase:tax',{})}</AppText>
                            <AppText style={[styles.smallText]}>{currencySymbol}{calcNoRecurring?.total_tax}</AppText>
                        </View>
                    }
                    <View style={[styles.info]}>
                        <AppText style={[styles.bigText]}>{t('screens:Purchase:total',{})}</AppText>
                        <View style={{flexDirection: 'row', alignItems: 'center', gap: '0.3em'}}>
                            <AppText style={[styles.bigText]}>{currencySymbol}{calcWithRecurring?.total_price}</AppText>
                            {calcWithRecurring?.total_price !== calcNoRecurring?.total_price && <AppText style={[styles.smallText]}>({t('screens:Purchase:payNow', {price: `${currencySymbol} ${calcNoRecurring?.total_price}`})})</AppText>}
                        </View>
                    </View>
                </View>
                {calcWithRecurring?.total_price !== calcNoRecurring?.total_price && <AppText style={[styles.smallText, {marginTop: '1em'}]}>{t('screens:Cart:recurringNote', {amount: currencySymbol + (calcWithRecurring.total_price - calcNoRecurring?.total_price).toFixed(2)})}</AppText>}                <View style={[styles.actions]}>
                    {!isInPaymentProcess &&
                    <ModalButton
                        onPress={handleCheckout}
                        disabled={cart.length === 0}
                        text={t('screens:Cart:checkout', {})}
                        type='primary'
                        colors={Colors.lightBlack}
                        textColor={Colors.white}
                        overrideStyle={[globalStyles.cardsShadow, styles.checkoutBtn]}
                        textStyles={{'fontFamily': 'heebo', fontSize: '0.9rem'}}
                    />}
                    <Link to={{pathname: '/shop', search:boxQueryString || location.search}} style={{textDecoration: 'none', textAlign: 'center', marginTop: '1em'}}>
                        <AppText style={[styles.continueBtn, {color: Colors.lightBlack, fontWeight: 'bold'}]}>{t('screens:Cart:continueShopping', {})}</AppText>
                    </Link>
                </View>
            </View>
            <LoginOrRegisterWrapper gotoFullProcess={true} isAppointment={false} show={showLogin} setClose={() => setShowLogin(false)}/>
        </>
);
};

export default CartSummary;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                padding: '1em',
                backgroundColor: Colors.white,
                width: '20em',
                height: 'fit-content'
            },
            mobileWrapper: {
                width: '100%',
                backgroundColor: 'none',
                padding: 0,
                height: '100%',
                justifyContent: 'space-between'
            },
            infoWrap: {
                gap: '0.5em',
            },
            info: {
                flexDirection: 'row',
                justifyContent: 'space-between'
            },
            smallText: {
                fontSize: '0.85rem',
                color: Colors.grayText
            },
            bigText: {
                fontSize: '0.95rem',
                color: Colors.lightBlack,
                fontWeight: 'bold'
            },
            actions: {
                marginTop: '1em'
            },
            checkoutBtn: {
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 3,
                minHeight: 'fit-content',
                paddingVertical: '0.5em',
                marginTop: 0
            },
            continueBtn: {
                fontSize: '0.9rem',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            },
            itemImg: {
                backgroundColor: Colors.graySeparator,
                borderRadius: '50%',
                width: '1.5em',
                height: '1.5em'
            },
            textOverflow: {
                maxWidth: '11em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            itemInfo: {
                flexDirection: 'row',
                gap: '1em'
            },
            promoCodeWrapper: {
                borderBottomColor: Colors.graySeparator,
                borderTopColor: Colors.graySeparator,
                borderBottomWidth: 1,
                borderTopWidth: 1,
                paddingVertical: 15,
                marginVertical: 15
            },
            promoCodeWrapperMobile: {
                marginVertical: 0,
                marginBottom: 10
            }
        }
    })
})