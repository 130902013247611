import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8ResumeWebsite(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M4.846 6C3.27 6 2 7.32 2 8.893V43a1 1 0 001 1h44a1 1 0 001-1V8.893C48 7.32 46.729 6 45.154 6H4.846zm0 2h40.308c.466 0 .846.375.846.893V18H4V8.893C4 8.375 4.38 8 4.846 8zM19 10v6h24v-6H19zM8 11a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zm6 0a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zm7 1h20v2H21v-2zM4 20h42v22H4V20zm9.97 4c-1.69 0-2.6.87-2.876 2.125A4.168 4.168 0 0011 27c-.003.508.102 1.052.242 1.61-.109.067-.287.284-.242.671.084.72.37.902.55.918.07.671.705 1.468.936 1.592 0 .479.017.844-.029 1.37-.553 1.533-4.285 1.104-4.457 4.062V38h12v-.777c-.172-2.958-3.888-2.529-4.441-4.063-.046-.525-.03-.89-.03-1.369.231-.125.85-.92.92-1.592.182-.016.469-.198.553-.918.045-.387-.135-.603-.244-.672.11-.344.242-.96.242-1.609 0-.298-.02-.595-.076-.875-.155-.777-.57-1.419-1.506-1.553-.21-.38-.75-.572-1.447-.572zM26 26v2h16v-2H26zm0 5v2h16v-2H26zm0 5v2h13v-2H26z" />
    </Svg>
  );
}

export default SvgIcons8ResumeWebsite;
