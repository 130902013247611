import {apiAction} from '../helpers/HTTP';
import {
    FRIEND, ACTIVE_FRIEND_ID, ADD_FRIEND_TO_USER
} from '../constants';


export const fetchFriendProfile = (id = null) => async (dispatch) => {
    const actionType = FRIEND.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`user/profile/${id}`, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data,id,ref_id: id});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const friendRelationAction = (actionType,id,action,ref_id) => async (dispatch) => {
    try {
        dispatch({type: `${actionType}_PENDING`});
        const profile = actionType === 'FRIEND_FRIENDSHIP_STATUS';
        const response = await apiAction('friendConnection', 'post', {id,action,profile});
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data,id,ref_id});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const setActiveFriendId = (id) => async(dispatch) => {
    try {
        dispatch({type: `${ACTIVE_FRIEND_ID}_SUCCESS`, payload: {id}});
    } catch (error) {
    }
}