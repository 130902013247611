import React, {useEffect, useState} from 'react';
import {View} from "react-native-web";
import {apiAction} from "../../../helpers/HTTP";
import {CONST} from "../../../constants/Forms"
import {AppText} from "../../Stateless/AppText";
import Spinner from "react-native-loading-spinner-overlay";
import {containsHTML, isMobile} from "../../../helpers/functions";
import {WebView} from "react-native-webview";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform, TouchableOpacity} from "react-native";
import {Colors} from "../../../styles/Colors";
import ModalButton from "../../ModalButton";
import {t} from "../../../services/i18n";
import FormQuestion from "./FormQuestion";
import SignatureHandler from "./SignatureHandler";
import {useSelector} from "react-redux";
import {scale} from "react-native-size-matters";

const FormHandler = (props) => {
    const {submitReplacement, onLater, onBack, returnOnNoForm} = props;
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const [activePropsArr, setActivePropsArr] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [digitalForm, setDigitalForm] = useState(null);
    const [form, setForm] = useState(null);
    const [activeProperty, setActiveProperty] = useState(null);
    const [numOfQuestions, setNumOfQuestions] = useState(null);
    const [stepComponent, setStepComponent] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [signature, setSignature] = useState(null);
    const [showRequiredMsg, setShowRequiredMsg] = useState(false);
    const mobile = isMobile();

    useEffect(() => {
        getForm();
    }, []);

    useEffect(() => {
        if(activeProperty) {
            setStepComponent(renderActiveProperty())
        }
    }, [activeProperty]);

    useEffect(() => {
        setStepComponent(renderQuestions())
    }, [questionIndex]);

    const getForm = async () => {
        const response = await apiAction(`boxDigitalForm/${box.id}`, 'get', null);
        if(response) {
            buildFormProps(response);
        } else {
            returnOnNoForm();
        }
    }

    const buildFormProps = (formObj) => {
        let arr = ['landing'];
        const builder = formObj.digital_form_builder;
        const questions = builder.find((item => item.property_type === CONST.TYPE_QUESTIONS));
        const signature_options = formObj.digital_form.signature_options !== 0;

        if (questions)
            arr.push(CONST.TYPE_QUESTIONS);
        if (signature_options)
            arr.push(CONST.TYPE_SIGN_OPTIONS);
        arr.push('summary');

        setActivePropsArr(arr)
        setDigitalForm(formObj.digital_form)
        setForm({questions: questions ? [...questions.digital_form_question] : null})
        setActiveProperty(arr[0])
        setNumOfQuestions(questions ? questions.digital_form_question.length : null)
        setIsLoading(false)

    }

    const renderActiveProperty = () => {
        switch (activeProperty) {
            case 'landing': {
                return renderLanding();
            }
            case CONST.TYPE_QUESTIONS: {
                return renderQuestions();
            }
            case CONST.TYPE_SIGN_OPTIONS: {
                return renderSignature();
            }
            case 'summary': {
                return renderSummary();
            }
            default:
                break;
        }
    }

    const renderLanding = () => {
        return (
            <>
            {
                containsHTML(digitalForm.content) ?
                        <WebView
                            source={{
                                html: `
                            <html>
                                <style type="text/css">${iFrameHtmlStyles(digitalForm.display_direction)}</style>
                                <body>
                                    ${digitalForm.content}
                                </body>
                            </html>`
                            }}
                        />
                    :
                    <AppText>{digitalForm.content}</AppText>
            }
            </>
        )
    }


    const renderQuestions = () => {
        if(numOfQuestions) {
            const question = form.questions[questionIndex];
            const onAnswer = (answer) => {
                setForm((prevState) => ({
                    ...prevState,
                    ...prevState.questions,
                    [questionIndex]: question
                }));
            }
            return (
                <View style={[{height: '100%'}]} key={`very-important-key-for-rerender-${questionIndex}`}>
                    <View style={[styles.question]}><AppText style={[{fontSize: '1.3rem'}]}>{question.question}</AppText></View>
                    <FormQuestion question={question} onSelect={onAnswer}/>
                </View>
            )
        }
    }

    const renderSignature = () => {
        return <SignatureHandler digitalForm={digitalForm} onSave={(res) => setSignature(res)}/>
    }


    const renderSummary = () => {

    }

    const handleNextSection = () => {
        const index = activePropsArr.indexOf(activeProperty);
        if(index + 1 < activePropsArr.length) {
            if(activePropsArr[index + 1] === 'summary') {
                handleFormSubmit()
            } else {
                setActiveProperty(activePropsArr[index + 1]);
            }
        } else {
            //last section
            //this option is not being used at this moment (last step is summary which we are not getting to in create user process)
        }
    }

    const handleFormSubmit = () => {
        if(digitalForm.signature_options === 3 && signature.signature && signature.agree) {
            submit(signature.signature);
        } else if(digitalForm.signature_options === 1 && signature.signature) {
            submit();
        } else if(digitalForm.signature_options === 2 && signature.agree) {
            submit();
        } else if(digitalForm.signature_options === 0) {
            submit();
        }
    }

    const submit = (signature = null) => {
        const tempForm = form
        if(signature) {
            tempForm.signature_data = signature;
        }
        const params = {
            form: tempForm,
            digital_form: digitalForm
        }
        if(submitReplacement) {
            submitReplacement(params)
        } else {
            //this option is not being used at this moment (this flow is being used only on create user flow)
            // const response = await apiAction('digitalForm', 'post', params);
        }
    }

    const handleNext = () => {
        const nextQuestion = () => {
            if(questionIndex + 1 === numOfQuestions) {
                handleNextSection()
            } else {
                setQuestionIndex(questionIndex + 1)
            }
        }

        if(activeProperty === CONST.TYPE_QUESTIONS) {
            const question = form.questions[questionIndex];
            if(question.required === 1) {
                setShowRequiredMsg(false);
                if (question.type === 'questionMultipleChoice') {
                    if((question.multi_choice && question.checked_array.length > 0) || (!question.multi_choice && question.user_answer)) {
                        nextQuestion()
                    }
                } else if (question.user_answer !== undefined) {
                    nextQuestion()
                } else {
                    setShowRequiredMsg(true);
                }
            } else {
                nextQuestion()
            }
        } else {
            handleNextSection()
        }
    }

    const handlePrev = () => {
        if(activeProperty === CONST.TYPE_QUESTIONS) {
            if(questionIndex === 0) {
                handlePrevSection();
            } else {
                setQuestionIndex(questionIndex - 1)
            }
        } else {
            handlePrevSection();
        }
    }

    const handlePrevSection = () => {
        const index = activePropsArr.indexOf(activeProperty);
        if(activeProperty === 'landing') {
            onBack()
        } else {
            setActiveProperty(activePropsArr[index - 1])
        }
    }

    const iFrameHtmlStyles = (direction) => {
        return `
            body { 
                font-family: 'Helvetica';
                font-weight: 100;
                direction: ${direction};
                margin-bottom: ${mobile ? '10px' : '1em'};
                line-height: 1.5;
                overflow-x: hidden;
                word-break: break-word;
            }
            b {
                font-size: ${mobile ? '15px' : '0.9rem'} !important;
            }
            p {
                font-size: ${mobile ? '15px' : '0.9rem'} !important;
            }
            span {
                font-size: ${mobile ? '15px' : '0.9rem'} !important;
            }
            h1 {
                font-size: ${mobile ? '19px' : '1.25rem'} !important;
            }
            h2 {
                font-size: ${mobile ? '18px' : '1.15rem'} !important;
            }
            h3 {
                font-size: ${mobile ? '17px' : '1rem'} !important;
            }
            strong {
                font-size: ${mobile ? '19px' : '1rem'} !important;
            }
            li {
                font-size: ${mobile ? '15px' : '0.9rem'} !important;
            }
            br {
                content: "";
            }
        `;
    }

    return (
        <View style={[{height: mobile ? 'inherit' : '100%'}]}>
            {isLoading ?
                <Spinner visible={true}/>
                :
                <View style={[mobile ? styles.formWrapperMobile : styles.formWrapper]}>
                    <View style={[styles.stepWrapper, mobile && styles.stepWrapperMobile, mobile && {height: 'calc(100% - 60px)'}]}>
                        <View style={[styles.headerSection]}>
                            <AppText style={[styles.headerText]}>{digitalForm.name}</AppText>
                            {activeProperty === 'landing' && numOfQuestions != null && numOfQuestions > 0 && <AppText style={[styles.subHeader]}>{t('screens:DigitalForms:numOfQuestions',{num:numOfQuestions})}</AppText>}
                            {activeProperty === CONST.TYPE_QUESTIONS && <AppText style={[styles.subHeader]}>{t('screens:DigitalForms:questionHeader',{num: questionIndex + 1, size:numOfQuestions})}</AppText>}
                            {activeProperty === CONST.TYPE_SIGN_OPTIONS && <AppText style={[styles.subHeader]}>{t('screens:DigitalForms:signatureStepSubheader',{})}</AppText>}
                        </View>
                        <View style={[{height: 'calc(100% - 70px)'}]}>
                            {stepComponent}
                        </View>
                        {showRequiredMsg && <AppText style={[{fontSize: '0.8rem', color: Colors.redPinkColor}]}>{t('screens:Register:fieldError',{fieldName: t('screens:DigitalForms:this-question', {})})}</AppText>}
                    </View>
                    <View style={[styles.buttonsRow, activeProperty === 'landing' && mobile && {justifyContent: 'end'}]}>
                        {((onBack && activeProperty === 'landing' && !mobile) || activeProperty !== 'landing') &&
                            <ModalButton onPress={(onBack && activeProperty === 'landing') ? onBack : handlePrev}
                                      type='primary'
                                      colors={Colors.white}
                                      text={t('common:back', {})}
                                      textColor={Colors.lightBlack}
                                      overrideStyle={EStyleSheet.flatten([styles.primaryBtn, styles.backBtn])}
                                      textStyles={{fontWeight: 'normal', fontFamily: 'heebo'}}/>
                        }
                        <View style={[styles.buttonsRow, {width: 'unset'}]}>
                            {/*{activeProperty === 'landing' && <TouchableOpacity onPress={onLater}><AppText style={[styles.later]}>{t('common:later',{})}</AppText></TouchableOpacity>}*/}
                            <ModalButton onPress={handleNext}
                                         type='primary'
                                         colors={Colors.lightBlack}
                                         text={t('common:next',{})}
                                         textColor={Colors.white}
                                         overrideStyle={styles.primaryBtn}
                                         textStyles={{fontWeight: 'normal', fontFamily: 'heebo'}}/>
                        </View>
                    </View>
                </View>
            }
        </View>
    );
}

export default FormHandler;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            formWrapper: {
                width: '100%',
                height: '100%',
                gap: '2em'
            },
            formWrapperMobile: {
                height: 'inherit',
                justifyContent: 'space-between',
                paddingHorizontal: 0,
                paddingVertical: '2em',
            },
            stepWrapper: {
                width: '100%',
                height: '100%',
                backgroundColor: Colors.white,
                padding: '2em'
            },
            stepWrapperMobile: {
                padding: 0,
                backgroundColor: 'transparent',
                overflow: 'auto'
            },
            later: {
                fontSize:'0.9rem',
                color: Colors.lightBlack,
                cursor: 'pointer'
            },
            primaryBtn: {
                width: '7em',
                paddingVertical: '0.4em',
                borderRadius: 3,
                marginTop: 0,
                minHeight: 'unset'
            },
            buttonsRow: {
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                gap: '1em',
                height: 40,
                justifyContent: 'space-between'
            },
            headerSection: {
                height: 70
            },
            headerText: {
                fontSize: '1.2rem',
                fontWeight: 'bold'
            },
            subHeader: {
                fontSize: '0.9rem',
            },
            backBtn: {
                borderWidth: 1,
                borderColor: Colors.lightBlack
            },
            question: {
                maxHeight: 100,
                overflow: 'auto'
            }
        }
    })
})

