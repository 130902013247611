import React from 'react';
import {Text, View, StyleSheet, ActivityIndicator, ScrollView} from 'react-native';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalButton from '../../../components/ModalButton';
import MembershipList from '../../../components/MembershipList';
import InviteFriendsList from '../../../components/InviteFriendsList';
import isEmpty from 'lodash/isEmpty';
import {
    insert,
    deleteScheduleUser,
    deleteStandBy,
    insertSuccess,
    fetchWeeklySchedules
} from '../../../actions/schedule';
import {emptyCart,addToCart,setShopLocationsBox} from "../../../actions/shop";

import {fetchUserMembershipsByBox} from '../../../actions/memberships';
import {Colors} from '../../../styles/Colors';
import {t} from '../../../services/i18n';
import Toast, {Durations} from 'react-native-root-toast';
import calendarEvents from "../../../services/calendarEvents";
import {removeFeed} from "../../../actions/home";
import {globalStyles} from "../../../styles/Global";
import {
    boxHasShop, doesLocationAllowEnterDebt, getPayForSlotMembershipType,
    getToastDefaultConfig,
    HUGE_ICON_SIZE,
    isBoxIL, isPayForSlotSelected,
    slotMockDataToSchedule
} from "../../../helpers/functions";
import {scale, verticalScale} from "react-native-size-matters";
import {isTextRTLStyles} from "../../../styles/Layout";
import {MembershipSelectLoader} from "../../../loaders";
import uuid from 'uuid';
import {
    INSERT_SCHEDULE_USER,
    INSERT_STAND_BY,
    CANCEL_SCHEDULE_USER,
    CANCEL_WAIT_LIST,
    PAST,
    INVITE_FRIENDS,
} from "../../../constants/booking";
import {AppText} from "../../Stateless/AppText";
import {IconTextButton} from "../../Stateless/IconTextButton";
import BookingError from "../../ScheduleFlow/Booking/error";
import * as Linking from 'expo-linking';
import {SCHEDULE_ACTION} from "../../../constants";
import DrawerHandler from "../../DrawerHandler";
import MembershipExpiredContent from "../../MembershipExpiredContent";
import {withNavigation} from 'react-navigation';
import {apiAction} from "../../../helpers/HTTP";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {IconSize} from "../../../constants/iconSize";
import {CustomCheckBoxV2} from "../../Stateless/CustomCheckBoxV2";
import Spinner from "react-native-loading-spinner-overlay";
import {MEMBERSHIP_TYPES_CONST} from "../../../constants/membershipTypes";

const cancelArray = [CANCEL_SCHEDULE_USER, CANCEL_WAIT_LIST];

@withNavigation
class BookingV2 extends React.PureComponent {


    constructor(props) {
        super(props);
        this.handleScheduleCancel = this.handleScheduleCancel.bind(this);
        this.checkMembership = this.checkMembership.bind(this);
        this.setSelectedFriends = this.setSelectedFriends.bind(this);
        this.handleActionClick = this.handleActionClick.bind(this);
        this.handleCancelInit = this.handleCancelInit.bind(this);
        this.addToCalendar = this.addToCalendar.bind(this);
        this.getCalenders = this.getCalenders.bind(this);
        this.chooseMembership = this.chooseMembership.bind(this);
        this.onScheduleAction = this.onScheduleAction.bind(this);
        this.lateCancel = this.lateCancel.bind(this);
        this.onPurchaseData = this.onPurchaseData.bind(this);
        this.handlePayForSlot = this.handlePayForSlot.bind(this);

        this.state = {
            checkedMembershipIndex: 0,
            addingCalendar: false,
            addedCalendar: false,
            selectedFriends: [],
            isLoading: true,
            submitting: false,
            data: this.props.data,
            lateCancel: false,
            showCalendars: false,
            checkedIndex: 0,
            drawerType: null,
            mergedMemberships: [],
            onPurchaseSpinner: false,
        }
    }

    async componentDidMount() {
        const {data} = this.props;
        this.calendarIndex = 0;
        this.initBookingStatus = data.booking_option; // Must be kept because the state changes.
        let memberships = await this.props.fetchUserMembershipsByBox(data.box_fk, 1);
        const membershipType = getPayForSlotMembershipType(data);
        if(memberships.data && membershipType){
            memberships.data.push(membershipType);
        }
        if (memberships && memberships.data.length === 0 && !membershipType) {
            this.setState({noMemberships: true})
        }else{
            this.setState({mergedMemberships: memberships.data,payForSlot: !!membershipType})
        }
    }


    onScheduleAction(isSuccess) {
        if (isSuccess) {
            this.props.insertSuccess(this.state.data, this.props.type); //patching redux
        }
        this.props.onAction(this.state.data);
    }

    checkMembership(index) {
        this.setState({checkedMembershipIndex: index}, () => {
            if(this.state.mergedMemberships[this.state.checkedMembershipIndex].type === MEMBERSHIP_TYPES_CONST.TYPE_SERVICE && this.state.mergedMemberships.length > 1){
                this.handleActionClick()
            }
        });
    }


    setSelectedFriends(selectedFriendsArray) {
        this.setState({selectedFriends: selectedFriendsArray});
    }


    async addToCalendar() {
        const {data} = this.props;
        const startDate = new Date(moment(data.date + " " + data.time));
        const endDate = new Date(moment(data.date + " " + data.end_time));
        const options = getToastDefaultConfig();
        const eventDetails = {
            title: data.box_categories.name,
            startDate,
            endDate,
            location: data.locations_box.location,
        };
        try {
            const event = await calendarEvents.createEvent(this.state.calenders[this.calendarIndex], eventDetails);
            this.setState({showCalendars: false, addingCalendar: false, addedCalendar: true})
            Toast.show((t('modals:BookClass:added-in-calendar', {})), {
                backgroundColor: Colors.green,
                ...options,
                position: Toast.positions.TOP + 20
            });
        } catch (e) {
            Toast.show((t('common:toast:calendarReadOnly', {})), {
                backgroundColor: Colors.brandedRed,
                ...options,
                position: Toast.positions.TOP + 20
            });
        }
    }

    async getCalenders() {
        let calenders = await calendarEvents.getCalenders();
        //When null - user will replay as
        if (calenders !== null) {
            if (Platform.OS.toLowerCase() === 'android') {
                calenders.sort((x, y) => (x.isPrimary === y.isPrimary) ? 0 : x ? -1 : 1)
            }
            calenders = calenders.filter(item => item.allowsModifications);
            calenders = calenders.splice(0, 5);
            if (calenders.length === 0) {
                const options = getToastDefaultConfig();
                Toast.show((t('common:toast:calendarNotFound', {})), {
                    backgroundColor: Colors.brandedRed,
                    ...options,
                    position: Toast.positions.TOP + 20
                });
            } else {
                this.setState({
                    calenders,
                    drawerType: 'calendar',
                    addingCalendar: true,
                    showCalendars: true
                }, () => this.RBSheet.open())
            }
        }
    }


    async handleActionClick() {
        if (this.props.booked) {
            this.props.navigation.goBack()
        } else {
            this.extras = null;
            this.setState({submitting: true});
            //TODO CAN BE REDUCE
            if (cancelArray.includes(this.state.data.booking_option)) {
                await this.handleScheduleCancel()
            } else {
                const payForSlotSelected = isPayForSlotSelected(this.state.mergedMemberships[this.state.checkedMembershipIndex])
                if(this.state.data.availability === true){
                    this.extras = slotMockDataToSchedule(this.state.data);
                }
                if(payForSlotSelected){
                    this.handlePayForSlot();
                }else{
                    this.insert();
                }
            }
        }
    }

    async insert(){
        const res = await this.props.insert(this.state.data.booking_option, this.state.data.id, this.state.mergedMemberships[this.state.checkedMembershipIndex].id,this.extras);
        await this.props.emptyCart();
        const {scheduleActionError} = this.props;
        if (isEmpty(scheduleActionError)) {
            this.setState({
                data: res,
                submitting: false,
                onPurchaseSpinner: false,
            }, () => {
                this.onScheduleAction(true)
            });
        } else {
            this.setState({
                error: scheduleActionError,
                submitting: false,
                onPurchaseSpinner: false,
                drawerType: 'error'
            }, () => this.RBSheet.open())
        }
    }

    async handlePayForSlot() {
        let membershipType = this.state.mergedMemberships[this.state.checkedMembershipIndex];
        if(this.props.hasPaymentServices && membershipType.price > 0){
            await this.props.emptyCart();
            await this.props.setShopLocationsBox({locationsBoxId:this.state.data.locations_box_fk,boxesId: this.state.data.box_fk});
            //TODO NOTE WORKAROUND Pay for slot
            membershipType.quantity = 0;
            this.props.addToCart(membershipType);
            const payForSlot = isPayForSlotSelected(this.state.mergedMemberships[this.state.checkedMembershipIndex]);
            const debtAllowed = doesLocationAllowEnterDebt(this.state.data.locations_box);
            this.props.navigation.navigate("Purchase",{showEnterDebtOption: payForSlot && debtAllowed,onGoBack: (purchaseData) => this.onPurchaseData(purchaseData)});
        }else{
            //TODO Note - we'll make cart look the same but go to another route - We need to make debt from membership only;
            this.payLater(membershipType)
        }

    }

    payLater(membershipType) {
        membershipType.quantity = 1;
        let params = {
            payment_object: {
                payment_details: {},
                payLater: true,
            },
            cart: [membershipType],
            boxes_id: this.state.data.box_fk,
        };
        this.onPurchaseData(params)
    }

    async onPurchaseData(purchaseData) {
        if(purchaseData.hasOwnProperty('payLater') && purchaseData.payLater === true){
            let membershipType = this.state.mergedMemberships[this.state.checkedMembershipIndex]; // TODO Resolve with state?
            this.payLater(membershipType)
        }else{
            this.setState({onPurchaseSpinner: true});
            if(this.extras === null){
                this.extras = {}
            }
            this.extras.purchaseData = purchaseData;
            this.extras.payForSlot = true;
            this.insert();
        }
    }

    async handleScheduleCancel() {
        this.setState({submitting: true}, async () => {
            const is_schedule = this.state.data.booking_option === CANCEL_SCHEDULE_USER;
            let res;
            let data;
            if (is_schedule) {
                res = await this.props.deleteScheduleUser(this.state.data.user_booked, this.state.data.id, SCHEDULE_ACTION, this.props.type, this.state.lateCancel);
            } else {
                res = await this.props.deleteStandBy(this.state.data.user_in_standby, SCHEDULE_ACTION, this.props.type);
            }
            const {scheduleActionError} = this.props;
            if (isEmpty(scheduleActionError)) {
                if(res.availability_id){
                    data = {...this.state.data,booking_option: INSERT_SCHEDULE_USER,user_booked: null}
                }
                this.setState({data: res.availability_id ? data : res, submitting: false}, () => this.onScheduleAction(false));
            } else {
                this.setState({
                    error: scheduleActionError,
                    drawerType: 'error',
                    submitting: false
                }, () => this.RBSheet.open())
            }
        })

    }

    async handleCancelInit() {
        const {data} = this.state;
        if (data.booking_option === CANCEL_SCHEDULE_USER) {
            this.setState({submitting: true, error: null});
            try {
                await apiAction(`scheduleUser/checkLateCancel`, 'post', {schedule_id: data.id});
                this.setState({
                    lateCancel: false,
                    submitting: false,
                    drawerType: 'lateCancel'
                }, () => this.RBSheet.open());
            } catch (error) {
                if (error.error.code === 513) {
                    this.setState({
                        lateCancel: true,
                        submitting: false,
                        drawerType: 'lateCancel'
                    }, () => this.RBSheet.open());
                } else {
                    this.setState({
                        error: error.error,
                        submitting: false,
                        drawerType: 'error'
                    }, () => this.RBSheet.open())                }
            }
        }
    }


    actionButton() {
        if (this.state.data.booking_option === PAST)
            return null;
        const {color, text} = this.getTextAndColors();
        return (
            <View key={uuid.v4()} styles={[globalStyles.marginTopBetweenElements]}>
                <ModalButton
                    onPress={() => this.handleActionClick()}
                    type='primary'
                    colors={color}
                    text={text}
                    overrideStyle={{width: '100%'}}
                    textColor={Colors.white}
                    submitting={this.state.submitting}
                />
                {this.state.drawerType === 'lateCancel' ? (<ModalButton
                    onPress={() => this.RBSheet.close()}
                    type='secondary'
                    overrideStyle={{marginTop: verticalScale(10)}}
                    colors={Colors.transparent}
                    text={t('modals:Card:keep-it-on', {})}
                    textColor={Colors.grayText}
                />) : null}

            </View>
        )
    }

    getTextAndColors() {
        if (this.props.booked)
            return {text: t('common:drawer:close', {}), color: this.props.whiteLabelProperties.primaryColor};
        switch (this.state.data.booking_option) {
            case INSERT_SCHEDULE_USER:{
                const selectedMembership = this.state.mergedMemberships[this.state.checkedMembershipIndex];
                const text = isPayForSlotSelected(selectedMembership) ? t('modals:Card:payAndBook', {}) : t('modals:Card:book', {});
                return {text,color: this.props.whiteLabelProperties.primaryColor};
            }
            case CANCEL_SCHEDULE_USER:
                return {text: t('modals:CancelClass:button', {}), color: Colors.red};
            case INSERT_STAND_BY:
                return {text: t('modals:Card:add-to-waitlist', {}), color: Colors.yellow};
            case CANCEL_WAIT_LIST:
                return {text: t('modals:Card:remove-from-waitlist', {}), color: Colors.red};
            default:
                return '';
        }
    }

    chooseMembership() {
        const {checkedMembershipIndex,data,mergedMemberships} = this.state;

        if (data.booking_option === PAST || this.props.booked) {
            return null;
        }
        return (
            this.props.loadingMemberships ? (<MembershipSelectLoader key={uuid.v4()} items={1}/>) : (<View key={uuid.v4()}>
                    {mergedMemberships && mergedMemberships.length > 0 ?
                        <View style={[styles.wrapWithTopBorder, {maxHeight: verticalScale(180)}]}>
                            <AppText
                                style={[styles.membershipListText, globalStyles.marginTopLarge, {padding: scale(3)}]}>{t('modals:BookClass:choose-membership', {})}</AppText>
                            <ScrollView
                                style={{overflow: 'hidden'}}
                                contentContainerStyle={{padding: scale(3)}}>
                                <MembershipList
                                    list={mergedMemberships}
                                    checkedIndex={checkedMembershipIndex}
                                    checkMembership={this.checkMembership}
                                />
                            </ScrollView>
                        </View> : null}
                </View>
            )
        )
    }


    getActions() {
        const {data} = this.state;
        if (!data) return null;
        if (data.booking_option === PAST) {
            if (data.user_booked !== null && data.live_link) {
                return (
                    <View key={uuid.v4()} style={[globalStyles.stickToBottom]}>
                        <View style={styles.wrapWithTopBorder}>
                            <View style={[globalStyles.flexDirectionRowSpaceBetween, globalStyles.marginTopLarge]}>
                                <IconTextButton
                                    onPress={() => Linking.openURL(data.live_link)}
                                    iconType='MaterialIcons'
                                    iconName='tv'
                                    textOverride={[{...globalStyles.heeboLight, ...globalStyles.tinyText, ...globalStyles.marginTopMedium}]}
                                    textColor={Colors.green}
                                    iconSize={40}
                                    text={t('screens:Schedule:card:online-class', {})}/>
                            </View>
                        </View>
                    </View>
                )
            } else {
                return null;
            }
        }
        const {text} = this.getTextAndColors();
        if (data.user_booked !== null || this.props.booked) {
            let styles = [{...globalStyles.heeboLight, ...globalStyles.tinyText, ...globalStyles.marginTopMedium}];
            if (this.props.booked) {
                styles.push(globalStyles.heeboRegular);
            }
            return (
                <View key={uuid.v4()} style={[globalStyles.stickToBottom]}>
                    <View style={[this.props.booked ? {} : styles.wrapWithTopBorder]}>
                        <View style={[globalStyles.flexDirectionRowSpaceBetween, globalStyles.marginTopLarge]}>
                            <IconTextButton
                                onPress={this.getCalenders}
                                iconType='AntDesign'
                                iconName={this.state.addedCalendar ? 'today' :  'calendar'}
                                textOverride={styles}
                                textColor={this.props.booked ? Colors.white : this.state.addedCalendar ? Colors.green : Colors.black}
                                iconSize={30}
                                text={this.state.addedCalendar ? t('modals:BookClass:added-in-calendar', {}) : t('modals:BookClass:add-in-calendar', {})}
                                disabled={this.state.addingCalendar}
                            />
                            {!this.props.booked ? null : (
                                <IconTextButton
                                    onPress={this.props.onShare}
                                    iconName="forward-arrow"
                                    textColor={Colors.white}
                                    textOverride={styles}
                                    iconSize={30}
                                    text={t("common:share", {})}
                                    disabled={this.state.submitting}
                                    submitting={this.state.submitting}
                                />
                            )}
                            {data.availability_id ? null :<IconTextButton
                                onPress={() => this.setState({drawerType: INVITE_FRIENDS}, () => {
                                    this.RBSheet.open();
                                })}
                                iconType='AntDesign'
                                iconName='addusergroup'
                                textOverride={styles}
                                textColor={this.props.booked ? Colors.white : Colors.black}
                                iconSize={30}
                                text={t('modals:BookClass:invite-friends', {})}
                            />}
                            {this.props.booked ? null : (
                                <IconTextButton
                                    onPress={this.handleCancelInit}
                                    iconType='AntDesign'
                                    iconName='trash'
                                    textColor={Colors.red}
                                    textOverride={styles}
                                    iconSize={30}
                                    text={text}
                                    disabled={this.state.submitting}
                                    submitting={this.state.submitting}
                                />
                            )}
                        </View>
                    </View>
                    {this.props.booked ? this.actionButton() : null}
                </View>
            )
        }
        return this.actionButton();
    }


    calendersView() {
        return (
            <View key={uuid.v4()}>
                <AppText
                    style={styles.membershipListText}>{t('common:drawer:booking:chooseCalendar', {})}</AppText>
                <CustomCheckBoxV2
                    arr={this.state.calenders}
                    onIndexChange={(value) => this.calendarIndex = value} // Note - I dont want to change state;
                    onPress={() => this.addToCalendar()}
                    colors={this.props.whiteLabelProperties.primaryColor}
                    text={t('common:drawer:booking:addToCalendar', {})}
                />
            </View>
        )
    }

    render() {
        const {data} = this.state;
        const {user} = this.props;
        if (!data) return null;
        let content;
        switch (data.booking_option) {
            case INSERT_SCHEDULE_USER:
            case INSERT_STAND_BY: {
                content = ([this.chooseMembership(), this.getActions()]);
                break;
            }
            case CANCEL_WAIT_LIST:
            case CANCEL_SCHEDULE_USER:
                content = (this.getActions());
                break;
            default:
                break;
        }
        return (
            this.state.noMemberships && !cancelArray.includes(data.booking_option) ? (
                    user.hasOwnProperty('lastEndedMembership') ? (
                        <MembershipExpiredContent
                            phone={this.state.data.box.phone}
                            membershipType={user.lastEndedMembership.membership_types}
                            iconSize={30}
                            boxHasShop={boxHasShop}
                            isIL={isBoxIL(this.props.user.users_boxes, this.props.user.lastEndedMembership.box_fk)}
                            onLinkToShop={() => console.log('go to shop')}
                        />
                    ) : null
                ) :
                (

                    <React.Fragment>
                        <Spinner visible={this.state.onPurchaseSpinner}/>
                        {content}
                        {this.drawers()}
                    </React.Fragment>
                )
        )

    }



    drawers() {
        let title, content;
        const {data, drawerType, error} = this.state;
        if (!data) return null;
        switch (drawerType) {
            case INVITE_FRIENDS:
                title = t('modals:BookClass:invite-friends', {});
                content = <InviteFriendsList
                    bookedUsers={data.booked_users}
                    maxInvites={data.free}
                    scheduleId={data.id}
                    friendConnection={this.props.user.friend_connection}
                />
                break;
            case 'error':
                content = <BookingError error={error} closeDrawer={() => this.RBSheet.close()}/>
                break;
            case 'lateCancel':
                content = [this.lateCancel(), (this.actionButton())]
                break;
            case 'calendar':
                content = this.calendersView()
                break;
            default:
                return null;
        }

        return (
            <DrawerHandler title={title}
                           key={uuid.v4()}
                           onClose={() => this.setState({error: null, drawerType: null})}
                           ref={el => {
                               this.RBSheet = el ? el.ref.current : null;
                           }}>
                {content}
            </DrawerHandler>
        )
    }

    lateCancel() {
        const text = this.state.lateCancel ? t('modals:CancelClass:non-refundable', {}) : t('modals:CancelClass:are-you-sure-text', {})
        return (
            <View style={[{alignItems: 'center'}]}>
                <Icons8Generator name={'trash-can'} fill={Colors.red} size={IconSize.XXL}/>
                <AppText
                    style={[globalStyles.heeboRegular, globalStyles.marginTopBetweenTextElements, globalStyles.textCenter]}>{text}</AppText>
            </View>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserMembershipsByBox,
            insert,
            insertSuccess,
            removeFeed,
            deleteScheduleUser,
            deleteStandBy,
            fetchWeeklySchedules,
            setShopLocationsBox,
            emptyCart,
            addToCart
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    memberships: state.memberships.data,
    loadingMemberships: state.memberships.isLoading,
    scheduleActionLoading: state.schedule.action.isLoading,
    scheduleActionError: state.schedule.action.error.error,
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
    cart: state.shop.cart,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(BookingV2);


const styles = StyleSheet.create({
    wrapWithTopBorder: {
        borderTopColor: Colors.graySeparator,
        borderTopWidth: scale(2),
    },
    membershipListText: {
        fontSize: scale(13),
        ...isTextRTLStyles,
    },
    bold: {
        fontFamily: 'heebo-bold',
        fontWeight: '600',
        color: Colors.modalFontColor,
        ...isTextRTLStyles,
    },

});