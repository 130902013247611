import { scale, ScaledSheet,verticalScale } from 'react-native-size-matters';
import {globalStyles} from "../../../styles/Global";

let styles;
export default (styles = ScaledSheet.create({
  header: {
    // paddingHorizontal: '15@s',
    height: verticalScale(60),
      ...globalStyles.appPaddingHorizontal,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
      flex: 0

    // marginTop: Platform.OS === 'ios' ? scale(20) : 0
  },
  headerIcon: {
    color: '#FFF',
    fontSize: '24@s',
    /* transform: [{
      scaleX: i18n.isRTL ? -1 : 1,
    }], */
  },
  input: {
    backgroundColor: 'transparent',
    width: '75%',
    color: 'white',
    fontSize: '16@s'
  },
}));
