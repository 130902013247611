import React, {Component} from 'react';
import {Form, Icon} from 'native-base';
import {View} from 'react-native';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {Colors} from '../../styles/Colors';
import {t} from "../../services/i18n";
import {emailRequired, email, validateAfterEmailSymbol, removeWhiteSpace} from "../../helpers/Validators";
import {globalStyles} from "../../styles/Global";
import {apiAction} from "../../helpers/HTTP";
import {store} from "../../config/store";
import Toast from "react-native-root-toast";
import {getToastDefaultConfig, HUGE_ICON_SIZE} from '../../helpers/functions';
import FloatingTextInput from "../../components/Stateless/FloatingTextInput";
import {scale} from "react-native-size-matters";
import {LinearWrapper} from "../../components/Stateless/LinerWrapper";
import {FormButton} from "../../components/Stateless/FormButton";
import {AppText} from "../../components/Stateless/AppText";
import AppHeader from "../../components/Stateless/AppHeader";
import MoveToBottom from "../../components/Stateless/MoveToBottom";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {isWeb} from "../../helpers/functions";

// TODO: is redux form really needed
class ForgotPasswordScreen extends Component {
    static navigationOptions = {
        header: null
    };

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            error: null
        };
        this.submit = this.submit.bind(this);
    }

    async submit() {
        this.setState({submitting: true, error: null});
        try {
            const selector = formValueSelector('ResetPassword');
            const state = store.getState();
            const values = selector(state, 'email');
            const data = {
                email: values
            };
            const res = await apiAction(`user/resetPassword`, 'post', {...data});
            this.setState({submitting: false, error: null});
            const options = getToastDefaultConfig();
            setTimeout(() => {
                Toast.show((t('screens:ForgotPassword:success', {})), {
                    backgroundColor: Colors.brandSuccess,
                    ...options
                });
            },500);
            this.props.navigation.goBack();
        } catch (error) {
            this.setState({submitting: false, error: error.error});
        }
    }


    render() {
        const {handleSubmit, error, reset} = this.props;
        const form = (
            <View style={globalStyles.flex}>
                <View style={[globalStyles.centerItems]}>
                    <Icons8Generator name={'unlock'} width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}
                                     fill={Colors.black}/>
                    <AppText
                        style={[globalStyles.title, globalStyles.marginTopBetweenElementsBig]}>{t('screens:ForgotPassword:title', {})}</AppText>
                </View>
                <Form style={globalStyles.flex}>
                    <Field
                        name="email"
                        type="email"
                        keyboardType="email-address"
                        autoCompleteType="email"
                        component={FloatingTextInput}
                        showError={this.state.emailError}
                        onBlur={() => this.setState({emailError: true})}
                        onChange={() => this.setState({serverError: false})}
                        placeholder={t('screens:Login:email', {})}
                        validate={[emailRequired, email,validateAfterEmailSymbol]}
                        normalize={removeWhiteSpace}

                    />
                    {
                        MoveToBottom(
                            <FormButton onPress={handleSubmit(this.submit)}
                                        text={t('screens:ForgotPassword:button', {})}/>
                        )
                    }
                </Form>

            </View>
        );

        return (
            <LinearWrapper
                backgroundStart={Colors.white}
                backgroundEnd={Colors.white}
                barStyle="light-content"
                spinner={this.state.submitting}>
                <View
                    style={[globalStyles.appPaddingHorizontal, globalStyles.marginTopBetweenElementsBig, globalStyles.flex]}>
                    {/*<AppHeader addPadding={true}/>*/}
                    {form}
                </View>
            </LinearWrapper>
        );
    }
}

export default reduxForm({
    form: 'ResetPassword'
})(ForgotPasswordScreen);
