import React from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {TouchableOpacity, View} from "react-native-web";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {Colors} from "../../../../styles/Colors";
import CartItem from "./CartItem";
import ShopEmptyState from "../ShopEmptyState";
import CartSummary from "./CartSummary";
import {isMobile} from "../../../../helpers/functions";
import {useDispatch, useSelector} from "react-redux";
import {Platform} from "react-native";
import {emptyCart} from "../../../../actions/shop";

const CartDisplay = (props) => {
    const { goToCheckout } = props;
    const cart = useSelector(state => state.shop.cart)
    const mobile = isMobile();
    const dispatch = useDispatch();

    const deleteCart = () => {
        dispatch(emptyCart())
    }

    return (
        <>
            <View style={[mobile ? styles.itemsWrapperMobile : styles.itemsWrapper]}>
                {!mobile &&
                <View style={[styles.headerWrapper]}>
                    <AppText style={[styles.header]}
                             boldFont>{t('screens:Cart:title', {})} ({cart.length})</AppText>
                    <TouchableOpacity onPress={deleteCart}><Icons8Generator name={'trash'} fill={Colors.red}
                                                                            size={'1.7em'}/></TouchableOpacity>
                </View>
                }
                {cart.length ?
                    <View style={[{gap: '0.5em'}, mobile && {height: '100%'}]}>{cart.map(item => <CartItem key={`cart-item-${item.id}`} item={item}/>)}</View>
                    :
                    <ShopEmptyState/>
                }
            </View>
            <View style={[mobile ? {height: EStyleSheet.value('$mobileCartSummaryWithNote')} : {marginTop: '5em'}]}><CartSummary goToCheckout={goToCheckout}/></View>
        </>
    );
};

export default CartDisplay;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            itemsWrapper: {
                width: '50%',
                maxWidth: 700
            },
            itemsWrapperMobile: {
                width: '100%',
                height: () => `100% - ${EStyleSheet.value('$mobileCartSummaryWithNote') + EStyleSheet.value('$mobileHeader') + 25}`,
                overflow: 'auto',
                marginVertical: 10
            },
            headerWrapper: {
                flexDirection: 'row',
                gap: '1em',
                marginHorizontal: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                height: '5em'
            },
            header: {
                fontSize: '1.4rem',
                fontFamily: 'heebo'
            }
        }
    })
})