import * as React from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, View, ScrollView} from 'react-native';
import {Icon} from 'native-base';
import {t} from '../../services/i18n';
import NavigationService from "../../helpers/NavigationService";
import FriendsStatusAction from "../FriendsStatusAction";
import uuid from 'uuid';
import {connect} from "react-redux";
import {Colors} from "../../styles/Colors";
import ActionOnFriend from '../ActionOnFriend'
import {globalStyles} from "../../styles/Global";
import {EmptyRow} from "../Stateless/EmptyRow";
import {ImageTextRow} from "../Stateless/ImageTextRow";
import {ProfileLoaderContainer} from "../Stateless/ProfileLoaderContainer";
import {isTextRTLStyles} from "../../styles/Layout";
import {ScaledSheet, scale, verticalScale} from 'react-native-size-matters';
import FriendListDrawer from "../Stateless/FriendListDrawer";
import {Icons8Generator} from "../Stateless/Icons8Generator";


class FriendsList extends React.Component {
    state = {
        isExpanded: false,
    };

    constructor(props) {
        super(props);
        this.renderListElement = this.renderListElement.bind(this);
    }


    toggleList() {
        this.setState(prevState => ({isExpanded: !prevState.isExpanded}));
    }

    renderListElement(friendConnection, index) {
        const {actionDisabled, directObject} = this.props;
        let borderBottomStyle = null;
        if (this.props.list.length - 1 === index) {
            borderBottomStyle = styles.bottomBorderRadius;
        }
        const friend = directObject ? friendConnection : Object.prototype.hasOwnProperty.call(friendConnection, 'friend_user') ? friendConnection.friend_user : friendConnection.user;
        if(!friend)
            return null;
        const name = Object.prototype.hasOwnProperty.call(friend, 'full_name') ? friend.full_name : friend.full_name_shorten;
        const multiCheck = Object.prototype.hasOwnProperty.call(friend, 'users_boxes_active');
        return (
            <View key={uuid.v4()}
                  style={[borderBottomStyle, styles.listElement, this.props.drawer ? {} : globalStyles.appPaddingHorizontal]}>
                <TouchableOpacity style={[{flex: 1}]}
                                  onPress={() => {
                                      if (friend.is_user) {
                                          if (this.props.closeDrawer) {
                                              this.props.closeDrawer();
                                          }
                                          NavigationService.push("FriendProfile", {id: friend.id})
                                      }
                                  }}>
                    <ImageTextRow
                        imageName={name}
                        imageSize={40}
                        src={friend.image}
                        upperText={name}
                        // bottomText={multiCheck ?  friend.users_boxes_active.length > 1 ? t('modals:FriendsList:muetralClubs', {count: friend.users_boxes_active.length}) : friend.users_boxes_active[0].box.name : null}
                        bottomText={multiCheck ? Array.isArray(friend.users_boxes_active) ? friend.users_boxes_active[0].box.name : null : null}
                    />
                </TouchableOpacity>
                {friend.is_user === false || actionDisabled || friend.id === this.props.userId ? null : (
                    <View style={styles.actionWrapper}>
                        {this.handleAction(friendConnection)}
                    </View>
                )}
            </View>
        );
    }

    handleAction(friendConnection) {
        const {displaySmall, action, textButtons, actionId, reducerName} = this.props;
        if (action === null || action === 'friendStatus') {
            // return null;
            return (
                <FriendsStatusAction
                    friendConnection={friendConnection}
                    displaySmall={displaySmall}
                    textButtons={textButtons}
                    reducerName={reducerName}
                />
            );
        }
        return (
            <ActionOnFriend
                friendConnection={friendConnection}
                displaySmall={displaySmall}
                textButtons={textButtons}
                reducerName={reducerName}
                action={action}
                actionId={actionId}
            />
        )
    }


    render() {
        const {list, drawer, isLoading, action,search} = this.props;
        const usersToDisplay = 3;
        if (isLoading) {
            return (
                <ProfileLoaderContainer items={3}/>
            )
        }
        if (list.length === 0) {
            let text = t('screens:FriendProfile:no-friends', {});
            if (action === 'inviteToSchedule') {
                text = t('common:drawer:noFriendsToInvite', {});
            }
            return (
                <View style={[{
                    backgroundColor: Colors.white,
                    justifyContent: 'center'
                }, globalStyles.appBottomBorderRadius, drawer ? {height: verticalScale(80)} : globalStyles.appPaddingHorizontal]}>
                    <EmptyRow text={text} textColor={Colors.grayText}>
                        <Icons8Generator name={'ghost'}/>
                    </EmptyRow>
                </View>
            )
        }
        return (
            <View>
                <View>
                    {
                        drawer || search
                            ? list.map((item, index) => this.renderListElement(item, index))
                            : list.slice(0, usersToDisplay).map((friend, index) => this.renderListElement(friend, index))
                    }
                </View>
                {drawer || search ? null : list.length > 3 ?
                    <FriendListDrawer actionText={t('common:showAllFriends', {})}
                                      drawerTitle={this.props.isMyFriends ? t('headings:your-friends', {}) : this.props.activeFriend ? t('screens:FriendProfile:friends-of', {userName: this.props.activeFriend.first_name}) : null}
                                      {...this.props}/> : null}
            </View>
        );
    }
}

FriendsList.propTypes = {
    list: PropTypes.instanceOf(Array),
    isModal: PropTypes.bool,
    actionDisabled: PropTypes.bool,
    textButtons: PropTypes.bool,
    displayClub: PropTypes.bool,
    reducerName: PropTypes.string,
};


const mapStateToProps = state => ({
    userId: state.auth.data.id,
    user: state.user.data,
});

export default connect(
    mapStateToProps,
    null
)(FriendsList);

const styles = ScaledSheet.create({
    listElement: {
        paddingVertical: scale(16),
        flexDirection: 'row',
        backgroundColor: Colors.light,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderColor: Colors.listSeparator
    },
    bottomBorderRadius: {
        borderBottomLeftRadius: scale(8),
        borderBottomRightRadius: scale(8)
    },
    scrollViewMargin: {
        paddingBottom: verticalScale(60)
    }

});