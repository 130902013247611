import React from 'react';
import {TouchableOpacity, Platform, Text, StyleSheet, View} from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Colors} from '../../styles/Colors';
import {globalStyles} from "../../styles/Global";


export default class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        // TODO REMOVE THIS.PROPS FROM STATE
        this.state = {
            date: new Date(this.props.date),
        };
    }

    render() {
        const {onClose, onChange,minimumDate} = this.props;
        const {date} = this.state;
        const displayType = Platform.OS.toLowerCase() === 'ios' ? 'spinner' : 'default';
        return (
            <View style={[styles.container]}>
                {Platform.OS.toLowerCase() === 'ios' && (
                    <View style={styles.header}>
                        <TouchableOpacity onPress={(event) => onClose(event,this.state.date)}>
                            <Text  style={styles.button}>Done</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={(event) => onClose('aborted',moment())}>
                            <Text style={[styles.button,globalStyles.warningText]}>Dismiss</Text>
                        </TouchableOpacity>
                    </View>
                )}
                <DateTimePicker
                    style={{flex:0.4,backgroundColor:Colors.grayText}}
                    value={date}
                    mode="date"
                    display={displayType}
                    minimumDate={minimumDate}
                    onChange={(e, d) => {
                        if (Platform.OS.toLowerCase() === 'ios') {
                            this.setState({date: d});
                        } else {
                            onClose(e,d);
                        }
                    }}
                />

            </View>
        );
    }
}


DatePicker.propTypes = {
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    date: PropTypes.any
};

const styles = StyleSheet.create({
    container: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'flex-end',
        ...Platform.select({
            ios: {
                backgroundColor: Colors.backgroundOverlayRGBA
            },
            android: {
                backgroundColor: Colors.backgroundOverlayRGBA
            },
        }),

    },
    header: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        flexDirection:'row',
        justifyContent: 'space-between',
        backgroundColor:Colors.white,
        borderBottomWidth: 1,
        borderColor: Colors.grey,
    },
    button: {
        color:Colors.paleblue,
        fontSize:20,
        fontWeight: "500"
    }
});