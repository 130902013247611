import React from 'react';
import {globalStyles} from "../../../styles/Global";
import { View } from 'react-native';
import {AppText} from "../AppText";
import {Icons8Generator} from "../Icons8Generator";
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from 'react-native-size-matters';
import {IconSize} from "../../../constants/iconSize";
import {isMobile, isWeb} from "../../../helpers/functions";

export const EmptyState = ({
                               backgroundColor = Colors.transparent,
                               textColor = Colors.grayText,
                               header,
                               iconName,
                               iconColor = Colors.iconEmptyStateColor,
                               iconSize = IconSize.SPECIAL_SIZE_150,
                               text}) => {
    const isSite = isWeb();
    return (
        <View style={[globalStyles.flex,globalStyles.centerItems,{backgroundColor: backgroundColor}]}>
            <AppText style={[{color:textColor,fontSize: isSite ? '1em' : scale(15)}]}>{header}</AppText>
            <View style={{marginVertical: isSite ? '1em' : verticalScale(25)}}><Icons8Generator name={iconName} fill={iconColor} size={iconSize}/></View>
            <AppText style={[{color:textColor,fontSize: isSite ? '1em' : scale(15),textAlign:'center'}]}>{text}</AppText>
        </View>
    )
};



