import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Envelope(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M0 7v36h50V7H0zm2 2h46v2.506L28.336 28.643c-1.297 1.129-2.619 1.359-3.336 1.359-.717 0-2.039-.23-3.336-1.36C20.186 27.357 2.424 11.877 2 11.507V9zm0 5.15c2.438 2.126 16.968 14.792 18.354 15.998 1.7 1.481 3.5 1.85 4.646 1.85 1.145 0 2.946-.369 4.646-1.85C31.032 28.942 45.562 16.278 48 14.152V41H2V14.15z" />
    </Svg>
  );
}

export default SvgIcons8Envelope;
