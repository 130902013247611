import {apiAction} from '../helpers/HTTP';
import {
    SCHEDULE_ACTION,
    SCHEDULES,
    WEEKLY_SCHEDULES,
    FEED_SCHEDULE_ACTION,
    USER_CLASSES,
    SCHEDULE_BY_DATE,
    SCHEDULE_USERS_CLASSES,
    SCHEDULE_MEMBERSHIP_CLASSES,
    TOGGLE_SITE_REFETCH,
    FRIENDS_OF_FRIEND_SCHEDULE_ACTION,
    SCHEDULE_INSERTED,
    SCHEDULE_REMOVED,
    APPOINTMENT_TYPES,
    AVAILABILITIES, AVAILABILITIES_PATCH, AVAILABILITIES_REMOVE_PATCH
} from '../constants';
import {isWeb} from "../helpers/functions";
import {store} from "../config/store";


export const fetchTodaySchedules = (from,locations_box_id, to = null, isLoggedIn = true) => async (dispatch) => {
    const params = {
        from: from,
        to: to ? to : from,
        locations_box_id
    };

    const actionType = SCHEDULES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`${isWeb() && !isLoggedIn ? 'site/' : ''}schedule/betweenDates`, 'post', params);
        // const response = await apiAction('schedule/betweenDates', 'post', params);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const fetchUserClasses = (date,locations_box_id,direction = 'both') => async (dispatch) => {
    const params = {
        date,
        locations_box_id,
        direction
    };
    const actionType = USER_CLASSES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`, payload: {direction}});
        const response = await apiAction('schedule/getUserClasses', 'post', params);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


export const fetchWeeklySchedules = (from,to,locations_box_id) => async (dispatch) => {
    const params = {
        from,
        to,
        locations_box_id,
    };
    const actionType = WEEKLY_SCHEDULES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('schedule/weekly', 'post', params);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


export const fetchAvailabilitiesByDate = (date,locations_box_id,box_categories_id, isCoachAvailability) => async (dispatch) => {
    const params = {
        date,
        locations_box_id,
        box_categories_id,
        isCoachAvailability
    };
    const actionType = AVAILABILITIES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('availabilities/byDate', 'post', params);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


export const insert = (action,schedule_id,membership_user_id,extras, tokenFromRegistration = null) => async (dispatch) => {
    //TODO Note - schedule id and membership user id is not longer a must and can be created in dynamic fashion
    const params = {
        schedule_id,
        membership_user_id,
        extras
    };
    const actionType = SCHEDULE_ACTION.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const route = action === 'insertScheduleUser' ? 'scheduleUser/insert' : 'scheduleStandBy/insert';
        const response =  await apiAction(route, 'post', params, false, tokenFromRegistration);
        // this.insertSuccess(response.data,type);// TODO This is a better place to handle, when old behaviour will be moved.
        return response.data;
    } catch (error) {
        console.log('insert error: ', error)
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const insertSuccess = (data,type) =>  async (dispatch) => {
    switch(type){ //TODO Check for the real reason for the switch cash and not just dispatch all, who cares for multiple dispatch
        case 'byDate':{
            dispatch({type: `${SCHEDULE_BY_DATE}_SUCCESS`, payload: data});
            dispatch({type: `${AVAILABILITIES_PATCH}_SUCCESS`, payload: data});
            break;
        }
        case 'friendProfile': {
            dispatch({type: `${FRIENDS_OF_FRIEND_SCHEDULE_ACTION}_SUCCESS`, payload: data});
            break;
        }
        case 'search': {
            dispatch({type: `${SCHEDULE_INSERTED}_SUCCESS`, payload: data});
            break;
        }
        default:
            break;
    }
};


export const deleteScheduleUser = (schedule_user_id,schedule_id,action,type,late_cancel) => async (dispatch) => {
    const params = {
        schedule_user_id,
        schedule_id,
        late_cancel
    };
    return removeFromSchedule(dispatch,action,'scheduleUser/delete',params,type);
};

export const deleteStandBy = (schedule_stand_by_id,action,type) => async (dispatch) => {
    const params = {
        schedule_stand_by_id
    };
    return removeFromSchedule(dispatch,action,'scheduleStandBy/delete',params,type)
};

export const fetchAppointmentTypes = (boxesId,locationsBoxId, isCoachAvailability = true) => async (dispatch) => {
    const actionType = APPOINTMENT_TYPES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`appointment/types/${boxesId}/${locationsBoxId}/${isCoachAvailability}`, 'get');
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

const removeFromSchedule = async (dispatch,actionType,url,params,type) => {
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(url, 'post', params);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});//TODO Is there a reason not to dispatch all?
        switch(type){
            case 'byDate':
            case 'userClasses':
            {
                dispatch({type: `${SCHEDULE_BY_DATE}_SUCCESS`, payload: response.data});
                dispatch({type: `${SCHEDULE_USERS_CLASSES}_SUCCESS`, payload: response.data});
                dispatch({type: `${AVAILABILITIES_REMOVE_PATCH}_SUCCESS`, payload: response.data});
                break;
            }
            case 'future':{
                dispatch({type: `${SCHEDULE_MEMBERSHIP_CLASSES}_SUCCESS`, payload: response.data});
                break;
            }
            case 'feed':{
                dispatch({type: `${FEED_SCHEDULE_ACTION}_SUCCESS`, payload: response.data});
                break;
            }
            case 'friendProfile': {
                dispatch({type: `${FRIENDS_OF_FRIEND_SCHEDULE_ACTION}_SUCCESS`, payload: response.data});
                break;
            }
            case 'search': {
                dispatch({type: `${SCHEDULE_REMOVED}_SUCCESS`, payload: response.data});
                break;
            }
            default:
                break;
        }
        return response.data;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
}

