import React, {useEffect, useState} from 'react';
import {View} from "react-native-web";
import YesNoQuestion from "./Questions/YesNoQuestion";
import MultiChoiceQuestion from "./Questions/MultiChoiceQuestion";
import OpenQuestion from "./Questions/OpenQuestion";

const FormQuestion = (props) => {
    const {question, onSelect} = props;
    const [questionComponent, setQuestionComponent] = useState(null);

    useEffect(() => {
        setQuestionComponent(questionTypeHandler())
    }, [question]);

    const handleAnswer = (answer) => {
        if(question.type === 'questionMultipleChoice') {
            if(question.multi_choice) {
                question.checked_array = answer
            } else {
                question.user_answer = answer[0].answer_text
            }
        } else {
            question.user_answer = answer
        }
        onSelect(question)
    }


    const questionTypeHandler = () => {
        switch (question.type) {
            case 'questionYesNo':
            case 'questionYesNoAdv':
                return <YesNoQuestion onSelect={handleAnswer} answer={question.user_answer}/>;
            case 'questionOpen':
                return <OpenQuestion onSelect={handleAnswer} answer={question.user_answer}/>;
            case 'questionMultipleChoice':
                return <MultiChoiceQuestion onSelect={handleAnswer} answers={question.checked_array || null} answerOptions={question.answers} isOneChoice={!question.multi_choice}/>;
            default:
                return null;
        }
    }

    return (
        <>
            {questionComponent}
        </>
    );
}

export default FormQuestion;