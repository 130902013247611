import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8OutgoingCall(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M11.838 2.988c-.766-.063-1.621.162-2.412.713-.695.486-2.197 1.679-3.598 3.024-.7.672-1.367 1.372-1.885 2.06-.517.688-.972 1.317-.943 2.229.027.82.111 3.25 1.855 6.965C6.6 21.693 9.987 26.79 16.594 33.4c6.609 6.611 11.707 10 15.422 11.745C35.73 46.889 38.16 46.973 38.98 47c.912.028 1.54-.427 2.227-.945.688-.518 1.388-1.185 2.06-1.885 1.344-1.4 2.534-2.9 3.02-3.596 1.104-1.573.921-3.435-.31-4.299-.774-.541-7.889-5.277-8.956-5.93-1.086-.664-2.337-.364-3.453.225-.882.465-3.259 1.83-3.94 2.22-.51-.326-2.452-1.616-6.63-5.794-4.177-4.176-5.465-6.118-5.789-6.629.389-.678 1.75-3.048 2.217-3.94v-.001c.586-1.112.912-2.374.217-3.469-.296-.467-1.627-2.49-2.973-4.527-1.346-2.037-2.622-3.965-2.955-4.426v-.002c-.435-.6-1.111-.95-1.877-1.014zm-.182 2.045c.273.034.438.143.438.143.16.223 1.563 2.322 2.906 4.355a618.785 618.785 0 012.953 4.498c.038.06.094.723-.297 1.463v.002l-.002.002c-.44.841-2.496 4.438-2.496 4.438l-.287.502.293.5s1.536 2.59 6.42 7.474c4.885 4.884 7.475 6.424 7.475 6.424l.5.293.503-.287s3.592-2.055 4.438-2.5h.002c.734-.388 1.4-.336 1.477-.29.695.426 8.375 5.532 8.85 5.864.013.01.431.643-.179 1.512l-.002.002c-.367.526-1.57 2.05-2.824 3.357-.627.653-1.274 1.26-1.82 1.672-.545.41-1.034.54-.96.543-.848-.027-2.727-.046-6.177-1.666-3.45-1.62-8.372-4.86-14.86-11.348C11.524 25.5 8.287 20.577 6.667 17.127c-1.62-3.45-1.639-5.329-1.666-6.178.002.076.132-.415.543-.96.411-.547 1.017-1.196 1.67-1.823 1.306-1.254 2.83-2.458 3.357-2.826v.002c.433-.302.813-.343 1.086-.309zM39.99 6.99a1 1 0 00-.697 1.717L43.586 13H29a1 1 0 100 2h14.586l-4.293 4.293a1 1 0 101.414 1.414l5.912-5.912a1 1 0 00.004-1.588 1 1 0 00-.006-.004l-5.91-5.91a1 1 0 00-.717-.303z" />
    </Svg>
  );
}

export default SvgIcons8OutgoingCall;
