// @flow
import * as React from 'react';
import {ActivityIndicator, Platform, StyleSheet, View} from "react-native";
import Header from "./Header";
import {globalStyles} from "../../../../styles/Global";
import DayHeader from "./DayHeader";
import {Colors} from "../../../../styles/Colors";
import DayColumn from "./DayColumn";
import {useEffect, useState} from "react";
import {fetchTodaySchedules} from "../../../../actions/schedule";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import calendarEvents from "../../../../services/calendarEvents"
import {isLocationIL} from "../../../../helpers/functions";
import {toggleScheduleSiteReFetch, updateSiteSelectedLocation} from "../../../../actions/minisite";
import EStyleSheet from "react-native-extended-stylesheet";
import {AppText} from "../../../Stateless/AppText";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {TouchableOpacity} from "react-native-web";
import {t} from "../../../../services/i18n";
import {isSiteHebrew} from "../../../../helpers/ComponentFunctionality";

const format = 'YYYY-MM-DD'
const desktopHeaderAndCalendarHeight = '11em'

export const ScheduleContainer = (props) => {
    const {onBackPress} = props;
    const dispatch = useDispatch();
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const reFetch = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteReFetch)
    const loggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const [classesByDate, setClassesByDate] = useState(null);
    const [dates, setDates] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const today = moment().format(format)
    const isHebrew = isSiteHebrew();

    useEffect(() => {
        if(reFetch) {
            fetchClasses()
            dispatch(toggleScheduleSiteReFetch(false));
        }
    }, [reFetch]);


    useEffect(() => {
        if(box && location) {
            initDates()
        }
    }, [box,location]);

    useEffect(() => {
        if(toDate && fromDate) {
            const res = calendarEvents.getDateRangeArray(fromDate,toDate, format)
            setDates(res)
        }
    }, [toDate,fromDate]);

    useEffect(() => {
        if(box && location && dates.length) {
            fetchClasses()
        }
    }, [dates]);


    const fetchClasses = async () => {
        setFetching(true)
        setClassesByDate([])
        const classes = await dispatch(fetchTodaySchedules(fromDate, location.id, toDate, loggedIn));
        const res = calendarEvents.groupClassesByDate(classes.data, dates)
        setClassesByDate(res);
        setFetching(false)
    }

    const initDates = () => {
        const isIL = isLocationIL(location)
        const from = moment().startOf(isIL ? 'week' : 'isoWeek');
        const to =  moment().endOf(isIL ? 'week' : 'isoWeek')
        setFromDate(from);
        setToDate(to)
    }

    const handleWeekChange = (newFrom, newTo) => {
        setFromDate(newFrom.clone())
        setToDate(newTo.clone())
    }

    const handleLocationChange = (locationId) => {
        const newLocation = box.locations_box.find(item => item.id == locationId)
        dispatch(updateSiteSelectedLocation(newLocation))
    }

    return (
        <View style={[styles.content, {height:`calc(100% - ${desktopHeaderAndCalendarHeight})`, maxHeight:`calc(100% - ${desktopHeaderAndCalendarHeight})`}]}>
            {onBackPress ?
                <TouchableOpacity onPress={onBackPress} style={styles.pageHeader}>
                    <Icons8Generator name={isHebrew ? 'arrow' : 'arrow-rtl'} size={'1em'}/>
                    <AppText style={styles.pageHeaderText}>{t('screens:Schedule:class-schedule',{})}</AppText>
                </TouchableOpacity>
                :
                <View style={styles.pageHeader}>
                    <AppText style={styles.pageHeaderText}>{t('screens:Schedule:class-schedule',{})}</AppText>
                </View>
            }
            <View style={[styles.wrapper, globalStyles.cardContainer]}>
                <Header onWeekChange={handleWeekChange}
                        startDate={fromDate}
                        endDate={toDate}
                        onLocationChanged={handleLocationChange}
                        onSearchChanged={(input) => setSearchInput(input)}
                />
                <View style={styles.weeklyViewDays}>
                    {
                        dates.map(date => {
                            const momentDate = moment(date, format)
                            return <DayHeader key={`day-header-${date}`} dayName={momentDate.format('ddd')} dateNumber={momentDate.date()} isToday={date === today}/>
                        })
                    }
                </View>
                {fetching && <ActivityIndicator size="large" color={Colors.brandedBlue} style={{flex: 1}}/>}
                <View style={styles.weeklyViewValues}>
                    {classesByDate && Object.keys(classesByDate).map((date, index) =>
                            <DayColumn key={`${date}-classes-${index}`}
                                       classes={classesByDate[date]}
                                       date={date}
                                       searchInput={searchInput}
                            />
                    )}
                </View>
            </View>
        </View>
    );
};

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            content: {
                boxSizing: 'border-box',
                padding: '1em',
                overflow: 'hidden',
            },
            wrapper: {
                backgroundColor: Colors.sectionHeaderColor,
                height: '100%',
                maxHeight: 'inherit',
                width: '100%',
                overflow: 'auto'
            },
            pageHeader: {
                flexDirection: 'row',
                gap: '1em',
                maxHeight: 50,
                alignItems: 'center',
                marginBottom: '1em'
            },
            pageHeaderText: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            weeklyViewDays: {
                flexDirection: 'row',
                display: 'inline-grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                overflow: 'auto'
            },
            weeklyViewValues: {
                flexDirection: 'row',
                display: 'inline-grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                flex: 1,
                overflow: 'overlay',
            }
        }
    })
})