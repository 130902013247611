import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import TabBarIcon from '../../components/TabBarIcon';
import MoreGroupContainer from '../../containers/MoreGroupContainer';

export const MoreStack = createStackNavigator({
  MoreStack: MoreGroupContainer
});

MoreStack.navigationOptions = {
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="apps" />
};

