// @flow
import * as React from 'react';
import {Button, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import {useForm, Controller} from "react-hook-form"
import TextInputField from "../Common/TextInputField";
import ModalButton from "../../ModalButton";
import {useSelector} from "react-redux";
import {apiAction} from "../../../helpers/HTTP";
import {useEffect, useState} from "react";
import Spinner from "react-native-loading-spinner-overlay";
import {isMobile} from "../../../helpers/functions";
import GroupButton from "../Common/GroupButton";
import Dropdown from "../Common/Dropdown";
import DatePickerField from "../Common/DatePickerField";
import moment from "moment";
import {SERVER_DATE_FORMAT} from "../../../variableConstants";
import GenericAlert from "../Common/GenericAlert";

const ParticipantDetails = (props) => {
    const {onSubmit, onBack, details} = props;
    const [genderButtons, setGenderButtons] = useState(['male', 'female', 'other']);
    const {control, handleSubmit, watch, setValue, formState: { errors }} = useForm({
        defaultValues: {
            firstName: details?.firstName ? details.firstName : '',
            lastName: details?.lastName ? details.lastName : '',
            gender: details?.gender ? details.gender : 0,
            id: details?.id ? details.id : '',
            phone: details?.phone ? details.phone : '',
            birthDate: details?.birthDate ? details.birthDate : moment().format(SERVER_DATE_FORMAT),
        }
    });
    const mobile = isMobile();
    // console.log(watch())

    const submitForm = async (data) => {
        data.gender = genderButtons[data.gender]
        onSubmit(data)
    }

    return (
        <View style={[{paddingVertical: '2em'}, mobile && {height: '100%'}]}>
            <form style={{height: '100%', overflow: 'auto'}}>
                <View style={[styles.formWrapper, mobile && styles.formWrapperMobile]}>
                    <View style={[mobile && styles.fieldsWrapper]}>
                        <View style={[mobile ? styles.nameWrapperMobile : styles.nameWrapper]}>
                            <Controller
                                control={control}
                                name="firstName"
                                rules={{required: t('screens:Register:fieldError',{fieldName: t('screens:Register:firstName',{})})}}
                                render={({ field: { onChange, value }}) => (
                                    <View style={[{flex:1}, styles.inputGap]}>
                                        <TextInputField
                                            nameLabel={t('screens:Register:firstName',{}) + "*"}
                                            isPassword={false}
                                            value={value}
                                            onChange={details?.firstName ? (val) => setValue('firstName', val) : onChange}
                                            overrideStyles={[styles.inputStyle]}
                                        />
                                        <AppText style={[styles.subHeader, styles.errorMsg]}>{errors.firstName?.message}</AppText>
                                    </View>
                                )}
                            />
                            <Controller
                                control={control}
                                name="lastName"
                                rules={{required: t('screens:Register:fieldError',{fieldName: t('screens:Register:lastName',{})})}}
                                render={({ field: { onChange, value }}) => (
                                    <View style={[{flex:1}, styles.inputGap]}>
                                        <TextInputField
                                            nameLabel={t('screens:Register:lastName',{}) + "*"}
                                            isPassword={false}
                                            value={value}
                                            onChange={details?.lastName ? (val) => setValue('lastName', val) : onChange}
                                            overrideStyles={[styles.inputStyle]}
                                        />
                                        <AppText style={[styles.subHeader, styles.errorMsg]}>{errors.lastName?.message}</AppText>
                                    </View>
                                )}
                            />
                        </View>
                        <Controller
                            control={control}
                            name="gender"
                            render={({field: {onChange, value}}) => (
                                <View style={[styles.inputGap]}>
                                    <GroupButton nameLabel={t('screens:Register:gender', {})} buttons={genderButtons.map(btn => t(`common:${btn}`,{}))}
                                                 selectedIndex={value}
                                                 onSelectChanged={(val) => setValue('gender', val)}
                                                 style={{height: '2em'}}/>
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            name="id"
                            rules={{required: t('screens:Register:fieldError',{fieldName: t('screens:Register:id',{})}),
                                    pattern: {value: /^[0-9]*$/, message: t('screens:Register:idError', {})},
                                    minLength: {value: 9, message: t('screens:Register:idLengthError', {})},
                                    maxLength: {value: 9, message: t('screens:Register:idLengthError', {})}
                            }}
                            render={({ field: { onChange, value }}) => (
                                <View style={[{flex:1}, styles.inputGap]}>
                                    <TextInputField
                                        nameLabel={t('screens:Register:id',{}) + "*"}
                                        isPassword={false}
                                        value={value}
                                        keyboardType={'numeric'}
                                        onChange={details?.id ? (val) => setValue('id', val) : onChange}
                                        overrideStyles={[styles.inputStyle]}
                                    />
                                    <AppText style={[styles.subHeader, styles.errorMsg]}>{errors.id?.message}</AppText>
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            name="phone"
                            rules={{pattern: {value: /^[0-9]*$/, message: t('screens:Register:phoneError', {})}}}
                            render={({ field: { onChange, value }}) => (
                                <View style={[styles.inputGap]}>
                                    <TextInputField
                                        nameLabel={t('screens:Register:phone',{})}
                                        isPassword={false}
                                        value={value}
                                        keyboardType={'numeric'}
                                        onChange={details?.phone ? (val) => setValue('phone', val) : onChange}
                                        overrideStyles={[styles.inputStyle]}
                                    />
                                    <AppText style={[styles.subHeader, styles.errorMsg]}>{errors.phone?.message}</AppText>
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            name="birthDate"
                            render={({field: {onChange, value}}) => (
                                <View style={[{flex:1}, styles.inputGap]}>
                                    <DatePickerField startDate={value} onChange={(val) => setValue('birthDate', val)} nameLabel={t('screens:Register:birth-date',{})}/>
                                </View>
                            )}
                        />
                    </View>
                    <GenericAlert type={'warning'} text={t('screens:workshop:group-workshop-warning',{})}/>
                    <View style={[styles.actionsWrapper]}>
                        <ModalButton
                            onPress={onBack}
                            text={t('common:drawer:back', {})}
                            type='primary'
                            colors={Colors.white}
                            textColor={Colors.lightBlack}
                            overrideStyle={[styles.btn, {borderWidth: 1, borderColor: Colors.lightBlack}]}
                            textStyles={{'fontFamily': 'heebo'}}
                        />
                        <Controller
                            control={control}
                            type='submit'
                            name={'participantDetailsSubmit'}
                            render={() => (
                                <ModalButton
                                    onPress={handleSubmit((data)=> submitForm(data))}
                                    text={t('common:next', {})}
                                    type='primary'
                                    colors={Colors.lightBlack}
                                    textColor={Colors.white}
                                    overrideStyle={[styles.btn]}
                                    textStyles={{'fontFamily': 'heebo'}}
                                />
                            )}
                        />
                    </View>
                </View>
            </form>
        </View>
    );
};

export default ParticipantDetails;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            topText: {
                gap: '0.5em',
                position: 'relative'
            },
            header: {
                fontFamily: 'heebo',
                fontSize: '1.3rem',
                color:  Colors.lightBlack,
                alignSelf: 'center'
            },
            subHeader: {
                fontSize: '0.85rem',
                color: Colors.grey
            },
            btn: {
                borderRadius: 3,
                width: '7em',
                minHeight: 'auto',
                paddingVertical: '0.4em',
                marginTop: 0,
            },
            nameWrapper: {
                flexDirection: 'row',
                width: '100%',
                gap: '1em'
            },
            nameWrapperMobile: {
                flexDirection: 'column',
                gap: 0
            },
            formWrapper: {
                // marginTop: '2em'
            },
            formWrapperMobile: {
              height: 'inherit',
              justifyContent: 'space-between'
            },
            inputStyle: {
                marginBottom: 0,
            },
            inputGap: {
                marginBottom: '1em'
            },
            errorMsg: {
                color: Colors.red
            },
            actionsWrapper: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '2em',
                alignItems: 'center'
            },
            stepsWrapper: {
                flexDirection: 'row',
                gap: 6
            },
            step: {
                backgroundColor: Colors.veryLightGrey,
                height: 6,
                width: 6,
                borderRadius: '50%',
                content: ''
            },
            emailExistMsg: {
                alignSelf: 'center',
                textAlign: 'center',
            },
        }
    })
})