import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from '../../styles/Colors';
import { getIsRTLStyle, isTextRTLStyles } from '../../styles/Layout';

const deviceHeight = Dimensions.get('screen').height;
const deviceWidth = Dimensions.get('screen').width;
const styles = {
  // container: {
  //   // position: "relative",
  // },
  // formWrapper: {
  //   backgroundColor: 'white',
  //   position: 'absolute',
  //   top: 50,
  //   zIndex: 1,
  //   paddingBottom: 10,
  //   marginHorizontal: 23,
  //   borderRadius: 5,
  // },
  // fieldWrapper: {
  //   padding: 10,
  //   borderRadius: 5,
  //   shadowRadius: 7,
  //   shadowOffset: { width: 0, height: 2 },
  //   shadowOpacity: 1,
  //   shadowColor: 'rgba(0,0,0,0.1)',
  //   elevation: 4,
  //   backgroundColor: 'white',
  //   ...isTextRTLStyles,
  // },
  // input: {
  //   height: 53,
  //   fontSize: 20,
  //   ...isTextRTLStyles,
  //   textAlign: getIsRTLStyle('left', 'right'),
  // },
  // inputItem: {
  //   marginHorizontal: 17,
  //   borderColor: Colors.lightGrey,
  //   ...isTextRTLStyles,
  //   flexDirection: getIsRTLStyle('row', 'row-reverse'),
  //   justifyContent: 'space-between',
  // },
  // lastInputItem: {
  //   borderBottomWidth: 0,
  // },
  // welcomeContentWrapper: {
  //   flex: 1,
  //   width: '100%',
  //   position: 'relative',
  // },
  // welcomeContent: {
  //   position: 'absolute',
  //   bottom: 50,
  // },
  // gradient: {
  //   height: deviceHeight * 0.6,
  //   alignItems: 'flex-start',
  //   padding: 30,
  //   justifyContent: 'flex-end',
  //   zIndex: 1,
  //   position: 'relative',
  // },
  // logo: {
  //   marginLeft: -20,
  //   marginBottom: 20,
  // },
  // h1: {
  //   color: Colors.light,
  //   fontSize: 25,
  //   fontWeight: '600',
  //   marginBottom: 10,
  //   ...isTextRTLStyles,
  // },
  // h2: {
  //   color: Colors.light,
  //   fontSize: 16,
  //   marginLeft: -35,
  //   ...isTextRTLStyles,
  // },
  // footer: {
  //   justifyContent: 'flex-end',
  //   flexDirection: 'column',
  //   backgroundColor: 'transparent',
  //   height: deviceHeight * 0.4,
  // },
  // links: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   marginHorizontal: 10,
  //   marginBottom: '10%',
  // },
  // link: {
  //   fontSize: 12,
  //   color: Colors.brandPrimary,
  // },
  // errorText: {
  //   color: Colors.brandDanger,
  //   textAlign: 'center',
  //   marginVertical: 10,
  // },
  // loginButton: {
  //   backgroundColor: Colors.brandPrimary,
  // },
};

// styles.formWrapper.top = deviceHeight * 0.6 - styles.input.height - styles.fieldWrapper.padding;
// styles.formWrapper.width = deviceWidth - styles.formWrapper.marginHorizontal * 2;

const dynamicsStyles = StyleSheet.create(styles);
export default dynamicsStyles;
