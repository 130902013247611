import { StyleSheet,Platform } from 'react-native';
import { ScaledSheet, scale,verticalScale } from 'react-native-size-matters';
import { Colors } from '../../styles/Colors';
import { isTextRTLStyles } from '../../styles/Layout';
import i18n from '../../services/i18n';
import {globalStyles} from "../../styles/Global";

const container = {
  paddingVertical: scale(16),
  paddingHorizontal: scale(25),
  flexDirection: 'column',
  borderBottomWidth: scale(1),
  borderBottomColor: Colors.darkWhite,
};

export default StyleSheet.create({
  container,
  greyContainer: {
    ...container,
    backgroundColor: Colors.darkWhite,
  },
  upperBox: {
    // flexDirection: i18n.isRTL ? 'row-reverse' : 'row',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconAndName: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    color: Colors.brandPrimary,
    fontSize: scale(18),
    marginRight: scale(8),
  },
  iconAlt: {
    color: Colors.veryLightGrey,
    fontSize: scale(18),
    marginLeft: scale(8),
    transform: [{ scaleX: i18n.isRTL ? -1 : 1 }],
  },
  rightBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonIcon: {
    color: Colors.lightGrey,
    fontSize: scale(18),
  },
  staticText: {
    color: Colors.lightGrey,
    fontSize: scale(12),
  },
  title: {
    fontSize: scale(12),
    fontWeight: 'bold',
    color: Colors.slateGrey,
    ...isTextRTLStyles,
  },
  description: {
    paddingTop: scale(4),
    color: Colors.lightGrey,
    fontSize: scale(10),
    overflow: 'scroll',
    ...isTextRTLStyles,
    marginRight: 50,
  },
  textInput: {
    marginTop:5,
      marginHorizontal: scale(5),
    textAlign: i18n.isRTL ? 'left' : 'right',
  },
  passwordInput: {
    textAlign: i18n.isRTL ? 'left' : 'right',
  },
});

export const listStyle = ScaledSheet.create({
    inputIOS: {
        fontSize: scale(13),
        paddingVertical: 5,
        paddingHorizontal: 5,
        color: Colors.black,
        fontWeight: '500',
        paddingRight: 30, // to ensure the text is never behind the icon.png
        ...globalStyles.textAlignByRTL
    },
    inputAndroid: {
        fontSize: scale(13),
        paddingVertical: 5,
        paddingHorizontal: 5,
        color: Colors.black,
        paddingRight: 30,
        ...globalStyles.textAlignByRTL
    },
    iconContainer: {
        ...Platform.select({
            android: {
                top: 7,
            },
            ios: {
                top: 4
            }
        })
    }
  // inputIOS: {
  //   fontSize: scale(13),
  //   fontFamily: 'heebo',
  //   color: Colors.black,
  //   width: scale(100),
  //   height: verticalScale(15),
  // },
  // inputAndroid: {
  //     fontSize: scale(13),
  //     fontFamily: 'heebo',
  //     color: Colors.black,
  //     width: scale(100),
  //     height: verticalScale(15),
  // },
  //   icon.png: {
  //       position: 'absolute',
  //       backgroundColor: 'transparent',
  //       borderTopWidth: 5,
  //       borderTopColor: 'black',
  //       borderRightWidth: 5,
  //       borderRightColor: 'transparent',
  //       borderLeftWidth: 5,
  //       borderLeftColor: 'transparent',
  //       width: 0,
  //       height: 0,
  //       top: 20,
  //       right: 150,
  //   },
});
