import * as React from 'react';
import {Platform, View} from "react-native";
import PluginHeader from "../../components/Web/PluginHeader";
import WelcomeScreen from "./WelcomeScreen";
import PluginFooter from "../../components/Web/PluginFooter";
import { Colors } from "../../styles/Colors";
import {getReferer, isMobile} from "../../helpers/functions";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {useEffect, useState} from "react";
import {fetchProfile, setActiveUsersBoxes} from "../../actions/user";
import {useDispatch, useSelector} from "react-redux";
import ShopScreen from "./ShopScreen";
import ScheduleScreen from "./ScheduleScreen";
import {fetchUserMembershipsByBox} from "../../actions/memberships";
import EStyleSheet from "react-native-extended-stylesheet";
import {
    addBoxToSiteList, changeActiveBox,
    fetchAppLink, fetchBoxById, updateBoxQueryString,
    updateIsSite, updateSiteSelectedLocation, updateUserCreatedFlag,
    updateWhitelabel
} from "../../actions/minisite";
import IFrameResponse from "./IFrameResponse";
import {getProperties} from "../../actions/whiteLabelProperties";
import {changeSiteLang} from "../../helpers/ComponentFunctionality";
import SingleShopItemScreen from "./SingleShopItemScreen";
import PurchaseFlowsHandler from "../../components/Web/PurchaseFlows/PurchaseFlowsHandler";
import {Alert} from "react-native-web";
import GenericAlert from "../../components/Web/Common/GenericAlert";
import FullRegistrationHandler from "../../components/Web/Registration/FullProcess/FullRegistrationHandler";


export const PluginEntryScreen = (props) => {

    const dispatch = useDispatch()
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const minisiteBox = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const user = useSelector(state => state.user.data)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const mobile = isMobile()

    document.getElementsByTagName('body')[0].style.overflow = "hidden";
    document.title = minisiteBox?.name + ' | Arbox'

    const tabChanged = () => {
        if(!document.hidden) dispatch(changeActiveBox(params.identifier))
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', tabChanged)
        const identifier = params.identifier;
        const whitelabel = params.whitelabel;
        const referer = params.referer;
        const lang = params.lang

        if(identifier) {
            dispatch(addBoxToSiteList(identifier, whitelabel, referer))
            dispatch(fetchBoxById(params.identifier))
        } else if(minisiteBox && minisiteBox.external_url_id) {
            dispatch(fetchBoxById(minisiteBox.external_url_id))
        }
        if(referer) {
            const referer = getReferer(params.referer)
            dispatch(updateIsSite(referer))
        }
        if(whitelabel || (!whitelabel && identifier)) {
            dispatch(updateWhitelabel(whitelabel || 'Arbox'))
            dispatch(fetchAppLink())
            dispatch(getProperties())
        }
        if(lang) {
            changeSiteLang(lang,dispatch)
        }

        dispatch(updateBoxQueryString(identifier, whitelabel, referer, lang))

        if(isLoggedIn && minisiteBox) {
            dispatch(fetchProfile())
            dispatch(fetchUserMembershipsByBox(minisiteBox.id, true))
        }
    }, []);

    useEffect(() => {
        if(user && Object.keys(user).length && minisiteBox) {
            const users_boxes = user.users_boxes.find((item => item.box_fk === minisiteBox.id));
            if(users_boxes){
                dispatch(setActiveUsersBoxes(users_boxes));
            }
        }
    }, [user, minisiteBox]);

    useEffect(() => {
        if(minisiteBox && params.location) {
            const newLocation = minisiteBox.locations_box.find(item => item.id == params.location)
            if(newLocation) dispatch(updateSiteSelectedLocation(newLocation))
        }
    }, [minisiteBox]);

    return (
        <View style={styles(mobile).container}>
            <>
                <Router>
                    <PluginHeader />
                    <Routes>
                        <Route exact path="/" element={<WelcomeScreen />} />
                        <Route exact path="/shop" element={<ShopScreen />} />
                        <Route exact path="/shop/item/:id" element={<SingleShopItemScreen/>} />
                        <Route exact path="/schedule" element={<ScheduleScreen />} />
                        <Route exact path="/cart" element={<PurchaseFlowsHandler />} />
                        <Route exact path="/register" element={<PurchaseFlowsHandler />} />
                        <Route exact path="/iframeResponse" element={<IFrameResponse />} />
                        <Route exact path="/eden" element={<GenericAlert type={'warning'} text={'In order to reserve a spot for this workshop please fill in the parent’s/ legal guardian’s information first, and then the childs’s.'} />} />
                    </Routes>
                    {/*{isMobile() ? null : <PluginFooter />}*/}
                </Router>
            </>
        </View>
    );
};

const styles = (mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            container: {
                flex: 1,
                backgroundColor: Colors.white,
                overflow: 'hidden'
            },
        }
    })
})