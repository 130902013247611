import { StyleSheet } from 'react-native';
import { scale,verticalScale } from 'react-native-size-matters';
import {globalStyles} from "../../../styles/Global";

const styles = StyleSheet.create({
  containerFlex: {
    flex: 1,
  },
  containerFlexCentered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabIndicator: {
    backgroundColor: 'white',
  },
  tabBackground: {
    backgroundColor: 'transparent',
  },
  safeAreaViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
      ...globalStyles.appPaddingHorizontal
    // marginHorizontal: 20,
  },
  dropdownPadding: {
    paddingTop: 20,
    paddingBottom: 5,
  },
  scheduleIcon: {
    fontSize: 20,
    color: 'white',
  },
  calendarToggleButton: {
    alignSelf: 'center',
    //alignSelf:'flex-start',
    top: scale(8),
  },
});

export default styles;
