import React from 'react';
import {AppText} from "../AppText";
import Hyperlink from 'react-native-hyperlink'

import uuid from 'uuid'
import {Colors} from "../../../styles/Colors";
import {Platform, Text} from "react-native";
import {scale} from "react-native-size-matters";
import {UnicodeHelper} from "../UnicodeHelper";
import i18n from "../../../services/i18n";

export class AppTextMultiLine extends React.PureComponent {
    render() {
        const textArray = this.props.value.split('\n');
        const isAndroid = Platform.OS.toLowerCase() === 'android';
        const unicode = '\u200e';
        return (

            <Hyperlink key={uuid.v4()} linkDefault={true} linkStyle={{color: Colors.paleblue}}>
                {textArray.map((value) => {
                    return this.props.forceText ? <Text style={{fontFamily: 'heebo-light', fontSize: scale(13)}} key={uuid.v4()}>{isAndroid ? <Text>{unicode}</Text> : null}{value}</Text> : <AppText key={uuid.v4()}>{value}</AppText>;
                })}
            </Hyperlink>
        )
    }
}

