import React, {useEffect, useState} from 'react'
import {Platform, View} from 'react-native'
import {useDispatch, useSelector} from "react-redux";
import { Colors } from "../../styles/Colors"
import {isMobile} from "../../helpers/functions";
import LoginScreen from "../../screens/Web/LoginScreen";
import {logoutUserProfile} from "../../actions/user";
import {logoutUser} from "../../actions/auth";
import EStyleSheet from "react-native-extended-stylesheet";
import SiteDesktopHeader from "./Headers/SiteDesktopHeader";
import PluginDesktopHeader from "./Headers/PluginDesktopHeader";
import {ScheduleMobileHeader} from "./Headers/ScheduleMobileHeader";
import {toggleScheduleSiteReFetch} from "../../actions/minisite";
import {SITE_REFERER} from "../../constants";
import MobilePageHeader from "./Headers/MobilePageHeader";
import {globalSiteStyles} from "../../styles/Global";
import useSiteV2Redirect from "../../services/useSiteV2Redirect";


const PluginHeader = () => {
    const referer = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.referer)
    const [showLoginModal, setShowLoginModal] = useState(false);
    const dispatch = useDispatch()
    const mobile = isMobile();
    useSiteV2Redirect({})

    const handleLogout = async () => {
        dispatch(logoutUserProfile())
        dispatch(logoutUser()).then(() => dispatch(toggleScheduleSiteReFetch()))
    }

    return (
        <>
            {
                mobile ?
                    <MobilePageHeader handleShowLogin={() => setShowLoginModal(true)} style={[styles.wrapper]}/>
                    :
                    <View style={[globalSiteStyles(mobile).siteHeaderShadow, styles.wrapper]}>
                        {
                            referer === SITE_REFERER ?
                                <SiteDesktopHeader handleLogout={handleLogout} setShowLogin={() => setShowLoginModal(true)}/>
                                :
                                <PluginDesktopHeader handleLogout={handleLogout} setShowLogin={() => setShowLoginModal(true)}/>
                        }
                    </View>
            }

            <LoginScreen show={showLoginModal} handleSubmit={() => setShowLoginModal(false)} setClose={() => setShowLoginModal(false) } handleForgotPassword={() => setShowForgotPassword(true)}/>

        </>
    )
}

export default PluginHeader;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                backgroundColor: Colors.white,
                width: "100vw",
                paddingVertical: '0.5em',
                paddingHorizontal: '1.5em',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '4em',
                maxHeight: '4em',
            },

        }
    })
})
