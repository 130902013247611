import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={50} height={50}
        viewBox="0 0 50 50"
        fill="#191919"
        {...props}
    >
        <Path d="M15.5 11c-3.05 0-5.691 1.043-7.563 2.906C6.067 15.77 5 18.446 5 21.531v.657c-2.86.68-5 3.25-5 6.312 0 3.047 2.156 5.492 5 6.188v5.624c0 2.731 1.648 5.09 4 6.126V49c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2h16v2c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.563a6.702 6.702 0 0 0 4-6.124v-5.626c2.844-.69 5-3.14 5-6.187 0-3.063-2.14-5.633-5-6.313v-.656c0-3.086-1.066-5.761-2.938-7.625C40.193 12.043 37.551 11 34.5 11Zm0 2h19c2.602 0 4.688.848 6.156 2.313C42.125 16.776 43 18.895 43 21.53v.5c-3.344.262-6 3.063-6 6.469 0 1.117-.254 2.602-.875 3.688C35.504 33.273 34.668 34 33 34H17c-1.668 0-2.504-.727-3.125-1.813-.621-1.085-.875-2.57-.875-3.687 0-3.406-2.656-6.207-6-6.469v-.5c0-2.636.875-4.754 2.344-6.218C10.813 13.848 12.898 13 15.5 13Zm-9 11c2.496 0 4.5 2.004 4.5 4.5 0 1.383.246 3.148 1.125 4.688C13.004 34.727 14.668 36 17 36h16c2.332 0 3.996-1.273 4.875-2.813C38.754 31.648 39 29.884 39 28.5c0-2.496 2.004-4.5 4.5-4.5.11 0 .207.023.313.031C46.16 24.195 48 26.11 48 28.5a4.481 4.481 0 0 1-4.125 4.469l-1.031.093.125 1.032c.02.176.031.328.031.5v5.718c0 2.145-1.422 3.903-3.375 4.47-.043.019-.086.038-.125.062a4.758 4.758 0 0 1-1.188.156H11.689c-.415 0-.805-.059-1.188-.156l-.031-.032c-.024-.003-.04-.023-.063-.03a1.116 1.116 0 0 0-.25-.063A4.634 4.634 0 0 1 7 40.312v-5.718c0-.172.012-.328.031-.5l.125-1.032-1.031-.093A4.481 4.481 0 0 1 2 28.5C2 26.004 4.004 24 6.5 24ZM11 46.969c.223.023.457.031.688.031H15v1h-4Zm28 0V48h-4v-1h3.313c.23 0 .464-.008.687-.031Z" />
    </Svg>
)

export default SvgComponent