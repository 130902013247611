import React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';
import {getThemeProperty, keys} from "../../src/styles/Theme";

const ChilloutIcon = props => (
  <Svg height={145} width={153} {...props}>
    <G fill="none">
      <Circle
        cx={76.5}
        cy={72.5}
        fill="#d6d6d6"
        opacity={0.2}
        r={69}
        stroke="#e6e6e6"
      />
      <Path
        d="M129.328 59.2c-8.022 0-14.524-6.537-14.524-14.6S121.306 30 129.328 30c8.022 0 14.525 6.537 14.525 14.6s-6.503 14.6-14.525 14.6zm4.736 2.14c5.026-1.433 9.121-5.093 11.164-9.85 2.729 4.352 4.495 7.851 5.3 10.497.625 2.054 1.336 5.213 2.135 9.477a7.72 7.72 0 0 1-3.57 8.965 7.201 7.201 0 0 1-3.947 1.171c-4.02.272-7.046.407-9.08.407-2.018 0-5.068-.135-9.15-.407a13.016 13.016 0 0 1-1.968 1.987L92.89 110.379A19.834 19.834 0 0 1 80.17 115H9.152C4.098 115 0 110.881 0 105.8s4.098-9.2 9.152-9.2h8.716a9.154 9.154 0 0 1 2.874-3.856l30.026-23.735a10.106 10.106 0 0 1 12.617.058l19.252 15.505L96.3 73.45c-2.864-6.341-4.703-11.042-5.52-14.102-1.267-4.755-1.953-9.266-1.953-13.933 0-4.668 1.261-7.898 5.135-8.941.281-.076.563-.134.844-.175a7.624 7.624 0 0 1 2.189-.16c4.14.083 7.205.195 9.194.335 1.947.137 4.45.35 7.508.64a17.407 17.407 0 0 0-1.68 7.487c0 2.756.638 5.363 1.773 7.68a37.075 37.075 0 0 0-1.857-.209c-1.745-.144-4.212-.194-7.402-.15.426 2.717.873 4.727 1.342 6.029.468 1.302 1.396 3.116 2.783 5.442l.013-.01c5.55-4.52 13.695-3.659 18.19 1.92.437.544.824 1.111 1.16 1.698h7.265c.263-.295.042-1.6-.663-3.916a69.001 69.001 0 0 0-.558-1.743zM68.326 96.6l-10.685-8.698a.793.793 0 0 0-.99-.008L45.563 96.6z"
        fill={getThemeProperty(keys.brandedGreen)}
      />
    </G>
  </Svg>
);

export default ChilloutIcon;
