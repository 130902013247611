import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import getEnvVars from "../config/Env";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {apiAction} from "../helpers/HTTP";

const useSiteV2Redirect = (props) => {
    const { } = props;
    const minisiteBox = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const location = useLocation();
    const {httpPrefix, siteV2Url}= getEnvVars();

    useEffect(() => {
        console.log('useSiteV2Redirect!!!', location)
    }, [location]);

    useEffect(() => {
        if(minisiteBox) {
            shouldRedirect()
        }
    }, [window.location.href, params, minisiteBox, location]);

    const shouldRedirect = async () => {
        let redirect = true;
        if(window.location.pathname.includes('schedule') && params?.scheduleType === 'workshops') redirect = false;
        if(window.location.pathname.includes('cart') || window.location.pathname.includes('register')) redirect = false;
        const response = await apiAction('site/boxes/isFirstPay/'+ params.identifier, 'get', null);
        if(response) redirect = false;
        if(redirect) {
            translateToSiteV2()
        }
    }

    const translateToSiteV2 = () => {
        const path = window.location.pathname.toLowerCase()
        let route = '/'
        let searchParams = `whitelabel=${params.whitelabel ?? 'Arbox'}`

        if(path.includes('schedule')) {
            if(!params.scheduleType || params.scheduleType === 'schedule') {
                route = '/group'
            } else if(params.scheduleType === 'appointment') {
                if(params.availabilityType === 'SPACE_AVAILABILITY_TYPE') {
                    route = '/space-appointment'
                } else {
                    route = '/staff-appointment'
                }
            }
        } else if(path.includes('shop') || path.includes('cart')) {
            route = '/membership'
            if(path.includes('shop/item/')) {
                const itemId = path.split('/').pop()
                route = `/membership/${itemId}`
            }
        }

        if(params.lang) searchParams += `&lang=${params.lang}`
        if(params.location) searchParams += `&location=${params.location}`
        if(params.referer) searchParams += `&referrer=${params.referer}`

        let redirectUrl = `${httpPrefix}${params.identifier}.${siteV2Url}${route}?${searchParams}`
        window.location.href = redirectUrl
    }
};

export default useSiteV2Redirect;