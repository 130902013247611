// @flow
import * as React from 'react';
import {isMobile} from "../../../helpers/functions";
import {Platform, View} from "react-native";
import RightConfety from "../../../svg/custom/ConfetyRight";
import LeftConfety from "../../../svg/custom/ConfetyLeft";
import BookingSuccess from "../../../svg/Icons8Ok";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";
import CalendarChecked from "../../../svg/Icons8Today";
import {t} from "../../../services/i18n";
import ModalButton from "../../ModalButton";
import PopupModal from "./PopupModal";
import EStyleSheet from "react-native-extended-stylesheet";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {Linking, TouchableOpacity} from "react-native-web";
import {useEffect} from "react";
import {useSelector} from "react-redux";

const GenericSuccessModal = (props) => {
    const {show, setClose, title, subHeader, iconName, isPopup} = props;
    const appLinkFromStore = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.appLink.data)

    const mobile = isMobile();


    const renderComponent = () => {
        const content =
            <View style={[styles.wrapper, mobile && styles.mobileWrapper]}>
                <View style={[styles.iconSide]}>
                    <Icons8Generator name={iconName} size={mobile || isPopup ? '5em' : '10em'} fill={Colors.darkGrayContent} style={{
                        padding: 25,
                        transform: 'rotate(-15deg)'
                    }}/>
                    <BookingSuccess fill={Colors.brandedGreen} width={mobile ? '2em' : '4em'} height={mobile ? '2em' : '4em'} style={[mobile ? styles.checkMobile : styles.check]}/>
                </View>
                <View>
                    <View style={[styles.headerWrapper]}>
                        <AppText style={[styles.header]} boldFont>{title}</AppText>
                        <AppText style={[styles.subHeader]}>{subHeader}</AppText>
                    </View>
                    {!mobile && <View style={[styles.separator]}/>}
                    {!mobile &&
                        <View style={[styles.appWrapper]}>
                            <AppText
                                style={[styles.downloadAppHeader]}>{t('screens:Register:successPage:download-mobile-app', {})}</AppText>
                            <View style={[styles.appContentWrapper]}>
                                <View style={[styles.contentRow]}>
                                    <Icons8Generator name={'check'} fill={Colors.darkGrayContent} size={'1em'}/>
                                    <AppText
                                        style={[styles.downloadAppContent]}>{t('screens:Register:successPage:booking-payment', {})}</AppText>
                                </View>
                                <View style={[styles.contentRow]}>
                                    <Icons8Generator name={'check'} fill={Colors.darkGrayContent} size={'1em'}/>
                                    <AppText
                                        style={[styles.downloadAppContent]}>{t('screens:Register:successPage:reminders-updates', {})}</AppText>
                                </View>
                                <View style={[styles.contentRow]}>
                                    <Icons8Generator name={'check'} fill={Colors.darkGrayContent} size={'1em'}/>
                                    <AppText
                                        style={[styles.downloadAppContent]}>{t('screens:Register:successPage:manage-booking', {})}</AppText>
                                </View>
                            </View>
                        </View>
                    }
                </View>
                {mobile &&
                    <TouchableOpacity onPress={()=> Linking.openURL(appLinkFromStore)} style={styles.mobileDownloadAppWrapper}>
                        <Icons8Generator name={'download'} size={'3em'} fill={Colors.darkGrayContent}/>
                        <AppText style={[styles.downloadAppText]}>{t('screens:Register:successPage:download-app', {})}</AppText>
                    </TouchableOpacity>
                }
                {mobile && <TouchableOpacity onPress={setClose}><AppText style={[styles.downloadAppHeader]}>{t('common:drawer:close',{})}</AppText></TouchableOpacity>}
            </View>
        return isPopup ? <PopupModal show={show} setClose={setClose} overrideStyles={[styles.modal, isPopup && !mobile && {padding: '3em'}]} hideCloseBtn={false}>{content}</PopupModal> : <View style={styles.notModal}>{content}</View>

    }

    return (
        <>{renderComponent()}</>
    );
};

export default GenericSuccessModal;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: '1em',
                paddingVertical: '1em',
                margin: 'auto'
            },
            notModal: {
                width: '100%',
                paddingHorizontal: '15%',
                marginTop: '10%'
            },
            wrapper: {
                flexDirection: 'row',
                justifyContent: 'space-between'
            },
            mobileWrapper: {
                flexDirection: 'column' ,
                gap: '3em',
                alignItems: 'center',
                textAlign: 'center',
                margin: 'auto'
            },
            iconSide: {
                alignSelf: 'center',
                position: 'relative'
            },
            headerWrapper: {
              gap: '1em',
            },
            header: {
                fontSize: '1.7rem',
                color: Colors.lightBlack,
                maxWidth: '10em'
            },
            subHeader: {
                fontSize: '1.1rem',
                color: Colors.darkGrayContent,
                width: '17em'
            },
            separator: {
                height: 2,
                width: '100%',
                backgroundColor: Colors.graySeparator,
                marginVertical: '2em'
            },
            appWrapper: {
              gap: '1em'
            },
            appContentWrapper: {
                gap: '0.7em'
            },
            downloadAppHeader: {
                fontSize: '1rem',
                color: Colors.darkGrayContent,
                fontWeight: 'bold'
            },
            downloadAppContent: {
                fontSize: '0.9rem',
                color: Colors.darkGrayContent
            },
            contentRow: {
                flexDirection: 'row',
                gap: '1em',
                alignItems: 'center'
            },
            check: {
                position: 'absolute',
                right: 8,
                top: -10
            },
            checkMobile: {
                position: 'absolute',
                right: 20,
                top: 5
            },
            mobileDownloadAppWrapper: {
                gap: '1em',
                alignItems: 'center',
                textAlign: 'center'
            },
            downloadAppText: {
                fontWeight: 'bold'
            }
        }
    })
})