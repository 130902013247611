import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8TwoTickets(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M33.656 4c-.508 0-.996.184-1.375.563l-2.625 2.625-1.593-1.625A1.988 1.988 0 0026.655 5c-.508 0-.996.184-1.375.563L1.563 29.28a1.978 1.978 0 000 2.782L5.78 36.25A1 1 0 007 36.406a1.875 1.875 0 012.313.282c.636.636.722 1.578.28 2.312a1 1 0 00.157 1.219l4.188 4.218c.761.762 2.019.762 2.78 0l2.595-2.624 1.625 1.624c.761.762 2.019.762 2.78 0l23.72-23.718a1.978 1.978 0 000-2.782l-4.22-4.187A1 1 0 0042 12.594a1.875 1.875 0 01-2.313-.281 1.875 1.875 0 01-.28-2.313 1 1 0 00-.157-1.219l-4.188-4.219A1.988 1.988 0 0033.657 4zm0 2h.032L37.5 9.844c-.422 1.344-.281 2.812.781 3.875 1.063 1.062 2.532 1.203 3.875.781L46 18.313v.03L22.344 42h-.032l-1.562-1.594 16.75-16.75.313.313a1.006 1.006 0 001.437-1.407l-.313-.312 1.5-1.531a1.982 1.982 0 000-2.782L36.22 13.75A1 1 0 0035 13.594a1.875 1.875 0 01-2.313-.281 1.875 1.875 0 01-.28-2.313 1 1 0 00-.157-1.219l-1.156-1.187zm-7 1h.032l1.968 1.969c.098.332.356.59.688.687l1.156 1.188c-.422 1.344-.281 2.812.781 3.875 1.063 1.062 2.532 1.203 3.875.781L39 19.344l-2.031 2.062a.472.472 0 00-.063.063l-.093.062a.954.954 0 00-.126.157l-17.75 17.687c-.32.105-.566.363-.656.688L15.344 43 11.5 39.156c.422-1.343.281-2.812-.781-3.875-1.063-1.062-2.531-1.203-3.875-.781L3 30.687v-.03L21.625 12.03l.844.313.75-.531.031-.907-.219-.281zm-2.343 5.469l-.72.5-.03.906.187.281.719.719.843.281.72-.5.03-.906-.187-.281-.719-.719zm2.843 2.844l-.75.5-.031.906.219.281.687.688.844.312.75-.531.031-.907-.218-.28-.688-.688zm2.813 2.812l-.719.5-.031.906.187.282.719.718.844.282.718-.5.032-.907-.188-.281-.718-.719zm2.843 2.844l-.75.5-.03.906.218.281.688.719.843.281.75-.531.032-.875-.188-.313-.719-.687z" />
    </Svg>
  );
}

export default SvgIcons8TwoTickets;
