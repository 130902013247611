import React from 'react';
import {connect} from "react-redux";
import {withNavigation} from 'react-navigation';
import {CalendarProvider, ExpandableCalendar} from 'react-native-calendars';
import {bindActionCreators} from "redux";
import {
    View,
    ActivityIndicator,
    Platform,
} from 'react-native';
import moment from 'moment';
import styles from './styles';
import {Colors} from "../../../styles/Colors";
import {
    fetchTodaySchedules,
    fetchUserClasses,
    fetchWeeklySchedules,
    fetchAvailabilitiesByDate
} from "../../../actions/schedule";
import FlatListHandler from "../../../components/FlatListHandler";
import i18n from "../../../services/i18n";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {getThemeProperty, keys} from "../../../styles/Theme";
import {SERVER_DATE_FORMAT} from "../../../variableConstants";
import {ScheduleTypes} from "../../../constants/schedule";


@withNavigation
class ScheduleScreen extends React.PureComponent {


    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            refreshing: false,
            horizontal: true,
            isLoading: true,
            calendarDate: moment(),
            monthChanged: false,
            markedDates: {},
            updateSources: ['calendarInit', 'todayPress', 'listDrag', 'dayPress', 'pageScroll', 'weekScroll', 'propUpdate'],
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.monthChanged = this.monthChanged.bind(this);
        this.getMarkedDates = this.getMarkedDates.bind(this);
        this.onVisibleMonthsChange = this.onVisibleMonthsChange.bind(this);
        this.renderArrow = this.renderArrow.bind(this);
        this.callFetchUserClasses = this.callFetchUserClasses.bind(this);
        this.getDataByType = this.getDataByType.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        this.onUserClassesReload = false;
        const calendarDate = this.props.date ? this.props.date : moment();
        const between = calendarDate.clone();
        if (this.props.type === 'byDate') {
            await this.props.fetchWeeklySchedules(between.clone().startOf('month'), between.clone().endOf('month'), this.props.currentLocationsBox);
        }
        this.setState({calendarDate}, () => {
            this.init();
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async componentDidUpdate(prevProps) {
        if (this.props.currentLocationsBox !== prevProps.currentLocationsBox) {
            await this.props.fetchTodaySchedules(this.state.calendarDate, this.props.currentLocationsBox);
            await this.props.fetchUserClasses(moment(), this.props.currentLocationsBox);
            if (this.props.type === 'byDate') {
                await this.props.fetchWeeklySchedules(this.state.calendarDate.clone().startOf('month'), this.state.calendarDate.clone().endOf('month'), this.props.currentLocationsBox);
            }
        }
        if (this.props.weekly !== prevProps.weekly) {
            const markedDates = await this.getMarkedDates();
            this.setState({markedDates}, () => {
                setTimeout(() => {
                    this.forceUpdate()
                }, 1000)
            });
        }
        if (this.props.date !== prevProps.date) {
            this.setState({calendarDate: this.props.date}, async () => {
                this.init();
            });
        }
        if (this.props.schedules !== prevProps.schedules) {
            this.onUserClassesReload = true;
        }
        if (this.props.index !== prevProps.index && this.props.type === 'userClasses') {
            if (this.onUserClassesReload === true) {
                this.init();
                this.onUserClassesReload = false;
            }

        }
    }


    onRefresh() {
        this.setState({refreshing: true});
        this.init();
    }


    async init() {
        //Did we really need reduce here?
        this.setState({isLoading: true}, async () => {
            switch (this.props.type) {
                case ScheduleTypes.BY_DATE:
                    await this.props.fetchTodaySchedules(this.state.calendarDate, this.props.currentLocationsBox);
                    break;
                case ScheduleTypes.USER_CLASSES:
                    await this.props.fetchUserClasses(moment(), this.props.currentLocationsBox);
                    break;
                case ScheduleTypes.AVAILABILITIES:
                    await this.props.fetchAvailabilitiesByDate(this.state.calendarDate, this.props.currentLocationsBox, this.props.selectedBoxCategory.id);//Do I need redux here?
                    break;
                default:
                    break;
            }
            this.setState({isLoading:false});
        });

    }

    onVisibleMonthsChange(value) {

    }


    async monthChanged(month) {
        const between = moment.utc(month.dateString);
        await this.props.fetchWeeklySchedules(between.clone().startOf('month'), between.clone().endOf('month'), this.props.currentLocationsBox);
    }


    onDateChange(date, updateSource) {
        if (this._isMounted && updateSource !== 'propUpdate') {
            this.setState({calendarDate: moment.utc(date)}, () => {
                this.init();
            });
        }

    }

    async callFetchUserClasses(date, direction) {
        await this.props.fetchUserClasses(date, this.props.currentLocationsBox, direction);
    }

    getMarkedDates = () => {
        const marked = {};
        const current = this.state.calendarDate.clone().format(SERVER_DATE_FORMAT);
        this.props.weekly.forEach(item => {
            const color = current === item ? Colors.white : this.props.whiteLabelProperties.primaryColor;
            marked[item] = {marked: true, dotColor: color};
        });
        marked[current] = marked[current] ? {...marked[current],selected: true} : {selected: true}
        return Object.assign({}, JSON.parse(JSON.stringify(marked)));
    };


    renderArrow(direction) {
        let icon = i18n.isRTL ? direction === 'right' ? 'arrow-rtl' : 'arrow' : direction !== 'right' ? 'arrow-rtl' : 'arrow';
        return (
            <Icons8Generator name={icon} fill={Colors.black}/>
        )
    }

    getDataByType() {
        const {schedules, userClasses, availabilities, type} = this.props;
        switch (type) {
            case ScheduleTypes.BY_DATE:
                return schedules;
            case ScheduleTypes.USER_CLASSES:
                return userClasses;
            case ScheduleTypes.AVAILABILITIES:
                return availabilities;
            default:
                return [];
        }
    }

    render() {
        const {type, isLoadingMoreClasses} = this.props;
        const {isLoading} = this.state;
        const current = this.state.calendarDate.clone().format(SERVER_DATE_FORMAT);
        if (isLoading && ![ScheduleTypes.BY_DATE, ScheduleTypes.AVAILABILITIES].includes(type)) {
            return <ActivityIndicator/>
        }
        let calendarEvents = JSON.parse(JSON.stringify(this.getMarkedDates()));

        const isAndroidAndRTL = Platform.OS.toLowerCase() === 'android' && i18n.isRTL;

        return (
            <View style={[styles.wrapper]}>
                {[ScheduleTypes.BY_DATE, ScheduleTypes.AVAILABILITIES].includes(type) ?
                    (
                        <CalendarProvider
                            date={current}
                            onDateChanged={this.onDateChange}
                            onMonthChange={this.monthChanged}
                            key={current}
                            style={[{flex: 0}]}
                        >
                            <ExpandableCalendar
                                current={current}
                                style={styles.expendableCalendar}
                                theme={{
                                    key: 'dark',
                                    selected: this.props.whiteLabelProperties.primaryColor,
                                    selectedDayBackgroundColor:this.props.whiteLabelProperties.primaryColor,
                                    selectedDayTextColor: '#fff',
                                    todayTextColor: this.props.whiteLabelProperties.primaryColor,
                                }}
                                hideKnob={Platform.OS.toLowerCase() === 'android'}
                                markedDates={calendarEvents}
                                disablePan={Platform.OS.toLowerCase() === 'android'} //we need this
                                disableWeekScroll={isAndroidAndRTL}
                                firstDay={this.props.firstDay}
                                renderArrow={this.renderArrow}
                            />
                        </CalendarProvider>
                    ) : null}
                <View style={{flex: 1}}>
                    <FlatListHandler
                        schedules={this.getDataByType()}
                        type={type}
                        date={current}
                        fetchUserClasses={this.callFetchUserClasses}
                        isLoading={this.state.isLoading}
                        isLoadingMoreClasses={isLoadingMoreClasses}
                        onRefresh={this.onRefresh}
                        coachCount={this.props.coachCount}
                    />
                </View>
            </View>

        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchTodaySchedules,
            fetchUserClasses,
            fetchWeeklySchedules,
            fetchAvailabilitiesByDate
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    schedules: state.schedule.schedules.data,
    availabilities: state.schedule.availabilities.data,
    userClasses: state.schedule.userClasses.data,
    isLoadingMoreClasses: state.schedule.userClasses.isLoading,
    weekly: state.schedule.weekly.data,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ScheduleScreen);



