import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgHello(props) {
  return (
      <Svg width={130} height={120} viewBox="0 0 130 120" {...props}>
          <G fillRule="nonzero" fill="none">
              <Path
                  d="M36.187 75.068l23.239-23.099 1.91-1.898-30.243-29.11c-2.228-2.215-3.82-8.543.319-12.657 4.138-4.113 10.187-1.898 12.415.317 1.91 2.215 35.972 35.438 45.522 44.93 9.55 9.493 15.917 0 15.917 0L118 66.208c-6.367 12.656-6.367 22.149-16.235 31.325l-14.326 14.239c-10.823 10.758-28.013 11.074-38.837.316L26 89.623l10.187-14.555z"
                  fill="#FFCC80"
              />
              <Path
                  d="M69.542 58.979c3.481 3.466 3.165 8.824-.316 12.29-3.481 3.467-8.86 4.097-12.025.63L15.115 30.932c-3.48-3.466-3.164-8.824.317-12.29a8.918 8.918 0 0112.34-.315l41.77 40.653zm-16.454 33.09c3.48 3.466 3.164 8.824-.317 12.29a8.918 8.918 0 01-12.34.315L2.457 67.173c-3.481-3.467-3.165-8.824.316-12.291a8.918 8.918 0 0112.341-.315l37.973 37.502z"
                  fill="#FFCC80"
              />
              <Path
                  d="M61.804 74.4c3.5 3.475 2.546 9.478-.955 12.953a8.991 8.991 0 01-12.412.316L5.472 45.968c-3.5-3.475-3.183-8.846.318-12.32a8.991 8.991 0 0112.412-.317l43.602 41.07z"
                  fill="#FFCC80"
              />
              <Path fill="#FFA000" d="M102 72l19-25" />
              <Path
                  d="M101.492 82c-1.901 0-4.12-.629-5.704-1.886-4.12-3.144-5.07-9.118-1.902-13.205l19.016-25.151c3.169-4.087 9.19-5.03 13.31-1.887 4.12 3.144 5.07 9.118 1.902 13.205l-19.016 25.151c-1.901 2.515-4.753 3.773-7.606 3.773z"
                  fill="#FFCC80"
              />
              <Path
                  d="M32.196 22L61.06 50.566c1.255 1.27 1.255 2.857 0 4.126-1.255 1.27-3.137 1.27-4.079 0L32.196 22zm-15.372 9.522L51.02 65.166c1.255 1.27 1.255 3.174 0 4.127-1.255 1.27-2.824 1.27-4.079 0L16.824 31.522zM14 54.057l27.608 26.98c1.255 1.269 1.255 3.173 0 4.126-1.255.952-3.137 1.27-4.079 0L14 54.057z"
                  fill="#F2A25A"
              />
              <Path
                  d="M36.59 120C20.365 110.185 7.637 94.67 7 94.037l5.09-4.116s12.728 15.514 27.683 24.696L36.59 120zm52.82-77.256C77.317 21.53 56 9.182 55.681 9.182L58.864 3.8c.954.634 23.227 13.299 36.272 36.095l-5.727 2.85zm3.18-19.314C86.865 11.715 75.09 6.016 74.774 5.7L77.636 0C78.273.317 91.318 6.966 98 20.264l-5.41 3.166z"
                  fill="#F5C2D1"
              />
          </G>
      </Svg>
  );
}

export default SvgHello;
