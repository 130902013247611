import React from 'react';
import {Icon} from 'native-base';
import {getIconByName} from "../../../helpers/functions";
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";


export const RNPickerIcon = props => {
    return (
        <Icon name="caretdown" type="AntDesign"
              style={[{color: props.color ? props.color : Colors.black , fontSize: scale(16),marginTop:verticalScale(9),marginHorizontal: scale(5)}
                  ,props.style ? {...props.style} : {} ]}/>
    )
};



