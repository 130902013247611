import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Print(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M11 2v13H5c-1.645 0-3 1.355-3 3v18c0 1.645 1.355 3 3 3h6v9h28v-9h6c1.645 0 3-1.355 3-3V18c0-1.645-1.355-3-3-3h-6V2H11zm2 2h24v11H13V4zM5 17h6.832a1 1 0 00.326 0h25.674a1 1 0 00.326 0H45c.565 0 1 .435 1 1v18c0 .565-.435 1-1 1h-6v-9H11v9H5c-.565 0-1-.435-1-1V18c0-.565.435-1 1-1zm36 3a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zM13 30h24v7.832a1 1 0 000 .326V46H13v-7.832a1 1 0 000-.326V30zm4 3a1 1 0 100 2h16a1 1 0 100-2H17zm0 4a1 1 0 100 2h12a1 1 0 100-2H17zm0 4a1 1 0 100 2h16a1 1 0 100-2H17z" />
    </Svg>
  );
}

export default SvgIcons8Print;
