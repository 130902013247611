import {connect} from 'react-redux';
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {withNavigation} from 'react-navigation';
import PropTypes from 'prop-types';
import ProfileScreen from '../../screens/ProfileScreen';
import {fetchProfile} from '../../actions/user';


class ProfileContainer extends Component {
    state = {
        isInEditMode: false,
    };

    constructor(props) {
        super(props);
        this.handleEdit = this.handleEdit.bind(this);
    }

    goToCameraScreen = () => this.props.navigation.navigate('Camera');

    handleEdit() {
        this.setState(prevState => ({isInEditMode: !prevState.isInEditMode}));
    }

    render() {

        return (
            <ProfileScreen
                isInEditMode={this.state.isInEditMode}
                onPressEdit={this.handleEdit}
                goToCameraScreen={this.goToCameraScreen}
                fetchProfile={this.props.fetchProfile}
                navigation={this.props.navigation}
            />
        );
    }
}

ProfileContainer.propTypes = {
    navigation: PropTypes.instanceOf(Object),
    fetchProfile: PropTypes.func,
};
const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchProfile,
        },
        dispatch,
    );
};


export default withNavigation(
    connect(
        null,
        mapActionsToProps,
    )(ProfileContainer),
);
