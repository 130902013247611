// @flow
import * as React from 'react';
import {ActivityIndicator, Image, Platform, TouchableOpacity, View} from "react-native";
import {Link} from "react-router-dom";
import Calendar from "../../../svg/Icons8CalendarBooked";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import Bag from "../../../svg/Icons8Shopaholic";
import MobilePhone from "../../../svg/Icons8MobilePhone";
import User from "../../../svg/Icons8MaleUser";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import {useSelector} from "react-redux";
import Logout from "../../../svg/Icons8LogoutRoundedUp";
import ModalButton from "../../ModalButton";
import {useEffect, useState} from "react";


const SiteDesktopHeader = (props) => {
    const {handleLogout, setShowLogin} = props;
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const user = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.data)
    const whiteLabelProperties = useSelector(state => state.whiteLabelProperties)
    const [logging, setLogging] = useState(false);

    const logout = async () => {
        setLogging(true)
        handleLogout()
    }

    useEffect(() => {
        if(!isLoggedIn) {
            setLogging(false)
        }
    }, [isLoggedIn]);

    return (
        <View style={styles.pluginWrapper}>
            {logging ? <ActivityIndicator color={Colors.brandedBlue}/> :
                <>
                    {
                        isLoggedIn ?
                            <View style={styles.pluginLoggedInWrapper}>
                                <View style={styles.pluginActionWrapper}>
                                    {user.image ?
                                        <img style={{width: '2em', height: '2em', borderRadius: '50%'}}
                                             src={user.image}/>
                                        :
                                        <User style={styles.userProfileImg} fill={Colors.iconEmptyStateColor}/>
                                    }
                                    <AppText style={[styles.textPlugin]}>{user.full_name}</AppText>
                                </View>
                                <View style={styles.pluginActionWrapper}>
                                    <Logout/>
                                    <TouchableOpacity onPress={logout}><AppText
                                        style={[styles.textPlugin]}>{t('screens:More:logout', {})}</AppText></TouchableOpacity>
                                </View>
                            </View>
                            :
                            <ModalButton
                                onPress={setShowLogin}
                                text={t('screens:Login:login', {})}
                                type='primary'
                                colors={whiteLabelProperties.primaryColor}
                                textColor={Colors.white}
                                overrideStyle={[styles.loginBtnPlugin]}
                            />
                    }
                </>
            }
        </View>
    );
};

export default SiteDesktopHeader

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            pluginWrapper: {
                width: "100%",
                flexDirection: 'row',
                justifyContent: 'flex-end'
            },
            pluginLoggedInWrapper: {
                flexDirection: 'row',
                gap: '2em'
            },
            pluginActionWrapper: {
                flexDirection: 'row',
                gap: '0.5em',
                alignItems: 'center',
                justifyContent: 'center'
            },
            userProfileImg: {
                width: '2em',
                height: '2em',
                borderRadius: '50%'
            },
            textPlugin: {
                fontSize: '0.8rem',
                cursor: 'pointer'
            },
            loginBtnPlugin: {
                width: '3em',
                height: '2em',
                minHeight: '2em',
                borderRadius: 2,
                paddingVertical: '1em',
                paddingHorizontal: '3em',
                marginTop: 0
            },
        }
    })
})