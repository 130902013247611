import React from 'react'
import ContentLoader, {Code, Rect, Circle} from 'react-content-loader/native'
import {View} from "react-native";
import i18n from "../services/i18n";
import {Colors} from "../styles/Colors";
import {scale} from 'react-native-size-matters';
import {globalStyles} from "../styles/Global";
import uuid from 'uuid';

export const CardLoader = () => (
    <Code width={scale(280)} rtl={i18n.isRTL}/>
);


export const ProfileLoader = (props) => (
    <ContentLoader
        rtl={i18n.isRTL}
        speed={0.3}
        width={400}
        height={60}
        viewBox="0 0 400 60"
        backgroundColor={Colors.graySeparator}
        foregroundColor="#cccccc"
        {...props}
    >
        <Rect x="48" y="8" rx="3" ry="3" width="150" height="6"/>
        <Rect x="48" y="26" rx="3" ry="3" width="100" height="6"/>
        <Circle cx="20" cy="20" r="20"/>
    </ContentLoader>
)

export const MembershipSelectLoader = (props) => (
    <View style={[globalStyles.marginTopSm]} key={uuid.v4()}>
        {
            [...Array(props.items)].map((e, index) =>
                <ContentLoader
                    key={index}
                    rtl={i18n.isRTL}
                    speed={0.3}
                    width={400}
                    height={60}
                    viewBox="0 0 400 60"
                    backgroundColor={Colors.graySeparator}
                    foregroundColor="#cccccc"
                    {...props}
                >
                    <Rect x="10" y="10" rx="3" ry="3" width="250" height="10"/>
                    <Rect x="10" y="30" rx="3" ry="3" width="250" height="10"/>
                </ContentLoader>
            )
        }
    </View>
);

export const LogbookLoader = (props) => (
    <View style={[globalStyles.marginTopSm]} key={uuid.v4()}>
        {
            [...Array(props.items)].map((e, index) =>
                <ContentLoader
                    key={index}
                    rtl={i18n.isRTL}
                    speed={0.3}
                    width={400}
                    height={120}
                    viewBox="0 0 400 120"
                    backgroundColor="#F3F3F3"
                    foregroundColor="#ECEBEB"
                    {...props}
                >
                    <Rect x="0" y="13" rx="6" ry="6" width="330" height="100"/>
                </ContentLoader>
            )
        }
    </View>
);

export const CardsLoader = (props) => (
    <View style={globalStyles.loadingCardContainer}>
        {
            [...Array(props.items)].map((e, index) => <View style={[globalStyles.loadingCard]}
                                                  key={index}><CardLoader/></View>)
        }
    </View>
)