import React from 'react';
import {
    View,
    TouchableOpacity,
    TextInput,
    Dimensions,
    ScrollView
} from 'react-native';
import {connect} from 'react-redux';
import styles from './styles';
import {bindActionCreators} from 'redux';
import SectionHeading from '../../../components/SectionHeading';
import {LinearGradient} from 'expo-linear-gradient';
import {t} from '../../../services/i18n';
import {Colors} from '../../../styles/Colors';
import _, {debounce, throttle} from 'lodash';
import {apiAction} from "../../../helpers/HTTP";
import FriendsList from '../../../components/FriendsList';
import {
    getUserFromFriendConnection,
    filterSearch,
    MEDIUM_ICON_SIZE
} from '../../../helpers/functions'
import {fetchFriendProfile} from '../../../actions/friend'
import {globalStyles} from "../../../styles/Global";
import {ProfileLoader} from "../../../loaders";
import {StatusBarWrap} from "../../../components/Stateless/StatusBarWrap";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {verticalScale} from 'react-native-size-matters';
import {getThemeProperty, keys} from "../../../styles/Theme";

class FriendSearchScreen extends React.Component {
    state = {
        isLoading: true,
        results: [],
        friends: [],
        selectedText: '',
        searching: false,
    };

    constructor(props) {
        super(props);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.query = this.query.bind(this);
        this.yourFriends = this.yourFriends.bind(this);
    }

    componentDidMount = async () => {
        this.themeColor = getThemeProperty(keys.brandedGreen);


        const {user} = this.props;
        const friends = user.friend_connection.map(item => getUserFromFriendConnection(item));
        this.setState({isLoading: false, friends});

        this.searchInput.focus();

    };

    async handleTextChange(selectedText) {
        this.setState({selectedText: selectedText});
        if (selectedText.length > 1) {
            debounce(() => {
                this.setState({searching: true});
                this.query();
            }, 500)()
        } else {
            this.setState({results: [], searching: false})
        }

    }

    async query() {
        try {
            if (this.state.selectedText.length > 1) {
                const response = await apiAction('search', 'post', {input: this.state.selectedText});
                let results = response.data.map(item => {
                    this.state.friends.map(friend => {
                        if (item.id === friend.id) {
                            item.full_name = friend.full_name;
                        }
                    });
                    return item;
                });
                //TODO find better solution

                this.setState({results, searching: false});
            } else {
                this.setState({results: [], searching: false});
            }

        } catch (error) {

        }
    }


    yourFriends() {
        const friends = this.state.selectedText.length > 1 ? filterSearch(this.state.friends, 'full_name', this.state.selectedText) : this.state.friends;
        const display = friends.slice(0, 3);
        if (display.length > 0) {
            return (
                <View style={globalStyles.flex}>
                    <View style={[{height: verticalScale(40)}]}>
                        <SectionHeading
                            iconName={'account-check'} text={t('headings:search-friend-show-friends', {})}/>
                    </View>

                    <FriendsList
                        actionDisabled={true}
                        list={display}
                        isMyFriends={false}
                        textButtons={false}
                        directObject={true}
                        reducerName={null}
                        action={null}
                        displayClub={false}
                    />
                </View>

            );
        }
        return null;

    }

    onLayout(e) {
        // const {nativeEvent: {layout: {height}}} = e;
        // this.height = height;
        // this.forceUpdate();
    }

    renderLists() {
        const {results} = this.state;
        const {fetchFriendProfile} = this.props;
        const {height: heightOfDeviceScreen} = Dimensions.get('window');

        return (
            <View style={[globalStyles.flex]}>
                {this.state.selectedText.length > 1 ? null : this.yourFriends()}
                {this.state.selectedText.length > 1 ? (
                    <View style={{height: verticalScale(40)}}>
                        <SectionHeading
                            text={t('headings:search-friend-result', {
                                numOfResult: results.length,
                                searchName: this.state.selectedText
                            })}/>
                    </View>
                ) : null}
                <ScrollView contentContainerStyle={[{flexGrow: 1}, globalStyles.screenBottomPadding]}>
                    {this.state.searching ?
                        <View style={[globalStyles.appPaddingHorizontal, globalStyles.marginTopBetweenElements]}>
                            <ProfileLoader/>
                            <ProfileLoader/>
                            <ProfileLoader/>
                            <ProfileLoader/>
                            <ProfileLoader/>
                            <ProfileLoader/>
                        </View>
                        : null}
                    {results.length > 0 ? (
                        <FriendsList
                            actionDisabled={true}
                            list={results}
                            isMyFriends={false}
                            textButtons={false}
                            directObject={true}
                            reducerName={null}
                            action={null}
                            drawer={false}
                            search={true}
                            displayClub={false}
                        />
                    ) : null}
                </ScrollView>
            </View>
        );
    }


    renderHeader() {
        return (
            <LinearGradient
                colors={[this.themeColor,this.themeColor]}
            >
                <View style={styles.header}>
                    <TouchableOpacity onPress={() => this.searchInput.focus()}>
                        <Icons8Generator name={'search'} fill={this.props.whiteLabelProperties.primaryColorText} width={MEDIUM_ICON_SIZE}
                                         height={MEDIUM_ICON_SIZE}/>
                    </TouchableOpacity>
                    <TextInput
                        ref={(ref) => {
                            this.searchInput = ref;
                        }}
                        style={[globalStyles.heeboRegular, styles.input, globalStyles.textAlignByRTL,{color: this.props.whiteLabelProperties.primaryColorText}]}
                        onChangeText={this.handleTextChange}
                        underlineColorAndroid="transparent"
                        value={this.state.selectedText}
                        placeholder={t('headings:search-for-friends', {})}
                        placeholderTextColor={this.props.whiteLabelProperties.primaryColorText}
                        autoFocus
                    >
                    </TextInput>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}
                                      hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}>
                        <Icons8Generator name={'close'} fill={this.props.whiteLabelProperties.primaryColorText} width={MEDIUM_ICON_SIZE}
                                         height={MEDIUM_ICON_SIZE}/>
                    </TouchableOpacity>
                </View>
            </LinearGradient>
        )
    }

    render() {
        return (
            <View style={globalStyles.flex}>
                <StatusBarWrap
                    barStyle={"light-content"}
                />
                {this.renderHeader()}
                {this.renderLists()}
            </View>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchFriendProfile
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(FriendSearchScreen);
