import {NavigationActions, StackActions} from 'react-navigation';
import uuid from 'uuid';

let navigator;

function setTopLevelNavigator(navigatorRef) {
    navigator = navigatorRef;
}

function navigate(routeName, params) {
    navigator.dispatch(
        NavigationActions.navigate({
            routeName,
            params,
            key: uuid.v4()
        })
    );
}

function push(routeName, params) {
    navigator.dispatch(
        StackActions.push({
            routeName,
            params,
        })
    );
}

function goBack(){
    navigator.dispatch(NavigationActions.back(navigator.state.key));

}
function pop() {
    const popAction = StackActions.pop(1);
    navigator.dispatch(popAction);
}

export default {
    navigate,
    push,
    pop,
    goBack,
    setTopLevelNavigator
};
