import React from 'react';
import {View} from 'react-native';
import {isTextRTLStyles} from "../../../styles/Layout";
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import {t} from "../../../services/i18n";
import date from '../../../services/i18n/date';
import {scale, verticalScale, ScaledSheet} from 'react-native-size-matters';
import {AppText} from "../AppText";
import {Icons8Generator} from "../Icons8Generator";


export const ClassDetails = props => {
    const data = props.data;
    const disableCoachDisplay = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'coach');
    return (
        <View>
            {
                props.noClassName === true ? null : (
                    <View style={[globalStyles.flexDirectionRow, globalStyles.marginTopSm]}>
                        <AppText
                            style={[globalStyles.heeboMedium, {color: Colors.grayText}]}>{data.box_categories.name}</AppText>
                    </View>)
            }
            <View style={globalStyles.marginTopBetweenElements}>
                {data.coach && !disableCoachDisplay ? (
                    <View style={[globalStyles.flexDirectionRow, globalStyles.flexDirectionRowMarginBottom]}>
                        <Icons8Generator name={'user'}/>
                        <AppText style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text]}>
                            {t('common:coach', {})} {data.coach.first_name} {data.coach.last_name}
                        </AppText>
                    </View>
                ) : null}
                <View style={[globalStyles.flexDirectionRow]}>
                    <Icons8Generator name={'clock-outline'}/>
                    <AppText style={[globalStyles.heeboRegular, globalStyles.paddingStart, styles.text]}>
                        {date.format(data.date, 'LL')} {date.timeFormat(data.time, 'LT')}
                    </AppText>
                </View>
            </View>
            {props.children}
        </View>
    )
};


const styles = ScaledSheet.create({
    regular: {
        fontWeight: '400',
        color: Colors.modalFontColor,
        ...isTextRTLStyles,
    },
    bold: {
        fontFamily: 'heebo-bold',
        fontWeight: '600',
        color: Colors.modalFontColor,
        ...isTextRTLStyles,
    },
    text: {
        fontSize: scale(12),
        alignSelf: 'center'
    }


});
