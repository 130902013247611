import React from 'react';
import {View, TextInput} from 'react-native';
import {Icon} from 'native-base';
import styles from './styles';
import {t} from '../../services/i18n';
import FriendsList from '../../components/FriendsList';
import {connect} from 'react-redux';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {Colors} from "../../styles/Colors";


class InviteFriendsList extends React.Component {
    state = {
        friendsList: [],
        searchList: [],
        searchValue: '',
    };

    componentDidMount() {
        const {friendsList} = this.props;
        this.setState({friendsList}, () =>
            this.setState(prevState => ({searchList: prevState.friendsList})),
        );
    }

    searchFilterFunction = text => {
        const {friendsList} = this.state;
        this.setState({
            searchValue: text,
        });
        const newData = friendsList.filter(item => {
            const itemData = `${item.firstName.toUpperCase()} ${item.lastName.toUpperCase()}`;
            const textData = text.toUpperCase();
            return itemData.indexOf(textData) > -1;
        });
        this.setState({
            searchList: newData,
        });
    };

    renderHeader = () => {
        const {searchValue} = this.state;
        return (
                <View>
                     <Icons8Generator name={'search'} fill={Colors.white}/>
                    {/*<Icon type="MaterialIcons" name="search" style={styles.searchInputIcon}/>*/}
                    <TextInput
                        style={styles.searchInput}
                        onChangeText={text => this.searchFilterFunction(text)}
                        value={searchValue}
                        placeholder={t('modals:BookClass:texts:search-placeholder')}
                    />
                </View>
        );
    };


    render() {
        const {maxInvites, bookedUsers, scheduleId} = this.props;
        const friends = [...this.props.friendConnection];
        const bookedIds = bookedUsers.filter(item => item.id !== this.props.user.id).map(item => item.id);
        friends.map(item => {
            if (bookedIds.includes(item.users_id) || bookedIds.includes(item.friend_users_id)) {
                item.invite_status = 'booked';
            }else{
                item.invite_status = 'invite'
            }
        });
        return (
            <View>
                <FriendsList
                    actionDisabled={false}
                    list={friends}
                    isMyFriends={true}
                    textButtons={true}
                    displaySmall={true}
                    displayClub={false}
                    action={'inviteToSchedule'}
                    actionId={scheduleId}
                    closeDrawer={this.props.closeDrawer}
                    drawer={true}
                />
            </View>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    null,
)(InviteFriendsList);

