import {apiAction} from '../helpers/HTTP';
import {
    BOXES, COACHES, FORMS,
    LOCATIONSBOX,
    SELECTED_LOCATION,
} from '../constants';

//BOXES REDUCERS AND ACTIONS ARE BOXES, BUT RELATED DIRECTLY TO USERS BOXES AS WELL!
export const fetchUserBoxes = () => async (dispatch) => {
    const actionType = BOXES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('boxes', 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const fetchUserBoxesAndLocations = () => async (dispatch) => {
    const actionType = LOCATIONSBOX.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('boxes/locations', 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const fetchUserFormsByBox = (boxesId) => async (dispatch) => {
    const actionType = FORMS.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const route = `usersBoxes/${boxesId}/forms`;
        const response = await apiAction(route, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


export const fetchCoachesByBox = (boxesId) => async (dispatch) => {
    const actionType = COACHES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const route = `box/coaches/${boxesId}`;
        const response = await apiAction(route, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


