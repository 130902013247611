import React, {useEffect, useState} from 'react';
import {CheckBox, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";
import {containsHTML, isMobile} from "../../../helpers/functions";
import {WebView} from "react-native-webview";
import {t} from "../../../services/i18n";
import SignatureCanvas from "react-signature-canvas/src";
import {Icons8Generator} from "../../Stateless/Icons8Generator";

const SignatureHandler = (props) => {
    const {digitalForm, onSave} = props;
    const [agreeCheckbox, setAgreeCheckbox] = useState(false);
    const [signature, setSignature] = useState({});
    const [isSignature, setIsSignature] = useState(digitalForm && digitalForm.signature_options === 3 || digitalForm.signature_options === 1);
    const [isCheckBox, setIsCheckBox] = useState(digitalForm && digitalForm.signature_options === 3 || digitalForm.signature_options === 2);
    const mobile = isMobile()

    const clear = () => {
        signature.clear();
        onSave(null)
    }

    const sign = () => {
        onSave({signature: signature.toDataURL(), agree: agreeCheckbox})
    }

    const onCheckBoxClicked = (res) => {
        setAgreeCheckbox(res);
        onSave({signature: Object.keys(signature).length > 0 && !signature.isEmpty() ? signature.toDataURL() : null, agree: res})
    }

    const iFrameHtmlStyles = (direction) => {
        return `
            body { 
                font-family: 'Helvetica';
                font-weight: 100;
                direction: ${direction};
                margin: 0;
                font-size: ${mobile ? '15px' : '0.9rem'};
            }
            b {
                font-size: ${mobile ? '15px' : '0.9rem'};
            }
            p {
                font-size: ${mobile ? '15px' : '0.9rem'};
            }
            span {
                font-size: ${mobile ? '15px' : '0.9rem'};
            }
            h1 {
                font-size: ${mobile ? '19px' : '1.25rem'};
                margin: 0.3em 0;
            }
            h2 {
                font-size: ${mobile ? '18px' : '1.15rem'};
                margin: 0.3em 0;
            }
            h3 {
                font-size: ${mobile ? '17px' : '1rem'};
                margin: 0.3em 0;
            }
            strong {
                font-size: ${mobile ? '17px' : '1rem'} !important;
            }
            br {
                content: "";
            }
        `;
    }

    return (
        <View style={[styles.wrapper]}>
            {digitalForm && digitalForm.signature_declaration &&
            <>
                {
                    containsHTML(digitalForm.signature_declaration) ?
                        <View style={[{height: `calc(100% - ${isSignature ? 170 : 50}px)`}]}>
                            <WebView
                            source={{
                                html: `
                                <html>
                                <link href='https://fonts.googleapis.com/css?family=Heebo' rel='stylesheet'>
                                    <style type="text/css">${iFrameHtmlStyles(digitalForm.display_direction)}</style>
                                    <body>
                                        ${digitalForm.signature_declaration}
                                    </body>
                                </html>`
                            }}/>
                        </View>
                        :
                        <AppText style={[{fontSize: '0.9rem', height: `calc(100% - ${isSignature ? 170 : 50}px)`, overflow: 'auto'}]}>{digitalForm.signature_declaration}</AppText>
                }
            </>
            }
            {digitalForm && digitalForm.signature_declaration && <View style={[styles.separator]}/>}
            <View style={[{gap: 7, height: 100}]}>
                {
                    isSignature &&
                    <>
                        <AppText style={[{fontSize: '0.9rem'}]}>{t('screens:DigitalForms:siteSignatureExplain', {})}</AppText>
                        <SignatureCanvas ref={(ref) => setSignature(ref)}
                                         penColor={Colors.lightBlack}
                                         backgroundColor={Colors.answerBorder}
                                         canvasProps={{height: mobile ? 180 : 70}} onEnd={sign}/>
                        <TouchableOpacity style={[styles.resetRow]} onPress={clear}>
                            <Icons8Generator name={'refresh'} size={'1em'}/>
                            <AppText style={[{fontSize: '0.9rem'}]}>{t('screens:DigitalForms:signature:retry', {})}</AppText>
                        </TouchableOpacity>
                    </>
                }
                {
                    isCheckBox &&
                    <View style={[styles.agreeCheckboxWrapper]}>
                        <CheckBox
                            value={agreeCheckbox}
                            onValueChange={onCheckBoxClicked}
                            style={[styles.agreeCheckbox]}
                            color={Colors.lightBlack}
                        />
                        <AppText
                            style={[{fontSize: '0.9rem'}]}>{t('screens:DigitalForms:signatureAgreeCheckbox', {})}</AppText>
                    </View>
                }
            </View>
        </View>
    );
}

export default SignatureHandler;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                height: '100%'
            },
            textHeader: {
                fontSize: '1.2rem',
                fontWeight: 'bold'
            },
            contentWrapper: {

            },
            separator: {
                height: 1,
                width: '100%',
                backgroundColor: Colors.answerBorder,
                marginVertical: '1em'
            },
            signatureSpace: {
                height: '5em',
                width: '100%'
            },
            agreeCheckboxWrapper: {
                flexDirection: 'row',
                gap: '0.5em',
                alignItems: 'center',
                height: 35
            },
            resetRow: {
                flexDirection: 'row',
                justifyContent: 'end',
                width: '100%',
                gap: '0.5em',
                alignItems: 'center'
            }
        }
    })
})

