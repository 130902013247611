import React from 'react';
import {createAppContainer, createSwitchNavigator} from 'react-navigation';

import MainTabNavigator from './MainTabNavigator';
import AuthNavigator from './AuthNavigator';
import AuthLoadingScreen from '../screens/AuthLoadingScreen';
import {FirstLoginStackWithFooter} from "./FirstLoginStack";

export const getRootNavigator = () =>
     {
         let routing,initRoute;
              routing = {
                 AuthLoading: AuthLoadingScreen,
                 Auth: AuthNavigator,
                 Main: MainTabNavigator,
                 FirstLogin: FirstLoginStackWithFooter,
             }
             initRoute = 'AuthLoading';
         return createAppContainer(
             createSwitchNavigator(
                 routing,
                 initRoute,
             ),
         );
     }

