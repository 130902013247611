import {createStore, applyMiddleware, compose} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import rootReducer from "./rootReducer";
import createMigrate from "redux-persist/es/createMigrate";


const logger = createLogger({
    collapsed: true,
    // logger: console
});

const migrations = {
    2: (state) => {
        return {
            ...state,
            minisite: {
                activeIdentifier: null,
                boxes: {},
            }
        }
    },
    6: (state) => {
        let newBoxes = {...state.minisite.boxes}
        newBoxes = Object.values(newBoxes).map(box => {
            return {...box, registerStepNumber: 0}
        })
        return {
            ...state,
            minisite: {
                activeIdentifier: null,
                boxes: Object.assign({}, ...newBoxes.map((x) => ({[x.identifier]: x}))),
            }
        }
    },
    8: (state) => {
        let newBoxes = {...state.minisite.boxes}
        newBoxes = Object.values(newBoxes).map(box => {
            return {...box, queryString: null}
        })
        return {
            ...state,
            minisite: {
                activeIdentifier: null,
                boxes: Object.assign({}, ...newBoxes.map((x) => ({[x.identifier]: x}))),
            }
        }
    },
    10: (state) => {
        let newBoxes = {...state.minisite.boxes}
        newBoxes = Object.values(newBoxes).map(box => {
            return {...box, userCreatedTimePeriod: null}
        })
        return {
            ...state,
            minisite: {
                activeIdentifier: null,
                boxes: Object.assign({}, ...newBoxes.map((x) => ({[x.identifier]: x}))),
            }
        }
    }
}

//TODO PLUGIN -  ADD SELECT BY PLATFORM
const persistConfig = {
    key: 'root',
    version: 10,
    storage: AsyncStorage,
    whitelist: ['auth', 'minisite'], // only auth will be persisted
    migrate: createMigrate(migrations, { debug: __DEV__ })
};

const middlewares = __DEV__
    ? composeWithDevTools(applyMiddleware(thunk))
    : compose(applyMiddleware(thunk));

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, {}, middlewares);

export const persistor = persistStore(store);


