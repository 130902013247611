import React from 'react';
import {TouchableOpacity} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {ScaledSheet, verticalScale, scale} from "react-native-size-matters"
import {AppText} from "../AppText";


export const TextButton = props => {
    return (
        <TouchableOpacity
            style={[{backgroundColor: props.backgroundColor}, styles.container]}
            disabled={props.disabled} onPress={() => props.onPress()}>
            <AppText style={[globalStyles.heeboMedium,styles.textStyling, {color: props.textColor}]}>{props.text}</AppText>
        </TouchableOpacity>
    )
};


const styles = ScaledSheet.create({
    container: {
        borderRadius: scale(3),
        marginTop:verticalScale(5)
    },
    textStyling: {
        fontSize: scale(13),
        alignSelf: 'center',
        paddingHorizontal: scale(15),
        paddingVertical: verticalScale(5),

    }
});
