import React from 'react';
import {connect, useSelector} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {bindActionCreators} from "redux";
import {createStackNavigator} from 'react-navigation-stack';
import {withNavigationFocus} from "react-navigation";
import {SafeAreaView, View, ActivityIndicator, KeyboardAvoidingView} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import CarouselFooter from '../../screens/CarouselFooterScreen';
import {store} from "../../config/store";
import {Colors} from '../../styles/Colors';
import FirstLoginChangePasswordScreen from "../../screens/FirstLogin/FirstLoginChangePasswordScreen";
import {globalStyles} from "../../styles/Global";
import FirstLoginChangeNameAndBioScreen from "../../screens/FirstLogin/FirstLoginNameAndBioScreen";
import FirstLoginProfileScreen from "../../screens/FirstLogin/FirstLoginProfileScreen";
import FirstLoginCompleteScreen from "../../screens/FirstLogin/FirstLoginCompleteScreen";
import {apiAction} from "../../helpers/HTTP";
import {patchUpdateProfileResponse} from "../../actions/user";
import CameraContainer from '../../containers/CameraContainer';
import i18n from "../../services/i18n";
import AppHeader from "../../components/Stateless/AppHeader";
import {LinearWrapper} from "../../components/Stateless/LinerWrapper";
import {getThemeProperty,keys} from "../../styles/Theme";

class CustomHeader extends React.Component {
    render() {
        return <AppHeader addPadding={true} pop={true} overrideStyles={{backgroundColor:this.props.value}}/>;
    }
}

let PrimaryColor = connect(state => ({ value: state.whiteLabelProperties.primaryColor }))(CustomHeader);

export const FirstLoginStack = createStackNavigator({
        FirstLoginChangePassword: {
            screen: FirstLoginChangePasswordScreen,
            navigationOptions: ({navigation}) => ({
                header: null
            }),
        },
        FirstLoginNameAndBio: FirstLoginChangeNameAndBioScreen,
        FirstLoginProfile: FirstLoginProfileScreen,
        FirstLoginComplete: {
          screen: FirstLoginCompleteScreen,
            navigationOptions: {
                header: <PrimaryColor/>
            },
        },
        Camera: CameraContainer,
    },
    {
        defaultNavigationOptions: {
            header: () => <AppHeader addPadding={true} pop={true} overrideStyles={{backgroundColor:Colors.white}}/>,
        },
    }
);


const mapStateToProps = state => state;

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            patchUpdateProfileResponse,
        },
        dispatch,
    );

@withNavigationFocus
@connect(mapStateToProps, mapDispatchToProps)
export class FirstLoginStackWithFooter extends React.Component {
    static router = FirstLoginStack.router;

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            error: null
        };
        this.onButtonClick = this.onButtonClick.bind(this);
        this.firstLoginChangePassword = this.firstLoginChangePassword.bind(this);
        this.firstLoginNameAndBio = this.firstLoginNameAndBio.bind(this);
        this.firstLoginProfile = this.firstLoginProfile.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    componentDidMount() {
        // this.props.setFirstLogin();
    }

    async firstLoginChangePassword(currentSlideName, nextSlideName) {
        try {
            const selector = formValueSelector(currentSlideName);
            const state = store.getState();
            const values = selector(state, 'password');
            const data = {
                old_password: null,
                new_password: values,
                on_boarding: true
            };
            await apiAction(`user/changePassword`, 'post', {...data});
            this.handleSuccess(nextSlideName);
        } catch (error) {
            this.setState({error: error.error, submitting: false});
        }
    }

    async firstLoginNameAndBio(currentSlideName, nextSlideName) {
        try {
            const selector = formValueSelector(currentSlideName);
            const state = store.getState();
            //TODO HANDLE LANGUAGES
            let values = selector(state, 'first_name', 'last_name', 'bio','birthday');
            values.language = i18n.locale;
            const response = await apiAction(`user/updateUserDetails`, 'patch', values);
            this.props.patchUpdateProfileResponse(response.data);
            this.handleSuccess(nextSlideName);
        } catch (error) {
            this.setState({error: error.error, submitting: false});
        }
    }


    //Bad name it updates picture.
    async firstLoginProfile(currentSlideName, nextSlideName) {
        try {
            // TODO DONT KNOW HOW TO HANDLE YET.
            this.handleSuccess(nextSlideName);
            // we can pass to next;
        } catch (error) {
            this.setState({error: error.error, submitting: false});
        }
    }

    handleSuccess(nextSlideName) {
        this.setState({submitting: false});
        this.props.navigation.navigate(nextSlideName)
    }

    onButtonClick(currentSlideName, nextSlideName) {
        // TODO JUST NOTE, WORKING HERE WITHOUT REDUX STATE!!
        this.setState({submitting: true, error: null});
        switch (currentSlideName) {
            case 'FirstLoginChangePassword':
                this.firstLoginChangePassword(currentSlideName, nextSlideName);
                break;
            case 'FirstLoginNameAndBio':
                this.firstLoginNameAndBio(currentSlideName, nextSlideName);
                break;
            case 'FirstLoginProfile':
                this.firstLoginProfile(currentSlideName, nextSlideName);
                break;
            default:
                this.setState({submitting: false});
                break;
        }

    }


    render() {
       const last = this.props.navigation.state.index === 3;
        return (
            <LinearWrapper
                backgroundStart={last ? getThemeProperty(keys.brandedGreen) : Colors.white}
                backgroundEnd={last ? getThemeProperty(keys.brandedGreen) : Colors.white}
                barStyle="light-content"
                spinner={false}>
                    {this.state.submitting ? (<View style={globalStyles.overlayLoading}>
                        <ActivityIndicator size='large'/>
                    </View>) : null}
                    {/*<SafeAreaView style={{flex: 1}}>*/}
                        <FirstLoginStack navigation={this.props.navigation} options={{test:'nme'}}/>
                        <CarouselFooter
                            router={FirstLoginStack.router}
                            onButtonClick={this.onButtonClick}
                            submitting={this.state.submitting}
                            error={this.state.error}
                        />
                    {/*</SafeAreaView>*/}
            </LinearWrapper>
        );
    }
}

