// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import {t} from "../../../services/i18n";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {BIG_ICON_SIZE, SMALL_ICON_SIZE} from "../../../helpers/functions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {updateSiteSelectedLocation} from "../../../actions/minisite";
import DrawerHandler from "../../DrawerHandler";

const ShopMobileHeader = (props) => {
    const cart = useSelector(state => state.shop.cart)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const selectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const [singleLocation, setSingleLocation] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);
    let RBSheet = useRef(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if(box && box.locations_box.length > 1) {
            setSingleLocation(false)
        }
    }, [box]);

    useEffect(() => {
        if(!singleLocation) {
            if (openDrawer) {
                RBSheet?.open();
            } else {
                RBSheet?.close();
            }
        }
    }, [openDrawer]);

    const changeLocation = (location) => {
        dispatch(updateSiteSelectedLocation(location))
        setOpenDrawer(false)
    }

    return (
        <View style={styles.wrapper}>
            {
                singleLocation ?
                    <AppText style={styles.mobilePageTitle} boldFont>{t('headings:online-shop', {})}</AppText>
                    :
                    <>
                        <TouchableOpacity onPress={() => setOpenDrawer(true)}>
                            <View style={{flexDirection: 'row', alignItems: 'center', gap: '0.5em'}}>
                                <AppText style={styles.mobilePageTitle} boldFont>{t('headings:location-shop', {location: selectedLocation.location})}</AppText>
                                <Icons8Generator name='arrow-down'/>
                            </View>
                        </TouchableOpacity>
                        <DrawerHandler title={t('common:drawer:change-location', {})}
                                       ref={el => RBSheet = el ? el.ref.current : null}
                                       onClose={() => setOpenDrawer(false)}
                                       overrideCustom={styles.drawer}
                        >
                            <View style={{paddingVertical: '2em', gap: '1.2em'}}>
                                {
                                    box.locations_box.map(location => {
                                        return <TouchableOpacity style={styles.locationRow}
                                                                 onPress={() => changeLocation(location)}
                                                                 key={`mobile-location-drawer-${location.id}`}>
                                            <AppText style={[{fontSize: '1.1em'}]}>{location.location}</AppText>
                                            {(selectedLocation.id === location.id) &&
                                            <Icons8Generator name='check' size='1.5em'
                                                             fill={Colors.brandedGreen}/>}
                                        </TouchableOpacity>
                                    })
                                }
                            </View>
                        </DrawerHandler>
                    </>
            }
            <Link to={{pathname: '/cart', search:boxQueryString || location.search}} style={{flexDirection: 'row', textDecoration: 'none'}}>
                <Icons8Generator name={'cart'} size={'1.5em'} fill={Colors.lightBlack}/>
            </Link>
        </View>
    );
};
export default ShopMobileHeader;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            mobilePageTitle: {
                fontSize: '1rem',
                color: Colors.lightBlack,
            },
            wrapper: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '85%'
            },
            cartWrapper: {
                flexDirection: 'row',
            },
            cartNumber: {
                color: Colors.lightBlack,
                fontSize: '0.7rem'
            },
            drawer: {
                paddingHorizontal: '1.3em',
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                height: 'fit-content'
            },
            locationRow: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        }
    })
})