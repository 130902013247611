import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8User(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25.875 3.406c-4.672.086-7.656 1.973-8.938 4.906-1.222 2.793-.949 6.32-.062 9.97-.477.558-.855 1.308-.719 2.437.149 1.23.489 2.105.969 2.718.266.336.613.368.938.532.175 1.047.468 2.093.906 2.968.25.504.535.97.812 1.344.125.168.305.266.438.407.008 1.234.011 2.261-.094 3.562-.324.785-1.082 1.418-2.313 2.031-1.269.633-2.921 1.219-4.593 1.938-1.672.718-3.39 1.593-4.75 2.968-1.36 1.376-2.32 3.262-2.469 5.75L5.937 46h40.126L46 44.937c-.148-2.488-1.113-4.374-2.469-5.75-1.355-1.374-3.054-2.25-4.718-2.968-1.665-.719-3.297-1.305-4.563-1.938-1.215-.61-1.98-1.226-2.313-2-.109-1.316-.101-2.347-.093-3.593.133-.145.312-.239.437-.407.274-.379.535-.843.782-1.343.425-.875.734-1.926.906-2.97.312-.163.648-.202.906-.53.48-.614.82-1.489.969-2.72.133-1.093-.235-1.816-.688-2.374.489-1.586 1.114-4.149.907-6.782-.114-1.437-.481-2.87-1.344-4.062-.79-1.094-2.07-1.89-3.688-2.219-1.05-1.363-2.941-1.875-5.125-1.875zm.031 2h.032c2.011.008 3.316.598 3.687 1.25l.25.407.469.062c1.39.191 2.187.754 2.75 1.531.562.778.875 1.864.968 3.063.188 2.398-.503 5.191-.937 6.5l-.25.781.688.406c-.043-.027.382.262.28 1.094-.116.98-.35 1.488-.53 1.719-.18.23-.274.218-.282.218l-.843.063-.094.813c-.094.863-.442 1.921-.844 2.75a7.44 7.44 0 01-.594 1.03c-.183.255-.375.395-.281.345l-.531.28v.595c0 1.449-.059 2.636.093 4.312v.125l.063.125c.57 1.535 1.89 2.492 3.344 3.219 1.453.726 3.12 1.261 4.687 1.937 1.567.676 3 1.485 4.063 2.563.843.855 1.375 1.988 1.656 3.406H8.25c.281-1.414.809-2.55 1.656-3.406 1.067-1.078 2.52-1.887 4.094-2.563 1.574-.676 3.23-1.21 4.688-1.937 1.457-.727 2.804-1.684 3.375-3.219l.062-.25c.152-1.676.094-2.863.094-4.313v-.593l-.532-.282c.09.047-.125-.09-.312-.343a7.261 7.261 0 01-.625-1.032c-.414-.828-.754-1.894-.844-2.75l-.093-.812-.844-.063c-.008 0-.102.012-.282-.218s-.414-.739-.53-1.719c-.098-.832.323-1.121.28-1.094l.657-.406-.188-.719c-.941-3.625-1.105-6.918-.125-9.156.977-2.23 2.969-3.633 7.125-3.719z" />
    </Svg>
  );
}

export default SvgIcons8User;
