import React from 'react';
import {useSelector} from "react-redux";
import {View, TouchableOpacity, Image, StyleSheet} from 'react-native';
import {AppText} from "../AppText";
import i18n, {t} from "../../../services/i18n";
import {globalStyles} from "../../../styles/Global";
import {MEMBERSHIP_TYPES_CONST} from "../../../constants/membershipTypes";
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";
import {
    EXTRA_HUGE_ICON_SIZE,
    getFirstPaymentDate,
    MEDIUM_ICON_SIZE,
} from "../../../helpers/functions";
import {Icons8Generator} from "../Icons8Generator";
import uuid from 'uuid';
import Price from '../../Shopping/Price';
import moment from 'moment';
import date from '../../../services/i18n/date';
import {SERVER_DATE_FORMAT} from "../../../variableConstants";
import FitImage from "react-native-fit-image";
import {IconSize} from "../../../constants/iconSize";

const planTransparent = require('../../../images/planTransparent.png');
const sessionTransparent = require('../../../images/sessionTransparent.png');

export const MembershipLength = props => {
    const {membership, style, largeNumber} = props;
    if (membership.is_recurring_payment === 1) {
        const recurringCycle = t(`screens:Shop:card:recurringCycle:${membership.recurring_cycle.toString()}`, {});
        return (
            <React.Fragment>
                <AppText
                    style={style}>{t(`screens:Shop:card:plan`, {recurringCycle})}</AppText>
            </React.Fragment>
        )
    }
    if(membership.type === MEMBERSHIP_TYPES_CONST.TYPE_SESSION){
        return (
            <React.Fragment>
                <AppText>{membership.sessions} </AppText>
                <AppText>{t(`screens:Shop:card:sessions`, {})}</AppText>
            </React.Fragment>
        )
    }
    if (membership.period_amount !== null && membership.period_time_unit !== null) {
        const period_time_unit = t(`common:timePeriods:${membership.period_time_unit.toLowerCase()}`, {count: membership.period_amount});
        if (membership.period_amount === 1) {
            return (
                <React.Fragment>
                    <AppText style={[{marginTop: -8, textTransform: 'capitalize'}, ...style]}>{t(`common:one${membership.period_time_unit.toLowerCase()}`)}</AppText>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <AppText style={[...style,globalStyles.heeboBold,largeNumber ? {fontSize: scale(25)} : {}]}>{membership.period_amount} </AppText>
                    <AppText style={[{marginTop: -8, textTransform: 'capitalize'}, ...style]}>{period_time_unit}</AppText>
                </React.Fragment>
            )}
    }
    return null;


};

export const MembershipDeparments = props => {
    const {membership, style} = props;
    return (
        <AppText
            style={[globalStyles.grayText, style]}>{membership.departments ? membership.departments.name : t("modals:BookClass:generalExplicit")}</AppText>
    )
}

export const MembershipSessionNumberOrPlanLength = props => {
    const {type, membership, stylesOverride} = props;
    const color = type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? Colors.brandedGreen : Colors.brandedBlue;
    const styles = [globalStyles.heeboMedium, {color}, {...stylesOverride}];
    return (
        <View style={[globalStyles.flexDirectionColumn, {alignItems: 'center'}]}>
            {type === MEMBERSHIP_TYPES_CONST.TYPE_SESSION ? (
                <React.Fragment>
                    <AppText
                        style={[styles, globalStyles.heeboBold, {fontSize: scale(25)}]}>{membership.sessions}</AppText>
                    <AppText style={[{
                        marginTop: -8,
                        textTransform: 'capitalize'
                    }, ...styles]}>{t(`screens:Shop:card:sessions`, {})}</AppText>
                </React.Fragment>
            ) : <MembershipLength largeNumber={true} membership={membership} style={styles}/>}
        </View>
    )
}

export const MembershipCard = props => {
    const {type, onPress} = props;

    return (
        <TouchableOpacity key={uuid.v4()}
                          onPress={() => onPress()}
                          style={[globalStyles.simpleCardContainer, globalStyles.cardsShadow, globalStyles.flexDirectionRow]}>
            <View
                 style={[{
                backgroundColor: type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ?  '#EFFFF9' : '#F8FCFF',
                width: '25%',
                resizeMode: "center",
                justifyContent: "center",
            }]}>
                <Image style={[{position:'absolute',bottom:0, start: 0}, i18n.isRTL && {transform: [{ rotate: '270deg' }]}]} source={type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? planTransparent : sessionTransparent}/>
                <MembershipSessionNumberOrPlanLength {...props}/>
            </View>
            <MembershipDetails {...props}/>
        </TouchableOpacity>
    )
}

export const PurchaseEndCard = (props) => {
    const {membership} = props;
    const name = membership.type === MEMBERSHIP_TYPES_CONST.TYPE_ITEM ? 'shopaholic' : MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? 'membership-card' : 'sessions'
    return (
        <View style={[globalStyles.flexDirectionRow, {height: verticalScale(50), alignItems: 'center'}]}>
            <View style={{
                width: '25%',
                justifyContent: "center",
                alignItems: 'center'
            }}>
                {membership.image ? <Image
                    style={[styles.imageWrap, {width: scale(40), height: scale(40)}, {overflow: 'hidden'}]}
                    source={{uri: membership.image}}
                /> : <Icons8Generator size={IconSize.XL} name={name}/>}
            </View>
            <PurchaseEndDetails {...props}/>
        </View>
    )

}

export const PurchaseEndDetails = (props) => {
    const {membership, hidePrice} = props;
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    const price = membership.price * membership.quantity;
    return (
        <View style={[globalStyles.flex, globalStyles.flexDirectionRowSpaceBetween]}>
            <AppText  numberOfLines={1}
                        style={[{maxWidth:scale(150)}]}>{membership.name}</AppText>
            { !hidePrice && <AppText style={{marginHorizontal: scale(15)}}>{currencySymbol}{price.toFixed(2)}</AppText>}
        </View>
    )
}

export const CartCard = props => {
    const {membership, onPress} = props;
    if (membership.type !== MEMBERSHIP_TYPES_CONST.TYPE_ITEM)
        return <MembershipCard {...props} cart={true}/>
    return (
        <TouchableOpacity key={uuid.v4()}
                          onPress={() => onPress()}
                          style={[globalStyles.simpleCardContainer, globalStyles.cardsShadow, globalStyles.flexDirectionRow]}>

            <View style={[{
                backgroundColor: Colors.white,
                width: '25%', justifyContent: 'center', alignItems: 'center'
            }]}>
                <ProductImageOrIcon membership={membership} cart={true} size={EXTRA_HUGE_ICON_SIZE}/>
            </View>
            <MembershipDetails {...props} cart={true}/>
        </TouchableOpacity>
    )
}


export const MembershipDetails = props => {
    const {membership, cart, subQuantity, addQuantity, removeFromCart} = props;
    const showExtra = !cart || membership.is_recurring_payment === 1;
    return (
        <View style={[globalStyles.appMinimalPaddingHorizontal, {
            flexGrow: 1,
            justifyContent: 'space-between',
            // backgroundColor:Colors.black,
            marginVertical: verticalScale(8)
        }]}>
            <View style={globalStyles.flexDirectionRowSpaceBetween}>
                <View>
                    <AppText
                        numberOfLines={1}
                        style={[globalStyles.heeboMedium,{maxWidth:scale(200)}]}>{membership.name}</AppText>
                    <MembershipBigBoxDetails {...props}/>
                </View>
                {cart ? (
                    <TouchableOpacity
                        hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
                        onPress={removeFromCart}>
                        <Icons8Generator size={MEDIUM_ICON_SIZE} fill={Colors.red} name={'trash'}/>
                    </TouchableOpacity>) : null}
            </View>
            <View
                style={[globalStyles.flexDirectionRow,{height:verticalScale(22),alignItems:'center'}]}>
                <Price membership={membership} extraInfoDisplay={showExtra}/>
                {cart ? <View style={{flex: 1, alignItems: 'flex-end'}}><MembershipQuantity membership={membership}
                                                                                            addQuantity={addQuantity}
                                                                                            subQuantity={subQuantity}/></View> : null}
            </View>
        </View>
    )
}

export const MembershipQuantity = props => {
    const {membership, subQuantity, addQuantity} = props;
    return (
        <View style={globalStyles.flexDirectionRow}>
            <TouchableOpacity style={[styles.plusMinusButtons, membership.quantity === 1 ? {opacity: 0.6} : {}]}
                              hitSlop={{top: 15, bottom: 15, left: 15, right: 15}}
                              onPress={subQuantity}>
                <Icons8Generator size={MEDIUM_ICON_SIZE} fill={'#000000'} name={'minos'}/>
            </TouchableOpacity>
            <AppText style={[globalStyles.heeboRegular, {alignSelf:'center',marginHorizontal: scale(10)}]}>{membership.quantity}</AppText>
            <TouchableOpacity style={styles.plusMinusButtons}
                              hitSlop={{top: 15, bottom: 15, left: 15, right: 15}}
                              onPress={addQuantity}>
                <Icons8Generator size={MEDIUM_ICON_SIZE} fill={'#000000'} name={'plus'}/>
            </TouchableOpacity>
        </View>
    )

}

export const MembershipBigBoxDetails = props => {
    const {membership, cart} = props;

    const noCommitment = membership.period_amount === null || membership.period_time_unit === null;
    if (membership.is_recurring_payment === 1) {
        const user = useSelector(state => state.user.data);
        const users_boxes = user.users_boxes.find(ub => ub.box_fk === membership.box_fk);
        if (cart && users_boxes) {
            //TODO CALC START DATE!
            const box = users_boxes.box;
            const start_date = membership.start ? membership.start : moment().format(SERVER_DATE_FORMAT);
            let first_payment_date = getFirstPaymentDate(start_date, membership, box);
            const recurring_first_pay_date = date.format(first_payment_date, 'LL')
            return <AppText
                style={[globalStyles.grayText, {fontSize: scale(12)}]}>{(t('screens:Cart:firstPayDate', {recurring_first_pay_date}))}</AppText>
        }
        return (
            <React.Fragment>
                <AppText
                    style={[globalStyles.heeboRegular, globalStyles.grayText, {fontSize: scale(11)}]}>{noCommitment ? t('screens:Shop:card:membershipDetails:noCommitment', {}) :
                    <DisplayCommit membership={membership}/>}</AppText>
            </React.Fragment>
        )
    }
    return (<MembershipDeparments membership={membership}/>)

}

export const DisplayCommit = props => {
    const {membership} = props;
    const period_time_unit = t(`common:timePeriods:${membership.period_time_unit.toLowerCase()}`, {count: membership.period_amount});
    return t('screens:Shop:card:membershipDetails:commitment', {period: membership.period_amount, period_time_unit})
}


export const ProductImageOrIcon = props => {
    const {membership, size, cart} = props;

    if (membership.image) {
        return (
            <FitImage
                style={[styles.imageWrap, cart ? {width: scale(80), height: scale(80)} : {}, {overflow: 'hidden'}]}
                source={{uri: membership.image}}
            />
        )
    }
    return <Icons8Generator name={'shopaholic'} fill={Colors.brandedPink} size={size}/>

}


const styles = StyleSheet.create({
    plusMinusButtons: {
        width: scale(22),
        height: scale(22),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 100,
        backgroundColor: Colors.iconBorder
    },

    imageWrap: {
        width: scale(160),
        height: scale(160),
        alignSelf: "center",
        borderRadius: 4
    }

});