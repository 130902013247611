export const FIRST_PAY_JS = `
var firstpay = {};

// cryptogram
firstpay.cryptogram = (function () {

	// messageCodes
	var messageCodes = {
        configuration: 101,
        processPayment: 102
	}

	// elements
	var elements = {
		// selectors
		scriptElementId: 'firstpay-script-cryptogram',
		iframeElementId: 'firstpay-iframe',

		// elements
		scriptElement: null,
		iframeElement: null,

		// script attributes
		scriptAttributeTranscenterId: 'transcenterId',
		scriptAttributeProcessorId: 'processorId',
        scriptAttributeTransactionType: 'transactionType',
        scriptAttributeAmountEnabled: 'amountEnabled',
        scriptAttributeAmount: 'amount',
		scriptAttributeManualSubmit: 'manualSubmit',
        scriptAttributeSubmitText: 'submitText',
		scriptAttributeLanguage: 'language',
		scriptAttributeDisablePaste: 'disablePaste',
	
        // credit card
		scriptAttributeCvv: 'cvv'
	}

	// init
	var init = function () {
		const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=0.5, maximum-scale=0.5, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);
		document.body.style.backgroundColor = '#F7F7F7';
		// elements
		elements.scriptElement = document.getElementById(elements.scriptElementId);
		elements.iframeElement = document.getElementById(elements.iframeElementId);
        
		
		
		 

		// message handler
		if (window.addEventListener) window.addEventListener("message", processMessage, false);
		else window.attachEvent("onmessage", processMessage);
	}

	// START: MESSAGE HANDLING

	// processMessage
	var processMessage = function (event) {        
		// firstpay message check
		if (event.data.firstpay) {
			// configuration
			  window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
			if (event.data.code == messageCodes.configuration) processConfigurationMessage(event.data);
		}
	}


	var processConfigurationMessage = function (messageData) {
	    var transcenterId = elements.iframeElement.dataset[elements.scriptAttributeTranscenterId] || '';
	    var processorId = elements.iframeElement.dataset[elements.scriptAttributeProcessorId] || '';
        var transactionType = elements.iframeElement.dataset[elements.scriptAttributeTransactionType] || '';
        var amountEnabled = elements.iframeElement.dataset[elements.scriptAttributeAmountEnabled] || '';
		var amount = elements.iframeElement.dataset[elements.scriptAttributeAmount] || '';

	    if (transcenterId == undefined || transcenterId == null || transcenterId.trim() == '') {
	        console.error('data-transcenter-id is required firstpay iframe\\'s attribute');
	        return;
	    }

	    if (processorId == undefined || processorId == null || processorId.trim() == '') {
	        console.error('data-processor-id is required firstpay iframe\\'s attribute');
            return;
        }

	    if (transactionType == undefined || transactionType == null || transactionType.trim() == '') {
	        console.error('data-transaction-type is required firstpay iframe\\'s attribute');
            return;
        }

	    var manualSubmit = elements.iframeElement.dataset[elements.scriptAttributeManualSubmit] ? parseBoolean(elements.iframeElement.dataset[elements.scriptAttributeManualSubmit], true) : true;
        var submitText = elements.iframeElement.dataset[elements.scriptAttributeSubmitText] || '';
        var language = elements.iframeElement.dataset[elements.scriptAttributeLanguage];
		var disablePaste = elements.iframeElement.dataset[elements.scriptAttributeDisablePaste] ? parseBoolean(elements.iframeElement.dataset[elements.scriptAttributeDisablePaste], false) : false;
        
	    // credit card
	    var cvv = elements.iframeElement.dataset[elements.scriptAttributeCvv] ? parseBoolean(elements.iframeElement.dataset[elements.scriptAttributeCvv], true) : true;

		// processFirstPayStylesheets
		var firstPayRules = processFirstPayStylesheets();

        //iframe check
        elements.iframeElement = elements.iframeElement.contentWindow ? elements.iframeElement : document.getElementById(elements.iframeElementId)

        // postMessage
        var responseObj = {
            // main
            firstpay: true,
            code: messageCodes.configuration,
            type: 'configuration',
            transcenterId: transcenterId,
            processorId: processorId,
            transactionType: transactionType,
            amountEnabled: amountEnabled,
            amount: amount,
            manualSubmit: manualSubmit,
            submitText: submitText,
			language: language,
			disablePaste: disablePaste,

            // credit card
            cvv: cvv,

            // style sheets
            firstPayRules: firstPayRules
        };
        elements.iframeElement.contentWindow.postMessage(responseObj, '*');
        window.ReactNativeWebView.postMessage(JSON.stringify(responseObj));

	}
	
	// END: MESSAGE HANDLING

	// START: STYLESHEETS

	// processFirstPayStylesheets
	var processFirstPayStylesheets = function () {
		// firstPayRules
		return [
		{selectorText : "inputHeight" ,cssText: 'input { height:10vh; background-color: white;}'},
		{selectorText : "fontSize" ,cssText: '.firstpay-input,.firstpay-label { font-size:2rem;'},
		{selectorText : "fontSize" ,cssText: '.firstpay-form-row { margin-bottom:5vh;'},
		{selectorText : "selectHeight" ,cssText: 'select { height:10vh; background-color: white;}'},
		{selectorText : "selectColumn" ,cssText: '.firstpay-column-expiration-month,.firstpay-column-expiration-year { padding-left:0; float:none; width: 100% }'},
        ]
		var firstPayRules = [];

		// styleSheets
		var styleSheets = document.styleSheets;
    			  window.ReactNativeWebView.postMessage(JSON.stringify('style1'));


		// has styleSheets
		if (styleSheets == null || styleSheets == undefined) return

		// looping style sheets
		for (var styleSheetIndex = 0; styleSheetIndex < styleSheets.length; styleSheetIndex++) {
			// loopStyleSheet
			var loopStyleSheet = styleSheets[styleSheetIndex];

			// loopRules
            try {

                var loopRules = loopStyleSheet.cssRules || loopStyleSheet.rules;
            }
            catch (err){
                console.log("An error occurred while applying custom styles. Some custom CSS will not be reflected.");
            }
			// has rules
			if (loopRules == null || loopRules == undefined) continue;

			// lopping rules
			for (var ruleIndex = 0; ruleIndex < loopRules.length; ruleIndex++) {
				// loopSingleRule
				var loopSingleRule = loopRules[ruleIndex];

				// does selector contains firstpay
				if (loopSingleRule.selectorText && loopSingleRule.selectorText.indexOf('firstpay') != -1)
				    firstPayRules.push({ selectorText: loopSingleRule.selectorText, cssText: loopSingleRule.cssText });
			}
		}
    			  window.ReactNativeWebView.postMessage(JSON.stringify('style2'));
    			  window.ReactNativeWebView.postMessage(JSON.stringify(firstPayRules));

		// return
		return firstPayRules;
	}

	// END: STYLESHEETS

	// Parse fields that we treat as a boolean according to known values
	var parseBoolean = function (booleanString, defaultValue) {
		var isTrue = (/^(true|yes|1)$/i).test(booleanString);
		var isFalse = (/^(false|no|0)$/i).test(booleanString);

		if (isTrue) return true;
		if (isFalse) return false;

		// if invalid string return default
		return defaultValue;
	}

	// documentReady
	var documentReady = function () {
		// readyState
		var state = document.readyState;
		
		// init on ready
		(state === 'interactive' || state === 'complete') ? init() : document.addEventListener('DOMContentLoaded', init);
	};

	// documentReady
    documentReady();

})();`;