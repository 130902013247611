import React from 'react';
import {connect} from "react-redux";
import {View, StyleSheet, TouchableOpacity, ScrollView, Alert, ActivityIndicator} from 'react-native';
import {bindActionCreators} from "redux";
import RNPickerSelect from 'react-native-picker-select';
import Spinner from 'react-native-loading-spinner-overlay';
import uuid from 'uuid';
import {apiAction} from "../../helpers/HTTP";
import {Colors} from "../../styles/Colors";
import {scale} from "react-native-size-matters";
import {globalPickerSelectStyles, globalStyles} from "../../styles/Global";
import {AppText} from "../../components/Stateless/AppText";
import {
    LARGE_BETWEEN_HUGE_ICON_SIZE,
    SMALL_ICON_SIZE
} from "../../helpers/functions";
import {t} from "../../services/i18n";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import HeaderWrapper from "../../components/HeaderWrapper";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {MEMBERSHIP_TYPES_CONST} from "../../constants/membershipTypes";
import {fetchShopAbleItems, fetchTaxes, fetchCurrencySymbol, emptyCart,setShopLocationsBox} from "../../actions/shop";
import {setActiveUsersBoxes} from "../../actions/user";
import MembershipAndItemsWrapper from "../../components/Shopping/MembershipAndItemsWrapper";
import Cart from '../../components/Shopping/Cart';
import {BoxImageOrIcon} from "../../components/Stateless/BoxImageOrIcon";
import {WebView} from 'react-native-webview';
import {EmptyState} from "../../components/Stateless/EmptyState";
import {IconSize} from "../../constants/iconSize";



class ShopScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.customArrowBehaviour = this.customArrowBehaviour.bind(this);
        this.itemsCount = 0;
        this.membershipCount = 0;
        this.boxIndex = 0;
        this.boxes = [];
        this.state = {
            isLoading: false,
            shopItemsType: null,
            title: t('screens:Shop:title', {})

        };
    }

    componentDidMount() {
        this.didFocusListener = this.props.navigation.addListener(
            'didFocus',
            () => {
                this.props.navigation.setParams({
                    tapOnTabNavigator: this.tapOnTabNavigator
                });
            },
        );
        this.boxes = this.props.user.users_boxes
        // Removed because it needs to work with with inactive boxes
        // .filter((item => item.active === 1))
            .map((item => {
                return {
                    ...item,
                    ...item.box,
                    value: item.box.name,
                }
            }));
        this.setState({
            items: this.boxes.map((option, index) => ({
                key: index,
                label: option.value,
                value: option.value
            }))
        });
        this.loadShopData();
    }

    tapOnTabNavigator = async () => {
        this.loadShopData();
    };

    componentWillUnmount() {
        this.didFocusListener.remove();
    }

    render() {
        const noArrow = this.state.shopItemsType === null || (this.state.shopItemsType !== null && this.membershipCount > 0 && this.itemsCount && 0);
        return (
            <React.Fragment>
                <StatusBarWrap backgroundColor={this.props.whiteLabelProperties.primaryColor}/>
                <HeaderWrapper
                    headerColor={this.props.whiteLabelProperties.primaryColor}
                    headerTitle={this.state.title}
                    headerArrowColor={Colors.white}
                    noArrow={noArrow}
                    customArrowBehaviour={!noArrow ? this.customArrowBehaviour : null}
                    padding={globalStyles.appPaddingHorizontal}
                    isPop={true}>
                    <View style={globalStyles.flexDirectionRow}>
                      <Cart fill={this.props.whiteLabelProperties.primaryColorText}/>
                    </View>
                </HeaderWrapper>
                <View style={{flex:1}}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[{flexGrow: 1}]}>
                    {this.screen()}
                </View>
            </React.Fragment>
        );
    }

    screen() {
        let {inputIOS, inputAndroid, iconContainer} = globalPickerSelectStyles;
        const temp = {
            inputIOS: {...inputIOS, color: Colors.black},
            inputAndroid: {...inputAndroid, color: Colors.black,},
            viewContainer: {justifyContent: 'center', flex: 1, paddingHorizontal: scale(10)}
        };
        const combineStyles = StyleSheet.flatten([iconContainer, {...temp}]);
        if (this.state.isLoading)
            return (<Spinner visible={this.state.isLoading}/>);
        const box = this.boxes[this.boxIndex];
        const image = box ? box.cloudinary_image ? box.cloudinary_image : box.locations_box ? box.locations_box.logo : null : null;
        return (
            <View style={[globalStyles.flex, {backgroundColor: Colors.screensGrayBackground}]}>
                {this.boxes.length > 1 ? (<View style={[globalStyles.stickToHeaderBar,globalStyles.flexDirectionRow, globalStyles.appPaddingHorizontal]}>
                    <BoxImageOrIcon src={image}/>
                    <RNPickerSelect
                        items={this.state.items}
                        value={this.state.value}
                        style={combineStyles}
                        disabled={this.boxes.length === 1}
                        useNativeAndroidPickerStyle={false}
                        placeholder={{}}
                        onValueChange={(value, index) => {
                            this.boxIndex = index;
                            this.setState({value});
                            if (Platform.OS.toLowerCase() === 'android') {this.handleRNPickerExit();}
                        }}
                        Icon={() => this.icon(this.boxes.length === 1)}
                        onOpen={() => {
                            this.setState({
                                prevValue: this.state.value,
                                prevBoxIndex: this.boxIndex
                            });
                        }}
                        onClose={() => {this.handleRNPickerExit();}}
                        onDonePress={() => {console.log('on done press')}}
                    />
                </View>) : null}
                <View style={[globalStyles.flex]}>
                    {this.isIsrael ? (
                        <React.Fragment>
                        <WebView
                        scalesPageToFit={true}
                        onLoad={() => this.setState({iFrameLoader: false})}
                        style={{flex: 1, zIndex: 1000, backgroundColor: Colors.white}}
                        source={{uri: this.state.url}}/>
                        <Spinner visible={this.state.iFrameLoader !== false}/>
                        </React.Fragment>
                    ) : this.shopAbleItems()}
                </View>
            </View>
        )
    }

    handleRNPickerExit() {
        if (this.state.prevValue === this.state.value) {
            //NOTING CHANGED! make sure boxIndex is to prev aswell.
            this.boxIndex = this.state.prevBoxIndex;
            return true;
        }
        if (this.props.cart.length === 0) {
            this.loadShopData();
        } else {
            setTimeout(() => {
                Alert.alert(
                    t('screens:Shop:boxSwitch:alert:title', {}),
                    t('screens:Shop:boxSwitch:alert:info', {}),
                    [
                        {
                            text: t('screens:Shop:boxSwitch:alert:cancel', {}),
                            onPress: () => {
                                this.boxIndex = this.state.prevBoxIndex;
                                this.setState({value: this.state.prevValue})
                            },
                            style: 'cancel'
                        },
                        {
                            text: t('screens:Shop:boxSwitch:alert:ok', {}), onPress: () => {
                                //CLEAR CART AND CHANGE!
                                this.props.emptyCart();
                                this.loadShopData();
                            }
                        }
                    ],
                    {cancelable: false}
                );
            }, 800);

        }
    }

    customArrowBehaviour() {
        this.setState({shopItemsType: null,title:t('screens:Shop:title', {})});
    }

    icon(isSingle) {
        if (isSingle)
            return null;
        return <Icons8Generator name={'arrow-down'} size={SMALL_ICON_SIZE}
                                fill={Colors.black} marginTop={5}/>
    }

    async loadShopData() {
        if (this.boxes.length > 0) {
            try {
                this.setState({isLoading: true});
                this.activeBoxesId = this.boxes[this.boxIndex].box.id;
                this.activelocationsBoxId = this.boxes[this.boxIndex].locations_box.id;
                    await this.props.setShopLocationsBox({locationsBoxId:this.activelocationsBoxId,boxesId: this.activeBoxesId});
                    const memberships = await this.props.fetchShopAbleItems(`shop/${this.activeBoxesId}/${this.activelocationsBoxId}`);
                    await this.props.fetchCurrencySymbol(`shop/currencySymbol/${this.activelocationsBoxId}`);
                    this.membershipCount = memberships.data.plan.length + memberships.data.session.length;
                    this.itemsCount = memberships.data.item.length;
                    const users_boxes = this.props.user.users_boxes.find((item => item.box_fk === this.activeBoxesId));
                    if(users_boxes){
                        this.props.setActiveUsersBoxes(users_boxes);
                    }
                    this.setState({isLoading: false});
                // }

            } catch (e) {
                this.setState({isLoading: false, memberships: {}});
            }
        }
    }

    async loadPayments() {
        if (this.boxes.length > 0) {
            try {
                this.setState({isLoading: true, url: ''});
                const params = {
                    users_boxes_id: this.boxes[this.boxIndex].ub_id,
                    locations_box_id: this.boxes[this.boxIndex].locations_box.id
                };
                const url = await apiAction('shop/getPaymentsUrl', 'post', params);
                this.setState({isLoading: false, url});
            } catch (e) {
                this.setState({isLoading: false, url: ''});
            }
        }
    }

    shopAbleItems() {
        if (this.membershipCount === 0 && this.itemsCount === 0) {
            return <EmptyState header={t('screens:Shop:emptyState:header', {})}
                               text={t('screens:Shop:emptyState:text', {})}
                               iconName={'ghost'}
                               iconSize={IconSize.SPECIAL_SIZE_150}/>
        }
        if (this.state.shopItemsType !== null) {
            return <MembershipAndItemsWrapper selectedType={this.state.shopItemsType}/>
        }
        return (
            <View style={globalStyles.marginTopLarge}>
                {
                    [this.membershipCount > 0 ? this.selectType(MEMBERSHIP_TYPES_CONST.SELECT_TYPE_MEMBERSHIPS) : null,
                        this.itemsCount > 0 ? this.selectType(MEMBERSHIP_TYPES_CONST.SELECT_TYPE_ITEMS) : null]
                }
            </View>
        )
    }

    selectType(type) {
        const name = type === MEMBERSHIP_TYPES_CONST.SELECT_TYPE_MEMBERSHIPS ? 'membership-card' : 'cart';
        const text = type === MEMBERSHIP_TYPES_CONST.SELECT_TYPE_MEMBERSHIPS ? t('screens:Shop:card:memberships', {}) : t('screens:Shop:card:products', {});
        const title = type === MEMBERSHIP_TYPES_CONST.SELECT_TYPE_MEMBERSHIPS ? t('screens:Shop:card:memberships', {}) : t('screens:Shop:card:products', {});
        const count = type === MEMBERSHIP_TYPES_CONST.SELECT_TYPE_MEMBERSHIPS ? this.membershipCount : this.itemsCount;

        return (
            <TouchableOpacity key={uuid.v4()}
                              onPress={() => this.setState({shopItemsType: type,title:title})}
                              style={[globalStyles.simpleCardContainer, globalStyles.cardsShadow, globalStyles.flexDirectionRow, {marginHorizontal: scale(10)},
                                  ]}>
                <View style={[globalStyles.centerItems, {width: '35%'}]}>
                    <Icons8Generator name={name} size={LARGE_BETWEEN_HUGE_ICON_SIZE}/>
                </View>
                <View style={[{width: '60%', alignSelf: 'center'}]}>
                    <AppText style={[globalStyles.heeboRegular, globalStyles.subTitle]}>{text}</AppText>
                    <AppText style={[globalStyles.grayText]}>{t('screens:Shop:card:items', {count})}</AppText>
                </View>
            </TouchableOpacity>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchShopAbleItems,
            fetchCurrencySymbol,
            fetchTaxes,
            emptyCart,
            setShopLocationsBox,
            setActiveUsersBoxes
        },
        dispatch,
    );
};


const mapStateToProps = state => ({
    user: state.user.data,
    cart: state.shop.cart,
    whiteLabelProperties: state.whiteLabelProperties
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ShopScreen);
