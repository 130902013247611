import {SHOW_MODAL,SHOW_MODAL_SIMPLE, HIDE_MODAL, ERROR_MODAL} from './constants';

const initialState = {
    isModalVisible: false,
    cardType: '',
    modalType: '',
    isInfoModal: false,
    error: {
        show: false,
        message: '',
    },
};

let modalReducer;
export default (modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {...state, ...action.payload};
        case SHOW_MODAL_SIMPLE:
            return {...state, ...action.payload};
        case HIDE_MODAL:
            return initialState;
        case ERROR_MODAL:
            return {...state, isModalVisible: true, error: {show: true, message: action.payload}};
        default:
            return state;
    }
});
