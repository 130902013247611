import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {globalStyles} from "../../styles/Global";
import {verticalScale, scale, ScaledSheet} from "react-native-size-matters"
import UserAvatar from 'react-native-user-avatar';
import {AppText} from "../Stateless/AppText";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {Colors} from "../../styles/Colors";
import {BIG_ICON_SIZE, SMALL_ICON_SIZE} from "../../helpers/functions";
import {withNavigation} from "react-navigation";


@withNavigation
class WorkoutLeaderBoardRow extends React.PureComponent {


    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {};

    }

    results(item, fontWeight) {
        let value;
        if (item.viewable_total_time) {
            value = item.viewable_total_time
        } else {
            value = item.total_extra_reps > 0 ? item.total_rounds + ' + ' + item.total_extra_reps: item.total_rounds;
        }
        return (
            <View style={[globalStyles.flexDirectionRow, {alignItems: 'center'}]}>
                <Icons8Generator name={item.viewable_total_time ? 'time' : 'restart'} size={SMALL_ICON_SIZE}/>
                <AppText style={[globalStyles.paddingStart, fontWeight]}>{value}</AppText>
            </View>
        )
    }

    render() {
        const {arr} = this.props;

        return (
            <View style={globalStyles.flex}>
                {arr.map((item, i) => {
                    const user = item.users;
                    const me = user.id === this.props.user.id;
                    const fontWeight = me ? globalStyles.heeboMedium : globalStyles.heeboRegular;
                    return (
                        <View key={user.id}
                            style={[globalStyles.flexDirectionRow, globalStyles.rowBottomBorder, {height: verticalScale(50)}]}>
                            <TouchableOpacity
                                style={[globalStyles.appPaddingHorizontal, globalStyles.flex, globalStyles.flexDirectionRow, {
                                    alignItems: 'center'
                                }]}
                                onPress={() => {
                                    if (!me) {
                                        this.props.navigation.navigate('FriendProfile', {id: user.id});
                                    }
                                }}>
                                <AppText style={[fontWeight, {width: scale(15)}]}>{i + 1}.</AppText>
                                <View style={{width: scale(40)}}>{i + 1 < 3 ? (
                                    <Icons8Generator name={'prize'} size={BIG_ICON_SIZE}
                                                     fill={Colors.logbookPrizeColor}/>
                                ) : null}</View>
                                <UserAvatar
                                    size={scale(40)}
                                    name={user.full_name_shorten}
                                    src={user.image ? user.image : null}
                                />
                                <AppText
                                    style={[fontWeight, globalStyles.paddingStartLarge, {width: scale(120)}]}>{user.full_name_shorten}</AppText>
                                <View style={{flex: 1, alignItems: 'flex-end'}}>
                                    {this.results(item, fontWeight)}
                                </View>
                            </TouchableOpacity>
                        </View>
                    )
                })}

            </View>
        )
    }
}


const styles = ScaledSheet.create({});


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch,
    );
};

const mapStateToProps = state => ({
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(WorkoutLeaderBoardRow);
