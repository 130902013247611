import React from 'react';
import {View, ScrollView, Dimensions} from "react-native";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import {LinearWrapper} from "../../../components/Stateless/LinerWrapper";
import AppHeader from "../../../components/Stateless/AppHeader";
import LogBookCard from "../../../components/LogBookCard"
import WorkoutLeaderBoard from "../../../components/WorkoutLeaderBoard"
import date from '../../../services/i18n/date';
import uuid from 'uuid';
import {withNavigation} from "react-navigation";
import {AppText} from "../../../components/Stateless/AppText";
import moment from 'moment';
import {t} from "../../../services/i18n";
import {TextButton} from "../../../components/Stateless/TextButton";
import {RoundIcon} from "../../../components/Stateless/RoundIcon";
import {scale} from "react-native-size-matters";
import {TabView, TabBar, SceneMap} from 'react-native-tab-view';
import {TabBarCustom} from "../../../components/Stateless/TabBarCustom";
import {TAB_VIEW_WIDTH} from "../../../variableConstants";

@withNavigation
class WorkoutScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            workout: null,
            isLoading: true,
            index: 0,
            routes: [{key: '0', title:  t('screens:Logbook:workout', {})},
                {key: '1', title:  t('screens:Logbook:leaderboard', {})},
            ],
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        this.setState({workout: this.props.navigation.state.params.workout, isLoading: false})
    }


    screen() {
        const data = this.props.navigation.state.params.workout;
        const workoutDate = date.format(moment(data[0][0].name), 'MM/DD');
        const boxCategories = data[0][0].box_categories;
        const hasLeaderBoard = data[0][0].has_leader_board;
        const hasUserResult = data.some(boxSection => boxSection.some(obj => obj.user_result));
        return (
            <View key={uuid.v4()} >
                <View style={[globalStyles.flexDirectionColumn, globalStyles.centerItems]}>
                    <RoundIcon name={'gymnastics'}/>
                    <AppText
                        style={[globalStyles.heeboMedium, globalStyles.title, globalStyles.marginTopBetweenTextElements, {color: Colors.white}]}>{boxCategories.name}</AppText>
                    <AppText
                        style={[globalStyles.heeboRegular, globalStyles.subTitle, {color: Colors.white}]}>{workoutDate}</AppText>
                    <TextButton
                        onPress={() => {
                            this.props.navigation.navigate('AddWorkoutScreen', {workout: data});
                        }}
                        backgroundColor={Colors.white}
                        textColor={this.props.whiteLabelProperties.logbookColor}
                        text={hasUserResult ? t('screens:Logbook:editResults', {}) : t('screens:Logbook:fillResults', {})}
                    />
                </View>
                <View
                    style={[globalStyles.marginTopBetweenElements]}>
                    {hasLeaderBoard ? this.tabView() : (
                        <View style={[globalStyles.cardContainer]}>
                            <LogBookCard screen={'workout'} data={data}/>
                        </View>
                    )}
                </View>
            </View>
        )

    }

    tabView() {
        const data = this.props.navigation.state.params.workout;
        const {index, routes} = this.state;
        return (
            <TabView
                navigationState={{index, routes}}
                initialLayout={{width: scale(TAB_VIEW_WIDTH)}}
                renderScene={({route, focused}) => {
                    switch (route.key) {
                        case '0': {
                            return <View style={[globalStyles.cardContainer]}><LogBookCard screen={'workout'}
                                                                                           data={data}/></View>;
                        }
                        case '1': {
                            return <WorkoutLeaderBoard data={data}/>;
                        }
                        default:
                            return null
                    }
                }}
                onIndexChange={i => this.setState({index: i})}
                renderTabBar={(props) => <TabBarCustom {...props}
                                                       topBorder={false}
                                                       numOfItems={2}
                                                       backgroundColor={this.props.whiteLabelProperties.logbookColor}
                                                       indicatorColor={Colors.white}
                                                       tabStyleProps={{marginBottom: 8}}
                                                       textProps={{fontSize: scale(15), color: Colors.white}}/>}

            />
        );
    }

    render() {
        return (
            <LinearWrapper
                backgroundStart={this.props.whiteLabelProperties.logbookColor}
                backgroundEnd={this.props.whiteLabelProperties.logbookColor}
                barStyle="light-content"
                spinner={this.state.isLoading}>
                <View style={globalStyles.appMinimalPaddingHorizontal}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[globalStyles.statusBarGap, globalStyles.screenBottomPadding]}>
                        {<AppHeader pop={true} headerColor={Colors.white}/>}
                        {this.state.workout !== null ? this.screen() : null}
                    </ScrollView>
                </View>
            </LinearWrapper>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(WorkoutScreen);
