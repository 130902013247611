import React from 'react';
import {connect} from "react-redux";
import {View, StyleSheet, ScrollView, RefreshControl} from 'react-native';
import {bindActionCreators} from "redux";
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {
    CartCard,
} from "../../components/Stateless/MembershipDisplayer";
import {MEMBERSHIP_TYPES_CONST} from "../../constants/membershipTypes";
import {verticalScale, scale} from 'react-native-size-matters';
import {addToCart, removeFromCart, subQuantity, patchMembershipStartDate} from '../../actions/shop'
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import HeaderWrapper from "../../components/HeaderWrapper";
import {t} from "../../services/i18n";
import DrawerHandler from "../../components/DrawerHandler";
import ModalButton from "../../components/ModalButton";
import PriceSummary from "../../components/Shopping/PriceSummary";
import {DrawerDeleteLayout} from "../../components/Stateless/DrawerDeleteLayout";
import NavigationService from "../../helpers/NavigationService";
import uuid from 'uuid';
import {EmptyState} from "../../components/Stateless/EmptyState";
import {IconSize} from "../../constants/iconSize";

class CartScreen extends React.PureComponent {


    constructor(props) {
        super(props);
        this.subQuantity = this.subQuantity.bind(this);
        this.trashDrawer = this.trashDrawer.bind(this);
        this.actionButtons = this.actionButtons.bind(this);
        this.state = {
            activeMembership: {}
        }
    }


    render() {
        return (
            <React.Fragment>
                <StatusBarWrap backgroundColor={this.props.whiteLabelProperties.primaryColor}/>
                <HeaderWrapper
                    headerColor={this.props.whiteLabelProperties.primaryColor}
                    headerTitle={t('screens:Cart:title', {})}
                    headerArrowColor={Colors.white}
                    padding={globalStyles.appPaddingHorizontal}
                    isPop={true}/>
                <View style={[globalStyles.flex, {
                    backgroundColor: Colors.screensGrayBackground,
                }]}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[{flexGrow: 1,paddingTop: verticalScale(10)},globalStyles.appMinimalPaddingHorizontal]}>
                        {this.screen()}
                    </ScrollView>
                    {this.props.cart.length > 0 ? <PriceSummary/> : null}
                    {this.actionButtons()}
                    {this.trashDrawer()}
                </View>
            </React.Fragment>
        );
    }

    screen() {
        const {cart} = this.props;
        if(cart.length === 0)
            return <EmptyState header={t('screens:Cart:emptyState:header', {})}
                               text={t('screens:Cart:emptyState:text', {})}
                               iconName={'cart'}
                               iconSize={IconSize.SPECIAL_SIZE_150}/>
        return (
            cart.map(membership => {
                const type = membership.type;
                const color = type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? Colors.brandedGreen : Colors.brandedBlue;
                const icon = type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? 'membership-card' : 'two-tickets';
                return (<CartCard
                    key={uuid.v4()}
                    onPress={() => this.props.navigation.navigate("Product", {membership: membership})}
                    subQuantity={() => this.subQuantity(membership)}
                    addQuantity={() => this.props.addToCart(membership)}
                    removeFromCart={() => {
                        this.setState({activeMembership: membership}, () => this.RBSheet.open())
                    }}
                    color={color} icon={icon} type={type} membership={membership}/>)
            })
        )
    }


    trashDrawer() {
        return (
            <DrawerHandler
                ref={el => {
                    this.RBSheet = el ? el.ref.current : null;
                }}>
                <DrawerDeleteLayout onRemoveButton={() => this.onCartItemRemove()}
                                    onCancelButton={() => this.RBSheet.close()}
                                    headerText={t('screens:Cart:removeItem:header', {name: this.state.activeMembership.name})}
                                    infoText={t('screens:Cart:removeItem:info', {})}
                                    removeButtonText={t('screens:Cart:removeItem:remove', {})}
                                    cancelButtonText={t('screens:Cart:removeItem:cancel', {})}
                                    submitting={false}/>
            </DrawerHandler>
        )
    }

    actionButtons() {
        const hasCart = this.props.cart.length > 0;
        return (
            <React.Fragment>
                <View style={globalStyles.appPaddingHorizontal}>
                {hasCart > 0 ? (<ModalButton
                    onPress={() => this.props.navigation.navigate("Purchase")}
                    type='primary'
                    colors={this.props.whiteLabelProperties.primaryColor}
                    text={t('screens:Cart:checkout', {})}
                    overrideStyle={{width: '90%', minHeight: verticalScale(30),marginTop:verticalScale(10)}}
                    textColor={Colors.white}
                />) : null }
                <ModalButton
                    onPress={() => NavigationService.pop()}
                    type='secondary'
                    overrideStyle={[globalStyles.stickyButtonsMarginBottom,{marginTop: verticalScale(hasCart ? 10 : 0)}]}
                    colors={Colors.transparent}
                    text={t('screens:Cart:continueShopping', {})}
                    textColor={this.props.whiteLabelProperties.primaryColor}
                />
            </View>
            </React.Fragment>
                )
    }

    async onCartItemRemove() {
        await this.props.removeFromCart(this.state.activeMembership);
        this.RBSheet.close();
    }

    subQuantity(membership) {
        if (membership.quantity <= 1)
            return true;
        this.props.subQuantity(membership);
    }

}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            patchMembershipStartDate,
            addToCart,
            removeFromCart,
            subQuantity
        },
        dispatch,
    );
};


const mapStateToProps = state => ({
    cart: state.shop.cart,
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(CartScreen);
