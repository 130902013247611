import React from 'react';
import { TouchableOpacity } from "react-native";
import { Linking } from "react-native";
import { View } from "react-native";
import { verticalScale, ScaledSheet, scale } from "react-native-size-matters";
import { withNavigation } from 'react-navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavigationService from '../../helpers/NavigationService';
import { t } from "../../services/i18n";
import { Colors } from '../../styles/Colors';
import { globalStyles } from "../../styles/Global";
import { AppText } from "../Stateless/AppText";
import { Icons8Generator } from "../Stateless/Icons8Generator";
import { SeparatorWithText } from "../Stateless/SeparatorWithText";
import {fetchShopAbleItems} from '../../actions/shop'

class MembershipExpiredContent extends React.PureComponent {


    constructor(props) {
        super(props);

        this.membershipType = this.props.membershipType
        this.phone = this.props.phone
        this.iconSize = this.props.iconSize
        this.boxHasShop = this.props.boxHasShop
        this.productInShop = this.props.membershipType.show_in_app && this.props.membershipType.price && !this.props.isIL
    }
    

    renderExpiredContentWithoutShop = () => (
        <View style={[globalStyles.centerItems, globalStyles.marginTopBetweenElementsBig]}>
            <TouchableOpacity style={[globalStyles.centerItems]} disabled={!this.phone} onPress={() => {
                this.props.onLinkToShop?.();
                Linking.openURL(`tel:${this.phone}`)
            }}>
                <Icons8Generator height={verticalScale(this.iconSize)} width={verticalScale(this.iconSize)} fill={Colors.arboxBlue} name="phone" />
                <AppText style={[styles.expiredCardRenew, globalStyles.marginTopMedium]}>
                    {t("screens:Home:cards:expired-card:contact")}
                </AppText>
            </TouchableOpacity>
        </View>
    );

    renderExpiredContentWithShop = () => {
        
        const linkToProduct = async () => {
            try {
                const membershipTypeId = this.membershipType.id;
                const locationBoxId = this.membershipType.location_box_fk;
                const response = await this.props.fetchShopAbleItems(`shop/item/${membershipTypeId}/${locationBoxId}`);
                const membership = response.data;
                this.props.onLinkToShop?.();
                if(membership) {
                    NavigationService.navigate("Product", {membership})
                } else {
                    NavigationService.navigate('Shop')
                }
            } catch(err) {
                console.log(err);
            }
        }

            return (
                <View>
                {!!this.productInShop && (
                    <View>
                        <View style={[globalStyles.centerItems, globalStyles.marginTopBetweenElementsMedium, globalStyles.flexDirectionColumn]}>
                            <TouchableOpacity style={[globalStyles.centerItems]} onPress={linkToProduct}>
                                <Icons8Generator height={verticalScale(this.iconSize)} width={verticalScale(this.iconSize)} fill={Colors.arboxBlue} name="restart" />
                                <AppText style={[styles.expiredCardRenew, globalStyles.marginTopMedium]}>{t("screens:Home:cards:expired-card:renew")}</AppText>
                            </TouchableOpacity>
                            <AppText style={[styles.expiredMembershipName, globalStyles.marginTop]}>
                                {this.membershipType.name}
                            </AppText>
                        </View>
                        <SeparatorWithText textStyle={[globalStyles.helveticaSeparatorWithText]} style={[globalStyles.marginTopBetweenElements]} text="OR" color={Colors.grayText} />
                    </View>
                )}
                    <View style={[globalStyles.flexDirectionRow, globalStyles.centerItems, globalStyles.marginTopBetweenElements]}>
                        <View style={[{ flex: 1 }]}>
                            <TouchableOpacity style={[globalStyles.centerItems]}  disabled={!this.phone} onPress={() => {
                                this.props.onLinkToShop?.();
                                Linking.openURL(`tel:${this.phone}`)
                            }}>
                                <Icons8Generator height={verticalScale(this.iconSize)} width={verticalScale(this.iconSize)} fill={Colors.arboxBlue} name="phone" />
                                <AppText style={[styles.expiredCardRenew, globalStyles.marginTopMedium]}>{t("screens:Home:cards:expired-card:contact")}</AppText>
                            </TouchableOpacity>
                        </View>
                        <View style={[{ flex: 1 }]}>
                            <TouchableOpacity style={[globalStyles.centerItems]}  onPress={() => {
                                this.props.onLinkToShop?.();
                                NavigationService.navigate('Shop')
                            }}>
                                <Icons8Generator height={verticalScale(this.iconSize)} width={verticalScale(this.iconSize)} fill={Colors.arboxBlue} name="shopaholic" />
                                <AppText style={[styles.expiredCardRenew, globalStyles.marginTopMedium]}>{t("screens:Home:cards:expired-card:shop")}</AppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
        );
    }
        
    render() {
        return(
            <View>
                <View style={[globalStyles.centerItems,globalStyles.marginTopBetweenElements]}>
                    <AppText style={[styles.expiredCardContent, globalStyles.marginTopMedium]}>
                        {t("screens:Home:cards:expired-card:content")}
                    </AppText>
                    {this.boxHasShop ? this.renderExpiredContentWithShop() : this.renderExpiredContentWithoutShop()}
                </View>
            </View>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.data,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchShopAbleItems,
        },
        dispatch,
    );

const connectObject = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MembershipExpiredContent);

export default withNavigation(connectObject);


const styles = ScaledSheet.create({
    expiredCardContent: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        color: Colors.lightBlack,
        fontSize: verticalScale(12),
        lineHeight: verticalScale(23),
        width: scale(282),
    },
    expiredMembershipName: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.black,
        fontSize: verticalScale(12),
        fontWeight: '300',
    },
    expiredCardRenew: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.arboxBlue,
        height: verticalScale(23),
        fontSize: verticalScale(12),
        fontWeight: '400',
    },
    expiredCardSubTitle: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.grayText,
        fontSize: verticalScale(13),
        fontWeight: '300',
        marginTop: verticalScale(6),
    },
    expiredCardTitle: {
        ...globalStyles.heeboRegular,
        fontSize: verticalScale(21),
        fontWeight: '400',
    },
    expiredCardCloseTitle: {
        ...globalStyles.heeboRegular,
        fontSize: verticalScale(15),
        fontWeight: '400',
        color: Colors.lightBlack
    },
});