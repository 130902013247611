import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Gymnastics(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M22 2c-1.645 0-3 1.355-3 3v13.547L14.113 38.21l-.004.023a.822.822 0 00-.03.22c-.036.171-.079.335-.079.546 0 1.645 1.355 3 3 3a3.004 3.004 0 002.945-2.441l-.011.05L22.78 28h4.957l-3.59 15.492a1.08 1.08 0 00-.023.274c-.055.234-.125.46-.125.734 0 1.922 1.578 3.5 3.5 3.5a3.508 3.508 0 003.438-2.86l2.921-12.578 3.239 12.774a1 1 0 00.37.555C38.024 47.082 39.103 48 40.5 48c1.922 0 3.5-1.578 3.5-3.5 0-.23-.047-.418-.086-.61a1.096 1.096 0 00-.027-.277l-5.055-19.289.016.078C38.094 20.195 34.414 17 30 17h-5V5c0-1.645-1.355-3-3-3zm0 2c.566 0 1 .434 1 1v13c0 .55.45 1 1 1h6a6.985 6.985 0 016.879 5.754c.008.027.012.05.02.074l4.804 18.348.934-.266a1 1 0 00-.696 1.215c.043.148.059.27.059.375 0 .84-.66 1.5-1.5 1.5a1.488 1.488 0 01-1.453-1.148.528.528 0 00-.043-.133L34.78 28.082a1 1 0 00-.976-.754c-.461.008-.86.324-.965.777L28.98 44.73l-.011.047A1.484 1.484 0 0127.5 46c-.84 0-1.5-.66-1.5-1.5 0-.14.027-.29.086-.465a.998.998 0 00-.063-.777 1.004 1.004 0 00-.601-.492l.89.25 3.66-15.79a1 1 0 00-.187-.847A1.01 1.01 0 0029 26h-7c-.46 0-.86.316-.969.762l-3.043 12.37c-.004.02-.008.036-.008.056A.988.988 0 0117 40c-.566 0-1-.434-1-1 0-.063.012-.145.043-.266.004-.004.004-.011.004-.02l4.922-19.808a.972.972 0 00.031-.238V5c0-.566.434-1 1-1zM11.5 15A5.513 5.513 0 006 20.5c0 3.027 2.473 5.5 5.5 5.5s5.5-2.473 5.5-5.5-2.473-5.5-5.5-5.5zm0 2c1.945 0 3.5 1.555 3.5 3.5S13.445 24 11.5 24A3.484 3.484 0 018 20.5c0-1.945 1.555-3.5 3.5-3.5z" />
    </Svg>
  );
}

export default SvgIcons8Gymnastics;
