import * as React from 'react';
import {connect} from "react-redux";
import {
    View, ImageBackground,
    TouchableOpacity, Text, ActivityIndicator, Image
} from 'react-native';
import {scale, ScaledSheet, verticalScale} from 'react-native-size-matters';
import {bindActionCreators} from 'redux'
import {AppText} from "../Stateless/AppText";
import i18n, {t} from '../../services/i18n';
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";
import SvgLastYearSign from '../../svg/custom/LastYearSign';
import poweredByArbox from "../../images/poweredByArbox.png";
import UserAvatar from 'react-native-user-avatar';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {BIG_ICON_SIZE, LARGE_ICON_SIZE, MEDIUM_ICON_SIZE} from "../../helpers/functions";
import uuid from 'uuid';

class YearlySummaryShare extends React.PureComponent {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.refName = uuid.v4();
        console.log("initial ref: " + this.refName);
    }


    async componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <View>
                <ImageBackground
                    source={poweredByArbox}
                    style={[{width: '100%', height: '100%', paddingTop: 100}, globalStyles.centerStartItems,
                        globalStyles.flexDirectionColumn]}>
                    <View style={[globalStyles.flexDirectionColumn, {
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 30
                    }]}>
                        <SvgLastYearSign style={{...styles.cardAlignCenter}}/>
                        <Text style={[globalStyles.heeboBold, {
                            position: 'absolute',
                            fontSize: scale(24),
                            paddingTop: 30
                        }]}>
                            {this.props.data.year}
                        </Text>
                    </View>
                    <View style={[globalStyles.centerItems, {paddingTop: 20, paddingBottom: 20}]}>
                        <UserAvatar size={scale(80)}
                                    key={this.imageIndex}
                                    name={this.props.user.full_name}
                                    src={this.props.user.image ? this.props.user.image : null}
                        />
                        <View>
                            <AppText style={[globalStyles.heeboMedium, {
                                fontSize: scale(20),
                                textAlign: 'center',
                            }, globalStyles.directionText, globalStyles.marginTopBetweenTextElements]}> {this.props.user.full_name}</AppText>
                        </View>
                    </View>
                    <View style={styles.separator}/>
                    <AppText style={[globalStyles.heeboRegular, {
                        fontSize: scale(16)
                    }]}>
                        {t("screens:Home:cards:yearly-summary-card:title", {year: this.props.data.year})}</AppText>
                    <View style={styles.separator}/>
                    <View style={[globalStyles.flexDirectionRowSpaceBetween,{paddingTop: 20,marginLeft:scale(15),marginRight:scale(15)}]}>
                        {this.renderUserYearlySummaryParameter('status-clock', this.props.data.workoutHours, t('screens:Home:cards:yearly-summary-card:time-training'))}
                        {this.renderUserYearlySummaryParameter('status-calendar', this.props.data.classes, t('screens:Home:cards:yearly-summary-card:registered-training'))}
                        {this.renderUserYearlySummaryParameter('handshake-heart', this.props.data.newFriends, t('screens:Home:cards:yearly-summary-card:new-friends'))}
                    </View>
                </ImageBackground>

            </View>
        )
    }


    renderUserYearlySummaryParameter = (iconName, value, parameter) => {
        return (

            <View style={[globalStyles.flexDirectionColumn,{width: '30%',alignItems: 'center'}]}>
                <View >

                    <Icons8Generator name={iconName} fill={Colors.lightBlack} width={LARGE_ICON_SIZE}
                                     height={LARGE_ICON_SIZE}/>
                </View>

                <AppText style={[globalStyles.heeboBold,
                    styles.cardContent, styles.cardAlignCenter, {
                        fontSize: scale(24),
                        color: Colors.lightBlack,
                        height: verticalScale(30),
                    }]}>{value}</AppText>

                <AppText style={[globalStyles.heeboRegular, {
                    fontSize: scale(12),
                    color: Colors.lightBlack,
                }]}>{parameter}
                </AppText>
            </View>
        )
    }

}




const mapStateToProps = state => ({
    ...state,
    user: state.user.data,
    isUserLoading: state.user.isLoading,
    isLoading: state.home.isLoading,
    feeds: state.home.data,
    messagesCount: state.home.messagesCount,
    whiteLabelProperties: state.whiteLabelProperties,
    modal: state.modal,
});


const mapActionsToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(YearlySummaryShare);


const styles = ScaledSheet.create({
    separator: {
        height: 1,
        backgroundColor: Colors.graySeparator,
        alignSelf: 'stretch',
        marginLeft: 40,
        marginRight: 40,
        marginBottom: 20,
        marginTop: 20
    },
    marginsByRTL: {
        marginLeft: i18n.isRTL ? 0 : 18,
        marginRight: i18n.isRTL ? 18 : 0,
    },
});


