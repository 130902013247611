import React from 'react';
import {View} from 'react-native';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CalendarProvider, ExpandableCalendar} from 'react-native-calendars';
import i18n from "../../../services/i18n";
import {Colors} from "../../../styles/Colors";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {globalStyles} from "../../../styles/Global";
import moment from "moment/moment";
import LogBookItems from "../LogBookItems";
import {fetchWorkouts,fetchUserResults} from '../../../actions/logbook'
import {SERVER_DATE_FORMAT} from "../../../variableConstants";


class LogBookScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {
            isLoading: true,
            calendarDate: moment(),
            updateSources: ['calendarInit', 'todayPress', 'listDrag', 'dayPress', 'pageScroll', 'weekScroll', 'propUpdate'],
        };
        this.monthChanged = this.monthChanged.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.init = this.init.bind(this);
        this.getMarkedDates = this.getMarkedDates.bind(this);

    }


    componentDidMount() {
        this._isMounted = true;
        this.init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async monthChanged(month) {
        if(this._isMounted){
            const between = moment.utc(month.dateString);
            this.props.fetchUserResults(between.clone().startOf('month'), between.endOf('month'));
        }
    }

    async componentDidUpdate(prevProps) {

        if (this.props.userResults !== prevProps.userResults) {
            const markedDates = await this.getMarkedDates();
            this.setState({markedDates}, () => {
                setTimeout(() => {
                    this.forceUpdate()
                }, 1000)
            });
        }

    }

    renderArrow(direction) {
        let icon = i18n.isRTL ? direction === 'right' ? 'arrow-rtl' : 'arrow' : direction !== 'right' ? 'arrow-rtl' : 'arrow';
        return (
            <Icons8Generator name={icon} fill={Colors.black}/>
        )
    }

    onDateChange(date, updateSource) {
        if (this._isMounted) {
            this.setState({calendarDate: moment.utc(date)},() => this.init());
        }
    }

    init() {
        if (this._isMounted) {
            this.setState({isLoading: true}, async () => {
                await this.props.fetchWorkouts(this.state.calendarDate.clone().format(SERVER_DATE_FORMAT));
                await this.props.fetchUserResults(this.state.calendarDate.clone().startOf('month'), this.state.calendarDate.clone().endOf('month'));
                this.setState({isLoading: false});
            });
        }
    }

    getMarkedDates = () => {
        const marked = {};
        const current = this.state.calendarDate.clone().format(SERVER_DATE_FORMAT);
        this.props.userResults.forEach(item => {
            const color = current === item ? Colors.white : this.props.whiteLabelProperties.primaryColor;
            marked[item] = {marked: true, dotColor: color};
        });
        marked[current] = marked[current] ? {...marked[current],selected: true} : {selected: true}
        return Object.assign({}, JSON.parse(JSON.stringify(marked)));
    };

    render() {
        const isAndroidAndRTL = Platform.OS.toLowerCase() === 'android' && i18n.isRTL;
        const current = this.state.calendarDate.clone().format(SERVER_DATE_FORMAT);
        let calendarEvents = JSON.parse(JSON.stringify(this.getMarkedDates()));

        return (
            <View style={globalStyles.flex}>
                <CalendarProvider
                    date={current}
                    onDateChanged={this.onDateChange}
                    key={current}
                    onMonthChange={this.monthChanged}
                    style={[{flex: 0}]}>
                    <ExpandableCalendar
                        // style={styles.expendableCalendar}
                        current={current}
                        markedDates={calendarEvents}
                        theme={{
                            selectedDayBackgroundColor: this.props.whiteLabelProperties.logbookColor,
                            todayTextColor: this.props.whiteLabelProperties.logbookColor,
                        }}
                        hideKnob={Platform.OS.toLowerCase() === 'android'}
                        disablePan={Platform.OS.toLowerCase() === 'android'} //we need this
                        disableWeekScroll={isAndroidAndRTL}
                        firstDay={this.props.firstDay}
                        renderArrow={this.renderArrow}
                    />
                </CalendarProvider>
                <View style={[globalStyles.flex]}>
                    <LogBookItems
                        data={this.props.workouts}
                        isLoading={this.state.isLoading}
                        init={this.init}
                    />
                </View>
            </View>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchWorkouts,
            fetchUserResults,
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
    workouts: state.logbook.data,
    userResults: state.logbook.userResults,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(LogBookScreen);
