import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8PlusMath(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M24 9v15H9v2h15v15h2V26h15v-2H26V9z" />
    </Svg>
  );
}

export default SvgIcons8PlusMath;
