import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const SvgLogoBlack = (props) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 873.5 183.27" {...props}>
        <G data-name="Layer 2">
            <G data-name="Layer 1">
                <Path d="m49.29 90 18.52-57.53H68L86.12 90ZM85.06 0H51.65L0 144.72h31.2l10.24-29.86h53.4l9.16 29.86h32.07ZM218.47 144.72v-106h26.27v12.75a34.23 34.23 0 0 1 7.22-8.4 31.17 31.17 0 0 1 7.75-4.63 29.75 29.75 0 0 1 8.07-2 82.27 82.27 0 0 1 8.4-.43h3.66v28.6a46 46 0 0 0-7.75-.65q-25.62 0-25.62 25.63v55.13ZM441.61 115.75q-6.67 8.94-17.23 8.94-10.12 0-16.9-8.4t-6.79-22q0-34.44 23.48-34.45 24.1 0 24.12 32.73 0 14.22-6.68 23.15m32.19-47.13a52.11 52.11 0 0 0-8.5-17.34A38.51 38.51 0 0 0 451.62 40a41 41 0 0 0-18.41-4q-19.59 0-31.44 16.59V0H374.2v144.72h26.92l.22-12.92q9.69 15.72 29.5 15.72a44 44 0 0 0 18.42-3.88 40.19 40.19 0 0 0 14.53-11.2 54.36 54.36 0 0 0 9.48-17.87 77.05 77.05 0 0 0 3.44-23.91 78.9 78.9 0 0 0-2.91-22.07M614.38 124q-25.62 0-25.62-32.3 0-32.08 25.62-32.09T640 91.74q0 32.31-25.63 32.3m50.08-55.34A47.26 47.26 0 0 0 636.78 40a59.5 59.5 0 0 0-22.4-4 61 61 0 0 0-22.72 4 48.51 48.51 0 0 0-17.11 11.3 49.19 49.19 0 0 0-10.88 17.66 66.07 66.07 0 0 0-3.77 22.83 67.31 67.31 0 0 0 3.66 22.61 47.22 47.22 0 0 0 27.78 29.07 61 61 0 0 0 23 4.1 59.69 59.69 0 0 0 22.29-4 47.39 47.39 0 0 0 17-11.3 50.13 50.13 0 0 0 10.76-17.55 66.06 66.06 0 0 0 3.77-22.94 66.92 66.92 0 0 0-3.77-23M792.94 59.36l-2.26 3.45-13.42 20.67-29.21-44.72h31.48l13.41 20.6zM848.54 144.72h-31.29l-14.59-22.34 15.78-23.86 30.1 46.2zM873.5 0l-60.06 90.83-15.79 23.88-45.33 68.56h-30.9l60.5-91.53.37-.56 15.42-23.76.25-.37L841.93 0h31.57z" />
            </G>
        </G>
    </Svg>
)

export default SvgLogoBlack