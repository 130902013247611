import {createStackNavigator} from 'react-navigation-stack';

import HomeScreen from '../../screens/Home/HomeScreen';
import FriendSearchScreen from '../../screens/Home/FriendSearchScreen';
import NotificationScreen from '../../screens/Settings/NotificationScreen';
import FriendProfileContainer from "../FriendProfileContainer";
import AddWorkoutScreen from "../../screens/Logbook/AddWorkoutScreen";
import DigitalFormScreen from "../../screens/DigitalFormScreen";

let HomeNavigator;
export default HomeNavigator = createStackNavigator({
    HomeScreen: {
        screen: HomeScreen,
        navigationOptions: ({navigation}) => ({
            header: null
        }),
    },
    FriendSearchScreen: {
        screen: FriendSearchScreen,
        navigationOptions: ({navigation}) => ({
            header: null
        }),
    },
    FriendProfile: {
        screen: FriendProfileContainer,
        navigationOptions: ({navigation}) => ({
            header: null
        }),
    },
    Notification: {
        screen: NotificationScreen,
        navigationOptions: ({navigation}) => ({
            header: null
        }),
    },
    AddWorkoutScreen: {
        screen: AddWorkoutScreen,
        navigationOptions: ({navigation}) => ({
            header: null
        }),
    },
    DigitalForm: {
        screen: DigitalFormScreen,
        navigationOptions: ({navigation}) => ({
            header: null
        }),
    },

});

HomeNavigator.navigationOptions = {
    header: null,
};

