// @flow
import * as React from 'react';
import {ActivityIndicator, Image, Platform, TouchableOpacity, View} from "react-native";
import {Link} from "react-router-dom";
import Calendar from "../../../svg/Icons8CalendarBooked";
import Home from "../../../svg/Icons8Home"
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import Bag from "../../../svg/Icons8Shopaholic";
import User from "../../../svg/Icons8MaleUser";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {apiAction} from "../../../helpers/HTTP";
import {globalSiteStyles} from "../../../styles/Global";
import {isMobile} from "../../../helpers/functions";

const SiteDesktopHeader = (props) => {
    const {handleLogout, setShowLogin} = props;
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const user = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.data)
    const siteLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const [logging, setLogging] = useState(false);

    useEffect(() => {
        if(!isLoggedIn) {
            setLogging(false)
        }
    }, [isLoggedIn]);

    const logout = async () => {
        setLogging(true)
        handleLogout()
    }

    const routeClicked = (routeName) => {
        if(routeName === location.pathname) {
            location.href = `${window.location.origin}${window.location.pathname}${boxQueryString}`
        }
    }

    return (
        <>
            <View style={[styles.actions]}>
                {
                    box && box?.cloudinary_image ?
                        <Link to={{pathname: '/', search:boxQueryString || location.search}} style={{ textDecoration: 'none' }}><Image style={styles.logo} source={box?.cloudinary_image}/></Link>
                        :
                        <Link to={{pathname: '/', search:boxQueryString || location.search}} onClick={() => routeClicked('/')} style={{ textDecoration: 'none' }}>
                            <View style={styles.action}>
                                <Home/>
                                <AppText style={[styles.text]}>{t('headings:home', {})}</AppText>
                            </View>
                        </Link>

                }
                <Link to={{pathname: '/schedule', search:boxQueryString || location.search}} onClick={() => routeClicked('/schedule')} style={{ textDecoration: 'none' }}>
                    <View style={styles.action}>
                        <Calendar/>
                        <AppText style={[styles.text]}>{t('screens:Schedule:title', {})}</AppText>
                    </View>
                </Link>
                <Link to={{pathname: '/shop', search:boxQueryString || location.search}} onClick={() => routeClicked('/shop')} style={{ textDecoration: 'none' }}>
                    <View style={styles.action}>
                        <Bag style={{width: '1em', height: '1em'}}/>
                        <AppText style={[styles.text]}>{t('headings:online-shop', {})}</AppText>
                    </View>
                </Link>
            </View>
            <View style={styles.infoAndLogin}>
                {logging ? <ActivityIndicator color={Colors.brandedBlue}/> :
                    <>
                        {isLoggedIn ?
                            <View style={[styles.actions]}>
                                <View style={[styles.actions, {gap: '1em'}]}>
                                    {user.image ?
                                        <img style={{width: '2em', height: '2em', borderRadius: '50%'}} src={user.image}/>
                                        :
                                        <User style={styles.userProfileImg} fill={Colors.iconEmptyStateColor}/>
                                    }
                                    <AppText style={[styles.text]}>{user.full_name_shorten}</AppText>
                                </View>
                                <TouchableOpacity onPress={logout}>
                                    <AppText style={[styles.text]}>{t('screens:More:logout', {})}</AppText></TouchableOpacity>
                            </View>
                            :
                            <View style={styles.actions}>
                                <TouchableOpacity onPress={setShowLogin}>
                                    <AppText style={[styles.text]}>{t('screens:Login:login', {})}</AppText></TouchableOpacity>
                            </View>
                        }
                    </>
                }
            </View>
        </>
    );
};

export default SiteDesktopHeader

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            actions: {
                flexDirection: 'row',
                gap: '4em',
                alignItems: 'center',
                justifyContent: 'center'
            },
            action: {
                flexDirection: 'row',
                gap: '1em',
                alignItems: 'center'

            },
            logo: {
                width: '4em',
                height: '4em',
                borderRadius: 5
            },
            text: {
                fontSize: '0.95rem',
            },
            infoAndLogin: {
                flexDirection: 'row',
                gap: '6em'
            },
            userProfileImg: {
                width: '2em',
                height: '2em',
                borderRadius: '50%'
            },
        }
    })
})