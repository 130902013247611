import {
    REFERER,
    SELECTED_LOCATION,
    SITE_BOX,
    SITE_LANGUAGE,
    SITE_WHITELABEL,
    SITE_WHITELABEL_LINK,
    TOGGLE_SITE_REFETCH,
    HEADER_CONFIG,
    ISRAEL_PAYMENT_RES,
    ISRAEL_PAYMENT_RES_CLEANUP,
    ADD_BOX_TO_SITE_DATA,
    CHANGE_ACTIVE_BOX,
    LOGIN,
    LOGIN_BOX_USER, UPDATE_REGISTER_PROCESS_STEP, UPDATE_QUERY_STRING, UPDATE_USER_CREATED
} from "../constants";
import {apiAction} from "../helpers/HTTP";
import {store} from "../config/store";

export const addBoxToSiteList = (identifier, whitelabel, referer) => async (dispatch) => {
    dispatch({type: ADD_BOX_TO_SITE_DATA, payload: {identifier, whitelabel, referer}});
}

export const changeActiveBox = (identifier) => async (dispatch) => {
    dispatch({type: CHANGE_ACTIVE_BOX, payload: identifier});
}

export const updateIsSite = (referer) => {
    return async dispatch => {
        dispatch({type: REFERER, payload: referer});
    };
};

export const fetchBoxById = (identifier) => async (dispatch) => {
    const actionType = SITE_BOX.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`, payload: identifier});
        const response = await apiAction('site/box/'+identifier, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: {error, identifier}});
        return error;
    }
};

export const updateWhitelabel = (whitelabel) => {
    return async dispatch => {
        dispatch({type: SITE_WHITELABEL, payload: whitelabel});
    };
};

export const fetchAppLink = () => async (dispatch) => {

    const actionType = SITE_WHITELABEL_LINK.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`site/whitelabelAppLink`, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const updateSiteSelectedLocation = (location) => async (dispatch) => {
    dispatch({type: SELECTED_LOCATION, payload: location});
    return location;
};

export const updateLanguage = (lang) => {
    return async dispatch => {
        dispatch({type: SITE_LANGUAGE, payload: lang});
    }
};

export const toggleScheduleSiteReFetch = (flag= true) => async (dispatch) => {
    dispatch({type: TOGGLE_SITE_REFETCH, payload: flag});
}

export const updateHeaderConfig = (headerObj) => async (dispatch) => {
    const defaultHeaderObj = { processName: null, stepName: null, bgColor: null, headerText: null, subHeaderText: null, showTabs: true, steps: null, fromBackPress: false}
    const config = {...defaultHeaderObj, ...headerObj}
    dispatch({type: HEADER_CONFIG, payload: config});
}

export const updateIsraelPaymentRes = (res) => {
    return dispatch => {
        dispatch({type: ISRAEL_PAYMENT_RES, payload: res});
    };
};

export const cleanupIsraelPaymentRes = () => {
    return async dispatch => {
        dispatch({type: ISRAEL_PAYMENT_RES_CLEANUP});
    };
};

export const authenticateUserAfterRegistration = (user) => {
    return async dispatch => {
        dispatch({type: `${LOGIN_BOX_USER}_SUCCESS`, payload: {data: user}});
    };
};

export const registerProcessUpdateStep = (stepNumber) => {
    return async dispatch => {
        dispatch({type: UPDATE_REGISTER_PROCESS_STEP, payload: stepNumber});
    };
};

export const updateBoxQueryString = (identifier, whitelabel, referer, lang) => {

    const siteIdentifier = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.identifier;
    const siteLang = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.language;
    const siteWhitelabel = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.whitelabel;
    const siteReferer = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.referer;

    let str = `?identifier=${identifier || siteIdentifier}&referer=${referer || siteReferer}&whitelabel=${whitelabel || siteWhitelabel}&lang=${lang || siteLang || 'en'}`;
    console.log('updating query string to: ', str, " activeIdentifier is:", store.getState().minisite.activeIdentifier)
    return async dispatch => {
        dispatch({type: UPDATE_QUERY_STRING, payload: str});
        return str;
    };
};

export const updateUserCreatedFlag = (flag) => {
    return async dispatch => {
        dispatch({type: UPDATE_USER_CREATED, payload: flag});
    };
};