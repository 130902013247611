import {StyleSheet} from 'react-native';
import {scale, verticalScale} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import i18n from '../../services/i18n';
import {horizontalRTLFlip, isTextRTLStyles} from '../../styles/Layout';

export default StyleSheet.create({
    chooseClubListWrapper: {
        flexDirection: 'column',
        marginTop: scale(10),
        maxHeight: verticalScale(350),
        width: '100%',
    },
    clubImagePlaceholder: {
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        width: scale(40),
        height: scale(40),
        backgroundColor: Colors.clubPlaceholderColor,
    },
    clubRow: {
        width: '100%',
        flexDirection: 'row',
        paddingVertical: scale(12),
        paddingHorizontal: scale(8),
        borderWidth: 1,
        borderColor: Colors.lightGrey,
        borderRadius: 5,
        marginVertical: scale(5),
    },
    clubInfoWrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: scale(10),
    },
    nameWrapper: {
        flexDirection: 'row',
    },
    name: {
        ...isTextRTLStyles,
    },
    clubIcon: {
        ...horizontalRTLFlip,
    },
    checkmarkWrapper: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        right: -5,
        top: -5,
    },
    checkmark: {
        color: Colors.brandSuccess,
        fontSize: scale(14),
        // ...horizontalRTLFlip,
    },
});
