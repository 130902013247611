import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import {AppText} from "../Stateless/AppText";
import {ActivityIndicator, TouchableOpacity} from "react-native";
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";


// TODO NOTE - COPY OF ModelButton with dispatch changes
const RemoteSubmitButton = ({ dispatch,type,colors,text,overrideStyle,textColor,textStyles,formName,submitting,disabled,regular }) => {

    const buttonStyles = type ? type === 'primary' ? {backgroundColor: colors} : {
        borderWidth: 1,
        borderColor: colors,
    } : {};
    const override = overrideStyle ? overrideStyle : null;
    return (
        <TouchableOpacity disabled={submitting || disabled}
                          style={[styles.button, buttonStyles, override]}
                          onPress={() => dispatch(submit(formName))}>
            {
                submitting ?
                    <ActivityIndicator color={Colors.white}/> :
                    (<AppText
                        style={[styles.text,regular ? globalStyles.heeboRegular : {}, {color: textColor},
                            textStyles ? {...textStyles} : {}]}>{text}</AppText>)
            }
        </TouchableOpacity>
    )
}

const styles = ScaledSheet.create({
    button: {
        minHeight: verticalScale(40),
        marginTop: verticalScale(25),
        width: scale(180),
        borderRadius: scale(3),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    text: {
        fontSize: 15,
        fontFamily: 'heebo-bold',
    },
});

export default connect()(RemoteSubmitButton)
