import * as React from "react"
import Svg, { Defs, Path, ClipPath, Use, G } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 81 57",
        }}
        viewBox="0 0 81 57"
        {...props}
    >
        <Defs>
            <Path id="SVGID_1_" d="M0 0h80.2v56.7H0z" />
        </Defs>
        <ClipPath id="SVGID_00000033358602430006344970000014118134352466977708_">
            <Use
                xlinkHref="#SVGID_1_"
                style={{
                    overflow: "visible",
                }}
            />
        </ClipPath>
        <G
            style={{
                clipPath:
                    "url(#SVGID_00000033358602430006344970000014118134352466977708_)",
            }}
        >
            <Path
                d="M41.5 26.9c7.2 0 12.9-5.8 12.9-12.9.1-7.2-5.7-13-12.9-13s-13 5.8-13 12.9c.1 7.2 5.9 13 13 13zM41.3 26.9c-13.6 0-24.7 11.1-24.7 24.7v4.1H66v-4.1C66 38 54.9 26.9 41.3 26.9z"
                fill={'none'}
                stroke="#0C4596"
                strokeMiterlimit={10}
                strokeWidth={2}
            />
            <Path
                d="M54.7 26.9c7.2 0 13-5.8 13-12.9s-5.8-13-13-13M79.1 55.7v-4.1C79.1 38 68 26.9 54.4 26.9"
                stroke="#EE2D44"
                strokeMiterlimit={10}
                strokeWidth={2}
                fill={'none'}
            />
            <Path
                d="M25.5 26.9c-7.2 0-13-5.8-13-12.9s5.8-13 13-13M1 55.7v-4.1C1 38 12.1 26.9 25.7 26.9"
                stroke="#38B473"
                strokeMiterlimit={10}
                strokeWidth={2}
                fill={'none'}
            />
        </G>
    </Svg>
)
export default SvgComponent
