// @flow
import * as React from 'react';
import {View, TouchableOpacity} from "react-native-web";
import PersonalDetails from "./PersonalDetails";
import {useDispatch, useSelector} from "react-redux";
import {apiAction} from "../../../helpers/HTTP";
import {useEffect, useState} from "react";
import {isMobile, slotMockDataToSchedule} from "../../../helpers/functions";
import {addToCart, emptyCart, setShopLocationsBox} from "../../../actions/shop";
import PaymentHandlerContainer from "../Shop/Payment/PaymentHandler";
import {insert} from "../../../actions/schedule";
import Spinner from "react-native-loading-spinner-overlay";
import {ErrorHandler} from "../../ErrorHandler";
import {authenticateUserAfterRegistration} from "../../../actions/minisite";
import {fetchProfile} from "../../../actions/user";
import {AppText} from "../../Stateless/AppText";
import CancellationPolicy from "../Appointment/CancellationPolicy";
import {t} from "../../../services/i18n";

const PERSONAL_DETAILS = 'PERSONAL_DETAILS'
const PAYMENT = 'PAYMENT'
const CANCELLATION_POLICY = 'CANCELLATION_POLICY'

const RegisterHandler = (props) => {
    const {onBack, onResetPassword, selectedSlot, onRegisterSuccess, isAppointment} = props;
    const selectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation);
    const [hasPaymentServices, setHasPaymentServices] = useState(null);
    const [personalDetails, setPersonalDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [detailsFlag, setDetailsFlag] = useState(null);
    const [errors, setErrors] = useState(null);
    const [user, setUser] = useState(null);
    const [zeroPurchase, setZeroPurchase] = useState(false);
    const [step, setStep] = useState(PERSONAL_DETAILS);
    const dispatch = useDispatch();
    const mobile = isMobile();

    useEffect(() => {
        if(selectedLocation) {
            fetchLocationPaymentServices()
        }
    }, [selectedLocation]);

    useEffect(() => {
        if(detailsFlag && (!hasPaymentServices || zeroPurchase)) {
            registerWithDebt()
        }
    }, [detailsFlag, zeroPurchase]);

    useEffect(() => {
        if(user && (!hasPaymentServices || zeroPurchase)) {
            userCreatedSuccessfully(detailsFlag);
        }
    }, [user]);

    const renderStep = () => {
        //this only works when called directly from the return function.. otherwise, paymentMethodFlow function doesn't work properly
        switch (step) {
            case PERSONAL_DETAILS:
                return renderPersonalDetails()
            case CANCELLATION_POLICY:
                return renderCancellationPolicy()
            case PAYMENT:
                return renderPayment()
        }
    }

    const renderPersonalDetails = () => {
        const onSubmit = (data) => {
            setPersonalDetails(data);
            if(showCancellationPolicy()) {
                setStep(CANCELLATION_POLICY)
            } else {
                paymentMethodFlow()
            }
        }
        return <PersonalDetails onSubmit={(data) => onSubmit(data)} onBack={onBack} onResetPassword={onResetPassword}/>
    }

    const renderCancellationPolicy = () => {
        return <View style={[{height: mobile ? '90%' : 300, marginVertical: '2em'}]}><CancellationPolicy onAgree={paymentMethodFlow}/></View>
    }

    const renderPayment = () => {
        const onBack = () => {
            setStep(showCancellationPolicy() ? CANCELLATION_POLICY : PERSONAL_DETAILS);
        }
        return <PaymentHandlerContainer handleCreateUser={createUserSignUp} signUpDetails={personalDetails} show={true} setClose={() => null} isPopup={false} onProcessSuccess={(paymentRes) => userCreatedSuccessfully(paymentRes)} onBack={onBack}/>
    }

    const fetchLocationPaymentServices = async () => {
        const res = await apiAction(`locationsBox/doesLocationHasPaymentService/${selectedLocation.id}`, 'get', null);
        setHasPaymentServices(res)
    }

    const showCancellationPolicy = () => {
        return selectedLocation.cancellation_policy && selectedSlot.availability && selectedSlot.box_categories.price > 0
    }

    const paymentMethodFlow = async () => {
        const payForSlotMembership = selectedSlot.box_categories.membership_types.find(membership => membership.location_box_fk === selectedSlot.locations_box_fk && membership.type === 'service')
        if(hasPaymentServices && payForSlotMembership.price > 0) {
            await dispatch(emptyCart());
            await dispatch(setShopLocationsBox({locationsBoxId: selectedSlot.locations_box_fk, boxesId: selectedSlot.box_fk}));
            payForSlotMembership.quantity = 0;
            dispatch(addToCart(payForSlotMembership));
            setStep(PAYMENT)
        } else {
            payForSlotMembership.quantity = 1;
            let params = {
                payment_object: {
                    payment_details: {},
                    payLater: true,
                },
                cart: [payForSlotMembership],
                boxes_id: selectedSlot.box_fk
            };
            setZeroPurchase(payForSlotMembership.price === 0)
            setDetailsFlag(params)
        }
    }

    const registerWithDebt = async () => {
        await createUserSignUp()
    }

    const userCreatedSuccessfully = async (paymentRes) => {
        setIsLoading(true)
        let extra = isAppointment ? slotMockDataToSchedule(selectedSlot) : {};
        extra.purchaseData = paymentRes;
        extra.payForSlot = true;
        const locationMembership = selectedSlot.box_categories.membership_types.find(membership => membership.location_box_fk === selectedSlot.locations_box_fk && membership.type === 'service')
        const res = await dispatch(insert(selectedSlot.booking_option, selectedSlot.id, locationMembership.id, extra, user.token))
        res.isBooked = true;
        if(res.error || res.exception) {
            setErrors([res.error ? res.error.messageToUser : t('common:errorHeader', {})])
            res.isBooked = false;
        }
        console.log('insert res', res)
        if(!mobile) {
            await dispatch(authenticateUserAfterRegistration(user))
            dispatch(fetchProfile())
        } else {
            res.user = user;
        }
        setIsLoading(false)
        onRegisterSuccess(res)
    }

    const createUserSignUp = async (values) => {
        setIsLoading(true)
        let params = {
            tokenInfo: values,
            box_fk: selectedLocation.box_fk,
            locations_box_fk: selectedLocation.id,
            parent: {
                first_name: personalDetails.firstName,
                last_name: personalDetails.lastName,
                phone: personalDetails.phone,
                email: personalDetails.email,
                leadId: personalDetails.leadId || null,
            },
            child: null,
            hasPayments: hasPaymentServices && !zeroPurchase
        };
        const response = await apiAction('site/createUserFromSite', 'post', params);
        setUser(response.user)
        return response;
    }

    return (
        <View style={[isMobile() && {height: '100%'}, {position: 'relative'}]}>
            {errors ? <ErrorHandler errors={errors}/> : null}
            <Spinner visible={isLoading}/>
            {renderStep()}
        </View>
    );
};

export default RegisterHandler;
