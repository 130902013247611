import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Puzzled(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#191919" {...props}>
          <Path d="M5.916.984a6.766 6.766 0 00-1.717.317C2.5 1.8 1.3 2.8.6 4.1-.1 5.4-.198 6.9.302 8.6L2.5 8c-.3-1-.4-1.9-.2-2.5.2-1.1 1-1.9 2.3-2.3 1.1-.3 2.1-.2 2.7.2.6.4 1 1 1.3 1.7.2.6.2 1.2 0 1.8-.1.4-.3.7-.5 1.1l-.7 1.2c-.7 1.1-1 2.1-1.1 2.9-.1.8.1 1.8.4 2.9l2-.7c-.3-1-.4-1.8-.3-2.4.1-.6.4-1.3 1-2.3.8-1.3 1.3-2.3 1.5-2.9.2-.6.1-1.4-.1-2.2-.4-1.4-1.2-2.4-2.4-3A4.978 4.978 0 005.916.984zM25 3a22.89 22.89 0 00-11.502 3.084 1 1 0 001.004 1.73A20.864 20.864 0 0125 5c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 37.61 4 26c0-2.337.387-4.58 1.092-6.682a1 1 0 00-1.897-.636A22.974 22.974 0 002 26c0 12.69 10.31 23 23 23s23-10.31 23-23S37.69 3 25 3zm-6 7c-2.735 0-4.524 1.801-5.592 3.19a1 1 0 101.584 1.22C15.924 13.2 17.135 12 19 12c1.667 0 2.428.74 2.428.74a1 1 0 001.345-1.48S21.333 10 19 10zm12 1c-2.333 0-3.773 1.26-3.773 1.26a1 1 0 101.345 1.48S29.333 13 31 13c1.865 0 3.076 1.199 4.008 2.41a1 1 0 101.584-1.22C35.524 12.8 33.735 11 31 11zM9.6 16.3l-2.4.7.7 2.5 2.4-.7-.7-2.5zm8.4.7a2.5 4 0 00-2.5 4 2.5 4 0 002.5 4 2.5 4 0 002.5-4 2.5 4 0 00-2.5-4zm14 0a2.5 4 0 00-2.5 4 2.5 4 0 002.5 4 2.5 4 0 002.5-4 2.5 4 0 00-2.5-4zm1 16c-6.02 0-9.985 1.557-12.436 3.164-2.45 1.607-3.441 3.354-3.441 3.354a1 1 0 101.754.964s.66-1.253 2.783-2.646C23.784 36.443 27.32 35 33 35a1 1 0 100-2z" />
      </Svg>
  );
}

export default SvgIcons8Puzzled;
