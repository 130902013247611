import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8ExpandArrow(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#18181b" {...props}>
          <Path d="M44.984 12.969a2.012 2.012 0 00-1.398.617L25 32.172 6.414 13.586a1.995 1.995 0 00-3.285.645 1.993 1.993 0 00.457 2.183l20 20a2 2 0 002.828 0l20-20c.594-.57.781-1.45.461-2.21a2.004 2.004 0 00-1.89-1.235z" />
      </Svg>
  );
}

export default SvgIcons8ExpandArrow;
