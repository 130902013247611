import * as Sharing from 'expo-sharing';

export const openShareDialogAsync = async (imageUriToShare) => {
    try {
        if (!(await Sharing.isAvailableAsync())) {
            // TODO: ask to enable sharing;
            alert(`Uh oh, sharing isn't available on your platform`);
            return;
        }

        await Sharing.shareAsync(imageUriToShare);
    } catch(err) {
        alert(`Uh oh, sharing isn't available on your platform`);
    }
};