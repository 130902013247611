import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="50pt"
        height="50pt"
        viewBox="0 0 50 50"
        {...props}
    >
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="M47 36v6c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4v-6"
        />
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "miter",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="M25 4v31m8-8-8 8-8-8m8-23v31"
        />
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "miter",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="m33 27-8 8-8-8"
        />
    </Svg>
)

export default SvgComponent
