import {imageUpload} from '../helpers/HTTP';
import {
    PICTURE, UPDATE_IMAGE,
} from '../constants';


export const setProfilePicture = (imageUri,isGallery) => async (dispatch) => {
    const actionType = PICTURE.toUpperCase();
    const updateImage = UPDATE_IMAGE.toUpperCase();
    try {

        dispatch({type: `${actionType}_PENDING`,payload: imageUri});
        const response = await imageUpload(`user/uploadPicture`, imageUri,() => console.log('uploading process'));
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        dispatch({type: `${updateImage}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

