export const IconSize = {
    XXXS: 8,
    XXS: 12,
    XS: 16,
    S: 20,
    M: 24,
    L: 30,
    XL: 40,
    XXL: 50,
    XXXL: 60,
    SPECIAL_SIZE_85: 85,
    SPECIAL_SIZE_100: 100,
    SPECIAL_SIZE_150: 150,
    SPECIAL_SIZE_200: 200
};

