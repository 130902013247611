import * as React from 'react';
import {View, KeyboardAvoidingView, ActivityIndicator} from 'react-native';
import {WebView} from 'react-native-webview';
import {t} from '../../../services/i18n';
import {globalStyles} from "../../../styles/Global";
import {FIRST_PAY_JS} from "../../../constants/paymentsExternalJS/firstPay";
import {SelectPaymentMethodCard} from "../../Stateless/PaymentMethodCard";
import {Colors} from "../../../styles/Colors";
import ModalButton from "../../ModalButton";
import {connect} from "react-redux";
import {scale, verticalScale} from 'react-native-size-matters';
import {AppText} from "../../Stateless/AppText";
import Spinner from "react-native-loading-spinner-overlay";
import {withNavigation} from "react-navigation";
import CreateTokenForm from "../../Shopping/CreateTokenForm";
import {TAB_VIEW_HEIGHT} from "../../../variableConstants";
import RemoteSubmitButton from "../../ReduxFormHelpers/RemoteSubmitButton";
import {apiAction} from "../../../helpers/HTTP";
import BookingError from "../../ScheduleFlow/Booking/error";
import {ErrorHandler} from "../../ErrorHandler";
import {objHasAllProperties} from "../../../helpers/functions";


@withNavigation
class FirstPay extends React.PureComponent {


    constructor(props) {
        super(props);
        this.createToken = this.createToken.bind(this);
        this.state = {
            loadWebView: false,
            iFrame: false,
            iFrameUrl: 'https://secure.1stpaygateway.net/secure/PaymentHostedForm/v3/',
            iFrameEndPoint: null,
            addingToken: false,
            createTokenForm: false,
            enableCreateTokenForm: false,
            selected: this.props.boxPaymentService[0].payment_service === 'FIRSTPAY_CC' ? 'CreditCard' : 'Ach',
            error: false,
            selectedMethodIndex: 0,
            boxPaymentServiceId: this.props.boxPaymentService[0].id
        }
        this.isLoading = true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {

        return (
            <View style={{flex: 1}}>
                <View style={{flex: 1}}>
                    {this.state.errors ? <ErrorHandler errors={this.state.errors}/> : null }
                    {this.state.addingToken ? (<Spinner visible={true}/>) : null}
                    {this.state.iFrame !== true && this.state.createTokenForm !== true ? (
                        <React.Fragment>
                            <AppText
                                style={globalStyles.marginBottomVS15}>{t('screens:Purchase:selectPaymentMethod', {})}</AppText>
                            {this.props.boxPaymentService.map((item,key) => {
                                const temp = item.payment_service.toLowerCase();
                                const type = temp === 'firstpay_cc' ? 'card' : 'ach'
                                return (
                                    <SelectPaymentMethodCard
                                        onPress={() => this.setState(
                                            {
                                                selectedMethodIndex: key,
                                                boxPaymentServiceId: this.props.boxPaymentService[key].id,
                                                selected: type === 'card' ? 'CreditCard' : 'Ach'
                                            }
                                        )}
                                        object={type}
                                        key={key}
                                        text={t(`screens:Purchase:${type}`, {})}
                                        selected={key === this.state.selectedMethodIndex}
                                        iconName={type}
                                    />
                                )
                            })}
                        </React.Fragment>
                    ) : null}
                    {this.state.iFrame === true ? (
                       <WebView
                            ref={(ref) => {
                                this.webview = ref;
                            }}
                            scalesPageToFit={true}
                            originWhitelist={['*']}
                            textZoom={100}
                            renderLoading={() => <ActivityIndicator
                                color={this.props.whiteLabelProperties.primaryColor}/>}
                            scrollEnabled={false}
                            onMessage={(event) => this.messageEvent(event)}
                            injectedJavaScriptBeforeContentLoaded={FIRST_PAY_JS}
                            source={{
                                html: `
                <iframe id="firstpay-iframe"
                frameborder="0"
                style="overflow:hidden;height:100%;width:100%;"
                src="${this.state.iFrameUrl}${this.state.iFrameEndPoint}"
                data-transcenter-id="${this.state.transcenter}"
                data-processor-id="${this.state.processor}"
                data-transaction-type="Vault"
                data-manual-submit="false"
                data-submit-text="Next"
                >
                </iframe>`
                            }}
                        />
                    ) : null}
                    {this.state.createTokenForm ? <CreateTokenForm onSubmit={this.createToken} />: null}
                </View>
                <View
                    style={[globalStyles.flexDirectionRow, globalStyles.centerItems, globalStyles.stickyButtonsMarginBottom, {
                        justifyContent: 'flex-end',
                    }]}>
                        <React.Fragment>
                            <ModalButton
                                onPress={() => this.props.handlePrev()}
                                type='secondary'
                                colors={Colors.transparent}
                                text={t('common:back', {})}
                                overrideStyle={{width: '50%', minHeight: verticalScale(30)}}
                                textColor={this.props.whiteLabelProperties.primaryColor}
                            />
                            {this.state.createTokenForm ? <RemoteSubmitButton
                                type='primary'
                                colors={this.props.whiteLabelProperties.primaryColor}
                                text={t('common:submit', {})}
                                overrideStyle={{width: '50%', minHeight: verticalScale(30)}}
                                textColor={Colors.white}
                                formName={'CreateTokenForm'}/> : <ModalButton
                                onPress={() => !this.state.iFrame ? this.openIFrame() : this.setState({iFrame: false,createTokenForm: true})}
                                type='primary'
                                disabled={this.state.iFrame && !this.state.enableCreateTokenForm}
                                colors={this.state.iFrame && !this.state.enableCreateTokenForm ? Colors.brandedGraySelected : this.props.whiteLabelProperties.primaryColor}
                                text={t(this.state.iFrame ? "common:next" : "common:select", {})}
                                overrideStyle={{width: '50%', minHeight: verticalScale(30)}}
                                textColor={Colors.white}
                            />}
                        </React.Fragment>
                </View>
            </View>

        )
    }

    async createToken(values) {

        const {country,address,zip,state,city} = values;
        const params = {
            box_payment_services_id:  this.state.boxPaymentServiceId,
            payment_object: {...this.state.paymentObject,paymentType: this.state.iFrameEndPoint},
            users_boxes: {
                country, address, zip,state,city
            },
        };
        this.setState({addingToken: true});
        try{
            const response = await apiAction('payments/createToken', 'post', params);
            if(response.hasOwnProperty('data') && response.data) {
                if(objHasAllProperties(response.data,['errorMessages','isError']) && response.data.isError !== false && response.data.errorMessages.length > 0){
                    this.setState({addingToken: false,errors:  response.data.errorMessages})
                }else{
                   this.setState({addingToken: false},() => this.props.createTokenSuccess());
                }
            }else{
                this.setState({addingToken: false,errors:  [t('common:unknownFailure', {})]})
            }
        }catch (e) {
            console.log(e);
            this.setState({addingToken: false,errors:  [t('common:unknownFailure', {})]})
        }
    }

    openIFrame() {
        const searchFor = this.state.selected === 'CreditCard' ? 'FIRSTPAY_CC' : 'FIRSTPAY_ACH';
        const paymentService = this.props.boxPaymentService.find(item => item.payment_service === searchFor);
        if (!paymentService) {
            //TODO LOG TO SENTRY
        }
        this.setState({
                iFrameEndPoint: this.state.selected,
                transcenter: paymentService.accounting_service_token,
                processor: paymentService.api_terminal
            },
            () => this.setState({iFrame: true}));
    }

    messageEvent = (event) => {
        if (event.nativeEvent.data !== 'null') {
            const payload = JSON.parse(event.nativeEvent.data);
            if (payload.hasOwnProperty('code') && payload.code === 105 && payload.success === true) {
                this.setState({ enableCreateTokenForm: true,paymentObject:payload});
            }
            //TODO CHECK IF CAN REACT ON USER CHANGE NUMBER.
        } else {
            console.log(event.nativeEvent, 'data is properly null')
        }
    };

}


const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    null,
)(FirstPay);
