import React, {useEffect, useState} from 'react'
import {Image, Platform, TouchableOpacity, View} from 'react-native'
import { Colors } from "../../../styles/Colors"
import {scale, verticalScale} from "react-native-size-matters";
import {MobileNavigationMenu} from "../MobileNavigationMenu";
import {useLocation} from "react-router";
import {getPageHeaderOnPress} from "../../../helpers/ComponentFunctionality";
import EStyleSheet from "react-native-extended-stylesheet";
import {useSelector} from "react-redux";
import {Linking} from "react-native-web";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {globalSiteStyles} from "../../../styles/Global";


const MobilePageHeader = (props) => {
    const {handleShowLogin} = props;
    const location = useLocation();
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const [headerByLocation, setHeaderByLocation] = useState(null);

    useEffect(() => {
        if(box) {
            const header = getMobilePageHeader()
            setHeaderByLocation(header);
        }
    }, [location.pathname, box]);

    const getMobilePageHeader = () => {
        return <>
            {location.pathname === '/' ?
                <>
                    {box?.cloudinary_image && <Image style={styles.mobileLogo} source={box?.cloudinary_image}/>}
                    <View style={[styles.mobileActions]}>
                        <TouchableOpacity onPress={() => Linking.openURL(`tel:${box?.phone}`)}><Icons8Generator style={styles.mobileIcon} name={'phone'} fill={Colors.lightBlack}/></TouchableOpacity>
                        <TouchableOpacity onPress={() => setShowHamburgerMenu(true)}>
                            <Icons8Generator name={'menu'} style={styles.mobileIcon} fill={Colors.lightBlack}/>
                        </TouchableOpacity>
                    </View>
                </>
                :
                <>
                    {getPageHeaderOnPress(location.pathname)}
                    <View style={[styles.mobileActions]}>
                        <TouchableOpacity onPress={() => setShowHamburgerMenu(true)}>
                            <Icons8Generator name={'menu'} style={styles.mobileIcon} fill={Colors.lightBlack}/>
                        </TouchableOpacity>
                    </View>
                </>
            }
        </>
    }


    return (
        <View style={[globalSiteStyles().siteHeaderShadow, props.style,
            styles.wrapperMobile,
            {backgroundColor: Colors.white},
            location.pathname === '/' && box && !(box.cloudinary_image) && {justifyContent: 'end'}
        ]}>
            {headerByLocation}
            <MobileNavigationMenu show={showHamburgerMenu}
                                  setClose={() => setShowHamburgerMenu(false)}
                                  handleShowLogin={handleShowLogin}/>
        </View>
    )
}

export default MobilePageHeader;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapperMobile: {
                paddingHorizontal: scale(20),
                alignItems: 'center',
                maxHeight: '80'
            },
            mobileLogo: {
                width: '3em',
                height: '3em',
                borderRadius: 5
            },
            mobileActions: {
                flexDirection: 'row',
                gap: verticalScale(20)
            },
            mobileIcon: {
                width: '1.7em',
                height: '1.7em'
            },
        }
    })
})
