import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="50pt"
        height="50pt"
        viewBox="0 0 50 50"
        width="1em" height="1em"
        {...props}
    >
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "square",
                strokeLinejoin: "miter",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="m18 30 6.219 5.625L33 22"
        />
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "butt",
                strokeLinejoin: "round",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="M11 5H4c-.508 0-1 .43-1 .957v40.25c0 .531.492.793 1 .793h42c.508 0 1-.262 1-.793V5.957C47 5.43 46.59 5 46.082 5H38.75m-3.582 0H15M3 14h44"
        />
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "butt",
                strokeLinejoin: "round",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="M38 9h-2c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1ZM14 9h-2c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1Zm0 0"
        />
    </Svg>
)

export default SvgComponent
