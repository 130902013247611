import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Substract(props) {
  return (
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
          <Path d="M9 24v2h32v-2z" />
      </Svg>
  );
}

export default SvgIcons8Substract;
