import moment from "moment";
import he from "moment/locale/he";
import * as config from '../../config/i18n';
import {axiosInstance} from "../../helpers/HTTP";
import {store} from "../../config/store";
import {isWeb} from "../../helpers/functions";


const date = {
    /**
     * Load library, setting its initial locale
     *
     * @param {string} locale
     * @return Promise
     */
    init(locale) {
        return new Promise((resolve, reject) => {
            config
                .supportedLocales[locale]
                .momentLocaleLoader()
                .then(() => {
                    moment.locale(locale, [he]);
                    return resolve(true);
                })
                .catch(err => reject(err));
        })
    },

    /**
     * @param {Date} date
     * @param {string} format
     * @param {boolean} overrideLocation
     * @return {string}
     */
    format(date, format,overrideLocation = false) {
        const dateFormat = store.getState().user.data.dateFormat;
        return moment(date).format(overrideLocation ? format : dateFormat ? dateFormat : format);
    },
    timeFormat(time, format, workBreak = false,overrideLocation = false) {
        let timeFormat = isWeb() ? store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.siteSelectedLocation.time_format : store.getState().user.data.timeFormat;
        if(timeFormat === 'h:mm A' && workBreak){
            timeFormat = 'h:mm [\n] a'
        }
        return moment(time, format).format(overrideLocation ? format : timeFormat ? timeFormat : format);
    },
    friendlyDate(date, format = 'dddd, DD MMM') {
        return moment(date).format(format);
    },
    dayMonth(date) {
        let dateFormat = store.getState().user.data.dateFormat;
        dateFormat = dateFormat ? dateFormat.replace('/YYYY', '') : 'MM/DD';
        return moment(date).format(dateFormat);
    }

}

export default date;
