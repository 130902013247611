import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"
const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={87}
        height={100}
        fill="none"
        viewBox="0 0 87 100"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#0C4596"
                d="m43.18 2.31 41.18 23.77v47.55L43.18 97.4 2 73.63V26.08L43.18 2.31Zm0-2.31L0 24.93v49.86l43.18 24.93 43.18-24.93V24.93L43.18 0Z"
            />
            <Path
                stroke="#EE2D44"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M75.2 68.37 43.21 48.72"
            />
            <Path
                stroke="#38B473"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m11.45 67.81 31.76-19.09V13.05"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h86.36v99.72H0z" />
            </ClipPath>
        </Defs>
    </Svg>
)
export default SvgComponent
