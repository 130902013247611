import React from 'react';
import {View, List} from 'native-base';
import {ActivityIndicator, I18nManager as RNI18nManager, Platform, ScrollView,Linking} from 'react-native';
import {t} from '../../services/i18n';
import SettingsRow from '../../components/SettingsRow';
import styles from './styles';
import i18n from "../../services/i18n";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {changeLanguage} from "../../actions/user";
import * as Updates from 'expo-updates';
import {globalStyles} from "../../styles/Global";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import HeaderWrapper from "../../components/HeaderWrapper";
import Config from "../../config/Config";
import ConfigDefaults from "../../config/ConfigDefaults";
import Constants from 'expo-constants';


class SettingsScreen extends React.PureComponent {
    state = {
        isLoading: false,
        currentSettings: {
            account: {
                privateYourProfile: true,
                emailAddress: 'harrylockwood@gmail.com',
                password: 'abcdefghij12334590',
            },
            preferences: {
                timeFormat24Hours: false,
                language: this.props.user.language,
                reminderBeforeTraining: false,
                waitingListOrCancelledTraining: false,
                receiveClubMessages: false,
                friendRequest: false,
            },
        },
    };

    constructor(props) {
        super(props);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
    }


    async onChangeLanguage() {
        //TODO Update server then props!
        await this.props.changeLanguage(this.state.currentSettings.preferences.language);
        const isLocaleRTL = i18n.dir === 'RTL';
        RNI18nManager.forceRTL(isLocaleRTL);
        RNI18nManager.allowRTL(isLocaleRTL);
        // RN won't set the layout direction if we
        // don't restart the app's JavaScript.
        Updates.reloadAsync();

        // Updates.reloadFromCache();

    }

    componentDidMount = async () => {
        /*
        await this.props.fetchCurrentSettings();
        this.setState({
          currentSettings: this.props.settings
        }, () => this.setState({ isLoading: false }))
        */
    };

    changeValue = (section, property) => {
        return newValue => {
            this.setState(prevState => {
                const newSettings = Object.assign({}, prevState.currentSettings);
                newSettings[section][property] = newValue;
                return {currentSettings: newSettings};
            });
        };
    };

    changeValueNew = (value, section, property) => {
        this.setState(prevState => {
            const newSettings = Object.assign({}, prevState.currentSettings);
            newSettings[section][property] = value;
            return {currentSettings: newSettings};
        });
    };

    render() {
        if (this.state.isLoading) {
            return (
                <View style={styles.flexContainerCentered}>
                    <ActivityIndicator/>
                </View>
            );
        }

        return (
            <View>

                <StatusBarWrap/>
                <HeaderWrapper
                    headerTitle={t('screens:More:settings:title', {})}
                    headerArrowColor={this.props.whiteLabelProperties.primaryColorText}
                    padding={globalStyles.appPaddingHorizontal}
                    noArrow={false}
                    isPop={true}
                />
                <ScrollView>
                    <List style={styles.settingsList}>
                        <SettingsRow grey icon="user" title={t('screens:More:settings:account:name')}/>
                        <SettingsRow
                            onPress={() => this.props.navigation.navigate('ChangePassword')}
                            icon='keyboard-arrow-right'
                            iconType="MaterialIcons"
                            title={t('screens:More:settings:account:properties:password')}
                            type="PASSWORD"
                            disabled
                            value='************'
                            rightIcon
                            onChange={this.changeValue('account', 'password')}
                        />
                        <SettingsRow grey iconType="MaterialCommunityIcons" icon="preferences"
                                     title={t('screens:More:settings:preferences:name',)}/>
                        <SettingsRow
                            title={t('screens:More:settings:preferences:properties:language')}
                            type="LIST"
                            value={this.state.currentSettings.preferences.language}
                            onChange={(value) => {
                                this.changeValueNew(value, 'preferences', 'language');
                                if (Platform.OS.toLowerCase() === 'android') {
                                    i18n.changeLanguage(value, this.onChangeLanguage);
                                }
                            }}
                            onDonePress={(value) => {
                                i18n.changeLanguage(this.state.currentSettings.preferences.language, this.onChangeLanguage);
                            }}
                            listValues={[
                                { label: t('common:languages:en',{}), value: 'en' },
                                { label: t('common:languages:he',{}), value: 'he' },
                            ]}
                            icon='keyboard-arrow-down'
                            iconType="MaterialIcons"
                            rightIcon
                        />
                        <SettingsRow grey iconType="MaterialCommunityIcons" icon="info"
                                     title={t('screens:More:settings:arboxSupport')}/>
                        <SettingsRow
                            type="BUTTON"
                            title={t('screens:More:settings:mailSupport')}
                            onPress={() => {
                                Linking.openURL('mailto:support@arboxapp.com')
                            }}
                            buttonTitle='Application Support'/>
                        <SettingsRow
                            type="BUTTON"
                            title={t('screens:More:settings:termsOfUse')}
                            onPress={() => Linking.openURL('https://arboxassets.s3.eu-central-1.amazonaws.com/Docs/Arbox+Privacy+Policy.pdf')}
                        />
                        <SettingsRow
                            title={`${t('common:version',{})} ${Constants.manifest.version} - ${Config.version || ConfigDefaults.version}`}
                        />
                    </List>
                </ScrollView>
            </View>
        );
    }
}



const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            changeLanguage,
        },
        dispatch
    );
};

const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(SettingsScreen);


