import React, {useState} from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import {bindActionCreators} from "redux";
import {Form} from 'native-base';
import {Field, reduxForm} from 'redux-form';
import {connect, useDispatch, useSelector} from 'react-redux'
import {isMobile, isWeb} from "../../../helpers/functions";
import {Colors} from '../../../styles/Colors';
import {t} from '../../../services/i18n';
import {authenticateUser} from "../../../actions/auth";
import {AppText} from "../../Stateless/AppText";
import {globalSiteStyles, globalStyles} from "../../../styles/Global";
import Spinner from 'react-native-loading-spinner-overlay';
import ModalButton from "../../ModalButton";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import TextInputField from "../../Web/Common/TextInputField";
import EStyleSheet from "react-native-extended-stylesheet";
import {fetchProfile, setActiveUsersBoxes} from "../../../actions/user";
import {fetchUserMembershipsByBox} from "../../../actions/memberships";
import {toggleScheduleSiteReFetch} from "../../../actions/minisite";

let SimpleForm = (props) => {

    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {handleSubmit} = props;
    const whiteLabelProperties = useSelector(state => state.whiteLabelProperties)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const dispatch = useDispatch()
    const mobile = isMobile();

    const submit = async () => {
        try {
            setError(null)
            setSubmitting(true)
            const res = await props.authenticateUser(email, password);
            if (res.error) {
                setError(res.error)
                setSubmitting(false)
                setServerError(true)
            } else {
                const user = await dispatch(fetchProfile())
                const users_boxes = user.data.users_boxes.find((item => item.box_fk === box.id));
                await dispatch(setActiveUsersBoxes(users_boxes))
                dispatch(fetchUserMembershipsByBox(box.id, true))
                dispatch(toggleScheduleSiteReFetch())
                setSubmitting(false)
                handleSubmit()
            }
        } catch (error) {
            setError(error)
            setSubmitting(false)
            setServerError(true)
        }

    };

    return (
        <Form style={{flex: 1}}>
            <Spinner visible={submitting}/>
            <View>
                <AppText style={[globalStyles.textRed, styles.errorWeb]}>
                    {serverError ? error ? error.messageToUser !== null ? t(`common:serverErrors:${error.messageToUser}`, {}) : error.message : '' : ''}
                </AppText>
                <TextInputField isPassword={false} nameLabel={t('screens:Login:email', {})} keyboardType={'email'} placeHolder={t('screens:Login:email', {})} value={email} onChange={setEmail}/>
                <TextInputField isPassword={true} nameLabel={t('screens:Login:password', {})} placeHolder={t('screens:Login:password', {})} value={password} onChange={setPassword}/>
                <ModalButton
                    text={t('screens:Login:login', {})}
                    textColor={Colors.lightBlack}
                    colors={Colors.white}
                    type='primary'
                    onPress={submit}
                    overrideStyle={[globalSiteStyles(mobile).siteBlackBtn, styles.loginWeb, isMobile() ? styles.mobileLoginWeb: null]}
                    textStyles={{
                        'fontFamily': 'heebo',
                        'fontSize': isMobile() ? '1rem': '0.95rem',
                    }}
                />
            </View>
        </Form>
    )
}


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            loginWeb: {
                width: '100%',
                marginTop: '0.5em',
            },
            errorWeb: {
                fontSize: '0.8em'
            },
            mobileLoginWeb: {
                // marginBottom: scale(25),
            }
        }
    })
})

const mapStateToProps = state => ({
    error: state.auth.error
});

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            authenticateUser
        },
        dispatch
    );
};

SimpleForm = connect(
    mapStateToProps,
    mapActionsToProps
)(SimpleForm);


export default reduxForm({
    form: 'loginForm',
    fields: ['email', 'password']
})(SimpleForm);

