// @flow
import * as React from 'react';
import {Platform, StyleSheet, TextInput, TouchableOpacity, View} from "react-native";
import {Colors} from "../../../styles/Colors";
import {isMobile} from "../../../helpers/functions";
import EStyleSheet from "react-native-extended-stylesheet";
import Search from "../../../svg/Icons8Search"
import {isSiteHebrew} from "../../../helpers/ComponentFunctionality";
import {I18nManager} from "react-native";

const SearchInput = (props) => {

    const {placeHolder, value, onChange, overrideStyles} = props;
    // I18nManager.forceRTL(true)
    return (
        <View style={[styles.inputWrapper,overrideStyles, isMobile() ? styles.mobileInputWrapper : null]}>
            <TextInput
                style={[styles.input, {alignSelf: 'right'}]}
                placeholder={placeHolder}
                blurOnSubmit={true}
                placeholderTextColor={Colors.labelColor}
                onChangeText={onChange}
                value={value}
                textAlign={isSiteHebrew() ? 'right' : 'left'}
            />
            <Search fill={Colors.labelColor}/>
        </View>
    );
};

export default SearchInput;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            inputWrapper: {
                flexDirection: 'row',
                backgroundColor: Colors.iconBorder,
                paddingHorizontal: '0.5em',
                paddingVertical: '0.5em',
                borderRadius: 2,
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5em'
                // height: 35,
            },
            input: {
                width: '100%',
                // outlineStyle: "none",
                fontWeight: '300',
                fontSize: '0.8rem',
            },
        }
    })
})