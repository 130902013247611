import {scale, verticalScale,ScaledSheet} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import {globalStyles} from "../../styles/Global";
import {isTextRTLStyles} from '../../styles/Layout';

export default ScaledSheet.create({
    card: {
        backgroundColor: '#FFF',
        // width: '100%',
        paddingVertical: '25@vs',
        paddingHorizontal: '32@s',
        borderRadius: 5,
        shadowOffset: {width: 0, height: 5},
        shadowColor: 'rgba(0, 0, 0, 0.08)',
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 5,
        // marginTop: '19@s',
        // marginBottom: '15@s',
        minHeight: scale(100),
    },
    aboutToExpireCardTitle: {
        ...globalStyles.heeboRegular,
        color: Colors.black,
        fontSize: verticalScale(14),
        fontWeight: '400',
    },
    futureCardContent: {
        ...globalStyles.heeboRegular,
        color: Colors.black,
        fontSize: verticalScale(12),
        fontWeight: '300',
        height: scale(19),
        ...globalStyles.directionWrapSelf,
    },
    expiredCardContent: {
        ...globalStyles.heeboRegular,
        color: Colors.black,
        fontSize: verticalScale(12),
        fontWeight: '300',
        lineHeight: verticalScale(23),
    },
    expiredMembershipName: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.black,
        fontSize: verticalScale(12),
        fontWeight: '300',
    },
    expiredCardRenew: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.arboxBlue,
        height: scale(17),
        fontSize: verticalScale(14),
        fontWeight: '400',
    },
    aboutToExpireCardRenew: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.lightBlack,
        height: scale(17),
        fontSize: verticalScale(14),
        fontWeight: '300',
    },
    futureCardRenew: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.lightBlack,
        height: scale(17),
        fontSize: verticalScale(14),
        fontWeight: '300',
    },
    expiredCardSubTitle: {
        ...globalStyles.heeboRegular,
        color: Colors.grayText,
        fontSize: verticalScale(12),
        fontWeight: '300',
        marginTop: verticalScale(2),
    },
    expiredCardTitle: {
        ...globalStyles.heeboRegular,
        textAlign: "center",
        alignSelf: 'center',
        color: Colors.red,
        fontSize: verticalScale(14),
        fontWeight: '400',
    },
    cardTitle: {
        color: Colors.brandPrimary,
        fontSize: scale(12),
        // marginTop: scale(8),
        fontWeight: '600',
    },
    cardBigTitle: {
        fontSize: scale(24),
        fontWeight: '600',
        marginTop: scale(8),
    },
    cardContent: {
        fontSize: scale(12),
        marginTop: scale(8),
    },
    cardAlignCenter: {
        textAlign: 'center',
    },
    editButtonWrapper: {
        marginTop: verticalScale(-38),
        paddingBottom: verticalScale(5),
        alignItems: 'center',
    },
    editButton: {
        borderRadius: '20@s',
        height: '32@s',
        width: '32@s',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    userAvatarWrapper: {
        marginBottom: scale(8),
        flexDirection: 'row',

    },
    greetingText: {
        justifyContent: 'center',
        paddingLeft: scale(5),
        paddingRight: scale(5)
    },

    marginTopBetweenElements: {
        marginTop: scale(8)
    },
    buttonText: {
        fontSize: scale(10),
        ...isTextRTLStyles,
    },
    birthdayCardDecLeft: {
        width: scale(86),
        height: scale(78),
        position: 'absolute',
        top: scale(0),
        left: scale(0)
    },
    birthddayCardDecRight: {
        width: scale(86),
        height: scale(78),
        position: 'absolute',
        top: scale(0),
        right: scale(0),
        transform: [{scaleX: -1}]
    },
    messageText: {
        fontSize: scale(12),
        color: Colors.slateGrey,
    },
    flowerPowderLeft: {
        width: scale(42),
        height: scale(78),
        position: 'absolute',
        bottom: scale(40),
        left: scale(20)
    },
    flowerPowderRight: {
        width: scale(42),
        height: scale(78),
        position: 'absolute',
        bottom: scale(40),
        right: scale(20)
    },
    baloons: {
        width: scale(171),
        height: scale(86),
        alignSelf: 'center'
    },
    moreAvatarsText: {
        fontSize: '16@s',
        fontStyle: 'normal',
        letterSpacing: 0.51,
        textAlign: 'center',
        color: '#0099dc',
        ...isTextRTLStyles,
    },
    fitImageWithSize: {
        height: verticalScale(100),
        width: scale(100),
    },
    iconOverImage:{
        position: 'absolute',
        zIndex: 100,
        backgroundColor: Colors.white,
        borderRadius: 100,
        padding: scale(2),
        end: scale(-10),
        bottom: verticalScale(-3)
    }
});
