// @flow
import * as React from 'react';
import {Platform, StyleSheet, TextInput, TouchableOpacity, View} from "react-native";
import Visible from "../../../svg/Icons8Eye"
import {useState} from "react";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import {isMobile} from "../../../helpers/functions";
import EStyleSheet from "react-native-extended-stylesheet";
import {isSiteHebrew} from "../../../helpers/ComponentFunctionality";
import {t} from "../../../services/i18n";

const TextInputField = (props) => {

    const {input, nameLabel, isPassword, placeHolder, value, onChange, overrideStyles, error, meta, keyboardType} = props;
    const [showPassword, setShowPassword] = useState(false);
    const keyboard = keyboardType === 'email' ? "email-address" : keyboardType === 'numeric' ? 'numeric' : 'default'
    return (
        <View style={[isMobile() ? styles.mobileWrapper : styles.wrapper, overrideStyles]}>
            <AppText style={[styles.name, isMobile() ? styles.mobileName : null]}>{nameLabel}</AppText>
            <View style={[styles.inputWrapper, isMobile() ? styles.mobileInputWrapper : null, isSiteHebrew() && {flexDirection: 'row-reverse'},
                meta?.invalid && meta?.touched && {borderColor: 'red', borderWidth: 1}
            ]}>
                <TextInput
                    style={styles.input}
                    secureTextEntry={isPassword === true && !showPassword}
                    placeholder={placeHolder}
                    blurOnSubmit={true}
                    onChangeText={input ? input.onChange : onChange}
                    value={input ? input.value : value}
                    keyboardType={keyboard}
                />
                { isPassword === true &&
                <TouchableOpacity onPress={() => setShowPassword(prevShowPassword => !prevShowPassword)}>
                    <Visible />
                </TouchableOpacity>
                }
            </View>
            {error && <AppText style={[styles.name, styles.error, isMobile() ? styles.mobileName : null]}>{error}</AppText>}
        </View>
    );
};

export default TextInputField;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                marginBottom: '1em'
            },
            mobileWrapper: {
                marginBottom: verticalScale(15)
            },
            name: {
                fontSize: '0.8rem',
                color: Colors.labelColor,
            },
            inputWrapper: {
                flexDirection: 'row',
                backgroundColor: Colors.iconBorder,
                paddingHorizontal: '0.5em',
                paddingVertical: '0.5em',
                borderRadius: 2,
            },
            input: {
                width: '100%',
                outlineStyle: "none",
                fontWeight: '300'
            },
            mobileName: {
                fontSize: scale(12),
                marginBottom: verticalScale(5)
            },
            mobileInputWrapper: {
                paddingHorizontal: scale(8),
                paddingVertical: scale(10),
            },
            error: {
                color: Colors.red
            }
        }
    })
})