import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_PENDING
} from './constants';

const initialState = {
  forgotPasswordError: false,
  forgotPasswordErrorMessage: ''
};

// TODO: add real redux state changes
let forgotPasswordReducer;
export default forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_PENDING:
      return {
        ...state
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordError: true,
        forgotPasswordErrorMessage: action.payload.error
      };
    default:
      return state;
  }
};