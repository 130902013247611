import {
    PROFILE,
    UPDATE_PROFILE,
    SELECT_BOX_ID,
    UPDATE_IMAGE,
    REMOVE_FRIEND_FROM_USER,
    ADD_FRIEND_TO_USER,
    DELETE_PROFILE_IMAGE, SET_ACTIVE_USERS_BOXES, LOGOUT_USER,
} from '../constants';

const initialState = {
    isLoading: false,
    isUpdatingProfile: false,
    data: {},
    error: null,
    selectedBoxId: null,
    activeUsersBoxes: null,
};

const userReducer = (state = initialState, action) => {
    const actionType = PROFILE.toUpperCase(); // just MAKE SURE IT IS UPPER
    const updateProfile = UPDATE_PROFILE.toUpperCase();
    const selectBoxId = SELECT_BOX_ID.toUpperCase();
    const updateImage = UPDATE_IMAGE.toUpperCase();
    const deleteProfileImage = DELETE_PROFILE_IMAGE.toUpperCase();
    const setActiveUsersBoxes = SET_ACTIVE_USERS_BOXES.toUpperCase();

    switch (action.type) {
        case `${actionType}_PENDING`:
            return {
                ...state,
                isLoading: true,
            };
        case `${actionType}_SUCCESS`:{
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        }
        case `${actionType}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case `${updateProfile}_PENDING`:
            return {
                ...state,
                isUpdatingProfile: true,
            };
        case `${updateProfile}_SUCCESS`:{
            return {
                ...state,
                isUpdatingProfile: false,
                data: {...state.data,...action.payload},
                error: null
            };
        }
        case `${updateProfile}_FAILED`:
            return {
                ...state,
                isUpdatingProfile: false,
                error: action.payload
            };
        case `${updateImage}_SUCCESS`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    image: action.payload
                },
            };
        }
        case `${selectBoxId}_SUCCESS`: {
            return {
                ...state,
                selectedBoxId: action.payload,
            };
        }
        case `${REMOVE_FRIEND_FROM_USER}_SUCCESS`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    friend_connection: state.data.friend_connection
                        .filter(item => item.users_id !== action.payload.id && item.friend_users_id !== action.payload.id)
                },
            };
        }
        case `${ADD_FRIEND_TO_USER}_SUCCESS`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    friend_connection: [...state.data.friend_connection,action.payload]
                },
            };
        }

        case `${deleteProfileImage}_SUCCESS`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    image: null
                },
            };
        }
        case `${setActiveUsersBoxes}_SUCCESS`: {
            return {
                ...state,
                activeUsersBoxes: {...action.payload}
            };
        }
        case LOGOUT_USER: {
            return initialState;
        }
        default:
            return state;
    }
};

export default userReducer;