import React from 'react';
import {connect} from "react-redux";
import {Icon} from 'native-base'
import {ScaledSheet, scale, verticalScale} from "react-native-size-matters";
import {bindActionCreators} from "redux";
import {View, TextInput, TouchableWithoutFeedback, Keyboard, ScrollView,Platform} from 'react-native'
import {LinearWrapper} from "../../../components/Stateless/LinerWrapper";
import ModalButton from "../../../components/ModalButton";
import {Colors} from "../../../styles/Colors";
import {RoundIcon} from "../../../components/Stateless/RoundIcon";
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../../../components/Stateless/AppText";
import uuid from 'uuid';
import {t} from '../../../services/i18n';
import {LogbookWorkoutType} from "../../../components/Stateless/LogbookWorkoutType";
import NumericInput from 'react-native-numeric-input'
import {InputWrapWithBorders} from "../../../components/Stateless/InputWrapWithBroders";
import MinSecHandler from '../../../components/MinSecHandler'
import LogBookIsRx from "../../../components/LogBookIsRx";
import RNPickerSelect from 'react-native-picker-select';
import {apiAction} from "../../../helpers/HTTP";
import {patchWorkout} from "../../../actions/logbook"

const hasSectionValues = ['AMRAP', 'FOR_TIME', 'RFT'];
const timeInputs = ['FOR_TIME', 'RFT'];

class AddWorkoutScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.updateNoteValue = this.updateNoteValue.bind(this);
        this.icon = this.icon.bind(this);
        this.submit = this.submit.bind(this);

        this.state = {
            isLoading: true,
            form: null,
            sectionIndex: 0,
            indexInSection: 0,
        }
    }

    async componentDidMount() {
        const data = this.props.navigation.state.params.workout;
        let obj = {
            is_rx: 0,
            extra_reps: 0,
            weight: 0,
            min: 0,
            sec: 0,
            total_time:0,
            total_reps:0,
            your_note: null
        };
        let sections = [];
        try {
            data.forEach((workoutSections, sectionIndex) => {
                sections[sectionIndex] = [];
                workoutSections.forEach((singleSection, index) => {
                    let user_result = singleSection.user_result ? {...obj,workout_fk:singleSection.id,...singleSection.user_result} : {...obj,workout_fk:singleSection.id,scale_fk:singleSection.scale_fk};
                    if (index === 0) {
                        if (hasSectionValues.includes(singleSection.workout_type.name)) {
                            if (user_result.total_time && user_result.total_time !== 0) {
                                const min = Math.floor(user_result.total_time / 60);
                                user_result.sec = user_result.total_time - min * 60;
                                user_result.min = min;
                            }
                            sections[sectionIndex].push(
                                {
                                    ...obj,
                                    ...singleSection,
                                    user_result,
                                    type: 'sectionResult',
                                    key:uuid.v4(),
                                })
                        }
                    }
                    const weight = singleSection.scales && singleSection.scales.skills ? singleSection.scales.skills.weight : 0;
                    const manyScales = singleSection.scales_many && singleSection.scales_many.length > 1;

                    if(singleSection.workout_type.name === 'AMRAP' || weight !== 0 || manyScales){
                        sections[sectionIndex].push(
                            {
                                ...obj,
                                ...singleSection,
                                user_result,
                                type: 'regular',
                                key:uuid.v4(),
                                weight
                            });
                    }
                    if (data[sectionIndex].length - 1 === index) {
                        sections[sectionIndex].push({type: 'sectionNote',key:uuid.v4(),user_result:{your_note: user_result.your_note}})
                    }
                })
            });
        } catch (e) {
        }
        //HERE we need to resolve data so it will become
        this.setState({isLoading: false, form: sections})
    }


    async submit() {
        this.setState({isLoading: true});
        const data = this.props.navigation.state.params.workout;
        // console.log(data[0][0],'form:', this.state.form[0][0]);
        const boxes = data[0][0].boxes;
        //Make sure form has workout id -- DUM WORKAROUND
        let form = this.state.form;
        form[0][0].workout_id = data[0][0].workout_id;
        //Make sure form has workout id
        //TODO Reduce network transaction
        try {
            const response = await apiAction('logbook/addEditUserResults', 'post', {
                form: form,
                boxes_id: boxes.id
            });
            // THE RESPONSE IS WORKOUT
            if(response && response.data){
                if(this.props.navigation.state.params.onGoBack){
                    this.setState({isLoading: false},() => {
                        this.props.navigation.state.params.onGoBack();
                        this.props.navigation.goBack();
                    });
                }else{
                    this.setState({isLoading: false},() => this.props.navigation.navigate("Workout", {
                        workout: response.data
                    }))
                }

            }else{
                this.setState({isLoading: false});
            }
        } catch (e) {
            this.setState({isLoading: false});
        }
    }


    handleNext() {
        const formLength = this.state.form.length;
        if (this.state.form[this.state.sectionIndex].length - 1 !== this.state.indexInSection) {
            this.setState({indexInSection: this.state.indexInSection + 1});
            return false
        }
        if (this.state.sectionIndex + 1 === formLength) {
            this.submit();
            return false;
        } else {
            this.setState({sectionIndex: this.state.sectionIndex + 1, indexInSection: 0});
            return false;
        }

    }

    handlePrev() {
        if (this.state.sectionIndex === 0 && this.state.indexInSection === 0) {
            this.props.navigation.goBack();
        } else if (this.state.indexInSection !== 0) {
            this.setState({indexInSection: this.state.indexInSection - 1})
        } else {
            const lastIndex = this.state.form[this.state.sectionIndex - 1].length - 1;
            this.setState({sectionIndex: this.state.sectionIndex - 1, indexInSection: lastIndex})
        }
    }

    buttons() {
        const isFirst = this.state.indexInSection === 0 && this.state.sectionIndex === 0;
        const formLength = this.state.form.length - 1;
        const isLastSection = this.state.sectionIndex === formLength ;
        let isLast = false;
        if(isLastSection){
            const lastSectionLength = this.state.form[this.state.sectionIndex].length - 1;
            isLast = lastSectionLength === this.state.indexInSection;
        }
        return (
            <View style={[styles.buttonContainer]}>
                <ModalButton
                    onPress={() => this.handlePrev()}
                    type='secondary'
                    colors={this.props.whiteLabelProperties.logbookColor}
                    text={isFirst ? t('common:later', {}) :  t('common:prev', {}) }
                    overrideStyle={{width: scale(150)}}
                    textColor={Colors.white}
                />
                <ModalButton
                    onPress={() => this.handleNext()}
                    type='primary'
                    colors={Colors.white}
                    text={isLast ? t("common:submit", {}) : t("common:next", {})}
                    overrideStyle={{width: scale(150)}}
                    textColor={this.props.whiteLabelProperties.logbookColor}
                />
            </View>
        )
    }

    form() {
        const data = this.props.navigation.state.params.workout;
        const sectionName = data[this.state.sectionIndex][0].box_sections.name;
        return (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={[styles.container]}>
                    <View style={[globalStyles.flexDirectionColumn, {
                        alignItems: 'center',
                        minHeight: verticalScale(180)
                    }]}>
                        <RoundIcon name={'view-details'}/>
                        <AppText
                            style={[globalStyles.heeboMedium, globalStyles.title, globalStyles.marginTopBetweenTextElements, {color: Colors.white}]}>{sectionName}</AppText>
                        <LogbookWorkoutType textColor={Colors.white} section={data[this.state.sectionIndex][0]}/>
                        {this.subHeader()}
                    </View>
                    <View style={globalStyles.flex}>
                        <ScrollView>
                            <React.Fragment>
                            {this.formInputs()}
                            </React.Fragment>
                        </ScrollView>
                    </View>
                    {this.buttons()}
                </View>
            </TouchableWithoutFeedback>

        )
    }

    subHeader() {
        const active = this.state.form[this.state.sectionIndex][this.state.indexInSection];
        switch (active.type) {
            case 'regular': {
                const value = active.scales ? active.scales.name : null;
                const skill_reps = active.skill_reps ? active.skill_reps : null;
                const rounds = active.rounds ? active.rounds : null;
                return (
                    <View style={[globalStyles.flexDirectionRow, {marginVertical: verticalScale(15)}]}>
                        {skill_reps || rounds ? <AppText
                            style={[globalStyles.heeboRegular, globalStyles.textWhite, globalStyles.title]}>{skill_reps} {rounds ? skill_reps ? '* ' + rounds + ' ' : rounds + ' * ' : null}</AppText> : null}
                        {value ? <AppText
                            style={[globalStyles.heeboRegular, globalStyles.textWhite, globalStyles.title]}>{value}</AppText> : null}
                    </View>
                )
            }
            case 'sectionNote':
            case 'sectionResult': {
                const text = active.type === 'sectionNote' ? t('screens:Logbook:myNote',{}):  t('screens:Logbook:results',{});
                return (
                    <View style={[globalStyles.flexDirectionRow, {marginVertical: verticalScale(15)}]}>
                        <AppText
                            style={[globalStyles.heeboRegular, globalStyles.textWhite, globalStyles.title]}>{text}</AppText>
                    </View>
                )
            }
            default:
                break;
        }
    }

    formInputs() {
        const active = this.state.form[this.state.sectionIndex][this.state.indexInSection];
        const name = active.workout_type ? active.workout_type.name : null;
        let time,round,selectScale,isRx,yourNote;
        switch (active.type) {
            case 'sectionResult': {
                if (name === 'AMRAP') {
                    round = (
                        this.rounds(active, 'total_rounds',t('screens:Logbook:totalRounds', {}))
                    );
                } else {
                    time = (
                        <InputWrapWithBorders>
                            <AppText
                                style={[globalStyles.heeboMedium, globalStyles.textWhite, globalStyles.marginBottomVS10]}>{t('screens:Logbook:totalTime', {})}</AppText>
                            <MinSecHandler active={active.user_result} updateValue={this.updateValue}/>
                        </InputWrapWithBorders>
                    );
                }
                isRx = (
                    <InputWrapWithBorders>
                        <LogBookIsRx active={active.user_result} updateValue={this.updateValue}/>
                    </InputWrapWithBorders>
                )
            }
                break;
            case 'sectionNote': {
                yourNote = (
                    <View
                        style={[globalStyles.flexDirectionRow, globalStyles.centerItems, globalStyles.marginTopBetweenElements]}>
                        <TextInput
                            multiline={true}
                            onChangeText={(text) => {
                                this.updateNoteValue(text)
                            }}
                            style={[globalStyles.heeboRegular, globalStyles.directionText, styles.textInput]}
                            value={active.user_result.your_note}
                        />
                    </View>
                )
            }
                break;
            case 'regular': {
                if (name === 'AMRAP') {
                    round = (
                        this.rounds(active, 'extra_reps',t('screens:Logbook:extraReps', {}) )
                    );
                }
                else if (active.weight !== 0) {
                    round = (
                        this.rounds(active, 'skill_weight',t('screens:Logbook:skillWeight', {}))
                    );
                }
                if(active.scales_many && active.scales_many.length > 1){
                    selectScale =  (
                        <InputWrapWithBorders>
                            <AppText
                                style={[globalStyles.heeboMedium, globalStyles.textWhite, globalStyles.marginBottomVS10]}>{t('screens:Logbook:selectScale',{}) }</AppText>
                            <View>
                        <RNPickerSelect
                            useNativeAndroidPickerStyle={false}
                            key={active.key}
                            onValueChange={(value) => this.updateValue('scale_fk',value)}
                            items={active.scales_many}
                            itemKey={active.user_result.scale_fk}
                            placeholder={{}}
                            style={pickerStyle}
                            Icon={() => this.icon()}
                        />
                            </View>
                        </InputWrapWithBorders>
                    );
                }
            }
                break;
            default:
                break;

        }
        return [selectScale,time, round, isRx, yourNote]
    }

    icon(){
        return (
            <Icon name="caretdown" type="AntDesign"
                  style={{color: Colors.black, fontSize: scale(16),marginTop:verticalScale(9),marginHorizontal: scale(5)}}/>
        )
    }

    rounds(active, key,text) {
        return (
            <InputWrapWithBorders>
                <AppText style={[globalStyles.heeboMedium, globalStyles.textWhite, globalStyles.marginBottomVS10]}>
                    {text}
                </AppText>
                <NumericInput
                    key={active.key}
                    value={active.user_result[key]}
                    onChange={value => this.updateValue(key, value)}
                    onLimitReached={(isMax, msg) => console.log(isMax, msg)}
                    totalWidth={scale(205)}
                    totalHeight={50}
                    iconSize={25}
                    step={1}
                    minValue={0}
                    valueType='real'
                    textColor={this.props.whiteLabelProperties.logbookColor}
                    iconStyle={{color: this.props.whiteLabelProperties.logbookColor}}
                    containerStyle={{backgroundColor: Colors.white}}
                    rightButtonBackgroundColor={Colors.white}
                    leftButtonBackgroundColor={Colors.white}/>
            </InputWrapWithBorders>
        )
    }

    updateValue(placement, value) {
        let obj = this.state.form[this.state.sectionIndex][this.state.indexInSection].user_result;
        obj[placement] = value;
        this.setState((prevState) => {
            let temp = {
                ...prevState,
                form: [...prevState.form]
            };
            temp.form[this.state.sectionIndex][this.state.indexInSection].user_result = {...obj};
            return temp
        })

    }

    updateNoteValue(value) {
        //Stupid SHIT!
        this.setState((prevState) => ({
                ...prevState,
                form: [...prevState.form],
                [this.state.sectionIndex] : [...prevState.form][this.state.sectionIndex].map(item => item.user_result.your_note = value)
        }))
    }

    render() {
        const {isLoading, form} = this.state;
        return (
            <LinearWrapper
                backgroundStart={this.props.whiteLabelProperties.logbookColor}
                backgroundEnd={this.props.whiteLabelProperties.logbookColor}
                barStyle="light-content"
                spinner={isLoading}>
                { form ? this.form() : null}
            </LinearWrapper>
        );
    }


}

const
    mapStateToProps = state => ({
        user: state.user.data,
        whiteLabelProperties: state.whiteLabelProperties,

    });

const
    mapActionsToProps = dispatch => {
        return bindActionCreators({
            patchWorkout
        }, dispatch);
    };

export default connect(
    mapStateToProps,
    mapActionsToProps
)(AddWorkoutScreen);

const
    styles = ScaledSheet.create({
        container: {
            marginTop: verticalScale(10),
            flex: 1,
        },
        headerText: {
            fontSize: scale(18),
            color: Colors.black
        },
        text: {
            fontSize: scale(14),
            color: Colors.black
        },
        buttonContainer: {
            width: scale(320),
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: verticalScale(30),
        },
        textInput: {
            borderBottomColor: Colors.white,
            borderBottomWidth: 1,
            width: scale(280),
            color: Colors.white
        }


    });



 const pickerStyle = ScaledSheet.create({
    inputIOS: {
        fontSize: scale(18),
        paddingVertical: verticalScale(10),
        paddingHorizontal: scale(5),
        width:scale(250),
        maxWidth:scale(320),
        ...globalStyles.appSmBorderRadius,
        backgroundColor:Colors.white,
        color: Colors.black,
        ...globalStyles.textAlignByRTL
    },
    inputAndroid: {
        fontSize: scale(18),
        paddingVertical: verticalScale(10),
        paddingHorizontal: scale(40),
        maxWidth:scale(320),
        ...globalStyles.appSmBorderRadius,
        backgroundColor:Colors.white,
        color: Colors.black,
        ...globalStyles.textAlignByRTL
    },
    iconContainer: {
        ...Platform.select({
            android: {
                top: 7,
            },
            ios: {
                top: 2
            }
        })
    }
});