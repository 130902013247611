import {MEMBERSHIP_SCHEDULES, MEMBERSHIPS, SCHEDULE_MEMBERSHIP_CLASSES} from '../constants';

const initialState = {
    isLoading: false,
    data: null,
    error: {},
    schedules: {
        isLoading: false,
        data: {},
        error: null,
    },
};

const membershipsReducer = (state = initialState, action) => {
    const actionType = MEMBERSHIPS.toUpperCase(); // just MAKE SURE IT IS UPPER
    const membershipSchedules = MEMBERSHIP_SCHEDULES.toUpperCase(); // just MAKE SURE IT IS UPPER
    switch (action.type) {
        case `${actionType}_PENDING`:
            return {
                ...state,
                data: null,
                isLoading: true,
            };
        case `${actionType}_SUCCESS`:{
            return {
                ...state,
                isLoading: false,
                data: action.payload.data
            };
        }
        case `${actionType}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case `${membershipSchedules}_PENDING`:
            return {
                ...state,
                schedules: {
                    data: {},
                    isLoading: true,
                    error: null,
                }
            };
        case `${membershipSchedules}_SUCCESS`:{
            return {
                ...state,
                schedules: {
                    isLoading: false,
                    data: action.payload,
                    error: null,
                }
            };
        }
        case `${membershipSchedules}_FAILED`:
            return {
                ...state,
                schedules: {
                    isLoading: false,
                    data: {},
                    error: action.payload
                }
            };
        case `${SCHEDULE_MEMBERSHIP_CLASSES}_SUCCESS`: {
            return {
                ...state,
                schedules: {
                    data: {
                        ...state.schedules.data,
                        future: state.schedules.data.future.filter(item => item.id !== action.payload.id)
                    }
                }
            }
        }
        default:
            return state;
    }
};

export default membershipsReducer;