import React from 'react';
import {View, TouchableOpacity, Image, Text, ActivityIndicator, Button} from 'react-native';
import {withNavigation} from 'react-navigation';
import {scale, verticalScale} from 'react-native-size-matters';
import Hyperlink from 'react-native-hyperlink'
import UserAvatar from 'react-native-user-avatar';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import ACTIONS from './constants'
import styles from './style';
import {modalTypes} from '../../modals/modalTypes';
import {showModal} from '../../modals/actions';
import {Colors} from '../../styles/Colors';
import {i18n, t} from '../../services/i18n';
import {globalStyles} from "../../styles/Global";
import {CardLoader} from "../../loaders";
import {FEED_SCHEDULE_ACTION, FRIEND_CONNECTIONS_ACTION} from "../../constants";
import ScheduleCard from '../../components/ScheduleCard';
import {apiAction} from "../../helpers/HTTP";
import Toast from "react-native-root-toast";
import {removeFeed} from "../../actions/home";
import {selectClubId} from "../../actions/user";
import {captureRef} from 'react-native-view-shot';
import {
    BIG_ICON_SIZE,
    getUserFromFriendConnection,
    HUGE_ICON_SIZE,
    LARGE_ICON_SIZE,
    MEDIUM_ICON_SIZE,
    SMALL_ICON_SIZE,
    boxHasShop,
    isBoxIL,
} from '../../helpers/functions'
import {friendRelationAction} from '../../actions/friend';
import {addFriend} from '../../actions/user';
import {fetchShopAbleItems} from '../../actions/shop';
import {getToastDefaultConfig} from '../../helpers/functions';
import {AppText} from "../Stateless/AppText";
import {ClassActionButton} from "../Stateless/ClassActionButton";
import NavigationService from "../../helpers/NavigationService";
import DrawerHandler from "../DrawerHandler";
import uuid from 'uuid';
import FriendsList from '../FriendsList';
import EpidemicForm from "../EpidemicForm";
import {FitImageWrap} from "../Stateless/FitImageWrap";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import SvgCorona from "../../svg/custom/Corona";
import SvgFillForm from "../../svg/custom/FillForm";
import SvgFinger from "../../svg/custom/Finger";
import SvgHello from "../../svg/custom/Hello";
import SvgCake from "../../svg/custom/Cake";
import {getThemeProperty, keys} from "../../styles/Theme";
import Config from "../../config/Config";
import SvgSwipeArbox from "../../svg/custom/SwipeArbox";
import SvgHelloArbox from "../../svg/custom/HelloArbox";
import SvgFillFormArbox from "../../svg/custom/FillFormArbox";
import SvgCoronaArbox from "../../svg/custom/CoronaArbox";
import SvgLastYearSign from "../../svg/custom/LastYearSign";
import LogBookCard from "../LogBookCard"
import date from '../../services/i18n/date';
import * as Linking from "expo-linking";
import SvgExpired from '../../svg/custom/Expired';
import {SeparatorWithText} from '../Stateless/SeparatorWithText';
import {GraySeparator} from '../Stateless/GraySeparator';
import {store} from '../../config/store';
import MembershipExpiredContent from '../MembershipExpiredContent';
import {openShareDialogAsync} from "../../helpers/share";
import ShareModal from "../../modals/modalFlows/ShareModal";
import YearlySummaryShare from "../YearlySummaryShare";

// birthday card images
const balloonsImgURL = require('../../images/balloons.png');
const birthdayDecoImgURL = require('../../images/birthdayDecoration.png');
const flowerPowderRightImgURL = require('../../images/flowerPowderRight.png');
const flowerPowderLeftImgURL = require('../../images/flowerPowderLeft.png');
const demo = require('../../images/demo.png');


class FeedCard extends React.PureComponent {


    constructor(props) {
        super(props);
        this.renderActionButton = this.renderActionButton.bind(this);
        this.renderUserAvatars = this.renderUserAvatars.bind(this);
        this.getTimeDiff = this.getTimeDiff.bind(this);
        this.headerIcon = this.headerIcon.bind(this);
        this.pictureView = this.pictureView.bind(this);
        this.cardContent = this.cardContent.bind(this);
        this.cardBigTitle = this.cardBigTitle.bind(this);
        this.cardTitle = this.cardTitle.bind(this);
        this.handleActionButton = this.handleActionButton.bind(this);
        this.linkToSchedule = this.linkToSchedule.bind(this);
        this.state = {
            submitting: false
        };
        this.iconType = 'MaterialCommunityIcons';
        this.iconName = 'trash-can';
        this.textColor = Colors.redPinkColor;
        this.RBSheet = React.createRef();

    }


    UNSAFE_componentWillMount() {

    }

    componentDidMount() {
        this.themeColor = getThemeProperty(keys.brandedGreen);

    }

    componentWillUnmount() {

    }

    async captureRefAndShare(refName) {
        const imageUri = await captureRef(this[refName]);
        await openShareDialogAsync('file://' + imageUri);
    }

    renderFeedCard(data) {
        switch (this.props.action) {
            // case 'demo':
            //     return this.demo();
            case 'loader':
                return this.renderLoader();
            case ACTIONS.ACTION_WELCOME_CARD: // DONE - NEEDS TO ADD TRANSLATIONS
                return this.renderWelcomeCard(data);
            case ACTIONS.ACTION_EPIDEMIC_STATEMENT: {
                this.iconType = 'MaterialCommunityIcons';
                this.iconName = 'edit-property';
                this.textColor = Colors.black;
                return this.renderEpidemicStatement(data);
            }

            case ACTIONS.ACTION_EXPLAIN_CARD: // DONE
                return this.renderInstructionCard(data);

            case ACTIONS.ACTION_BOX_MESSAGE: // 'club message': // DONE
                return this.renderClubMessageCard(data);

            case ACTIONS.ACTION_SCHEDULE_USER_STATUS: // 'status': DONE
                return this.renderStatusCard(data);

            case ACTIONS.ACTION_USER_YEARLY_SUMMARY:
                return this.renderYearlySummaryCard(data);

            case ACTIONS.ACTION_MY_BIRTHDAY: // 'user birthday': DONE
                return this.renderUserBirthdayCard();

            case ACTIONS.ACTION_FRIEND_REQUEST: // 'friend request': DONE1
            {
                this.iconType = 'AntDesign';
                this.iconName = 'check';
                this.textColor = Colors.black;
                return this.renderFriendRequestCard(data);
            }
            case ACTIONS.ACTION_FRIEND_REQUEST_ACCEPTED: // 'friend request': DONE1
            {
                return this.renderFriendRequestAccptedCard(data);
            }
            case ACTIONS.ACTION_ENDING_MEMBERSHIP: // 'repurchase':
                return this.renderMembershipEnding(data);
            case ACTIONS.ACTION_EXPIRED_MEMBERSHIP: // 'repurchase':
                return this.renderMembershipExpired(data);
            case ACTIONS.ACTION_FUTURE_MEMBERSHIP: // 'repurchase':
                return this.renderFutureMembership(data);
            case ACTIONS.ACTION_FRIEND_BIRTHDAY: // 'friend birthday':
            {
                this.iconType = 'MaterialCommunityIcons';
                this.iconName = 'balloon';
                this.textColor = Colors.black;
                return this.renderFriendBirthdayCard(data);
            }
            case ACTIONS.ACTION_MY_BALLOONS: // 'my balloon':
                return this.renderMyBalloonCard(data);
            case ACTIONS.ACTION_SCHEDULE_UPCOMING: // DONE1
                return this.renderUpcomingSchedules(data);
            case ACTIONS.ACTION_SCHEDULE_CANCELLED: // DONE
                return this.renderCancelSchedules(data);
            case ACTIONS.ACTION_MEDICAL_CERT_CARD:
                this.iconType = 'MaterialCommunityIcons';
                this.iconName = 'edit-property';
                this.textColor = Colors.black;
                return this.renderMedicalWarning(data);
            case ACTIONS.ACTION_SCHEDULE_INVITATION:
                return this.renderScheduleInvitation(data);
            case ACTIONS.ACTION_SCHEDULE_ENTERED_FROM_STANDBY:
                return this.renderEnteredFromStandby(data);
            case ACTIONS.ACTION_THANKS_FOR_BALLOONS:
                return this.renderThanksForBalloons(data);
            case ACTIONS.ACTION_FRIEND_SENT_BALLOON:
                return this.renderFriendSentBalloons(data);
            case ACTIONS.ACTION_TODAY_WORKOUT: {
                this.iconName = 'prize';
                this.textColor = Colors.black;
                return this.renderTodayWorkout(data);
            }
            default:
                return this.renderUnknown(data);
        }
    }

    demo() {
        return (
            <View style={[{minHeight: scale(100)}]}>
                <View style={globalStyles.card}>
                    {/*<AppText style={[globalStyles.heeboMedium,{fontSize:scale(15)}]}>CrossFit</AppText>*/}
                    <Image
                        resizeMode="contain"
                        style={{alignSelf: 'center', marginTop: -15, height: scale(90), width: scale(90)}}
                        source={demo}
                    />
                    <AppText style={[globalStyles.heeboMedium, {
                        marginTop: scale(10),
                        alignSelf: 'center',
                        fontSize: scale(18)
                    }]}>Ready for the CrossFit Open?</AppText>

                    <View style={{marginTop: scale(10)}}>
                        <AppText style={{...styles.cardContent}}>
                            Join your friends and hundreds of thousands of other CrossFit athletes to kick off the
                            world’s largest participatory sporting event.
                        </AppText>
                    </View>
                </View>
                <TouchableOpacity
                    onPress={() => Linking.openURL('https://games.crossfit.com/cf/login?flow=games&returnTo=%2Fregister%2Fopen%3F_ga%3D2.253445965.532574463.1622033253-1341354198.1622033253')}
                    style={{backgroundColor: Colors.brandedBlue, width: '100%', marginTop: verticalScale(20)}}>
                    <AppText style={[globalStyles.heeboMedium, {
                        paddingVertical: scale(10),
                        color: Colors.white,
                        alignSelf: 'center',
                        fontSize: scale(15)
                    }]}>Register Today</AppText>
                </TouchableOpacity>
            </View>
        )
    }

    getTimeDiff(created_at) {
        const duration = moment.duration(moment().diff(created_at));
        const time = {};
        if (Math.abs(duration.asMinutes()) < 59) {
            time.time = Math.abs(duration.minutes());
            time.unit = duration.minutes() === 1 ? t("timing:minute", {}) : t("timing:minutes", {});
        } else if (Math.abs(duration.asHours()) <= 24) {
            time.time = Math.abs(duration.hours());
            time.unit = duration.hours() === 1 ? t("timing:hour", {}) : t("timing:hours", {});
        } else {
            time.time = Math.abs(parseInt(duration.asDays().toString()));
            time.unit = duration.days() === 1 ? t("timing:day", {}) : t("timing:days", {});
        }
        return time;
    }

    //@DEPRECATED
    headerIcon(iconColor = Colors.white, iconBackground = Colors.clubMsgCardBtnColor, iconType = 'MaterialIcons', iconName = 'subject', iconSize = 20) {
        return null;
        if (this.props.index === 0)
            return null;
        if (this.props.screen === 'notifications')
            return null;
        return (
            <View style={styles.editButtonWrapper}>
                <View style={[styles.editButton, {backgroundColor: iconBackground}]}>
                    {/*<Icon type={iconType} name={iconName} style={{color: iconColor, fontSize: scale(iconSize)}}/>*/}
                </View>
            </View>
        );
    }

    cardTitle(value, style) {
        return (
            <AppText style={[styles.cardBigTitle]}>{value}</AppText>
        );
    }

    cardBigTitle(value, style) {
        return (
            <AppText style={[styles.cardBigTitle]}>{value}</AppText>
        );
    }

    pictureView(name, image, message, time, link, box = false) {
        return (
            <View style={styles.userAvatarWrapper}>
                <TouchableOpacity onPress={() => link()}>
                    {box ? this.boxImage(image) : (<UserAvatar
                        size={scale(40)}
                        name={name}
                        src={image}
                    />)}
                </TouchableOpacity>
                <View style={styles.greetingText}>
                    <AppText style={[styles.messageText, globalStyles.directionText]}>{message}</AppText>
                    <AppText style={[globalStyles.timeLine, globalStyles.directionText]}>{time}</AppText>
                </View>
            </View>
        );
    }

    boxImage(image) {
        return (
            <FitImageWrap
                src={image}
            />
        )
    }


    messageAndTimeLayout(message, time) {
        return (
            <View>
                <AppText style={[styles.messageText]}>{message}</AppText>
                <AppText style={[globalStyles.timeLine]}>{time}</AppText>
            </View>
        );
    }

    cardContent(content) {
        const {messages_center, message} = this.props.data;
        let value = this.props.screen === 'notifications' ? messages_center ? messages_center.message : message : content;
        return (
            <Hyperlink linkDefault={true} linkStyle={{color: Colors.paleblue}}>
                <AppText style={[styles.cardContent, globalStyles.directionText]}>
                    {value}
                </AppText>
            </Hyperlink>
        );
    }


    renderUnknown(data) {
        return (<View style={[styles.card]}>
            {this.cardBigTitle(data.action)}
        </View>);
    }

    renderLoader() {
        return (
            <View style={[styles.card]}>
                <CardLoader/>
            </View>
        )
    }


    handleSuccess(action) {
        this.setState({submitting: false});
        let message;
        switch (action) {
            case 'sendBalloons': {
                message = t('screens:Home:cards:balloons-card:toast', {});
                break;
            }
            case 'acceptFriendRequest': {
                message = t('screens:Home:cards:friend-request:toast', {});
                break;
            }
            default:
                break;
        }
        if (message) {
            const options = getToastDefaultConfig();
            Toast.show((message), {
                backgroundColor: Colors.brandSuccess,
                ...options
            });
            this.props.removeFeed(this.props.data.id);
        }

    }

    // render action button
    renderActionButton(text, action, abstractedData) {
        if (this.props.screen === 'notifications')
            return null;
        return (
            <ClassActionButton
                onPress={() => this.handleActionButton(action, abstractedData)}
                text={text}
                iconType={this.iconType}
                iconName={this.iconName}
                textColor={this.textColor}
            />
        );
    }

    async handleActionButton(action, abstractedData) {
        //Handle dispatch by action
        //NOTE abstractedData IS DATA THAT FEED ALREADY "OPENED"
        //TODO handle ERROR PROPERLY!
        const {data} = this.props;
        try {
            switch (action) {
                case 'epidemicForm': {
                    if (this.RBSheet)
                        this.RBSheet.open();
                    break;
                }
                case 'sendBalloons': {
                    this.setState({submitting: true});
                    const params = {
                        friend_users_id: abstractedData.id
                    };
                    const response = await apiAction('userToUser/balloons/send', 'post', params);
                    this.handleSuccess(action);
                    break;
                }
                //Upcoming Schedule Button
                case 'cancelScheduleUser': {
                    const schedule = {...data.schedule, feed_id: data.id};
                    this.props.showModal(modalTypes.MODAL_SIMPLE, 'BOOKED', schedule, schedule.booking_option, FEED_SCHEDULE_ACTION, 'feed');
                    break;
                }
                case 'acceptFriendRequest': {
                    this.setState({submitting: true});
                    const response = await this.props.friendRelationAction('approveFriend', abstractedData.id, 'approveFriend', this.props.user.id);
                    this.props.addFriend(response.data);
                    this.handleSuccess(action);
                    break;
                }
                case ACTIONS.ACTION_TODAY_WORKOUT: {
                    this.props.navigation.navigate('AddWorkoutScreen', {
                        workout: data,
                        onGoBack: () => this.props.refresh()
                    });
                    break;
                }
                default:
                    this.setState({submitting: false});
                    break;
            }
        } catch (error) {
            this.setState({submitting: false});
            // Handle errors!
        }
    }

    renderShareCardButton = (uniqueRef) => (
        <TouchableOpacity style={[{marginBottom: scale(10)}, globalStyles.avatarsRow, globalStyles.centerItems]}
                          onPress={() => this.captureRefAndShare(uniqueRef)}>
            <AppText style={[{color: Colors.brandedBlue}, globalStyles.marginByRTL]}>{t("common:share")}</AppText>
            <Icons8Generator name="forward-arrow" fill={Colors.brandedBlue} width={LARGE_ICON_SIZE}
                             height={LARGE_ICON_SIZE}/>
        </TouchableOpacity>
    )

    renderStatusCard = (data) => {
        const uniqueRef = uuid.v4();
        return (
                <View>
                    <View collapsable={false} ref={(view) => {this[uniqueRef] = view}} style={[globalStyles.card, globalStyles.flexDirectionColumn]}>
                        <View style={globalStyles.flexDirectionRowSpaceBetween}>
                            <AppText
                                style={[globalStyles.heeboMedium, {color: Colors.black}]}>{t("screens:Home:cards:status-card:title")}</AppText>
                            <Icons8Generator name={'status-heart'} fill={Colors.black} width={LARGE_ICON_SIZE}
                                             height={LARGE_ICON_SIZE}/>
                        </View>
                        <View style={[globalStyles.flexDirectionColumn]}>
                            {this.renderClassesStatus('status-clock', data.results.future, t('screens:Home:cards:status-card:feature-training'), Colors.green)}
                            {this.renderClassesStatus('status-calendar', data.results.past, t('screens:Home:cards:status-card:accomplished-training'), Colors.red)}
                            {this.renderClassesStatus('status-average', data.results.average, t('screens:Home:cards:status-card:average-training'), Colors.blue)}
                        </View>
                    </View>
                    {this.renderShareCardButton(uniqueRef)}
                </View>
        )
    };

    renderYearlySummaryCard = (data) => {
        const uniqueRef = uuid.v4();
        return (
            <View style={[globalStyles.card]}>
                <View style={[globalStyles.flexDirectionColumn, {justifyContent: 'center', alignItems: 'center'}]}>
                    <SvgLastYearSign style={{...styles.cardAlignCenter}}/>
                    <AppText style={[globalStyles.heeboBold, {position: 'absolute', fontSize: scale(24)}]}>
                        {data.year}
                    </AppText>
                </View>
                <View>
                    <AppText style={{
                        ...styles.cardContent, ...styles.cardAlignCenter,
                        paddingTop: 20, paddingBottom: 10, fontSize: scale(14)
                    }}>
                        {t("screens:Home:cards:yearly-summary-card:title", {year: data.year})}</AppText>
                </View>
                <GraySeparator height={1}/>
                <View style={[globalStyles.flexDirectionRowSpaceAround]}>
                    {this.renderUserYearlySummaryParameter('status-clock', data.workoutHours, t('screens:Home:cards:yearly-summary-card:time-training'))}
                    {this.renderUserYearlySummaryParameter('status-calendar', data.classes, t('screens:Home:cards:yearly-summary-card:registered-training'))}
                    {this.renderUserYearlySummaryParameter('handshake-heart', data.newFriends, t('screens:Home:cards:yearly-summary-card:new-friends'))}
                </View>
                <GraySeparator height={1}/>


                <View>
                    {this.renderPoweredByShareButton()}
                </View>
                <ShareModal
                    isVisible={this.state.isModalVisible}
                    sharedComponent={<YearlySummaryShare data={data}/>}
                    hideModal={this.hideModal}>
                </ShareModal>

            </View>

        )
    };

    hideModal = () => {
        this.setState({isModalVisible: false});
    };


    async captureRefAndShare(refName) {
        const imageUri = await captureRef(this[refName]);
        await openShareDialogAsync('file://' + imageUri);
    }

    renderCapture = async () => {
        setTimeout(async () => {
            const imageUri = await captureRef(this['screenCaptured']);
            await openShareDialogAsync('file://' + imageUri);
            this.setState(() => ({hidePriceOnShare: false}));
        }, 100);
    };

    renderPoweredByShareButton = () => (
        <TouchableOpacity style={[globalStyles.centerItems, globalStyles.flexDirectionRow,
            globalStyles.flexDirectionRowSpaceAround
            , {height: 20}]} onPress={() => {
            this.setState({isModalVisible: true})
        }}>
            <AppText style={[{color: Colors.brandedBlue},globalStyles.heeboRegular]}>{t("common:share")}</AppText>
            <Icons8Generator name="forward-arrow" fill={Colors.brandedBlue} width={LARGE_ICON_SIZE}
                             height={LARGE_ICON_SIZE}/>
        </TouchableOpacity>
    );

    renderEpidemicStatement() {
        return (
            <View style={{borderColor: Colors.brandedPink, borderWidth: 3, borderRadius: 5}}>
                <View style={[globalStyles.card, {minHeight: scale(100)}]}>
                    <AppText
                        style={[globalStyles.heeboMedium, {color: Colors.black}]}>{t('screens:Home:cards:epidemic-card:title', {})}
                    </AppText>
                    <AppText
                        style={[globalStyles.marginTopBetweenTextElements, globalStyles.heeboRegular]}>{t('screens:Home:cards:epidemic-card:content', {})}
                    </AppText>
                    <View style={{alignItems: 'center', marginTop: verticalScale(20)}}>
                        {Config.whiteLabel.toLowerCase() === 'arbox' ?
                            <SvgCoronaArbox width={scale(90)} height={scale(90)}/> :
                            <SvgCorona width={scale(90)} height={scale(90)}/>}
                    </View>
                </View>
                {this.renderActionButton(t('screens:Home:cards:epidemic-card:action-button', {}), 'epidemicForm', null)}
                <DrawerHandler title={t('screens:Home:cards:epidemic-card:drawer:header', {})}
                               ref={el => {
                                   this.RBSheet = el ? el.ref.current : null;
                               }}>
                    <EpidemicForm closeDrawer={() => this.RBSheet.close()}/>
                </DrawerHandler>
            </View>
        )
    }

    // <editor-fold desc="POLAND SHIT STAYS" >

    // render welcome card
    renderWelcomeCard = () => {
        return (
            <View style={[globalStyles.card, {alignItems: 'center', minHeight: scale(100)}]}>
                <AppText
                    style={[globalStyles.heeboMedium, {color: this.themeColor}]}>{t("screens:Home:cards:welcome-card:title")}</AppText>
                <View style={{paddingHorizontal: scale(30), marginTop: scale(10)}}>
                    <AppText
                        style={{...styles.cardContent, ...styles.cardAlignCenter}}>{t("screens:Home:cards:welcome-card:content")}</AppText>
                </View>
                <View style={{alignItems: 'center', marginTop: verticalScale(20)}}>
                    {Config.whiteLabel.toLowerCase() === 'arbox' ?
                        <SvgHelloArbox width={scale(110)} height={scale(110)}/> :
                        <SvgHello width={scale(110)} height={scale(110)}/>}
                </View>
            </View>
        )
    };

    // render instruction card
    renderInstructionCard = () => {
        return (
            <View style={[globalStyles.card, {alignItems: 'center', minHeight: scale(100)}]}>
                <AppText>{t("screens:Home:cards:instruction-card:title")}</AppText>
                <View style={{paddingHorizontal: scale(30), marginTop: scale(10)}}>
                    <AppText
                        style={[styles.cardContent, styles.cardAlignCenter]}>{t("screens:Home:cards:instruction-card:content")}</AppText>
                </View>
                <View style={{alignItems: 'center', marginTop: verticalScale(20)}}>
                    {Config.whiteLabel.toLowerCase() === 'arbox' ?
                        <SvgSwipeArbox width={scale(110)} height={scale(110)}/> :
                        <SvgFinger width={scale(110)} height={scale(110)}/>}
                </View>
            </View>
        )
    };

    renderClassesStatus = (iconName, num, status, color) => {
        return (
            <View style={[globalStyles.flexDirectionRow, globalStyles.marginTopBetweenElements]}>
                <View style={{alignItems: 'center', justifyContent: 'center'}}>
                    <Icons8Generator name={iconName} fill={color} width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE}/>
                </View>
                <View style={[globalStyles.paddingStartXXXLarge, globalStyles.flexDirectionColumn]}>
                    <AppText style={[{
                        fontSize: scale(24),
                        fontWeight: '500',
                        color: Colors.lightBlack
                    }]}>{num}</AppText>
                    <AppText style={[globalStyles.heeboMedium, {
                        fontSize: scale(14),
                        color: Colors.labelColor,
                    }]}>{status}
                    </AppText>

                    <AppText style={[{
                        fontSize: scale(12),
                        color: Colors.labelColor,
                    }]}>{iconName !== 'status-clock' ? t('screens:Home:cards:status-card:last-30-days', {}) : t('screens:Home:cards:status-card:next-30-days', {})}
                    </AppText>
                    <View
                        style={{
                            backgroundColor: color,
                            borderRadius: scale(10),
                            height: verticalScale(2),
                            width: scale(200),
                            marginTop: verticalScale(5),
                        }}
                    />
                </View>
            </View>
        )
    };


    renderUserYearlySummaryParameter = (iconName, value, parameter) => {
        return (

            <View style={[globalStyles.flexDirectionColumn,{width: '33%',alignItems: 'center'}]}>
                <View >

                    <Icons8Generator name={iconName} fill={Colors.lightBlack} width={LARGE_ICON_SIZE}
                                     height={LARGE_ICON_SIZE}/>
                </View>

                <AppText style={[globalStyles.heeboBold,
                    styles.cardContent, styles.cardAlignCenter, {
                        fontSize: scale(24),
                        color: Colors.lightBlack,
                        height: verticalScale(30),
                    }]}>{value}</AppText>

                <AppText style={[globalStyles.heeboRegular, {
                    fontSize: scale(12),
                    color: Colors.lightBlack,
                }]}>{parameter}
                </AppText>
            </View>
        )
    }

    renderUserBirthdayCard = () => {
        const uniqueRef = uuid.v4();
        return (
            <View>
                <View collapsable={false} ref={(view) => {this[uniqueRef] = view}} style={[globalStyles.card]}>
                    <View style={{alignItems: 'center'}}>
                        <View style={{alignItems: 'center', marginTop: verticalScale(20)}}>
                            <SvgCake width={scale(90)} height={scale(90)}/>
                        </View>
                        <AppText style={{
                            marginTop: scale(10),
                            fontSize: scale(14),
                            fontWeight: '600',
                            color: Colors.black
                        }}
                        >{t("screens:Home:cards:birthday-card:title", {})}
                        </AppText>
                        <AppText style={{
                            marginBottom: scale(20),
                            fontSize: scale(18),
                            fontWeight: '600',
                            textAlign: 'center',
                            color: Colors.red
                        }}
                        >{t("screens:Home:cards:birthday-card:content", {})}
                        </AppText>
                        <UserAvatar
                            size={scale(60)}
                            name={this.props.user.full_name}
                            src={this.props.user.image ? this.props.user.image : null}
                        />
                        <AppText style={{
                            marginTop: scale(5),
                            fontSize: scale(11),
                            color: Colors.birthdayCardTitleColor
                        }}>{this.props.user.full_name}</AppText>
                    </View>
                    <Image style={styles.birthdayCardDecLeft} source={birthdayDecoImgURL} resizeMode="contain"/>
                    <Image style={styles.birthddayCardDecRight} source={birthdayDecoImgURL} resizeMode="contain"/>
                    <Image style={styles.flowerPowderLeft} source={flowerPowderLeftImgURL} resizeMode="contain"/>
                    <Image style={styles.flowerPowderRight} source={flowerPowderRightImgURL} resizeMode="contain"/>
                </View>
                {this.renderShareCardButton(uniqueRef)}
            </View>
        )
    };

    renderFriendBirthdayCard = (data) => {
        const user = getUserFromFriendConnection(data);
        if (user === null)
            return null;
        const link = () => NavigationService.push("FriendProfile", {id: user.id});
        return (
            <View>
                <View style={[globalStyles.card]}>
                    {this.headerIcon(Colors.white, Colors.friendBirthdayCardBtnColor, 'MaterialCommunityIcons', 'gift')}
                    {this.pictureView(user.full_name, user.image, t("screens:Home:cards:friend-birthday-card:title", {userName: user.full_name}), null, link)}
                    <Image
                        style={[styles.baloons, {marginTop: scale(10), marginBottom: scale(10)}]}
                        source={balloonsImgURL}
                        resizeMode="contain"
                    />
                    <View style={{marginTop: scale(15)}}>
                        <AppText
                            style={styles.cardContent}>{t("screens:Home:cards:friend-birthday-card:message")}</AppText>
                    </View>
                </View>
                {this.renderActionButton(t('screens:Home:cards:friend-birthday-card:action-button', {}), 'sendBalloons', user)}
            </View>
        )
    };

    renderMyBalloonCard = (data) => {
        const {friends} = data;
        const user = friends[0].user;
        const users = friends.map(item => item.user);
        const link = () => NavigationService.push("FriendProfile", {id: user.id});
        return (
            <View style={[globalStyles.card, {marginTop: scale(30), paddingBottom: scale(30)}]}>
                {this.headerIcon(Colors.white, Colors.red, "MaterialCommunityIcons", "gift")}
                {this.pictureView(user.full_name, user.image, t('screens:Home:cards:balloons-card:title', {
                    count: friends.length,
                    userName: user.full_name,
                    numOfUsers: friends.length - 1
                }), t("timing:time", this.getTimeDiff(friends[0].created_at)), link)}
                <Image
                    style={[styles.baloons, {marginTop: scale(10), marginBottom: scale(10)}]}
                    source={balloonsImgURL}
                    resizeMode="contain"
                />
                <View style={{marginTop: scale(15)}}>
                    <Text
                        style={[styles.cardContent, {textAlign: 'center'}]}
                    >{t("screens:Home:cards:balloons-card:message")}
                    </Text>
                </View>
                <View>
                    <TouchableOpacity
                        style={{marginTop: scale(16), paddingHorizontal: scale(8), flexDirection: 'row'}}
                        onPress={() => this.RBSheet.open()}>
                        {this.renderUserAvatars(users)}
                    </TouchableOpacity>
                </View>
                <DrawerHandler title={t('common:drawer:sayThanks', {})}
                               ref={el => {
                                   this.RBSheet = el ? el.ref.current : null;
                               }}>
                    <FriendsList
                        actionDisabled={false}
                        list={friends}
                        isMyFriends={false}
                        displaySmall={true}
                        directObject={false}
                        textButtons={true}
                        action={'thanksForBalloons'}
                        reducerName={FRIEND_CONNECTIONS_ACTION}
                        drawer={true}
                        closeDrawer={() => this.RBSheet.close()}
                    />
                </DrawerHandler>
            </View>
        )
    }


    renderUserAvatars(users) {
        if (users && users.length > 0) {
            return (
                users.map(user => {
                    return (
                        <View
                            style={[styles.moreAvatars, i18n.isRTL ? {marginLeft: scale(8)} : {marginRight: scale(8)}]}
                            key={uuid.v4()}>
                            <UserAvatar
                                size={scale(35)}
                                name={`${user.first_name} ${user.last_name}`}
                                src={user.image ? user.image : null}
                            />
                        </View>
                    );
                })
            );
        }
        return null;

    }

    // </editor-fold>
    renderFriendRequestAccptedCard = (data) => {
        const user = getUserFromFriendConnection(data);
        if (user === null)
            return null;
        return (
            <View>
                <View style={[globalStyles.card]}>
                    {this.messageAndTimeLayout(t('screens:Home:cards:friend-request-accepted:title', {userName: user.full_name}), t("timing:time", this.getTimeDiff(data.created_at)))}
                    <AppText
                        style={[globalStyles.marginTopBetweenTextElements, globalStyles.heeboRegular]}>{t('screens:Home:cards:friend-request-accepted:message', {})}</AppText>
                    <View style={[globalStyles.flexDirectionRowSpaceAround, globalStyles.marginTopBetweenElements]}>
                        {this.bigUserAvatar(this.props.user, true)}
                        <Icons8Generator name='link' fill={Colors.black} width={LARGE_ICON_SIZE}
                                         height={LARGE_ICON_SIZE}/>
                        {this.bigUserAvatar(user, false)}
                    </View>

                </View>
                <ClassActionButton
                    onPress={() => NavigationService.push("FriendProfile", {id: user.id})}
                    iconType={'MaterialCommunityIcons'}
                    iconName={'view-profile'}
                    textColor={Colors.black}
                    text={t('screens:Home:cards:friend-request-accepted:accept-button', {})}
                />
            </View>
        )


    };

    bigUserAvatar(user, me) {
        return (
            <TouchableOpacity
                style={[globalStyles.flexDirectionColumn, globalStyles.centerItems]}
                onPress={() => NavigationService.push("FriendProfile", {id: user.id})}
                key={uuid.v4()}>
                <UserAvatar
                    size={scale(80)}
                    name={`${user.first_name} ${user.last_name}`}
                    src={user.image ? user.image : null}
                />
                <AppText style={globalStyles.marginTopSm}>{me ? t('common:me', {}) : user.full_name_shorten}</AppText>
            </TouchableOpacity>
        )
    }

    // <editor-fold desc="NEW OR REFRACTOR " >
    renderFriendRequestCard = (data) => {
        const user = getUserFromFriendConnection(data);
        if (user === null)
            return null;
        const link = () => NavigationService.push("FriendProfile", {id: user.id});

        return (
            <View>
                <View style={[globalStyles.card, globalStyles.centerItems]}>
                    <View>
                        <View style={styles.iconOverImage}>
                            <Icons8Generator name={'friends'} size={MEDIUM_ICON_SIZE}/>
                        </View>
                        <UserAvatar
                            size={scale(50)}
                            name={user.full_name ? user.full_name : user.first_name}
                            src={user.image ? user.image : null}
                        />
                    </View>
                    <View style={[globalStyles.flexDirectionRow, globalStyles.marginTopBetweenElements]}>
                        <AppText style={[globalStyles.subTitle, {textAlign: 'center'}]}>
                            <AppText
                                style={[globalStyles.heeboMedium]}>{user.full_name ? user.full_name : user.first_name} </AppText>
                            {t('screens:Home:cards:friend-request:sub-title', {})}
                        </AppText>

                    </View>
                    <AppText
                        style={[globalStyles.marginTopMedium, {textAlign: 'center'}]}>{t('screens:Home:cards:friend-request:message', {userName: user.full_name})}</AppText>
                </View>

                {this.props.screen !== 'notifications' ? (<View
                    style={[globalStyles.flexDirectionRow, globalStyles.cardActionButtonContainer, {
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }]}>
                    <ClassActionButton
                        onPress={() => this.handleActionButton('acceptFriendRequest', user)}
                        text={t("screens:Home:cards:friend-request:accept-button", {})}
                        iconType={this.iconType}
                        iconName={this.iconName}
                        textColor={this.textColor}
                        style={{alignItems: 'center'}}
                        noWrap={true}
                    />
                    <ClassActionButton
                        onPress={() => this.props.removeFeed(this.props.data.id)}
                        text={t("screens:Home:cards:friend-request:reject", {})}
                        iconType={this.iconType}
                        iconName='close'
                        textColor={Colors.redPinkColor}
                        noWrap={true}
                    />
                </View>) : null}
            </View>
        )
    };


    renderMedicalWarning = (data) => {
        const {box, locations_box} = data;
        let digital_form, text, membership_type;
        if (data.sub_action === 'membership_types') {
            digital_form = data.digital_form;
            membership_type = t("screens:Home:cards:medical-card:mt-name", {
                mt_name: data.membership_types.name,
                digital_form_name: digital_form.name
            });
        } else {
            digital_form = data.sub_action === 'medical' ? data.digital_form_medical : data.sub_action === 'waiver' ? data.digital_form_waiver : null;
            //Meaning both
            if (digital_form === null) {
                digital_form = Object.prototype.hasOwnProperty.call(data, 'digital_form_medical') ? data.digital_form_medical : data.digital_form_waiver;
            }
        }
        text = t("screens:Home:cards:medical-card:name", {name: digital_form.name});
        const image = box ? box.cloudinary_image ? box.cloudinary_image : locations_box ? locations_box.logo : null : null;

        return (
            <View>
                <View style={[globalStyles.card]}>
                    <View style={[globalStyles.flexDirectionRow, globalStyles.centerStartItems]}>
                        <FitImageWrap src={image}/>
                        <AppText
                            style={[globalStyles.heeboRegular, globalStyles.paddingStart, {color: Colors.black}]}>{box.name}</AppText>
                    </View>
                    {membership_type ? <AppText style={[globalStyles.heeboRegular]}>{membership_type}</AppText> :
                        <AppText style={[globalStyles.heeboRegular]}>{text}</AppText>}
                    <View style={{alignItems: 'center', marginTop: verticalScale(20)}}>
                        {Config.whiteLabel.toLowerCase() === 'arbox' ?
                            <SvgFillFormArbox width={scale(90)} height={scale(90)}/> :
                            <SvgFillForm width={scale(90)} height={scale(90)}/>}
                    </View>
                </View>
                <ClassActionButton
                    onPress={() => {
                        this.props.navigation.navigate("DigitalForm", {
                            id: digital_form.id,
                            box,
                            image,
                            onGoBack: () => this.props.refresh()
                        });
                    }}
                    text={t("screens:Home:cards:medical-card:action-button", {})}
                    iconType={this.iconType}
                    iconName={this.iconName}
                    textColor={this.textColor}
                    style={{alignItems: 'center'}}
                    noWrap={false}
                />
            </View>

        )
    };

    renderCancelSchedules = (data) => {
        const {schedule} = data;
        return (
            this.renderReminderCard(t("screens:Home:cards:cancel-schedules:title"), globalStyles.warningText, schedule.box_categories.name, t("screens:Home:cards:cancel-schedules:content", {
                scheduleName: schedule.box_categories.name,
                boxName: schedule.box.name,
                date: date.format(schedule.date, 'LL'),
                time: schedule.time
            }))
        )
    };

    renderReminderCard = (title, titleStyle, subTitle, content) => {
        return (
            <View style={[globalStyles.card]}>
                <AppText style={[styles.cardTitle, titleStyle]}>{title}</AppText>
                <AppText style={[styles.cardBigTitle]}>{subTitle}</AppText>
                {this.cardContent(content)}
            </View>
        );
    };

    renderExpiredCard = (box, membershipType, hasShop, isIL) => {
        const iconSize = 25;

        return (
            <View style={[globalStyles.card]}>
                <View>
                    <SvgExpired style={{alignSelf: 'center'}} width={scale(40)} height={scale(40)} fill={Colors.red}/>
                    <AppText
                        style={[styles.expiredCardTitle, globalStyles.marginTopBetweenElements]}>{t("screens:Home:cards:expired-card:title")}</AppText>
                    <AppText style={[styles.expiredCardSubTitle, {alignSelf: 'center'}]}>{box.name}</AppText>
                    <MembershipExpiredContent
                        phone={box.phone}
                        membershipType={membershipType}
                        iconSize={iconSize}
                        boxHasShop={hasShop}
                        isIL={isIL}
                    />
                </View>
            </View>
        );
    };

    renderAboutToExpireCard(title, boxName, message, icon, linkToProduct) {
        return (
            <View>
                <View style={[globalStyles.card, {padding: verticalScale(15)}]}>
                    <View style={[globalStyles.flexDirectionRowSpaceBetween, {alignItems: 'center'}]}>
                        <AppText style={[styles.aboutToExpireCardTitle]}>{title}</AppText>
                        {icon}
                    </View>
                    <View style={[globalStyles.flexDirectionColumn]}>
                        <AppText style={[styles.expiredCardSubTitle]}>{boxName}</AppText>
                        {message}
                    </View>
                    {/* <GraySeparator style={[globalStyles.marginTopBetweenElements]} height={scale(1)}/>
                    <TouchableOpacity onPress={linkToProduct}>
                        <View style={[globalStyles.flexDirectionRow, globalStyles.centerItems, {alignSelf: 'center'}]}>
                            <Icons8Generator style={{marginRight: scale(11)}} height={scale(18)} width={scale(18)} fill={Colors.black} name="restart" />
                            <AppText style={[styles.aboutToExpireCardRenew]}>{t("screens:Home:cards:expired-card:renew")}</AppText>
                        </View>
                    </TouchableOpacity> */}
                </View>
                <ClassActionButton
                    onPress={linkToProduct}
                    text={t("screens:Home:cards:expired-card:renew")}
                    iconName={"restart"}
                    textColor={'black'}
                    style={{alignItems: 'center'}}
                    noWrap={false}
                />
            </View>
        )
    }

    linkToSchedule(box, startDate) {
        this.props.selectClubId(box.id);
        this.props.navigation.navigate('Schedule', {calendarDate: startDate})
    }


    renderFutureMembershipCard(title, box, message, icon, startDate) {
        // return null;
        // const linkToSchedule = () => {
        //     this.props.selectClubId(box.id);
        //     this.props.navigation.navigate('Schedule', {calendarDate: startDate})
        // }

        return (
            <View>
                <View style={[globalStyles.card, {padding: verticalScale(15)}]}>
                    <View style={[globalStyles.flexDirectionRowSpaceBetween]}>
                        <AppText style={[styles.aboutToExpireCardTitle]}>{title}</AppText>
                        {icon}
                    </View>
                    <AppText>{box.name}</AppText>
                    {message}
                </View>
                <ClassActionButton
                    onPress={() => this.linkToSchedule(box, startDate)}
                    text={t("screens:Home:cards:future-plan:book", {})}
                    iconName={"plus"}
                    textColor={'black'}
                    style={{alignItems: 'center'}}
                    noWrap={false}
                />
            </View>
        )
    }

    renderMembershipEnding(data) {
        const membershipType = data.membership_types
        const linkToProduct = async () => {
            try {
                const membershipTypeId = membershipType.id;
                const locationBoxId = membershipType.location_box_fk;
                const response = await this.props.fetchShopAbleItems(`shop/item/${membershipTypeId}/${locationBoxId}`);
                const membership = response.data;
                if (membership) {
                    this.props.navigation.navigate("Product", {membership})
                } else {
                    this.props.navigation.navigate('Shop')
                }
            } catch (err) {
                console.log(err);
            }
        }

        const boxName = data.box.name;
        let title, message, icon;
        if (membershipType.type === 'session') {
            title = t("screens:Home:cards:ending-session:title");
            message = (<AppText style={[globalStyles.marginTopBetweenElements]}>
                <AppText style={[styles.expiredCardContent]}>{t("screens:Home:cards:ending-session:youHave")}</AppText>
                <AppText
                    style={[styles.expiredCardContent]}> {data.sessions_left} {t("screens:Home:cards:ending-session:sessions")}</AppText>
                <AppText
                    style={[styles.expiredCardContent]}>{t("screens:Home:cards:ending-session:inMembership")}</AppText>
                <AppText style={[styles.expiredCardContent]}>{data.membership_types.name}</AppText>
            </AppText>);
            icon = <Icons8Generator height={scale(30)} width={scale(30)} fill={Colors.red} name="sessions"/>
        } else {
            title = t("screens:Home:cards:ending-plan:title");
            message = (<AppText style={[globalStyles.marginTopBetweenElements]}>
                    <AppText
                        style={[styles.expiredCardContent]}>{data.membership_types.name} </AppText>
                    {data.days > 0 ? (
                            <AppText>
                                <AppText
                                    style={[styles.expiredCardContent]}>{t("screens:Home:cards:ending-plan:willExpireIn")}</AppText>
                                <AppText
                                    style={[styles.expiredCardContent]}> {data.days} {t("screens:Home:cards:ending-plan:days")}</AppText>
                            </AppText>
                        )
                        : (
                            <AppText>
                                <AppText
                                    style={[styles.expiredCardContent]}>{t("screens:Home:cards:ending-plan:willExpireToday")}</AppText>
                                <AppText
                                    style={[styles.expiredCardContent]}> {t("screens:Home:cards:ending-plan:today")}</AppText>
                            </AppText>
                        )
                    }
                </AppText>
            );
            icon = <SvgExpired height={scale(30)} width={scale(30)} fill={Colors.red}/>
        }
        return (
            this.renderAboutToExpireCard(title, boxName, message, icon, linkToProduct)
        );
    }

    renderMembershipExpired(data) {
        const hasShop = boxHasShop(this.props.user.users_boxes, data.box_fk);
        const isIL = isBoxIL(this.props.user.users_boxes, data.box_fk);
        const box = data["box"];
        const membershipType = data["membership_types"];
        return (
            this.renderExpiredCard(box, membershipType, hasShop, isIL)
        );
    }

    renderFutureMembership(data) {
        const box = data.box;
        const membershipName = data.membership_types.name
        const title = t("screens:Home:cards:future-plan:title", {});
        const startDate = data.start;
        const message = (<AppText style={[globalStyles.marginTopBetweenElements]}>
                <AppText style={[styles.futureCardContent]}>{membershipName}</AppText>
                <AppText style={[styles.futureCardContent]}> {t("screens:Home:cards:future-plan:message", {})}</AppText>
                <AppText
                    style={[styles.futureCardContent]}> {data.daysToStart} {t("screens:Home:cards:ending-plan:days", {})}</AppText>
            </AppText>
        );
        // TODO: change to outline clock;
        const icon = <Icons8Generator name="clock" height={scale(25)} width={scale(25)} fill={Colors.black}/>
        return (
            this.renderFutureMembershipCard(title, box, message, icon, startDate)
        );
    }

    renderClubMessageCard = (data) => {
        const {locations_box, messages_center, box} = data;
        const boxes = box ? box : messages_center.box;
        const box_name = boxes.name;
        const box_id = boxes.id;
        const image = boxes.cloudinary_image ? boxes.cloudinary_image : locations_box.logo;
        const message = messages_center ? messages_center.message : data.message;
        const created_at = messages_center ? messages_center.created_at : data.created_at;
        const link = () => this.props.navigation.navigate("ClubScreen", {box_id});
        return (
            <View style={[globalStyles.card]}>
                {this.headerIcon(Colors.white, Colors.clubMsgCardBtnColor, 'MaterialIcons', 'subject')}
                {this.pictureView(box_name, image, t('screens:Home:cards:club-message-card:title', {clubName: box_name}), t("timing:time", this.getTimeDiff(created_at)), link, true)}
                {this.cardContent(message)}
            </View>
        )
    };

    renderScheduleInvitation = (data) => {
        const user = getUserFromFriendConnection(data);
        const link = () => NavigationService.push("FriendProfile", {id: user.id});
        const view = this.pictureView(user.full_name, user.image, t('screens:Home:cards:invite-schedule:title', {userName: user.full_name}), t("timing:time", this.getTimeDiff(data.created_at)), link);
        if (user === null)
            return null;
        if (this.props.screen === 'notifications') {
            return (
                <View style={[globalStyles.card]}>
                    {view}
                    {this.cardBigTitle(data.schedule.box_categories.name)}
                    {this.cardContent()}
                </View>
            )
        }
        return (
            <ScheduleCard
                data={{...data.schedule, feed_id: data.id}}
                type='feed'
                screen={this.props.screen}
                feedView={true}>
                {this.pictureView(user.full_name, user.image, t('screens:Home:cards:invite-schedule:title', {userName: user.full_name}), t("timing:time", this.getTimeDiff(data.created_at)), link)}
            </ScheduleCard>
        )
    };

    renderUpcomingSchedules = (data) => {
        const {schedule} = data;

        const time = this.getTimeDiff(schedule.date_time.date);
        return (
            <ScheduleCard
                data={{...data.schedule, feed_id: data.id}}
                type='feed'
                screen={this.props.screen}
                feedView={true}
                onlyUsers={true}>
                {this.headerIcon(Colors.white, Colors.purple, 'MaterialCommunityIcons', 'timer')}
                {this.cardContent(t("screens:Home:cards:upcoming-schedules:content", {
                    ...time,
                    scheduleName: schedule.box_categories.name,
                    boxName: schedule.box.name
                }))}
            </ScheduleCard>
        )
    };

    renderTodayWorkout = (data) => {
        const isFillResults = Object.prototype.hasOwnProperty.call(data[0][0], 'fill_results');
        if (isFillResults) {
            return this.renderFillTodayWorkout(data)
        }
        return <LogBookCard data={data} screen={'home'} noBorders={true} hasContainer={true}/>
    };

    renderFillTodayWorkout = (data) => {
        const workoutDate = date.dayMonth(moment(data[0][0].name), 'MM/DD');
        const boxCategories = data[0][0].box_categories;
        const boxes = data[0][0].boxes;
        return (
            <View>
                <View style={[globalStyles.card, globalStyles.centerItems]}>
                    <AppText>{t('screens:Home:cards:today-workout:header', {})}</AppText>
                    <AppText
                        style={[globalStyles.marginTopBetweenTextElements, globalStyles.heeboMedium]}>{boxCategories.name} - {workoutDate}</AppText>
                    <AppText style={[{
                        color: Colors.brandedGraySelected,
                        fontSize: scale(12)
                    }, globalStyles.marginTopSm]}>{boxes.name}</AppText>
                    <View style={globalStyles.marginVertical}>
                        <Icons8Generator name={'gymnastics'} size={HUGE_ICON_SIZE}/>
                        <Icons8Generator style={{
                            backgroundColor: Colors.transparent,
                            top: scale(3),
                            ...i18n.isRTL ? {left: scale(-5)} : {right: scale(-5)},
                            position: 'absolute',
                        }} name={'check-circle'} fill={Colors.green}/>
                    </View>

                    <AppText style={globalStyles.textCenter}>{t('screens:Home:cards:today-workout:text', {})}</AppText>
                </View>
                {this.renderActionButton(t('screens:Home:cards:today-workout:button', {}), ACTIONS.ACTION_TODAY_WORKOUT, null)}
            </View>
        )
    };

    renderFriendSentBalloons = (data) => {
        const user = getUserFromFriendConnection(data);
        if (user === null)
            return null;
        const link = () => NavigationService.push("FriendProfile", {id: user.id});
        return (
            <View style={[globalStyles.card]}>
                {this.pictureView(user.full_name, user.image, t('screens:Home:cards:balloons-card:title', {userName: user.full_name}), t("timing:time", this.getTimeDiff(data.created_at)), link)}
                {this.cardContent()}
            </View>
        )
    }

    renderThanksForBalloons = (data) => {
        const user = getUserFromFriendConnection(data);
        if (user === null)
            return null;
        const link = () => NavigationService.push("FriendProfile", {id: user.id});
        return (
            <View style={[globalStyles.card]}>
                {this.pictureView(user.full_name, user.image, t('screens:Home:cards:balloons-card:thanked', {userName: user.full_name}), t("timing:time", this.getTimeDiff(data.created_at)), link)}
                {this.cardContent()}
            </View>
        )
    };

    renderEnteredFromStandby = (data) => {
        const {schedule} = data;
        const time = this.getTimeDiff(schedule.date_time.date);
        const day = t('common:dayOfWeek:' + schedule.day_of_week, {});
        return (
            <ScheduleCard
                data={{...data.schedule, feed_id: data.id}}
                type='feed'
                screen={this.props.screen}
                feedView={true}
                onlyUsers={true}>
                {this.headerIcon(Colors.white, Colors.purple, 'MaterialCommunityIcons', 'timer-off')}
                {this.cardContent(t("screens:Home:cards:stand-by-entered:content", {
                    time: schedule.time,
                    date: schedule.date,
                    day: day,
                    scheduleName: schedule.box_categories.name,
                    boxName: schedule.box.name
                }))}
            </ScheduleCard>
        )
    };


    render() {
        const {data} = this.props;
        try {
            return (
                (
                    <View key={uuid.v4()} style={[globalStyles.appMinimalPaddingHorizontal]}>
                        {this.state.submitting ? <ActivityIndicator size="large"
                                                                    style={[globalStyles.activityIndicatorStyle, globalStyles.activityIndicatorStyleAbsolute]}/> : null}
                        <View style={globalStyles.cardContainer}>
                            {this.renderFeedCard(data)}
                        </View>
                    </View>

                )
            );

        } catch (e) {
            return null;
        }
    }
}

FeedCard.propTypes = {
    action: PropTypes.string,
    user: PropTypes.object,
    //isDefault: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
const mapStateToProps = state => ({
    user: state.user.data,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            showModal,
            removeFeed,
            friendRelationAction,
            addFriend,
            fetchShopAbleItems,
            selectClubId
        },
        dispatch,
    );

const connectObject = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeedCard);

export default withNavigation(connectObject);

