import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="50pt"
        height="50pt"
        viewBox="0 0 50 50"
        width="1em" height="1em"
        {...props}
    >
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="M12 45c0 1.102.898 2 2 2h22c1.102 0 2-.898 2-2V5c0-1.102-.898-2-2-2H14c-1.102 0-2 .898-2 2Zm10-2h6M12 9h26M12 40h26"
        />
    </Svg>
)

export default SvgComponent
