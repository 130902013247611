import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8UserFilled(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M30.934 32.527c-.149-1.613-.09-2.738-.09-4.21.73-.383 2.039-2.825 2.258-4.887.574-.047 1.48-.606 1.746-2.817.14-1.187-.426-1.855-.774-2.066.934-2.809 2.875-11.5-3.586-12.399-.668-1.168-2.37-1.757-4.582-1.757-8.855.164-9.922 6.687-7.98 14.156-.348.21-.914.879-.774 2.066.27 2.211 1.172 2.77 1.746 2.817.22 2.062 1.579 4.504 2.313 4.886 0 1.473.055 2.598-.094 4.211C19.367 37.238 7.547 35.914 7 45h38c-.547-9.086-12.316-7.762-14.066-12.473z" />
    </Svg>
  );
}

export default SvgIcons8UserFilled;
