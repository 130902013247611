import React, {useEffect, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import i18n, {t} from "../../../services/i18n";
import {AppText} from "../AppText";
import {scale} from 'react-native-size-matters';
import {getThemeProperty, keys} from "../../../styles/Theme";
import CountryPicker, {getAllCountries} from 'react-native-country-picker-modal'


/**
 * to be wrapped with redux-form Field component
 */

export const CountryPickerRedux = (props) => {
    const {input, meta , ...inputProps} = props;

    const error = inputProps.showError === true || meta.submitFailed === true ? meta ? meta.error : null : null;
    const validationStyles = meta ? meta.touched && meta.active
        ? {borderBottomColor:getThemeProperty(keys.brandedGreen)} : {} : {};
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(input.value)
    },[input])

    return (
        <View style={styles.row}>
            <View style={[styles.column]}>
                {!value ? null : <AppText>{t('common:forms:createToken:country',{})}</AppText>}
                <CountryPicker
                    withFilter={true}
                    containerButtonStyle={[styles.textInputContainer,{borderBottomColor:props.color ? props.color : getThemeProperty(keys.brandedGreen)}, validationStyles]}
                    withCountryNameButton={true}
                    countryCode={value}
                    placeholder={inputProps.placeholder}
                    onSelect={inputProps.onCountrySelect}
                />
                {!value && meta.submitFailed ? <AppText style={globalStyles.textRed}>{t('common:required', {})}</AppText> : null}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#005493',
        flex: 1,
    },

    row: {
        paddingVertical: 10,
    },
    column: {},

    textInputContainer: {
        minHeight: 35,
        borderBottomWidth: 0.7,
    },

    textInputLabel: {
        ...globalStyles.heeboRegular,
        fontSize: 12,
        color: Colors.black,
    },

    input: {
        paddingHorizontal: 5,
        width: '100%',
        fontSize: scale(18),
        color: Colors.black,
        ...globalStyles.heeboRegular,
        textAlign: i18n.isRTL ? 'right' : 'left'
    },
    inputContainer: {
        borderBottomWidth: 0.7,
        borderBottomColor: 'rgba(255,255,255,0.6)',
        flex: 1,
    },
});
