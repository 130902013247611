// @flow
import * as React from 'react';
import {Platform, View} from "react-native";
import {AppText} from "../../components/Stateless/AppText";
import {t} from "../../services/i18n";
import BagsIllustration from "../../svg/custom/BagsIllustration";
import EStyleSheet from "react-native-extended-stylesheet";
import {isMobile} from "../../helpers/functions";
import {Colors} from "../../styles/Colors";
import ShopPage from "../../components/Web/Shop/ShopPage";
import {useEffect, useState} from "react";
import {emptyCart, fetchShopAbleItems} from "../../actions/shop";
import {useDispatch, useSelector} from "react-redux";
import {ActivityIndicator} from "react-native-web";
import {apiAction} from "../../helpers/HTTP";

const ShopScreen = (props) => {
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const [isStoreEmpty, setIsStoreEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const primaryColor = useSelector(state => state.whiteLabelProperties.primaryColor);
    const mobile = isMobile();
    const dispatch = useDispatch()

    useEffect(() => {

        if(box && location) {
            fetchItems()
        }
    }, [location, box]);

    const fetchItems = async () => {
        setIsLoading(true)
        const hasPayment = await apiAction(`locationsBox/doesLocationHasPaymentService/${location.id}`, 'get', null);
        if(!hasPayment) {
            setIsStoreEmpty(true)
            setIsLoading(false)
        } else {
            const items = await dispatch(fetchShopAbleItems(`shop/${box.id}/${location.id}`));
            const notEmptyTypes = Object.values(items.data).filter(type => {
                return type.length > 0
            })
            setIsStoreEmpty(notEmptyTypes.length === 0)
            setIsLoading(false)
        }
    }

    return (
        <>
            {isLoading ? <ActivityIndicator color={Colors.brandedBlue}/> :
                <>
                    {isStoreEmpty ?
                        <View style={[mobile ? styles.wrapperMobile : styles.wrapper]}>
                            <View style={[mobile ? styles.contentMobile : styles.content]}>
                                <AppText style={[styles.textSubHeader, {fontSize: mobile ? '1.1rem' : '1.3rem'}]}>{t('headings:welcome-to-our',{})}</AppText>
                                <AppText style={[styles.textHeader, {fontSize: mobile ? '2.5rem' : '2.8rem'}]}>{t('headings:online-shop',{})}</AppText>
                                <AppText style={[styles.text, {fontSize: mobile ? '1rem' : '1.1rem'}]}>{t('headings:coming-soon',{})}</AppText>
                            </View>
                            <BagsIllustration style={[mobile ? styles.bagsMobile : styles.bags]} width={'18em'} height={'18em'}/>
                        </View>
                        :
                        <ShopPage/>
                    }
                </>
            }
        </>

    );
};
export default ShopScreen;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapperMobile: {
                position: 'relative',
                flex: 1,
            },
            wrapper: {
                margin: 'auto',
                flexDirection: 'row',
                width: '80%',
                maxWidth: 850,
                justifyContent: 'space-between'
            },
            contentMobile: {
                padding: '3em',
            },
            content: {
                padding: 0
            },
            textSubHeader: {
                fontSize: '1.1rem',
                fontFamily: 'heebo',
                color: Colors.lightBlack
            },
            textHeader: {
                fontSize: '2.5rem',
                fontFamily: 'heebo',
                marginBottom: '1em',
                color: Colors.lightBlack
            },
            text: {
                fontSize: '1rem',
                color: Colors.lightBlack
            },
            bagsMobile: {
                transform: 'rotate(-20deg)',
                position: 'absolute',
                bottom: '-1em',
                right: '-2em'
            },
            bags: {
                position: 'unset',
            }
        }
    })
})
