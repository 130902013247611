import React, {useState} from 'react'
import {Platform, StyleSheet, Text, View} from 'react-native'
import { globalStyles } from "../../styles/Global"
import { Colors } from "../../styles/Colors"
import {AppText} from "../Stateless/AppText";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import Logo from "../../svg/custom/LogoBlack"
import Phone from "../../svg/Icons8Phone"
import Mail from "../../svg/Icons8Envelope"
import {isMobile} from "../../helpers/functions";
import {useDispatch, useSelector} from "react-redux";
import {Linking, TouchableOpacity} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {changeSiteLang, isSiteHebrew} from "../../helpers/ComponentFunctionality";
import {PLUGIN_REFERER, SITE_REFERER} from "../../constants";
import {Icons8Generator} from "../Stateless/Icons8Generator";

const PluginFooter = (props) => {
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const referer = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.referer)
    const dispatch = useDispatch();

    const changeLang = (lang) => {
        changeSiteLang(lang, dispatch, true)
    }

    return (
        <footer>
            {!isMobile() ?
                <View style={[styles.wrapper, referer === PLUGIN_REFERER ? styles.pluginWrapper : null]}>
                    {
                        referer === SITE_REFERER &&
                        <View style={styles.infoSide}>
                            {box &&
                            <>
                                <View style={styles.singleInfo}>
                                    <Phone/>
                                    <TouchableOpacity onPress={() => Linking.openURL(`tel:${box?.phone}`)}><AppText style={[styles.text]}>{box.phone}</AppText></TouchableOpacity>
                                </View>
                                <View style={styles.singleInfo}>
                                    <Mail/>
                                    <TouchableOpacity onPress={() => Linking.openURL(`mailto:${box?.email}?subject=SendMail&body=Description`)}><AppText style={[styles.text]}>{box.email}</AppText></TouchableOpacity>
                                </View>
                                {location?.country_code.toUpperCase() ===  'IL' &&
                                <View style={[styles.singleInfo]}>
                                    <Icons8Generator name={'lang-blob'} size={'1.5em'}/>
                                    <View style={[styles.singleInfo, {gap: '0.5em'}]}>
                                        <TouchableOpacity onPress={() => changeLang('he')}><AppText
                                            style={[styles.text]}>עברית</AppText></TouchableOpacity>
                                        <AppText style={[styles.text]}>/</AppText>
                                        <TouchableOpacity onPress={() => changeLang('en')}><AppText
                                            style={[styles.text]}>English</AppText></TouchableOpacity>
                                    </View>
                                </View>
                                }
                            </>
                            }
                        </View>
                    }
                    <TouchableOpacity style={[styles.arboxSide, isSiteHebrew() && {flexDirection: 'row-reverse'}]} onPress={()=> Linking.openURL('https://www.arboxapp.com/')}>
                        <AppText style={[styles.text]}>Powered By</AppText>
                        <Logo style={[styles.logo]}/>
                    </TouchableOpacity>
                </View> :
                //TODO EDEN ASK FOR A PROPER IMAGE
                <TouchableOpacity style={[styles.wrapperMobile, isSiteHebrew() && {flexDirection: 'row-reverse'}]} onPress={()=> Linking.openURL('https://www.arboxapp.com/')}>
                    <AppText style={[styles.textMobile, {'color': Colors.lightBlack}]}>Powered By</AppText>
                    <Logo style={[styles.logoMobile]} fill={Colors.lightBlack}/>
                </TouchableOpacity>
            }
        </footer>
    )
}

export default PluginFooter;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                width: "100vw",
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1.5em',
                backgroundColor: Colors.white,
                position: 'fixed',
                bottom: 0,
                height: '4em',
                maxHeight: '$footerMaxHeight'
            },
            pluginWrapper: {
                justifyContent: 'flex-end',
            },
            text: {
                fontSize: '0.7rem'
            },
            logo: {
                width: '4em'
            },
            arboxSide: {
                flexDirection: "row",
                gap: '1em',
            },
            infoSide: {
                flexDirection: "row",
                gap: '4em'
            },
            singleInfo: {
                flexDirection: "row",
                gap: '1em',
                alignItems: 'center',
            },
            wrapperMobile: {
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: "row",
                backgroundColor: Colors.white,
                height: '$footerMaxHeight',
                maxHeight: '$footerMaxHeight'
            },
            textMobile: {
                fontWeight: 'bold',
                marginHorizontal: scale(10)
            },
            logoMobile: {
                width: scale(60)
            }
        }
    })
})
