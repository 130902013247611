import axios from 'axios';
import {t} from '../services/i18n';

import {Platform} from 'react-native';

import Config from '../config/Config';
import ConfigDefaults from '../config/ConfigDefaults';
import {store} from '../config/store';
import Toast from "react-native-root-toast";
import {Colors} from "../styles/Colors";
import getEnvVars from "../config/Env"
import {logoutUser, logoutUserSuccess} from "../actions/auth";
import {isWeb} from "./functions";
import {APP_REFERER} from "../constants";

export const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});



export const apiAction = async (apiCall, method = 'get', data = null, isExternal = false, tokenFromRegistration = null) => {
    axiosInstance.defaults.headers.common.accessToken = store.getState().auth.token; // Always exists, on init. store has it, if null not logged in yet.
    axiosInstance.defaults.headers.common.whiteLabel = Config.whiteLabel;
    if(isWeb()){
        handleWebHeaders(isExternal, tokenFromRegistration)
    } else {
        axiosInstance.defaults.headers.common.refererName = APP_REFERER.toLowerCase();
    }
    axiosInstance.defaults.headers.common.version = Config.version || ConfigDefaults.version;

     const {apiUrl, externalApiUrl}= getEnvVars();
    const api = `${isExternal ? externalApiUrl : apiUrl}${apiCall}?XDEBUG_SESSION_START=PHPSTORM`;
    const options = {
        method,
        url: api
    };
    if (data) {
        options.data = data;
    }
    console.log(api,method);
    try {
        const response = await axiosInstance.request(options);
        //TODO Note -> Sometimes we return structure that has data data or data
        return response.data;
    } catch (err) {
        if (Object.prototype.hasOwnProperty.call(err.response.data, 'data')) {
            console.log(err.response.data.error);
            if(err.response.data.error){
                const error = err.response.data.error;
                if(error.code === 401){
                    store.dispatch(logoutUserSuccess());
                }
            }

        }
        if (Object.prototype.hasOwnProperty.call(err.response.data, 'uniqueIdentifier')) {
            err.response.data = {
                // HERE IF SYSTEM FALLS ON UNKNOWN ERROR - RESET OBJECT TO PRINTED ONE
                statusCode: 0,
                error: {
                    message: 'unknown failure',
                    messageToUser: t('common:unknownFailure', {}),
                    code: 0,
                },
                data: null
            };
            Toast.show(t('common:unknownFailure', {}), {
                backgroundColor: Colors.brandDanger,
                textColor: Colors.white
            });
        }
        console.log(err);
        throw err.response.data;
    }
};

const handleWebHeaders =(isExternal, tokenFromRegistration) => {
    const token = tokenFromRegistration || (isExternal ? 0 : (store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.auth.token || 0))
    axiosInstance.defaults.headers.common.accessToken = token
    axiosInstance.defaults.headers.common.boxFk = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier]?.box.data?.id;
    const site = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(site.whitelabel){
        axiosInstance.defaults.headers.common.whiteLabel = site.whitelabel;
    }
    if(site.box.data){
        axiosInstance.defaults.headers.common.identifier = site.box.data.external_url_id;
    } else if (params.hasOwnProperty('identifier') && !site.box.data) {
        axiosInstance.defaults.headers.common.identifier = params.identifier;
    }
    if(site.referer) {
        axiosInstance.defaults.headers.common.refererName = site.referer.toLowerCase();
    }
}

export const createFormData = (imageUri) => {
    const data = new FormData();
    let ext = imageUri.substr(imageUri.lastIndexOf('.') + 1);
    ext = ext ? ext : 'jpeg';
    data.append('file', {
        name: 'temp',
        type: `'image/'${ext}`,
        uri: Platform.OS.toLowerCase() === 'android' ? imageUri : imageUri.replace('file://', ''),
    });
    return data;
};

export const imageUpload = async (apiCall, imageUri, callback) => {
    const formData = createFormData(imageUri);
    axiosInstance.defaults.headers.common.accessToken = store.getState().auth.token; // Always exists, on init. store has it, if null not logged in yet.
    axiosInstance.defaults.headers.common.whiteLabel = Config.whiteLabel;
    const {apiUrl}= getEnvVars();
    const api = `${apiUrl}${apiCall}?XDEBUG_SESSION_START=17933`;

    try {
        return await axiosInstance.post(`${api}`, formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: callback,
        });
    } catch (error) {
        throw error.response;
    }
};


