// @flow
import * as React from 'react';
import {Platform, StyleSheet, View} from "react-native";
import {AppText} from "../../../Stateless/AppText";
import {Colors} from "../../../../styles/Colors";
import ClassItem from "./ClassItem";
import EStyleSheet from "react-native-extended-stylesheet";
import {useEffect, useState} from "react";
import {fetchCurrencySymbol} from "../../../../actions/shop";
import {useDispatch, useSelector} from "react-redux";

const DayItemsList = (props) => {
    const {classes, time, searchInput} = props;

    return (
        <View style={styles.wrapper}>
            <AppText style={styles.hourHeader} boldFont>{time}</AppText>
            <View style={{width: '100%', gap:'0.5em'}}>
                {classes && classes.map(item => {
                    if(item.box_categories.name.toLowerCase().includes(searchInput.toLowerCase())){
                        return <ClassItem key={`class-item-${item.id}`} classInfo={item}/>
                    }
                })}
            </View>
        </View>
    );
};

export default DayItemsList;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                alignItems: 'center',
                width: '100%',
            },
            hourHeader: {
                color: Colors.grayText,
                fontSize: '0.8rem',
                margin: '0.5em'
            }
        }
    })
})