import React from 'react';
import {connect} from "react-redux";
import {View, ScrollView} from 'react-native';
import {bindActionCreators} from "redux";
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {verticalScale, scale} from 'react-native-size-matters';
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import {t} from "../../services/i18n";
import Spinner from "react-native-loading-spinner-overlay";
import AppHeader from "../../components/Stateless/AppHeader";
import {RoundIcon} from "../../components/Stateless/RoundIcon";
import {AppText} from "../../components/Stateless/AppText";
import {ScheduleRowByType, UserAvatars} from "../../components/Stateless/ScheduleDisplayer";
import {SCHEDULE_ACTION} from "../../constants";
import BookingV2 from "../../components/ScheduleFlowV2/Booking";
import {RoundedImageOrIcon} from "../../components/Stateless/RoundedImageOrIcon";
import {CANCEL_SCHEDULE_USER, CANCEL_WAIT_LIST, INSERT_SCHEDULE_USER, INSERT_STAND_BY} from "../../constants/booking";
import {IconSize} from "../../constants/iconSize";
import {captureRef} from "react-native-view-shot";
import {openShareDialogAsync} from "../../helpers/share";
import {withNavigation} from "react-navigation";
import date from "../../services/i18n/date";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {getToastDefaultConfig} from "../../helpers/functions";
import Toast from "react-native-root-toast";
import {hasCoachRestriction} from "../../helpers/DisablePages";
import {fetchCurrencySymbol} from "../../actions/shop";
import {apiAction} from "../../helpers/HTTP";

@withNavigation
class ScheduleSingleScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoading: true,
            booked: false,
            hasPaymentServices: false
        }
        this.shareScreen = null;
        this.onAction = this.onAction.bind(this);
        this.onShare = this.onShare.bind(this);
    }


    async componentDidMount() {
        //TODO ADD CHECKS IF CAME FROM NOTIFICATIONS;
        const data = this.props.navigation.getParam('data');
        if(this.props.currencySymbol === null){
            //Meaning redux never fetched it
            await this.props.fetchCurrencySymbol(`shop/currencySymbol/${data.locations_box_fk}`);
        }
        const hasPaymentServices = await apiAction(`locationsBox/doesLocationHasPaymentService/${data.locations_box_fk}`, 'get', null);
        this.setState({data, isLoading: false,hasPaymentServices})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.data !== prevState.data) {
            this.setState({data: this.state.data, isLoading: false})
        }
    }

    render() {
        return (
            <React.Fragment>
                <StatusBarWrap backgroundColor={this.props.whiteLabelProperties.primaryColor}/>
                <Spinner visible={this.state.isLoading}/>
                {this.state.booked ? null : this.header()}
                <View style={[ globalStyles.appPaddingHorizontal, {
                    flex: 1,
                    backgroundColor: this.state.booked ? this.props.whiteLabelProperties.primaryColor : Colors.screensGrayBackground
                }]}>
                    <ScrollView
                        style={[this.initBookingStatusInsert && !this.state.booked ? {maxHeight: verticalScale(250)} : {}]}
                        contentContainerStyle={[{
                            paddingTop: verticalScale(10),
                            flexGrow: 1,
                        }]}>
                        {this.screen()}
                    </ScrollView>
                    <View
                        style={[globalStyles.flex, globalStyles.stickToBottom, globalStyles.stickyButtonsMarginBottom, {height: verticalScale(200)}]}>
                        {this.footer()}
                    </View>
                </View>
            </React.Fragment>
        );
    }

    footer() {
        const {data,hasPaymentServices} = this.state;
        if (!data) return null;
        return <BookingV2 data={data}
                          reducerActionName={SCHEDULE_ACTION}
                          type={'byDate'}
                          booked={this.state.booked}
                          onAction={this.onAction}
                          onShare={this.onShare}
                          hasPaymentServices={hasPaymentServices}
        />
    }

    onAction(schedule) {
        this.setState({data: schedule, booked: schedule.booking_option === CANCEL_SCHEDULE_USER});// MEANING He just got booked
        if (!(schedule.booking_option === CANCEL_SCHEDULE_USER)) {
            this.props.navigation.goBack()
            this.toast(schedule ,true); // Must be first because changes later on;
        }
    }

    async toast(schedule,toast) {
        if (toast) {
            let text;
            switch (schedule.booking_option) {
                case CANCEL_WAIT_LIST:
                    text = t('common:toast:standByInsert', {});
                    break;
                case INSERT_STAND_BY:
                    text = t('common:toast:standByCancel', {});
                    break;
                case INSERT_SCHEDULE_USER:
                    text = t('common:toast:scheduleCancel', {});
                    break;
                default:
                    break;
            }
            const options = getToastDefaultConfig();
            Toast.show(t(text, {}), {
                backgroundColor: schedule.booking_option !== CANCEL_WAIT_LIST ? Colors.red : Colors.brandSuccess,
                ...options,
            });
        }
    }

    async onShare() {
        if (this.shareScreen) {
            const imageUri = await captureRef(this.shareScreen);
            await openShareDialogAsync('file://' + imageUri);
        } else {
            console.log('unable to capture screen')
        }

    }

    header() {
        const {data, booked} = this.state;
        if (!data) return null;
        const coach = data.coach;
        const displayCoach = hasCoachRestriction(data);

        const text = data.user_booked ? t('screens:ScheduleSingle:booked', {}) : data.user_in_standby ? t('screens:ScheduleSingle:inWaitList', {}) : null;

        return (
            <View style={[globalStyles.appMinimalPaddingHorizontal, {
                minHeight: verticalScale(100),
                paddingBottom: verticalScale(8),
                backgroundColor: this.props.whiteLabelProperties.primaryColor
            }]}>
                <AppHeader pop={true} headerColor={Colors.white}/>
                {data ? (
                    <View style={[globalStyles.centerItems, booked ? globalStyles.marginTopLarge : {}]}>
                        <RoundedImageOrIcon displayImage={displayCoach} obj={coach} imageSize={70}/>
                        <AppText
                            style={[globalStyles.heeboMedium, globalStyles.marginTopMedium, globalStyles.title, {color: Colors.white}]}>{data.box_categories.name} {displayCoach ? t('screens:ScheduleSingle:coach', {name: coach.first_name}) : null}</AppText>
                        {text && !booked ? <View
                            style={[globalStyles.flexDirectionRow, globalStyles.marginTopSm, globalStyles.centerItems]}>
                            <AppText
                                style={[globalStyles.heeboMedium, {
                                    color: Colors.white,
                                    marginEnd: scale(5)
                                }]}>
                                {text}</AppText>
                            <RoundIcon height={scale(13)} iconSize={10}
                                       fill={this.props.whiteLabelProperties.primaryColor} noMargin={true}
                                       name={'check'}/>
                        </View> : null}
                    </View>
                ) : null}
            </View>
        )
    }

    screen() {
        const {data, booked} = this.state;
        if (!data) return null;
        const displayerArray = ['date', 'time', 'online', 'description', 'slots', 'users']
        return (
            <View style={[globalStyles.marginTopMedium]}>
                {booked ? this.bookedScreen() :
                    displayerArray.map(type => <ScheduleRowByType type={type}
                                                                  data={data}/>)
                }
            </View>

        )

    }

    bookedScreen() {
        const {data} = this.state;
        if (!data) return null;
        const coach = data.coach;
        const displayCoach = hasCoachRestriction(data);

        return (
            <View ref={(view) => {
                this.shareScreen = view
            }}
                  style={[globalStyles.marginTopBetweenElementsBig, globalStyles.centerItems, {backgroundColor: this.props.whiteLabelProperties.primaryColor}]}>
                <Icons8Generator name={'check-circle'} size={IconSize.SPECIAL_SIZE_85} fill={Colors.white}/>
                <AppText
                    style={[globalStyles.heeboBold, globalStyles.marginTopLarge, globalStyles.title, {color: Colors.white}]}>{t('screens:ScheduleSingle:onBooked:successTitle', {})}</AppText>
                <AppText
                    numberOfLines={2} style={[globalStyles.heeboRegular, globalStyles.marginTopBetweenTextElements, globalStyles.title, {color: Colors.white}]}>{data.box_categories.name} {displayCoach ? t('screens:ScheduleSingle:coach', {name: coach.first_name}) : null}</AppText>
                <AppText
                style={[globalStyles.heeboRegular, {color: Colors.white}]}>{date.format(data.date, 'ddd, ll,', true)} {date.timeFormat(data.time, 'LT', false, false)}</AppText>
                {data.availability_id ? null : <AppText
                    style={[globalStyles.heeboRegular, globalStyles.marginTopBetweenTextElements, globalStyles.subTitle, {color: Colors.white}]}>{t('screens:ScheduleSingle:onBooked:subTitle', {})}</AppText>}
                <View style={[globalStyles.marginTopMedium, {flexWrap: "wrap"}]}>
                    <UserAvatars centerItems={true} size={35} marginBetweenAvatars={5} displayAmount={30}
                                 iconSize={IconSize.M} data={data}/>
                </View>

            </View>
        )
    }

}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {fetchCurrencySymbol},
        dispatch,
    );
};


const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,
    currencySymbol: state.shop.currencySymbol,

});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ScheduleSingleScreen);
