import React, {useEffect, useState} from 'react';
import {View} from "react-native-web";
import TextInputField from "../../Common/TextInputField";
import {t} from "../../../../services/i18n";
import {globalStyles} from "../../../../styles/Global";
import {AppText} from "../../../Stateless/AppText";
import NumericCounter from "../../Common/NumericCounter";
import {Colors} from "../../../../styles/Colors";
import ModalButton from "../../../ModalButton";

const CvvAndInstallments = (props) => {
    const {maxInstallments, onNext} = props;
    const [cvv, setCvv] = useState('');
    const [installments, setInstallments] = useState(1);

    const next = () => {
        if(cvv && cvv.length >= 3) {
            onNext({cvv, installments})
        }
    }

    return (
        <View>
            <TextInputField isPassword={false}
                            nameLabel={t('common:forms:additionalPaymentDetailsForm:cvv', {})}
                            placeHolder={t('common:forms:additionalPaymentDetailsForm:cvv', {})}
                            value={cvv}
                            keyboardType={'number-pad'}
                            onChange={setCvv}/>
            {maxInstallments > 1 &&
                <View>
                    <AppText
                        style={[{fontSize: '1rem'}]}>
                        {t('common:forms:additionalPaymentDetailsForm:maxInstallments',{max:maxInstallments})}
                    </AppText>
                    <NumericCounter min={1} max={maxInstallments} initVal={installments} onChange={setInstallments}/>
                </View>
            }
            <ModalButton
                onPress={next}
                type='primary'
                colors={Colors.lightBlack}
                text={t('screens:Purchase:buyNow', {})}
                overrideStyle={{borderRadius: 3, width: '100%', marginTop: '1em', minHeight: '2em'}}
                textColor={Colors.white}
            />
        </View>
    );
};

export default CvvAndInstallments;
