// @flow
import * as React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import BookingSuccess from "../../../svg/Icons8Ok";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import PopupModal from "./PopupModal";
import {Colors} from "../../../styles/Colors";
import CalendarChecked from "../../../svg/Icons8Today"
import EStyleSheet from "react-native-extended-stylesheet";
import {isMobile} from "../../../helpers/functions";
import RightConfety from "../../../svg/custom/ConfetyRight"
import LeftConfety from "../../../svg/custom/ConfetyLeft"
import {useEffect, useState} from "react";
import ModalButton from "../../ModalButton";

const SuccessModal = (props) => {
    const {show, setClose, title, subHeader, showNotAsPopup} = props;
    const mobile = isMobile();

    const renderComponent = () => {
        const content =
            <View style={[styles.wrapper, mobile && {flexGrow: 1}, showNotAsPopup && {gap: '2em'}]}>
                <RightConfety style={[styles.confety, styles.confetyRight]}/>
                <LeftConfety style={[styles.confety, styles.confetyLeft]}/>
                <BookingSuccess style={styles.closeIcon} fill={Colors.brandedGreen} width={'3em'} height={'3em'}/>
                <AppText style={[mobile ? styles.headerMobile : styles.header, showNotAsPopup && {fontSize: '1.5rem'}]} boldFont>{title}</AppText>
                <AppText style={[mobile ? styles.subheaderMobile : styles.subheader, showNotAsPopup && {fontSize: '1rem'}]}>{subHeader}</AppText>
                {false && <View style={{alignItems: 'center', gap: mobile ? '0.8em' : '0.6em', marginVertical: mobile ? '1em' : 0}}>
                    <CalendarChecked width={mobile ? '3em' : '2em'} height={mobile ? '3em' : '2em'}/>
                    <AppText style={[mobile ? styles.subheaderMobile : styles.subheader, {fontWeight: 'bold'}]}>{t('common:drawer:booking:addToCalendar',{})}</AppText>
                </View>}
                <ModalButton
                    onPress={setClose}
                    text={t('screens:Schedule:back-to-start',{})}
                    textColor={Colors.white}
                    type='primary'
                    colors={Colors.brandedGreen}
                    overrideStyle={[styles.backBtn]}
                    textStyles={{fontFamily: 'heebo', fontSize: '0.9rem'}}
                />

            </View>
        return showNotAsPopup ? <View style={styles.notModal}>{content}</View> : <PopupModal show={show} setClose={setClose} overrideStyles={styles.modal} hideCloseBtn={true}>{content}</PopupModal>

    }


    return <>{renderComponent()}</>;
};

export default SuccessModal;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: '1em',
                paddingVertical: '1em',
                width: 400,
                margin: 'auto'
            },
            notModal: {
                width: 600,
                marginHorizontal: 'auto',
                marginTop: '2em'
            },
            wrapper: {
                gap: '1em',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                position: 'relative'
            },
            header: {
                fontSize: '1rem'
            },
            headerMobile: {
                fontSize: '1.2rem'
            },
            subheader: {
                fontSize: '0.8rem',
                width: '17em'
            },
            subheaderMobile: {
                fontSize: '1rem',
            },
            closeBtn: {
                fontSize: '0.9rem',
            },
            closeIcon: {
                width: '6em'
            },
            bike: {
                width: '15em'
            },
            confety: {
                position: 'absolute',
                top: 0
            },
            confetyRight: {
                right: 0
            },
            confetyLeft: {
                left: 0
            },
            backBtn: {
                marginTop: 0,
                borderRadius: 3,
                width: 150,
                minHeight: 0,
                paddingVertical: '0.5em'
            }
        }
    })
})