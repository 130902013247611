import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8FullTank(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm0 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25c0-.363.01-.724.027-1.082C4.59 12.812 13.753 4 25 4zm-.016 1.986A1 1 0 0024 7v4.055a13.921 13.921 0 00-8.154 3.377l-2.867-2.868a1 1 0 00-.717-.302 1 1 0 00-.698 1.717l3.538 3.537a1 1 0 001.529-.104 11.949 11.949 0 018.215-3.41 1 1 0 00.304 0 11.953 11.953 0 018.215 3.404 1 1 0 001.533.11l3.538-3.537a1 1 0 00-.727-1.717 1 1 0 00-.688.302l-2.865 2.866A13.928 13.928 0 0026 11.055V7a1 1 0 00-1.016-1.014zM7.846 20.56v11.548h5.22V30.16h-2.968v-3.13h2.63v-1.868h-2.63v-2.654h2.771v-1.95H7.846zm29.431 0v11.548h2.25v-4.763h2.604v-1.867h-2.604v-2.97h2.774V20.56h-5.024zm-3.363 2.265a.303.303 0 00-.209.049l-9.771 6.322c-.468.167-.88.426-1.182.766A3.05 3.05 0 0022 32c0 .722.235 1.458.752 2.04.517.58 1.331.96 2.248.96.917 0 1.731-.38 2.248-.96.27-.304.46-.65.582-1.015l6.297-9.73c.134-.207-.014-.442-.213-.47zM25 31c.417 0 .602.12.752.29.15.168.248.432.248.71 0 .278-.098.542-.248.71-.15.17-.335.29-.752.29s-.602-.12-.752-.29A1.095 1.095 0 0124 32c0-.278.098-.542.248-.71.15-.17.335-.29.752-.29z" />
    </Svg>
  );
}

export default SvgIcons8FullTank;
