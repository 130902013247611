import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8EditProperty(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M2.813 0C1.276 0 0 1.277 0 2.813v5A.753.753 0 000 8v32c0 .55.45 1 1 1h23c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496H2V9h40v13c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879V8a.753.753 0 000-.188v-5C44 1.278 42.723 0 41.187 0zm0 2h38.374c.465 0 .813.348.813.813V7H2V2.812C2 2.349 2.348 2 2.813 2zM7 15v2h4v-2zm7 0v2h23v-2zm-7 5v2h4v-2zm7 0v2h23v-2zm31.906 4.906c-1.05 0-2.105.387-2.906 1.188l-.688.718.032.032L26.906 42.28a1.02 1.02 0 00-.281.438l-1.688 6c-.101.347-.011.722.243.984.254.258.625.36.976.266l6-1.594a1.02 1.02 0 00.438-.281l15.812-15.688c.121-.101.219-.23.282-.375V32l.124-.094a4.116 4.116 0 000-5.812 4.086 4.086 0 00-2.906-1.188zM7 25v2h4v-2zm7 0v2h23v-2zm29.75 3.25l3 3L31.406 46.5l-2.937-2.844.031-.125zM7 30v2h4v-2zm7 0v2h19v-2z" />
    </Svg>
  );
}

export default SvgIcons8EditProperty;
