import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    Dimensions,
    View,
    ActivityIndicator
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {TabView, TabBar} from 'react-native-tab-view';
import {Colors} from '../../../styles/Colors';
import styles from './styles';
import {t} from '../../../services/i18n';
import {fetchMembershipSchedules} from '../../../actions/memberships';
import {scale} from 'react-native-size-matters';
import {globalStyles} from "../../../styles/Global";
import FlatListHandler from "../../../components/FlatListHandler";
import {AppText} from "../../../components/Stateless/AppText";
import {StatusBarWrap} from "../../../components/Stateless/StatusBarWrap";
import HeaderWrapper from "../../../components/HeaderWrapper";
import {getThemeProperty, keys} from "../../../styles/Theme";


class FitnessPunchesScreen extends React.Component {
    static navigationOptions = {
        header: null,
    };

    constructor(props) {
        super(props);
        this._isMounted = false;

        this.state = {
            currentClub: this.props.navigation.state.params.currentClub,
            membership: this.props.navigation.state.params.membership,
            index: this.props.navigation.state.params.index,
            routes: [{
                key: 'past', title: t('screens:More:clubs:classes-history:used-punches-tab', {pastClasses: 0})
            },
                {
                    key: 'future', title: t('screens:More:clubs:classes-history:future-classes-tab', {futureClasses: 0})
                },
                {
                    key: 'lateCancellation',
                    title: t('screens:More:clubs:classes-history:late-cancel-tab', {lateCancellation: 0})
                }],
        };
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
            this.themeColor = getThemeProperty(keys.brandedGreen);
        await this.props.fetchMembershipSchedules(this.state.membership.id);
        if (this.props.error === null) {
            if(this._isMounted){
                this.setState({
                    routes: [{
                        key: 'past',
                        title: t('screens:More:clubs:classes-history:used-punches-tab', {pastClasses: this.props.schedules.past.length})
                    },
                        {
                            key: 'future',
                            title: t('screens:More:clubs:classes-history:future-classes-tab', {futureClasses: this.props.schedules.future.length})
                        },
                        {
                            key: 'lateCancellation',
                            title: t('screens:More:clubs:classes-history:late-cancel-tab', {lateCancellation: this.props.schedules.lateCancellation.length})
                        }]
                });
            }

        }
        // Update ROUTES UP NUMBERS !
    }

    renderSchedules = (type, array) => {
        return (
            <View style={globalStyles.schedulesWrapper}>
                <FlatListHandler schedules={array}
                                 type={type}/>
            </View>
        )
    };


    renderTabs() {
        return (
            <TabView
                bounces={false}
                navigationState={this.state}
                renderScene={({route}) => {
                    switch (route.key) {
                        case 'past':
                            return this.renderSchedules('past', this.props.schedules.past);
                        case 'future':
                            return this.renderSchedules('future', this.props.schedules.future);
                        case 'lateCancellation':
                            return this.renderSchedules('lateCancellation', this.props.schedules.lateCancellation);
                        default:
                            return null;
                    }
                }}
                onIndexChange={index => this.setState({index})}
                renderTabBar={props => (
                    <LinearGradient
                        colors={[this.themeColor,this.themeColor]}
                    >
                        <TabBar
                            {...props}
                            indicatorStyle={[styles.tabIndicator,{backgroundColor:this.props.whiteLabelProperties.primaryColorText}]}
                            style={styles.tabBackground}
                            renderLabel={({route, focused}) => {
                                return (
                                    <View>
                                        <AppText
                                            style={[focused ? globalStyles.heeboMedium : globalStyles.heeboRegular, {
                                                fontSize: scale(12),
                                                textAlign: 'center',
                                                color: this.props.whiteLabelProperties.primaryColorText
                                            }]}>
                                            {route.title}
                                        </AppText>
                                    </View>
                                )
                            }
                            }
                        />
                    </LinearGradient>
                )}
                initialLayout={{width: Dimensions.get('window').width}}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <StatusBarWrap/>
                <HeaderWrapper
                    headerTitle={this.state.membership.membership_types ? this.state.membership.membership_types.name : t('screens:ClubProfile:membershipStaffMember',{})}
                    headerArrowColor={this.props.whiteLabelProperties.primaryColorText}
                    padding={globalStyles.appMinimalPaddingHorizontal}
                    isPop={true}
                />
                {this.props.isLoading ?  <ActivityIndicator color={Colors.white} style={globalStyles.activityIndicatorStyle}/> : this.renderTabs()}
            </React.Fragment>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchMembershipSchedules,
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    schedules: state.memberships.schedules.data,
    isLoading: state.memberships.schedules.isLoading,
    error: state.memberships.schedules.error,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(FitnessPunchesScreen);

