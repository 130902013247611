import React, {Component} from 'react';
import {View, Text, StyleSheet, Dimensions, Platform,ActivityIndicator} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import * as MediaLibrary from 'expo-media-library';
import {Camera} from 'expo-camera';
import {withNavigation} from 'react-navigation';
import {t} from "../../services/i18n";
import Toast from "react-native-root-toast";
import {getToastDefaultConfig} from "../../helpers/functions";
import {Colors} from "../../styles/Colors";

import * as ImageManipulator from "expo-image-manipulator";

class CameraScreen extends Component {
    static navigationOptions = {
        title: '',
    };

    state = {
        hasCameraPermission: null,
        hasCameraRollPermission: null,
        type: Camera.Constants.Type.back,
        flashIcon: 'ios-flash-off',
        flash: Camera.Constants.FlashMode.off,
        ratio: '16:9',
    };

    deviceHeight = Math.ceil(Dimensions.get('screen').height);

    deviceWidth = Math.floor(Dimensions.get('screen').width);

    aspectRatio = this.greatestCommonDivisor(this.deviceWidth, this.deviceHeight);

    widthRatio = this.deviceWidth / this.aspectRatio;

    heightRatio = this.deviceHeight / this.aspectRatio;

    DESIRED_RATIO = '16:9';

    constructor(props) {
        super(props);
        this._pickImage = this._pickImage.bind(this);
        this._snap = this._snap.bind(this);
    }


    componentDidMount() {

        const on_init = this.props.navigation.getParam('on_init');
        if (!on_init) {
            this._pickImage();
        }
        if (on_init.toLowerCase() === 'camera') {
            this._snap();
        } else {
            this._pickImage();
        }
    }

    goBack() {
        const options = getToastDefaultConfig();
        const text = t('general:noCameraPermissions', {});
            Toast.show(t(text, {}), {
                backgroundColor: Colors.red,
                ...options
            });
            this.props.navigation.goBack();
            // return <Text> No access to camera</Text>;
    }

    _snap = async () => {
        try {
            const {status} = await Camera.requestPermissionsAsync();
            if (status !== 'granted') {
             this.goBack();
            }
            const result = await ImagePicker.launchCameraAsync({allowsEditing: true});
            if (!result.cancelled) {
                const image = await this.imageCompress(result);
                this.props.setProfilePicture(image.uri,false);
            }
            this.props.navigation.goBack();
        } catch (error) {
        }
    };

    _pickImage = async () => {
        const {status} = await MediaLibrary.requestPermissionsAsync();
        if (status !== 'granted') {
            this.goBack();
        }
        this.setState({hasCameraRollPermission: status === 'granted'}, async () => {
            if (this.state.hasCameraRollPermission) {
                const result = await ImagePicker.launchImageLibraryAsync({allowsEditing: true});
                if (!result.cancelled) {
                    const image = await this.imageCompress(result);
                    this.props.setProfilePicture(image.uri,true);
                }
                this.props.navigation.goBack();
            }
        });
    };

    imageCompress =  async(image) => {
        return  await ImageManipulator.manipulateAsync(
            image.localUri || image.uri,
            [],
            { compress: 0.8, format: ImageManipulator.SaveFormat.JPEG }
        );
    }

    prepareRatio = async () => {
        if (Platform.OS === 'android' && this.camera) {
            const ratios = await this.camera.getSupportedRatiosAsync();
            // See if the current device has your desired ratio, otherwise get the maximum supported one
            // Usually the last element of "ratios" is the maximum supported ratio
            const ratio = ratios.find(r => r === this.DESIRED_RATIO) || ratios[ratios.length - 1];
            this.setState({ratio});
        }
    };

    greatestCommonDivisor(a, b) {
        return b === 0 ? a : this.greatestCommonDivisor(b, a % b);
    }

    render() {
        return (
            <View style={{flex: 1,alignItems:'center',justifyContent:'center'}}><ActivityIndicator size='large'/></View>
        );
    }
}

const styles = StyleSheet.create({
    containerFlex: {
        flex: 1,
    },
    containerFlexCentered: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
});


export default withNavigation(CameraScreen);
