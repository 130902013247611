import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        fill="#191919"
        {...props}
    >
        <Path d="M25 2C12.36 2 2 11.36 2 23c0 4.95 1.906 9.484 5.031 13.063H7c.39.656.441 1.437.219 2.406-.223.968-.723 2.058-1.313 3.062s-1.25 1.91-1.781 2.625c-.266.356-.508.657-.688.907-.09.124-.148.253-.218.374-.07.122-.219.114-.219.657 0 .414.164.922.563 1.312.398.39.972.594 1.624.594 4.61 0 8.805-3.426 12-5.313C19.637 43.5 22.25 44 25 44c12.64 0 23-9.36 23-21S37.64 2 25 2Zm0 2c11.66 0 21 8.547 21 19s-9.34 19-21 19c-2.703 0-5.285-.473-7.656-1.313l-.438-.156-.375.25C13.063 42.766 8.887 45.965 5.25 46c.137-.188.27-.36.469-.625a32.326 32.326 0 0 0 1.906-2.844c.652-1.11 1.27-2.34 1.563-3.625.292-1.285.257-2.66-.47-3.875l-.062-.093-.031-.063C5.735 31.609 4 27.492 4 23 4 12.547 13.34 4 25 4Zm-8.656 8a1.005 1.005 0 0 0-.782.688l-3.03 8.75a1 1 0 0 0-.188.562l-1.281 3.688a1.002 1.002 0 0 0 1.14 1.425.999.999 0 0 0 .735-.8L14.094 23h4.812l1.157 3.313a.999.999 0 1 0 1.875-.625l-4.5-13A.999.999 0 0 0 16.343 12Zm.156 4.031L18.219 21H14.78Zm16.406 2.938c-.043.008-.086.02-.125.031A1.002 1.002 0 0 0 32 20v2h-5.187c-.551.05-.958.543-.907 1.094.051.55.543.957 1.094.906h8.719a13.494 13.494 0 0 1-3.313 5.563c-1.703-1.68-2.468-3.094-2.468-3.094a1 1 0 1 0-1.75.968s.863 1.56 2.687 3.407c-1.137.851-2.5 1.64-4.188 2.218a.999.999 0 1 0 .625 1.876 16.66 16.66 0 0 0 5.032-2.75c1.152.96 2.527 1.91 4.218 2.718a1.007 1.007 0 0 0 .876-1.812 18.501 18.501 0 0 1-3.594-2.25A16.022 16.022 0 0 0 37.78 24H39c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496h-5v-2a1 1 0 0 0-1.094-1.031Z" />
    </Svg>
)

export default SvgComponent
