import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {AppText} from "../AppText";
import {t} from "../../../services/i18n";
import {globalStyles} from "../../../styles/Global";
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";
import {
    EXTRA_HUGE_ICON_SIZE, getAchNamingTranslations,
    HUGE_ICON_SIZE, isWeb, MEDIUM_ICON_SIZE,
    SMALL_ICON_SIZE
} from "../../../helpers/functions";
import {Icons8Generator} from "../Icons8Generator";


export const PaymentMethodCard = props => {
    const {userBoxToken, selected, onPress} = props;
    const cardName = userBoxToken.card_name.toLowerCase();
    const isAch = cardName.includes('ach');
    const header = !isAch ? userBoxToken.card_name : t('screens:Purchase:ach', {});
    const details = !isAch ? userBoxToken.exp_date : getAchNamingTranslations(cardName);
    const isSelected = userBoxToken === selected;

    return (
        <TouchableOpacity onPress={() => onPress(userBoxToken)}
                          style={[
                              globalStyles.simpleCardContainer,
                              !isWeb() && globalStyles.cardsShadow,
                              globalStyles.centerItems,
                              globalStyles.flexDirectionRowSpaceBetween
                              , {minHeight: verticalScale(70)}
                              , isWeb() && {borderWidth: 1, borderColor: Colors.graySeparator, minHeight: '1em', width: '100%', paddingVertical: '0.5em', gap: '1em', marginBottom: '0.5em', paddingHorizontal: '1em'}
                          ]}>
            <View style={[{width: isWeb() ? 'fit-content' : '25%'}, globalStyles.centerItems]}>
                <Icons8Generator name={isWeb() ? 'credit-card' : isAch ? 'bank' : 'card'} size={isWeb() ? MEDIUM_ICON_SIZE : HUGE_ICON_SIZE}/>
            </View>
            <View style={{flex: 1}}>
                <AppText style={[globalStyles.grayText, isWeb() && {fontSize: '0.8rem'}]}>{header}</AppText>
                <AppText style={[globalStyles.marginTopSm, isWeb() && {fontSize: '0.8rem'}]}>{t('screens:Purchase:cardDetails', {
                    number: userBoxToken.last_credit_card_digits,
                    details
                })}</AppText>
            </View>
            <View style={[{paddingHorizontal: isWeb() ? 0 : scale(15)}]}>
                <View style={[globalStyles.centerItems, {
                    width: isWeb() ? '1.5em' : scale(20),
                    height: isWeb() ? '1.5em' : scale(20),
                    backgroundColor: isSelected ? Colors.brandedGreen : Colors.white,
                    borderWidth: isSelected ? 0 : 1,
                    borderColor: Colors.separatorCircleGray,
                    borderRadius: scale(20) / 2
                }]}>
                    <Icons8Generator name={'check'} size={SMALL_ICON_SIZE} fill={Colors.white}/>
                </View>
            </View>
        </TouchableOpacity>
    )
}
export const SelectPaymentMethodCard = props => {
    const {object, selected, onPress, iconName, text} = props;
    return (
        <TouchableOpacity onPress={() => onPress(object)}
                          style={[
                              globalStyles.simpleCardContainer,
                              !isWeb() && globalStyles.cardsShadow,
                              globalStyles.centerItems,
                              globalStyles.flexDirectionRowSpaceBetween
                              , {minHeight: verticalScale(85), marginBottom: verticalScale(15)},
                          isWeb() && {width: '100%', borderWidth: 1, borderColor: Colors.graySeparator, minHeight: '3em'}]}>
            <View style={[{width: isWeb() ? '15%' : '30%'}, globalStyles.centerItems]}>
                <Icons8Generator name={iconName} size={isWeb() ? MEDIUM_ICON_SIZE : EXTRA_HUGE_ICON_SIZE}/>
            </View>
            <AppText style={[{flex: 1}, isWeb() && {fontSize: '1rem'}]}>{text}</AppText>
            <View style={[{paddingHorizontal: isWeb() ? '1em' : scale(15)}]}>
                <View style={[globalStyles.centerItems, {
                    width: isWeb() ? '2em' : scale(20),
                    height: isWeb() ? '2em' : scale(20),
                    backgroundColor: selected ? Colors.brandedGreen : Colors.white,
                    borderWidth: selected ? 0 : 1,
                    borderColor: Colors.separatorCircleGray,
                    borderRadius: isWeb() ? '50%' : scale(20) / 2
                }]}>
                    <Icons8Generator name={'check'} size={SMALL_ICON_SIZE} fill={Colors.white}/>
                </View>
            </View>
        </TouchableOpacity>
    )
}
