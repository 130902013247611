import React, {useState} from 'react';
import {
    View,
    TouchableOpacity,
    Platform,
    Image
} from 'react-native';
import {Colors} from '../../styles/Colors';
import LoginForm from '../../components/Shared/LoginForm';
import {t} from '../../services/i18n';
import {AppText} from "../../components/Stateless/AppText";
import {Linking} from "react-native-web";
import {scale, verticalScale} from "react-native-size-matters";
import PopupModal from "../../components/Web/Common/PopupModal";
import {isMobile} from "../../helpers/functions";
import {useSelector} from "react-redux";
import PluginFooter from "../../components/Web/PluginFooter";
import EStyleSheet from "react-native-extended-stylesheet";
import ForgotPasswordScreenWeb from "./ForgotPasswordScreenWeb";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {isSiteHebrew} from "../../helpers/ComponentFunctionality";


const LoginScreen = (props) => {
    const {show, setClose, handleSubmit} = props
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const mobile = isMobile();

    const handleClose = () => {
        setClose()
        setTimeout(() => setShowForgotPassword(false), 300)
    }

    return (
        <>
            <PopupModal show={show}
                        setClose={handleClose}
                        style={styles.container}
                        backgroundColor={mobile ? (showForgotPassword ? Colors.white : Colors.white) : Colors.white}
                        overrideStyles={[styles.modal, mobile ? (showForgotPassword ? styles.forgotPasswordMobile : styles.mobileModal) : null]}>
                {showForgotPassword ?
                    <>
                        <TouchableOpacity onPress={() => setShowForgotPassword(false)} style={[{
                            position: 'absolute',
                            top: 20,
                            left: 20
                        }, isSiteHebrew() && {transform: [{rotate: '180deg'}]}]}><Icons8Generator name={'arrow-rtl'}
                                                                                                  size={'1em'}/></TouchableOpacity>
                        <ForgotPasswordScreenWeb show={showForgotPassword}
                                                 setClose={() => setShowForgotPassword(false)}/>
                    </>
                    :
                    <View style={styles.mobileContent}>
                        <View style={[styles.logoWrapper, mobile ? styles.mobileLogoWrapper : null]}>
                            {box?.cloudinary_image && <Image style={[styles.logo, mobile ? styles.mobileLogo : null]} source={box?.cloudinary_image}/>}
                            <AppText
                                style={[styles.clubName, mobile ? styles.mobileClubName : null]}>{box?.name}</AppText>
                        </View>
                        <View style={[mobile ? styles.mobileCtaWrapper : null, {marginTop: '1em'}]}>
                            <View style={mobile ? styles.loginFormMobile : styles.loginForm}>
                                <LoginForm handleSubmit={handleSubmit}/>
                            </View>
                            <View style={[styles.footer, mobile ? styles.mobileFooter : null]}>
                                <TouchableOpacity
                                    onPress={() => setShowForgotPassword(true)}>
                                    <AppText
                                        style={[styles.footerCta, mobile ? styles.mobileFooterCta : null]}>{t('screens:Login:forgot-password', {})}</AppText>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => Linking.openURL('https://arboxassets.s3.eu-central-1.amazonaws.com/Docs/Arbox+Privacy+Policy.pdf')}>
                                    <AppText
                                        style={[styles.footerCta, mobile ? styles.mobileFooterCta : null]}>{t('screens:Login:terms', {})}</AppText>
                                </TouchableOpacity>
                            </View>
                            {mobile && <PluginFooter/>}
                        </View>
                    </View>
                }
            </PopupModal>
        </>
    );

}
export default LoginScreen;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: '4em',
                width: '27em',
                paddingVertical: '3.5em'
            },
            logoWrapper: {
                gap: '1em',
                alignItems: 'center',
            },
            loginForm: {
                marginBottom: '3em'
            },
            loginFormMobile: {
                marginBottom: verticalScale(10)
            },
            footer: {
                alignItems: 'center',
                gap: '1em'
            },
            footerCta: {
                fontSize: '0.8em'
            },
            logo: {
                width: '4em',
                height: '4em',
                borderRadius: '50%',
                backgroundColor: Colors.brandedGraySelected,
            },
            clubName: {
                fontSize: '1.5em',
                fontWeight: 'bold',
                fontFamily: 'coolvetica-rg',
            },
            signUp: {
                borderRadius: 2,
                borderWidth: 1,
                fontSize: 10,
                minHeight: '4em',
                boxSizing: 'border-box',
                width: '100%',
                marginTop: '3em'
            },
            separatorContainer: {
                marginVertical: '2em',
                position: 'relative',
            },
            line: {
                width: '100%',
                backgroundColor: Colors.separatorCircleGray,
                height: 1,
            },
            separatorText: {
                backgroundColor: 'white',
                paddingHorizontal: '1em',
                position: 'absolute',
                top: '-10px',
                left: '50%',
                transform: 'translate(-50%, 0)',
                fontSize: '0.9em',
                color: Colors.separatorCircleGray,
            },
            lineRTL: {
                transform: 'translate(50%, 0)',
            },
            mobileClose: {
                width: '2em',
                height: '2em',
            },
            mobileLogo: {
                width: scale(70),
                height: scale(70),
                padding: scale(4)

            },
            mobileClubName: {
                fontSize: '1.3rem',
                fontWeight: '300'
                // color: Colors.white,
            },
            mobileLogoWrapper: {
                gap: scale(30),
                paddingTop: scale(60),
            },
            mobileModal: {
                paddingVertical: 0,
                paddingHorizontal: 0,
            },
            mobileCtaWrapper: {
                backgroundColor: Colors.white,
                paddingVertical: scale(30),
                paddingHorizontal: scale(30),
                // height: '100%',
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
            },
            mobileContent: {
                width: '100%',
                boxSizing: 'border-box',
            },
            mobileSignUp: {
                marginTop: 0,
                paddingVertical: scale(8)
            },
            mobileSeparatorContainer: {
                marginVertical: scale(30),
            },
            mobileSeparatorText: {
                paddingHorizontal: scale(15),
                fontSize: scale(12),
            },
            mobileFooterCta: {
                fontSize: scale(12)
            },
            mobileFooter: {
                gap: verticalScale(15),
                marginBottom: verticalScale(50)

            },
            forgotPasswordMobile: {
                paddingHorizontal: '2em',
                paddingVertical: '2em',
                paddingTop: '4em'
            }
        }
    })
})