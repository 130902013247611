import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgFillForm(props) {
  return (
      <Svg width={32} height={44} viewBox="0 0 32 44" {...props}>
          <G fillRule="nonzero" fill="none">
              <Path
                  d="M28 4H18a2 2 0 11-4 0H4C1.789 4 0 5.789 0 8v32c0 2.211 1.789 4 4 4h24c2.211 0 4-1.789 4-4V8c0-2.211-1.789-4-4-4"
                  fill="#F5C2D1"
              />
              <Path
                  d="M28 41H4c-.551 0-1-.449-1-1V8c0-.551.449-1 1-1h24c.551 0 1 .449 1 1v32c0 .551-.449 1-1 1"
                  fill="#FFF"
              />
              <Path
                  d="M18 4a2 2 0 11-4 0H7v4a2 2 0 002 2h14a2 2 0 002-2V4h-7z"
                  fill="#0052A6"
              />
              <Path
                  d="M16 0c-2.207 0-4 1.793-4 4s1.793 4 4 4 4-1.793 4-4-1.793-4-4-4m0 6a2 2 0 11-.001-3.999A2 2 0 0116 6M6 18h9v2H6v-2zm0 12h9v2H6v-2z"
                  fill="#0052A6"
              />
              <Path
                  fill="#00D181"
                  d="M22.91 20.59L21.5 22 18 18.5l1.41-1.41 2.09 2.09z"
              />
              <Path
                  d="M25.972 17.528L21.5 22l-1.41-1.41 1.41-1.41 3.062-3.062 1.41 1.41zM22.91 32.59L21.5 34 18 30.5l1.41-1.41 2.09 2.09 1.41 1.41z"
                  fill="#21D083"
              />
              <Path
                  fill="#00D181"
                  d="M25.972 29.528L21.5 34l-1.41-1.41 1.41-1.41 3.062-3.062z"
              />
          </G>
      </Svg>
  );
}

export default SvgFillForm;
