import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8LineChart(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M48 3c-1.105 0-2 .895-2 2 0 .137.004.277.031.406.004.008-.004.024 0 .032l-10.468 9.656A1.929 1.929 0 0035 15c-.352 0-.684.094-.969.25L27 11.062V11a1.999 1.999 0 10-4 0c0 .105.016.21.031.313l-7.312 5.812A1.987 1.987 0 0015 17a2.008 2.008 0 00-2 1.813l-9.844 4.562A2.012 2.012 0 002 23a1.999 1.999 0 100 4 2.008 2.008 0 002-1.813l9.844-4.562c.328.23.726.375 1.156.375 1.105 0 2-.895 2-2 0-.105-.016-.21-.031-.313l7.281-5.843c.23.094.484.156.75.156.363 0 .707-.11 1-.281l7 4.219V17a1.999 1.999 0 104 0c0-.137-.004-.277-.031-.406v-.032l10.468-9.656c.18.055.368.094.563.094a1.999 1.999 0 100-4zm0 26c-1.105 0-2 .895-2 2 0 .137.004.277.031.406.004.008-.004.024 0 .032l-10.437 9.656a1.988 1.988 0 00-1.094-.032L26.937 33.5A1.999 1.999 0 0025 31c-1.104 0-1.999.895-1.999 2 0 .105.016.21.031.313l-7.312 5.812A1.987 1.987 0 0015 39c-.625 0-1.164.3-1.531.75l-9.625-1.5A1.99 1.99 0 002 37a1.999 1.999 0 100 4c.625 0 1.164-.3 1.531-.75l9.625 1.5A1.99 1.99 0 0015 43c1.105 0 2-.895 2-2 0-.105-.016-.21-.031-.313l7.281-5.843a1.928 1.928 0 001.25.094l7.563 7.562A1.999 1.999 0 0035 45c1.104 0 1.999-.895 1.999-2 0-.137-.004-.277-.031-.406-.004-.008.004-.024 0-.032l10.468-9.656c.18.055.368.094.563.094a1.999 1.999 0 100-4z" />
    </Svg>
  );
}

export default SvgIcons8LineChart;
