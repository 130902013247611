// @flow
import * as React from 'react';
import {View} from "react-native-web";
import {useEffect, useState} from "react";
import {AppText} from "../../components/Stateless/AppText";
import {useParams} from "react-router";
import {apiAction} from "../../helpers/HTTP";
import {useDispatch, useSelector} from "react-redux";
import {fetchCurrencySymbol, fetchShopAbleItems} from "../../actions/shop";
import ExtendedCardHandler from "../../components/Web/Shop/extendedCards/ExtendedCardHandler";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {isMobile} from "../../helpers/functions";
import {updateSiteSelectedLocation} from "../../actions/minisite";

const SingleShopItemScreen = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const currencySymbol = useSelector(state => state.shop.currencySymbol)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation);
    const [shopItem, setShopItem] = useState(null);
    const [dollars, setDollars] = useState(null);
    const [cents, setCents] = useState(null);
    const mobile = isMobile();

    useEffect(() => {
        if(!currencySymbol && location?.id) {
            dispatch(fetchCurrencySymbol(`shop/currencySymbol/${location.id}`));
        }
        if(location) {
            getCurrentItem();
        }
    }, [location]);

    useEffect(() => {
        if(shopItem && box) {
            handlePrice();
            const newLocation = box.locations_box.find(item => item.id == shopItem.location_box_fk)
            dispatch(updateSiteSelectedLocation(newLocation))
        }
    }, [shopItem, box]);


    const getCurrentItem = async () => {
        const response = await apiAction(`shop/item/${id}/${location.id}`, 'get', null);
        setShopItem(response.data);
    }

    const handlePrice = () => {
        const price = shopItem.price.toString()
        if(price.includes('.')) {
            const split = price.split('.');
            setDollars(split[0])
            setCents(split[1])
        } else {
            setDollars(shopItem.price)
        }
    }

    return (
        <>
            {shopItem && dollars !== null && <View style={[mobile ? styles.cardWrapperMobile : styles.cardWrapper]}><ExtendedCardHandler item={shopItem} dollars={dollars} cents={cents} show={true} setClose={null} isPopup={false}/></View>}
        </>
    );
};

export default SingleShopItemScreen;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            cardWrapper: {
                width: '60%',
                marginHorizontal: 'auto',
                height: 600
            },
            cardWrapperMobile: {
                height: () => `100% - ${EStyleSheet.value('$mobileHeader')}`,
                overflow: 'auto'
            }
        }
    })
})