import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8MaleUser(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2.008c-12.69 0-23 10.31-23 23s10.31 23 23 23 23-10.31 23-23-10.31-23-23-23zm0 2c11.61 0 21 9.39 21 21a20.923 20.923 0 01-6.012 14.705c-1.83-1.343-4.06-2.017-5.949-2.69-1.064-.378-2.02-.753-2.719-1.171-.668-.4-1.04-.813-1.236-1.286-.092-1.146-.073-2.07-.066-3.16.12-.12.276-.205.4-.351.292-.345.59-.764.873-1.235.487-.807.84-1.795 1.04-2.797.302-.154.626-.187.872-.502.455-.58.762-1.396.899-2.533.12-1.004-.203-1.694-.62-2.224.449-1.465 1.003-3.795.815-6.205-.104-1.327-.438-2.662-1.24-3.772-.767-1.06-2.011-1.822-3.604-2.098C28.442 8.541 26.835 8 24.914 8H24.896c-4.302.079-7.078 1.86-8.267 4.576-1.13 2.583-.887 5.799-.076 9.147-.436.53-.778 1.233-.655 2.265.137 1.137.444 1.954.899 2.533.246.315.57.348.873.502.198 1.001.552 1.988 1.039 2.795.284.471.58.89.873 1.235.124.146.28.233.4.353.007 1.09.025 2.013-.066 3.16-.196.472-.567.884-1.234 1.286-.698.42-1.653.797-2.715 1.177-1.887.677-4.116 1.355-5.951 2.688A20.923 20.923 0 014 25.007c0-11.609 9.39-21 21-21zm-.078 5.994c1.844.002 3.004.548 3.322 1.105l.244.428.487.067c1.256.174 1.958.662 2.46 1.357.503.695.783 1.666.868 2.756.17 2.18-.454 4.754-.85 5.945l-.252.756.682.414c-.07-.042.32.189.232.92-.106.89-.334 1.343-.486 1.537-.152.194-.218.17-.201.168l-.824.068-.088.823c-.076.706-.473 1.67-.938 2.441a7.75 7.75 0 01-.687.975c-.208.245-.43.397-.346.353l-.535.28V31c0 1.324-.054 2.407.086 3.95l.011.132.047.125c.394 1.06 1.215 1.806 2.139 2.36.924.553 1.983.952 3.076 1.341 1.801.641 3.678 1.272 5.11 2.203A20.913 20.913 0 0125 46.008c-5.134 0-9.829-1.84-13.475-4.893 1.44-.926 3.32-1.559 5.118-2.203 1.092-.391 2.149-.793 3.07-1.348.921-.554 1.74-1.301 2.133-2.357l.047-.125.011-.133c.139-1.54.086-2.624.086-3.949v-.605l-.535-.282c.084.044-.138-.108-.346-.353a7.7 7.7 0 01-.687-.973c-.465-.771-.862-1.736-.938-2.441l-.088-.823-.824-.068c.016.001-.049.026-.2-.168-.153-.194-.38-.647-.487-1.537-.088-.732.307-.965.232-.92l.635-.385-.186-.72c-.86-3.312-.99-6.325-.105-8.348.884-2.02 2.655-3.303 6.46-3.375z" />
    </Svg>
  );
}

export default SvgIcons8MaleUser;
