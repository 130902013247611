import React from 'react';
import {View, TextInput} from 'react-native';
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";
import {AppText} from "../Stateless/AppText";
import {t} from "../../services/i18n";
import ModalButton from "../ModalButton";
import {apiAction} from "../../helpers/HTTP";
import {connect} from "react-redux";
import date from '../../services/i18n/date';
import moment from 'moment';
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import uuid from 'uuid';

const RMS = ['RM1', 'RM3', 'RM5'];

class UserRmForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            submitting: false,
            form: {
                RM1: undefined,
                RM3: undefined,
                RM5: undefined,
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async submit() {
        if (this._isMounted) {
            this.setState({submitting: true});
            try {
                const res = await apiAction(`logbook/skill`, 'post', {
                    skills_id: this.props.skillId,
                    form: this.state.form
                });
                this.setState({submitting: false});
                this.props.closeDrawer();
            } catch (e) {
                this.setState({submitting: false});
            }
        }
    }

    rmInputs() {
        return (
            RMS.map(item => {
                return (
                    <View
                        style={[globalStyles.flexDirectionColumn, globalStyles.marginTopBetweenElementsBig, globalStyles.centerItems, {width: scale(250 / 3)}]}>
                        <TextInput
                            style={[globalStyles.heeboRegular, globalStyles.directionText, styles.inputStyles]}
                            keyboardType='numeric'
                            onChangeText={(text) => {
                                this.setState((prevState) => ({
                                    ...prevState,
                                    form: {...prevState.form,[item]: text},
                                }))
                            }
                            }
                            placeholder="00.00"
                            value={this.state.form[item]}
                            maxLength={6}  //setting limit of input
                        />
                        <AppText style={globalStyles.marginTopMedium}>{item}</AppText>
                    </View>
                )
            })
        )
    }

    render() {
        return (
            <View>
                <AppText>{date.format(moment(), 'LL')}</AppText>
                <View style={[globalStyles.flexDirectionRow, globalStyles.flexDirectionRowSpaceBetween,{alignItems:'center'}]}>
                    {this.rmInputs()}
                </View>
                <ModalButton
                    onPress={() => this.submit()}
                    type='primary'
                    colors={this.props.whiteLabelProperties.logbookColor}
                    text={t('common:save', {})}
                    textColor={Colors.white}
                    submitting={this.state.submitting}
                />
            </View>
        )
    }
}

const styles = ScaledSheet.create({

    inputStyles: {
        borderColor: Colors.brandedGraySelected,
        borderWidth: 1,
        width: '100%',
        fontSize:scale(17),
        height: verticalScale(50),
        textAlign: 'center',
        color: Colors.black
    }


});

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});


export default connect(
    mapStateToProps,
    null,
)(UserRmForm);



