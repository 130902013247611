import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8CheckedUserMale(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M21.906.406c-3.699.102-6.398 1.18-8 3.281-1.898 2.5-2.293 6.399-1.094 11.5-.5.602-.82 1.399-.718 2.5.3 2.2 1.113 3.102 1.812 3.5.301 1.7 1.102 3.727 2 4.625v3.594c-.601 1.399-2.605 2.196-4.906 3.094-3.8 1.5-8.602 3.406-9 9.406L1.906 43h32.5l-2.218-2H4.093c.8-4 4.32-5.387 7.718-6.688 2.7-1.101 5.293-2.105 6.094-4.406l.094-.218v-5l-.5-.282c-.398-.3-1.395-2.195-1.594-4.093l-.094-.813-.812-.094c-.102 0-.613-.406-.813-1.906-.101-.8.313-1.094.313-1.094l.594-.406-.188-.688c-1.199-4.699-1.008-8.304.594-10.406 1.2-1.601 3.406-2.492 6.406-2.593 1.899 0 3.29.48 3.688 1.28l.218.407.5.094c1.301.199 2.274.82 2.875 1.718 1.5 2.5.606 6.981-.093 9.282l-.188.906.594.406s.414.293.313 1.094c-.2 1.602-.711 1.906-.813 1.906l-.813.094-.093.813c-.2 1.898-1.196 3.894-1.594 4.093l-.5.282v5l.094.218c.898 2.301 3.394 3.305 6.093 4.407 2.801 1.101 5.727 2.273 7.126 4.874l.5.5 1.187-1.5c-1.7-3.199-5.102-4.585-8-5.687-2.3-.898-4.305-1.695-4.906-3.094v-3.593c.898-.899 1.699-2.926 2-4.625.699-.399 1.613-1.301 1.812-3.5.102-1.102-.195-1.899-.593-2.5.601-2 1.789-7.075-.313-10.376-.898-1.398-2.207-2.324-3.906-2.625-.899-1.199-2.79-1.78-5.188-1.78zm26.532 31.5l-.626.782-7.75 9.874-5.406-4.718-.75-.657-1.312 1.5.75.657 6.187 5.406.813.688.656-.813 8.375-10.719.625-.781z" />
    </Svg>
  );
}

export default SvgIcons8CheckedUserMale;
