import React from 'react';
import {connect} from "react-redux";
import {ScrollView, RefreshControl, KeyboardAvoidingView, Image, TouchableOpacity} from 'react-native';
import {View, Icon} from 'native-base';
import PropTypes from 'prop-types';
import SectionHeading from '../../components/SectionHeading';
import FriendsList from '../../components/FriendsList';
import {t} from '../../services/i18n';
import {Colors} from '../../styles/Colors';
import FriendsStatusAction from "../../components/FriendsStatusAction";
import {scale, verticalScale, ScaledSheet} from "react-native-size-matters";
import {fetchFriendProfile, setActiveFriendId} from '../../actions/friend';
import Spinner from 'react-native-loading-spinner-overlay';
import {bindActionCreators} from "redux";
import {hideModal, showModal} from "../../modals/actions"
import {friendRelationAction} from '../../actions/friend'
import {removeFriend, addFriend} from '../../actions/user'
import {removeFeed} from '../../actions/home'

import ScheduleCard from '../../components/ScheduleCard';


import {
    FRIEND_FRIENDSHIP_STATUS,
    FRIENDS_OF_FRIEND_ACTION,
} from '../../constants';

import {globalStyles} from "../../styles/Global";
import AppHeader from "../../components/Stateless/AppHeader";
import UserAvatarCameraEdit from "../../components/UserAvatarCameraEdit";
import {AppText} from "../../components/Stateless/AppText";
import {apiAction} from "../../helpers/HTTP";
import {modalTypes} from "../../modals/modalTypes";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import Toast from "react-native-root-toast";
import {EmptyRow} from "../../components/Stateless/EmptyRow";
import DrawerHandler from "../../components/DrawerHandler";
import ModalButton from "../../components/ModalButton";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import ModalWithTopBarImage from "../../components/ModalWithTopBarImage";
import {getThemeProperty, keys} from "../../styles/Theme";

const balloonsImgURL = require('../../images/balloons.png');

//TODO It seems to be stupid to have this profile, but cant change it easily.
class FriendProfileScreen extends React.Component {
    static navigationOptions = {
        header: null,
        headerTransparent: true
    };

    constructor(props) {
        super(props);
        this.friendAction = this.friendAction.bind(this);
        this.sendBalloons = this.sendBalloons.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.renderFriendDetails = this.renderFriendDetails.bind(this);
        this.scheduleAction = this.scheduleAction.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        this.RBSheet = React.createRef();

        this.state = {
            submittingBalloons: true,
            balloonsSent: false,
            isPhotoPreviewModalVisible: false
        }

    }

    componentDidMount() {
        this.themeColor = getThemeProperty(keys.brandedGreen);
        this.friendId = this.props.navigation.getParam('id');
        this.props.hideModal();
        this.fetchData();

        if (this.RBSheet) {
            this.RBSheet.close();
        }
    }

    async fetchData() {
        const {fetchFriendProfile, setActiveFriendId} = this.props;
        if (this.props.navigation.getParam('id') === this.props.user.id) {
            this.props.navigation.navigate("Profile");
        }
        const res = await fetchFriendProfile(this.props.navigation.getParam('id'));
        if (res.data !== null && res.data.balloons_sent === true) {
            this.setState({balloonsSent: true})
        }
        this.setState({submittingBalloons: false});
        setActiveFriendId(this.props.navigation.getParam('id'));
    }


    friendAction() {
    }

    async sendBalloons() {
        this.setState({submittingBalloons: true});
        const params = {
            friend_users_id: this.props.navigation.getParam('id')
        };
        const response = await apiAction('userToUser/balloons/send', 'post', params);

        this.setState({submittingBalloons: false, balloonsSent: true});
        if (response !== 0) {
            this.props.removeFeed(response);
        }
    }

    onRefresh() {
        this.fetchData();
    }

    toggleModal() {
        this.setState(prevState => ({
            isPhotoPreviewModalVisible: !prevState.isPhotoPreviewModalVisible,
        }));
    }

    scheduleAction() {
        this.props.showModal(modalTypes.MODAL_SIMPLE, cardType, data, 'info', this.props.reducerActionName, this.props.type)
    }


    renderFriendDetails(activeFriend) {
        const status = activeFriend ? activeFriend.our_relation !== null ? activeFriend.our_relation.status : null : null;
        const name = activeFriend ? status === 1 ? activeFriend.full_name : activeFriend.full_name_shorten : null; // render name by friendship status
        return (
            <View style={[globalStyles.centerItems, globalStyles.marginFromStatusBar]}>
                <UserAvatarCameraEdit
                    name={name}
                    src={activeFriend.image}
                    isEditable={false}
                    onPress={activeFriend.image ? this.toggleModal : null}
                />
                <View
                    style={[globalStyles.flexDirectionRow, globalStyles.centerItems, globalStyles.marginTopBetweenElements]}>
                    {status === 1 ?
                        <Icons8Generator name={'user-following'} fill={this.props.whiteLabelProperties.primaryColorText}/> : null}
                    <AppText
                        style={[globalStyles.heeboMedium, {color: this.props.whiteLabelProperties.primaryColorText}, status === 1 ? globalStyles.paddingStart : null]}>{name}</AppText>
                </View>
                {activeFriend.bio ? <AppText
                    style={[globalStyles.heeboRegular, globalStyles.textCenter, {color: this.props.whiteLabelProperties.primaryColorText}]}>{activeFriend.bio}</AppText> : null}
                {status !== 1 ? (
                    <View style={[globalStyles.centerItems, globalStyles.marginTopBetweenElements]}>
                        <FriendsStatusAction
                            friendConnection={activeFriend}
                            textButtons={true}
                            reducerName={FRIEND_FRIENDSHIP_STATUS}
                            displaySmall={false}
                        />
                    </View>
                ) : null}
            </View>
        )
    }

    renderBirthday(activeFriend) {
        if (activeFriend.has_birthday) {
            return (
                <View
                    style={[styles.profileContainer, globalStyles.marginTopBetweenElements, globalStyles.flexDirectionColumn, {
                        minHeight: null,
                        alignItems: 'center'
                    }]}>
                    <Image
                        style={{width: scale(200)}}
                        source={balloonsImgURL}
                        resizeMode="contain"
                    />
                    <AppText
                        style={globalStyles.heeboMedium}>{t('screens:FriendProfile:friend-birthday', {friend: activeFriend.first_name})}</AppText>
                    <ModalButton
                        onPress={this.sendBalloons}
                        type={this.state.balloonsSent ? 'secondary' : 'primary'}
                        colors={Colors.red}
                        regular={true}
                        overrideStyle={{margin: verticalScale(15)}}
                        text={this.state.balloonsSent ? t('screens:FriendProfile:sent-balloons', {}) : t('screens:FriendProfile:send-balloons', {})}
                        textColor={this.state.balloonsSent ? Colors.red : Colors.white}
                        submitting={this.state.submittingBalloons}
                        disabled={this.state.balloonsSent}
                    />
                </View>

            );
        }


    }

    renderFriendConnection(activeFriend) {
        const friendConnection = activeFriend ? activeFriend.friend_connection : null;
        if (friendConnection) {
            return (
                <View>
                    <SectionHeading
                        text={t('headings:friend-friends', {friend: activeFriend.first_name})}/>
                    <FriendsList
                        actionDisabled={false}
                        list={friendConnection}
                        isMyFriends={false}
                        activeFriend={activeFriend}
                        textButtons={false}
                        reducerName={FRIENDS_OF_FRIEND_ACTION}
                        action='friendStatus'
                        displayClub={true}
                        displaySmall={true}
                    />
                </View>
            )
        }
        return null;
    }

    renderBooking(activeFriend) {
        const schedules = activeFriend.schedules;
        if (schedules) {
            return (
                <View>
                    <SectionHeading
                        borderTop={true}
                        iconName='calendar-multiple'
                        text={t('headings:friend-bookings', {friend: activeFriend.first_name})}/>
                    {schedules.length > 0 ? schedules.map(schedule => {
                            return (
                                <ScheduleCard key={schedule.id} listView={true} data={schedule} type='friendProfile'/>
                            )
                        }) :
                        (
                            <View style={globalStyles.appPaddingHorizontal}>
                                <EmptyRow text={t('screens:FriendProfile:no-schedules', {})}
                                          textColor={Colors.grayText}>
                                    <Icon type="MaterialCommunityIcons" name="sleep" style={{color: Colors.grayText}}/>
                                </EmptyRow>
                            </View>
                        )
                    }
                </View>

            )
        }
        return null;

    }

    renderFriendTabs(activeFriend) {
        const {friend} = this.props;
        const status = activeFriend ? activeFriend.our_relation !== null ? activeFriend.our_relation.status : null : null;
        const name = activeFriend ? status === 1 ? activeFriend.full_name : activeFriend.full_name_shorten : null; // render name by friendship status
        return (
            <View>
                {this.renderBirthday(activeFriend)}
                <View
                    style={[styles.profileContainer, globalStyles.marginTopBetweenElements, status !== 1 ? {minHeight: verticalScale(320)} : {}]}>
                    {this.renderBooking(activeFriend)}
                    {this.renderFriendConnection(activeFriend)}
                    {
                        activeFriend.our_relation === null || activeFriend.our_relation.status !== 1 ? (
                            <View style={styles.friendStatus}>
                                <Icons8Generator name={'lock'} width={scale(60)} height={scale(60)}
                                                 fill={Colors.iconGray}/>
                                {activeFriend.our_relation === null ? <AppText style={[
                                    styles.addFriendInfo,
                                    globalStyles.appTextPaddingHorizontal,
                                    globalStyles.directionText,
                                    globalStyles.centerItems,
                                    {textAlign: 'center'}
                                ]}>{t('screens:FriendProfile:add-friend-info', {friend: name})}</AppText> : null}
                            </View>

                        ) : null}
                </View>
            </View>
        )
    }

    //TODO Move to component
    drawer() {
        return (
            <DrawerHandler noMargin={true}
                           noCloseBtn={true}
                           title={t('common:drawer:activities', {})}
                           ref={el => this.RBSheet = el ? el.ref.current : null}>
                <TouchableOpacity onPress={() => this.removeFriend()}
                                  style={[globalStyles.flexDirectionRow, globalStyles.drawerRowHeight]}>
                    <Icons8Generator name={"remove-user"}/>
                    <AppText
                        style={[globalStyles.paddingStartLarge, {color: Colors.friendBirthdayCardBtnColor}]}>{t('screens:FriendProfile:unfriend', {})}</AppText>
                </TouchableOpacity>
            </DrawerHandler>
        )
    }

    async removeFriend() {
        try {
            await this.props.friendRelationAction(FRIEND_FRIENDSHIP_STATUS, this.friendId, 'removeFriend', this.props.user.id);
            //Patch user from users
            this.props.removeFriend(this.friendId);
            this.RBSheet.close();

        } catch (error) {
            Toast.show(t('common:unknownFailure', {}), {
                backgroundColor: Colors.red,
                textColor: Colors.white
            });
        }

    }

    render() {
        const {isLoading, friend} = this.props;
        const friendId = this.props.navigation.getParam('id');
        const activeFriend = friend[friendId];
        const status = activeFriend ? activeFriend.our_relation !== null ? activeFriend.our_relation.status : null : null;
        const name = activeFriend ? status === 1 ? activeFriend.full_name : activeFriend.full_name_shorten : null; // render name by friendship status
        return (
            <KeyboardAvoidingView style={[globalStyles.keyboardAvoidingViewWrapper]} behavior="padding" enabled>
                {this.drawer()}
                <StatusBarWrap/>
                <View
                    style={[{backgroundColor:this.themeColor},globalStyles.flex]}>
                    <View style={globalStyles.appMinimalPaddingHorizontal}>
                        <Spinner visible={isLoading}/>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            refreshControl={(
                                <RefreshControl
                                    refreshing={false}
                                    onRefresh={this.onRefresh}/>)}
                            contentContainerStyle={[globalStyles.statusBarGap, globalStyles.screenBottomPadding]}>
                            {<AppHeader headerColor={this.props.whiteLabelProperties.primaryColorText}>
                                {status === 1 ?
                                    <TouchableOpacity onPress={() => this.RBSheet.open()}>
                                        <Icons8Generator name={'dots-horizontal'} fill={this.props.whiteLabelProperties.primaryColorText}/>
                                    </TouchableOpacity>
                                    : null}
                            </AppHeader>}
                            {activeFriend ? (
                                <View>
                                    {this.renderFriendDetails(activeFriend)}
                                    {this.renderFriendTabs(activeFriend)}
                                </View>

                            ) : null}
                        </ScrollView>
                        {activeFriend && this.state.isPhotoPreviewModalVisible ?
                                    <ModalWithTopBarImage
                                        headerText={activeFriend.first_name}
                                        isVisible={this.state.isPhotoPreviewModalVisible}
                                        toggleModal={this.toggleModal}
                                        imageUrl={activeFriend.image}
                                    /> :  null
                        }
                    </View>
                </View>
            </KeyboardAvoidingView>
        );
    }


}

const mapStateToProps = state => ({
    friend: state.friend.data,
    isLoading: state.friend.isLoading,
    error: state.friend.error,
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,
});

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        setActiveFriendId,
        friendRelationAction,
        removeFriend,
        removeFeed,
        addFriend,
        hideModal,
        showModal,
        fetchFriendProfile,

    }, dispatch);
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(FriendProfileScreen);

FriendProfileScreen.propTypes = {
    friend: PropTypes.instanceOf(Object),
    isLoading: PropTypes.bool,
    error: PropTypes.instanceOf(Object),
    fetchFriendProfile: PropTypes.func,
};

const styles = ScaledSheet.create({

    profileContainer: {
        borderRadius: scale(8),
        backgroundColor: Colors.white,
    },

    userAvatarWrapper: {
        // marginTop: scale(10),
    },
    bottomSaveButtonWrapper: {
        marginHorizontal: scale(10),
        marginVertical: scale(10),
    },
    bottomSaveButton: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomSaveButtonText: {
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: scale(16),
        color: Colors.white,
    },
    friendStatus: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    addFriendInfo: {
        color: Colors.grayText
    }
});


