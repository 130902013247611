/* eslint-disable import/no-named-default */
import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {Text, View, Item, Label} from 'native-base';
import {LinearGradient} from 'expo-linear-gradient';
import isEmpty from 'lodash/isEmpty';
import Spinner from 'react-native-loading-spinner-overlay';

import {
    KeyboardAvoidingView,
    ScrollView,
    TouchableOpacity,
    Platform,
    RefreshControl,
    TextInput,
} from 'react-native';
import {scale, verticalScale, ScaledSheet} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import UserAvatarCameraEdit from '../../components/UserAvatarCameraEdit';
import ProfileFeature from '../../components/ProfileFeature';
import SectionHeading from '../../components/SectionHeading';
import FriendsList from '../../components/FriendsList';
import ClubsList from '../../components/ClubsList';
import {t} from '../../services/i18n';
import {fetchProfile, updateProfile, deleteProfileImage} from '../../actions/user';
import {default as profileFeatureStyles} from "../../components/ProfileFeature/styles";
import {globalStyles} from "../../styles/Global";
import {connectActionSheet} from '@expo/react-native-action-sheet'
import NavigationService from "../../helpers/NavigationService";
import i18n from "../../services/i18n";
import {AppText} from "../../components/Stateless/AppText";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import ModalWithTopBarImage from "../../components/ModalWithTopBarImage";
import {getThemeProperty, keys} from "../../styles/Theme";

class ProfileScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isPhotoPreviewModalVisible: false,
            isLoading: true,
            statusbarF: true,
            isMyFriends: true,
            user: {}
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onOpenActionSheet = this.onOpenActionSheet.bind(this);
        this.renderProfile = this.renderProfile.bind(this);
        this.editButtons = this.editButtons.bind(this);
        this.friendListProps = {
            fetchProfile: fetchProfile,
            actionDisabled: true,
            action: 'friendStatus',
            isMyFriends: true,
            textButtons: false,
            displayClub: true,
        }

    }

    componentDidMount() {
        this.themeColor = getThemeProperty(keys.brandedGreen);
        if (Object.keys(this.props.user).length > 0) {
            this.setState({user: this.props.user});
        } else {
            this.getData();
        }
        this.didFocusListener = this.props.navigation.addListener(
            'didFocus',
            () => {
                this.props.navigation.setParams({
                    tapOnTabNavigator: this.tapOnTabNavigator
                });
            },
        );
    };

    componentWillUnmount() {
        this.didFocusListener.remove();
    }

    tapOnTabNavigator = () => {
        this.forceUpdate();
    };

    onRefresh() {
        this.getData();
    }

    async getData() {
        const user = await this.props.fetchProfile();
        this.setState({user: user.data});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.image !== this.props.user.image) {
            this.setState(prevState => ({...prevState, user: {...prevState.user, image: this.props.user.image}}));
        }
    }

    setValue(value, name) {
        this.setState(prevState => ({...prevState, user: {...prevState.user, [name]: value}}));
    }

    toggleModal() {
        this.setState(prevState => ({
            isPhotoPreviewModalVisible: !prevState.isPhotoPreviewModalVisible,
        }), () => console.log(this.state.isPhotoPreviewModalVisible));

    }

    async save() {
        if (this.props.isInEditMode) {
            try {
                const user = await this.props.updateProfile(this.state.user);
                if (user.data !== null) {
                    this.setState({user: user.data});
                    this.props.onPressEdit();
                }
                //Handle Errors!
            } catch (e) {
            }
        } else {
            this.props.onPressEdit()
        }
    }

    cancel() {
        this.props.onPressEdit();
        this.setState({user: {...this.props.user}});
    }

    onOpenActionSheet = () => {
        let options = ['Cancel', 'Camera', 'Gallery', 'Delete'];
        if (!this.props.user.image) {
            options.pop();
        }
        const destructiveButtonIndex = 3;
        const cancelButtonIndex = 0;
        this.props.showActionSheetWithOptions(
            {
                options,
                cancelButtonIndex,
                destructiveButtonIndex,
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    NavigationService.navigate('Camera', {on_init: 'camera'});
                }
                if (buttonIndex === 2) {
                    NavigationService.navigate('Camera', {on_init: 'gallery'});
                }
                if (buttonIndex === 3) {
                    this.props.deleteProfileImage();
                }
            },
        );
    };

    editButtons() {
        if (this.props.isInEditMode) {
            return (
                <View style={[styles.editHeader]}>
                    <TouchableOpacity onPress={() => this.cancel()}>
                        <Text style={[globalStyles.heeboMedium, {color: this.props.whiteLabelProperties.primaryColorText, fontSize: scale(14)}]}
                              uppercase={false}>{t('screens:Profile:cancel', {})}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => this.save()}>
                        <Text style={[globalStyles.heeboMedium, {color: this.props.whiteLabelProperties.primaryColorText, fontSize: scale(14)}]}
                              uppercase={false}>{t('screens:Profile:save', {})}</Text>
                    </TouchableOpacity>
                </View>
            );
        }
        return null;
    }

    renderProfile() {
        const {user} = this.state;
        return (
            <View
                style={[{backgroundColor: Colors.white}, this.props.isInEditMode ? {...globalStyles.appBottomBorderRadius, ...globalStyles.appTopBorderRadius} : {}]}>
                <SectionHeading
                    text={t('headings:personal-details', {})}
                    iconName="account"
                    onPress={() => this.props.onPressEdit()}
                    isInEditMode={this.props.isInEditMode}
                    borderTop={this.props.isInEditMode}
                />
                {/*BIO!! NOT .. STUIDPY ALL AROUND US*/}
                {this.props.isInEditMode ? (
                    <View style={[profileFeatureStyles.container]}>
                        <View style={profileFeatureStyles.description}>
                            <Item
                                stackedLabel
                                style={[profileFeatureStyles.formItem, profileFeatureStyles.editableInput, {paddingHorizontal: 20}]}>
                                <Label
                                    style={[profileFeatureStyles.label, globalStyles.directionText]}>
                                    {t('screens:Profile:features:bio').toUpperCase()}
                                </Label>
                                <View style={[profileFeatureStyles.inputWrapper]}>
                                    <TextInput
                                        underlineColorAndroid="transparent"
                                        placeholder={t('screens:Profile:features:bio-placeholder')}
                                        placeholderTextColor="grey"
                                        onChangeText={(text) => this.setValue(text, 'bio')}
                                        value={user.bio}
                                        numberOfLines={4}
                                        onFocus={() => this.setState({focus: true})}
                                        onBlur={() => this.setState({focus: false})}
                                        style={[globalStyles.heeboMedium, globalStyles.directionText, profileFeatureStyles.input, this.state.focus ? globalStyles.appFocusInputColor : globalStyles.appInputColor]}
                                        multiline={true}
                                    />
                                </View>
                            </Item>
                        </View>
                    </View>
                ) : null}
                <View>
                    {this.props.isInEditMode ? (
                        <View>
                            <ProfileFeature
                                icon="person"
                                iconType="MaterialIcons"
                                label={t('common:firstName', {})}
                                value={user.first_name}
                                name='first_name'
                                setValue={this.setValue}
                                isInEditMode={this.props.isInEditMode}
                            />
                            <ProfileFeature
                                icon="person"
                                iconType="MaterialIcons"
                                label={t('common:lastName', {})}
                                value={user.last_name}
                                name='last_name'
                                setValue={this.setValue}
                                isInEditMode={this.props.isInEditMode}
                            />
                        </View>
                    ) : null}
                    <ProfileFeature
                        icon="cake"
                        iconType="MaterialIcons"
                        label={t('screens:Profile:features:birthday', {})}
                        value={user.birthday ? new Date(user.birthday) : null}
                        name='birthday'
                        setValue={this.setValue}
                        isInEditMode={this.props.isInEditMode}
                    />
                    <ProfileFeature
                        icon="phone"
                        iconType="MaterialIcons"
                        label={t('screens:Profile:features:phone', {})}
                        value={user.phone}
                        name='phone'
                        setValue={this.setValue}
                        isInEditMode={this.props.isInEditMode}
                    />
                </View>
            </View>
        );

    }

    render() {
        const {isUpdatingProfile, isLoading, isUpdatingImage, tempImage} = this.props;
        const {users_boxes, friend_connection} = this.props.user;
        const {user} = this.state;
        return (
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : null}
                style={[globalStyles.keyboardAvoidingViewWrapper]}>
                <StatusBarWrap backgroundColor={this.themeColor}/>

                <LinearGradient
                    colors={[this.themeColor, this.themeColor]}
                    style={[styles.gradient]}>
                    <View style={globalStyles.flex}>
                        {this.editButtons()}
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            contentContainerStyle={[globalStyles.screenBottomPadding]}
                            refreshControl={(
                                <RefreshControl
                                    refreshing={false}
                                    onRefresh={this.onRefresh}/>)}>
                            {isLoading || isUpdatingProfile ?
                                <Spinner visible={isLoading || isUpdatingProfile}/> : null}
                            <View style={[globalStyles.centerItems, globalStyles.marginFromStatusBar]}>
                                {!(isEmpty(user)) ? <UserAvatarCameraEdit
                                    onPress={user.image ? this.toggleModal : null}
                                    onMaskPress={this.onOpenActionSheet}
                                    name={user.full_name}
                                    src={user.image}
                                    isEditable={true}
                                    isUpdatingImage={isUpdatingImage}
                                    tempImage={tempImage}
                                /> : null}
                                {!this.props.isInEditMode ? (
                                    <View style={globalStyles.centerItems}>
                                        <AppText
                                            style={[globalStyles.heeboMedium, globalStyles.marginTopBetweenTextElements, {
                                                color: this.props.whiteLabelProperties.primaryColorText,
                                                fontSize: scale(16)
                                            }]}>{user.full_name}</AppText>
                                        {user.bio ? <AppText
                                            style={[globalStyles.heeboRegular, globalStyles.textCenter, {color: this.props.whiteLabelProperties.primaryColorText}]}>{user.bio}</AppText> : null}
                                    </View>) : null}
                            </View>
                            <View
                                style={[styles.profileDataContainer, this.props.isInEditMode ? globalStyles.marginTopBetweenElementsBig : {}]}>
                                {users_boxes && !this.props.isInEditMode ? (
                                    <View>
                                        <SectionHeading text={t('headings:your-clubs', {})}
                                                        iconName="silo"
                                                        borderTop={true}
                                        />
                                        <ClubsList list={users_boxes}/>
                                    </View>
                                ) : null}
                                {this.renderProfile()}
                                {friend_connection && !this.props.isInEditMode ? (
                                    <View>
                                        <SectionHeading text={t('headings:your-friends', {})}/>
                                        <FriendsList
                                            {...this.friendListProps}
                                            list={friend_connection}
                                            drawer={false}
                                        />
                                    </View>
                                ) : null}
                            </View>

                        </ScrollView>
                        <View style={{flex: 1}}/>
                        <ModalWithTopBarImage
                            headerText={user.full_name}
                            isVisible={this.state.isPhotoPreviewModalVisible}
                            toggleModal={this.toggleModal}
                            imageUrl={user.image}
                        />
                    </View>
                </LinearGradient>
            </KeyboardAvoidingView>
        );
    }
}

ProfileScreen.propTypes = {
    onPressEdit: PropTypes.func,
    isInEditMode: PropTypes.bool,
    goToCameraScreen: PropTypes.func,
    updateProfile: PropTypes.func,
    fetchProfile: PropTypes.func,
    navigation: PropTypes.object.isRequired,
    user: PropTypes.object,
    isLoading: PropTypes.bool,
    isUpdatingProfile: PropTypes.bool,
    isUpdatingImage: PropTypes.bool,
    tempImage: PropTypes.string,
};

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        fetchProfile,
        updateProfile,
        deleteProfileImage
    }, dispatch);
};


const mapStateToProps = state => ({
    user: state.user.data,
    error: state.user.error,
    isLoading: state.user.isLoading,
    isUpdatingProfile: state.user.isUpdatingProfile,
    isUpdatingImage: state.picture.isLoading,
    tempImage: state.picture.tempImage,
    whiteLabelProperties: state.whiteLabelProperties
});

export default connectActionSheet(connect(
    mapStateToProps,
    mapActionsToProps
)(ProfileScreen));


const styles = ScaledSheet.create({

    container: {
        flex: 1,
    },
    keyboardAvoidingViewWrapper: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    gradient: {
        flex: 1,
    },
    inner: {
        flex: 1,
        justifyContent: "flex-end",
    },
    header: {
        fontSize: 36,
        marginBottom: 48,
    },
    input: {
        height: 40,
        borderColor: "#000000",
        borderBottomWidth: 1,
        marginBottom: 36,
    },
    btnContainer: {
        backgroundColor: "white",
        marginTop: 12,
    },


    headerIconBack: {
        color: Colors.white,
        fontSize: scale(25),
        transform: [{
            scaleX: i18n.isRTL ? -1 : 1,
        }],
    },
    numberOfNotification: {
        fontSize: '6@s',
        color: 'red',
        lineHeight: '6@s',
        padding: 0,
    },
    profileDataContainer: {
        marginVertical: verticalScale(20),
        width: scale(325),
        backgroundColor: Colors.white,
        ...globalStyles.appTopBorderRadius,
        borderRadius: scale(8),
        alignSelf: 'center',
    },
    editHeader: {
        width: scale(350),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: verticalScale(40),
        paddingHorizontal: 15,
        backgroundColor: Colors.transparent,
    },

});
