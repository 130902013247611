import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Eye(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 12C11.668 12 1.25 24.344 1.25 24.344a.998.998 0 000 1.312S11.668 38 25 38s23.75-12.344 23.75-12.344a.998.998 0 000-1.312S38.332 12 25 12zm0 2c2.629 0 5.14.543 7.469 1.375C34.03 17.141 35 19.449 35 22c0 5.535-4.465 10-10 10s-10-4.465-10-10c0-2.55.938-4.86 2.5-6.625C19.836 14.539 22.363 14 25 14zm-10.813 2.844A11.9 11.9 0 0013 22c0 6.617 5.383 12 12 12s12-5.383 12-12c0-1.836-.418-3.594-1.156-5.156 5.648 2.87 9.71 7.035 10.75 8.156C44.969 26.758 35.973 36 25 36S5.031 26.758 3.406 25c1.04-1.125 5.121-5.285 10.781-8.156zM25 17a5 5 0 100 10 5 5 0 000-10z" />
    </Svg>
  );
}

export default SvgIcons8Eye;
