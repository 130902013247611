import React from 'react';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../AppText";
import {View} from 'react-native';
import {ScaledSheet, verticalScale} from "react-native-size-matters";


export const EmptyRow = props => {
    return (
        <View style={[globalStyles.flexDirectionRow, styles.row]}>
            <AppText style={[globalStyles.heeboRegular, props.textColor]}>{props.text}</AppText>
            {props.children}
        </View>
    )
};


const styles = ScaledSheet.create({
    row: {
        height: verticalScale(40),
        alignItems:'center',
        justifyContent: 'space-between'
    }
});




