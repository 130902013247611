// @flow
import * as React from 'react';
import {Image, TouchableOpacity, View} from "react-native-web";
import {AppText} from "../../Stateless/AppText";
import EStyleSheet from "react-native-extended-stylesheet";
import {ImageBackground, Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import {isLocationIL, isMobile} from "../../../helpers/functions";
import {Icons8Generator} from "../../Stateless/Icons8Generator";

import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {t} from "../../../services/i18n";

const SingleShopItem = (props) => {
    const {item, bgIcon, onItemClicked, hideDepartments, backgroundColor, isPopup} = props;
    const [dollars, setDollars] = useState(null);
    const [cents, setCents] = useState(null);
    const shop = useSelector(state => state.shop)
    const mobile = isMobile();
    const isRtl = isLocationIL()
    const BgIcon = bgIcon;

    useEffect(() => {
        const price = item?.price.toString()
        if(price.includes('.')) {
            const split = price.split('.');
            setDollars(split[0])
            setCents(split[1])
        } else {
            setDollars(item?.price)
        }
    }, []);


    const renderCardContent = () => {
        return (
            <>
                <View style={[globalStyles.cardsShadow, styles.itemPhoto, backgroundColor && {backgroundColor: backgroundColor}]}>
                    {item.image ?
                        <Image source={item.image} alt="" style={{width: '100%', height: '100%'}}/>
                        :
                        <ImageBackground source={BgIcon} style={[{width: '100%', height: '100%'}, styles.itemPhoto]} imageStyle={{resizeMode: 'repeat'}}>
                            {item.period_time_unit ?
                                <View style={{alignItems: 'center'}}>
                                    <AppText style={[{fontSize: '1.5rem', fontWeight: 'bold'}]}>{item.period_amount}</AppText>
                                    <AppText style={[{fontSize: '1rem', fontWeight: 'bold'}]}>{item.period_amount > 1 ? t(`common:timePeriods:${item.period_time_unit.toLowerCase()}_plural`,{}) : t(`common:timePeriods:${item.period_time_unit.toLowerCase()}`,{})}</AppText>
                                </View>
                                :
                                item.is_recurring_payment && item.recurring_cycle ?
                                    <View style={{alignItems: 'center'}}>
                                        <AppText style={[{fontSize: '1.5rem', fontWeight: 'bold'}]}>{t(`screens:Shop:card:recurringCycle:${item.recurring_cycle}`, {})}</AppText>
                                        <AppText style={[{fontSize: '1rem', fontWeight: 'bold'}]}>{t('screens:Shop:card:plan',{})}</AppText>
                                    </View>
                                    :
                                    <Icons8Generator name={'shopaholic'} size={'5em'}/>
                            }
                        </ImageBackground>
                    }
                </View>
                <View style={[styles.itemInfo]}>
                    <View>
                        <AppText style={[styles.itemName]}>{item.name}</AppText>
                        {!hideDepartments && <AppText style={[styles.itemCategory, styles.textOverflow]}>{item.departments?.name}</AppText>}
                    </View>
                    <View style={[styles.price]}>
                        <View style={{gap: '0.2em', flexDirection: isRtl ? 'row-reverse' : 'row'}}>
                            <AppText style={[mobile ? styles.dollarPriceMobile : styles.dollarPrice]}>{`${shop.currencySymbol}${dollars}`}</AppText>
                            {cents && <AppText style={[mobile ? styles.centPriceMobile : styles.centPrice]}>{cents}</AppText>}
                        </View>
                    </View>
                </View>
            </>
        )
    }


    return (
        <>
            {
                isPopup ?
                    <TouchableOpacity onPress={() => onItemClicked(item, dollars, cents)} style={[styles.itemContainer, mobile && styles.itemContainerMobile]}>
                        {renderCardContent()}
                    </TouchableOpacity>
                    :
                    <View style={[styles.itemContainer, mobile && styles.itemContainerMobile]}>
                        {renderCardContent()}
                    </View>
            }
        </>
    );

};

export default SingleShopItem;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            itemContainer: {
                fontSize: '0.75rem',
                gap: '0.7em',
                color: Colors.darkGrayContent,
            },
            itemPhoto: {
                width: '12em',
                height: '12em',
                content: '',
                borderRadius: 4,
                alignItems: 'center',
                justifyContent: 'center',
            },
            itemInfo: {
                gap: '0.5em',
                width: '12em',
            },
            itemName: {
                marginTop: '0.2em',
                fontSize: '0.85rem',
            },
            itemCategory: {
                fontSize: '0.85rem',
                color: Colors.labelColor,
                textTransform: 'capitalize',
                alignSelf: 'flex-start'
            },
            price: {
                gap: '0.2em',
                marginTop: '0.4em',
                flexDirection: 'row',
            },
            dollarPrice: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            centPrice: {
                fontSize: '0.7rem',
                fontWeight: 'bold'
            },
            textOverflow: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            itemContainerMobile: {
                margin: 'auto'
            },
            dollarPriceMobile: {
                fontSize: '0.8rem',
                fontWeight: 'bold'
            },
            centPriceMobile: {
                fontSize: '0.7rem',
                fontWeight: 'bold'
            }
        }
    })
})
