import {
    NOTIFICATIONS
} from '../constants';



const initialState = {
    isLoading: false,
    data: {},
    error: null,
    messagesCount: 0,

};

export default function notificationsReducer(state = initialState, action) {
    const notifications = NOTIFICATIONS.toUpperCase(); // just MAKE SURE IT IS UPPER
    switch (action.type) {
        case `${notifications}_PENDING`:
            return {
                ...state,
                isLoading: true
            };
        case `${notifications}_SUCCESS`:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case `${notifications}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                data: []
            };

        default:
            return state;
    }
};
