import i18n from '../services/i18n';
import {Platform} from "react-native";


export const isTextRTLStyles = {
    ...Platform.select({
        ios: {
            writingDirection: i18n.isRTL ? 'rtl' : 'ltr',
        },
        android: {
            // textAlign: i18n.isRTL ? 'right' : 'left',
        },
    }),
  // writingDirection: i18n.isRTL ? 'rtl' : 'ltr',
};

export function getIsRTLStyle(ltrStyle, rtlStyle) {
  return i18n.isRTL ? rtlStyle : ltrStyle;
}


export const horizontalRTLFlip = {
  transform: [{
    scaleX: i18n.isRTL ? -1 : 1,
  }],
};
