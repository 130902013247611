import React from 'react';
import {KeyboardAvoidingView,Platform} from 'react-native';
import {StatusBarWrap} from "../StatusBarWrap";
import {LinearGradient} from 'expo-linear-gradient';
import Spinner from 'react-native-loading-spinner-overlay';
import {getThemeProperty, keys} from "../../../styles/Theme";

export const LinearWrapper = props => {
        // this.themeColor = getThemeProperty(keys.brandedGreen);
    return (
        <KeyboardAvoidingView
            style={{flex: 1}}
            behavior={"padding"}
            enabled={Platform.OS.toLowerCase() === 'ios'}
        >
            <StatusBarWrap
                backgroundColor={props.backgroundStart}
                barStyle={props.barStyle ? props.barStyle : 'light-content'}
            />
            <LinearGradient
                colors={[props.backgroundStart, props.backgroundStart]}
                style={{flex: 1}}>
                <Spinner visible={props.spinner}/>
                {props.children}
            </LinearGradient>
        </KeyboardAvoidingView>
    )
};