

import React  from 'react';
import {globalStyles} from "../../../styles/Global";
import {ProfileLoader} from "../../../loaders";
import {View} from 'react-native';
import uuid from 'uuid';


export const ProfileLoaderContainer = props => {
    return (
        <View style={[globalStyles.appPaddingHorizontal,globalStyles.marginTopBetweenElements]}>
            {[...Array(props.items)].map(x => <ProfileLoader key={uuid.v4()}/>)}
        </View>
    )
};

