import {apiAction} from '../helpers/HTTP';
import {
     FRIEND_CONNECTIONS,
} from '../constants';

export const fetchFriendConnections = (users_ids) => async (dispatch) => {
    const actionType = FRIEND_CONNECTIONS.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`friendConnection/schedule`, 'post', {users_ids});
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};