import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8PadLock(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#18181b" {...props}>
          <Path d="M22.781 0a11.904 11.904 0 00-3.562.531c-6.317 1.961-9.93 8.739-7.969 15.063v.062c.258.711.95 2.961 1.375 4.344H9c-1.645 0-3 1.355-3 3v24c0 1.645 1.355 3 3 3h32c1.645 0 3-1.355 3-3V23c0-1.645-1.355-3-3-3H14.75c-.309-.992-1.238-3.926-1.625-5h.031C11.52 9.723 14.5 4.11 19.781 2.469c5.27-1.637 10.914 1.328 12.563 6.594v.03c.226.794 1.312 4.313 1.312 4.313.09.36.371.645.73.73a.997.997 0 001.176-1.323S34.434 9.171 34.25 8.53V8.5C32.781 3.762 28.602.543 23.937.062 23.552.024 23.172 0 22.782 0zM9 22h32c.555 0 1 .445 1 1v24c0 .555-.445 1-1 1H9c-.555 0-1-.445-1-1V23c0-.555.445-1 1-1zm16 8c-1.7 0-3 1.3-3 3 0 .898.398 1.688 1 2.188V38c0 1.102.898 2 2 2 1.102 0 2-.898 2-2v-2.813c.602-.5 1-1.289 1-2.187 0-1.7-1.3-3-3-3z" />
      </Svg>
  );
}

export default SvgIcons8PadLock;
