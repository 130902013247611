import React from 'react';
import {View, TouchableOpacity, Button, Text} from 'react-native';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";
import {AppText} from "../Stateless/AppText";
import {AppTextMultiLine} from "../Stateless/AppTextMultiLine";
import {FitImageTextRow} from "../Stateless/FitImageTextRow";
import {verticalScale, scale, ScaledSheet} from "react-native-size-matters"
import {withNavigation} from "react-navigation";
import uuid from 'uuid';
import {LogbookWorkoutType} from "../Stateless/LogbookWorkoutType";
import {t} from "../../services/i18n";
import {captureRef} from "react-native-view-shot";
import {openShareDialogAsync} from "../../helpers/share";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {LARGE_ICON_SIZE} from "../../helpers/functions";

const userResultsTime = ['FOR TIME', 'RFT'];

//BAD CODE ON SERVER, FORCES BAD CODE HERE!
@withNavigation
class LogBookCard extends React.PureComponent {


    constructor(props) {
        super(props);

        this.renderTodayWorkout = this.renderTodayWorkout.bind(this);

    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    async captureRefAndShare(refName) {
        const imageUri = await captureRef(this[refName]);
        await openShareDialogAsync('file://' + imageUri);
    }

    renderShareCardButton = (uniqueRef) => (
        <TouchableOpacity style={[{marginBottom: scale(10)}, globalStyles.avatarsRow, globalStyles.centerItems]} onPress={() => this.captureRefAndShare(uniqueRef)}>
            <Text style={[{color: Colors.brandedBlue}, globalStyles.marginByRTL]} >{t("common:share")}</Text>
            <Icons8Generator name="forward-arrow" fill={Colors.brandedBlue} width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />
        </TouchableOpacity>
    )

    renderTodayWorkout = () => {
        const data = this.props.data[0][0];
        const box = data.boxes;
        const boxCategories = data.box_categories;
        const uniqueRef = uuid.v4();

        return (
                <View key={uniqueRef}>
                    <View collapsable={false} style={[this.props.screen === 'home' ? globalStyles.card : [{paddingHorizontal: scale(20)}]]} ref={(view) => {this[uniqueRef] = view}}>
                        {this.props.screen === 'home' ? (
                            <View>
                                <FitImageTextRow
                                    imageName={box.name}
                                    imageSize={40}
                                    src={box.cloudinary_image}
                                    upperText={box.name}
                                />
                                <AppText
                                    style={[globalStyles.heeboRegular, globalStyles.subTitle, globalStyles.marginTopBetweenTextElements, {color: Colors.grayText}]}>{t('screens:Logbook:dailyWorkout', {})}</AppText>
                                <AppText
                                    style={[globalStyles.heeboRegular, globalStyles.title, {marginVertical:verticalScale(3)}]}>{boxCategories.name}</AppText>
                            </View>
                        ) : null}
                        {this.props.data.map((boxSections) => {
                            let once = true;
                            return boxSections.map((boxSection, index) => {
                                let section_user_result = null;
                                if (once) {
                                    section_user_result = this.getSectionUserResults(boxSections);
                                    once = false;
                                }
                                return this.boxSection(boxSections, boxSection, section_user_result, index);
                            })
                        })}
                    </View>
                    {this.renderShareCardButton(uniqueRef)}
                </View>
        )
    };

    boxSection(boxSections, boxSection, section_user_result, index) {
        let header = null;
        let body;
        if (index === 0) {
            header = this.workoutSectionHeader(boxSection);
        }
        body = this.sectionBody(boxSections, boxSection, index);
        return [header, section_user_result, body];
    }

    getSectionUserResults(boxSections) {
        const boxSection = boxSections.find(item => item.user_result);
        const user_result = boxSection ? boxSection.user_result : null;
        if (user_result) {
            let workoutType = boxSection.workout_type !== null ? boxSection.workout_type.name : null;
            if (!workoutType)
                return null;
            workoutType = workoutType.replace('_', ' ').toUpperCase();

            if (userResultsTime.includes(workoutType)) {
                return (
                    <View style={styles.row}>
                        <AppText style={globalStyles.heeboRegular}>TIME</AppText>
                        <AppText
                            style={globalStyles.heeboRegular}>{this.getTotalTime(user_result.total_time)} {this.getRxOrScale(user_result)}</AppText>
                        <View style={globalStyles.absoluteSeparator}/>
                    </View>
                )
            }
            if (workoutType === 'AMRAP') {
                let extraReps = 0;
                boxSections.filter(item => {
                    if (item.user_result) {
                        extraReps += item.user_result.extra_reps;
                    }
                });
                return (
                    <View style={styles.row}>
                        <AppText style={globalStyles.heeboRegular}>ROUNDS</AppText>
                        <AppText style={globalStyles.heeboRegular}>{user_result.total_rounds} + {extraReps}</AppText>
                        <View style={globalStyles.absoluteSeparator}/>
                    </View>
                )
            }
        }
        return null;
    }

    getRxOrScale(user_result) {
        if (user_result.is_rx === 1)
            return 'RX';
        return 'SCALED';
    }


    getTotalTime(time) {
        if (time === 0)
            return null;
        return `${this.viewableNumber(time)} MIN,`;
    }

    viewableNumber(num) {
        const min = Math.floor(num / 60);
        const sec = num - min * 60;
        if (sec !== 0)
            return min + ':' + sec.toString().padStart(2, '0');
        return min;
    }

    sectionBody(boxSections, section, index) {
        const user_result = section.user_result;
        const value = user_result && user_result.scales ? user_result.scales.name : section.scales ? section.scales.skills.display_name : null;
        const skill_reps = section.skill_reps ? section.skill_reps : null;
        const rounds = section.rounds ? section.rounds : null;
        const weight = this.props.user.gender === 'male' ? section.skill_weight_male_rx : section.skill_weight_female_rx;
        const note = boxSections.length - 1 === index && user_result && user_result.your_note;
        return (
            <View>
                <View>
                    {skill_reps || value ? (
                        <View style={styles.row}>
                            <View style={globalStyles.flexDirectionRow}>
                                {skill_reps || rounds ? <AppText
                                    style={globalStyles.heeboRegular}>{skill_reps} {rounds ? skill_reps ? '* ' + rounds + ' ' : rounds + ' * ' : null}</AppText> : null}
                                {value ? <AppText style={globalStyles.heeboRegular}>{value}</AppText> : null}
                            </View>
                            <View style={globalStyles.flexDirectionRow}>
                                {user_result ? this.handleUserResult(section) : weight ?
                                    <AppText>Weight: {weight}</AppText> : null}
                            </View>
                        </View>) : null}
                    {section.comment && boxSections.length - 1 === index ? (
                        <View style={styles.rowComment}>
                            <AppTextMultiLine forceText={true} key={uuid.v4()} value={section.comment}/>
                            <View style={[globalStyles.absoluteSeparator, {top: 0}]}/>
                        </View>
                    ) : null}
                    {note ? this.yourNote(user_result.your_note) : null}
                </View>
                {note ? null : (<View style={globalStyles.absoluteSeparator}/>)}
            </View>
        )
    }

    yourNote(note) {
        return (
            <View>
                <View key={uuid.v4()} style={styles.headerRow}>
                    <View style={styles.headerRowFix}>
                        <AppText style={[globalStyles.heeboRegular, globalStyles.subTitle]}>{t('screens:Logbook:yourNotes', {})}</AppText>
                    </View>
                </View>
                <View style={styles.rowComment}>
                    <AppTextMultiLine key={uuid.v4()} value={note}/>
                </View>
            </View>

        )
    }

    handleUserResult(section) {
        const user_result = section.user_result;
        const skill_weight = user_result.skill_weight !== 0 ? user_result.skill_weight : null;
        return skill_weight ? (<AppText
            style={[globalStyles.heeboRegular, globalStyles.subTitle]}>Weight: {skill_weight}</AppText>) : null;
        //Decide by section props.


    }

    workoutSectionHeader(sectionHeader) {
        const boxSectionHeader = sectionHeader.box_sections.name;
        const titleBoxSection = sectionHeader.title_section_box !== null ? sectionHeader.title_section_box.title : null;
        return (
            <View key={uuid.v4()} style={[styles.headerRow,this.props.noBorders ? {} : globalStyles.appTopBorderRadius]}>
                <View style={[styles.headerRowFix]}>
                    <View style={[globalStyles.flexDirectionRow]}>
                        <AppText style={[globalStyles.heeboRegular, globalStyles.subTitle]}>{boxSectionHeader}</AppText>
                        {titleBoxSection ? (
                            <AppText
                                style={[globalStyles.heeboRegular, globalStyles.subTitle]}>\ {titleBoxSection}</AppText>
                        ) : null}
                    </View>
                    <LogbookWorkoutType textColor={Colors.black} section={sectionHeader}/>
                </View>
            </View>
        )
    }


    render() {
        return (
                this.renderTodayWorkout()
            )

    }
}


const styles = ScaledSheet.create({
    row: {
        ...globalStyles.flexDirectionRowSpaceBetween,
        ...globalStyles.centerStartItems,
        alignItems:'center',
        height: verticalScale(40),
        width: '100%',
        backgroundColor: Colors.white,
    },
    headerRow: {
        ...globalStyles.flexDirectionRow,
        height: verticalScale(40),
        //CRAP Solution
        width: scale(331),
        marginHorizontal: -verticalScale(20),
        backgroundColor: Colors.brandedLightText
    },
    headerRowFix: {
        ...globalStyles.flexDirectionRowSpaceBetween,
        alignItems:'center',
        paddingHorizontal: verticalScale(20),
        width: '100%'
    },
    rowComment: {
        ...globalStyles.flexDirectionRow,
        ...globalStyles.centerStartItems,
        minHeight: verticalScale(40),
        paddingVertical: verticalScale(10),
        backgroundColor:Colors.white,
        width:'100%'
    }
});


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(LogBookCard);
