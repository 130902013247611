import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgCake(props) {
  return (
      <Svg width={28} height={43} viewBox="0 0 28 43" {...props}>
          <G fillRule="nonzero" fill="none">
              <Path
                  d="M13.001 11.995v3.003a1 1 0 101.998 0v-3.003c-.319.083-.65.141-.999.141s-.678-.058-.999-.141z"
                  fill="#FC374A"
              />
              <Path
                  d="M18 8.161c0-2.236-4-8.096-4-8.096s-4 5.859-4 8.096a4.03 4.03 0 003.001 3.904v-1.883c0-.558.447-1.011.999-1.011.554 0 .999.453.999 1.011v1.883A4.03 4.03 0 0018 8.161z"
                  fill="#FFB300"
              />
              <Path d="M12 10.135a2 2 0 114 .003 2 2 0 01-4-.003z" fill="#FF9800" />
              <Path
                  d="M14 9.136a1 1 0 00-.999.999v1.86c.321.083.65.141.999.141s.68-.058.999-.141v-1.86A.997.997 0 0014 9.136z"
                  fill="#E65100"
              />
              <Path
                  d="M18 35.999A2 2 0 0116.002 38h-4.004A2 2 0 0110 35.999L9.964 15.99c0-1.104.896-1.998 1.998-1.998h4.004c1.104 0 1.998.894 1.998 1.998L18 35.999z"
                  fill="#FF3345"
              />
              <Path
                  d="M26 41a2 2 0 01-2 2H4a2 2 0 01-2-2L1 28a2 2 0 012-2h22a2 2 0 012 2l-1 13z"
                  fill="#0052A6"
              />
              <Path
                  d="M28 27c0 4-6.268 5-14 5S0 31 0 27s6-5 14-5 14 1 14 5z"
                  fill="#F5C2D1"
              />
              <Path
                  d="M0 27c.531 2.438 3.438 3.375 4.063 6.139.18.796.532 2.412 1.326 2.787.611.288 1.078.115 1.313-.488.479-1.23.299-2.75 1.736-2.75 1.563 0 1.979 1.254 2.361 2.638.243.881.687 1.782 1.479 2.347 1.33.949 2.791-.428 3.475-1.619.813-1.415.987-4.09 3.243-3.227C19.703 33.098 20.237 34 21 34c1 0 1.527-.641 2.001-1.267 2.043-2.7 2.615-1.917 3.778-3.08C27.779 28.653 28 27 28 27H0z"
                  fill="#F5C2D1"
              />
              <Path
                  d="M8 24a1 1 0 100 2 1 1 0 000-2zm8 4a1 1 0 100 2 1 1 0 000-2z"
                  fill="#656565"
              />
              <Path
                  d="M15 24a1 1 0 100 2 1 1 0 000-2zm-3 2a1 1 0 100 2 1 1 0 000-2z"
                  fill="#0052A6"
              />
              <Path d="M20 24a1 1 0 100 2 1 1 0 000-2z" fill="#FF3345" />
          </G>
      </Svg>
  );
}

export default SvgCake
