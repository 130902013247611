import * as React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {View, TouchableOpacity, Text, ActivityIndicator} from 'react-native';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {scale, ScaledSheet} from 'react-native-size-matters';
import {Icon, Button} from 'native-base';
import {bindActionCreators} from 'redux'

import {friendRelationAction} from '../../actions/friend'
import {t} from '../../services/i18n';
import {Colors} from "../../styles/Colors";
import i18n from "../../services/i18n";
import {isTextRTLStyles} from "../../styles/Layout";
import {apiAction} from "../../helpers/HTTP";
import {globalStyles} from "../../styles/Global";
import {getThemeProperty,keys} from "../../styles/Theme";


// TODO: mocked onPress while adding/removing friend and clicking on club
class ActionOnFriend extends React.PureComponent {
    state = {
        buttonDisabled: false,
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.textButton = '';
        this.buttonStyles = {'backgroundColor': Colors.brightSkyBlue};
        this.textStyles = {'color': Colors.white};
        this.iconStyles = {};
        this.actionIconName = '';
        this.buttons = true;
        this.icons = true;

        this.friendAction = this.friendAction.bind(this);

    }


    async componentDidMount() {
        this._isMounted = true;

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async friendAction() {
        const {friendConnection, action} = this.props;
        //NOTE THAT ITS NOT ALWAYS FRIEND CONNECTION! CAN BE ANY OBJECT!
        try{
            if (this.action !== null) {
                if (this._isMounted) {
                    this.setState({buttonDisabled: true});
                    switch (action) {
                        case 'inviteToSchedule': {
                            const params = {
                                schedule_id: this.props.actionId,
                                friend_users_id: this.props.user.id === friendConnection.friend_users_id ? friendConnection.users_id : friendConnection.friend_users_id
                            };
                            const response = await apiAction('userToUser/schedule/invite', 'post', params);
                            if (response === true || response === 'OK') {
                                friendConnection.invite_status = 'invited';
                            }
                            break;
                        }
                        case 'thanksForBalloons': {
                            const params = {
                                balloons_id: friendConnection.id,
                            };
                            const response = await apiAction('userToUser/balloons/thanks', 'post', params);
                            if (response === true || response === 'OK') {
                                friendConnection.status = 1;
                            }
                            break;
                        }
                        default:
                            break;
                    }

                }
                if (this._isMounted) {
                    this.setState({buttonDisabled: false});
                }
            }
        }catch(error){
            //TODO Handle error!
            this.setState({buttonDisabled: false});
        }
    }

    handleByAction() {
        const {friendConnection, action} = this.props;
        switch (action) {
            case 'inviteToSchedule': {
                this.icons = false;
                switch (friendConnection.invite_status) {
                    case 'booked': {
                        this.textButton = t('modals:BookClass:booked');
                        this.buttonStyles = {backgroundColor: Colors.transparent, borderColor: getThemeProperty(keys.brandedGreen)};
                        this.textStyles = {color: getThemeProperty(keys.brandedGreen)};
                        this.action = null;
                        break;
                    }
                    case 'invited': {
                        this.textButton = t('modals:BookClass:invited');
                        this.buttonStyles = {backgroundColor: Colors.transparent, borderColor: getThemeProperty(keys.brandedGreen)};
                        this.textStyles = {color: getThemeProperty(keys.brandedGreen)};
                        this.action = null;
                        break;
                }
                    case 'invite': {
                        this.textButton = t('modals:BookClass:invite');
                        this.buttonStyles = {borderWidth: 0,backgroundColor: getThemeProperty(keys.brandedGreen)};
                        this.action = 'invite';
                        break;
                    }
                }
                break;
            }
            case 'thanksForBalloons': {
                this.icons = true;
                switch (friendConnection.status) {
                    case 0: {
                        this.textButton = t("screens:Home:cards:balloons-card:modal:thanks", {});
                        this.buttonStyles = {
                            backgroundColor: Colors.transparent,
                            borderColor: Colors.thanksBorderColor,
                            borderWidth: 1,
                            paddingHorizontal: 15
                        };
                        this.textStyles = {'color': Colors.grayText};
                        this.actionIconName = 'heart-outline';
                        this.iconStyles = {color: Colors.thanksHeartColor};
                        this.action = 'thanks';
                        break;
                    }
                    case 1: {
                        this.textButton = t("screens:Home:cards:balloons-card:modal:thanked", {});
                        this.textStyles = {'color': Colors.grayText};
                        this.actionIconName = 'heart';
                        this.iconStyles = {color: 'red'};
                        this.action = null;
                        this.buttonStyles = {backgroundColor: Colors.thanksBackgroundColor, borderWidth: 0, paddingHorizontal: 15};
                        break;
                    }
                    default:
                        break;
                }
                break;
            }
            default:
                break;
        }

    }

    render() {
        // is Profile button design is like isModal, but reducers responses isn't.
        const {textButtons, displaySmall} = this.props;
        this.handleByAction();
        return (
            // is Profile button design is like isModal, but reducers responses isn't.
            textButtons ? (
                    <TouchableOpacity
                        // disabled={this.state.buttonDisabled}
                        // small
                        style={[styles.editButton, {...this.buttonStyles}]}
                        onPress={() => {
                            this.friendAction()
                        }}
                    >
                        {this.icons ? <Icon name={this.actionIconName} type="MaterialCommunityIcons"
                                            style={[styles.editIcon, {...this.iconStyles}]}/> : null}
                        <Text uppercase={false} style={[styles.editText,{color:getThemeProperty(keys.brandedGreen)}, {...this.textStyles}]}>{this.state.buttonDisabled ? null : this.textButton}</Text>
                        {this.state.buttonDisabled ?
                            <ActivityIndicator color={Colors.white} style={globalStyles.activityIndicatorStyle}/> : null}
                    </TouchableOpacity>
                ) :
                (
                    <View>
                        <TouchableOpacity
                            onPress={() => {
                                this.friendAction()
                            }}
                            style={[styles.actionButton]}
                        >
                            {this.state.buttonDisabled ?
                                <ActivityIndicator color={Colors.white} style={globalStyles.activityIndicatorStyle}/> :  <MaterialCommunityIcons
                                    name={this.actionIconName}
                                    size={scale(15)}
                                    color={Colors.brightSkyBlue}
                                />}
                        </TouchableOpacity>
                    </View>
                )

        );
    }
}

ActionOnFriend.propTypes = {
    friendConnection: PropTypes.object,
    textButtons: PropTypes.bool,
    friendRelationAction: PropTypes.func,
    displaySmall: PropTypes.bool,
    reducerName: PropTypes.string,
    action: PropTypes.string,
};

const mapStateToProps = state => ({
    user: state.user.data
});

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        friendRelationAction,
    }, dispatch);
};

export default  connect(
    mapStateToProps,
    mapActionsToProps
)(ActionOnFriend);


const styles = ScaledSheet.create({
    actionButton: {
        borderRadius: 100,
        paddingVertical: scale(11),
        paddingHorizontal: scale(12),
        alignItems: 'center',
        justifyContent: 'center'
    },
    editButton: {
        borderRadius: scale(3),
        borderWidth: 1,
        height: 'auto',
        paddingVertical: '5@s',
        paddingHorizontal: '25@s',
        alignSelf: 'center',
        fontFamily: 'heebo',
        flexDirection: i18n.isRTL ? 'row-reverse' : 'row',
    },
    editText: {
        fontSize: '10@s',
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'heebo',
        ...isTextRTLStyles,
    },
    editIcon: {
        fontSize: '12@s',
        marginLeft: i18n.isRTL ? '6@s' : 0,
        marginRight: i18n.isRTL ? 0 : '6@s',
    }
});
