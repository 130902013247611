import { StyleSheet } from 'react-native';
import { scale } from 'react-native-size-matters';
import { Colors } from '../../styles/Colors';
import i18n from '../../services/i18n';

let styles;
export default (styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    // paddingLeft: scale(30),
    // paddingRight: scale(15),
    justifyContent: 'space-between',
    height: scale(64),
    backgroundColor: '#FFF'
  },
  text: {
    color: Colors.slateGrey,
    fontSize: scale(16),
    marginLeft: scale(15),
  },
  icon: {
    color: Colors.lightGrey,
    marginTop: scale(5),

  },
  sepline: {
    borderBottomColor: 'rgba(250, 250, 250, 1)',
    borderBottomWidth: scale(1),
    height: scale(1)
  },
}));
