import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';

import LoginContainer from '../../containers/LoginContainer';
import ForgotPasswordContainer from '../../containers/ForgotPasswordContainer';
import TermsOfUseScreen from '../../screens/TermsOfUseScreen';

export const LoginStack = createStackNavigator(
  {
    Login: LoginContainer,
    ForgotPassword: ForgotPasswordContainer,
    TermsOfUse: TermsOfUseScreen,
  },
  {
    mode: 'modal',
    headerMode: 'none',
  },
);

LoginStack.navigationOptions = {};
