import React from 'react';
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {View, Icon} from 'native-base';
import * as Linking from 'expo-linking';
import date from '../../../services/i18n/date';
import FitImage from 'react-native-fit-image';
import {TabView} from 'react-native-tab-view';
import {CardLoader, CardsLoader} from "../../../loaders";
import {
    ScrollView,
    TouchableOpacity,
    RefreshControl,
} from 'react-native';
import {ScaledSheet, verticalScale, scale} from 'react-native-size-matters';
import {t} from '../../../services/i18n';

import {globalStyles} from '../../../styles/Global';
import {fetchUserMembershipsByBox} from "../../../actions/memberships";
import {fetchUserFormsByBox} from "../../../actions/boxes";
import {fetchShopAbleItems} from "../../../actions/shop";
import {
    BIG_ICON_SIZE, MEDIUM_ICON_SIZE,
    membershipTextColorByStatus,
} from "../../../helpers/functions";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../../components/Stateless/AppText";
import AppHeader from "../../../components/Stateless/AppHeader";
import {LinearWrapper} from "../../../components/Stateless/LinerWrapper";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import i18n from "../../../services/i18n";
import {getThemeProperty, keys} from "../../../styles/Theme";
import {TAB_VIEW_WIDTH} from "../../../variableConstants";
import {TabBarCustom} from "../../../components/Stateless/TabBarCustom";
import {apiAction} from "../../../helpers/HTTP";
import { GraySeparator } from '../../../components/Stateless/GraySeparator';

class ClubProfileScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            statusbarF: true,
            index: 0,
            routes: [{key: '0', title: t('screens:ClubProfile:tabs:memberships', {})},
                {key: '1', title: t('screens:ClubProfile:tabs:forms', {})},
            ],
        };
        this.membersipRowOptions = ['dates', 'sessions', 'holds', 'futureSchedules', 'membershipScreen'];

        this.goFitnessPunchesScreen = this.goFitnessPunchesScreen.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.formRows = this.formRows.bind(this);

    }

    componentDidMount = async () => {
        // TODO WTF is didFocus and did blur on mounts.
        this.props.fetchUserMembershipsByBox(this.props.navigation.state.params.club.box.id);
        this.props.fetchUserFormsByBox(this.props.navigation.state.params.club.box.id);
    };

    onRefresh() {
        this.props.fetchUserMembershipsByBox(this.props.navigation.state.params.club.box.id);
        this.props.fetchUserFormsByBox(this.props.navigation.state.params.club.box.id);
    }

    goFitnessPunchesScreen = (membership, index) => {
        this.props.navigation.navigate("FitnessPunchesScreen", {
            currentClub: this.props.navigation.state.params.club.box.id,
            membership,
            index
        })
    };


    renderMembershipRows(membership) {
        return (
            this.membersipRowOptions.map((type, index) => {
                let text, iconName, screen = null;
                let iconType = 'MaterialIcons';
                let tabIndex = 0;
                switch (type) {
                    case 'dates': {
                        iconName = 'date-range';
                        if (membership.end === null)
                            text = t('screens:ClubProfile:membershipNoEndDate', {});
                        else if (membership.start === null)
                            text = t('screens:ClubProfile:membershipEndsAt', {endDate: date.format(membership.end, 'LL')});
                        else
                            text = t('screens:ClubProfile:membershipStartsAtEndsAt', {
                                startDate: date.format(membership.start, 'LL'),
                                endDate: date.format(membership.end, 'LL')
                            });
                        break;
                    }
                    case 'sessions': {
                        if (membership.membership_types && membership.sessions_left !== null && membership.membership_types.type === 'session') {
                            iconName = 'two-tickets';
                            text = t('screens:ClubProfile:membershipSessionsLeft', {sessionsLeft: membership.sessions_left});
                        }
                        break;
                    }
                    case 'holds': {
                        if (membership.total_holds !== 0) {
                            iconName = 'pause';
                            text = t('screens:ClubProfile:membershipHasHolds', {holds: membership.total_holds});
                        }
                        break;
                    }
                    case 'futureSchedules': {
                        if (membership.active === 0)
                            break;
                        iconName = 'clock';
                        text = t('screens:ClubProfile:membershipFutureSchedules', {schedules: membership.future_schedules});
                        screen = true;
                        tabIndex = 1;
                        break;
                    }
                    case 'membershipScreen': {
                        iconName = 'to-do';
                        iconType = 'MaterialCommunityIcons';
                        text = t('screens:ClubProfile:membershipScreen', {});
                        screen = true;
                        tabIndex = 0;
                        break;
                    }
                }
                if (screen) {
                    return (
                        <TouchableOpacity key={index} onPress={() => this.goFitnessPunchesScreen(membership, tabIndex)}
                                          style={[globalStyles.appPaddingHorizontal, styles.membershipRow]}>
                            {iconName ? <Icons8Generator name={iconName}/> : null}
                            <AppText
                                style={[globalStyles.heeboRegular, styles.membershipRowText, iconName ? {...globalStyles.paddingStartLarge} : {}]}>{text}</AppText>
                            <View style={globalStyles.itemToFlexEnd}>
                                <Icons8Generator name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>
                            </View>
                        </TouchableOpacity>
                    )
                }
                if (text) {
                    return (
                        <View key={index} style={[globalStyles.appPaddingHorizontal, styles.membershipRow]}>
                            <Icons8Generator name={iconName}/>
                            <AppText
                                style={[globalStyles.heeboRegular, styles.membershipRowText, iconName ? {...globalStyles.paddingStartLarge} : {}]}>{text}</AppText>
                        </View>
                    )
                }
                return null;
            })
        )
    }

    renderBoxDetails() {
        const {club} = this.props.navigation.state.params;
        return (
            <View style={[globalStyles.centerItems]}>
                <FitImage
                    resizeMode="contain"
                    source={{uri: club.box.cloudinary_image ? club.box.cloudinary_image : club.locations_box.logo}}
                    style={[globalStyles.cardRadius, styles.imageContainer]}
                />
                <AppText
                    style={[globalStyles.heeboMedium, globalStyles.marginTopBetweenTextElements, {color: this.props.whiteLabelProperties.secondaryColorText}]}>{club.box.name}</AppText>
                {club.box.bio ? <AppText
                    style={[globalStyles.heeboRegular, globalStyles.textCenter, globalStyles.marginTopBetweenTextElements, {color: this.props.whiteLabelProperties.secondaryColorText}]}>{club.box.bio}</AppText> : null}

                <View
                    style={[globalStyles.flexDirectionRow, globalStyles.cardRadius, globalStyles.marginTopBetweenTextElements, styles.boxContacts]}>
                    <TouchableOpacity disabled={!club.box.email}
                                      onPress={() => Linking.openURL(`mailto:${club.box.email}`)}>
                        <Icons8Generator name={'email'} fill={!club.box.email ? Colors.iconGray : Colors.black}
                                         width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}/>
                    </TouchableOpacity>
                    <TouchableOpacity disabled={!club.box.website} onPress={() => Linking.openURL(club.box.website)}>
                        <Icons8Generator name={'web'} fill={!club.box.website ? Colors.iconGray : Colors.black}
                                         width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}/>
                    </TouchableOpacity>
                    <TouchableOpacity disabled={!club.box.phone}
                                      onPress={() => Linking.openURL(`tel:${club.box.phone}`)}>
                        <Icons8Generator name={'phone'} fill={!club.box.phone ? Colors.iconGray : Colors.black}
                                         width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}/>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    renderMemberships() {
        const {memberships, isLoading} = this.props;

        const linkToProduct = async (membershipType) => {
            try {
                const membershipTypeId = membershipType.id;
                const locationBoxId = membershipType.location_box_fk;
                const response = await this.props.fetchShopAbleItems(`shop/item/${membershipTypeId}/${locationBoxId}`);
                const membership = response.data;
                if(membership) {
                    this.props.navigation.navigate("Product", {membership})
                } else {
                    this.props.navigation.navigate('Shop')
                }
            } catch(err) {
                console.log(err);
            }
        }

        return (
            <View>
                {
                    !isLoading && memberships && memberships.length > 0 ? (memberships.map((membership, index) => (
                        <View key={index.toString()}
                              style={[globalStyles.cardRadius, styles.membershipContainer, index !== 0 ? globalStyles.marginTopBetweenElements : {}]}>
                            <View
                                style={[globalStyles.appTopBorderRadius, globalStyles.appPaddingHorizontal, styles.membershipRow, {
                                    justifyContent: 'space-between',
                                    backgroundColor: Colors.sectionHeaderColor,
                                }]}>
                                <AppText
                                    style={[globalStyles.heeboMedium, styles.membershipRowHeader]}>{membership.membership_types ? membership.membership_types.name : t('screens:ClubProfile:membershipStaffMember', {})}</AppText>
                                <AppText
                                    style={[globalStyles.heeboMedium, {color: membershipTextColorByStatus(membership.active)}]}>{t('common:membership:status' + membership.active, {})}</AppText>
                            </View>

                            {this.renderMembershipRows(membership)}
                            {membership.active === 0 && (
                                <View>
                                    <GraySeparator height={scale(1)}/>
                                    <View style={[globalStyles.cardRadius, globalStyles.flexDirectionRowMarginBottom, {paddingBottom: scale(11)}]}>
                                        <TouchableOpacity onPress={() =>linkToProduct(membership.membership_types)}>
                                            <View style={[globalStyles.flexDirectionRow, globalStyles.centerItems, {alignSelf: 'center'}]}>
                                                <Icons8Generator style={{marginRight: scale(11)}} height={scale(15)} width={scale(15)} fill={Colors.black} name="restart" />
                                                <AppText style={[styles.renewMembershipExpired]}>{t("screens:Home:cards:expired-card:renew")}</AppText>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )}
                        </View>
                    ))) : (
                        <View style={globalStyles.loadingCardContainer}>
                            {
                                !isLoading && memberships && memberships.length === 0 ? null :
                                    [...Array(4)].map((e, index) => <View style={[globalStyles.loadingCard]}
                                                                          key={index}><CardLoader/></View>)
                            }
                        </View>
                    )
                }
            </View>
        );
    }

    //SWITCH TO TEMPLATES!
    renderForms() {
        const {forms, formsLoading} = this.props;
        if (formsLoading) {
            return (
                <CardsLoader items={3}/>
            )
        }
        return forms.map((item, index) => {
            let name;
            let is_done = true;
            let digital_form = null;
            if(Object.prototype.hasOwnProperty.call(item,'action')){
                if(item.sub_action === 'membership_types'){
                    digital_form = item.digital_form;
                }else{
                    digital_form = item.sub_action === 'medical' ? item.digital_form_medical : item.sub_action === 'waiver' ? item.digital_form_waiver : null;
                    //Meaning both
                    if (digital_form === null) {
                        digital_form = Object.prototype.hasOwnProperty.call(item, 'digital_form_medical') ? item.digital_form_medical : item.digital_form_waiver;
                    }
                }
                name = digital_form ? digital_form.name : '';
                is_done = false;
            }else{
              name = item.name;
            }
            return (<View key={index}
                          style={[globalStyles.cardRadius, styles.membershipContainer, index !== 0 ? globalStyles.marginTopBetweenElements : {}]}>
                    <View
                        style={[globalStyles.appTopBorderRadius, globalStyles.appPaddingHorizontal, styles.membershipRow, {
                            justifyContent: 'space-between',
                            backgroundColor: Colors.sectionHeaderColor,
                        }]}>
                        <AppText
                            style={[globalStyles.heeboMedium, styles.membershipRowHeader]}>{name}</AppText>
                        <AppText
                            style={[globalStyles.heeboMedium, styles.membershipRowHeader,!is_done ? {color: Colors.brandedRed} : {}]}>{is_done ? t('screens:ClubProfile:forms:done', {}) :  t('screens:ClubProfile:forms:missing', {})}</AppText>
                    </View>
                    {this.formRows(item,is_done,digital_form)}
                </View>
            )
        })
    }

    //edit-property
    formRows(item,is_done,digital_form) {
      const isDigital = is_done === false;
        return (
            <View style={[globalStyles.flexDirectionColumn, globalStyles.appPaddingHorizontal]}>
                {is_done === true ? <View style={[styles.membershipRow]}>
                    <Icons8Generator name={'today'}/>
                    <AppText
                        style={[globalStyles.heeboRegular, styles.membershipRowText, globalStyles.paddingStartLarge]}>{t('screens:ClubProfile:forms:completedAt', {date: date.format(item.created_at, 'LL')})}</AppText>
                </View> : null}
                <TouchableOpacity onPress={() => is_done ? this.viewFile(item) : this.openDigitalForm(item,digital_form)}
                                  style={[styles.membershipRow]}>
                    <Icons8Generator size={MEDIUM_ICON_SIZE} name={isDigital ? 'edit-property' : 'eye'}/>
                    <AppText
                        style={[globalStyles.heeboRegular, styles.membershipRowText, globalStyles.paddingStartLarge]}>{isDigital ? t('screens:ClubProfile:forms:fillForm',{}): t('screens:ClubProfile:forms:viewForm',{})}</AppText>
                </TouchableOpacity>
            </View>
        )
    }

    openDigitalForm(data,digital_form){
        const {box, locations_box} = data;
        const image = box ? box.cloudinary_image ? box.cloudinary_image : locations_box ? locations_box.logo : null : null;
        this.props.navigation.navigate("DigitalForm", {id: digital_form.id, box, image,onGoBack: () => this.onRefresh()});
    }

    async viewFile(item) {
        const id = item.id;
        const res = await apiAction(`usersBoxes/forms/${id}`, 'get', null);
        if (res) {
            Linking.openURL(res);
        }
    }

    tabs() {
        const {index, routes} = this.state;
        return (
            <TabView
                lazy
                navigationState={{index, routes}}
                initialLayout={{width: scale(TAB_VIEW_WIDTH)}}
                style={globalStyles.marginTopBetweenTextElements}
                renderScene={({route, focused}) => {
                    switch (route.key) {
                        case '0': {
                            return this.renderMemberships();
                        }
                        case '1': {
                            return this.renderForms();
                        }
                        default:
                            return null
                    }
                }}
                onIndexChange={i => this.setState({index: i})}
                renderTabBar={(props) => <TabBarCustom {...props}
                                                       topBorder={false}
                                                       numOfItems={2}
                                                       backgroundColor={this.props.whiteLabelProperties.secondaryColor}
                                                       indicatorColor={Colors.white}
                                                       tabStyleProps={{marginBottom: 8}}
                                                       textProps={{fontSize: scale(15), color: Colors.white}}/>}

            />
        )
    }

    render() {
        return (
            <LinearWrapper
                backgroundStart={getThemeProperty(keys.brandedBlue)}
                backgroundEnd={getThemeProperty(keys.brandedBlue)}
                barStyle="light-content"
                spinner={false}>
                <View style={globalStyles.appMinimalPaddingHorizontal}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[globalStyles.statusBarGap, globalStyles.screenBottomPadding]}
                        refreshControl={
                            <RefreshControl
                                refreshing={false}
                                onRefresh={this.onRefresh}
                            />
                        }>
                        {<AppHeader pop={true} headerColor={this.props.whiteLabelProperties.secondaryColorText}/>}
                        {this.renderBoxDetails()}
                        {this.tabs()}
                    </ScrollView>
                </View>
            </LinearWrapper>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        fetchUserMembershipsByBox,
        fetchUserFormsByBox,
        fetchShopAbleItems
    }, dispatch);
};

const mapStateToProps = state => ({
    forms: state.boxes.forms.data,
    formsLoading: state.boxes.forms.isLoading,
    memberships: state.memberships.data,
    isLoading: state.memberships.isLoading,
    error: state.memberships.error,
    whiteLabelProperties: state.whiteLabelProperties,

});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(ClubProfileScreen);

ClubProfileScreen.propTypes = {
    navigation: PropTypes.object,
    fetchUserMembershipsByBox: PropTypes.func,
    club: PropTypes.object,
    memberships: PropTypes.array,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
};

const styles = ScaledSheet.create({
    renewMembershipExpired: {
        fontFamily: 'heebo',
        color: Colors.black,
        fontSize: verticalScale(14),
        fontWeight: '400',
    },
    keyboardAvoidingViewWrapper: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    gradient: {
        flex: 1,
    },
    imageContainer: {
        width: scale(150),
        height: verticalScale(80),

    },
    boxContacts: {
        backgroundColor: Colors.white,
        height: verticalScale(45),
        alignItems: 'center',
        justifyContent: 'space-around',
        width: scale(330),
    },

    membershipContainer: {
        backgroundColor: Colors.white
    },
    membershipRow: {
        flexDirection: 'row',
        alignItems: 'center',
        height: verticalScale(40),
    },
    membershipRowHeader: {
        color: Colors.grayHeader
    },
    membershipRowText: {
        fontSize: scale(13),
    },
    icon: {
        color: Colors.black,
        fontSize: scale(12)
    }
});

