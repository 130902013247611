import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CameraScreen from '../../screens/CameraScreen';
import { setProfilePicture } from '../../actions/picture';

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      setProfilePicture
    },
    dispatch
  );
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CameraScreen);
