import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from "react-native-web";
import {AppText} from "../../Stateless/AppText";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";

const NumericCounter = (props) => {
    const {min, max, initVal, onChange} = props;
    const [val, setVal] = useState(initVal);

    useEffect(() => {
        onChange(val)
    }, [val]);

    const updateVal = (isPrev) => {
        if(isPrev) {
            if(val > min) {
                setVal(val - 1)
            }
        } else {
            if(val < max) {
                setVal(val + 1)
            }
        }
    }

    return (
        <View style={[styles.wrapper]}>
            <TouchableOpacity onPress={() => updateVal(true)} style={[styles.box]}><AppText style={[styles.text]}>-</AppText></TouchableOpacity>
            <View style={[styles.box]}><AppText style={[styles.text]}>{val}</AppText></View>
            <TouchableOpacity onPress={() => updateVal(false)} style={[styles.box]}><AppText style={[styles.text]}>+</AppText></TouchableOpacity>
        </View>
    );
};

export default NumericCounter;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                flexDirection: 'row'
            },
            box: {
                width: '4em',
                height: '2em',
                borderWidth: 1,
                borderColor: Colors.graySeparator,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1em'
            },
            text: {
                fontSize: '1rem'
            }
        }
    })
})