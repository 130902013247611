import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Website(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2C14.395 2 5.445 9.219 2.797 19h2.086c.75-2.504 1.965-4.8 3.523-6.816 1.707.96 3.684 1.765 5.871 2.382A37.253 37.253 0 0013.398 19h2.04c.203-1.375.48-2.688.808-3.938 2.41.532 5.016.848 7.754.907V19h2v-3.031c2.738-.059 5.344-.371 7.754-.906.328 1.25.605 2.562.809 3.937h2.039a37.254 37.254 0 00-.88-4.434c2.188-.617 4.165-1.421 5.872-2.382A20.914 20.914 0 0145.114 19h2.09C44.553 9.219 35.604 2 25 2zm-1 2.121v9.848a38.042 38.042 0 01-7.188-.836C18.457 8.215 21.063 4.77 24 4.12zm2 0c2.938.649 5.543 4.094 7.188 9.012a38.042 38.042 0 01-7.188.836zm-6.867.73c-1.762 1.86-3.235 4.54-4.297 7.79-1.91-.54-3.637-1.223-5.113-2.016a21.04 21.04 0 019.41-5.773zm11.734 0a21.04 21.04 0 019.41 5.774c-1.476.793-3.203 1.477-5.113 2.016-1.062-3.25-2.535-5.93-4.297-7.79zM3 21l2.54 8h2.327l2.117-5.98L12.102 29h2.277l2.586-8H14.8l-1.633 5.59L11.301 21H8.75l-1.945 5.496L5.199 21zm15.016 0l2.543 8h2.332L25 23.02 27.121 29h2.277l2.586-8h-2.168l-1.628 5.59L26.32 21h-2.554l-1.946 5.496L20.22 21zm14.968 0l2.547 8h2.34l2.121-5.98 2.13 5.98h2.284L47 21h-2.172l-1.64 5.59L41.316 21h-2.562L36.8 26.496 35.187 21zM2.797 31C5.445 40.781 14.395 48 25 48c10.605 0 19.555-7.219 22.203-17h-2.09a20.914 20.914 0 01-3.52 6.816c-1.706-.96-3.683-1.765-5.87-2.382.367-1.403.668-2.88.879-4.434h-2.04a36.508 36.508 0 01-.808 3.938A40.093 40.093 0 0026 34.03V31h-2v3.031c-2.738.059-5.344.371-7.754.907A36.508 36.508 0 0115.438 31h-2.04c.211 1.555.512 3.031.88 4.434-2.188.617-4.165 1.421-5.872 2.382A20.999 20.999 0 014.883 31zM24 36.031v9.848c-2.938-.649-5.543-4.094-7.188-9.012A38.042 38.042 0 0124 36.031zm2 0c2.543.059 4.96.352 7.188.836-1.645 4.918-4.25 8.363-7.188 9.012zM14.84 37.36c1.062 3.25 2.531 5.93 4.293 7.786a21 21 0 01-9.41-5.77c1.476-.797 3.203-1.477 5.117-2.016zm20.324 0c1.91.54 3.637 1.223 5.113 2.016a21 21 0 01-9.41 5.77c1.762-1.856 3.235-4.536 4.297-7.786z" />
    </Svg>
  );
}

export default SvgIcons8Website;
