import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 221.12 221.12"
        {...props}
    >
        <Circle
            cx={110.56}
            cy={110.56}
            r={105.7}
            fill="none"
            stroke="#0554a4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={9.72}
        />
        <Path
            fill="none"
            stroke="#49b97b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={9.72}
            d="M110.56 62.26v39.4"
        />
        <Path
            fill="none"
            stroke="#ee3b49"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={9.72}
            d="m172.66 144.26-50.7-27.4"
        />
    </Svg>
)

export default SvgComponent
