import { StyleSheet } from 'react-native';
import { scale } from 'react-native-size-matters';
import { Colors } from '../../../styles/Colors';

export default StyleSheet.create({
  contentHeader: {
    fontFamily: 'heebo-bold',
    fontSize: scale(14),
    color: Colors.brandPrimary,
    textAlign: 'center',
  },
  contentList: {
    marginTop: scale(30),
    marginBottom: scale(40),
  },
  contentEntry: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: scale(10),
  },
  textInput: {
    width: scale(80),
    height: scale(32),
    borderWidth: scale(1),
    borderColor: Colors.veryLightGrey,
    textAlign: 'right',
    paddingRight: scale(6),
  }
});
