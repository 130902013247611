import * as React from "react";
import Svg, { G, Path, Line, Polyline } from "react-native-svg";

function SvgBrokenBikes(props) {
  return (
<Svg viewBox="0 0 278 273" {...props}>
    <G id="Login-+-Splash-+-Onbroading" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <G id="Error" transform="translate(-49.000000, -288.000000)">
            <G id="01-08-(2)" transform="translate(50.000000, 289.000000)">
                <Path d="M55,185.106062 C66.5759494,184.251801 77.8902954,188.588817 86,197" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M19.3457811,229 C16.7039799,209.324242 29.4506708,190.821212 49,186" id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M27,249 C25.6,247.236181 24.3333333,245.407035 23.2,243.447236 C21.8666667,241.095477 20.8,238.547739 20,236" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M92,203 C98.7241379,213.913386 99.8232759,227.406299 95.0387931,239.311811 C94.9741379,239.510236 94.9094828,239.64252 94.8448276,239.840945 L107,245" id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Line x1="180" y1="262" x2="189" y2="180" id="Path" stroke="#ED3B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="122" y1="244" x2="178" y2="218" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Polyline id="Path" stroke="#ED3B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="97 157.157895 99.0817844 152 116.061338 159.23445"></Polyline>
                <Line x1="115.996283" y1="159.23445" x2="132" y2="166" id="Path" stroke="#ED3B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="120.965989" y1="176.418144" x2="180" y2="200.617543" id="Path" stroke="#0554A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="114.055654" y1="248.100687" x2="114.45053" y2="167" id="Path" stroke="#0554A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Path d="M91.1528269,245.876958 C79.4381625,264.124613 55.0216431,269.487723 36.5940813,257.84585 C34.6196996,256.603178 32.7111307,255.164295 31,253.529201" id="Path" stroke="#0554A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Polyline id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="193 217.081871 207.895141 223 219 212"></Polyline>
                <Line x1="166" y1="171" x2="183.863341" y2="178.217926" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="194.713666" y1="194.864675" x2="227" y2="208" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Path d="M55.2017294,223.559259 C55.6849852,222.304938 57.1347526,221.677777 58.4464469,222.165569 C59.7581412,222.65336 60.3104335,224.116735 59.8271777,225.440741 C59.3439219,226.695062 57.8941545,227.322223 56.5824602,226.834431 C55.2707659,226.34664 54.6494371,224.883265 55.2017294,223.559259 C55.2017294,223.628944 55.2017294,223.628944 55.2017294,223.559259" id="Path" fill="#0554A3" fill-rule="nonzero"></Path>
                <Line x1="63" y1="220" x2="107" y2="179" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="192" y1="250" x2="194" y2="245" id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="168" y1="255" x2="164" y2="251" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="200" y1="256" x2="204" y2="253" id="Path" stroke="#ED3B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Path d="M168,241.5 C168,240.66 168.66,240 169.5,240 C170.34,240 171,240.66 171,241.5 C171,242.34 170.34,243 169.5,243 C169.5,243 169.5,243 169.5,243 C168.66,242.94 168,242.28 168,241.5 L168,241.5" id="Path" fill="#0554A3" fill-rule="nonzero"></Path>
                {/* <Path d="M145,s256.497538 C145,255.641802 145.642857,255 146.5,255 C147.357143,255 148,255.641802 148,256.497538 C148,257.353274 147.357143,257.995076 146.5,257.995076 C146.5,257.995076 146.5,257.995076 146.5,257.995076 C145.642857,258.066387 145,257.353274 145,256.497538" id="Path" fill="#F5C2D1" fill-rule="nonzero"></Path> */}
                <Line x1="0" y1="270.882133" x2="238.828892" y2="270.612092" id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Path d="M223,85 C204.989891,78.6676558 194.209646,59.9970326 197.630921,41" id="Path" stroke="#ED3B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M275,53 C272.001481,74.5605239 252.315556,89.5935497 231,86.6265051" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M200,32.1832291 C208.510417,12.0331511 231.732639,2.5857375 251.920139,11.1742953 C265.642361,16.9880883 274.944444,30.0691225 276,45" id="Path" stroke="#0554A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M234.028239,47.8819887 C233.819428,46.4899158 234.793879,45.2370501 236.116349,45.0282391 C237.508422,44.8194282 238.761287,45.7938793 238.970098,47.1163486 C239.178909,48.4388179 238.274062,49.7612873 236.881989,49.9700982 C235.559519,50.1789092 234.23705,49.2740617 234.028239,47.8819887 C234.028239,47.8819887 234.028239,47.8819887 234.028239,47.8819887" id="Path" fill="#0554A3" fill-rule="nonzero"></Path>
                <Line x1="213" y1="3" x2="221" y2="0" id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Line x1="194" y1="82" x2="186" y2="79" id="Path" stroke="#4ABA7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Line>
                <Path d="M207.26071,52 C205.254302,36.886 215.018821,22.696 230,19" id="Path" stroke="#F5C2D1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
                <Path d="M266.395062,41 C267.316872,46.2897959 267.185185,51.8408163 266,57" id="Path" stroke="#ED3B4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></Path>
            </G>
        </G>
    </G>
</Svg>
  );
}

export default SvgBrokenBikes
