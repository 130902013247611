import {
    CHANGE_LANGUAGE,
    DEVICE_TOKEN,
    HAS_SHOP,
    LOGIN,
    LOGOUT,
    PATCH_AUTH_DETAILS
} from "../constants";

export const initialStateAuth = {
    loggedIn: false,
    isAuthenticating: false,
    token: null,
    deviceToken: null,
    hasStore: false,
    data: {},
    error: null,
};

const authReducer = (state = initialStateAuth, action) => {
    const login = LOGIN.toUpperCase(); // just MAKE SURE IT IS UPPER
    const logout = LOGOUT.toUpperCase();
    const patchAuthDetails = PATCH_AUTH_DETAILS.toUpperCase();
    const deviceToken = DEVICE_TOKEN.toUpperCase();
    const hasShop = HAS_SHOP.toUpperCase();
    const changeLanguage = CHANGE_LANGUAGE.toUpperCase();
    switch (action.type) {
        case `${login}_PENDING`:
            return {
                ...state,
                isAuthenticating: true,
                loggedIn: false,
                error: null
            };
        case `${login}_SUCCESS`: {
            return {
                ...state,
                isAuthenticating: false,
                loggedIn: true,
                data: action.payload.data,
                token: action.payload.data.token
            };
        }

        case `${login}_FAILED`: {
            return {
                ...state,
                isAuthenticating: false,
                loggedIn: false,
                error: action.payload.error,
            };
        }
        case `${logout}_PENDING`:
            return {
                ...state,
            };
        case `${logout}_SUCCESS`:
            return {...initialStateAuth};
        case `${logout}_FAILED`:
            return {...initialStateAuth};
        case `${patchAuthDetails}_SUCCESS`: {
            const {first_name, full_name, full_name_shorten, last_name, last_name_shorten, language} = action.payload;
            const update = {first_name, full_name, full_name_shorten, last_name, last_name_shorten, language};
            return {
                ...state,
                data: {
                    ...state.data,
                    ...update
                },
            };
        }
        case `${changeLanguage}_SUCCESS`: {
            return {
                ...state,
                data: {...state.data, language: action.payload.language},
            };
        }
        case `${deviceToken}_SUCCESS`: {
            return {
                ...state,
                deviceToken: action.payload,
            };
        }
        case `${hasShop}_SUCCESS`: {
            return {
                ...state,
                hasShop: action.payload,
            };
        }
        default:
            return state;
    }
};

export default authReducer;

