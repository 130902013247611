import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8SquaredMenuFilled(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M6 4c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2H6zm15 0c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-8zm15 0c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-8zM6 19c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2H6zm15 0c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-8zm15 0c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-8zM6 34c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2H6zm15 0c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-8zm15 0c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-8z" />
    </Svg>
  );
}

export default SvgIcons8SquaredMenuFilled;
