import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ForgotPasswordScreen from '../../screens/ForgotPasswordScreen';
import { changePassword } from './actions';

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      changePassword
    },
    dispatch
  );
};

// const mapStateToProps = state => ({
//   forgotPassword: state.forgotPassword
// });

export default connect(
  // mapStateToProps,
  mapActionsToProps
)(ForgotPasswordScreen);
