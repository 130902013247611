import React from 'react';
import { TouchableOpacity, View,StyleSheet } from 'react-native';
import {scale} from "react-native-size-matters";
import {AppText} from "../Stateless/AppText";
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {isWeb, MEDIUM_ICON_SIZE} from "../../helpers/functions";
import {useSelector} from "react-redux";
import {t} from "../../services/i18n";

export const PayForSlotCard = (props) => {
    const {onPress, checked, membershipType} = props;
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    return (
        <TouchableOpacity
            style={[globalStyles.flexDirectionRow, styles.checkBoxWrapper, checked ? styles.itemChecked : {}, isWeb() && {height: 'unset', marginVertical: '0.3em', paddingVertical: '0.5em'}]}
            onPress={() => onPress()}>
            {checked ? (
                <View style={styles.checkmarkWrapper}>
                    <Icons8Generator name={'check-circle'} fill={Colors.green}/>
                </View>
            ) : null}
            <View style={[globalStyles.flexDirectionRow, {alignItems: 'center', flex: 1}]}>
                <Icons8Generator name={'payCard'} size={MEDIUM_ICON_SIZE}/>
                <AppText style={[globalStyles.heeboRegular, styles.label, globalStyles.paddingStartLarge, isWeb() && {fontSize: '0.85rem'}]}>{t('modals:Card:payFor', {name:membershipType.name})}</AppText>
                <View style={[globalStyles.itemToFlexEnd]}>
                    <AppText
                        style={[globalStyles.heeboRegular,styles.label, isWeb() && {fontSize: '0.85rem'}]}>{currencySymbol}{membershipType.price}</AppText>
                </View>
            </View>
        </TouchableOpacity>
    )
}

//Dup to look the same as checkbox
const styles = StyleSheet.create({
    checkBoxWrapper: {
        position: 'relative',
        borderWidth: 1,
        borderColor: Colors.lightGrey,
        paddingHorizontal: scale(15),
        width: '100%',
        height:scale(50),
        paddingVertical: scale(8),
        marginVertical: scale(5),
        borderRadius: 2,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemChecked: {
        borderColor: Colors.brandSuccess,
        backgroundColor: Colors.viewBackground,
    },
    checkmarkWrapper: {
        backgroundColor: '#FFFFFF',
        top: scale(-5),
        right: scale(-5),
        position: 'absolute',
    },
    checkmark: {
        fontSize: scale(15),
        color: Colors.brandSuccess,
    },
    label: {
        fontSize: scale(15),
    },
    active: {
        fontSize: scale(12),
        color: Colors.futureColor,
    },
});