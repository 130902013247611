import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8ToDo(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M14-.063a1.006 1.006 0 00-.156.032.466.466 0 00-.063.031 1 1 0 00-.625.438l-7.25 10.874-4.125-4.937A1 1 0 10.22 7.625l5 6c.199.254.508.398.828.387a.993.993 0 00.797-.45l8-12a.993.993 0 00.11-1.085.996.996 0 00-.954-.54zM20 7v2h30V7zm-6 9.938a1.006 1.006 0 00-.156.03.466.466 0 00-.063.032 1 1 0 00-.625.438l-7.25 10.875-4.125-4.938a1 1 0 10-1.562 1.25l5 6c.199.254.508.398.828.387a.993.993 0 00.797-.45l8-12a.993.993 0 00.11-1.085.996.996 0 00-.954-.54zM20 24v2h30v-2zm-6 11.938a1.006 1.006 0 00-.156.03.466.466 0 00-.063.032 1 1 0 00-.625.438l-7.25 10.874-4.125-4.937a1 1 0 10-1.562 1.25l5 6c.199.254.508.398.828.387a.993.993 0 00.797-.45l8-12a.993.993 0 00.11-1.085.996.996 0 00-.954-.54zM20 43v2h30v-2z" />
    </Svg>
  );
}

export default SvgIcons8ToDo;
