import {store} from "../config/store";

export const keys = {
    brandedGreen: 'primaryColor',
    brandedGreenRGBA: 'primaryColorRGBA',
    brandedBlue: 'secondaryColor',
    mainTabColor: 'mainTabColor',
    logbookColor: 'logbookColor',
    logoUrl: 'logoUrl',
    logoWidth: 'logoWidth',
    logoHeight: 'logoHeight',
};
//NOTE - USE THIS.PROPS WHEN CONNECT IS AVAILABLE
export const getThemeProperty = (name) => {
    const whiteLabelProperties = store.getState().whiteLabelProperties;
    return whiteLabelProperties[name];
};
