import React from 'react';
import {View,ActivityIndicator} from 'react-native';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {fetchFriendConnections} from '../../../actions/connections';
import FriendsList from "../../../components/FriendsList";
import {FRIEND_CONNECTIONS_ACTION} from "../../../constants";


class FriendsListModal extends React.Component {

    constructor(props) {
        super(props);
        this.me = {};
        this.leads = [];
        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        // TODO HANDLE STATE PROPERLY.
        this.setState({isLoading: true});
        const {information} = this.props;
        const users_ids = [];
        this.me = {};
        this.leads = [];
        information.booked_users.forEach(item => {
            if (item.is_user === true) {
                if (this.props.userId === item.id) {
                    this.me = item;
                    this.me.our_relation = null;
                } else {
                    users_ids.push(item.id)
                }
            } else {
                item.our_relation = null;
                this.leads.push(item)
            }
        });
        await this.props.fetchFriendConnections(users_ids);
        this.setState({isLoading: false});
    }


    render() {
        const {isLoading} = this.state;

        let connections = [...this.props.connections, ...this.leads];
        if (!(Object.entries(this.me).length === 0 && this.me.constructor === Object)) {
            connections = connections.sort((a,b) => (a.our_relation === b.our_relation)? 0 : a.our_relation !== null ? -1 : 1);
            connections = [this.me, ...connections];
        }
        return (
            <View>
                    <FriendsList
                        actionDisabled={false}
                        list={connections}
                        isMyFriends={false}
                        displaySmall={true}
                        directObject={true}
                        action='friendStatus'
                        reducerName={FRIEND_CONNECTIONS_ACTION}
                        drawer={true}
                        closeDrawer={this.props.closeDrawer}
                        isLoading={isLoading}
                    />
            </View>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchFriendConnections,
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    connections: state.connections.data,
    isLoading: state.connections.isLoading,
    userId: state.auth.data.id,
});

FriendsListModal.propTypes = {
    information: PropTypes.object,
    connections: PropTypes.array,
    userId: PropTypes.number,
    fetchFriendConnections: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(FriendsListModal);
