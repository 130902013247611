import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Lock(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 3c-6.637 0-12 5.363-12 12v5H9c-1.645 0-3 1.355-3 3v24c0 1.645 1.355 3 3 3h32c1.645 0 3-1.355 3-3V23c0-1.645-1.355-3-3-3h-4v-5c0-6.637-5.363-12-12-12zm0 2c5.566 0 10 4.434 10 10v5H15v-5c0-5.566 4.434-10 10-10zM9 22h32c.555 0 1 .445 1 1v24c0 .555-.445 1-1 1H9c-.555 0-1-.445-1-1V23c0-.555.445-1 1-1zm16 8c-1.7 0-3 1.3-3 3 0 .898.398 1.688 1 2.188V38c0 1.102.898 2 2 2 1.102 0 2-.898 2-2v-2.813c.602-.5 1-1.289 1-2.187 0-1.7-1.3-3-3-3z" />
    </Svg>
  );
}

export default SvgIcons8Lock;
