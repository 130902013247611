import {BOXES, FORMS, LOCATIONSBOX, COACHES} from '../constants';
// BOXES IS FOR BOXES ON MORE, LOCATIONS BOX INCLUDE ALL LOCATIONS FOR BOX, AND NOT ONLY USER BOXES ( ALL LOCATIONS FOR SCHEDULES )
const initialState = {
    isLoading: false,
    data: null,
    locations: {
        isLoading: false,
        data:null,
        error: null
    },
    forms: {
        isLoading: false,
        data:null,
        error: null
    },
    coaches: {
        isLoading: false,
        data:null,
        error: null
    },
    error: null,
};

const boxesReducer = (state = initialState, action) => {
    const actionType = BOXES.toUpperCase(); // just MAKE SURE IT IS UPPER
    const locationsBox = LOCATIONSBOX.toUpperCase(); // just MAKE SURE IT IS UPPER
    const coaches = COACHES.toUpperCase(); // just MAKE SURE IT IS UPPER
    switch (action.type) {
        case `${actionType}_PENDING`:
            return {
                ...state,
                isLoading: true,
            };
        case `${actionType}_SUCCESS`:{
            return {
                ...state,
                isLoading: false,
                data: action.payload.data
            };
        }

        case `${actionType}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case `${locationsBox}_PENDING`:
            return {
                ...state,
                locations: {
                    ...state.locations,
                    isLoading: true
                },
            };
        case `${locationsBox}_SUCCESS`:{
            return {
                ...state,
                locations: {
                    ...state.locations,
                    data: action.payload.data,
                    isLoading: false
                },
            };
        }
        case `${locationsBox}_FAILED`:
            return {
                ...state,
                locations: {
                    ...state.locations,
                    error: action.payload,
                    isLoading: false
                },
            };

        case `${coaches}_PENDING`:
            return {
                ...state,
                coaches: {
                    ...state.coaches,
                    isLoading: true
                },
            };
        case `${coaches}_SUCCESS`:
            return {
                ...state,
                coaches: {
                    ...state.coaches,
                    data: action.payload.data,
                    isLoading: false
                },
            };
        case `${coaches}_FAILED`:
            return {
                ...state,
                coaches: {
                    ...state.coaches,
                    error: action.payload,
                    isLoading: false
                },
            };

        case `${FORMS}_PENDING`:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    isLoading: true
                },
            };
        case `${FORMS}_SUCCESS`:{
            return {
                ...state,
                forms: {
                    ...state.forms,
                    data: action.payload,
                    isLoading: false
                },
            };
        }
        case `${FORMS}_FAILED`:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    error: action.payload,
                    isLoading: false
                },
            };
        default:
            return state;
    }
};

export default boxesReducer;