import React from 'react';
import {connect} from "react-redux";
import {View, TouchableOpacity, ScrollView} from 'react-native';
import {bindActionCreators} from "redux";
import Spinner from 'react-native-loading-spinner-overlay';
import {globalStyles} from "../../styles/Global";
import {AppText} from "../../components/Stateless/AppText";
import {Colors} from "../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";
import {t} from "../../services/i18n";
import {
    calcCartItems, getToastDefaultConfig, IsraelPaymentSystems, isServiceCart,
    LARGE_ICON_SIZE, MEDIUM_ICON_SIZE, objHasAllProperties,
} from "../../helpers/functions";
import {apiAction} from "../../helpers/HTTP";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import HeaderWrapper from "../../components/HeaderWrapper";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {PaymentMethodCard} from "../../components/Stateless/PaymentMethodCard";
import {SeparatorWithText} from "../../components/Stateless/SeparatorWithText";
import ModalButton from "../../components/ModalButton";
import AddPaymentMethod from "../../components/PaymentProcess/AddPaymentMethod";
import omit from "lodash/omit";
import {emptyCart} from "../../actions/shop";
import {ErrorHandler} from "../../components/ErrorHandler";
import DrawerHandler from "../../components/DrawerHandler";
import AdditionalPaymentDetailsForm from "../../components/Shopping/AdditionalPaymentDetailsForm";
import {MEMBERSHIP_TYPES_CONST} from "../../constants/membershipTypes";


class PurchaseScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            step: 1,
            total_payment: {total_price: 0},
            total_payment_recurring: {total_price: 0},
            title: t('screens:Purchase:title', {}),
            errors: false
        };
        this.handlePress = this.handlePress.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.onMountStep1 = this.onMountStep1.bind(this);
        this.cartItemsSum = this.cartItemsSum.bind(this);
        this.handleCVV = this.handleCVV.bind(this);
        this.purchase = this.purchase.bind(this);
        this.updateInstallments = this.updateInstallments.bind(this);
        this.onCVVCallback = this.onCVVCallback.bind(this);
        this.onDrawerClosed = this.onDrawerClosed.bind(this);
        this.handleCartIsService = this.handleCartIsService.bind(this);
        this.showAllowDebt = this.showAllowDebt.bind(this);

    }

    componentDidMount() {
        this.installments = 1;
        this.isServiceCart = isServiceCart(this.props.cart);
        this.onMountStep1();
        const params = this.props.navigation.state.params;
        if(params && params.hasOwnProperty.showEnterDebtOption){
            this.allowDebt = params.showEnterDebtOption;
        }else{
            this.allowDebt = false;
        }
    }

    async onMountStep1() {
        this.setState({isLoading: true, step: 1});
        try{
            const userBoxPaymentsAndTokens = await this.fetchPaymentServiceData();
            const boxPayment = userBoxPaymentsAndTokens['box_payment_service'].find(item => item.payment_service !== 'CASH');
            const {cart} = this.props;
            const total_payment = calcCartItems(cart, false);
            const total_payment_recurring = calcCartItems(cart, true);
            const maxInstallments = Math.max.apply(Math, this.props.cart.map(o => o.hasOwnProperty('max_installments') ? o.max_installments : 1));
            this.setState({
                isLoading: false,
                total_payment,
                total_payment_recurring,
                userBoxToken: userBoxPaymentsAndTokens['user_box_token'],
                selectedPayment: userBoxPaymentsAndTokens['user_box_token'].length > 0 ? userBoxPaymentsAndTokens['user_box_token'][0] : null,
                boxPaymentService: userBoxPaymentsAndTokens['box_payment_service'],
                paymentServiceName: boxPayment.payment_service,
                maxInstallments
            });
        }catch (e){
            console.log(e,'errr');
        }

    }



    render() {

        return (
            <View style={{flex: 1, backgroundColor: Colors.screensGrayBackground}}>
                <StatusBarWrap backgroundColor={this.props.whiteLabelProperties.primaryColor}/>
                <HeaderWrapper
                    headerColor={this.props.whiteLabelProperties.primaryColor}
                    headerTitle={this.state.title}
                    headerArrowColor={Colors.white}
                    padding={globalStyles.appPaddingHorizontal}
                    isPop={true}/>
                <TouchableOpacity onPress={() => this.props.navigation.navigate('Cart')}
                                  style={[globalStyles.stickToHeaderBar, globalStyles.appPaddingHorizontal, globalStyles.flexDirectionRowSpaceBetween]}>
                    <View style={[globalStyles.flexDirectionRow, globalStyles.centerItems]}>
                        <Icons8Generator name={'cart'} size={LARGE_ICON_SIZE}
                                         fill={Colors.black}/>
                        <AppText
                            style={[globalStyles.sideMargin]}>{(t('screens:Purchase:cartItemsCount', {items: this.cartItemsSum()}))} {this.handleCartIsService()}</AppText>
                    </View>
                    {this.paymentAmount()}
                </TouchableOpacity>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[{flexGrow: 1, backgroundColor: Colors.screensGrayBackground}]}>
                    <View style={[globalStyles.appPaddingHorizontal, globalStyles.marginTopBetweenElements, {flex: 1}]}>
                        {this.screen()}
                    </View>
                </ScrollView>
                {this.purchaseButton()}
                {this.drawer()}
            </View>
        );
    }

    handleCartIsService(){
        if(this.isServiceCart && this.props.cart.length > 0){
            return <AppText> - {this.props.cart[0].name}</AppText>
        }
    }


    drawer() {
        return (
            <DrawerHandler onClose={this.onDrawerClosed} ref={el => {
                this.RBSheet = el ? el.ref.current : null
            }}>
                <AdditionalPaymentDetailsForm onSubmit={this.onCVVCallback} updateParent={this.updateInstallments}
                                              maxInstallments={this.state.maxInstallments}/>
            </DrawerHandler>
        )
    }

    onDrawerClosed() {
        if (this.cvvDetails) {
            this.purchase(this.cvvDetails)
        }
    }

    handleCVV() {
        if (this.state.total_payment.total_price - this.state.total_payment_recurring.total_price === 0) {
            this.purchase(); // Meaning recurring payment!
        } else {
            this.cvvDetails = null;
            this.RBSheet.open();
        }
    }

    updateInstallments(value) {
        //TODO DO NOT SET STATE!
        this.installments = value;
    }

    screen() {
        if (this.state.isLoading)
            return <Spinner visible={this.state.isLoading}/>;
        switch (this.state.step) {
            case 1:
                return this.step1();
            case 'addPayment':
                return this.addPayment();
            default:
                break;
        }
    }


    step1() {
        const {userBoxToken, errors} = this.state;
        return (
            <View style={{backgroundColor: Colors.screensGrayBackground}}>
                {errors ? <ErrorHandler errors={errors}/> : null}
                <AppText style={globalStyles.marginBottomVS15}>{t('screens:Purchase:selectPaymentMethod', {})}</AppText>
                {userBoxToken.map(item => <PaymentMethodCard userBoxToken={item} key={item.id}
                                                             selected={this.state.selectedPayment}
                                                             onPress={this.handlePress}/>)}
                {userBoxToken.length > 0 ?
                    <SeparatorWithText text={t('screens:Purchase:or', {})} color={Colors.separatorCircleGray}/> : null}
                <TouchableOpacity onPress={() => this.setState({step: 'addPayment'})}
                                  style={[globalStyles.simpleCardContainer, globalStyles.cardsShadow, globalStyles.flexDirectionRow, {
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      minHeight: verticalScale(50)
                                  }]}>
                    <Icons8Generator name={'plus'} size={MEDIUM_ICON_SIZE} fill={Colors.brandedBlue}/>
                    <AppText style={[globalStyles.heeboMedium, globalStyles.paddingStart, {
                        fontSize: scale(14),
                        color: Colors.brandedBlue
                    }]}>{t('screens:Purchase:addPaymentMethod', {})}</AppText>
                </TouchableOpacity>
                {this.showAllowDebt()}
            </View>
        )
    }

    handlePress(userBoxToken) {
        if (this.state.selectedPayment !== userBoxToken) {
            this.setState({selectedPayment: userBoxToken});
        }
    }

    showAllowDebt(){
        if(this.allowDebt){
            return (
                <React.Fragment>
                    <SeparatorWithText text={t('screens:Purchase:or', {})} color={Colors.separatorCircleGray}/>
                    <TouchableOpacity onPress={() => {
                        this.props.navigation.state.params.onGoBack({payLater:true});
                        this.props.navigation.goBack();
                    }} style={[globalStyles.simpleCardContainer, globalStyles.cardsShadow, globalStyles.flexDirectionRow, {
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: verticalScale(50)
                    }]}>
                        <Icons8Generator name={'plus'} size={MEDIUM_ICON_SIZE} fill={this.props.whiteLabelProperties.primaryColor}/>
                        <AppText style={[globalStyles.heeboMedium, globalStyles.paddingStart, {
                            fontSize: scale(14),
                            color: this.props.whiteLabelProperties.primaryColor
                        }]}>{t('screens:Purchase:bookPayLater', {})}</AppText>
                    </TouchableOpacity>
                </React.Fragment>
            )
        }

    }

    purchaseButton() {
        if (this.state.selectedPayment && this.state.step === 1) {
            const text = this.isServiceCart ? t('modals:Card:payAndBook', {}) : t('screens:Purchase:buyNow', {});
            return (
                <View style={[globalStyles.appPaddingHorizontal, globalStyles.stickyButtonsMarginBottom]}>
                    <ModalButton
                        onPress={() => {
                            IsraelPaymentSystems.includes(this.state.paymentServiceName.toLowerCase()) ? this.handleCVV() : this.purchase()
                        }}
                        type='primary'
                        colors={this.props.whiteLabelProperties.primaryColor}
                        text={text}
                        overrideStyle={{width: '90%', minHeight: verticalScale(30)}}
                        textColor={Colors.white}
                    />

                </View>

            )
        }
        return null;
    }


    cartItemsSum() {
        if (this.props.cart.length > 0) {
            return this.props.cart.reduce((sum, item) => {
                return sum + item.quantity
            }, 0)
        }
        return 0;
    }

    onCVVCallback(values) {
        this.RBSheet.close();
        if (this.installments > this.state.maxInstallments) {
            this.installments = this.state.maxInstallments;
        }
        values = {...values, installments: this.installments}
        this.cvvDetails = values;
    }

    async purchase(values = {}) {
        try {
            this.setState({isLoading: true});
            const items = this.props.cart.map((membership) => {
                return omit(membership, 'limitations');
            });
            let params = {
                payment_object: {
                    payment_details: {
                        token: true,
                        paid: false,
                        tax_free_price: this.state.total_payment.sub_total - this.state.total_payment_recurring.sub_total,
                        transaction_amount: this.state.total_payment.total_price - this.state.total_payment_recurring.total_price,
                        ...values,
                        client_payment_numbers: {
                            total_payment: this.state.total_payment,
                            total_payment_recurring: this.state.total_payment_recurring,
                        }
                    },
                    user_box_token: {
                        id: this.state.selectedPayment['id']
                    }
                },
                cart: items,
                boxes_id: this.state.selectedPayment['box_fk']
            };
            if(this.isServiceCart){
                params.payment_object.payLater = false;
                this.props.navigation.state.params.onGoBack(params);
                this.props.navigation.goBack();
            }else{
                console.log('params from purchase screen: ', params)
                const response = await apiAction('shop/membershipPurchase', 'post', params);
                if (response.hasOwnProperty('data') && response.data) {
                    if (objHasAllProperties(response.data, ['errorMessages', 'isError']) && response.data.isError !== false && response.data.errorMessages.length > 0) {
                        this.setState({isLoading: false, errors: response.data.errorMessages})
                    } else {
                        this.setState({
                            isLoading: false,
                            errors: null
                        }, () => this.props.navigation.navigate('PurchaseEnd'));
                    }
                } else {
                    this.setState({isLoading: false, errors: [t('common:unknownFailure', {})]})
                }
            }

        } catch (e) {
            const message = e.hasOwnProperty('error') ? e.error.messageToUser || e.error.message : t('common:unknownFailure', {});
            this.setState({isLoading: false, errors: [message]})
        }
    }


    addPayment() {
        this.setState({errors: false});
        return (
            <AddPaymentMethod
                boxPaymentService={this.state.boxPaymentService}
                paymentServiceName={this.state.paymentServiceName}
                handlePrev={this.handlePrev}
                createTokenSuccess={this.onMountStep1}
            />
        )
    }

    handlePrev() {
        this.setState({errors: false, step: 1});
    }

    paymentAmount() {
        const payNow = (this.state.total_payment.total_price - this.state.total_payment_recurring.total_price).toFixed(2);
        return (
            <View style={globalStyles.flexDirectionRow}>
                <AppText
                    style={{fontSize: scale(12)}}>{this.props.currencySymbol}{this.state.total_payment.total_price.toFixed(2)}</AppText>
                {this.state.total_payment_recurring.total_price > 0 ? (<AppText
                    style={{fontSize: scale(12)}}>  ({t('screens:Purchase:payNow', {price: this.props.currencySymbol + payNow})})</AppText>) : null}
            </View>
        )

    }

    async fetchPaymentServiceData() {
        try {
            return await apiAction(`shop/userBoxPaymentsAndTokens/${this.props.user.id}/${this.props.locationsBoxId}`, 'get', null);
        } catch (e) {
            console.log(e);
            // throw 'error on userBoxPaymentsAndTokens';
        }
    }

}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {emptyCart},
        dispatch,
    );
};


const mapStateToProps = state => ({
    user: state.user.data,
    cart: state.shop.cart,
    locationsBoxId: state.shop.locationsBoxId,
    boxesId: state.shop.boxesId,
    whiteLabelProperties: state.whiteLabelProperties,
    currencySymbol: state.shop.currencySymbol,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(PurchaseScreen);
