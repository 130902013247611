import React from 'react';
import { TouchableOpacity, View, Text,StyleSheet } from 'react-native';
import {scale,vericalScale} from "react-native-size-matters";
import {Icons8Generator} from "../Icons8Generator";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../AppText";


class CustomCheckBox extends React.Component {



    componentDidMount(){
    }

    render() {
        const { check, checked, label,index} = this.props;
        return (
            <TouchableOpacity onPress={() => check(index)}>
                <View style={[styles.checkBoxWrapper, checked ? styles.itemChecked : {}]}>
                    {checked ? (
                        <View style={styles.checkmarkWrapper}>
                            <Icons8Generator name={'check-circle'} fill={Colors.green}/>
                        </View>
                    ) : null}
                    <View style={styles.item}>
                        <AppText style={styles.label}>{label}</AppText>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

export default CustomCheckBox;


const styles = StyleSheet.create({
    checkBoxWrapper: {
        position: 'relative',
        width:'98%',
        borderWidth: 1,
        borderColor: Colors.lightGrey,
        paddingHorizontal: scale(15),
        paddingVertical: scale(8),
        marginVertical: scale(5),
        borderRadius: 2,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemChecked: {
        borderColor: Colors.brandSuccess,
        backgroundColor: Colors.viewBackground,
    },
    checkmarkWrapper: {
        backgroundColor: '#FFFFFF',
        top: scale(-5),
        end: scale(-5),
        position: 'absolute',
    },
    checkmark: {
        fontSize: scale(15),
        color: Colors.brandSuccess,
    },
    label: {
        fontSize: scale(12),
    },
    active: {
        fontSize: scale(12),
        color: Colors.futureColor,
    },
});