// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import {useEffect, useRef, useState} from "react";
import {t} from "../../../services/i18n";
import DrawerHandler from "../../DrawerHandler";
import {useDispatch, useSelector} from "react-redux";
import {updateHeaderConfig, updateSiteSelectedLocation} from "../../../actions/minisite";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {
    BOOK_APPOINTMENT_PROCESS, BOOK_APPOINTMENT_PROCESS_SPACES_OPTIONAL,
    CHOOSE_SERVICE_STEP,
    CHOOSE_SLOT_STEP, COACH_OR_SPACE_STEP,
    GROUP_SESSION_PROCESS
} from "../../../constants";
import {globalStyles} from "../../../styles/Global";
import {goBack, isSiteHebrew} from "../../../helpers/ComponentFunctionality";

const ScheduleMobileHeader = (props) => {
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const selectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const headerConfig = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.headerConfig)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [singleLocation, setSingleLocation] = useState(true);
    const dispatch = useDispatch()
    let RBSheet = useRef(null);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(() => {
        if(!singleLocation) {
            if (openDrawer) {
                RBSheet?.open();
            } else {
                RBSheet?.close();
            }
        }
    }, [openDrawer]);

    useEffect(() => {
        if(box && box.locations_box.length > 1) {
            setSingleLocation(false)
        }
    }, [box]);


    const changeLocation = (location) => {
        dispatch(updateSiteSelectedLocation(location))
        setOpenDrawer(false)
    }

    const goBackPress = () => {
        goBack(headerConfig, dispatch)
    }

    return (
        <>
            {
                headerConfig?.processName?.includes(BOOK_APPOINTMENT_PROCESS) && headerConfig?.steps?.findIndex(step => step === headerConfig?.stepName) > 0 ?
                    <View style={[globalStyles.flexDirectionRow, styles.infoSide]}>
                        {!params?.categoryId &&<TouchableOpacity onPress={goBackPress}><Icons8Generator name={isSiteHebrew() ? 'arrow' : 'arrow-rtl'} fill={Colors.lightBlack}/></TouchableOpacity>}
                        <View>
                            <AppText style={[styles.text, styles.header]} boldFont>{headerConfig?.headerText}</AppText>
                            <AppText style={[styles.text, styles.subHeader]}>{headerConfig?.subHeaderText}</AppText>
                        </View>
                    </View>
                    :
                    <>
                        {!singleLocation ?
                            <>
                                <TouchableOpacity onPress={() => setOpenDrawer(true)}>
                                    <View style={{flexDirection: 'row', alignItems: 'center', gap: '0.5em'}}>
                                        <AppText style={styles.mobilePageTitle} boldFont>{t('screens:Schedule:title-location', {location: selectedLocation.location})}</AppText>
                                        <Icons8Generator name='arrow-down'/>
                                    </View>
                                </TouchableOpacity>
                                <DrawerHandler title={t('common:drawer:change-location', {})}
                                               ref={el => RBSheet = el ? el.ref.current : null}
                                               onClose={() => setOpenDrawer(false)}
                                               overrideCustom={styles.drawer}
                                >
                                    <View style={{paddingVertical: '2em', gap: '1.2em'}}>
                                        {
                                            box.locations_box.map(location => {
                                                return <TouchableOpacity style={styles.locationRow}
                                                                         onPress={() => changeLocation(location)}
                                                                         key={`mobile-location-drawer-${location.id}`}>
                                                    <AppText style={[{fontSize: '1.1em'}]}>{location.location}</AppText>
                                                    {(selectedLocation.id === location.id) &&
                                                    <Icons8Generator name='check' size='1.5em'
                                                                     fill={Colors.brandedGreen}/>}
                                                </TouchableOpacity>
                                            })
                                        }
                                    </View>
                                </DrawerHandler>
                            </>
                            :
                            <AppText style={styles.mobilePageTitle} boldFont>{t('screens:Schedule:title', {})}</AppText>
                        }
                    </>

            }
        </>
    );
};
export default ScheduleMobileHeader;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            mobilePageTitle: {
                fontSize: '1rem',
                color: Colors.lightBlack
            },
            drawer: {
                paddingHorizontal: '1.3em',
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                height: 'fit-content'
            },
            locationRow: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            infoSide: {
                gap: '1em',
                justifyContent: 'center',
                alignItems: 'center'
            },
            text: {
                color: Colors.lightBlack,
                fontSize: '1rem'
            }
        }
    })
})