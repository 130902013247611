import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8DeleteUserMale(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 3c-5.815 0-9.663 3.586-9.979 9.25-.014.246-.021.496-.021.75-.002 1.647.405 3.299.855 4.717A3.576 3.576 0 0015 19.994c0 .105.004.346.018.504.274 2.107 1.228 3.113 2.066 3.52.277 1.898.965 3.49 1.916 4.435v3.776c-.578 1.386-2.59 2.187-4.908 3.113-3.901 1.558-8.757 3.495-9.09 9.603L5 46h24.623a11.868 11.868 0 01-.924-2H7.121c.731-4.013 4.274-5.429 7.711-6.8 2.736-1.094 5.322-2.124 6.115-4.471l.053-.157V27.5l-.4-.3c-.661-.496-1.413-1.939-1.604-4.042l-.053-.906h-.912c-.204-.036-.831-.483-1.025-1.965a5.39 5.39 0 01-.006-.281c.004-.756.567-1.182.572-1.186l.614-.43-.239-.708c-.675-2.012-.949-3.359-.947-4.647C17.005 8.08 20.071 5 25 5c1.977 0 3.114.84 3.488 1.559l.244.468.526.063c3.065.362 3.727 3.082 3.742 5.299.015 2.155-.64 4.291-.955 5.318l-.172.772.656.404c.092.058.467.438.473 1.125 0 .064-.003.211-.008.275-.14 1.61-.818 1.963-.992 1.969h-.873l-.12.863c-.321 2.363-1.042 3.661-1.609 4.086l-.4.299v5.059l.064.171c.16.417.389.783.649 1.124.376-.627.806-1.218 1.287-1.764v-3.64c1.107-1.122 1.636-3.003 1.885-4.386.908-.378 1.91-1.394 2.101-3.613.01-.11.014-.35.014-.459-.007-.98-.383-1.791-.855-2.326.366-1.245.869-3.225.855-5.293-.027-4.009-1.848-6.601-5.018-7.195C29.02 3.823 27.167 3 25 3zm15 27c-5.511 0-10 4.489-10 10s4.489 10 10 10 10-4.489 10-10-4.489-10-10-10zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm-3.51 3.49a1 1 0 00-.697 1.717L38.586 40l-2.793 2.793a1 1 0 101.414 1.414L40 41.414l2.793 2.793a1 1 0 101.414-1.414L41.414 40l2.793-2.793a1 1 0 00-.727-1.717 1 1 0 00-.687.303L40 38.586l-2.793-2.793a1 1 0 00-.717-.303z" />
    </Svg>
  );
}

export default SvgIcons8DeleteUserMale;
