// @flow
import * as React from 'react';
import {Image, Text, TouchableOpacity, View} from "react-native-web";
import PopupModal from "../../Common/PopupModal";
import {useEffect, useState} from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import ModalButton from "../../../ModalButton";
import {calcItemTax, calcMembershipEndDate, isLocationIL, isMobile} from "../../../../helpers/functions";
import {Link} from "react-router-dom";
import moment from "moment";
import {SERVER_DATE_FORMAT} from "../../../../variableConstants";
import date from "../../../../services/i18n/date";
import {globalStyles} from "../../../../styles/Global";
import {patchMembershipStartDate} from "../../../../actions/shop";
import DatePickerField from "../../Common/DatePickerField";
import {isSiteHebrew} from "../../../../helpers/ComponentFunctionality";

const MembershipExtendedCard = (props) => {
    const {setClose, item, dollars, cents, addToCart, isFromRegister, isPopup} = props;
    const [duration, setDuration] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const currencySymbol = useSelector(state => state.shop.currencySymbol)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const cart = useSelector(state => state.shop.cart)
    const mobile = isMobile();
    const isRtl = isLocationIL()
    const tax = calcItemTax(item.price, item.membership_types_taxes)

    useEffect(() => {
        createDurationText()
        handleStartDate()
    }, []);

    useEffect(() => {
        handleStartDate()
    }, [startDate]);

    const handleStartDate = () => {
        const start = startDate || item.start || moment().format(SERVER_DATE_FORMAT);
        const calcEndDate = calcMembershipEndDate(item.period_time_unit, item.period_amount, start);
        const end = calcEndDate ? date.format(calcEndDate, 'LL') : null;
        item.start = start;
        item.end = calcEndDate;
        setStartDate(start);
        setEndDate(end)
    }

    const createDurationText = () => {
        let durationTxt = '';
        let expiration = null;
        if(item.period_time_unit) {
            expiration = t('screens:Home:cards:ending-plan:expiration', {length: `${item.period_amount} ${item.period_amount > 1 ? t(`common:timePeriods:${item.period_time_unit.toLowerCase()}_plural`,{}) : t(`common:timePeriods:${item.period_time_unit.toLowerCase()}`,{})}`})
        }
        if(item.type === 'plan') {
            if(!item.period_amount && !item.period_time_unit) { //if unlimited
                expiration = t('screens:Shop:card:unlimited', {})
                if(!item.is_recurring_payment) {
                    durationTxt = expiration
                }
            } else {
                durationTxt = expiration
            }
            if(item.is_recurring_payment) {
                durationTxt = t(`screens:Shop:card:recurring_cycle`, {recurringCycle: t(`screens:Shop:card:recurringCycle:${item.recurring_cycle}`, {})})
                durationTxt = `${expiration ? `${durationTxt}, ${expiration}` : durationTxt}`
            }
        } else if(item.type === 'session') {
            durationTxt = `${item.sessions} ${t('screens:Shop:card:sessions',{})}`
            durationTxt = `${expiration ? `${durationTxt}, ${expiration}` : durationTxt}`
        }
        setDuration(durationTxt);
    }

    return (
        <View style={[{height: mobile ? '100%' : 'fit-content'}, !isPopup && !mobile && {height: 'inherit'}]}>
            <View style={[mobile && styles.mobileWrapper, !isPopup && mobile && styles.pageMobileWrapper, !isPopup && !mobile && {height: 'inherit'}]}>
                <View style={[styles.contentWrapper, !isPopup && mobile && styles.scrollableContent, !isPopup && !mobile && {height: `calc(100% - ${EStyleSheet.value('$desktopShopFooterBtns')}px)`}]}>
                    {isPopup ?
                        <AppText style={[styles.header]} boldFont>{item.name}</AppText>
                        :
                        <View style={[styles.pageHeaderWrapper, mobile ? {margin: 0, justifyContent: 'start'} : {height: 30}]}>
                            <Link to={{pathname: '/shop', search:boxQueryString || location.search}}><Icons8Generator name={isSiteHebrew() ? 'arrow' : 'arrow-rtl'} fill={Colors.lightBlack} size={'1.5em'}/></Link>
                            <AppText style={[styles.header]} boldFont>{item.name}</AppText>
                            {!mobile && <Link to={{pathname: '/cart', search:boxQueryString || location.search}} style={{textDecoration: 'none'}}><View style={[styles.headerCartWrapper]}><Icons8Generator name={'cart'} fill={Colors.lightBlack} size={'1.5em'}/><AppText style={[styles.headerCartCount]}>({cart.length})</AppText></View></Link>}
                        </View>
                    }
                    <View style={[styles.content, mobile ? {flexDirection: 'column'} : {overflow: 'auto', maxHeight: 300}, !isPopup && !mobile && {flexDirection: 'column'}, !isPopup && !mobile && {height: `calc(100% - ${90}px)`, overflow: 'auto'}]}>
                        <View style={[mobile ? {width: '100%'} : styles.infoSide, !isPopup && !mobile && {width: 'unset'}]}>
                            <View style={[styles.infoSection]}>
                                <AppText style={[styles.contentLabel]}>{t(`screens:Shop:card:${item.type === 'session' ? 'numSessions' : 'duration'}`,{})}</AppText>
                                <AppText style={[styles.contentText]}>{duration}</AppText>
                            </View>
                            {item.description &&
                                <View style={[styles.infoSection]}>
                                    <AppText style={[styles.contentText]}>{item.description}</AppText>
                                </View>
                            }
                            <View style={[mobile ? {width: '100%'} : styles.infoSection, {gap: '0.5em'}]}>
                                <AppText style={[styles.contentLabel]}>{t('screens:Product:startDate',{})}</AppText>
                                <DatePickerField startDate={startDate}
                                                 onChange={(val) => moment(val, 'YYYY-MM-DD').diff(moment(), 'days') >= 0 ? setStartDate(val) : null}
                                                 overrideStyle={!isPopup && !mobile && {width: '15em'}}
                                                 min={moment().format('YYYY-MM-DD')}
                                                 onKeyDown={(e) => e.preventDefault()}
                                />
                                {endDate ? <AppText style={[styles.contentText]}>{t('screens:Product:endDate', {endDate})}</AppText> : null}
                            </View>
                            <View>
                                <AppText style={[styles.contentLabel]}>{t('screens:Purchase:price',{})}</AppText>
                                <View style={[{gap: '1em', flexDirection: 'row', alignItems: 'baseline'}]}>
                                    <View style={{gap: '0.2em', flexDirection: isRtl ? 'row-reverse' : 'row'}}>
                                        <AppText style={[mobile ? styles.dollarPriceMobile : styles.dollarPrice]}>{`${currencySymbol}${dollars}`}</AppText>
                                        {cents && cents > 0 && <AppText style={[mobile ? styles.centPriceMobile : styles.centPrice]}>{cents}</AppText>}
                                    </View>
                                    {item.is_recurring_payment !== 0 && <AppText style={[styles.contentText]}>/ {t(`screens:Shop:card:recurringCycle:cyclePeriod:${item.recurring_cycle}`, {})}</AppText>}
                                </View>
                                {tax !== null && parseInt(tax) > 0 && <AppText style={[styles.contentText]}>+{currencySymbol}{tax} {t('screens:Purchase:tax',{})}</AppText>}
                            </View>
                        </View>
                        {item.limitations && item.limitations.length > 0 &&
                            <View style={[styles.limitationsSide, !isPopup && !mobile && {width: 'unset'}]}>
                                {item.limitations?.map(limitation => {
                                    return (
                                        <View key={`limitations-extended-membership-${limitation.header}`}
                                              style={[styles.infoSection, {marginBottom: 0, borderBottomWidth: 0}]}>
                                            <AppText style={[styles.contentLabel]}>{limitation.header}</AppText>
                                            {limitation.values.map(val => <AppText
                                                style={[styles.contentText, {fontSize: '0.8rem'}]}
                                                key={`limitations-text-${val}`}>{val}</AppText>)}
                                        </View>)
                                })}
                            </View>
                        }
                    </View>
                </View>
                <View style={[styles.footerActions, mobile && {flexDirection: 'column-reverse'}, isFromRegister && mobile && {flexDirection: 'row'}, !isPopup && !mobile && styles.pageFooterBtns, !isPopup && mobile && styles.footerBtnsMobile]}>
                    {
                        isFromRegister ?
                            <>
                                <TouchableOpacity onPress={setClose} style={[styles.addToCart, mobile && styles.mobileRegisterCancelBtn, mobile && {borderColor: Colors.lightBlack}]}>
                                    <AppText style={[styles.addToCartText, {color: Colors.lightBlack}]}>{t('common:cancel',{})}</AppText>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => addToCart()} style={[styles.addToCart, mobile && {flex: 1}]}>
                                    <View style={[styles.primaryBtn, {backgroundColor: Colors.lightBlack}, mobile && {width: '100%'}]}>
                                        <AppText style={[styles.buyNowBtnText]}>{t('common:select', {})}</AppText>
                                    </View>
                                </TouchableOpacity>
                            </>
                            :
                            <>
                                <TouchableOpacity onPress={() => addToCart()} style={[styles.addToCart]}>
                                    <Icons8Generator name={'cart'} fill={Colors.lightBlack} size={'1.2em'}/>
                                    <AppText style={[styles.addToCartText, {color: Colors.lightBlack}]}>{t('screens:Purchase:addToCart',{})}</AppText>
                                </TouchableOpacity>
                                <Link to={{pathname: '/cart', search:boxQueryString}} style={EStyleSheet.flatten([{textDecoration: 'none'}, (mobile || !isPopup) && {width: '100%'}])} onClick={addToCart}>
                                    <View style={[styles.primaryBtn, {backgroundColor: Colors.lightBlack}, (mobile || !isPopup) && {width: '100%'}]}>
                                        <AppText style={[styles.buyNowBtnText]}>{t('screens:Purchase:buyNow', {})}</AppText>
                                    </View>
                                </Link>
                            </>
                    }
                </View>
            </View>
        </View>
    );
};

export default MembershipExtendedCard;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: 0,
                paddingVertical: 0,
                width: 'fit-content',
                overflowY: 'auto',
                overflowX: 'hidden'
            },
            header: {
                fontSize: '1.3rem',
                fontFamily: 'heebo-bold',
                maxWidth: '90%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            contentWrapper: {
                padding: '1em'
            },
            footerActions: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: '1em',
                gap: '1.5em',
                borderTopColor: Colors.graySeparator,
                borderTopWidth: 1,
                alignItems: 'center'
            },
            primaryBtn: {
                width: '7em',
                minHeight: 'auto',
                padding: '0.5em',
                borderRadius: 2,
                marginTop: 0,
                alignItems: 'center',
            },
            addToCart: {
                flexDirection: 'row',
                gap: '0.5em',
                alignItems: 'center'
            },
            addToCartText: {
                fontSize: '0.85rem',
                fontWeight: 'bold'
            },
            content: {
                flexDirection: 'row',
                gap :'2em',
                marginTop: '1em'
            },
            infoSide: {
                width: '15em'
            },
            limitationsSide: {
                width: '12em'
            },
            contentText: {
                fontSize: '0.85rem',
                color: Colors.grey,
            },
            contentLabel: {
                fontSize: '0.9rem',
                color: Colors.lightBlack,
                fontWeight: 700
            },
            infoSection: {
                marginBottom: '1em',
                paddingBottom: '1em',
                borderBottomWidth: 1,
                borderBottomColor: Colors.graySeparator
            },
            dollarPrice: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            centPrice: {
                fontSize: '0.7rem',
                fontWeight: 'bold'
            },
            dollarPriceMobile: {
                fontSize: '1.3rem',
                fontWeight: 'bold'
            },
            centPriceMobile: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            mobileWrapper: {
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            },
            buyNowBtnText: {
                fontSize: '0.9rem',
                color: Colors.white,
                fontFamily: 'heebo',
                borderRadius: 25,
            },
            datepicker: {
                backgroundColor: Colors.brandedLightText,
                borderWidth: 0,
                borderRadius: 3,
                height: 35,
                alignItems: 'center',
                padding: '0 0.5em',
                fontFamily: 'heebo',
                color: Colors.lightBlack,
                fontSize: '0.8rem'
            },
            mobileRegisterCancelBtn: {
                flex: 1,
                justifyContent: 'center',
                borderWidth: 1,
                height: '100%',
                borderRadius: 2
            },
            pageHeaderWrapper: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: '1em',
                alignItems: 'center',
                gap: '0.5em'
            },
            headerCartWrapper: {
                flexDirection: 'row',
                textDecoration: 'none'
            },
            headerCartCount: {
                fontSize: '0.8rem',
            },
            pageFooterBtns: {
                flexDirection: 'column-reverse',
                width: '15em',
                border: 'none',
                alignSelf: 'center',
                height: () => EStyleSheet.value('$desktopShopFooterBtns'),
            },
            footerBtnsMobile: {
                height: () => EStyleSheet.value('$mobileShopFooterBtns'),
                justifyContent: 'center',
                gap: '1em'
            },
            pageMobileWrapper: {
                height: () => `100% - ${EStyleSheet.value('$mobileHeader')}`,
                maxHeight: () => `100% - ${EStyleSheet.value('$mobileHeader')}`,
            },
            scrollableContent: {
                height: () => `100% - ${EStyleSheet.value('$mobileHeader') + EStyleSheet.value('$mobileShopFooterBtns')}`,
                maxHeight: () => `100% - ${EStyleSheet.value('$mobileHeader') + EStyleSheet.value('$mobileShopFooterBtns')}`,
                overflow: 'auto'
            },
        }
    })
})