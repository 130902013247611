import React from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import {fetchProfile} from "../../actions/user";
import {bindActionCreators} from "redux";
import {store} from "../../config/store";
import {hasStore} from "../../actions/auth";
import {globalStyles} from "../../styles/Global";
import FitImage from 'react-native-fit-image';
import {verticalScale,scale} from 'react-native-size-matters';
import Spinner from 'react-native-loading-spinner-overlay';
import * as Sentry from 'sentry-expo';
import {isWeb} from "../../helpers/functions";

class AuthLoadingScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.bootstrapAsync();
    }

    bootstrapAsync = async () => {

        let route = 'Main';
        if (!this.props.loggedIn || this.props.token === null) {
            route = 'Auth';
        } else {
            try {
                const user = await this.props.fetchProfile();
                Sentry.Native.setUser({id: user.data.id });
                //TODO This is a patch on auth reducer, so wont have to check if has store all the time
                const has_shop = user.data.users_boxes.filter((item => {
                    // Removed because it needs to work with with inactive boxes
                    // if(item.active === 1){
                        if(item.locations_box.country_code !== 'IL')
                            return item;
                        if(item.locations_box.has_shop === true)
                            return item;
                    // }
                }));
                store.dispatch(hasStore(has_shop.length !== 0));
                route = 'Main';
                if (user.data.on_boarding === 0 && !(isWeb())) {//TODO EDEN - NOTE THIS WILL BE ON YOUR ON BOARDING ROUTE
                    route = 'FirstLogin';
                }
            } catch (error) {
                //TODO Handle user error!
            }
        }
        this.props.navigation.navigate(route);
    };

    render() {
        return (
            <View style={[globalStyles.flex]}>
                <Spinner animation={'fade'} visible={true}/>
                <FitImage
                    source={require('../../../assets/images/splash.png')}
                    style={{width:scale(350),height:verticalScale(680)}}
                />
            </View>
        );
    }
}

AuthLoadingScreen.propTypes = {
    navigation: propTypes.instanceOf(Object),
    loggedIn: propTypes.bool,
    firstLogin: propTypes.bool,
};

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        fetchProfile
    }, dispatch);
};

const mapStateToProps = state => ({
    token: state.auth.token,
    loggedIn: state.auth.loggedIn,
});


export default connect(
    mapStateToProps,
    mapActionsToProps
)(AuthLoadingScreen);

