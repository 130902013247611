import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Notification(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 0c-2.2 0-4 1.8-4 4 0 .516.102 1.016.281 1.469C15.656 6.929 12 11.816 12 18c0 7.832-1.922 11.398-3.75 13.406-.914 1.004-1.816 1.614-2.594 2.188-.39.285-.746.57-1.062.937C4.277 34.898 4 35.421 4 36c0 1.375.844 2.543 2.031 3.313 1.188.769 2.746 1.265 4.625 1.656 2.438.504 5.446.77 8.75.906A6.027 6.027 0 0019 44c0 3.3 2.7 6 6 6s6-2.7 6-6c0-.75-.152-1.465-.406-2.125 3.304-.137 6.312-.402 8.75-.906 1.879-.39 3.437-.887 4.625-1.657C45.156 38.544 46 37.376 46 36c0-.578-.277-1.102-.594-1.469-.316-.367-.672-.652-1.062-.937-.778-.574-1.68-1.184-2.594-2.188C39.922 29.398 38 25.832 38 18c0-6.18-3.664-11.063-9.281-12.531.18-.453.281-.953.281-1.469 0-2.2-1.8-4-4-4zm0 2c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm2.344 5.188C32.676 8.136 36 12.258 36 18c0 8.168 2.078 12.363 4.25 14.75 1.086 1.191 2.184 1.938 2.906 2.469.36.265.63.488.75.625.121.136.094.125.094.156 0 .625-.29 1.082-1.125 1.625-.836.543-2.195 1.047-3.938 1.406C35.453 39.754 30.492 40 25 40c-5.492 0-10.453-.246-13.938-.969-1.742-.36-3.101-.863-3.937-1.406C6.289 37.082 6 36.625 6 36c0-.031-.027-.02.094-.156.12-.137.39-.36.75-.625.722-.532 1.82-1.278 2.906-2.469C11.922 30.363 14 26.168 14 18c0-5.738 3.328-9.828 8.656-10.781A3.93 3.93 0 0025 8c.887 0 1.68-.316 2.344-.813zm-5.782 34.75C22.684 41.96 23.825 42 25 42c1.176 0 2.316-.04 3.438-.063.355.602.562 1.313.562 2.063 0 2.223-1.777 4-4 4s-4-1.777-4-4c0-.758.2-1.46.563-2.063z" />
    </Svg>
  );
}

export default SvgIcons8Notification;
