import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        fill="#18181b"
        {...props}
    >
        <Path d="M12 0c-1.102 0-2 .898-2 2v2H4c-1.16 0-2 .84-2 2v40c0 1.16.84 2 2 2h27.078a11.835 11.835 0 0 1-1.453-2H4V15h42v14.625c.719.414 1.387.906 2 1.457V6c0-1.16-.84-2-2-2h-6V2c0-1.102-.898-2-2-2h-2c-1.102 0-2 .898-2 2v2H16V2c0-1.102-.898-2-2-2Zm0 2h2v6h-2Zm24 0h2v6h-2ZM4 6h6v2c0 1.102.898 2 2 2h2c1.102 0 2-.898 2-2V6h18v2c0 1.102.898 2 2 2h2c1.102 0 2-.898 2-2V6h6v7H4Zm6 13v23h18.184A11.646 11.646 0 0 1 28 40h-2v-5h3.105c.325-.707.72-1.375 1.168-2H26v-5h5v4.09c.598-.68 1.266-1.29 2-1.817V28h7v-9Zm2 2h5v5h-5Zm7 0h5v5h-5Zm7 0h5v5h-5Zm7 0h5v5h-5Zm-21 7h5v5h-5Zm7 0h5v5h-5Zm21 2c-5.516 0-10 4.484-10 10s4.484 10 10 10 10-4.484 10-10-4.484-10-10-10Zm0 2c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8Zm-28 3h5v5h-5Zm7 0h5v5h-5Zm17.5.5c-.25 0-.5.102-.7.3a.967.967 0 0 0 0 1.4l2.798 2.8-2.797 2.8a.967.967 0 0 0 0 1.4c.199.198.5.3.699.3.2 0 .5-.102.7-.3l2.8-2.798 2.8 2.797c.2.2.5.301.7.301.2 0 .5-.102.7-.3a.967.967 0 0 0 0-1.4L41.401 40l2.797-2.8a.967.967 0 0 0 0-1.4.967.967 0 0 0-1.398 0L40 38.599 37.2 35.8c-.2-.2-.45-.301-.7-.301Z" />
    </Svg>
)

export default SvgComponent
