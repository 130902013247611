// @flow
import * as React from 'react';
import {View} from "react-native-web";
import ShopItemsContainer from "./ShopItemsContainer";
import {isMobile} from "../../../helpers/functions";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";

const ShopPage = (props) => {
    const mobile = isMobile();

    return (
        <View style={[styles(mobile).wrapper]}>
            <View style={[{width: mobile ? '100%' : '45em', marginHorizontal: 'auto', marginVertical: mobile ? 0 : '2em'}]}>
                <ShopItemsContainer/>
            </View>
        </View>

    );
};

export default ShopPage;

const styles = (mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                height: mobile ? '100%' : 'calc(100% - 100px)',
                maxHeight: mobile ? '100%' : 'calc(100% - 100px)' ,
                overflow: mobile ? 'hidden' : 'auto'
            }
        }
    })
})