import {
    ACTIVE_FRIEND_ID,
    FRIEND,
    FRIEND_FRIENDSHIP_STATUS,
    FRIENDS_OF_FRIEND_ACTION,
    FRIENDS_OF_FRIEND_SCHEDULE_ACTION
} from '../constants';

const initialState = {
    isLoading: false,
    data: {},
    error: null,
    active_friend: null
};

const friendReducer = (state = initialState, action) => {

    const friend = FRIEND.toUpperCase(); // just MAKE SURE IT IS UPPER
    const friendshipStatus = FRIEND_FRIENDSHIP_STATUS.toUpperCase();
    const friendOfFriendAction = FRIENDS_OF_FRIEND_ACTION.toUpperCase();
    const friendOfFriendScheduleAction = FRIENDS_OF_FRIEND_SCHEDULE_ACTION.toUpperCase();
    const activeFriendId = ACTIVE_FRIEND_ID.toUpperCase();

    switch (action.type) {
        case `${friend}_PENDING`:
            return {
                ...state,
                isLoading: true,
            };
        case `${friend}_SUCCESS`: {
            return {
                ...state,
                isLoading: false,
                data: {...state.data, [action.ref_id]: action.payload},
                error: null
            };
        }
        case `${friend}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case `${friendshipStatus}_SUCCESS`: {
            return {
                ...state,
                data: {...state.data, [action.payload.id]: action.payload},
                isLoading: false,
                error: null
            };
        }
        case `${friendshipStatus}_FAILED`: {
            return {
                ...state,
                error: null,
                isLoading: false,

            };
        }
        case `${friendOfFriendAction}_PENDING`:
            return {
                ...state,
            };
        case `${friendOfFriendAction}_SUCCESS`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [state.active_friend]: {
                        ...state.data[state.active_friend],
                        friend_connection: state.data[state.active_friend].friend_connection
                            .map(item => item.users_id === action.payload.id || item.friend_users_id === action.payload.id ? {
                            ...item,
                            our_relation: action.payload.our_relation
                        } : item),
                    }
                }
            };
        }
        case `${friendOfFriendAction}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case `${activeFriendId}_SUCCESS`: {
            return {
                ...state,
               active_friend: action.payload.id
            };
        }
        case `${friendOfFriendScheduleAction}_SUCCESS`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [state.active_friend]: {
                        ...state.data[state.active_friend],
                        schedules: state.data[state.active_friend].schedules
                            .map(item => item.id === action.payload.id ? {
                                ...action.payload,
                            } : item),
                    }
                }
            };
        }
        default:
            return state;
    }
};

export default friendReducer;