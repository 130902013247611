import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';
import {scale} from 'react-native-size-matters';
import TabBarIcon from '../../components/TabBarIcon';

import ProfileContainer from '../../containers/ProfileContainer';
import FriendProfileContainer from '../../containers/FriendProfileContainer';
import ClubProfileScreen from "../../screens/Club/ClubProfileScreen";
import FitnessPunchesScreen from "../../screens/Club/FitnessPunchesScreen";
import DigitalFormScreen from "../../screens/DigitalFormScreen";

export const ProfileStack = createStackNavigator(
    {
        Profile: {
            screen: ProfileContainer,
            navigationOptions: {
                headerBackTitle: null,
                header: null,
            },
        },
        FriendProfile: {
            screen: FriendProfileContainer,
            navigationOptions: {
                headerTintColor: 'white',
                headerLeftContainerStyle: {
                    paddingLeft: scale(10),
                },
            },
        },
        ClubProfileScreen: {
            screen: ClubProfileScreen,
            navigationOptions: ({navigation}) => ({
                header: null
            }),
        },
        FitnessPunchesScreen: {
            screen: FitnessPunchesScreen,
            navigationOptions: ({ navigation }) => ({
                header: null
            }),
        },
        DigitalForm: {
            screen: DigitalFormScreen,
            navigationOptions: ({ navigation }) => ({
                header: null
            }),
        }

    },
    {
        headerMode: 'none',
    },
);

ProfileStack.navigationOptions = {
    tabBarIcon: ({focused}) => <TabBarIcon focused={focused} name="account"/>,
};
