// @flow
import * as React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import {AppText} from "../../../Stateless/AppText";
import {Colors} from "../../../../styles/Colors";
import Coach from "../../../../svg/Icons8PersonalTrainer"
import {useEffect, useState} from "react";
import {BookingCard} from "./BookingCard";
import {t} from "../../../../services/i18n";
import SuccessModal from "../../Common/SuccessModal";
import {useDispatch, useSelector} from "react-redux";
import LoginScreen from "../../../../screens/Web/LoginScreen";
import {CANCEL_SCHEDULE_USER, CANCEL_WAIT_LIST, PAST} from "../../../../constants/booking";
import {isAMPMtimeFormat, isMobile, SMALL_ICON_SIZE} from "../../../../helpers/functions";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {hasCoachRestriction} from "../../../../helpers/DisablePages";
import {getTimeStringByFormat} from "../../../../helpers/ComponentFunctionality";
import EStyleSheet from "react-native-extended-stylesheet";
import {ClassItem as MobileClassItem} from "../Mobile/ClassItem";
import {authenticateUserAfterRegistration, toggleScheduleSiteReFetch} from "../../../../actions/minisite";
import moment from "moment";
import LoginOrRegisterWrapper from "../../Registration/LoginOrRegisterWrapper";
import GenericSuccessModal from "../../Common/GenericSuccessModal";
import {fetchCurrencySymbol} from "../../../../actions/shop";
import {fetchProfile} from "../../../../actions/user";

const ClassItem = (props) => {
    const {classInfo} = props;
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showBooked, setShowBooked] = useState(false);
    const [showInWaitList, setShowInWaitList] = useState(false);
    const [payNowPrice, setPayNowPrice] = useState(null);
    const [userFromRegister, setUserFromRegister] = useState(null);
    const loggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    const dispatch = useDispatch()
    const showCoach = hasCoachRestriction(classInfo);

    useEffect(() => {
        if(classInfo.box_categories.membership_types.length > 0) {
            const membershipLocation = classInfo.box_categories.membership_types.find(membership => membership.location_box_fk === location.id)
            setPayNowPrice(membershipLocation.price)
        }
    }, [location]);


    useEffect(() => {
        if(classInfo) {
            setShowBooked(classInfo.booking_option === CANCEL_SCHEDULE_USER || ((classInfo.user_booked || classInfo.user_in_standby) && classInfo.booking_option === PAST));
            setShowInWaitList(classInfo.user_in_standby && classInfo.booking_option !== PAST)
        }
    }, [classInfo]);

    const bookingClosed = (res) => {
        setShowBookingModal(false)
        if(res.isBooked) {
            if(res.user) {
                setUserFromRegister(res.user)
            }
            setShowSuccess(true)
        } else {
            setShowSuccess(false)
        }
    }

    const openCardOrLogin = () => {
        if(loggedIn) {
            setShowBookingModal(true)
        } else {
            setShowLogin(true)
        }
    }

    const closeSuccess = async () => {
        if(userFromRegister) {
            await dispatch(authenticateUserAfterRegistration(userFromRegister))
            await dispatch(fetchProfile())
        }
        dispatch(toggleScheduleSiteReFetch());
        setShowSuccess(false)
    }

    return (
        <>
            {
                isMobile() ?
                    <MobileClassItem classInfo={classInfo} onCardClick={openCardOrLogin} price={payNowPrice}/>
                    :
                    <TouchableOpacity onPress={openCardOrLogin}>
                        <View style={[styles.wrapper, {backgroundColor: classInfo.box_categories.category_color ? classInfo.box_categories.category_color : Colors.arboxBlue}]}>
                            <View style={[{justifyContent: 'space-between', flexDirection: "row-reverse"}]}>
                            {(showBooked || showInWaitList) ?
                                <AppText style={[styles.badge, {color: classInfo.box_categories.category_color}]}>{t(`screens:Schedule:card:${showBooked ? 'booked' : 'in-waitlist'}`)}</AppText>
                                : <AppText style={styles.plus}>+</AppText>}
                            <View style={styles.upper}>
                                <AppText style={[styles.text,styles.smallText]}>{getTimeStringByFormat(classInfo.time, classInfo.end_time, location?.time_format)}</AppText>
                                <AppText style={styles.text} boldFont={true}>{classInfo.box_categories.name}</AppText>
                            </View>
                            </View>
                            {showCoach &&
                            <View style={styles.lower}>
                                <Icons8Generator name={'name-tag'} size={'1em'} fill={Colors.lightBlack}/>
                                <AppText style={[styles.text, classInfo.coach ? styles.smallText : styles.noCoach]}>{classInfo.coach ? classInfo.coach.full_name : '-'}</AppText>
                            </View>
                            }
                            {classInfo.box_categories.membership_types.length > 0 && currencySymbol &&
                                <View style={styles.lower}>
                                    <Icons8Generator name={'ticket'} size={'1em'} fill={Colors.lightBlack}/>
                                    <AppText style={[styles.text, styles.smallText]}>{t('screens:Purchase:payNow', {price: `${currencySymbol} ${payNowPrice}`})}</AppText>
                                </View>
                            }
                        </View>
                    </TouchableOpacity>

            }
            {showBookingModal && <BookingCard classInfo={classInfo} show={showBookingModal} setClose={bookingClosed} showBooked={showBooked || showInWaitList}/>}
            {showSuccess && <GenericSuccessModal show={showSuccess}
                                                 iconName={'calendar-booked'}
                                                 setClose={closeSuccess}
                                                 isPopup={true}
                                                 title={t(`screens:ScheduleSingle:${classInfo.booking_option === 'insertStandby' ? 'onWaitList:successTitle' : 'onBooked:successTitle'}`, {})}
                                                 subHeader={t(`common:drawer:booking:${classInfo.booking_option === 'insertStandby' ? 'success-enter-wait-list' : 'dont-forget-msg'}`, {
                                              session: classInfo.booking_option === 'insertStandby' ? classInfo.box_categories.name : t('screens:Schedule:group-session', {}),
                                              coach: classInfo.coach?.full_name,
                                              date: moment(`${classInfo.date} ${classInfo.time}`, 'YYYY-MM-DD HH:mm').format('LLLL')
                                          })}/>}
            {showLogin && classInfo.box_categories.membership_types.length && classInfo.free > 0 ?
                <LoginOrRegisterWrapper isAppointment={false} show={showLogin} setClose={() => setShowLogin(false)} selectedSlot={classInfo} onRegisterSuccess={bookingClosed}/>
                :
                <LoginScreen show={showLogin} handleSubmit={() => setShowLogin(false)} setClose={() => setShowLogin(false) } handleForgotPassword={() => console.log('forgot password')}/>
            }
        </>
    );
};

export default ClassItem;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                backgroundColor: Colors.brandedGreen,
                width: '100%',
                padding: '0.5em',
                borderRadius: 8,
                gap: '0.6em',
                position: 'relative'
            },
            upper: {
                flex: 1
            },
            lower: {
                gap: '0.4em',
                flexDirection: "row",
                alignItems: 'center'
            },
            text: {
                fontSize: '0.85rem',
                fontWeight: 'bold',
                color: Colors.lightBlack,
            },
            smallText: {
                fontSize: '0.75rem',
            },
            noCoach: {
                fontSize: '1rem',
                fontWeight: '300',
            },
            plus: {
                fontSize: '1.3rem',
                fontWeight: '300',
                // position: 'absolute',
                // top: '0.4em',
                // right: '0.4em',
                lineHeight: '0.6',
                color: Colors.lightBlack
            },
            longText: {
                maxWidth: '8em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            badge: {
                backgroundColor: Colors.lightBlack,
                borderRadius: '15px',
                paddingHorizontal: '5px',
                height: 'fit-content',
                fontSize: '0.7rem',
                fontWeight: 'bold'
            }
        }
    })
})