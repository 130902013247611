export const modalTypes = {
  MODAL_SIMPLE: 'MODAL_SIMPLE',
  MODAL_FRIENDS_LIST: 'MODAL_FRIENDS_LIST',
  MODAL_WITH_TOP_BAR: 'MODAL_WITH_TOP_BAT',
  MODAL_WITH_TOP_BAR_IMAGE: 'MODAL_WITH_TOP_BAR_IMAGE',
  MODAL_BALLOONS_LIST: 'MODAL_BALLOONS_LIST',
  MODAL_FRIENDS_REQUEST: 'MODAL_FRIENDS_REQUEST',
  MODAL_REPETITION_MAXIMUM: 'MODAL_REPETITION_MAXIMUM',
  MODAL_PURCHASE: 'MODAL_PURCHASE',
  MODAL_SHARE: 'MODAL_SHARE',
};
