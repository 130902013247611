import React, {useState} from 'react';
import {TouchableOpacity, View, Text, StyleSheet} from 'react-native';
import {scale} from "react-native-size-matters";
import {Icons8Generator} from "../Icons8Generator";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../AppText";
import ModalButton from "../../ModalButton";


export const ListItems = props => {
    const [check, setChecked] = useState(0);
    const {arr,onIndexChange} = props;
    return arr.map((item, index) => {
        return (
            <TouchableOpacity key={index} onPress={() => {
                onIndexChange(index)
                setChecked(index)
            }}>
                <View style={[styles.checkBoxWrapper, check === index ? styles.itemChecked : {}]}>
                    {check === index ? (
                        <View style={styles.checkmarkWrapper}>
                            <Icons8Generator name={'check-circle'} fill={Colors.green}/>
                        </View>
                    ) : null}
                    <View style={styles.item}>
                        <AppText style={styles.label}>{item.title}</AppText>
                    </View>
                </View>
            </TouchableOpacity>
        )
    });
}

export const CustomCheckBoxV2 = props => {
    return(
    <React.Fragment>
        <ListItems onIndexChange={props.onIndexChange} arr={props.arr}/>
        <ModalButton
            onPress={props.onPress}
            type='primary'
            colors={props.colors}
            text={props.text}
            textColor={Colors.white}
        />
    </React.Fragment>
    )
}


const styles = StyleSheet.create({
    checkBoxWrapper: {
        position: 'relative',
        width: '98%',
        borderWidth: 1,
        borderColor: Colors.lightGrey,
        paddingHorizontal: scale(15),
        paddingVertical: scale(8),
        marginVertical: scale(5),
        borderRadius: 2,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemChecked: {
        borderColor: Colors.brandSuccess,
        backgroundColor: Colors.viewBackground,
    },
    checkmarkWrapper: {
        backgroundColor: '#FFFFFF',
        top: scale(-5),
        end: scale(-5),
        position: 'absolute',
    },
    checkmark: {
        fontSize: scale(15),
        color: Colors.brandSuccess,
    },
    label: {
        fontSize: scale(12),
    },
    active: {
        fontSize: scale(12),
        color: Colors.futureColor,
    },
});