import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MoreScreen from '../../../screens/MoreScreen';
import { logoutUser } from '../../../actions/auth';

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      logoutUser,
    },
    dispatch
  );
};

const mapStateToProps = state => ({});

class MoreContainer extends React.Component {
  static navigationOptions = ({ navigation, navigationOptions }) => ({
    header: null,
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MoreScreen
        navigation={this.props.navigation}
        logoutUser={this.props.logoutUser}
      />
    );
  }
}

MoreContainer.propTypes = {
  navigation: PropTypes.instanceOf(Object),
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MoreContainer);

