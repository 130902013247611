import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8RRestart(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#191919" {...props}>
          <Path d="M25 2a1 1 0 100 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25c0-6.693 3.13-12.635 8-16.48V15a1 1 0 102 0V5H4a1 1 0 100 2h6.7C5.401 11.215 2 17.712 2 25c0 12.69 10.31 23 23 23s23-10.31 23-23S37.69 2 25 2z" />
      </Svg>
  );
}

export default SvgIcons8RRestart;
