import React from 'react';
import {globalStyles} from "../../../styles/Global";
import { View } from 'react-native';
import {verticalScale,scale} from 'react-native-size-matters';
import {Colors} from "../../../styles/Colors";

export const DrawerBar = props => {
    // backgroundColor:Colors.graySeparator,
    return (
        <View style={[globalStyles.centerItems,{width: scale(38),marginVertical: verticalScale(10),borderRadius: 5, height: verticalScale(4)}]}/>
    )
};



