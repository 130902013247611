import {Badge, Icon, View} from 'native-base';
import {ActivityIndicator, StyleSheet, TouchableOpacity} from 'react-native';
import React from 'react';
import UserAvatar from 'react-native-user-avatar';
import {Colors} from '../../styles/Colors';
import {scale} from 'react-native-size-matters';


function UserAvatarCameraEdit({onPress, onMaskPress, name, src, isInEditMode, isEditable, isUpdatingImage, tempImage, size = null}) {
    return (
        <View>
            <TouchableOpacity onPress={onPress}>
                {isUpdatingImage ?
                    <ActivityIndicator style={styles.loader} size='large' color={Colors.white}/> : null}
                <UserAvatar size={size ? size : scale(80)}
                            name={name}
                            key={isUpdatingImage ? tempImage : src}
                            // component={<FitImageWrap src={isUpdatingImage ? tempImage : src}/>}
                            src={isUpdatingImage ? tempImage : src}
                            style={{opacity: isUpdatingImage ? 0.7 : 1}}
                />
            </TouchableOpacity>
            {isEditable && !isUpdatingImage ? (
                <TouchableOpacity style={styles.wrap} onPress={() => onMaskPress()}>
                    <Badge style={styles.badge}>
                        <Icon
                            type="MaterialCommunityIcons"
                            name="camera"
                            style={styles.icon}
                        />
                    </Badge>
                </TouchableOpacity>
            ) : null}
        </View>
    );
}

const styles = StyleSheet.create({
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10
    },
    badge: {
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backgroundColor: Colors.sectionHeaderColor
    },
    wrap: {
        zIndex: 1000,
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: 30,
    },
    icon: {
        color: Colors.black,
        fontSize: 16,
        lineHeight: 24,
        zIndex: 100
    },
});


export default UserAvatarCameraEdit;

