import React, { useRef } from 'react';
import {AppText} from "../AppText";
import {View,Button} from 'react-native';
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import {globalStyles} from "../../../styles/Global";
import SvgBrokenBikes from '../../../svg/custom/BrokenBikes';
import { Colors } from '../../../styles/Colors';
import { Icons8Generator } from '../Icons8Generator';
import DrawerHandler from '../../DrawerHandler';
import { Separator } from '../Separator';
import { Pressable } from 'react-native';
import { Text } from 'react-native';
import { getToastDefaultConfig } from '../../../helpers/functions';
import { t } from '../../../services/i18n';
import Toast from 'react-native-root-toast';

export const CustomFallback = props => {
    
    const onReportPress = () => {
        Toast.show(t(t('screens:CustomFallback:thanks', {}), {}), {
            backgroundColor: Colors.brandSuccess,
            textColor: Colors.white,
            duration: 2500,
            opacity: 0.85,
            shadowColor: Colors.iconGray,
        });
    }
        // props.resetError();

    return (
        <View style={[styles.container]}>
                <Icons8Generator onPress={props.resetError} style={styles.closeButton} width={scale(30)} height={scale(30)} name="close"/>
            <AppText style={[styles.title,globalStyles.marginTopBetweenTextElements]}>{t('screens:CustomFallback:ooops', {})}</AppText>
            <AppText style={[styles.subTitle,globalStyles.marginTopMedium]}>{t('screens:CustomFallback:somethingWrong', {})}</AppText>
            <Separator distance={63}/>
            <SvgBrokenBikes width={scale(276)} height={scale(272)}/>
            <Separator distance={63}/>         
            <Pressable /*color={Colors.brandedBlue}*/ onPress={props.resetError} /*title={'Try again'}*/>
                <Text style={[styles.button]}>{t('screens:CustomFallback:tryAgain', {})}</Text>
            </Pressable>
            <Separator distance={18}/>         
            <Pressable style={[globalStyles.marginTopBetweenTextElements]} /*color={Colors.brandedBlue}*/ onPress={onReportPress} /*title={'Report a Problem'}*/>
                <Text style={[styles.button]}>{t('screens:CustomFallback:report', {})}</Text>
            </Pressable>
        </View>
    )
} 
    
    const styles = ScaledSheet.create({
        button: {
            color: Colors.brandedBlue,
            fontSize: scale(16),
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexDirection:'column',
            justifyContent: 'center',
            position: 'relative',
        },
        title: {
            color: 'black',
            fontWeight: '400',
            fontSize: scale(32),
            fontFamily: 'heebo-bold',
        },
        bike: {
            marginBottom: '100px',
        },
        subTitle: {
            color: 'black',
            fontWeight: '300',
            fontSize: scale(16)
        },
        closeButton:{
            position: 'absolute',
            right: scale(26),
            top: verticalScale(65),
        }
    });




