import React from 'react';
import {connect} from "react-redux";
import {View, StyleSheet, ScrollView} from 'react-native';
import {bindActionCreators} from "redux";
import Spinner from 'react-native-loading-spinner-overlay';
import {globalStyles} from "../../styles/Global";
import {AppText} from "../../components/Stateless/AppText";
import {Colors} from "../../styles/Colors";
import {LinearWrapper} from "../../components/Stateless/LinerWrapper";
import AppHeader from "../../components/Stateless/AppHeader";
import {scale} from "react-native-size-matters";
import PurchaseActions from "../../components/Shopping/PurchaseActions";
import Price from "../../components/Shopping/Price";
import moment from 'moment';
import Cart from '../../components/Shopping/Cart'
import {MembershipDeparments, MembershipLength, ProductImageOrIcon} from "../../components/Stateless/MembershipDisplayer";
import {MEMBERSHIP_TYPES_CONST} from "../../constants/membershipTypes";
import DatePickerWrapper from "../../components/DatePickerWrapper";
import {t} from "../../services/i18n";
import {calcMembershipEndDate, ICON_SIZE_100} from "../../helpers/functions";
import {verticalScale} from 'react-native-size-matters';
import date from '../../services/i18n/date';
import {patchMembershipStartDate} from '../../actions/shop'
import {GraySeparator} from "../../components/Stateless/GraySeparator";
import uuid from 'uuid';
import {SERVER_DATE_FORMAT} from "../../variableConstants";


class ProductScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onDatePickerClose = this.onDatePickerClose.bind(this);
        this.state = {
            isLoading: true,
            membership: null
        }

    }

    componentDidMount() {
        //TODO ADD HANDLER FOR IDS
        this.setState({membership: {...this.props.navigation.state.params.membership}, isLoading: false});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            console.log('items changed');
            Object.keys(this.props.items).forEach(arr => {
                this.props.items[arr].map((item) => {
                    if (this.state.membership.id === item.id) {
                        this.setState({membership: {...item}, isLoading: false});
                    }
                })

            })
        }
        if(this.state.membership.id !== this.props.navigation.state.params.membership.id){
            this.setState({membership: {...this.props.navigation.state.params.membership}, isLoading: false});
        }
    }


    render() {
        return (
            <LinearWrapper
                backgroundStart={Colors.white}
                backgroundEnd={Colors.white}
                barStyle="light-content"
                spinner={this.state.isLoading}>
                <View style={[globalStyles.flex, globalStyles.appPaddingHorizontal]}>
                    {<AppHeader pop={true} headerColor={Colors.black}>
                        <Cart fill={Colors.black}/>
                    </AppHeader>}
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[{flexGrow: 1}, globalStyles.screenBottomPadding]}>
                        {this.screen()}
                    </ScrollView>
                    <PurchaseActions membership={this.state.membership}/>
                </View>
            </LinearWrapper>
        );
    }

    screen() {
        const {membership} = this.state;
        if (this.state.isLoading === true)
            return <Spinner visible={true}/>;
        return (
            <View style={[globalStyles.marginTopMedium]}>
                {this.imageOrIcon()}
                <AppText style={[globalStyles.marginTopBetweenElements, globalStyles.heeboMedium, globalStyles.title]}>{membership.name}</AppText>
                <View style={globalStyles.flexDirectionRow}>
                    <MembershipLength membership={membership} style={[{color: Colors.grayText,marginTop: 0},globalStyles.heeboLight]}/>
                </View>
                <MembershipDeparments membership={membership}/>
                {this.description()}
                <GraySeparator height={1}/>
                <Price membership={membership} showTaxes={true}/>
                {this.handleStartDate()}
                {this.renderMembershipLimitations()}
            </View>
        )
    }

    description(){
        if(this.state.membership.description){
            return (
                <View>
                    <GraySeparator height={1}/>
                    <AppText>{this.state.membership.description}</AppText>
                </View>
            )
        }
    }

    handleStartDate() {
        const {membership} = this.state;
        if(membership.type === MEMBERSHIP_TYPES_CONST.TYPE_ITEM)
            return null;
        const startDate = membership.start || moment().format(SERVER_DATE_FORMAT);
        const calcEndDate = calcMembershipEndDate(membership.period_time_unit, membership.period_amount, startDate);
        const endDate = calcEndDate ? date.format(calcEndDate, 'LL') : null;
        return (
            <View style={[globalStyles.flexDirectionColumn]}>
                <GraySeparator height={1}/>
                <AppText
                    style={[ {marginBottom: verticalScale(3)}]}>{t('screens:Product:startDate', {})}</AppText>
                <DatePickerWrapper minimumDate={new Date()} onDatePickerClose={this.onDatePickerClose}
                                   startDate={startDate}/>
                {endDate ? <AppText style={[globalStyles.marginTopMedium,globalStyles.smallTitle]}>{t('screens:Product:endDate', {endDate})}</AppText> : null}
            </View>
        )

    }

    async onDatePickerClose(date) {
        //Set date in Reducer
        const {membership} = this.state;
        const startDate = moment(date).format(SERVER_DATE_FORMAT);
        const endDate = calcMembershipEndDate(membership.period_time_unit, membership.period_amount, startDate);
        const payload = {
            id: membership.id,
            start: startDate,
            end: endDate
        };
        await this.props.patchMembershipStartDate(payload);
    }

    imageOrIcon() {
        //Handle image or icon.png
        if (this.state.membership.type !== MEMBERSHIP_TYPES_CONST.TYPE_ITEM) return null;
            return (
                <View style={[styles.imageWrap,globalStyles.centerItems,globalStyles.cardsShadow]}>
                    <ProductImageOrIcon membership={this.state.membership} size={ICON_SIZE_100}/>
                </View>
            )
    }


    renderMembershipLimitations() {
        const {membership} = this.state;
        if (Object.prototype.hasOwnProperty.call(membership, 'limitations') && membership.limitations.length > 0) {
            const limitations = membership.limitations;
            return (
                <View style={globalStyles.flexDirectionColumn}>
                    <GraySeparator height={1}/>
                    {limitations.map((item,key) => {
                        const header = (<AppText key={uuid.v4()} style={[globalStyles.grayText, globalStyles.heeboLight,{fontSize:scale(11)},key !== 0 ? {marginTop: verticalScale(15)} : {}]}>{item.header}</AppText>);
                            const values = item.values.map(prop => <AppText>{prop}</AppText>);
                        return [header,values]
                    })}
                </View>
            )
        }
        return null;
    }

}

const styles = StyleSheet.create({

    imageWrap: {
        width: scale(150),
        height: scale(150),
        alignSelf: "center",
        borderRadius: 4
    }
});

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            patchMembershipStartDate
        },
        dispatch,
    );
};


const mapStateToProps = state => ({
    cart: state.shop.cart,
    items: state.shop.items,
    whiteLabelProperties: state.whiteLabelProperties
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ProductScreen);
