import React from 'react';
import {ScrollView, View, RefreshControl} from 'react-native';
import {t} from '../../../services/i18n';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchNotifications} from "../../../actions/notifications";
import FeedList from '../../../components/FeedList'
import {globalStyles} from "../../../styles/Global";
import HeaderWrapper from "../../../components/HeaderWrapper";
import {StatusBarWrap} from "../../../components/Stateless/StatusBarWrap";


class NotificationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.onRefresh = this.onRefresh.bind(this);

    }

    componentDidMount = async () => {
        this.props.fetchNotifications();
    };

    render() {
        return (
            <React.Fragment>
                <StatusBarWrap/>
                    <HeaderWrapper
                        headerTitle={t('screens:More:settings:notifications:title',{})}
                        headerArrowColor={this.props.whiteLabelProperties.primaryColorText}
                        padding={globalStyles.appMinimalPaddingHorizontal}
                        isPop={false}
                    />
                {this.screen()}
            </React.Fragment>
        );
    }


    onRefresh() {
        this.props.fetchNotifications();
    }

    screen() {
        return (
            <View style={{flex: 1}}>
                <ScrollView
                    contentContainerStyle={[globalStyles.marginTopBetweenElements,globalStyles.screenBottomPadding]}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={this.onRefresh}
                        />
                    }>
                        <FeedList screen={'notifications'}
                                  feeds={this.props.notifications}
                                  isLoading={this.props.isLoading}
                        />
                </ScrollView>
            </View>
        );
    }


}

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        fetchNotifications
    }, dispatch);
};

const mapStateToProps = state => ({
    user: state.user.data,
    notifications: state.notifications.data,
    isLoading: state.notifications.isLoading,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(NotificationScreen);

