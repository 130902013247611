import { Platform, StyleSheet } from 'react-native';
import { scale } from 'react-native-size-matters';
import { Colors } from '../../styles/Colors';

let styles;
export default (styles = StyleSheet.create({
  roundBackground: {
    borderRadius: 100,
    width: scale(60),
    height: scale(60),
    alignItems: 'center',
    justifyContent: 'center',
  },
  topTint: {
    borderTopWidth: 0.7,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));
