// @flow
import * as React from 'react';
import {TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import {useEffect, useState} from "react";
import {isMobile} from "../../../../helpers/functions";

const YesNoQuestion = (props) => {
    const {onSelect, answer} = props;
    const [selectedAnswer, setSelectedAnswer] = useState(answer || null);

    useEffect(() => {
        setSelectedAnswer(answer);
    }, [answer]);


    const answerSelected = (answer) => {
        setSelectedAnswer(answer)
        onSelect(answer)
    }

    return (
        <View style={[styles.questionWrapper, isMobile() && {margin: 'auto', height: 'auto'}]}>
            <TouchableOpacity style={[styles.answerBtn, (selectedAnswer === true) && styles.selectedAnswerBtn]} onPress={() => answerSelected(true)}>
                <AppText style={[styles.answerText, (selectedAnswer === true) && styles.selectedAnswerText]}>{t('screens:DigitalForms:yes', {})}</AppText>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.answerBtn, (selectedAnswer === false) && styles.selectedAnswerBtn]} onPress={() => answerSelected(false)}>
                <AppText style={[styles.answerText, (selectedAnswer === false) && styles.selectedAnswerText]}>{t('screens:DigitalForms:no', {})}</AppText>
            </TouchableOpacity>
        </View>
    );
};

export default YesNoQuestion;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            questionWrapper: {
                flexDirection: 'row',
                gap: '1em',
                justifyContent: 'center',
                marginTop: '3em',
                height: '100%'
            },
            answerBtn: {
                height: 80,
                width: 80,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: Colors.answerBorder,
                justifyContent: 'center',
                alignItems: 'center'
            },
            answerText: {
                color: Colors.iconEmptyStateColor,
                fontWeight: '300',
                fontSize: '1.2rem'
            },
            selectedAnswerBtn: {
                backgroundColor: Colors.selectedAnswerBg,
                borderColor: Colors.lightBlack
            },
            selectedAnswerText: {
                color: Colors.lightBlack,
                fontWeight: 'bold'
            }
        }
    })
})

