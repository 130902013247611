import { StyleSheet } from 'react-native';
import { Colors } from '../../styles/Colors';
import { isTextRTLStyles } from '../../styles/Layout';

export default StyleSheet.create({
  header: {
    backgroundColor: '#FFF',
    borderBottomWidth: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  h1: {
    color: Colors.brandPrimary,
    fontSize: 14,
    fontWeight: '700',
    ...isTextRTLStyles,
  },
  xButtonIcon: {
    color: Colors.lightGrey,
    fontSize: 24
  },
  xButton: {
    position: 'absolute',
    right: 0,
    top: 9,
    backgroundColor: 'transparent',
    borderWidth: 0
  },
  button: {
    width: '90%',
    marginLeft: '5%'
  },
  buttonText: {
    color: 'grey',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    ...isTextRTLStyles,
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  footerInnerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  body: {
    marginHorizontal: 30
  },
  text: {
    color: Colors.lightGrey,
    fontSize: 14,
    ...isTextRTLStyles,
  }
});
