import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {scale, verticalScale} from 'react-native-size-matters';
import {IconSize} from "../../constants/iconSize";
import UserAvatar from 'react-native-user-avatar';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import styles from './style';
import uuid from 'uuid';
import {showModal} from '../../modals/actions';
import {fetchFriendProfile} from '../../actions/friend';
import {Colors} from '../../styles/Colors';
import {t} from '../../services/i18n';
import {globalStyles} from "../../styles/Global";
import date from '../../services/i18n/date';
import {AppText} from "../Stateless/AppText";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import DrawerHandler from "../DrawerHandler";
import FriendsListModal from "../../modals/modalFlows/FriendsListModal";
import {getUserFromFriendConnection} from "../../helpers/functions";
import {Slots, UserAvatars} from "../Stateless/ScheduleDisplayer";
import {withNavigation} from "react-navigation";
import {PAST} from "../../constants/booking";


@withNavigation
class ScheduleCardV2 extends React.PureComponent {
    state = {
        howManyUsersToDisplay: 2,
        drawerAction: null
    };

    constructor(props) {
        super(props);
    }


    renderCoach() {
        const {data} = this.props;
        const disableCoachDisplay = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'coach');
        return (
            <View style={globalStyles.flexDirectionRow}>
                <Icons8Generator size={IconSize.XS} name={'user'}/>
                <AppText
                    style={[globalStyles.paddingStart, globalStyles.smallTitle]}>{data.coach && !disableCoachDisplay ? data.coach.full_name : '--'} {data.second_coach && !disableCoachDisplay ? `& ${data.second_coach.full_name}` : null}</AppText>
            </View>
        );
    }

    renderIndicator() {
        const {data} = this.props;
        let text, color;
        if (data.late_cancellation === 1) {
            text = t('screens:Schedule:card:late-cancel', {});
            color = Colors.red;
        } else if (data.user_booked) {
            text = t('screens:Schedule:card:booked', {});
            color = Colors.green;
        } else if (data.user_in_standby) {
            text = t('screens:Schedule:card:in-waitlist', {});
            color = Colors.yellow;
        } else if (data.booking_option === PAST) {
            return null;
        } else {
            return <Icons8Generator name={'plus'} size={IconSize.M}
                                    fill={this.props.whiteLabelProperties.primaryColor}/>;
        }
        return (
            <View key={uuid.v4()} style={[styles.v2IndicatorWrap, {backgroundColor: color}]}>
                <AppText style={styles.v2IndicatorText}>{text}</AppText>
            </View>
        )
    }

    //Created to support new scheduleCard
    renderScheduleCard() {
        return (
            <TouchableOpacity
                onPress={() => this.props.navigation.navigate("ScheduleSingle", {data: this.props.data})}
                style={[globalStyles.flexDirectionRow, {marginBottom: verticalScale(10), marginEnd: scale(10)}]}>
                {this.renderTime()}
                <View style={[globalStyles.cardsShadow,
                    {
                        width: '80%',
                        backgroundColor: Colors.white,
                        borderRadius: 5,
                        elevation: 5,
                    }]}>
                    <View style={{padding: scale(12)}}>
                        {this.renderClassNameStatus()}
                        <View style={globalStyles.marginTopLarge}>
                            {this.renderCoach()}
                            {this.renderCapacityAndUsers()}
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    renderCapacityAndUsers() {
        const {data} = this.props;
        const hasWaitingList = data.max_users - data.registered === 0
        return (
            <View key={uuid.v4()}
                  style={[globalStyles.flexDirectionRowSpaceBetween, globalStyles.marginTopRegular, {alignItems: 'center'}]}>
                {<Slots data={this.props.data} textOverride={{...globalStyles.smallTitle}}/>}
                {<UserAvatars data={this.props.data} marginBetweenAvatars={0}
                              displayAmount={hasWaitingList ? 1 : 2} size={20}/>}
            </View>
        )
    }

    renderClassNameStatus() {
        const {data} = this.props;
        return (
            <View style={[globalStyles.flexDirectionRowSpaceBetween]}>
                <View style={[globalStyles.flexDirectionRow]}>
                    <AppText numberOfLines={1}
                             style={[globalStyles.heeboRegular, globalStyles.subTitle, {maxWidth: scale(150)}]}>{data.box_categories.name}</AppText>
                    {data.live_link ? <Icons8Generator size={IconSize.XXS}
                                                       style={[globalStyles.centerItems, globalStyles.paddingStartLarge]}
                                                       name={'tv'}/> : null}
                </View>
                <View style={globalStyles.centerItems}>
                    {this.renderIndicator()}
                </View>
            </View>
        )
    }

    renderTime() {
        const {data} = this.props;
        return (
            <View style={[globalStyles.centerItems, {width: '20%'}]}>
                <AppText>{date.timeFormat(data.time, 'LT', true, false)}</AppText>
            </View>
        )
    }

    closeDrawer() {
        if (this.RBSheet) {
            this.RBSheet.close()
        }
    }

    render() {
        const {data} = this.props;
        return (
            <View key={data.id}>
                {this.state.drawerAction ? (
                    <DrawerHandler key={uuid.v4()} noMargin={false}
                                   title={t('common:drawer:booked', {})}
                                   ref={el => this.RBSheet = el ? el.ref.current : null}>
                        <FriendsListModal
                            information={data}
                            closeDrawer={() => this.closeDrawer()}/>
                    </DrawerHandler>
                ) : null}
                {this.renderScheduleCard()}
            </View>
        );
    }
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchFriendProfile,
            showModal,
        },
        dispatch,
    );

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScheduleCardV2);
