import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8LogoutRoundedUp(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M24.984 1.986a1 1 0 00-.78.397l-7.911 7.91a1 1 0 101.414 1.414L24 5.414V27a1 1 0 102 0V5.414l6.293 6.293a1 1 0 101.414-1.414l-7.916-7.916a1 1 0 00-.807-.39zM37.94 6.238a1 1 0 00-.53 1.823C42.616 11.882 46 18.038 46 25c0 11.61-9.39 21-21 21S4 36.61 4 25c0-6.962 3.383-13.118 8.592-16.94a1 1 0 00-.559-1.814 1 1 0 00-.625.203C5.705 10.633 2 17.39 2 25c0 12.69 10.31 23 23 23s23-10.31 23-23c0-7.61-3.705-14.367-9.408-18.55a1 1 0 00-.653-.212z" />
    </Svg>
  );
}

export default SvgIcons8LogoutRoundedUp;
