import React from 'react';
import {ScrollView, View, TouchableOpacity, Text} from 'react-native';
import PropTypes from 'prop-types';
import {scale} from 'react-native-size-matters';
import FitImage from 'react-native-fit-image';
import {Colors} from '../../styles/Colors';
import styles from './styles';
import Spinner from 'react-native-loading-spinner-overlay';
import {globalStyles} from "../../styles/Global";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {BIG_ICON_SIZE} from "../../helpers/functions";

const DEFAULT_CLUB_ICON = 'clubs';


class ChooseClubList extends React.PureComponent {
    renderCheckmark() {
        return (
            <View style={styles.checkmarkWrapper}>
                <Icons8Generator name={'check-circle'}/>
            </View>
        );
    }

    renderClubRow(club) {
        const {selectClubId, selectedBoxId} = this.props;
        const checked = selectedBoxId === club.id;
        const image = club.cloudinary_image ? club.cloudinary_image : club.locations_box[0].logo;
        return (
            <TouchableOpacity
                style={[styles.clubRow, checked ? {borderColor: Colors.brandSuccess} : {}]}
                key={club.id}
                onPress={() => selectClubId(club.id)}
            >
                {checked ? this.renderCheckmark() : null}
                <View>
                    {!image ? (
                        <View style={globalStyles.clubImagePlaceholder}>
                            <Icons8Generator name={DEFAULT_CLUB_ICON}  width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}  fill={Colors.black}/>
                        </View>
                    ) : (
                        <FitImage
                            resizeMode="contain"
                            source={{uri: image}}
                            style={[globalStyles.cardRadius, {width: scale(40),height:scale(40)}]}
                        />                    )}
                </View>
                <View style={styles.clubInfoWrapper}>
                    <View style={styles.nameWrapper}>
                        <Text style={styles.name}>{club.name}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }

    render() {
        const {clubsList} = this.props;
        if (clubsList) {
            return (
                <View style={styles.chooseClubListWrapper}>
                    <ScrollView>
                    {clubsList.map(club => this.renderClubRow(club))}
                    </ScrollView>
                </View>
            );
        }
        return <Spinner  visible={true}/>
    }
}
// TODO UNDERSTAND WTF THEY MEANT TO DO
ChooseClubList.propTypes = {
    selectClubId: PropTypes.func,
    selectedBoxId: PropTypes.number,
    clubsList: PropTypes.instanceOf(Array),
};

export default ChooseClubList;
