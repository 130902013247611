// import i18next from 'i18next';


const plural = {

  init(i18next) {
    return new Promise((resolve, reject) => {
      try{
          const origGetSuffix = i18next.services.pluralResolver.getSuffix;
          i18next.services.pluralResolver.getSuffix = (code, count, ...other) => {
              if (code === 'he') {
                  if (count > 1) {
                      return '_plural';
                  }
                  return '';
              }
              return origGetSuffix.call(i18next.services.pluralResolver, code, count, ...other);
          };
          return resolve();
      }catch (error){
        reject(error);
      }
    })
  },
};

export default  plural;
