import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Calendar(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#191919" {...props}>
          <Path d="M12 0c-1.094 0-2 .906-2 2v2H4c-1.16 0-2 .84-2 2v7h46V6c0-1.16-.84-2-2-2h-6V2c0-1.094-.906-2-2-2h-2c-1.094 0-2 .906-2 2v2H16V2c0-1.094-.906-2-2-2zm0 2h2v6h-2zm24 0h2v6h-2zM2 15v31c0 1.16.84 2 2 2h42c1.16 0 2-.84 2-2V15zm10 6h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm-21 7h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm-21 7h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5z" />
      </Svg>
  );
}

export default SvgIcons8Calendar;
