import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';
import MoreContainer from './MoreContainer';
import ClubContainer from './ClubContainer';
import LogbookContainer from './Logbook/LogbookContainer';
import SettingsContainer from './SettingsContainer';
import AddWorkoutScreen from '../../screens/Logbook/AddWorkoutScreen';
import SearchScreen from "../../screens/SearchScreen";
import WorkoutScreen from "../../screens/Logbook/WorkoutScreen";
import SkillScreen from "../../screens/Logbook/SkillScreen";
import FriendProfileScreen from "../../screens/FriendProfileScreen";

let MoreGroupNavigator;
export default MoreGroupNavigator = createStackNavigator({
        Workout: WorkoutScreen,
        Skill: SkillScreen,
        More: MoreContainer,
        Clubs: ClubContainer,
        Logbook: {
            screen: LogbookContainer,
            header: null,
            navigationOptions: {header: null},
        },
        Settings: SettingsContainer,
        AddWorkoutScreen: AddWorkoutScreen,
        FriendProfile: {screen: FriendProfileScreen},
        LogbookSearch: {screen: SearchScreen}
        // forgive me //TODO FIGURE WTF DID THEY DO HERE
        // SingularWorkout: {
        //     screen: LogbookWorkoutScreen,
        //     header: null,
        //     navigationOptions: {header: null},
        // },
        // WorkoutLeaderboard: {
        //     screen: LeaderboardScreen,
        //     header: null,
        //     navigationOptions: {header: null},
        // },
        // SingularRM: {
        //     screen: SingularRMScreen,
        //     header: null,
        //     navigationOptions: {header: null},
        // },
        // AddWorkoutScreen-OLD: {
        //     screen: AddWorkoutScreen-OLD,
        //     header: null,
        //     navigationOptions: {header: null},
        // },
        // TermsOfUse: TermsOfUseScreen,
    },
    {
        initialRouteName: 'More',
        defaultNavigationOptions:{
                header: null,
            headerMode:'none',
                navigationOptions: {header: null},
        }
    }
);

MoreGroupNavigator.navigationOptions = {
    header: null,
    navigationOptions: {header: null},

};


