import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8PencilDrawing(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M35.688 2a1.005 1.005 0 00-.594.313l-26 25.968a1.01 1.01 0 00-.219.375l-6.813 18a.992.992 0 00.235 1.047.992.992 0 001.047.234l18-6.812a1.01 1.01 0 00.375-.219L23 39.625a1.01 1.01 0 00.531-.531l24.157-24.188c.296-.242.433-.629.347-1.004a1.006 1.006 0 00-.75-.75 1.004 1.004 0 00-1.004.348L22.344 37.406a7.051 7.051 0 00-3.219-.656c.164-.445.41-.71.5-1.25.219-1.3.102-2.898-1.063-4.063-1.167-1.167-2.777-1.277-4.093-1.03-.465.085-.692.323-1.094.468.016-.14.059-.164.063-.313a4.918 4.918 0 00-.844-2.906L36.5 3.72A1.002 1.002 0 0035.781 2h-.093zM11.093 29.156c.25.457.355.903.344 1.344-.016.68-.235 1.266-.376 1.656a.999.999 0 001.563 1.125c.285-.222 1.238-.73 2.188-.906.949-.176 1.792-.082 2.343.469.555.554.66 1.402.5 2.343-.16.942-.652 1.895-.843 2.157a1.002 1.002 0 001.125 1.562c1.269-.386 2.257-.25 2.937-.031l-.469.469L9.375 43.53 6.5 40.625l4.156-11.031z" />
    </Svg>
  );
}

export default SvgIcons8PencilDrawing;
