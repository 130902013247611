import { StyleSheet } from 'react-native';
import { scale, ScaledSheet } from 'react-native-size-matters';
import { Colors } from '../../../styles/Colors';

export default ScaledSheet.create({
  modalContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: scale(15),
  },
  modalContentWrapper: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: scale(10)
  },
  modalCloseButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  modalContentAvatar: {
    //marginTop: '-55@s',
    alignItems: 'center',
  },
  userAvatarText: {
    marginTop: scale(17),
    fontSize: scale(13),
    fontWeight: '800',
    justifyContent: 'center',
    fontFamily: 'heebo-bold',
  },
  modalContentBody: {
    marginTop: scale(27),
    paddingHorizontal: scale(20),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: scale(38)
  },
  modalContentText: {
    fontSize: scale(12),
    color: 'rgba(17, 17, 17, 1)',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'heebo'
  },
  modalContentFooter: {
    borderRadius: scale(10)
  },
  modalButtonWrapper: {
    width: '100%',
    height: scale(50),
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: scale(10),
    borderBottomRightRadius: scale(10)
  },
  modalButtonText: {
    fontSize: scale(16),
    fontFamily: 'heebo-medium',
    color: Colors.brandPrimary,
    textAlign: 'center',
  },
  userPlusIcon: {
    marginTop: scale(35),
    marginBottom: scale(20)
  },
  friendRequestSuccessModalTitle: {
    fontFamily: 'heebo-bold',
    fontSize: scale(12),
    fontWeight: '400',
    color: Colors.brandPrimary,
    marginBottom: scale(10)
  },
  friendRequestSuccessModalContent: {
    fontFamily: 'heebo',
    fontSize: scale(12),
    fontWeight: '400',
    color: Colors.slateGrey,
  },
  actionBtnStyle: {
    marginTop: scale(24),
    marginBottom: scale(35)
  },
  actionBtnText: {
    color: Colors.brandPrimary,
    fontFamily: 'heebo-bold',
    fontSize: scale(16),
  }
});
