import {FRIEND_CONNECTIONS, FRIEND_CONNECTIONS_ACTION} from '../constants';

const initialState = {
    isLoading: false,
    data: [],
    error: null,
    action: {
        isLoading: false,
        error: null
    }

};

const connectionsReducer = (state = initialState, action) => {
    const friendConnections = FRIEND_CONNECTIONS.toUpperCase(); // just MAKE SURE IT IS UPPER
    const friendConnectionsAction = FRIEND_CONNECTIONS_ACTION.toUpperCase();
    switch (action.type) {
        case `${friendConnections}_PENDING`:
            return {
                ...state,
                isLoading: true,
            };
        case `${friendConnections}_SUCCESS`: {
            return {
                ...state,
                isLoading: false,
                data:action.payload,
                error: null
            };
        }
        case `${friendConnections}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case `${friendConnectionsAction}_PENDING`:{
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                }
            };
        }

        case `${friendConnectionsAction}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map(item => item.id === action.payload.id ? {...item,our_relation: action.payload.our_relation} : item),
                action: {
                    error:null,
                    isLoading: false,
                }
            };
        }
        case `${friendConnectionsAction}_FAILED`:
            return {
                ...state,
                action: {
                    error: action.payload,
                    isLoading: false,
                }
            };

        default:
            return state;
    }
};

export default connectionsReducer;