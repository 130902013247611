import React from 'react';
import {View, ScrollView} from 'react-native';
import {globalStyles} from "../../styles/Global";
import {scale, verticalScale, ScaledSheet} from "react-native-size-matters";
import {AppText} from "../Stateless/AppText";
import {Colors} from "../../styles/Colors";

const TableHeader = ['Date', 'Weight', 'Trend'];
import date from '../../services/i18n/date';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {BIG_ICON_SIZE} from "../../helpers/functions";
import {t} from '../../services/i18n';

class LogbookRmTableData extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tableHead: [t('screens:Logbook:skillScreen:date', {}), t('screens:Logbook:skillScreen:weight', {}),
                t('screens:Logbook:skillScreen:trend', {})],
            tableData: []
        };
        this.columnWidth = scale(100);
    }

    componentDidMount() {

    }

    tabView() {
        const data = this.props.title in this.props.userRm ? this.props.userRm[this.props.title] : [];
        return (
            <View style={[globalStyles.flex]}>
                <ScrollView contentContainerStyle={[{flexGrow: 1}, globalStyles.screenBottomPadding]}>
                    <View style={[globalStyles.cardContainer, globalStyles.appTopBorderRadius]}>
                        <View style={[globalStyles.flexDirectionRowSpaceBetween, styles.row,{alignItems:'center'}]}>
                            {TableHeader.map((item,i) => <View key={i} style={[{width: this.columnWidth}]}><AppText
                                style={globalStyles.textCenter}>{item}</AppText></View>)}
                        </View>
                        {this.tableData(data)}
                    </View>
                </ScrollView>
            </View>
        )
    }

    tableData(data) {
        const length = data.length;
        return (
            data.map((item, i) => {
                return (
                    <View key={i}
                          style={[globalStyles.flexDirectionRowSpaceBetween,{alignItems:'center'}, length === i + 1 ? {height: verticalScale(40)} : styles.row]}>
                        <AppText
                            style={[globalStyles.textCenter, {width: this.columnWidth}]}>{date.dayMonth(item.date)}</AppText>
                        <AppText
                            style={[globalStyles.textCenter, {width: this.columnWidth}]}>{item.skill_weight}</AppText>
                        <View
                            style={[globalStyles.centerItems, {width: this.columnWidth}]}>{this.renderTrend(item, data[i + 1])}</View>
                    </View>
                )
            })
        )
    }

    renderTrend(item, nextItem) {
        if (nextItem) {
            if (item.skill_weight > nextItem.skill_weight)
                return <Icons8Generator name={'arrow-up'} width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}
                                        fill={Colors.brandedGreen}/>
            else if (item.skill_weight < nextItem.skill_weight)
                return <Icons8Generator name={'arrow-down'} width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}
                                        fill={Colors.brandedRed}/>
        }
        return null;
    }

    render() {
        return (
            this.tabView()
        );
    }
}

const styles = ScaledSheet.create({
    row: {
        borderBottomColor: Colors.graySeparator,
        borderBottomWidth: verticalScale(1),
        height: verticalScale(40)
    }
});


export default LogbookRmTableData;
