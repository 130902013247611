import { StyleSheet } from 'react-native';
import { scale } from 'react-native-size-matters';
import { Colors } from '../../styles/Colors';
import { isTextRTLStyles } from '../../styles/Layout';
import i18n from '../../services/i18n';

export default StyleSheet.create({
  settingsList: {
    paddingBottom: scale(200),
  },
  greyBottom: {
    backgroundColor: Colors.darkWhite,
  },
  saveButton: {
    marginTop: scale(25),
    backgroundColor: Colors.brandPrimary,
    borderRadius: 6,
    marginHorizontal: scale(25),
  },
});
