import React from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {scale, ScaledSheet, verticalScale} from 'react-native-size-matters';
import {withNavigation} from "react-navigation";
import {Colors} from "../../../styles/Colors";
import ScheduleScreen from "../ScheduleScreen";
import Spinner from "react-native-loading-spinner-overlay";
import {fetchAppointmentTypes} from "../../../actions/schedule";
import {fetchCoachesByBox} from "../../../actions/boxes";
import {fetchCurrencySymbol} from "../../../actions/shop";
import {AppText} from "../../../components/Stateless/AppText";
import {globalStyles} from "../../../styles/Global";
import {t} from "../../../services/i18n";
import {apiAction} from "../../../helpers/HTTP";
import {ScheduleTypes} from "../../../constants/schedule";
import moment from "moment";
import {getPriceFromBoxCategories} from "../../../helpers/functions";


@withNavigation
class AppointmentTypesScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            selectedBoxCategory: null,
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentLocationsBox !== prevProps.currentLocationsBox) { // Workaround for schedule container usage, might need to move to schedule screen
            this.init();
        }
        if (this.props.selectedBoxCategory !== prevProps.selectedBoxCategory && this.props.selectedBoxCategory === null) {
            this.setState({selectedBoxCategory: this.props.selectedBoxCategory})
        }

    }

    async init() {
        this.setState({isLoading: true});
        await this.props.fetchAppointmentTypes(this.props.currentBox,this.props.currentLocationsBox);
        await this.props.fetchCurrencySymbol(`shop/currencySymbol/${this.props.currentLocationsBox}`);
        const coachCount =  await apiAction(`boxes/coachCount/${this.props.currentBox}`,'get', null);
        if(this.props.appointmentTypes.length === 1){//Auto select on
            this.handleSelectedCategory(this.props.appointmentTypes[0])
        }
        this.setState({coachCount,isLoading: false});
    }

    render() {
        const {isLoading} = this.state;
        if (isLoading) {
            return <Spinner visible={isLoading}/>
        }
        if (!this.state.selectedBoxCategory) {
            return this.appointmentTypesList(this.props.appointmentTypes)
        } else {
            this.props.onSelected({
                boxCategories: this.state.selectedBoxCategory,
                length: this.props.appointmentTypes.length
            })
            return (
                <ScheduleScreen
                    currentBox={this.props.currentBox}
                    currentLocationsBox={this.props.currentLocationsBox}
                    selectedBoxCategory={this.state.selectedBoxCategory}
                    date={this.state.date}
                    type={ScheduleTypes.AVAILABILITIES}
                    index={this.state.index}
                    coachCount={this.state.coachCount}
                />
            )
        }
    }


    appointmentTypesList(appointmentTypes) {
        return (
            <View style={[globalStyles.flex, globalStyles.appMinimalPaddingHorizontal]}>
                <AppText style={[globalStyles.marginVertical, globalStyles.subTitle]}>{t('screens:Schedule:chooseService',{})}</AppText>
                <ScrollView contentContainerStyle={[{flexGrow: 1}]}>
                    {appointmentTypes.map(appointmentType => (this.appointmentTypeCard(appointmentType)))}
                </ScrollView>
            </View>)
    }

    appointmentTypeCard(boxCategory) {
        //If boxCategories will have slot, it will be presented here!
        const price = getPriceFromBoxCategories(boxCategory);
        return (
            <TouchableOpacity key={boxCategory.id} style={[globalStyles.flexDirectionRow, {
                marginBottom: verticalScale(10),
            }]} onPress={() => this.handleSelectedCategory(boxCategory)}>
                <View style={[
                    globalStyles.cardsShadow,
                    globalStyles.flexDirectionRowSpaceBetween,
                    globalStyles.centerStartItems,
                    {
                        flex: 1,
                        minHeight: verticalScale(70),
                        backgroundColor: Colors.white,
                        borderStartWidth: 3,
                        borderStartColor: boxCategory.category_color || this.props.whiteLabelProperties.primaryColor,
                    }]}>
                    <View style={globalStyles.appPaddingHorizontal}>
                        <AppText style={[globalStyles.heeboRegular, globalStyles.subTitle]}>{boxCategory.name}</AppText>
                        <AppText
                            style={[globalStyles.heeboLight, globalStyles.smallTitle, globalStyles.grayText, globalStyles.marginTopSm]}>{boxCategory.length} {t("timing:minutes", {})}</AppText>
                    </View>
                    <View style={globalStyles.appPaddingHorizontal}>
                        <AppText
                            style={[globalStyles.heeboRegular, globalStyles.subTitle]}>{this.props.currencySymbol}{price}</AppText>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    //Server get closest appointment
    handleSelectedCategory(boxCategory){
        this.setState({isLoading: true}, async () => {
            const route = `availabilities/getClosestAvailability`;
            const params = {
                locations_box_id: this.props.currentLocationsBox,
                box_categories_id: boxCategory.id,
            }
            const date = await apiAction(route, 'post', params);//Returns the next date category has availability
            this.setState({isLoading: false,date: moment.utc(date),selectedBoxCategory:boxCategory});
        });
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators({
        fetchAppointmentTypes,
        fetchCoachesByBox,
        fetchCurrencySymbol
    }, dispatch);
};

const mapStateToProps = state => ({
    appointmentTypes: state.schedule.appointmentTypes.data,
    whiteLabelProperties: state.whiteLabelProperties,
    currencySymbol: state.shop.currencySymbol,
});

AppointmentTypesScreen.propTypes = {
    appointmentTypes: PropTypes.array,
};
export default connect(
    mapStateToProps,
    mapActionsToProps,
)(AppointmentTypesScreen);

