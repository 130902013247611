import React from 'react';
import {View} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {scale} from "react-native-size-matters"
import FitImage from 'react-native-fit-image';


export class FitImageWrap extends React.PureComponent {
    render() {
        return (
            <View
                style={[globalStyles.flexDirectionRow]}>
                {this.props.noImage || !this.props.src ? null : (<FitImage
                    resizeMode="contain"
                    source={{uri: this.props.src}}
                    style={[globalStyles.cardRadius, {width: scale(40), height: scale(40), borderRadius: 100}]}
                />)}
            </View>
        )
    }
}
