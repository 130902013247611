// @flow
import * as React from 'react';
import {ActivityIndicator, View} from "react-native-web";
import SearchInput from "../../Common/SearchInput";
import {t} from "../../../../services/i18n";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {useEffect, useState} from "react";
import {setActiveUsersBoxes} from "../../../../actions/user";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "../../../../helpers/functions";
import Dropdown from "../../Common/Dropdown";
import {fetchCurrencySymbol, fetchShopAbleItems} from "../../../../actions/shop";
import ShopEmptyState from "../../Shop/ShopEmptyState";
import SingleShopItem from "../../Shop/SingleShopItem";
import Card from "../../../../images/cardRepeatBg.png";
import Membership from "../../../../images/membershipRepeatBg.png";
import Bag from "../../../../images/bagRepeatBg.png";
import ExtendedCardHandler from "../../Shop/extendedCards/ExtendedCardHandler";
import {updateSiteSelectedLocation} from "../../../../actions/minisite";

const PlanSelectionStep = (props) => {
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const primaryColor = useSelector(state => state.whiteLabelProperties.primaryColor);

    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [itemsByCategoryUnfiltered, setItemsByCategoryUnfiltered] = useState(null);
    const [itemsByCategory, setItemsByCategory] = useState(null);
    const [showExtendedItem, setShowExtendedItem] = useState(null);
    const [emptyLocation, setEmptyLocation] = useState(false);

    const dispatch = useDispatch()
    const mobile = isMobile();

    useEffect(() => {
        setSearchInput('')
        dispatch(setActiveUsersBoxes(box));
        fetchMemberships()
    }, [location]);

    useEffect(() => {
        filterByInput()
    }, [searchInput]);


    const fetchMemberships = async () => {
        setIsLoading(true)
        const memberships = await dispatch(fetchShopAbleItems(`shop/${box.id}/${location.id}`));
        await dispatch(fetchCurrencySymbol(`shop/currencySymbol/${location.id}`));
        const emptyCategories = Object.values(memberships.data).filter(category => category.length > 0)
        setItemsByCategoryUnfiltered(memberships.data)
        setItemsByCategory(memberships.data);
        setEmptyLocation(emptyCategories.length === 0)
        setIsLoading(false)
    }

    const filterByInput = () => {
        if(itemsByCategoryUnfiltered) {
            let unfiltered = {}
            Object.keys(itemsByCategoryUnfiltered).map(category => {
                const filteredItems = itemsByCategoryUnfiltered[category].filter(item => {
                    if (searchInput) {
                        return item.name.toLowerCase().includes(searchInput)
                    } else return item
                })
                unfiltered[category] = filteredItems
            })
            setItemsByCategory(unfiltered);
        }
    }

    const onItemClicked = (item, dollars, cents = null) => {
        setShowExtendedItem({item, dollars, cents})
    }

    const changeLocation = (locationId) => {
        const newLocation = box?.locations_box.find(location => location.id === parseInt(locationId))
        dispatch(updateSiteSelectedLocation(newLocation))
    }

    return (
        <>
            <View style={[styles(primaryColor, mobile).wrapper]}>
                <View style={[styles(primaryColor, mobile).actions]}>
                    <SearchInput placeHolder={t('headings:search', {})} value={searchInput} onChange={setSearchInput} overrideStyles={EStyleSheet.flatten([styles(primaryColor, mobile).search, !mobile && styles().actionStyle])}/>
                    {box?.locations_box.length > 1 &&
                    <Dropdown items={box?.locations_box}
                              valuePropName='id'
                              labelPropName='location'
                              iconName={'location'}
                              initValue={location}
                              onSelection={(value) => changeLocation(value)}
                              style={EStyleSheet.flatten([!mobile && styles().actionStyle, mobile && {width: '100%'}])}
                    />}
                </View>
                <View style={[mobile && styles().membershipWrapper]}>
                    {isLoading ?
                        <ActivityIndicator color={Colors.brandedBlue} style={{marginVertical: '2em'}}/>
                        :
                        <>
                            {emptyLocation && <ShopEmptyState/>}
                            <View
                                style={[styles().itemsContainer, mobile && styles().itemsContainerMobile, {columnGap: mobile ? '1em' : '2em'}, !mobile && {maxHeight: '25em'}]}>
                                {itemsByCategory &&
                                    Object.keys(itemsByCategory).map(category => {
                                        let BgIcon = category === 'session' ? Card : category === 'plan' ? Membership : Bag;
                                        if (category !== 'item') {
                                            return (
                                                itemsByCategory[category].map(item => <SingleShopItem item={item}
                                                                                     key={`shop-web-tab-item-${item.name}`}
                                                                                     bgIcon={BgIcon}
                                                                                     hideDepartments={true}
                                                                                     isPopup={true}
                                                                                     backgroundColor={Colors.white}
                                                                                     onItemClicked={onItemClicked}/>)
                                            )
                                        }
                                    })
                                }
                            </View>
                        </>
                    }
                </View>
            </View>
            {showExtendedItem && <ExtendedCardHandler item={showExtendedItem.item} isPopup={true} dollars={showExtendedItem.dollars} cents={showExtendedItem.cents} show={true} setClose={() => setShowExtendedItem(null)} isFromRegister={true}/>}
        </>
    );
};

export default PlanSelectionStep;

const styles = (primaryColor, mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                alignItems: 'center',
                gap: mobile ? 0 : '2em'
            },
            actions: {
                flexDirection: mobile ? 'column' : 'row',
                gap: '0.5em',
                width: mobile ? '100%' : 'unset',
                paddingHorizontal: mobile ? '0.5em' : 'unset',
                backgroundColor: mobile ? Colors.white : 'unset',
                height: mobile ? EStyleSheet.value('$mobileRegisterMembershipActions') : 'unset'
            },
            search: {
                height: mobile ? 35 : '100%',
                width: mobile ? '100%' : '15em',
            },
            actionStyle: {
                backgroundColor: Colors.white,
                borderWidth: 1,
                borderColor: Colors.graySeparator,
                borderRadius: 2,
                border: '1 solid',
            },
            itemsContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                rowGap: '2em',
                // maxHeight: () => `100% - ${10 + parseInt(EStyleSheet.value('$tabsMaxHeight')) + parseInt(EStyleSheet.value('$mobileHeader'))}`,
                height: 'inherit',
                overflow: 'auto'
            },
            itemsContainerMobile: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                alignItems: 'start',
                paddingHorizontal: 10,
                height: 'unset'
            },
            membershipWrapper: {
                marginTop: '1em',
                height: `100% - ${EStyleSheet.value('$mobileRegisterMembershipActions') + EStyleSheet.value('$progressBarMobile') + EStyleSheet.value('$mobileHeader') + 10}`,
                overflow: 'auto'
            }
        }
    })
})