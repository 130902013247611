import React from 'react';
import {TouchableOpacity, View} from "react-native";
import {withNavigation} from "react-navigation";
import i18n from "../../../services/i18n";
import {scale, ScaledSheet,verticalScale} from "react-native-size-matters";
import {Icons8Generator} from "../Icons8Generator";
import {globalStyles} from "../../../styles/Global";
import {getThemeProperty,keys} from "../../../styles/Theme";
import {Colors} from "../../../styles/Colors";


class AppHeader extends React.Component {
    render() {
        return (
            <View style={[styles.headerRow,this.props.overrideStyles]}>
                    <TouchableOpacity style={[{zIndex: 1000,maxWidth: scale(50)},this.props.addPadding ? globalStyles.appPaddingHorizontal : {}]}
                                      hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
                                      onPress={() => {this.props.pop ? this.props.navigation.pop() : this.props.navigation.goBack()}}>
                        <Icons8Generator name={!i18n.isRTL ? 'arrow-rtl' : 'arrow'} fill={this.props.headerColor ? this.props.headerColor : getThemeProperty(keys.brandedGreen)}/>
                    </TouchableOpacity>
                    <View style={styles.endItems}>
                        {this.props.children}
                    </View>
            </View>
        )
    }
}

export default withNavigation(AppHeader);

const styles = ScaledSheet.create({
    headerRow: {
        position: 'relative',
        backgroundColor: Colors.transparent,
        // top:verticalScale(8),
        minHeight: verticalScale(20),
        justifyContent:'center',
        width:'100%',
        zIndex:1000,
    },
    endItems:{
        position: 'absolute',
        end:0,
        width:'auto'
    },

});