import React, {Component} from 'react';
import LogBookItems from "../../screens/Logbook/LogBookItems";
import {View} from 'react-native';

class LogBookSearchResults extends React.PureComponent {


    skills() {
        const {results} = this.props;
        return (
            <LogBookItems
                data={results.skills}
                isLoading={false}
                showDate={true}
                type={'skills'}
                init={this.query}
                header={true}
            />
        )    }

    workouts() {
        const {results} = this.props;
        return (
            <LogBookItems
                data={results.workouts}
                isLoading={false}
                showDate={true}
                init={this.query}
                type={'workouts'}
                header={true}
            />
        )
    }

    render() {
        const {results} = this.props;
        return (
            <View>
                {results ? results.skills.length > 0 ? this.skills() : null : null}
                {results ? results.workouts.length > 0 ? this.workouts() : null : null}
            </View>
        );
    }
}


export default LogBookSearchResults;
