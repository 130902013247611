import {PICTURE} from '../constants';

const initialState = {
    isLoading: false,
    data: null,
    tempImage: null,
    error: {},
};

const pictureReducer = (state = initialState, action) => {
    const actionType = PICTURE.toUpperCase(); // just MAKE SURE IT IS UPPER
    switch (action.type) {
        case `${actionType}_PENDING`:
            return {
                ...state,
                isLoading: true,
                tempImage: action.payload,
            };
        case `${actionType}_SUCCESS`:{
            return {
                ...state,
                isLoading: false,
                tempImage: null,
                data: action.payload,
            };
        }
        case `${actionType}_FAILED`:
            return {
                ...state,
                isLoading: false,
                tempImage: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default pictureReducer;