import React from 'react';
import {View, ScrollView, ActivityIndicator,Dimensions} from "react-native";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import {LinearWrapper} from "../../../components/Stateless/LinerWrapper";
import AppHeader from "../../../components/Stateless/AppHeader";
import uuid from 'uuid';
import {withNavigation} from "react-navigation";
import {AppText} from "../../../components/Stateless/AppText";
import {RoundIcon} from "../../../components/Stateless/RoundIcon";
import {apiAction} from "../../../helpers/HTTP";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {HUGE_ICON_SIZE, LARGE_ICON_SIZE} from "../../../helpers/functions";
import {t} from '../../../services/i18n';
import {ClassActionButton} from "../../../components/Stateless/ClassActionButton";
import DrawerHandler from "../../../components/DrawerHandler";
import UserRmForm from "../../../components/UserRmForm";
import {scale, verticalScale} from "react-native-size-matters";

import {TabView, TabBar, SceneMap} from 'react-native-tab-view';
import LogbookRmTableData from "../../../components/LogbookRmTableData";
import {TabBarCustom} from "../../../components/Stateless/TabBarCustom";

const RMS = ['RM1', 'RM3', 'RM5'];


@withNavigation
class SkillScreen extends React.PureComponent {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            userRm: [],
            isLoading: true,
            index: 0,
            routes: [{key: '1', title: 'RM1'},
                {key: '2', title: 'RM3'},
                {key: '3', title: 'RM5'}
            ],
        };
        this.init = this.init.bind(this);

    }

    async componentDidMount() {
        this._isMounted = true;
        this.init();
    }

    init() {
        this.setState({isLoading: true}, async () => {
            if (this._isMounted) {
                try {
                    const id = this.props.navigation.getParam('id');
                    const response = await apiAction(`logbook/skill/${id}`, 'get', null);
                    this.setState({userRm: response.data, isLoading: false})
                } catch (e) {
                    this.setState({userRm: [], isLoading: false})
                }
            }
            else {
                this.setState({userRm: [], isLoading: false})
            }
        })
    }


    screen() {
        const data = this.props.navigation.state.params;
        return (
            <View key={uuid.v4()}>
                <View style={[globalStyles.flexDirectionColumn, globalStyles.centerItems]}>
                    <RoundIcon name={'line-chart'}/>
                    <AppText
                        style={[globalStyles.heeboMedium, globalStyles.title, globalStyles.marginTopBetweenTextElements, {color: Colors.white}]}>{data.name}</AppText>
                </View>
                {this.skill()}
            </View>
        )

    }

    skill() {
        return (
            <React.Fragment>
                <View
                    style={[globalStyles.cardContainer, globalStyles.appTopBorderRadius, globalStyles.marginTopBetweenElements]}>
                    {this.state.isLoading ?
                        <View style={[globalStyles.flex, globalStyles.centerItems]}><ActivityIndicator
                            size="large"/></View> : this.header()}
                </View>
                {this.state.isLoading === false && Object.keys(this.state.userRm).length > 0 ?
                    (this.rmTable()) : null
                }
            </React.Fragment>
        )
    }



    rmTable() {
        const {index, routes} = this.state;
        return (
            <TabView
                navigationState={{index, routes}}
                bounces={false}
                sceneAnimationEnabled={true}
                swipeEnabled={true}
                initialLayout={{ width: scale(330) }}
                renderScene={({route, focused}) => {
                    switch (route.key) {
                        default:
                            return <LogbookRmTableData key={route.key} title={route.title} userRm={this.state.userRm}/>;
                    }
                }}
                onIndexChange={i => this.setState({index: i})}
                renderTabBar={(props) => <TabBarCustom {...props}
                                                       topBorder={false}
                                                       numOfItems={3}
                                                       backgroundColor={this.props.whiteLabelProperties.logbookColor}
                                                       indicatorColor={Colors.white}
                                                       tabStyleProps={{marginBottom: 8}}
                                                       textProps={{fontSize:scale(15),color:Colors.white}}/>}
            />
        );


    }

    header() {
        if (Object.keys(this.state.userRm).length === 0) {
            return this.empty();
        }
        return this.lastResults()
    }

    lastResults() {
        //Not empty we must have last results
        return (
            <View>
                <View style={globalStyles.card}>
                    <AppText
                        style={[globalStyles.heeboMedium, globalStyles.title]}>{t('screens:Logbook:skillScreen:lastResults', {})}</AppText>
                    <View style={[globalStyles.flexDirectionRowSpaceBetween]}>
                        {
                            RMS.map(item => {
                                let value = 0;
                                if (item in this.state.userRm) {
                                    value = this.state.userRm[item][0].skill_weight
                                }
                                return (
                                    <View
                                        key={uuid.v4()}
                                        style={[globalStyles.marginTopBetweenTextElements, globalStyles.centerItems]}>
                                        <AppText
                                            style={[globalStyles.heeboMedium, globalStyles.title]}>{value}</AppText>
                                        <AppText style={[globalStyles.marginTopSm]}>{item}</AppText>
                                    </View>)
                            })
                        }
                    </View>
                </View>
                {this.action()}
            </View>
        )
    }

    empty() {
        return (
            <View>
                <View style={[globalStyles.card, globalStyles.centerItems]}>
                    <AppText style={globalStyles.heeboRegular}>{t('screens:Logbook:skillScreen:noUserRm', {})}</AppText>
                    <Icons8Generator style={globalStyles.marginTopBetweenTextElements} name={'puzzled'}
                                     width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                </View>
                {this.action()}
            </View>

        )
    }

    action() {
        return (
            <ClassActionButton
                onPress={() => this.RBSheet.open()}
                iconName={'edit-property'}
                textColor={Colors.black}
                textOverride={{...globalStyles.heeboRegular}}
                text={t('screens:Logbook:skillScreen:addResults', {})}
            />
        )
    }

    render() {
        return (
            <LinearWrapper
                backgroundStart={this.props.whiteLabelProperties.logbookColor}
                backgroundEnd={this.props.whiteLabelProperties.logbookColor}
                barStyle="light-content"
                spinner={false}>
                <View style={[globalStyles.flex, globalStyles.appMinimalPaddingHorizontal]}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[globalStyles.statusBarGap, globalStyles.screenBottomPadding, {flexGrow: 1}]}>
                        {<AppHeader pop={true} headerColor={Colors.white}/>}
                        {this.screen()}
                    </ScrollView>
                    <DrawerHandler title={t('screens:Logbook:skillScreen:addResults', {})}
                                   ref={el => {
                                       this.RBSheet = el ? el.ref.current : null;
                                   }}>
                        <UserRmForm skillId={this.props.navigation.getParam('id')}
                                    closeDrawer={() => {
                                        this.RBSheet.close();
                                        this.init();
                                    }}/>
                    </DrawerHandler>
                </View>
            </LinearWrapper>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(SkillScreen);
