// @flow
import * as React from 'react';
import {ActivityIndicator, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import SingleShopItem from "./SingleShopItem";
import {cartHasWorkshop, isMobile} from "../../../helpers/functions";
import CustomTabs, {Tab} from "../Common/CustomTabs";
import {t} from "../../../services/i18n";
import {AppText} from "../../Stateless/AppText";
import SearchInput from "../Common/SearchInput";
import {useEffect, useState} from "react";
import Dropdown from "../Common/Dropdown";
import ModalButton from "../../ModalButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchCurrencySymbol, fetchShopAbleItems} from "../../../actions/shop";
import Bag from "../../../images/bagRepeatBg.png"
import Card from "../../../images/cardRepeatBg.png"
import Membership from "../../../images/membershipRepeatBg.png"
import ExtendedCardHandler from "./extendedCards/ExtendedCardHandler";
import {setActiveUsersBoxes} from "../../../actions/user";
import Spinner from "react-native-loading-spinner-overlay";
import {Link} from "react-router-dom";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import ShopEmptyState from "./ShopEmptyState";
import {updateSiteSelectedLocation} from "../../../actions/minisite";

const EMPTY_FILTERED = 'empty_filtered';

const ShopItemsContainer = (props) => {
    const [searchInput, setSearchInput] = useState('');
    const [itemsByCategory, setItemsByCategory] = useState(null);
    const [itemsByCategoryUnfiltered, setItemsByCategoryUnfiltered] = useState(null);
    const [departmentsDropdown, setDepartmentsDropdown] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [singleCategoryItems, setSingleCategoryItems] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [showExtendedItem, setShowExtendedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [emptyLocation, setEmptyLocation] = useState(false);
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const cart = useSelector(state => state.shop.cart)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const [selectedLocation, setSelectedLocation] = useState(location.id);
    const dispatch = useDispatch()
    const mobile = isMobile();

    useEffect(() => {
        setSearchInput('')
        dispatch(setActiveUsersBoxes(box));
        fetchShopItems()
    }, [selectedLocation]);

    useEffect(() => {
        filterByDepartmentAndSearch()
    }, [selectedDepartment, searchInput]);

    useEffect(() => {
        if(selectedLocation) {
            const newLocation = box.locations_box.find(item => item.id == selectedLocation)
            dispatch(updateSiteSelectedLocation(newLocation))
        }
    }, [selectedLocation]);


    const fetchShopItems = async () => {
        setIsLoading(true)
        let items = []
        let departments = []
        const membershipsData = await dispatch(fetchShopAbleItems(`shop/${box.id}/${selectedLocation}`));
        const memberships = membershipsData.data
        await dispatch(fetchCurrencySymbol(`shop/currencySymbol/${selectedLocation}`));
        const hasWorkshop = cartHasWorkshop();
        Object.keys(memberships).map(type => {
            if(hasWorkshop) { //if cart has a workshop in it, display only items
                if(type === 'item') {
                    pushObjToItemsDictionary(items, memberships[type], type)
                }
            }
            else {
                if (memberships[type].length) {
                    //divide shop items by category (for tabs)
                    const itemsData = memberships[type];
                    pushObjToItemsDictionary(items, itemsData, type)
                    //create department dropdown list
                    itemsData.map(item => item.departments ? departments.push({
                        name: item.departments.name,
                        id: item.department_id
                    }) : null)
                }
            }
        })
        setSingleCategoryItems(items.length === 1 ? items[0].items : null)
        setEmptyLocation(items.length === 0)
        setItemsByCategoryUnfiltered(items.length ? items : null)
        setItemsByCategory(items.length ? items : null);
        setDepartmentsDropdown(departments.length ? departments : null)
        setIsLoading(false)
    }

    const pushObjToItemsDictionary = (dictionary, itemsData, type) => {
        dictionary.push({
            items: itemsData,
            label: t(`screens:Shop:tabs:${type}`, {}),
            iconName: `${type}-shop`,
            type: type
        })
        return dictionary;
    }

    const filterByDepartmentAndSearch = () => {
        let tempItems = [];
        if(selectedDepartment || searchInput) {
            itemsByCategoryUnfiltered.map(category => {
                const filteredItems = category.items.filter(item => {
                    if(selectedDepartment && searchInput) {
                        return item.name.toLowerCase().includes(searchInput) && (item.department_id === selectedDepartment)
                    } else if(!selectedDepartment && searchInput) {
                        return item.name.toLowerCase().includes(searchInput)
                    } else {
                        return item.selectedDepartment === selectedDepartment
                    }
                })
                tempItems.push({
                    items: filteredItems.length ? filteredItems : [EMPTY_FILTERED],
                    label: category.label,
                    iconName: category.iconName,
                    type: category.type
                })
            })
            setItemsByCategory(tempItems);
        } else {
            setItemsByCategory(itemsByCategoryUnfiltered);
        }
    }

    return (
        <>
            <View style={[{columnGap: mobile ? 0 : '1em', width: '100%', height: '100%'}]}>
                {!mobile &&
                    <>
                        <AppText style={[styles.ShopHeader]} boldFont>{t('headings:online-shop', {})}</AppText>
                        <View style={[styles.shopActions]}>
                            <SearchInput placeHolder={t('headings:search', {})} value={searchInput} onChange={setSearchInput} overrideStyles={{height: '100%', width: '10em'}}/>
                            <View style={[styles.dropdownAndCart]}>
                                {box?.locations_box.length > 1 &&
                                <Dropdown items={box?.locations_box}
                                          valuePropName='id'
                                          labelPropName='location'
                                          iconName={'location'}
                                          initValue={selectedLocation}
                                          onSelection={(value) => setSelectedLocation(value)}
                                />}
                                <Link to={{pathname: '/cart', search:boxQueryString}} style={{textDecoration: 'none' }}>
                                    <View style={[styles.primaryBtn, {backgroundColor: Colors.lightBlack}]}>
                                        <Icons8Generator name={'cart'} fill={Colors.white} size={'1.2em'}/>
                                        <AppText style={[styles.cartBtnText]}>{`${t('screens:Cart:title', {})} (${cart.length})`}</AppText>
                                    </View>
                                </Link>
                            </View>
                        </View>
                    </>
                }
                {isLoading ? <ActivityIndicator color={Colors.brandedBlue} style={{marginVertical: '2em'}}/>
                    :
                    <>
                        {emptyLocation && <ShopEmptyState/>}
                        {singleCategoryItems ?
                            <View
                                style={[styles.itemsContainer, {columnGap: mobile ? '1em' : '2em'}, !mobile && {maxHeight: '25em'}]}>
                                {singleCategoryItems.map(item => <Link to={{pathname: `/shop/item/${item.id}`, search:boxQueryString || location.search}} style={{textDecoration: 'none'}} key={`shop-web-tab-item-${item.name}`}>
                                                                        <SingleShopItem item={item} onItemClicked={null} isPopup={false}/>
                                                                </Link>)}
                            </View>
                            :
                            <>
                                {itemsByCategory &&
                                <CustomTabs wrapperStyle={{marginBottom: mobile ? 10 : '3em'}}
                                            selectedTabName={selectedTab}
                                            onTabChanged={(name) => setSelectedTab(name)}>
                                    {itemsByCategory.map(category => {
                                        let BgIcon = category.type === 'session' ? Card : category.type === 'plan' ? Membership : Bag;
                                        return category.items.length ?
                                            <Tab name={category.label} iconName={mobile ? null : category.iconName}
                                                 key={`shop-web-tabs-${category.label}`}>
                                                <View
                                                    style={[styles.itemsContainer, mobile && styles.itemsContainerMobile, {columnGap: mobile ? '1em' : '2em'}]}>
                                                    {category.items[0] !== EMPTY_FILTERED ? category.items.map(item =>
                                                        <Link to={{pathname: `/shop/item/${item.id}`, search:boxQueryString || location.search}} key={`shop-web-tab-item-${item.name}`} style={{textDecoration: 'none'}}>
                                                            <SingleShopItem item={item} bgIcon={BgIcon} onItemClicked={null} isPopup={false}/>
                                                        </Link>)
                                                        :
                                                        <ShopEmptyState/>
                                                    }
                                                </View>
                                            </Tab>
                                            :
                                            <ShopEmptyState/>
                                    })
                                    }
                                </CustomTabs>
                                }
                            </>
                        }
                    </>
                }
            </View>
            {showExtendedItem && <ExtendedCardHandler item={showExtendedItem.item} dollars={showExtendedItem.dollars} cents={showExtendedItem.cents} show={true} setClose={() => setShowExtendedItem(null)}/>}
        </>
    );
};

export default ShopItemsContainer;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            itemsContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                rowGap: '2em',
                maxHeight: () => `100% - ${200 + parseInt(EStyleSheet.value('$tabsMaxHeight')) + parseInt(EStyleSheet.value('$mobileHeader'))}`,
                height: 'inherit',
                overflow: 'auto'
            },
            itemsContainerMobile: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                alignItems: 'start',
                paddingHorizontal: 10,
                maxHeight: () => `100% - ${10 + parseInt(EStyleSheet.value('$tabsMaxHeight')) + parseInt(EStyleSheet.value('$mobileHeader'))}`,
            },
            ShopHeader: {
                marginHorizontal: 'auto',
                fontSize: '1.4rem',
                // marginBottom: '1.5em',
                maxHeight:50,
                height: 50
            },
            shopActions: {
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginBottom: 20,
                maxHeight:40,
            },
            dropdownAndCart: {
                flexDirection: 'row',
                gap: '1em'
            },
            primaryBtn: {
                width: '8em',
                borderRadius: 3,
                minHeight: 'auto',
                marginTop: 0,
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '0.5em'
            },
            cartBtnText: {
                color: Colors.white,
                fontSize: '0.9rem',
                fontFamily: 'heebo'
            },
        }
    })
})
