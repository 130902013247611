import React from 'react';
import {connect} from "react-redux";
import {ActivityIndicator, TextInput, TouchableOpacity} from 'react-native';
import {Colors} from '../../styles/Colors';
import {ScaledSheet, scale, verticalScale} from "react-native-size-matters";
import {bindActionCreators} from "redux";
import {ScrollView, View} from 'react-native'
import SignaturePad from 'react-native-signature-pad-v2';
import FitImage from 'react-native-fit-image';
import uuid from 'uuid';
import {globalStyles} from "../../styles/Global";
import {apiAction} from "../../helpers/HTTP";
import {AppText} from "../../components/Stateless/AppText";
import {t} from "../../services/i18n";
import ModalButton from "../../components/ModalButton";
import {LinearWrapper} from "../../components/Stateless/LinerWrapper";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import SvgFillForm from "../../svg/custom/FillForm";
import SvgFingerDown from "../../svg/custom/FingerDown";
import {getThemeProperty, keys} from "../../styles/Theme";
import Config from "../../config/Config";
import SvgFillFormArbox from "../../svg/custom/FillFormArbox";
import SvgFingerDownArbox from "../../svg/custom/FinderDownArbox";
import {WebView} from 'react-native-webview';


const CONST = {
    QUESTION_TYPES: ["questionYesNo", "questionYesNoAdv", "questionMultipleChoice", "questionOpen"],
    SIGNATURE_OPTIONS_ARRAY: [{id: 1, name: 'digitalSignature'}, {id: 2, name: 'digitalSignatureMarkV'}],
    TYPE_PERSONAL_DETAILS: 'personalDetails',
    TYPE_CHILD_DETAILS: 'childDetails',
    TYPE_FORM_TYPES: 'formTypes',
    TYPE_SIGNED_PROPERTIES: 'signedProperties',
    TYPE_SIGN_OPTIONS: 'signOptions',
    TYPE_QUESTIONS: 'questions',
    TYPE_PICKERS: ['birthday', 'signatureDate']

};


const DISPLAYABLE_PROPERTIES_TYPE = [
    CONST.TYPE_QUESTIONS, CONST.TYPE_PERSONAL_DETAILS, CONST.TYPE_SIGNED_PROPERTIES
];

class DigitalFormScreen extends React.Component {
    static navigationOptions = {
        header: null,
        headerTransparent: true
    };

    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.updateQuestionState = this.updateQuestionState.bind(this);
        this.state = {
            isLoading: true,
            index: 0,
            questionIndex: 0,
            signaturePadKey: uuid.v4()
        }
    }

    async componentDidMount() {
        this.themeColor = getThemeProperty(keys.brandedGreen);
        this.signature_data = null;
        try {
            const id = this.props.navigation.getParam('id');
            const box = this.props.navigation.getParam('box');
            const image = this.props.navigation.getParam('image');
            const response = await apiAction(`digitalForm/${id}`, 'get', null);
            this.active_property_arr = this.buildActiveProperties(response);
            const questions = response.digital_form_builder.find((item => item.property_type === CONST.TYPE_QUESTIONS));
            this.setState({
                isLoading: false,
                digital_form_builder: response.digital_form_builder,
                digital_form: response.digital_form,
                form: {
                    questions: questions ? [...questions.digital_form_question] : null
                },
                active_property: this.active_property_arr[0],
                questions_length: questions ? questions.digital_form_question.length : null,
                box,
                image
            }, () => this.backgroundPropertiesHandler());

        } catch (e) {
            this.setState({isLoading: false});
        }
    }

    backgroundPropertiesHandler() {
        const users_boxes = this.props.user.users_boxes.find((item => item.box_fk = this.state.digital_form.box_fk));
        if (users_boxes) {
            let personalDetails = {};
            let signedProperties = {};
            const personal = this.state.digital_form_builder.find((item => item.property_type === CONST.TYPE_PERSONAL_DETAILS));
            if (personal) {
                personalDetails = this.handleArrayMaps(personal.digital_form_property, personalDetails, users_boxes)
            }
            const signed_props = this.state.digital_form_builder.find((item => item.property_type === CONST.TYPE_SIGNED_PROPERTIES));
            if (signed_props) {
                signedProperties = this.handleArrayMaps(signed_props.digital_form_property, signedProperties, users_boxes)
            }
            this.setState(prevState => ({
                form: {
                    ...prevState.form,
                    personalDetails,
                    signedProperties,
                }
            }));
        }
    }

    handleArrayMaps(arr, filledData, users_boxes) {
        arr.map(function (item) {
            item.name_ref = item.name.replace(/([A-Z])/g, '_$1').trim().toLowerCase();
            filledData[item.name] = users_boxes.hasOwnProperty(item.name_ref) && users_boxes[item.name_ref] !== null ? users_boxes[item.name_ref] : null;
            if (CONST.TYPE_PICKERS.includes(item.name)) {
                if (item.name === 'birthday') {
                    filledData.birthday = users_boxes.birthday
                } else {
                    filledData.signatureDate = new Date();
                }
            }

        });
        return filledData;
    }

    buildActiveProperties(response) {
        let arr = ['landing'];
        //TODO Can be changed foreach
        const builder = response.digital_form_builder;
        const questions = builder.find((item => item.property_type === CONST.TYPE_QUESTIONS));
        const signature_options = response.digital_form.signature_options !== 0;

        if (questions)
            arr.push(CONST.TYPE_QUESTIONS);
        if (signature_options)
            arr.push(CONST.TYPE_SIGN_OPTIONS);
        arr.push('summary');
        return arr;

        //TODO Keep, might come back
        // const personal_details = builder.find((item => item.property_type === CONST.TYPE_PERSONAL_DETAILS));
        // if (personal_details)
        //     arr.push(CONST.TYPE_PERSONAL_DETAILS);


    }

    handleNextSection() {
        //Fetch next active property;
        const index = this.active_property_arr.indexOf(this.state.active_property);
        if (this.active_property_arr[index] === "summary") {
            if (this.props.navigation.state.params.onGoBack) {
                this.props.navigation.state.params.onGoBack();
            }
            this.props.navigation.goBack();
        } else {
            if (this.active_property_arr[index + 1] === "summary") {
                if ((this.state.digital_form.signature_options === 3 || this.state.digital_form.signature_options === 1) && this.signature_data === null)
                    return;
                if (this.signature_data) {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            signature_data: this.signature_data
                        }
                    }), () => this.submit())
                } else {
                    this.submit();
                }
            } else {
                this.setState({active_property: this.active_property_arr[index + 1]})
            }
        }
    }

    handleNextButtonText() {
        const index = this.active_property_arr.indexOf(this.state.active_property);
        if (this.active_property_arr[index] === "summary") {
            return t('screens:DigitalForms:summary:button', {})
        }
        if (this.active_property_arr[index + 1] === "summary" && this.state.active_property !== CONST.TYPE_QUESTIONS) {
            return t('common:submit', {})
        } else if (this.active_property_arr[index + 1] === "summary" && this.state.active_property === CONST.TYPE_QUESTIONS && this.state.questionIndex + 1 === this.state.questions_length) {
            return t('common:submit', {})
        }
        return t('common:next', {});
    }

    async submit() {
        this.setState({isLoading: true});
        try {
            const response = await apiAction('digitalForm', 'post', {
                form: this.state.form,
                digital_form: this.state.digital_form
            });
            this.setState({active_property: 'summary', isLoading: false});
        } catch (e) {
            this.setState({isLoading: false});
        }

    }

    handlePrevSection() {
        const index = this.active_property_arr.indexOf(this.state.active_property);
        if (this.active_property_arr[index] === "landing") {
            this.props.navigation.goBack();
        } else {
            this.setState({active_property: this.active_property_arr[index - 1]})
        }
    }

    handleNext() {
        if (this.state.active_property === CONST.TYPE_QUESTIONS) {
            //Check for question required
            const question = this.state.form.questions[this.state.questionIndex];
            if (question.required === 1) {
                //if multi_choice - check another array
                if (question.multi_choice === 1) {
                    if (question.checked_array.length === 0)
                        return false;
                } else if (question.user_answer === undefined) {
                    return false;
                }
            }
            if (this.state.questionIndex + 1 === this.state.questions_length) {
                this.handleNextSection();
                return true;
            } else {
                this.setState({questionIndex: this.state.questionIndex + 1});
                return true;
            }
        } else {
            this.handleNextSection();
            return true;
        }
    }

    handlePrev() {
        if (this.state.active_property === CONST.TYPE_QUESTIONS) {
            if (this.state.questionIndex === 0) {
                this.handlePrevSection();
                return true;
            } else {
                this.setState({questionIndex: this.state.questionIndex - 1});
                return true;
            }
        } else {
            this.handlePrevSection();
            return true;
        }
    }

    landingButtons() {
        return (
            <View style={[styles.buttonContainer]}>
                <ModalButton
                    onPress={() => this.handleNext()}
                    type='primary'
                    colors={this.themeColor}
                    text={t("screens:Home:cards:medical-card:action-button", {})}
                    textColor={Colors.white}
                />
                <ModalButton
                    onPress={() => this.handlePrev()}
                    type='secondary'
                    overrideStyle={{marginTop: verticalScale(10)}}
                    colors={Colors.transparent}
                    text={t('common:later', {})}
                    textColor={this.themeColor}
                />
            </View>
        )
    }

    landing() {
        const {digital_form} = this.state;

        return (
            <View style={[styles.digitalFormContainer]}>
                <AppText
                    style={[globalStyles.marginTopBetweenElements, globalStyles.heeboMedium, styles.webViewHeader, {
                        alignSelf: 'center',
                        textAlign: 'center'
                    }]}>
                    {digital_form.name}
                </AppText>
                {this.containsHTML(digital_form.content) ? (
                    <ScrollView contentContainerStyle={[styles.digitalFormContainer]}>
                        <WebView
                            source={{
                                html: `
                                <html>
                                <link href='https://fonts.googleapis.com/css?family=Heebo' rel='stylesheet'>
                                    <style type="text/css">${this.iFrameHtmlStyles(digital_form.display_direction)}</style>
                                    <body>
                                        ${digital_form.content}
                                    </body>
                                </html>`
                            }}
                            style={styles.webView}
                            showsHorizontalScrollIndicator={false}
                        />
                    </ScrollView>
                ) : (
                    <ScrollView style={{height: scale(300)}}>
                        <AppText
                            style={[globalStyles.marginTopBetweenElements, globalStyles.heeboRegular, styles.text, {
                                alignSelf: 'center',
                                textAlign: this.directionToTextAlign(digital_form.display_direction),
                            }]}>{digital_form.content}</AppText>
                    </ScrollView>
                )
                }
            </View>
        )

    }

    containsHTML(text) {
        if (text) {
            return text.match(/<.+?>/);
        }
        return false;
    }

    directionToTextAlign(direction) {
        return (direction === "rtl" ? "right" : direction === "ltr" && "left");
    }

    signatureProps() {
        const {digital_form} = this.state;
        if (digital_form.signature_declaration) {
            return (
                this.containsHTML(digital_form.signature_declaration) ? (
                    <WebView
                        source={{
                            html: `
                                <html>
                                <link href='https://fonts.googleapis.com/css?family=Heebo' rel='stylesheet'>
                                    <style type="text/css">${this.iFrameHtmlStyles(digital_form.display_direction)}</style>
                                    <body>
                                        ${digital_form.signature_declaration}
                                    </body>
                                </html>`
                        }}
                        style={styles.signatureWebView}
                        showsHorizontalScrollIndicator={false}
                    />
                ) : (<ScrollView style={{height: scale(150)}}>
                    <AppText style={[globalStyles.heeboRegular, styles.text, {
                        color: Colors.black,
                        alignSelf: 'center',
                        textAlign: this.directionToTextAlign(digital_form.display_direction)
                    }]}>{digital_form.signature_declaration}</AppText>
                </ScrollView>)
            )
        }
        return null;
    }

    question() {
        if (this.state.form.questions) {
            let question = this.state.form.questions[this.state.questionIndex];
            return (
                <View>
                    <View style={globalStyles.flexDirectionRow}>
                        <AppText
                            style={[globalStyles.heeboRegular]}>{t('screens:DigitalForms:questionHeader', {
                            num: this.state.questionIndex + 1,
                            size: this.state.questions_length,
                        })}
                        </AppText>
                        <AppText
                            style={[globalStyles.heeboRegular, globalStyles.paddingStart]}>{t(question.required === 1 ? 'screens:DigitalForms:required' : 'screens:DigitalForms:optional', {})}
                        </AppText>
                    </View>
                    <AppText
                        style={[globalStyles.heeboMedium, {fontSize: scale(22)}]}>{question.question}</AppText>
                    {this.questionTypeHandler(question)}
                </View>
            )
        }
        return null;
    }

    questionOpen(question) {
        return (
            <View
                style={[globalStyles.flexDirectionRowSpaceAround, globalStyles.marginTopBetweenElementsBig, {}]}>
                <TextInput
                    multiline={true}
                    onChangeText={(text) => {
                        question.user_answer = text;
                        this.updateQuestionState(question);
                    }
                    }
                    onFocus={() => this.setState({focus: true})}
                    onBlur={() => this.setState({focus: false})}
                    style={[globalStyles.heeboRegular, globalStyles.directionText,
                        this.state.focus ? globalStyles.appFocusInputColor : globalStyles.appInputColor, {
                            borderBottomColor: this.themeColor,
                            borderBottomWidth: 1,
                            width: scale(280),
                            color: Colors.black
                        }
                    ]}
                    value={question.user_answer}
                />

            </View>
        )
    }


    questionYesNo(question) {
        const styles = {width: scale(100), height: scale(100), borderRadius: scale(3), borderWidth: 0.5};
        return (
            <View
                style={[globalStyles.flexDirectionRowSpaceAround, globalStyles.marginTopBetweenElementsBig, {
                    flex: 1,
                    alignItems: 'center'
                }]}>
                <ModalButton
                    style={globalStyles.marginTopBetweenTextElements}
                    onPress={() => {
                        question.user_answer = question.user_answer === true ? undefined : true;
                        this.updateQuestionState(question);
                    }
                    }
                    regular={true}
                    type={question.user_answer === true ? 'primary' : 'secondary'}
                    overrideStyle={[styles, question.user_answer === true ? {borderWidth: 0} : null]}
                    colors={question.user_answer === true ? this.themeColor : Colors.brandedGraySelected}
                    text={t('screens:DigitalForms:yes', {})}
                    textColor={question.user_answer === true ? Colors.white : Colors.black}
                    textStyles={{fontSize: scale(30)}}
                />
                <ModalButton
                    style={globalStyles.marginTopBetweenTextElements}
                    onPress={() => {
                        question.user_answer = question.user_answer === false ? undefined : false;
                        this.updateQuestionState(question);
                    }
                    }
                    regular={true}
                    type={question.user_answer === false ? 'primary' : 'secondary'}
                    overrideStyle={[styles, question.user_answer === false ? {borderWidth: 0} : null]}
                    colors={question.user_answer === false ? this.themeColor : Colors.brandedGraySelected}
                    text={t('screens:DigitalForms:no', {})}
                    textColor={question.user_answer === false ? Colors.white : Colors.black}
                    textStyles={{fontSize: scale(30)}}
                />
            </View>
        )
    }

    questionMultipleChoice(question) {
        //THIS TYPE HAS ANSWERS!
        let answers = question.answers;
        return (
            <View
                style={[globalStyles.flexDirectionColumn, globalStyles.marginTopBetweenElementsBig, {
                    flex: 1,
                    alignItems: 'center'
                }]}>
                {answers.map(answer => {
                    question.checked_array = question.hasOwnProperty('checked_array') ? question.checked_array : [];
                    const is_active = question.multi_choice === 0 ? answer.answer_text === question.user_answer : question.checked_array.indexOf(answer) !== -1;
                    const styles = {
                        width: scale(280),
                        minHeight: verticalScale(60),
                        borderRadius: scale(3),
                        borderWidth: is_active ? 0 : 0.5,
                        justifyContent: 'flex-start'
                    };
                    return (
                        <ModalButton
                            key={uuid.v4()}
                            onPress={() => {
                                this.handleMultiOptions(question, answer);
                            }}
                            regular={true}
                            overrideStyle={styles}
                            type={is_active === true ? 'primary' : 'secondary'}
                            colors={is_active === true ? this.themeColor : Colors.brandedGraySelected}
                            text={answer.answer_text}
                            textColor={is_active === true ? Colors.white : Colors.black}
                            textStyles={{fontSize: scale(20), paddingStart: scale(20)}}
                        />
                    )
                })}


            </View>
        )
    }

    handleMultiOptions(question, answer) {
        if (question.multi_choice === 0) {
            question.user_answer = question.user_answer === answer.answer_text ? undefined : answer.answer_text;
        } else {
            const index = question.checked_array.indexOf(answer);
            if (index === -1) {
                answer.checked = true;
                question.checked_array.push(answer);
            } else {
                answer.checked = false;
                question.checked_array.splice(index, 1);
            }
        }
        this.updateQuestionState(question);
    }

    updateQuestionState(question) {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                ...prevState.form.questions,
                [this.state.questionIndex]: question
            }
        }))
    }

    questionTypeHandler(question) {
        switch (question.type) {
            case 'questionYesNo':
            case 'questionYesNoAdv':
                return this.questionYesNo(question);
            case 'questionOpen':
                return this.questionOpen(question);
            case 'questionMultipleChoice':
                return this.questionMultipleChoice(question);
            default:
                break;
        }

    }

    personal() {
        const {digital_form_builder} = this.state;
        if (digital_form_builder) {
            const personal_details = digital_form_builder.find((item => item.property_type === CONST.TYPE_PERSONAL_DETAILS));
            if (personal_details) {
                return (
                    <View key={uuid.v4()}><AppText>personal</AppText></View>
                )
            } else {
                //Handle weird weird error
                this.handleNextSection();
            }
        }
        return null;
    }

    activeProperty() {
        switch (this.state.active_property) {
            case 'landing': {
                return this.landing();
            }
            case CONST.TYPE_PERSONAL_DETAILS: {
                return this.personal();
            }
            case CONST.TYPE_QUESTIONS: {
                return this.question();
            }
            case CONST.TYPE_SIGN_OPTIONS: {
                return this.signatureProps();
            }
            case 'summary': {
                return this.summary();
            }
            default:
                break;
        }
    }


    signature() {
        const {digital_form} = this.state;
        if (digital_form.signature_options === 3 || digital_form.signature_options === 1) {
            return (
                <View>
                    <View style={{
                        alignSelf: 'center',
                        marginTop: verticalScale(20)
                    }}>
                        {Config.whiteLabel.toLowerCase() === 'arbox' ?
                            <SvgFingerDownArbox width={scale(70)} height={scale(70)}/> :
                            <SvgFingerDown width={scale(70)} height={scale(70)}/>}
                    </View>
                    <AppText
                        style={[globalStyles.marginTopBetweenElements, globalStyles.heeboMedium, styles.signatureHeaderText, globalStyles.centerItems]}>{t('screens:DigitalForms:signature:info', {})}</AppText>
                    <View style={[globalStyles.marginTopBetweenElements, {
                        height: verticalScale(105),
                        borderWidth: 0.5,
                        zIndex: 10000,
                        borderColor: Colors.grayText
                    }]}>
                       <SignaturePad
                            key={this.state.signaturePadKey}
                            onError={this._signaturePadError}
                            onChange={this._signaturePadChange}
                            style={{backgroundColor: 'white'}}/>
                    </View>
                    <TouchableOpacity
                        style={[globalStyles.flexDirectionRow, globalStyles.marginTopBetweenTextElements, globalStyles.centerItems]}
                        onPress={() => {
                            this.signature_data = null;
                            this.setState({signaturePadKey: uuid.v4()})
                        }}>
                        <Icons8Generator name={'refresh'} fill={Colors.black}/>
                        <AppText
                            style={[globalStyles.heeboMedium, globalStyles.textBlack, globalStyles.paddingStartLarge]}>{t('screens:DigitalForms:signature:retry', {})}</AppText>
                    </TouchableOpacity>
                </View>
            )
        }
        return null;
    }

    _signaturePadError = (error) => {
        console.log(error);
    };

    _signaturePadChange = ({base64DataUrl}) => {
        this.signature_data = base64DataUrl;
    };

    summary() {
        return (
            <View>
                <AppText
                    style={[globalStyles.marginTopBetweenElements, globalStyles.heeboMedium, styles.headerText]}>{t('screens:DigitalForms:summary:title', {})}</AppText>
                <AppText
                    style={[globalStyles.marginTopBetweenElements, globalStyles.heeboRegular, styles.text]}>{t('screens:DigitalForms:summary:message', {})}</AppText>
                <AppText
                    style={[globalStyles.heeboRegular, globalStyles.textBlack, globalStyles.bold]}>{this.state.digital_form.name}</AppText>
                <View style={{alignItems: 'center', marginTop: verticalScale(50)}}>
                    {Config.whiteLabel.toLowerCase() === 'arbox' ?
                        <SvgFillFormArbox width={scale(100)} height={scale(100)}/> :
                        <SvgFillForm width={scale(90)} height={scale(90)}/>}
                </View>
            </View>
        )
    }

    form() {
        const {digital_form, box, image} = this.state;
        const buttonStyles = {width: scale(120), height: verticalScale(50)};
        return (
            <View style={[{flex: 1}, globalStyles.appPaddingHorizontal]}>
                <View style={{flex: (this.state.active_property === CONST.TYPE_SIGN_OPTIONS ? 0 : 1)}}>
                    <View style={globalStyles.flexDirectionRow}>
                        {this.state.active_property !== CONST.TYPE_SIGN_OPTIONS && image ? (
                            <FitImage
                                resizeMode="contain"
                                source={{uri: image}}
                                style={[globalStyles.cardRadius, {width: scale(40), height: scale(40)}]}
                            />
                        ) : null}
                        <AppText style={[globalStyles.heeboMedium, globalStyles.paddingStartLarge, {
                            color: Colors.black,
                            alignSelf: 'center'
                        }]}>{this.state.active_property !== CONST.TYPE_SIGN_OPTIONS && (
                            this.state.active_property === 'landing' || this.state.active_property === 'summary' ? box.name : digital_form.name)
                        }</AppText>
                    </View>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[styles.container, globalStyles.screenBottomPadding]}
                    >
                        {this.activeProperty()}
                    </ScrollView>
                </View>
                {this.state.active_property === 'landing' ? this.landingButtons() : (
                    <View>
                        {this.state.active_property === CONST.TYPE_SIGN_OPTIONS ? this.signature() : null}
                        <View style={[styles.buttonContainer, {flexDirection: 'row', justifyContent: 'space-around'}]}>
                            {this.state.active_property !== 'summary' ? (
                                <ModalButton
                                    onPress={() => this.handlePrev()}
                                    type='secondary'
                                    overrideStyle={buttonStyles}
                                    colors={Colors.transparent}
                                    text={t('common:prev', {})}
                                    textColor={this.themeColor}
                                />
                            ) : null}
                            <ModalButton
                                onPress={() => this.handleNext()}
                                type='primary'
                                colors={this.themeColor}
                                overrideStyle={[buttonStyles, this.state.active_property === 'summary' ? {width: scale(200)} : null]}
                                text={this.handleNextButtonText()}
                                textColor={Colors.white}
                            />
                        </View>
                    </View>
                )}
            </View>
        )

    }

    iFrameHtmlStyles(direction) {
        // b tag is given a font-size to because angular-tetx component puts the text in a b tag if you copy pase a text.
        return `
            body { 
                font-family: 'heebo';
                direction: ${direction};
                padding: ${scale(20)};
                margin-bottom: ${scale(20)};
            }
            b {
                font-size: ${scale(40)};
            }
            p {
                font-size: ${scale(40)};
            }
            span {
                font-size: ${scale(40)};
            }
            h1 {
                font-size: ${scale(50)};
            }
            h2 {
                font-size: ${scale(55)};
            }
            h3 {
                font-size: ${scale(60)};
            }
            br {
                content: "";
            }
        `;
    }

    render() {
        const {isLoading, digital_form} = this.state;
        return (
            <LinearWrapper
                backgroundStart={Colors.white}
                backgroundEnd={Colors.white}
                barStyle="light-content"
                spinner={isLoading}>
                {digital_form ?
                    this.form() : null
                }
            </LinearWrapper>
        );
    }


}

const
    mapStateToProps = state => ({
        user: state.user.data
    });

const
    mapActionsToProps = dispatch => {
        return bindActionCreators({}, dispatch);
    };

export default connect(
    mapStateToProps,
    mapActionsToProps
)(DigitalFormScreen);

const
    styles = ScaledSheet.create({
        container: {
            marginTop: verticalScale(10),
        },
        headerText: {
            fontSize: scale(18),
            color: Colors.black
        },
        signatureHeaderText: {
            fontSize: scale(14),
            color: Colors.black
        },
        text: {
            fontSize: scale(14),
            color: Colors.black

        },
        buttonContainer: {
            flex: 0,
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: verticalScale(30),
        },
        digitalFormContainer: {
            flexDirection: 'column',
            alignItems: 'stretch',
            flex: 1,
        },
        signatureWebView: {
            width: scale(300),
            height: verticalScale(150),
            flex: 1,
            backgroundColor: Colors.viewBackground
        },
        webView: {
            width: scale(300),
            height: verticalScale(300),
            marginTop: verticalScale(20),
            flex: 1,
            backgroundColor: Colors.viewBackground
        },
        webViewHeader: {
            fontSize: scale(20),
            paddingRight: scale(20),
            paddingLeft: scale(20),
        }

    });


