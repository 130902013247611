import {ScaledSheet, verticalScale, scale} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import i18n from '../../services/i18n';
import {isTextRTLStyles} from '../../styles/Layout';

export default ScaledSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: Colors.transparent,
        marginVertical:5,

    },
    icon: {
        color: Colors.black,
        fontSize: scale(17),
        alignItems: 'center'
    },
    iconBorder: {
        // borderWidth: 1,
        borderColor: '#F6F6F6',
        borderRadius: 100,
        justifyContent: 'center',
        alignSelf:'center',
        padding:13,
        // backgroundColor:Colors.redPinkColor
        // padding: 10,
        // height: verticalScale(38)
    },
    label: {
        fontSize: scale(10),
        // lineHeight: '14@s',
        color: '#a4a4a4',
        textTransform: 'uppercase',
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: '3@vs',
        fontFamily: 'heebo',
        ...isTextRTLStyles,
    },
    input: {
        fontSize: scale(14),
        ...isTextRTLStyles,
        width:scale(300),
        borderBottomColor:Colors.graySeparator,
        borderBottomWidth:1
    },
    inputWrapper: {
        flexDirection: 'row',
        // alignItems:'center',
        // width:scale(300),
        // borderBottomColor: Colors.iconGray,
        // borderBottomWidth:0.2
    },
    dateInput: {
        // padding: '3@s',
        // fontSize: '12@s',
        // fontFamily: 'heebo',
        // color: '#58616d',
        // width: 'auto',
    },
    editableInput:{
        minHeight: null,
        paddingVertical: 5,
        borderBottomWidth:0,
    },
    formItem: {
        borderBottomWidth: 0,
        minHeight: null,
        paddingHorizontal: scale(3),
        paddingVertical: 5
    },
    genderPicker: {
        width: scale(100),
        height: scale(32),
    }
});

export const genderSelectStyle = ScaledSheet.create({
    inputIOS: {
        padding: '3@s',
        fontSize: '12@s',
        fontFamily: 'heebo',
        color: '#58616d',
        width: '100@s',
        height: '32@s',
        ...isTextRTLStyles
    },
    inputAndroid: {
        padding: '3@s',
        color: '#58616d',
        width: '100@s',
        height: '32@s',
    },
    chevronUp: {
        transform: [{translateY: 4}, {rotate: i18n.isRTL ? '45deg' : '-45deg'}],
    },
    chevronDown: {
        transform: [{translateY: -5}, {rotate: i18n.isRTL ? '-135deg' : '135deg'}],
    },
});
