import React from "react";
import {Platform, View} from "react-native";
import {apiAction, apiActionManage} from "../../../helpers/HTTP";
import {isMobile, isWeb, objHasAllProperties} from "../../../helpers/functions";
import {t} from "../../../services/i18n";
import {ErrorHandler} from "../../ErrorHandler";
import Spinner from "react-native-loading-spinner-overlay";
import {AppText} from "../../Stateless/AppText";
import {globalStyles} from "../../../styles/Global";
import {SelectPaymentMethodCard} from "../../Stateless/PaymentMethodCard";
import CreateTokenForm from "../../Shopping/CreateTokenForm";
import ModalButton from "../../ModalButton";
import {Colors} from "../../../styles/Colors";
import {verticalScale} from "react-native-size-matters";
import RemoteSubmitButton from "../../ReduxFormHelpers/RemoteSubmitButton";
import {connect} from "react-redux";
import {WebView} from 'react-native-webview';
import {WEB_VIEW_LISTENER} from "../../../constants/paymentsExternalJS/webViewListener";
import getEnvVars from "../../../config/Env";
import uuid from 'uuid';
import EStyleSheet from "react-native-extended-stylesheet";
import {cleanupIsraelPaymentRes, updateIsraelPaymentRes} from "../../../actions/minisite";
import {bindActionCreators} from "redux";
import {authenticateUser} from "../../../actions/auth";


class PaymentProcessWrap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.israelServices = ['pelecard', 'icredit', 'meshulam'];
        this.createToken = this.createToken.bind(this);
        this.callback = this.callback.bind(this);
        this.messageEvent = this.messageEvent.bind(this);
        this.state = {
            externalComponent: false,
            addingToken: false,
            createTokenForm: false,
            enableCreateTokenForm: false,
            errors: false,
            token: null,
            selectedMethodIndex: 0,
            responseObject: null,
            boxPaymentServiceId: this.props.boxPaymentService[0].id
        }
        this.params = {};
        //First box payment service is selected
        this.envVars = getEnvVars();
        this.isWeb = isWeb
    }

    async componentDidMount() {
        if (this.israelServices.indexOf(this.props.paymentServiceName) > -1) {
            const response = await apiAction(`paymentsApi/getMethodDetailsToCreateToken/${this.state.boxPaymentServiceId}`, 'get', null, true);
            this.setState({externalComponent: true, iframe:response.iFrame});
            const reducedData = {
                payment_service: response.payment_service,
                payment_type: response.payment_type,
                CustomerTransactionId: response.CustomerTransactionId,
                processId: response?.processId,
                processToken: response?.processToken,
                uuid: response?.uuid,
            }
            this.reducedData = reducedData;
            this.props.updateIsraelPaymentRes(reducedData)
        }
    }


    render() {

        return (
            <View style={{flex: 1}}>
                <View style={{flex: 1, overflow: 'auto'}}>
                    {this.state.errors ? <ErrorHandler errors={this.state.errors}/> : null}
                    {this.state.addingToken ? (<Spinner visible={true}/>) : null}
                    {this.state.externalComponent !== true && this.state.createTokenForm !== true ? (
                        <React.Fragment>
                            <AppText
                                style={[this.isWeb ? {
                                    fontSize: '0.9rem',
                                    marginBottom: '1em'
                                } : globalStyles.marginBottomVS15]}>{t('screens:Purchase:selectPaymentMethod', {})}</AppText>
                            {this.props.boxPaymentService.map((item, key) => {
                                const temp = item.payment_type.toLowerCase();
                                const service = item.payment_service.toLowerCase();
                                const type = service.includes('firstpay') ? (service === 'firstpay_cc' ? 'card' : 'ach') : temp;
                                return (
                                    <SelectPaymentMethodCard
                                        onPress={() => this.setState({
                                            selectedMethodIndex: key,
                                            boxPaymentServiceId: this.props.boxPaymentService[key].id
                                        })}
                                        object={type}
                                        key={uuid.v4()}
                                        text={t(`screens:Purchase:${type}`, {})}
                                        selected={key === this.state.selectedMethodIndex}
                                        iconName={type}
                                    />
                                )
                            })}
                        </React.Fragment>
                    ) : null}
                    {this.state.externalComponent && !this.state.createTokenForm ?
                        this.isWeb && (this.israelServices.indexOf(this.props.paymentServiceName) > -1) ?
                            <WebView
                                onMessage={(event) => this.messageEventWeb(event)}
                                source={{uri: this.state.iframe}}
                            />
                            :
                            <WebView
                                scalesPageToFit={true}
                                ref={(ref) => {
                                    this.webview = ref;
                                }}
                                originWhitelist={['*']}
                                injectedJavaScriptBeforeContentLoaded={WEB_VIEW_LISTENER}
                                onMessage={(event) => this.messageEvent(event)}
                                source={{uri:
                                        `${this.envVars.reactAppUrl}/payments/createToken?payment_identifier=${this.state.boxPaymentServiceId}${isWeb() ? '&referrer=site' : ''}`
                                }}/>
                        : null}
                    {this.state.createTokenForm ?
                        <CreateTokenForm onSubmit={this.createToken} mobile={this.props.mobile}/> : null}
                </View>
                <View
                    style={[globalStyles.flexDirectionRow, globalStyles.centerItems, globalStyles.stickyButtonsMarginBottom, {justifyContent: 'flex-end'},
                        this.isWeb && styles(this.props.mobile).btnsWrapper,
                    ]}>
                    <React.Fragment>
                        <ModalButton
                            onPress={() => this.props.handlePrev()}
                            type={this.isWeb ? 'primary' : 'secondary'}
                            colors={this.isWeb ? Colors.veryLightGrey : Colors.transparent}
                            text={t('common:back', {})}
                            overrideStyle={this.isWeb ? styles(this.props.mobile, this.props.whiteLabelProperties.primaryColor).backBtn : {
                                width: '50%',
                                minHeight: verticalScale(30)
                            }}
                            textColor={this.isWeb ? Colors.grayHeader : this.props.whiteLabelProperties.primaryColor}
                            regular={this.isWeb}
                            textStyles={this.isWeb && {fontSize: '0.9rem'}}
                        />
                        {this.state.createTokenForm ?
                            <RemoteSubmitButton
                                type='primary'
                                colors={this.props.whiteLabelProperties.primaryColor}
                                text={t('common:submit', {})}
                                overrideStyle={[this.isWeb ? styles(this.props.mobile).submitBtn : {
                                    width: '50%',
                                    minHeight: verticalScale(30)
                                }]}
                                textColor={Colors.white}
                                formName={'CreateTokenForm'}
                                textStyles={this.isWeb && {fontSize: '0.9rem'}}
                            />
                            :
                            <ModalButton
                                onPress={() => !this.state.externalComponent ? this.openExternalProcess() : this.setState({
                                    externalComponent: false,
                                    createTokenForm: true
                                })}
                                type='primary'
                                disabled={this.state.externalComponent && !this.state.enableCreateTokenForm}
                                colors={this.state.externalComponent && !this.state.enableCreateTokenForm ? Colors.brandedGraySelected : this.props.whiteLabelProperties.primaryColor}
                                text={t(this.state.externalComponent ? "common:next" : "common:select", {})}
                                overrideStyle={this.isWeb ? styles(this.props.mobile).nextBtn : {
                                    width: '50%',
                                    minHeight: verticalScale(30)
                                }}
                                textColor={Colors.white}
                                regular={this.isWeb}
                                textStyles={this.isWeb && {fontSize: '0.9rem'}}
                            />}
                    </React.Fragment>
                </View>
            </View>

        )
    }


    messageEvent = (event) => {
        if (event.nativeEvent.data !== 'null') {
            const response = this.isWeb ? event.nativeEvent.data : JSON.parse(event.nativeEvent.data);
            if(response.hasOwnProperty('action') && response.action === 'payments_wrap'){
                this.callback(response.payload.data)//Should be token;
            }
            //TODO CHECK IF CAN REACT ON USER CHANGE NUMBER.
        } else {
            console.log(event.nativeEvent, 'data is properly null')
        }
    };

    messageEventWeb = (event) => {
        const res = event.nativeEvent.data;
        const origin = event.nativeEvent.origin;
        //this is all dagan from my computer but he would not remember this when there will be a bug
        if (origin === 'https://meshulam.co.il' || origin === 'https://secure.meshulam.co.il' || origin === 'https://sandbox.meshulam.co.il'){
            if(res.action === 'payment' && res.status === 1){
                if (this.reducedData) {
                    const params = {
                        id: this.reducedData.uuid,
                        processId: this.reducedData.processId,
                        processToken: this.reducedData.processToken
                    }
                    // success
                    this.callback(params);
                }
            }
        } else if (event.nativeEvent.data !== 'null') {
            const response = event.nativeEvent.data
            if(response.action === 'payments_wrap'){
                this.props.cleanupIsraelPaymentRes()
                this.callback(response.payload)//Should be token;
            }
        } else {
            console.log(event.nativeEvent, 'data is properly null')
        }
    }

    callback(token) {
        this.params = {
            box_payment_services_id: this.state.boxPaymentServiceId,
            payment_object: {
                payment_response: token,
                token: token.id,
            },
            users_boxes: null,
        }
        const skipUserDetails = this.doesPaymentNeedsUserDetails()
        if (skipUserDetails) {
            this.createToken(null);
        } else {
            this.setState({createTokenForm: true, responseObject: token})
        }
    }

    doesPaymentNeedsUserDetails() {
        if (this.israelServices.indexOf(this.props.paymentServiceName) > -1)
            return true;
        return this.props.paymentServiceName === 'stripe' && this.props.boxPaymentService[this.state.selectedMethodIndex].payment_type.toLowerCase() === 'ach';
    }

//TODO Try to remove first pay handler into here.
    async createToken(values) {
        this.setState({errors: false, addingToken: true});
        //Added full responseObject for possible future options;
        if (values !== null) {
            const {country, address, zip, state, city} = values;
            this.params.payment_object.billingAddress = {country, address, zip, state, city};
            this.params.users_boxes = {country, address, zip, state, city};
        }
        if(this.props.signUpFunc) {
            this.setState({addingToken: false});
            this.props.signUpFunc(this.params)
        } else {
            try {
                const response = await apiAction('payments/createToken', 'post', this.params);
                if (response.hasOwnProperty('data') && response.data) {
                    if (objHasAllProperties(response.data, ['errorMessages', 'isError']) && response.data.isError !== false && response.data.errorMessages.length > 0) {
                        this.setState({addingToken: false, errors: response.data.errorMessages})
                    } else {
                        this.setState({addingToken: false, errors: false}, () => this.props.createTokenSuccess());
                    }
                } else {
                    this.setState({addingToken: false, errors: [t('common:unknownFailure', {})]})
                }
            } catch (e) {
                const errorMessage = e.hasOwnProperty('error') ? e.error.messageToUser ? e.error.messageToUser : e.error.message : t('common:unknownFailure', {});
                this.setState({addingToken: false, errors: [errorMessage]})
            }
        }
    }


    openExternalProcess() {
        this.setState({
            externalComponent: true,
        });
    }


}

const mapStateToProps = state => (
    {
        whiteLabelProperties: state.whiteLabelProperties,
    }
);

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            updateIsraelPaymentRes,
            cleanupIsraelPaymentRes
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(PaymentProcessWrap);

const styles = (mobile, primaryColor) => EStyleSheet.create(
    {
        ...
            Platform.select({
                web: {
                    backBtn: {
                        width: '7em',
                        padding: '0.4em',
                        minHeight: '1em',
                        borderRadius: 2,
                        justifyContent: 'center',
                        flex: mobile ? 1 : 'none',
                    },
                    nextBtn: {
                        width: '7em',
                        padding: '0.4em',
                        minHeight: '1em',
                        borderRadius: 2,
                        flex: mobile ? 1 : 'none'
                    },
                    submitBtn: {
                        minHeight: '1em',
                        paddingVertical: '0.4em',
                        width: '7em',
                        borderRadius: 2,
                        alignSelf: 'end',
                        marginTop: '1em',
                        flex: mobile ? 1 : 'none'
                    },
                    btnsWrapper: {
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: 0,
                        gap: mobile ? '1em' : 0
                    }
                }
            })
    }
)