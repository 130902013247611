import React from 'react';
import {View,Platform} from 'react-native';
import {Colors} from '../../styles/Colors';
import styles from './styles';
import SvgIcons8Home from "../../svg/Icons8Home";
import {getSvg8IconsDefault, LARGE_ICON_SIZE, MEDIUM_ICON_SIZE} from "../../helpers/functions";
import SvgIcons8HomeFilled from "../../svg/Icons8HomeFilled";
import SvgIcons8ShoppingCart from "../../svg/Icons8ShoppingCart";
import SvgIcons8ShoppingCartFilled from "../../svg/Icons8ShoppingCartFilled";
import SvgIcons8User from "../../svg/Icons8User";
import SvgIcons8UserFilled from "../../svg/Icons8UserFilled";
import SvgIcons8SquaredMenuFilled from "../../svg/Icons8SquaredMenuFilled";
import SvgIcons8SquaredMenu from "../../svg/Icons8SquaredMenu";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import SvgIcons8BuildingWithRooftopTerrace from "../../svg/Icons8BuildingWithRooftopTerrace";
import SvgIcons8BuildingWithRooftopTerraceFilled from "../../svg/Icons8BuildingWithRooftopTerraceFilled";
import {connect} from "react-redux";
import {AppText} from "../Stateless/AppText";
import {globalStyles} from "../../styles/Global";
import {t} from '../../services/i18n';
import {verticalScale,scale} from 'react-native-size-matters';
import SvgIcons8Shopaholic from "../../svg/Icons8Shopaholic";
import SvgIcons8ShopaholicFilled from "../../svg/Icons8ShopaholicFilled";

class TabBarIcon extends React.PureComponent {
    render() {
        let props = getSvg8IconsDefault();
        const size = Platform.OS.toLowerCase() === 'ios' && Platform.isPad ? LARGE_ICON_SIZE : MEDIUM_ICON_SIZE;
        const extraProps = {width: size, height: size};
        const tabColor = this.props.whiteLabelProperties.primaryColor;
        const activeColor = this.props.focused ? this.props.whiteLabelProperties.primaryColor : Colors.black;
        let icon, text;
        switch (this.props.name) {
            case 'home': {
                icon = this.props.focused ? <SvgIcons8HomeFilled  {...props} {...extraProps} fill={tabColor}/> :
                    <SvgIcons8Home {...props} {...extraProps} fill={Colors.black}/>
                text = t('common:mainTabBar:home', {});
                break;
            }
            case 'cart': {
                icon = this.props.focused ? <SvgIcons8ShopaholicFilled  {...props} {...extraProps} fill={tabColor}/> :
                    <SvgIcons8Shopaholic {...props} {...extraProps} fill={Colors.black}/>
                text = t('common:mainTabBar:shop', {});
                break;
            }
            case 'club': {
                icon = this.props.focused ?
                    <SvgIcons8BuildingWithRooftopTerraceFilled  {...props} {...extraProps} fill={tabColor}/> :
                    <SvgIcons8BuildingWithRooftopTerrace {...props} {...extraProps} fill={Colors.black}/>
                text = t('common:mainTabBar:clubs', {});
                break;
            }
            case 'account': {
                icon = this.props.focused ? <SvgIcons8UserFilled {...props} {...extraProps} fill={tabColor}/> :
                    <SvgIcons8User {...props} {...extraProps} fill={Colors.black}/>
                text = t('common:mainTabBar:profile', {});
                break;
            }
            case 'apps': {
                icon = this.props.focused ? <SvgIcons8SquaredMenuFilled {...props} {...extraProps} fill={tabColor}/> :
                    <SvgIcons8SquaredMenu {...props} {...extraProps} fill={Colors.black}/>
                text = t('common:mainTabBar:more', {});
                break;
            }
            case 'calendar-range': {
                icon = <Icons8Generator name={this.props.focused ? 'calendar-filled' : 'calendar'} {...props} {...extraProps}
                                        fill={this.props.focused ? tabColor : Colors.black}/>
                text = t('common:mainTabBar:schedules', {});
                break;
            }

            default:
                break;

        }
        // if (this.props.background) {
        //   return (
        //     <View style={[styles.roundBackground,{backgroundColor: tabColor}]}>
        //         <Icons8Generator name={'calendar'} {...props} width={BIG_ICON_SIZE} height={BIG_ICON_SIZE} fill={Colors.white} />
        //     </View>
        //   );
        // }
        return (
            <React.Fragment>
                <View
                    style={[this.props.focused ? {borderColor: tabColor} : {borderColor: 'transparent'}, styles.topTint]}/>
                <View style={[globalStyles.flexDirectionColumn, {alignItems: 'center', marginTop: verticalScale(10)}]}>
                    {icon}
                    {Platform.isPad ? null : <AppText style={[globalStyles.marginTopSm, {color: activeColor,fontSize:scale(12)}]}>{text}</AppText>}
                </View>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties
});

export default connect(
    mapStateToProps,
    null,
)(TabBarIcon);


