import React from 'react';
import {View} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {scale} from "react-native-size-matters"
import FitImage from 'react-native-fit-image';
import {BIG_ICON_SIZE} from "../../../helpers/functions";
import {Colors} from "../../../styles/Colors";
import {Icons8Generator} from "../Icons8Generator";


export class BoxImageOrIcon extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                {!this.props.src ? (
                    <View style={[globalStyles.clubImagePlaceholder]}>
                        <Icons8Generator name={'clubs'} width={BIG_ICON_SIZE} height={BIG_ICON_SIZE}
                                         fill={Colors.black}/>
                    </View>
                ) : (<FitImage
                    resizeMode="contain"
                    source={{uri: this.props.src}}
                    style={[globalStyles.cardRadius, {width: scale(40), height: scale(40), overflow: 'hidden'}]}
                />)}
            </React.Fragment>
        )
    }
}
