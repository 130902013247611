import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Time(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#191919" {...props}>
          <Path d="M21 2v3h2v1.098C12.35 7.108 4 16.088 4 27c0 11.586 9.414 21 21 21s21-9.414 21-21c0-10.911-8.35-19.893-19-20.902V5h2V2h-8zm19.236 3.146L38.23 7.152l4.616 4.616 2.006-2.006-4.616-4.616zM25 8c10.505 0 19 8.495 19 19s-8.495 19-19 19S6 37.505 6 27 14.495 8 25 8zm-10.01 7.99a1 1 0 00-.697 1.717l8.777 8.777A2 2 0 0023 27a2 2 0 002 2 2 2 0 00.518-.068l1.775 1.775a1 1 0 101.414-1.414l-1.777-1.777A2 2 0 0027 27a2 2 0 00-2-2 2 2 0 00-.518.068l-8.775-8.775a1 1 0 00-.717-.303z" />
      </Svg>
  );
}

export default SvgIcons8Time;
