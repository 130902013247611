import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { CheckBox } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalSimple from '../../../components/ModalSimple';
import ModalButton from '../../../components/ModalButton';
import MembershipList from '../../../components/MembershipList';
import InviteFriendsList from '../../../components/InviteFriendsList';

import {
  fetchUserFriends,
  fetchUserMemberships,
} from '../../../containers/ProfileContainer/actions';
import { Colors } from '../../../styles/Colors';
import styles from './styles';
import ClassInfo from '../../../components/ClassInfo';
import SimpleCheckbox from '../../../components/SimpleCheckbox';
import { isTextRTLStyles } from '../../../styles/Layout';
import { t } from '../../../services/i18n';

class PurchaseModal extends React.Component {
  state = {
    modalStep: 0,
    checkedMembershipIndex: 0,
    addToCalendarFlag: false,
  };

  constructor(props) {
    super(props);
    this.moveToNextStep = this.moveToNextStep.bind(this);
  }

  componentDidMount() {
  }

  closeModal() {
    this.props.showModal();
    this.setState({ modalStep: 0 });
  }

  moveToNextStep() {
    this.setState({ modalStep: 1 });
  }

  render() {
    const { modalStep, checkedMembershipIndex, addToCalendarFlag, selectedFriends } = this.state;
    const {
      isModalVisible,
      showModal,
      hideModal,
      information,
      error,
      bookClassSchedule,
      addClassToCalendar,
      membershipList,
      friendsList,
    } = this.props;

    let modalContent;
    let modalIcon;
    let modalHeadingText;
    let modalSubHeadingText;
    let modalType;
    let topBarEnabled = false;
    let headerText = '';
    let absoluteButtonText;
    let absoluteButtonOnPress;
    let absoluteButtonBorderColor;
    let disablePadding;
    let iconType;

    switch (modalStep) {
      case 0:
        modalType = 'success';
        disablePadding = false;
        modalIcon = 'check-circle';
        modalHeadingText = t('modals:Buy:purchase-successful');
        modalSubHeadingText = `${t('modals:Buy:texts:your-purchase-of')} ${information.product} ${t('modals:Buy:texts:at')} ${information.shop} ${t('modals:Buy:texts:is-successful')}`;
        modalContent = (
          <View style={styles.modalContentSingle}>
            <ModalButton
              onPress={() => this.closeModal()}
              text={t('modals:Card:got-it')}
              textColor={Colors.brandPrimary}
            />
          </View>
        );
        break;
      default:
        return null;
    }

    return (
      <ModalSimple
        isModalVisible={isModalVisible}
        disablePadding={disablePadding}
        toggleModal={showModal}
        hideModal={hideModal}
        withTopBar={topBarEnabled}
        error={error}
        headerText={headerText}
        iconName={modalIcon}
        iconType={iconType}
        modalType={modalType}
        headingText={modalHeadingText}
        subHeadingText={modalSubHeadingText}
        absoluteButtonText={absoluteButtonText}
        absoluteButtonOnPress={absoluteButtonOnPress}
        absoluteButtonBorderColor={absoluteButtonBorderColor}
      >
        {modalContent}
      </ModalSimple>
    );
  }
}

PurchaseModal.propTypes = {
  isModalVisible: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  information: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
};

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {},
    dispatch,
  );
};

const mapStateToProps = state => ({
  scheduleAction: state.schedule.scheduleAction,
});

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PurchaseModal);
