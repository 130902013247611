import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8BankBuilding(props) {
  return (
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
          <Path fill="#4caf50" d="M13 17h22v18H13z" />
          <Path
              fill="#d7ccc8"
              d="M2 39h44v2H2zm32-20h5v16h-5zM9 19h5v16H9zm34-2H5v-3l19-9 19 9zM6 35h36v2H6z"
          />
          <Path
              fill="#bcaaa4"
              d="M32 17h9v2h-9zM7 17h9v2H7zM4 37h40v2H4zm20-27a2 2 0 100 4 2 2 0 100-4z"
          />
          <Path fill="#43a047" d="M16 17h16v2H16z" />
          <Path
              fill="#e8f5e9"
              d="M27.116 27.565a4.547 4.547 0 00-1.054-.778 14.348 14.348 0 00-1.593-.735c-.646-.26-1.087-.513-1.325-.762s-.357-.578-.357-.989c0-.443.122-.789.365-1.041.243-.252.586-.378 1.029-.378.448 0 .806.162 1.073.483.268.322.402.987.402 1.636H28c0-1.054-.27-2.091-.811-2.752-.529-.649-1.261-1.042-2.189-1.184V19h-2v2.138c-.671.162-1.232.463-1.679.91-.584.584-.875 1.331-.875 2.246 0 .53.085.986.255 1.369.17.384.408.717.713 1.001s.672.533 1.099.753c.427.219.935.44 1.524.661.589.222 1.006.463 1.252.726.246.263.369.614.369 1.057 0 .426-.138.766-.413 1.018-.276.252-.651.376-1.127.376-.573 0-1.012-.171-1.318-.513-.305-.344-.458-1.099-.458-1.742H20c0 1.108.304 2.257.911 2.924.514.565 1.218.921 2.089 1.093V35h2v-1.944c.894-.128 1.613-.447 2.141-.974.573-.573.859-1.318.859-2.237 0-.492-.076-.922-.227-1.293a2.994 2.994 0 00-.657-.987z"
          />
      </Svg>
  );
}

export default SvgIcons8BankBuilding;
