import Constants from 'expo-constants'
import {Platform} from "react-native";

const ENV = {
    dev: {
        // apiUrl: 'https://128e4b91d92.ngrok.io/arboxserverapp/public/index.php/api/v2/'
        // apiUrl: 'https://apiappv2pre.arboxapp.com/api/v2/',
        // reactAppUrl: 'https://devpanel.arboxapp.com'
        // apiUrl: 'https://apiappv2.arboxapp.com/api/v2/',
        // reactAppUrl: 'https://panel.arboxapp.com',
        // externalApiUrl: 'https://api.arboxapp.com/index.php/api/v1/external/',

        apiUrl: 'http://localhost/api/v2/',
        reactAppUrl: 'http://localhost:3002',
        externalApiUrl: 'http://localhost:8887/public/index.php/api/v1/external/',
        siteV2Url: 'localhost:9000',
        httpPrefix: 'http://'

    },
    staging: {
        // apiUrl: 'https://apiappv2pre.arboxapp.com/api/v2/',
        reactAppUrl: 'https://devpanel.arboxapp.com',
        externalApiUrl: 'https://apipreprod.arboxapp.com/api/v1/external/',
        apiUrl: 'https://devarboxserver.arboxapp.com/api/v2/',
        siteV2Url: 'devsitev5.arboxapp.com',
        httpPrefix: 'https://'
    },
    prod: {
        apiUrl: 'https://apiappv2.arboxapp.com/api/v2/',
        reactAppUrl: 'https://panel.arboxapp.com',
        externalApiUrl: 'https://api.arboxapp.com/index.php/api/v1/external/',
        siteV2Url: 'web.arboxapp.com',
        httpPrefix: 'https://'
    }

};

let getEnvVars;
export default getEnvVars = (env = Constants.manifest.releaseChannel) => {
    // What is __D_ ?
    // This variable is set to true when react-native is running in Dev mode.
    // __DEV__ is true when run locally, but false when published.
    if(Platform.OS.toLowerCase() === 'web'){
        const extra = Constants.manifest.extra;
        if(extra && extra.hasOwnProperty('build_env')){
            switch(extra.build_env){
                case "staging":
                    return ENV.staging;
                case "prod":
                    return ENV.prod;
                default:
                 return ENV.dev;
            }
        }
    }
    if (env === undefined) return ENV.dev; // since releaseChannels are undefined in dev, return your default.
    if (env.indexOf('prod') !== -1) return ENV.prod; // this would pick up prod-v1, prod-v2, prod-v3a
    if (env.indexOf('staging') !== -1) return ENV.staging
};