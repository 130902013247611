import React from 'react';
import {View, ActivityIndicator,Platform} from 'react-native';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {globalStyles} from "../../styles/Global";
import {verticalScale, scale, ScaledSheet} from "react-native-size-matters"
import Spinner from 'react-native-loading-spinner-overlay';
import {TabView} from 'react-native-tab-view';
import RNPickerSelect from 'react-native-picker-select';
import {Colors} from "../../styles/Colors";
import {RNPickerIcon} from "../Stateless/RNPickerIcon";
import {apiAction} from "../../helpers/HTTP";
import {AppText} from "../Stateless/AppText";
import {TabBarCustom} from "../Stateless/TabBarCustom";
import WorkoutLeaderBoardRow from './_WorkoutLeaderBoardRow';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {t} from "../../services/i18n";
import {TAB_VIEW_WIDTH} from "../../variableConstants";

const LeaderBoardTypes = ['FOR_TIME', 'AMRAP', 'RFT'];

//BAD CODE ON SERVER, FORCES BAD CODE HERE!
class WorkoutLeaderBoard extends React.PureComponent {


    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            selectIndex: 0,
            items: [],
            leaderBoard: {},
            isLoading: false,
            index: 0,
            routes: [
                {key: '1', title: 'RX'},
                {key: '0', title: 'SCALED'}
            ],
        };
        this.selectedIndex = 0;
        this.getLeaderBoard = this.getLeaderBoard.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        let temp = [];
        this.props.data.map((boxSections) => {
            return boxSections.map((boxSection, index) => {
                if (LeaderBoardTypes.includes(boxSection.workout_type.name)){
                    const find = temp.find(item => item.key === boxSection.box_sections.id);
                    if(!find) {
                        temp.push(
                            {
                                ...boxSection.box_sections,
                                label: boxSection.box_sections.name,
                                value: boxSection.box_sections.id,
                                key: boxSection.box_sections.id
                            })
                    }
                }
            })
        });
        this.setState({items: temp}, () => {
            this.getLeaderBoard();
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    getLeaderBoard() {
        const data = this.props.data[0][0];
        const boxesId = data.boxes.id;
        const workoutId = data.workout_id;
        this.setState({isLoading: true}, async () => {
            try {
                const boxSectionsId = this.state.items[this.state.selectIndex].id;
                const response = await apiAction(`logbook/leaderBoard/${boxSectionsId}/${workoutId}/${boxesId}`, 'get', null);
                this.setState({leaderBoard: response.data, isLoading: false})
            } catch (e) {
                this.setState({leaderBoard: {}, isLoading: false})
            }
        })
    }

    table() {
        const {index, routes} = this.state;
        return (
            <View style={[globalStyles.cardContainer]}>
                <TabView
                    navigationState={{index, routes}}
                    initialLayout={{width: scale(TAB_VIEW_WIDTH)}}
                    renderScene={({route, focused}) => {
                        if (this.state.isLoading) {
                            return (
                                <View style={[globalStyles.flex, globalStyles.centerItems]}><ActivityIndicator
                                    size="large"/></View>
                            )
                        }
                        switch (route.key) {
                            default:
                                return this.handle(route.key);
                        }
                    }}
                    onIndexChange={i => this.setState({index: i})}
                    renderTabBar={(props) => <TabBarCustom {...props}
                                                           numOfItems={2}
                                                           topBorder={true}
                                                           backgroundColor={Colors.sectionHeaderColor}
                                                           indicatorColor={Colors.black}
                                                           textProps={{fontSize: scale(13), color: Colors.black}}/>}
                />
            </View>
        )
    }

    handle(key) {
        const {leaderBoard} = this.state;
        if (Object.prototype.hasOwnProperty.call(leaderBoard, key)) {
            return <WorkoutLeaderBoardRow arr={leaderBoard[key]}/>
        }
        return (
            <View style={[globalStyles.flex, globalStyles.flexDirectionColumn, globalStyles.centerItems]}>
                <AppText
                    style={[globalStyles.marginVerticalVS10, globalStyles.textCenter]}>{t('screens:Logbook:LeaderBoardScreen:emptyText1', {})}</AppText>
                <Icons8Generator name={'leader-board'} size={scale(80)}/>
                <AppText
                    style={[globalStyles.marginVerticalVS10, globalStyles.textCenter]}>{t('screens:Logbook:LeaderBoardScreen:emptyText2', {})}</AppText>
            </View>
        )

    }

    render() {
        if (this.state.items.length === 0)
            return <Spinner/>;
        const active = this.state.items[this.state.selectIndex];
        return (
            <View style={globalStyles.flex}>
                <View style={[globalStyles.marginBottomVS10]}>
                    <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        key={active.id}
                        onValueChange={(value, index) => {
                            this.selectedIndex = index;
                            if (Platform.OS.toLowerCase() === 'android') {
                                this.setState({selectIndex: this.selectedIndex}, () => {
                                    this.getLeaderBoard();
                                });
                            }
                        }}
                        onDonePress={() => {
                            this.setState({selectIndex: this.selectedIndex}, () => {
                                this.getLeaderBoard();
                            });
                        }}
                        items={this.state.items}
                        itemKey={active.id}
                        placeholder={{}}
                        style={pickerStyle}
                        Icon={() => <RNPickerIcon style={{marginHorizontal: scale(15), fontSize: scale(13)}}/>}
                    />
                </View>
                {this.table()}
            </View>
        )

    }
}


const styles = ScaledSheet.create({});


const pickerStyle = ScaledSheet.create({
    inputIOS: {
        fontSize: scale(15),
        paddingVertical: verticalScale(10),
        paddingHorizontal: scale(20),
        ...globalStyles.appSmBorderRadius,
        backgroundColor: Colors.white,
        color: Colors.black,
        ...globalStyles.textAlignByRTL
    },
    inputAndroid: {
        fontSize: scale(15),
        paddingVertical: verticalScale(10),
        paddingHorizontal: scale(40),
        ...globalStyles.appSmBorderRadius,
        backgroundColor: Colors.white,
        color: Colors.black,
        ...globalStyles.textAlignByRTL
    },
    iconContainer: {
        ...Platform.select({
            android: {
                top: 7,
            },
            ios: {
                top: 2
            }
        })
    }
});

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(WorkoutLeaderBoard);
