import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        width={73}
        height={58}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 73 58"
        {...props}
    >
        <Rect x={0.5} y={0.5} width={72} height={10} rx={1.5} stroke="#21599E" />
        <Rect x={0.5} y={16.5} width={72} height={10} rx={1.5} stroke="#F1979D" />
        <Rect x={0.5} y={31.5} width={72} height={10} rx={1.5} stroke="#21599E" />
        <Rect x={0.5} y={47.5} width={72} height={10} rx={1.5} stroke="#21599E" />
        <Path d="M37 0v57.5" stroke="#54B57F" />
    </Svg>
)

export default SvgComponent
