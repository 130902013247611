import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgCorona(props) {
  return (
    <Svg width="1em" height="1em" viewBox="0 0 34 42" {...props}>
      <G fillRule="nonzero" fill="none">
        <Path
          d="M8 24c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4M34 24c0-2.2-1.8-4-4-4s-4 1.8-4 4 1.8 4 4 4 4-1.8 4-4"
          fill="#FFCC80"
        />
        <Path
          d="M3 16C3 3.3 31 5.7 31 14v13c0 8.3-8.2 15-14 15-5.8 0-14-6.7-14-15V16z"
          fill="#FFCC80"
        />
        <Path
          d="M1 16.3c.5-6 1.7-11.9 9-13.3l2-3h5c9.2 0 16 7.5 16 18v3.4L30 25v-6c0-10.5-13.5 4.4-20-9-.3 5.5-6 8-6 8v7l-3-3.6V18c0-.6-.1-1.1 0-1.7"
          fill="#424242"
        />
        <Path
          d="M26 25c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2M12 25c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2"
          fill="#784719"
        />
        <Path
          d="M27 28v8.75C24.12 39.97 20.2 42 17 42s-7.12-2.03-10-5.25V28s5 2 10 2 10-2 10-2zM27 32.063l6.885-9.012c-.256-1.122-.885-1.69-.885-1.69L27 28v4.063zM7 32.063L.115 23.05C.371 21.929 1 21.361 1 21.361L7 28v4.063z"
          fill="#FAECF0"
        />
      </G>
    </Svg>
  );
}

export default SvgCorona;
