import {scale, verticalScale, ScaledSheet} from 'react-native-size-matters';
import i18n from '../services/i18n';
import {Colors} from './Colors';
import {isTextRTLStyles} from './Layout';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";

export const globalStyles = ScaledSheet.create({
    flex: {
        flex: 1,
    },
    containerFlexCentered: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeLine: {
        fontSize: scale(10),
        color: Colors.labelColor,
        marginTop: scale(5),
    },
    warningText: {
        color: Colors.red
    },
    icon: {
        color: Colors.brandPrimary,
        fontSize: '12@s',
    },
    iconBorder: {
        borderWidth: 1,
        borderColor: '#F6F6F6',
        borderRadius: 100,
        padding: '5@s',
        marginRight: '8@s',
    },
    // TODO SOMETHING IS OFF!
    loadingCardContainer: {
        alignSelf: 'center',
        width: '90%',

    },
    loadingCard: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: '#FFF',
        borderRadius: 5,
        shadowOffset: {width: 0, height: 5},
        shadowColor: 'rgba(0, 0, 0, 0.08)',
        shadowOpacity: 1,
        shadowRadius: 10,
        padding: '20@s',
        marginTop: '20@s',
        marginBottom: '20@s',
    },
    schedulesWrapper: {
        flexDirection: 'column',
        backgroundColor: Colors.viewBackground,
        flexGrow: 1
    },
    schedulesContainer: {
        flex: 1,
        backgroundColor: Colors.viewBackground,
    },
    schedulesContainerInner: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 1,
    },
    overlayLoading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activityIndicatorStyle: {
        paddingHorizontal: 5,
    },
    activityIndicatorStyleAbsolute: {
        position: 'absolute',
        top: 0,
        zIndex: 100,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullScalingRNSizeMatters: {
      width: scale(350),
      height: verticalScale(680)
    },
    avatarsRow: {
        marginVertical: verticalScale(10),
        flexDirection: 'row',
    },
    moreAvatars: {
        borderRadius: '35@s',
        width: '35@s',
        height: '35@s',
        backgroundColor: 'rgba(0, 153, 220, 0.2)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexCenterElements: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    appPaddingHorizontal: {
        paddingHorizontal: scale(20),
    },
    appMinimalPaddingHorizontal: {
        paddingHorizontal: scale(10),
    },
    appTextPaddingHorizontal: {
        paddingHorizontal: scale(50),
    },
    appListsPaddingHorizontal: {
        paddingHorizontal: scale(16),
    },
    directionWrap: {
        alignItems: i18n.isRTL ? 'flex-start' : 'flex-end',
    },
    directionWrapSelf: {
        alignSelf: i18n.isRTL ? 'flex-start' : 'flex-end',
    },
    flexDirectionRow: {
        flexDirection: 'row',
    },
    flexDirectionColumn: {
        flexDirection: 'column',
    },
    flexWrap:{
        flexWrap: "wrap"
    },
    flexDirectionRowSpaceBetweenByRtl: {
        flexDirection: i18n.isRTL ? 'row' : 'row-reverse',
        justifyContent: 'space-between',
        // alignItems: 'center',
    },
    flexDirectionRowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        // alignItems: 'center',
    },
    flexDirectionRowSpaceAround: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        // alignItems: 'center',
    },
    textAlignByRTL: {
        textAlign: i18n.isRTL ? 'right' : 'left',
    },
    //JUST SO I wont have to change many files
    directionText: {
        ...isTextRTLStyles
    },
    grayIcons: {
        color: Colors.modalFontColor,
        fontSize: scale(20),
    },
    marginByRTL: {
        marginLeft: i18n.isRTL ? 0 : 8,
        marginRight: i18n.isRTL ? 8 : 0,
    },
    flexDirectionRowMarginBottom: {
        marginBottom: 4
    },
    centerItems: {
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    centerStartItems: {
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    textCenter: {
        textAlign: 'center',
        width: scale(260),
        alignSelf: 'center'
    },
    boldTextModal: {
        fontFamily: 'heebo-bold',
        fontWeight: '600',
        ...isTextRTLStyles,
    },
    statusBarGap: {
        paddingTop: verticalScale(15)
    },
    marginTopBetweenTextElements: {
        marginTop: verticalScale(16)
    },
    marginTopBetweenElements: {
        marginTop: verticalScale(20)
    },
    marginTopBetweenElementsMedium: {
        marginTop: verticalScale(28),
    },
    marginTopBetweenElementsBig: {
        marginTop: verticalScale(35)
    },
    marginFromStatusBar: {
        marginTop: verticalScale(35)
    },
    marginTopSm: {
        marginTop: verticalScale(3)
    },
    marginTopRegular: {
        marginTop: verticalScale(5)
    },
    marginTopMedium: {
        marginTop: verticalScale(7)
    },
    marginTopLarge: {
        marginTop: verticalScale(10)
    },
    marginBottomVS10: {
        marginBottom: verticalScale(10)

    },
    marginBottomVS15: {
        marginBottom: verticalScale(15)

    },
    stickyButtonsMarginBottom:{
        marginBottom: verticalScale(30)
    },
    stickToBottom:{
        flex: 1,
        justifyContent: 'flex-end',
    },
    graySeparator: {
        flexDirection: 'row',
        // width: '100%',
        backgroundColor: Colors.graySeparator,
        height: verticalScale(1),
        marginVertical: verticalScale(15)
    },
    absoluteSeparator: {
        position: 'absolute',
        width: scale(330),
        bottom: 0,
        marginHorizontal: -verticalScale(20),
        backgroundColor: Colors.graySeparator,
        height: verticalScale(1)
    },
    heeboRegular: {
        fontFamily: 'heebo',
        fontSize: scale(13)
    },
    heeboMedium: {
        fontFamily: 'heebo-medium',
        fontSize: scale(13)
    },
    heeboLight: {
        fontFamily: 'heebo-light',
    },
    heeboBold: {
        fontFamily: 'heebo-bold',
    },
    appBottomBorderRadius: {
        borderBottomLeftRadius: scale(8),
        borderBottomRightRadius: scale(8),
    },
    appTopBorderRadius: {
        borderTopLeftRadius: scale(8),
        borderTopRightRadius: scale(8),
    },
    appSmBorderRadius: {
        borderRadius: scale(3)
    },
    appInputColor: {
        borderBottomColor: Colors.graySeparator,
    },
    appFocusInputColor: {
        borderBottomColor: Colors.paleblue,
    },
    headerBackButton: {
        paddingTop: Constants.statusBarHeight,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    headerIconBack: {
        color: Colors.white,
        fontSize: scale(30),
    },
    cardRadius: {
        borderRadius: scale(10),
    },
    screenBottomPadding: {
        paddingBottom: verticalScale(25)
    },
    itemToFlexEnd: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignSelf: 'center'
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    //NOTE CHANGED FOR MARGIN TO SUPPORT ANDROID
    paddingStart: {
        marginStart: scale(5)
    },
    paddingStartLarge: {
        marginStart: scale(10)
    },
    paddingStartXLarge: {
        marginStart: scale(15)
    },
    paddingStartXXLarge: {
        marginStart: scale(20)
    },
    paddingStartXXXLarge: {
        marginStart: scale(25)
    },
    //NOTE CHANGED FOR MARGIN TO SUPPORT ANDROID

    keyboardAvoidingViewWrapper: {
        flex: 1,
        flexDirection: 'column',
    },
    iconOverTextInput: {
        position: 'absolute',
        zIndex: 10000,
        end: 15,
        bottom: 18
    },
    textImageContainer: {
        marginHorizontal: scale(20),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: verticalScale(70)
    },
    drawerBorderTop: {
        borderTopLeftRadius: scale(10),
        borderTopRightRadius: scale(10),
    },
    iconFlip: {
        transform: [{
            scaleX: i18n.isRTL ? -1 : 1,
        }],
    },

    cardContainer: {
        marginBottom: verticalScale(20),
        shadowOffset: {width: 0, height: 5},
        shadowColor: 'rgba(0, 0, 0, 0.16)',
        backgroundColor: Colors.white,
        minHeight: verticalScale(100),
        shadowOpacity: 1,
        shadowRadius: 10,
        borderRadius: 5,
        elevation: 5,
    },
    cardContainerFix: {
        width: scale(320),
        alignSelf: 'center',
    },
    card: {
        padding: verticalScale(20),
        backgroundColor: Colors.white,
        borderRadius: 5,
    },

    cardActionButtonContainer: {
        position: 'relative',
        height: verticalScale(45),
        alignItems: 'center',
        justifyContent: 'center',
        borderTopColor: Colors.graySeparator,
        borderTopWidth: verticalScale(1),
        backgroundColor: Colors.transparent
    },
    cardActionButton: {
        fontSize: scale(13)
    },
    drawerRowHeight: {
        height: verticalScale(45),
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    rowAlignItems: {
        alignItems: 'center'
    },
    textWhite: {
        color: Colors.white
    },
    textBlack: {
        color: Colors.black
    },
    textRed: {
        color: Colors.red
    },
    bold: {
        fontWeight: 'bold'
    },
    fitImageWithSize: {
        height: verticalScale(100),
        width: scale(100),
    },
    marginVertical: {
        marginVertical: verticalScale(15)
    },
    marginVerticalVS10: {
        marginVertical: verticalScale(10)
    },
    title: {
        fontSize: scale(18)
    },
    subTitle: {
        fontSize: scale(15)
    },
    smallTitle: {
        fontSize: scale(12)
    },
    tinyText: {
        fontSize:scale(11)
    },
    clubImagePlaceholder: {
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        width: scale(40),
        height: scale(40),
        backgroundColor: Colors.clubPlaceholderColor
    },
    tabIndicator: {
        backgroundColor: Colors.white,
    },
    tabBackground: {
        backgroundColor: Colors.transparent,
    },
    rowBottomBorder: {
        borderBottomWidth: 1,
        borderColor: Colors.listSeparator
    },
    graySmallSubHeader: {
        fontSize: scale(10),
        fontFamily: 'heebo-bold',
        color: Colors.modalFontColor,
    },
    grayText: {
        color: Colors.grayText
    },
    lightBlackText: {
        color: Colors.lightBlack
    },

    sideMargin: {
      marginHorizontal: scale(10)
    },


   stickToHeaderBar: {
       backgroundColor: Colors.white,
       height: verticalScale(45),
       alignItems: "center"
   },
    simpleCardContainer: {
        marginBottom: verticalScale(10),
        backgroundColor: Colors.white,
        minHeight: verticalScale(85),
        borderRadius: 4,
    },

    cardsShadow:{
        shadowColor: Colors.grayText,
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.08,
        shadowRadius: 6.27,

        elevation: 10,
    },
    helveticaSeparatorWithText: {
        fontFamily: 'heebo',
        fontSize: verticalScale(14),
        lineHeight: verticalScale(17),
    },
    tabViewWrapper:{
        width:"100%",
        height:"100%",
        flex:1
    },

});

export const globalSiteStyles = (mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            siteBlackBtn: {
                boxSizing: 'border-box',
                minHeight: '2em',
                borderRadius: 3,
                borderWidth: 1,
                borderColor: Colors.lightBlack,
                paddingVertical: '0.5em'
            },
            siteHeaderShadow: {
                shadowColor: Colors.black,
                shadowOffset: {
                    width: 0,
                    height: 0,
                },
                shadowOpacity: 0.30,
                shadowRadius: 6.27,

                elevation: 10,
            },
            siteFlexRowSpaceBetween: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '1em'
            },
            siteFlexRow: {
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1em'
            },
            font1rem: {
                fontSize: '1rem'
            }
        }
    })
})

export const globalPickerSelectStyles = ScaledSheet.create({
    inputIOS: {
        fontSize: 15,
        paddingVertical: 5,
        paddingHorizontal: 5,
        color: Colors.white,
        fontWeight: '500',
        paddingRight: 30, // to ensure the text is never behind the icon.png
        ...globalStyles.textAlignByRTL
    },
    inputAndroid: {
        fontSize: 15,
        paddingVertical: 5,
        paddingHorizontal: 5,
        color: Colors.white,
        paddingRight: 30,
        ...globalStyles.textAlignByRTL
    },
    iconContainer: {
        ...Platform.select({
            android: {
                top: 7,
            },
            ios: {
                top: 2
            }
        })
    },
    tabViewOnCardLayoutWidth: {
        width: scale(330)
    },
    tabViewHeaderText: {
        fontSize: scale(15),
        textAlign: 'center',
        color: Colors.white,
    },
});

