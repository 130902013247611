import React, {useEffect, useState} from 'react';
import {ActivityIndicator, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform, TextInput} from "react-native";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import {Colors} from "../../../../styles/Colors";
import {isMobile} from "../../../../helpers/functions";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {useDispatch, useSelector} from "react-redux";
import ErrorModal from "../../Common/ErrorModal";
import {validatePromoCode} from "../../../../actions/shop";

const MyComponent = (props) => {
    const {onApply} = props;
    const dispatch = useDispatch()
    const cart = useSelector(state => state.shop.cart)
    const activePromoCode = useSelector(state => state.shop.activePromoCode)
    const user = useSelector(state => state.user.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation);
    const [input, setInput] = useState(activePromoCode ? activePromoCode.promoCode.name : '');
    const [valid, setValid] = useState(!!activePromoCode);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const mobile = isMobile();

    useEffect(() => {
        if(cart && cart.length > 0 && input && activePromoCode) {
            const promoCodeCartQuantities = calcQuantities(activePromoCode.cart)
            const cartQuantities = calcQuantities(cart)
            if(promoCodeCartQuantities !== cartQuantities) {
                validate();
            }
        }
    }, [cart]);

    useEffect(() => {
        setValid(activePromoCode !== null)
        setInput(activePromoCode ? activePromoCode.promoCode.name : '')
    }, [activePromoCode]);


    const calcQuantities = (cart) => {
        return cart.reduce((calc,item) => {
            return calc + item.quantity
        },0)
    }

    const validate = async () => {
        setIsLoading(true);
        const params = {
            users_id: Object.keys(user).length ? user.id : null,
            locations_id: location.id,
            promo_code_name: input,
            cart: cart
        }
        const validationObject = await dispatch(validatePromoCode(params))
        if(validationObject.errorMessages || validationObject.error) {
            handleErrors(validationObject)
            setValid(false);
        } else {
            onApply && onApply(validationObject)
            setValid(true);
        }
        setIsLoading(false)
    }

    const handleErrors = (errors) => {
        let errorsArr = [];
        if(errors.errorMessages && Object.keys(errors.errorMessages).length > 0) {
            errorsArr = [...errorsArr, ...Object.values(errors.errorMessages).map(error => error[0])];
        }
        if(errors.error) {
            errorsArr.push([...errorsArr, errors.error.messageToUser]);
        }
        setErrors(errorsArr.length > 0 ? errorsArr.join('\n') : null)
    }

    const handlePromoCodeExplanation = () => {
        let params = {}
        if (activePromoCode.promoCode.recurring_cycles === null) {
            return t('screens:Cart:PromoCode:promoCodeExplain', {discount: `${activePromoCode.promoCode.discount_amount}%`});
        } else {
            const item = cart.find(item => item.is_recurring_payment && item.price_after_promo_code && (item.price_after_promo_code !== item.price)) // just making sure it's the right membership for the promo discount
            if (item) {
                params = {
                    newPrice: item.price_after_promo_code.basicCycles,
                    relativePrice: item.price_after_promo_code.relative,
                    count: item.price_after_promo_code.basicCycles !== item.price_after_promo_code.relative ? activePromoCode.promoCode.recurring_cycles - 1 : activePromoCode.promoCode.recurring_cycles
                }
                if (activePromoCode.promoCode.recurring_cycles === 0) {
                    return t(`screens:Cart:PromoCode:${params.relativePrice !== params.newPrice ? 'promoCodeAllRecurringRelative' : 'promoCodeAllRecurring'}`, params);
                } else {
                    if (params.relativePrice !== params.newPrice) { // has relative
                        return t(`screens:Cart:PromoCode:${activePromoCode.promoCode.recurring_cycles === 1 ? 'promoCodeOneCycleRelative' : 'promoCodeNumCyclesRelative'}`, params);
                    } else {
                        return t(`screens:Cart:PromoCode:${activePromoCode.promoCode.recurring_cycles === 1 ? 'promoCodeOneCycle' : 'promoCodeNumCycles'}`, params);
                    }
                }
            }
        }
    }

    return (
        <>
            <View style={[styles.wrapper]}>
                <AppText style={[styles.header, mobile && styles.headerMobile]}>{t('screens:Cart:PromoCode:couponCode',{})}</AppText>
                <View style={[styles.inputWrapper]}>
                    <TextInput style={[styles.input]} onChangeText={setInput} value={input} placeholder={t('screens:Cart:PromoCode:couponCode',{})} blurOnSubmit={true}/>
                    {
                        isLoading ?
                            <ActivityIndicator />
                            :
                            <>
                                {
                                    valid ?
                                        <View style={[styles.validMark]}><Icons8Generator name={'check'} fill={Colors.white} size={'1em'}/></View>
                                        :
                                        <>
                                            {input !== '' &&
                                            <TouchableOpacity onPress={() => validate()}>
                                                <AppText
                                                    style={[styles.applyBtn, mobile && styles.applyBtnMobile]}>{t('screens:Cart:PromoCode:apply', {})}</AppText>
                                            </TouchableOpacity>
                                            }
                                        </>
                                }
                            </>
                    }
                </View>
                {activePromoCode && <AppText style={[styles.explain]}>{handlePromoCodeExplanation()}</AppText>}
            </View>
            {errors && <ErrorModal show={true} setClose={() => setErrors(null)} title={'errors'} subHeader={errors}/>}
        </>
    );
};

export default MyComponent;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                width: '100%',
                gap: 5,
            },
            header: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            inputWrapper: {
                backgroundColor: Colors.pastDateBg,
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                paddingHorizontal: 10,
                height: 40,
                alignItems: 'center',
                borderRadius: 3
            },
            input: {
                width: '80%'
            },
            applyBtn: {
                fontSize: '0.9rem',
                fontWeight: 'bold',
                color: Colors.btnBlue
            },
            applyBtnMobile: {
                fontSize: '1rem',
            },
            validMark: {
                width: '1.5em',
                height: '1.5em',
                borderRadius: '50%',
                backgroundColor: Colors.brandedGreen,
                justifyContent: 'center',
                alignItems: 'center'
            },
            explain: {
                fontSize: '0.8rem'
            }
        }
    })
})