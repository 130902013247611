import React from 'react';
import { View, Text, TouchableHighlight, TouchableOpacity } from 'react-native';
//import { connect } from 'react-redux';
import { Colors } from '../../../styles/Colors';
import Modal from 'react-native-modal';
import ModalSimple from '../../../components/ModalSimple';
import styles from './styles';
import { scale } from 'react-native-size-matters';
import * as Icon from '@expo/vector-icons';
import { t } from '../../../services/i18n';

class FriendRequestModal extends React.Component {

  constructor(props) {
    super(props);
  }
  closeModal = () => {
    this.props.showModal();
  }
  render() {
    const { isModalVisible, showModal, hideModal, error, information } = this.props;

    return (
      <Modal
        isVisible={isModalVisible}
        hideModalContentWhileAnimating
        animationIn="fadeIn"
        useNativeDriver
        animationOut="fadeOut"
        animationInTiming={200}
        animationOutTiming={200}
        backdropTransitionInTiming={200}
        backdropTransitionOutTiming={200}
        onBackdropPress={hideModal}
      >
        <View style={[styles.modalContentWrapper, { alignItems: 'center' }]}>
          <Icon.MaterialCommunityIcons
            name="account-plus"
            style={styles.userPlusIcon}
            size={scale(54)}
            color={Colors.brandPrimary}
          />
          <Text style={styles.friendRequestSuccessModalTitle}>{t("screens:Home:cards:balloons-card:modal:freiend-request-success-modal-title")}</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.friendRequestSuccessModalContent}>{t("screens:Home:cards:balloons-card:modal:freiend-request-success-modal-content-left")}</Text>
            <Text style={[styles.friendRequestSuccessModalContent, { fontFamily: 'heebo-bold' }]}>{`${information.firstName} ${information.lastName}`}</Text>
          </View>
          <Text style={styles.friendRequestSuccessModalContent}>{t("screens:Home:cards:balloons-card:modal:freiend-request-success-modal-content-right")}</Text>
          <TouchableOpacity style={styles.actionBtnStyle} onPress={() => this.closeModal()}>
            <Text style={styles.actionBtnText}>{t("screens:Home:cards:balloons-card:modal:freiend-request-success-modal-action-button-text")}</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }
}

/*const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      fetchFriendProfile,
    },
    dispatch,
  );
};*/

//const mapStateToProps = state => ({  });

export default FriendRequestModal;
