import React from 'react';
import {View, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard,Platform} from 'react-native';
import {bindActionCreators} from "redux";
import {Form} from 'native-base';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux'

import {
    email,
    emailRequired,
    passwordMinLength4,
    passwordRequired, removeWhiteSpace, validateAfterEmailSymbol
} from '../../helpers/Validators';
import {Colors} from '../../styles/Colors';
import {t} from '../../services/i18n';
import {authenticateUser} from "../../actions/auth";
import {AppText} from "../Stateless/AppText";
import FloatingTextInput from "../Stateless/FloatingTextInput";
import {globalStyles} from "../../styles/Global";
import Spinner from 'react-native-loading-spinner-overlay';
import {FormButton} from "../Stateless/FormButton";

class SimpleForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            submitting: false,
            serverError: false
        };
    }


    submit = async (values) => {
        try {
            this.setState({error: null, submitting: true});
            const res = await this.props.authenticateUser(values.email, values.password);
            if (res.error) {
                this.setState({serverError: true, error: res.error, submitting: false})
            }
        } catch (error) {
            this.setState({serverError: true, error, submitting: false})
        }

    };

    componentDidMount() {
    }


    render() {
        const {handleSubmit} = this.props;
        const {error} = this.state;

        return (
            <KeyboardAvoidingView
                behavior={"padding"}
                enabled={Platform.OS.toLowerCase() === 'ios'}
                style={[globalStyles.keyboardAvoidingViewWrapper]}>
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <Form style={{flex: 1}}>
                        <Spinner visible={this.state.submitting}/>
                        <View style={[globalStyles.appMinimalPaddingHorizontal, globalStyles.flex]}>
                            <AppText style={[globalStyles.textRed]}>
                                {this.state.serverError ? error ? error.messageToUser !== null ? t(`common:serverErrors:${error.messageToUser}`, {}) : error.message : '' : ''}
                            </AppText>
                            <Field
                                name="email"
                                type="email"
                                keyboardType="email-address"
                                autoCompleteType="email"
                                component={FloatingTextInput}
                                showError={this.state.emailError}
                                onBlur={() => this.setState({emailError: true})}
                                onChange={() => this.setState({serverError: false})}
                                placeholder={t('screens:Login:email', {})}
                                style={{backgroundColor: Colors.green}}
                                validate={[emailRequired, email, validateAfterEmailSymbol]}
                                returnKeyType={'next'}
                                normalize={removeWhiteSpace}
                                onSubmitEditing={() => {
                                    if (this.password) {
                                        this.password.getRenderedComponent().props.input.ref.focus();
                                    }
                                }}
                            />
                            <Field
                                name="password"
                                type="password"
                                forwardRef={true}
                                ref={(ref) => {
                                    this.password = ref
                                }}
                                component={FloatingTextInput}
                                placeholder={t('screens:Login:password', {})}
                                showError={this.state.passwordError}
                                onBlur={() => this.setState({passwordError: true})}
                                onChange={() => this.setState({serverError: false})}
                                onSubmitEditing={handleSubmit(this.submit)}
                                secureTextEntry
                                returnKeyType={'go'}
                                normalize={removeWhiteSpace}
                                validate={[passwordRequired, passwordMinLength4]}
                            />
                            <FormButton onPress={handleSubmit(this.submit)} text={t('screens:Login:login', {})}/>
                        </View>
                    </Form>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>

        );
    }
}

const mapStateToProps = state => ({
    error: state.auth.error
});

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            authenticateUser
        },
        dispatch
    );
};

SimpleForm = connect(
    mapStateToProps,
    mapActionsToProps
)(SimpleForm);


export default reduxForm({
    form: 'loginForm',
    fields: ['email', 'password']
})(SimpleForm);

