import React from 'react';
import {StyleSheet,View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../../Stateless/AppText";
import {scale} from 'react-native-size-matters';
import {t} from "../../../services/i18n";
import {MEMBERSHIP_TYPES_CONST} from "../../../constants/membershipTypes";
import {Colors} from "../../../styles/Colors";
import {getTaxesFromMembership} from "../../../helpers/functions";

class Price extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }


    render() {
        const {membership, showTaxes,extraInfoDisplay} = this.props;
        const taxes = getTaxesFromMembership(membership);
        return (
            <React.Fragment>
                <AppText
                    style={[globalStyles.heeboRegular, this.props.style ? this.props.style : {}]}>
                    {this.props.currencySymbol}{membership.price.toFixed(2)}
                </AppText>
                {extraInfoDisplay ? this.handleExtraInfo() : null}
                {showTaxes && taxes.length > 0 ? (
                    taxes.map((tax,index) => {
                        return (<AppText
                            style={[globalStyles.heeboRegular, globalStyles.grayText,index === 0 ? globalStyles.marginTopSm : {}]}>
                            <AppText style={[globalStyles.grayText,{fontSize:scale(10)}]}>+
                            </AppText>{this.props.currencySymbol}{((tax.percentage / 100) * membership.price).toFixed(2)} {tax.name}
                        </AppText>)
                    })

                ) : null}
            </React.Fragment>
        )
    }

    handleExtraInfo(){
        const {membership} = this.props;
        if(membership.is_recurring_payment === 1 && membership.period_time_unit !== null){
            return (
                <AppText> / {t(`screens:Shop:card:recurringCycle:cyclePeriod:${membership.recurring_cycle.toString()}`, {})}</AppText>
            )
        }
        if(membership.type === MEMBERSHIP_TYPES_CONST.TYPE_SESSION){
            const pricePerSession = (membership.price / membership.sessions).toFixed(1);
            return (
                <View style={{flex:1,alignItems:'flex-end'}}>
                    <AppText style={[globalStyles.grayText]}>{this.props.currencySymbol}{pricePerSession} / {t('screens:Shop:card:session', {})}</AppText>
                </View>
                    )
        }
            return null;
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch,
    );
};

const mapStateToProps = state => ({
    currencySymbol: state.shop.currencySymbol,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(Price);


const styles = StyleSheet.create({});