import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgFingerDownArbox(props) {
  return (
      <Svg viewBox="0 0 182.4 246.7" {...props}>
          <Path
              d="M67.6 109.7l1.6 70.7c0 10.6 7.5 16.6 14.5 15C96 192.7 95 181.9 95 162.7l-.4-20.9"
              fill="none"
              stroke="#ed3b4a"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={5}
          />
          <Path
              d="M146.8 14.9A55.72 55.72 0 01171.5 59h.1l1.1 27.7"
              fill="none"
              stroke="#f5c2d1"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={5}
          />
          <Path
              d="M66.8 89.6l-1.8 4a16.61 16.61 0 11-30.4-13.4S52.3 42.3 56 34.8c10.3-21.5 32.2-29 45.2-29.4 0 0 13.3-1.3 32.7 3"
              fill="none"
              stroke="#0554a3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={5}
          />
          <Path
              fill="none"
              stroke="#f5c2d1"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={5}
              d="M85.4 50.3L74.9 72.4"
          />
          <Path
              d="M134.4 78.7h-.1a13.13 13.13 0 00-13.5 12.6l.5-5.3a13.15 13.15 0 00-12.6-13.6h-.1A13.16 13.16 0 0095 85l-.5 17.4m78.8-3.6v7.8a13.15 13.15 0 01-12.6 13.6h-.1a13.2 13.2 0 01-13.6-12.5v6.1a13.15 13.15 0 01-12.6 13.6h-.1a13.13 13.13 0 01-13.5-12.6l.5 5.3a13.15 13.15 0 01-12.6 13.6h-.1a13.13 13.13 0 01-13.5-12.6l-.5-17.4M36 243.6s27.5-3.8 29.2-11.9c3.5-16.4-51.9-1.2-51.5-15.1S83.5 206 83.5 206"
              fill="none"
              stroke="#4aba7a"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={5}
          />
      </Svg>
  );
}

export default SvgFingerDownArbox;
