import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8CreditCard(props) {
  return (
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
          <Path
              fill="#64B5F6"
              d="M45 35a4 4 0 01-4 4H7a4 4 0 01-4-4V13a4 4 0 014-4h34a4 4 0 014 4v22z"
          />
          <Path fill="#37474F" d="M3 14h42v6H3z" />
          <Path fill="#E3F2FD" d="M5 22h38v5H5z" />
      </Svg>
  );
}

export default SvgIcons8CreditCard;
