import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
        <Path d="M33 .063c-.719 0-1.418.304-1.969.843L31 .875l-5.906 5.844c-.16.097-.29.238-.375.406L.78 30.875v.031a2.827 2.827 0 0 0 0 4L3.906 38a1 1 0 0 0 1.313.094c.86-.688 1.98-1.188 3.093-1.188 2.66 0 4.688 2.125 4.688 4.688a4.49 4.49 0 0 1-1.156 3.031A1 1 0 0 0 11.906 46L15 49.094a2.827 2.827 0 0 0 4 0l23.656-23.657h.032a.993.993 0 0 0 .75-.78v-.032L49 19.094c.04-.028.059-.063.094-.094 1.203-1.008 1.199-2.832.125-3.906L46.094 12a1 1 0 0 0-1.313-.094C43.926 12.59 42.973 13 41.813 13a4.686 4.686 0 0 1-4.72-4.688c0-1.16.41-2.144 1.094-3A1 1 0 0 0 38.094 4L35 .906a2.835 2.835 0 0 0-2-.844Zm0 2.03c.223 0 .445.071.594.22l2.562 2.562c-.61 1-1.062 2.145-1.062 3.438 0 3.636 2.976 6.687 6.718 6.687 1.297 0 2.407-.453 3.407-1.063L47.78 16.5c.297.297.32.785.032 1a.949.949 0 0 0-.126.094l-5.343 5.343a.994.994 0 0 0-.75-.25 1 1 0 0 0-.657 1.657L17.595 47.687c-.297.297-.89.297-1.188 0l-2.562-2.562c.68-1.047 1.156-2.234 1.156-3.531 0-3.637-2.945-6.688-6.688-6.688-1.34 0-2.515.516-3.53 1.157L2.218 33.5c-.297-.297-.297-.89 0-1.188l23.406-23.28a1.006 1.006 0 0 0 1.438-1.407l5.343-5.313c.149-.148.371-.218.594-.218Zm-4.031 8.032-.75.5-.032.906.22.281 1.374 1.407.875.281.719-.531.031-.875-.187-.313-1.407-1.375Zm4.187 4.188-.718.53-.032.876.188.312L34 17.438l.844.28.75-.53.031-.875-.188-.313-1.406-1.406Zm4.219 4.218-.719.5-.031.907.188.28 1.406 1.407.843.281.75-.5.032-.906-.219-.281-1.406-1.407Z" />
    </Svg>
)

export default SvgComponent
