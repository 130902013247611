// @flow
import * as React from 'react';
import {Linking, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform, TouchableOpacity} from "react-native";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import LoginForm from "../../Shared/LoginForm";
import {isMobile} from "../../../helpers/functions";

const LoginCta = (props) => {
    const {onLogin, onForgotPassword} = props;
    const mobile = isMobile();

    return (
        <View style={[{justifyContent: 'space-between', paddingBottom: '1em'}, props.style]}>
            <View style={[styles.content, mobile && styles.contentMobile]}>
                <AppText style={[styles.header]}>{t('screens:Login:returning-user',{})}</AppText>
                <View style={styles.loginContent}>
                    <LoginForm handleSubmit={onLogin}/>
                    <TouchableOpacity style={{alignSelf: 'center'}} onPress={onForgotPassword}>
                        <AppText style={[styles.smallText, styles.clickableText]}>{t('screens:Login:forgot-password', {})}</AppText>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{alignSelf: 'center', textAlign: 'center'}}>
                <AppText style={[styles.smallText]}>{t('screens:Login:agree-to-terms', {})}</AppText>
                <TouchableOpacity onPress={() => Linking.openURL('https://arboxassets.s3.eu-central-1.amazonaws.com/Docs/Arbox+Privacy+Policy.pdf')}>
                    <AppText style={[styles.smallText, styles.clickableText]}>{t('screens:Login:terms', {})}</AppText>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default LoginCta;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            header: {
                fontFamily: 'heebo',
                fontSize: '1.3rem',
                color:  Colors.lightBlack
            },
            content: {
                gap: '1.3em'
            },
            smallText: {
                fontSize: '0.8rem'
            },
            clickableText: {
                color: Colors.blue
            },
            loginContent: {
                gap: '1em'
            },
            contentMobile: {
                // borderColor: Colors.graySeparator,
                // borderWidth: 1,
                padding: '1.3em',
                gap: '1em'
            }
        }
    })
})