import React from 'react';
import {FlatList, View, RefreshControl, TouchableOpacity, TouchableWithoutFeedback} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import ChillComponent from "../../../components/ChillComponent";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchWorkouts} from '../../../actions/logbook'
import styles from "../../../components/FeedCard/style";
import {CardLoader} from "../../../loaders";
import {scale, verticalScale} from "react-native-size-matters";
import {AppText} from "../../../components/Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import i18n, {t} from "../../../services/i18n";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {AntDesign} from '@expo/vector-icons';
import {SMALL_ICON_SIZE} from "../../../helpers/functions";
import {withNavigation} from "react-navigation";
import date from '../../../services/i18n/date';

@withNavigation
class LogBookItems extends React.PureComponent {
    constructor(props) {
        super(props);
        this.goToWorkout = this.goToWorkout.bind(this);
        this.skills = this.skills.bind(this);
        this.isMultiUser = this.props.user.users_boxes.length > 1;

    }


    componentDidMount() {
    }

    goToWorkout(item) {
        this.props.navigation.navigate("Workout", {
            workout: item
        })
    }

    renderItem = (item, index, separators) => {
        if (this.props.type === 'skills') {
            return this.skills(item);
        }
        const firstItem = item[0][0];
        const box = firstItem.boxes;
        const boxCategories = firstItem.box_categories;
        const hasUserResult = item.some(boxSection => boxSection.some(obj => obj.user_result));
        const titleBoxSection = firstItem.title_section_box !== null ? item[0][0].title_section_box.title : null;
        const workoutDate = date.format(firstItem, 'LL');
        return (
            <TouchableOpacity onPress={() => this.goToWorkout(item)}
                              style={[globalStyles.cardContainer, globalStyles.cardContainerFix, globalStyles.flexDirectionRowSpaceBetween, {alignItems:'center',minHeight: verticalScale(80)}]}>
                <View
                    style={[globalStyles.card]}>
                    <View style={[globalStyles.flexDirectionColumn]}>
                        <View style={globalStyles.flexDirectionRow}>
                            <AppText
                                style={[globalStyles.heeboRegular, globalStyles.title]}>{boxCategories.name}</AppText>
                            {titleBoxSection ? (
                                <AppText
                                    style={[globalStyles.heeboRegular, globalStyles.title]}> \ {titleBoxSection}</AppText>
                            ) : null}
                        </View>
                        {this.props.showDate === true ? <AppText
                            style={[globalStyles.heeboRegular, globalStyles.marginTopSm, {color: Colors.grayText}]}>{workoutDate}</AppText> : null}
                        {this.isMultiUser ? <AppText
                            style={[globalStyles.heeboRegular, globalStyles.marginTopSm, {color: Colors.grayText}]}>{box.name}</AppText> : null}
                    </View>
                </View>
                <View style={[globalStyles.flexDirectionRow, {paddingHorizontal: scale(10)}]}>
                    {hasUserResult ? <AntDesign name="checkcircle" size={scale(20)} color={Colors.green}/> : null}
                    <Icons8Generator fill={Colors.grayText} width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE}
                                     style={[globalStyles.paddingStart, {marginTop: verticalScale(2)}]}
                                     name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>
                </View>
            </TouchableOpacity>
        )
    };

    skills(item) {
        return (
            <TouchableOpacity onPress={() => this.props.navigation.navigate("Skill", {
                name: item.display_name,
                id: item.id,
            })}
                              style={[globalStyles.cardContainer, globalStyles.cardContainerFix, globalStyles.flexDirectionRowSpaceBetween, {alignItems:'center',minHeight: verticalScale(70)}]}>
                <View style={[globalStyles.card]}>
                    <AppText>{item.display_name}</AppText>
                </View>
                <View style={[globalStyles.flexDirectionRow, {paddingHorizontal: scale(10)}]}>
                    <Icons8Generator fill={Colors.grayText} width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE}
                                     style={[globalStyles.paddingStart, {marginTop: verticalScale(2)}]}
                                     name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>
                </View>
            </TouchableOpacity>
        )
    }

    header() {

        if (this.props.header === true) {
            const text = this.props.type === 'skills' ? 'screens:Logbook:skills' : 'screens:Logbook:workouts'
            return (
                <TouchableWithoutFeedback onPress={() => console.log('hi')}>
                    <AppText
                        style={[globalStyles.heeboRegular, globalStyles.appPaddingHorizontal, {marginBottom: verticalScale(5)}]}>{t(text, {count: this.props.data.length})}</AppText>
                </TouchableWithoutFeedback>

            )
        }
    }

    render() {
        if (this.props.isLoading) {
            return (
                <View style={globalStyles.loadingCardContainer}>
                    {
                        [...Array(3)].map((e, index) => <View style={[globalStyles.loadingCard]}
                                                              key={index}><CardLoader/></View>)
                    }
                </View>
            );
        }
        return (
            <View style={{flexGrow: 1}}>
                <FlatList
                    ref={el => this.flatList = el}
                    data={this.props.data}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({item, index, separators}) => this.renderItem(item, index, separators)}
                    keyExtractor={(item, index) => index.toString()}
                    ListHeaderComponent={this.header()}
                    collapsed={true}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={this.props.init}
                        />
                    }
                    contentContainerStyle={[{
                        flexGrow: 1,
                        justifyContent: 'flex-start'
                    }, globalStyles.marginTopBetweenElements]}
                    ListEmptyComponent={<ChillComponent styles={styles.flexCenterElements}/>}
                />
            </View>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchWorkouts
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(LogBookItems);


