import React from 'react';
import { View } from 'react-native';
import styles from './styles';
import ChilloutIcon from '../../componentImages/ChilloutIcon';
import {t} from '../../services/i18n';
import {AppText} from "../Stateless/AppText";

class ChillComponent extends React.PureComponent {
  render() {
    return (
      <View style={styles.flexContainerCentered}>
        <View style={styles.chillImage}>
          <ChilloutIcon />
        </View>
        <AppText style={styles.chillText}>{t('general:noSchedulesFound',{})}</AppText>
      </View>
    );
  }
}

export default ChillComponent;
