
import  React from "react"
import Svg, { G, Path } from 'react-native-svg';

function ArboxSvg(props) {
    return (
        <Svg width={228} height={51} viewBox="0 0 228 51" {...props}>
            <G fill={props.color} fillRule="nonzero">
                <Path d="M18.34 8.712h.063l4.91 15.444h-9.978L18.34 8.712zM13.968 0L0 38.876h8.425l2.756-8.02h14.444l2.47 8.02h8.68L23.026 0h-9.059zM59.89 38.876V9.202h7.204v3.57c.624-1.02 1.279-1.785 1.965-2.359a8.896 8.896 0 012.12-1.306c.718-.319 1.467-.478 2.215-.574a18.342 18.342 0 012.308-.127h.998v8.031a11.928 11.928 0 00-2.12-.19c-4.679 0-7.018 2.39-7.018 7.17v15.459h-7.673zM126.38 19.112c-.532-1.763-1.283-3.278-2.252-4.577a9.933 9.933 0 00-3.661-2.969c-1.44-.711-3.067-1.052-4.913-1.052-3.473 0-6.289 1.454-8.385 4.361V1.051h-7.353v38.133h7.165l.063-3.402c1.72 2.753 4.349 4.144 7.853 4.144 1.752 0 3.41-.34 4.913-1.02a10.91 10.91 0 003.88-2.938c1.063-1.3 1.908-2.846 2.534-4.701.626-1.856.907-3.96.907-6.31a20.217 20.217 0 00-.75-5.845m-8.574 12.433c-1.189 1.577-2.722 2.35-4.6 2.35-1.814 0-3.316-.742-4.505-2.226-1.22-1.485-1.815-3.402-1.815-5.784 0-6.061 2.096-9.092 6.258-9.092 4.287 0 6.414 2.876 6.414 8.628.032 2.536-.563 4.547-1.752 6.124M173.424 18.098c-.65-1.81-1.61-3.37-2.819-4.617-1.207-1.28-2.694-2.247-4.428-2.964-1.735-.686-3.686-1.06-5.854-1.06-2.23 0-4.212.342-5.946 1.06-1.734.686-3.252 1.685-4.49 2.995-1.24 1.279-2.2 2.839-2.85 4.648-.65 1.81-.99 3.838-.99 6.021 0 2.153.309 4.15.96 5.96.65 1.809 1.579 3.369 2.786 4.648 1.208 1.279 2.726 2.277 4.491 2.995 1.765.717 3.779 1.092 6.04 1.092 2.136 0 4.087-.344 5.822-1.061 1.734-.686 3.22-1.685 4.46-2.995 1.207-1.28 2.167-2.84 2.818-4.617.65-1.81.99-3.806.99-6.053 0-2.215-.34-4.243-.99-6.052m-13.132 14.6c-4.46 0-6.72-2.839-6.72-8.517 0-5.646 2.23-8.454 6.72-8.454 4.46 0 6.72 2.808 6.72 8.454 0 5.678-2.229 8.517-6.72 8.517M206.986 15.248l-.637.992-3.788 5.825-8.183-12.609h8.82zM222.747 38.876h-8.593l-4.016-6.103 4.359-6.506zM219.606 1.05l-11.69 17.764-.062.093-4.104 6.292-.094.155-16.633 25.08h8.084l12.715-18.973 4.198-6.324L228 1.051z" />
            </G>
        </Svg>
    )
}

export default ArboxSvg