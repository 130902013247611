// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import Arrow from "../../../../svg/Icons8Back"
import i18n, {t} from "../../../../services/i18n";
import {useEffect, useState} from "react";
import ModalButton from "../../../ModalButton";
import moment from "moment";
import Dropdown from "../../Common/Dropdown";
import {useSelector} from "react-redux";
import EStyleSheet from "react-native-extended-stylesheet";
import TextInputField from "../../Common/TextInputField";
import SearchInput from "../../Common/SearchInput";
import {isSiteHebrew} from "../../../../helpers/ComponentFunctionality";
import {globalSiteStyles} from "../../../../styles/Global";
import {isMobile} from "../../../../helpers/functions";

const Header = (props) => {
    const {onWeekChange, startDate, endDate, onLocationChanged, onSearchChanged} = props;
    const [fromDate, setFromDate] = useState(startDate);
    const [toDate, setToDate] = useState(endDate);
    const [dateTitle, setDateTitle] = useState('');
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const siteSelectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const [selectedLocation, setSelectedLocation] = useState(siteSelectedLocation ? siteSelectedLocation.id : null);
    const [searchInput, setSearchInput] = useState('');
    const mobile = isMobile()

    useEffect(() => {
        if(startDate && endDate) {
            updateDateTitle(startDate,endDate)
        }
        setFromDate(startDate);
        setToDate(endDate)
    }, [endDate, startDate]);

    useEffect(() => {
        onSearchChanged(searchInput)
    }, [searchInput]);

    useEffect(() => {
        setSelectedLocation(siteSelectedLocation?.id)
    }, [siteSelectedLocation]);


    const updateDateTitle = (start, end) => {
        let title;
        if(start.month() === end.month()) {
            title = `${start.date()} - ${end.date()} ${start.format('MMM')}, ${end.format('YYYY')}`
        } else {
            title = `${start.date()} ${start.format('MMM')} - ${end.date()} ${end.format('MMM')}, ${end.format('YYYY')}`
        }
        setDateTitle(title);
    }

    const handleNavigateWeek = (isBack = false) => {
        let newFrom;
        let newTo;
        if(isBack) {
            newFrom = fromDate.subtract(1,'w')
            newTo = toDate.subtract(1,'w')
        } else {
            newFrom = fromDate.add(1,'w')
            newTo = toDate.add(1,'w')
        }
        updateDateTitle(newFrom,newTo)
        setFromDate(newFrom);
        setToDate(newTo)
        onWeekChange(newFrom, newTo)
    }

    const navigateToToday = () => {
        const from = moment().startOf('week');
        const to =  moment().endOf('week')
        updateDateTitle(from,to)
        setFromDate(from);
        setToDate(to)
        onWeekChange(from,to)
    }

    const handleLocationChanged = (newLocation) => {
        setSelectedLocation(newLocation);
        onLocationChanged(newLocation)
    }

    return (
        <View style={styles.wrapper}>
            <View style={styles.navigationSide}>
                <View style={styles.arrowsWrapper}>
                    <TouchableOpacity onPress={() => handleNavigateWeek(true)}><Arrow style={[styles.arrow, isSiteHebrew() ? styles.reverseArrow : null]}/></TouchableOpacity>
                    <TouchableOpacity onPress={() => handleNavigateWeek()}><Arrow style={[styles.arrow, isSiteHebrew() ? null : styles.reverseArrow]}/></TouchableOpacity>
                </View>
                <AppText style={styles.text}>{dateTitle}</AppText>
                <ModalButton
                    onPress={navigateToToday}
                    text={t('general:today', {})}
                    textColor={Colors.lightBlack}
                    overrideStyle={[globalSiteStyles(mobile).siteBlackBtn, styles.todayBtn]}
                />
            </View>
            <View style={styles.navigationSide}>
                {box?.locations_box.length > 1 &&
                <Dropdown items={box?.locations_box}
                          valuePropName='id'
                          labelPropName='location'
                          iconName={'location'}
                          initValue={selectedLocation}
                          onSelection={(value) => handleLocationChanged(value)}
                          style={{width: '11em'}}
                />}
                <SearchInput placeHolder={t('screens:Schedule:search-class', {})} value={searchInput} onChange={setSearchInput} overrideStyles={{height: '100%'}}/>
            </View>
        </View>

    );
};
export default Header;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                backgroundColor: Colors.white,
                width: "100%",
                padding: '1em',
                borderBottomWidth: 1,
                borderBottomColor: Colors.siteScheduleBorder,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            navigationSide: {
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1em',
                height: '100%'
            },
            arrowsWrapper: {
                flexDirection: 'row',
                gap: '1em'
            },
            arrow: {
                width: '1.2em'
            },
            reverseArrow: {
                transform: 'scaleX(-1)'
            },
            text: {
                fontSize: '1.1em',
                fontWeight: 'bold'
            },
            todayBtn: {
                height: '2em',
                width: 'fit-content',
                paddingHorizontal: '1em',
                marginTop: 0
            },
            expandArrow: {
                position: 'relative',
                width: '0.8em'

            },
            datePicker: {
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: '100 !important'
            }
        }
    })
})