import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        fill="#191919"
        width="1em"
        height="1em"
        {...props}
    >
        <Path d="M25 .063C17.316.063 11.062 6.316 11.062 14c0 6.367 3.34 13.668 6.626 19.469 3.285 5.8 6.562 10.093 6.562 10.093a.934.934 0 0 0 .75.383.934.934 0 0 0 .75-.383s3.281-4.351 6.563-10.187c3.28-5.836 6.624-13.14 6.624-19.375C38.938 6.316 32.684.062 25 .062Zm0 1.875A12.04 12.04 0 0 1 37.063 14c0 5.555-3.157 12.75-6.376 18.469-2.628 4.676-4.816 7.742-5.687 8.937-.875-1.18-3.063-4.207-5.688-8.843-3.214-5.68-6.375-12.86-6.375-18.563A12.04 12.04 0 0 1 25 1.937Zm0 6.093c-3.836 0-6.969 3.133-6.969 6.969 0 3.836 3.133 6.969 6.969 6.969 3.836 0 6.969-3.133 6.969-6.969 0-3.836-3.133-6.969-6.969-6.969Zm0 1.938A5.013 5.013 0 0 1 30.031 15 5.013 5.013 0 0 1 25 20.031 5.013 5.013 0 0 1 19.969 15 5.013 5.013 0 0 1 25 9.969Zm-9.844 24.187c-4 .586-7.383 1.512-9.875 2.719-1.246.602-2.277 1.273-3.031 2.063C1.496 39.727 1 40.706 1 41.75c0 1.43.914 2.652 2.219 3.625 1.304.973 3.066 1.758 5.219 2.438C12.738 49.172 18.562 50 25 50c6.438 0 12.262-.828 16.563-2.188 2.152-.68 3.914-1.464 5.218-2.437C48.086 44.402 49 43.18 49 41.75c0-1.04-.496-2.023-1.25-2.813-.754-.789-1.785-1.46-3.031-2.062-2.492-1.207-5.875-2.133-9.875-2.719a1.014 1.014 0 0 0-1.157.844c-.085.55.293 1.07.844 1.156 3.86.567 7.11 1.465 9.313 2.532 1.101.535 1.953 1.117 2.468 1.656.516.539.688.988.688 1.406 0 .574-.383 1.234-1.406 2-1.024.766-2.614 1.52-4.625 2.156C36.945 47.176 31.266 48 25 48s-11.945-.824-15.969-2.094c-2.011-.636-3.601-1.39-4.625-2.156C3.383 42.984 3 42.324 3 41.75c0-.418.172-.867.688-1.406.515-.54 1.367-1.121 2.468-1.657 2.203-1.066 5.453-1.964 9.313-2.53.55-.087.93-.606.844-1.157a1.014 1.014 0 0 0-1.157-.844Z" />
    </Svg>
)

export default SvgComponent