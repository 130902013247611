import {
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED
} from './constants';

export const changePassword = (username, password) => {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_PENDING });

    if (password === 'password') {
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: { user: 'test' } });
    } else {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: { error: 'error forgot password message' }
      });
    }
  };
};
