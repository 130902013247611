import React from 'react';
import {TouchableOpacity} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {ScaledSheet, verticalScale, scale} from "react-native-size-matters"
import {AppText} from "../AppText";
import {getThemeProperty, keys} from "../../../styles/Theme";


export const FormButton = props => {
    return (
        <TouchableOpacity style={[styles.submit,{backgroundColor:getThemeProperty(keys.brandedGreen)}]} onPress={() => props.onPress()}>
            <AppText
                style={styles.text}>{props.text}</AppText>
        </TouchableOpacity>
    )
};


const styles = ScaledSheet.create({
    submit: {
        marginTop: verticalScale(30),
        height: verticalScale(50),
        borderRadius: scale(3),
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        ...globalStyles.heeboMedium,
        ...globalStyles.textWhite,
        fontSize: scale(16)
    }
});
