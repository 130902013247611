import * as React from 'react';
import Modal from 'react-native-modal';
import { View } from 'native-base';
import { Image, TouchableWithoutFeedback,TouchableOpacity, Dimensions } from 'react-native';
import { Colors } from '../../styles/Colors';
import styles from './styles';
import PropTypes from 'prop-types';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {AppText} from "../Stateless/AppText";
import {getThemeProperty, keys} from "../../styles/Theme";


class ModalWithTopBarImage extends React.PureComponent {
  state = {
    imgWidth: 0,
    imgHeight: 0
  };

  componentDidUpdate(prevProps) {
    // if (this.props.isModalVisible !== prevProps.isModalVisible) {
    //   this.setState({ isModalVisible: this.props.isModalVisible });
    // }
      if ( this.props.imageUrl !== prevProps.imageUrl){
        this.initImage();
      }
  }

  componentDidMount() {
      this.themeColor = getThemeProperty(keys.brandedGreen);
   this.initImage()
  }

  initImage() {
      Image.getSize(
          this.props.imageUrl,
          (width, height) => {
              const screenWidth = Dimensions.get('window').width;
              const scaleFactor = width / screenWidth;
              const imageHeight = height / scaleFactor;
              this.setState({ imgWidth: screenWidth, imgHeight: imageHeight });
          },
          () => {}
      );
  }

  render() {
    const { imgHeight } = this.state;
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>
        <Modal
          isVisible={this.props.isVisible}
          hideModalContentWhileAnimating
          animationIn="fadeIn"
          useNativeDriver={false}
          animationOut="fadeOut"
          animationInTiming={this.props.animationTiming || 500}
          animationOutTiming={this.props.animationTiming || 500}
          backdropTransitionInTiming={this.props.animationTiming || 500}
          backdropTransitionOutTiming={this.props.animationTiming || 500}
          onBackdropPress={this.props.toggleModal}
        >
          <View>
            <View
              style={[
                { backgroundColor: this.props.headerColor || this.themeColor },
                styles.modalTopBarHeader
              ]}
            >
              <AppText style={styles.topBarHeaderText}>{this.props.headerText}</AppText>
              <TouchableOpacity style={{zIndex:100}} onPress={this.props.toggleModal}>
                <Icons8Generator name={'close'} fill={Colors.white}/>
              </TouchableOpacity>
            </View>
            <View style={styles.modalContent}>
              <Image
                borderBottomLeftRadius={5}
                borderBottomRightRadius={5}
                style={[styles.imageStyle, { height: imgHeight }]}
                resizeMode="cover"
                source={{ uri: this.props.imageUrl }}
              />
            </View>
          </View>
        </Modal>
      </View>
    );
  }
}

ModalWithTopBarImage.propTypes = {
  isModalVisible: PropTypes.bool,
  imageUrl: PropTypes.string,
  toggleModal: PropTypes.func,
  headerIconSize: PropTypes.number,
  headerIconColor: PropTypes.string,
  headerText: PropTypes.string,
  animationTiming: PropTypes.number,
  headerColor: PropTypes.string
};

export default ModalWithTopBarImage;
