import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalSimple from '../../../components/ModalSimple';
import styles from './styles';
import { t } from '../../../services/i18n';
import FriendsList from '../../../components/FriendsList';

import {FRIEND_CONNECTIONS_ACTION} from "../../../constants";

class BalloonsListModal extends React.Component {
  state = {
    modalStep: this.props.isInfoModal ? 0 : 1,
    selectedUser: null
  };

  constructor(props) {
    super(props);
    this.moveToNextStep = this.moveToNextStep.bind(this);
    this.viewProfile = this.viewProfile.bind(this);
  }

  componentDidMount() {
  }

  closeModal() {
    this.props.showModal();
    this.setState({ modalStep: 0 });
  }

  viewProfile() {
    const { fetchFriendProfile } = this.props;
    const id = this.state.selectedUser.id;
    if (id === 420) {
      this.closeModal();
      fetchFriendProfile(id);
    }
    else this.setState({ modalStep: 2 });
  }

  moveToNextStep(user) {
    this.setState({ modalStep: 1, selectedUser: user });
  }

  render() {
    const {  information } = this.props;
      return (

              <View>
                  <FriendsList
                      actionDisabled={false}
                      list={information}
                      isMyFriends={false}
                      displaySmall={true}
                      directObject={false}
                      textButtons={true}
                      action={'thanksForBalloons'}
                      reducerName={FRIEND_CONNECTIONS_ACTION}
                      drawer={true}
                  />
              </View>
      );

  }
}

BalloonsListModal.propTypes = {
  isModalVisible: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  information: PropTypes.instanceOf(Object),
};



export default connect(
  null,
  null,
)(BalloonsListModal);

