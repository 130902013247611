import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgFingerDown(props) {
  return (
      <Svg width={139} height={156} viewBox="0 0 139 156" {...props}>
          <G fill="none" fillRule="evenodd">
              <G fillRule="nonzero">
                  <Path
                      d="M108.117 74.175v3.159H70.352l-.047 44.224c0 3.494-3.452 9.442-9.746 9.442-6.295 0-9.09-6.283-9.09-9.442V45.77L37.702 58.472c-4.374 4.397-11.54 3.86-15.225-.578-3.887-4.397-2.92-11.23.975-15.128l17.914-17.98C51.165 14.155 65.824 11 75.526 11h21.649C113.225 11 127 23.812 127 39.913v34.265l-18.883-.003z"
                      fill="#FFCC80"
                  />
                  <Path
                      d="M70 55.19c0-5.058 4.275-9.19 9.5-9.19s9.5 4.135 9.5 9.19v22.626C89 82.872 84.725 87 79.5 87S70 82.868 70 77.816V55.19zm19 0c0-5.058 4.275-9.19 9.5-9.19s9.5 4.135 9.5 9.19v22.626c0 5.056-4.275 9.184-9.5 9.184S89 82.868 89 77.816V55.19zm19 .026c0-5.056 4.275-9.19 9.5-9.19s9.5 4.134 9.5 9.19v19.446c0 5.056-4.275 9.184-9.5 9.184s-9.5-4.131-9.5-9.184V55.216z"
                      fill="#FFCC80"
                  />
                  <Path
                      d="M54.206 121.35c.039 3.71 2.924 6.688 6.469 6.65 3.528-.042 6.366-3.088 6.325-6.787-.036-3.714-12.83-3.572-12.794.137M25.395 45.419a7.084 7.084 0 00-.557 10.07c2.662 2.929 7.217 3.166 10.176.538 2.963-2.635-6.657-13.25-9.62-10.608"
                      fill="#FFF"
                  />
              </G>
              <Path
                  d="M42.805 96.36c-34.212 22.747-38.738 37.414-13.58 44.002 37.736 9.88 93.513 9.88 100.287-3.757 4.516-9.092-11.194-15.666-47.13-19.724"
                  stroke="#F5C2D1"
                  strokeWidth={10}
                  strokeLinecap="round"
              />
          </G>
      </Svg>
  );
}

export default SvgFingerDown;
