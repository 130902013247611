import {scale,verticalScale, ScaledSheet} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import {getIsRTLStyle, horizontalRTLFlip, isTextRTLStyles} from '../../styles/Layout';
import {globalStyles} from "../../styles/Global";

export default ScaledSheet.create({

    indentation: {
        // paddingLeft: '8@s',
    },
    mark: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 10,
        width: scale(3),
        height: '100%',
        borderRadius: 3,
        borderColor: '#dedfe0',
    },
    informationRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: verticalScale(5)
        // marginBottom: '4@s',
    },
    informationIcon: {
        fontSize: scale(14),
        color: Colors.grayText,
        marginRight: '5@vs',
    },
    title: {
        fontSize: scale(21),
        // fontWeight: '500',
        fontFamily: 'heebo',
        color: '#333333',
        ...isTextRTLStyles,
    },
    titleBox: {
        flexDirection: 'row',
        marginBottom: '4@s',
        width: '100%',
    },
    errorText: {
        fontSize: '12@s',
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: 0.46,
        color: '#d03c34',
        textTransform: 'uppercase',
        ...isTextRTLStyles,
    },
    informationText: {
        fontSize: scale(13),
        ...globalStyles.paddingStart,
        ...isTextRTLStyles,
    },

    moreAvatarsText: {
        fontSize: '16@s',
        fontFamily: 'heebo',
        fontStyle: 'normal',
        letterSpacing: 0.51,
        textAlign: 'center',
        color: '#0099dc',
        ...isTextRTLStyles,
    },
    buttonCancel: {
        borderColor: '#d03c34',
    },
    buttonTitle: {
        fontSize: '12@s',
        fontWeight: '500',
        letterSpacing: '0.38@s',
        color: '#58616d',
        ...isTextRTLStyles,
    },
    slotsFull: {
        color: Colors.red
    },
    indicatorWrapper: {
        position: 'absolute',
        paddingHorizontal: scale(15),
        paddingVertical: scale(3),
        right: scale(0),
        top: verticalScale(15),
        borderTopStartRadius:scale(3),
        borderBottomStartRadius:scale(3),
        alignItems: 'center',
        justifyContent: 'center',
    },
    v2IndicatorWrap: {paddingHorizontal:scale(5),borderRadius:scale(5)},
    v2IndicatorText: {...globalStyles.heeboMedium,fontSize:scale(11),color:Colors.white},
    indicator: {
        paddingTop: scale(1),
        color: '#FFFFFF',
        fontSize:scale(16)
        // ...horizontalRTLFlip,
    },
    modalContent: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

});
