// @flow
import * as React from 'react';
import PopupModal from "../../Common/PopupModal";
import {Platform, TouchableOpacity, View} from "react-native";
import {AppText} from "../../../Stateless/AppText";
import {useEffect, useState} from "react";
import {
    calcCartItems, getCartSum, isMobile,
    IsraelPaymentSystems,
    isServiceCart, objHasAllProperties, sendMessageToSentryBrowser,
    SMALL_ICON_SIZE
} from "../../../../helpers/functions";
import {useDispatch, useSelector} from "react-redux";
import {apiAction} from "../../../../helpers/HTTP";
import Spinner from "react-native-loading-spinner-overlay";
import {Colors} from "../../../../styles/Colors";
import {ErrorHandler} from "../../../ErrorHandler";
import {globalStyles} from "../../../../styles/Global";
import {t} from "../../../../services/i18n";
import {PaymentMethodCard} from "../../../Stateless/PaymentMethodCard";
import {SeparatorWithText} from "../../../Stateless/SeparatorWithText";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import EStyleSheet from "react-native-extended-stylesheet";
import AddPaymentMethod from "../../../PaymentProcess/AddPaymentMethod";
import ModalButton from "../../../ModalButton";
import omit from "lodash/omit";
import AdditionalPaymentDetailsForm from "../../../Shopping/AdditionalPaymentDetailsForm";
import uuid from "uuid";
import CvvAndInstallments from "./CvvAndInstallments";

const PaymentHandlerContainer = (props) => {
    const {isPopup, setClose, show, onProcessSuccess, signUpDetails, handleCreateUser, contentOverrideStyle, onBack, finishProcessBeforeToken, groupInfo} = props;

    const renderPaymentParentContainer = () => {
        return isPopup ?
            <PopupModal show={show} setClose={setClose} overrideStyles={{paddingHorizontal: 0, paddingVertical: 0, width: '28em'}}>
                <PaymentHandler onProcessSuccess={onProcessSuccess} signUpDetails={signUpDetails} handleCreateUser={handleCreateUser} contentOverrideStyle={contentOverrideStyle} onBack={onBack} finishProcessBeforeToken={finishProcessBeforeToken} groupInfo={groupInfo}/>
            </PopupModal>
            :
            <View style={[isMobile() && {height: 'inherit'}]}>
                <PaymentHandler onProcessSuccess={onProcessSuccess} signUpDetails={signUpDetails} handleCreateUser={handleCreateUser} contentOverrideStyle={contentOverrideStyle} onBack={onBack} finishProcessBeforeToken={finishProcessBeforeToken} groupInfo={groupInfo}/>
            </View>
    }

    return (
        <>
            {renderPaymentParentContainer()}
        </>
    );
};

export default PaymentHandlerContainer;


const PaymentHandler = (props) => {
    const {onProcessSuccess, signUpDetails, handleCreateUser, contentOverrideStyle, onBack, finishProcessBeforeToken, groupInfo} = props;
    const [stepComp, setStepComp] = useState(null);
    const [isService, setIsService] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [totalPayment, setTotalPayment] = useState({total_price: 0});
    const [totalPaymentRecurring, setTotalPaymentRecurring] = useState({total_price: 0});
    const [userToken, setUserToken] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [paymentService, setPaymentService] = useState(null);
    const [paymentServiceName, setPaymentServiceName] = useState(null);
    const [maxInstallments, setMaxInstallments] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [firstTimeRegisterFlag, setFirstTimeRegisterFlag] = useState(false);
    const [tokenInfo, setTokenInfo] = useState(null);
    const cart = useSelector(state => state.shop.cart)
    const activePromoCode = useSelector(state => state.shop.activePromoCode)
    const user = useSelector(state => state.user.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const whiteLabelProperties = useSelector(state => state.whiteLabelProperties)
    const loggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const mobile = isMobile();

    useEffect(() => {
        if(cart.length > 0) {
            setIsService(isServiceCart(cart));
            setupChoosePaymentMethodStep();
        }
    }, [cart]);

    useEffect(() => {
        if(isReady) {
            if(getCartSum(cart) === 0) {
                purchase();
            } else {
                setStepComp(renderStep())
                if (signUpDetails && selectedPayment && firstTimeRegisterFlag) {
                    purchase()
                    setFirstTimeRegisterFlag(false)
                }
            }
        }
    }, [isReady,step, selectedPayment]);


    const setupChoosePaymentMethodStep = async (external = null) => {
        setIsLoading(true)
        let userBoxPaymentsAndTokens

        if(external) {
            userBoxPaymentsAndTokens = external
        } else {
            if(signUpDetails && !loggedIn) {
                userBoxPaymentsAndTokens = await fetchPaymentServiceMockup()
            } else {
                userBoxPaymentsAndTokens = await fetchPaymentServiceData()
            }
        }
        const boxPayment = userBoxPaymentsAndTokens['box_payment_service'].find(item => item.payment_service !== 'CASH');
        const total_payment = calcCartItems(cart, false);
        const total_payment_recurring = calcCartItems(cart, true);
        const maxInstallments = Math.max.apply(Math, cart.map(item => item.hasOwnProperty('max_installments') ? item.max_installments : 1));
        setTotalPayment(total_payment);
        setTotalPaymentRecurring(total_payment_recurring);
        setUserToken(userBoxPaymentsAndTokens['user_box_token'])
        setSelectedPayment(userBoxPaymentsAndTokens['user_box_token'].length > 0 ? userBoxPaymentsAndTokens['user_box_token'][0] : null)
        setPaymentService(userBoxPaymentsAndTokens['box_payment_service'])
        setPaymentServiceName(boxPayment.payment_service)
        setMaxInstallments(maxInstallments);
        setIsLoading(false)
        setStep(1);
        setIsReady(true)
    }

    const renderStep = () => {
        if (isLoading)
            return <Spinner visible={isLoading}/>;
        switch (step) {
            case 1:
                return choosePaymentMethodStep();
            case 'addPayment':
                return addPayment();
            case 'addCVV':
                return addCvvStep();
            default:
                break;
        }
    }

    const fetchPaymentServiceData = async () => {
        try {
            const itemLocation = cart[0].location_box_fk
            return await apiAction(`shop/userBoxPaymentsAndTokens/${user.id}/${itemLocation}`, 'get', null);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchPaymentServiceMockup = async () => {
        try {
            const itemLocation = cart[0].location_box_fk
            return await apiAction(`shop/BoxPayments/${itemLocation}`, 'get', null);
        } catch (e) {
            console.log(e);
        }
    }

    const changeSelectedCard = (item) => {
        if(selectedPayment !== item) {
            setSelectedPayment(item)
        }
    }

    const choosePaymentMethodStep = () => {
        return (
            <View style={[mobile && {justifyContent: 'space-between', height: '100%'}]}>
                {errors ? <ErrorHandler errors={errors}/> : null}
                <View>
                    {userToken?.map((item, i) => <PaymentMethodCard userBoxToken={item} key={item.id} selected={selectedPayment} onPress={changeSelectedCard}/>)}
                    {userToken?.length > 0 ?
                        <SeparatorWithText text={t('screens:Purchase:or', {})} color={Colors.separatorCircleGray}
                                           textStyle={[{fontSize: '0.8rem'}]}/> : null}
                    <TouchableOpacity onPress={() => setStep('addPayment')}
                                      style={[globalStyles.simpleCardContainer, globalStyles.flexDirectionRow, styles.addPaymentMethodBtn]}>
                        <Icons8Generator name={'plus'} size={SMALL_ICON_SIZE} fill={Colors.black}/>
                        <AppText style={[styles.addCardBtn]}>{t('screens:Purchase:addPaymentMethod', {})}</AppText>
                    </TouchableOpacity>
                </View>
                <View style={{flexDirection: 'row', width: '100%', gap: '1em', justifyContent: 'space-between'}}>
                    {onBack && <ModalButton type={'primary'}
                             overrideStyle={[styles.nextBtn, mobile && {flex: 1}]}
                             colors={Colors.veryLightGrey}
                             text={t('common:back', {})}
                             textStyles={{color: Colors.grayHeader, fontSize: '0.9rem'}}
                             regular={true}
                             onPress={onBack}
                    />}
                    <ModalButton type={'primary'}
                                 overrideStyle={[styles.nextBtn, mobile && {flex: 1}]}
                                 disabled={!selectedPayment}
                                 colors={!selectedPayment ? Colors.brandedGraySelected : whiteLabelProperties.primaryColor}
                                 text={t('common:next', {})}
                                 textStyles={{color: Colors.white, fontSize: '0.9rem'}}
                                 regular={true}
                                 onPress={() => {
                                     IsraelPaymentSystems.includes(paymentServiceName.toLowerCase()) || maxInstallments > 1 ? handleCVV() : purchase()
                                 }}
                    />
                </View>
            </View>
        )
    }

    const addPayment = () => {
        return (
            <AddPaymentMethod
                boxPaymentService={paymentService}
                paymentServiceName={paymentServiceName}
                handlePrev={() => setStep(1)}
                createTokenSuccess={setupChoosePaymentMethodStep}
                mobile={mobile}
                signUpFunc={signUpDetails ? getUsersInstallments : null}
            />
        )
    }

    const getUsersInstallments = (values) => {
        setTokenInfo(values)
        setStep('addCVV')
    }

    const createUserSignUp = async (values) => {
        if(finishProcessBeforeToken) { // for full registration - full process POST
            const paymentParams = getPurchaseParams(values)
            callCreateUser(values, paymentParams.payment_object)
        } else { // for schedule (short) registration - "create user and create token" POST and then "insert" POST
            setFirstTimeRegisterFlag(true)
            const response = await handleCreateUser(values)
            await setupChoosePaymentMethodStep(response.userBoxToken)
        }
    }

    const callCreateUser = (values, paymentObject) => {
        if(getCartSum(cart) === 0) {
            handleCreateUser(values, paymentObject)
        } else {
            if(paymentObject.user_box_token.id) values = {} // I lost track of what is going on... this is for the case of group register when parent is logged in and chooses an exising credit card- pass empty token info so the server doesn't create a new token
            handleCreateUser(values, paymentObject)
        }
    }

    const addCvvStep = () => {
        return <CvvAndInstallments maxInstallments={maxInstallments}
                                   onNext={onCVVCallback}/>
    }


    const onCVVCallback = (values) => {
        if (values.installments > maxInstallments) {
            values.installments = maxInstallments
        }
        if(signUpDetails) {
            values = {...tokenInfo, ...values, installments: values.installments}
            createUserSignUp(values)
        } else {
            values = {...values, installments: values.installments}
            purchase(values);
        }
    }

    const handleCVV = () => {
        if (totalPayment.total_price - totalPaymentRecurring.total_price === 0) {
            purchase(); // Meaning recurring payment!
        } else {
            setStep('addCVV');
        }
    }

    const getPurchaseParams = (values) => {
        const items = cart.map((membership) => {
            return omit(membership, 'limitations');
        });
        let params = {
            payment_object: {
                payment_details: {
                    token: true,
                    paid: false,
                    tax_free_price: totalPayment.sub_total - totalPaymentRecurring.sub_total,
                    transaction_amount: totalPayment.total_price - totalPaymentRecurring.total_price,
                    ...values,
                    client_payment_numbers: {
                        total_payment: totalPayment,
                        total_payment_recurring: totalPaymentRecurring,
                    },
                    promo_code_id: activePromoCode ? activePromoCode.promoCode.id : null
                },
                user_box_token: {
                    id: selectedPayment ? selectedPayment['id'] : null
                }
            },
            cart: items,
            boxes_id: selectedPayment ? selectedPayment['box_fk'] : location.box_fk
        };
        if(isService) {
            params.payment_object.payLater = false;
        }
        if(getCartSum(cart) === 0) {
            params.payment_object.payLater = true;
        }
        if(groupInfo) {
            params.group_info = { child_id: groupInfo.child_id}
        }
        return params;
    }

    const purchase = async (values = {}) => {
        try {
            setIsLoading(true)
            let params = getPurchaseParams(values)
            if(isService){
                onProcessSuccess(params)
            } else if(signUpDetails) {
                createUserSignUp(values)
            } else {
                const messageId = uuid.v4()
                sendMessageToSentryBrowser("in-membershipPurchase-message", JSON.stringify(params), messageId)
                const response = await apiAction('shop/membershipPurchase', 'post', params);
                sendMessageToSentryBrowser("in-membershipPurchase-message-response", JSON.stringify(response), messageId)
                if (response.hasOwnProperty('data') && response.data) {
                    if (response.statusCode !== 200 || (objHasAllProperties(response.data, ['errorMessages', 'isError']) && response.data.isError !== false && response.data.errorMessages.length > 0)) {
                        setIsLoading(false)
                        setErrors(response.data.errorMessages ? response.data.errorMessages : [response.error.messageToUser])
                    } else {
                        setIsLoading(false)
                        setErrors(null)
                        onProcessSuccess()
                    }
                } else {
                    setIsLoading(false)
                    setErrors([t('common:unknownFailure', {})])
                }
            }

        } catch (e) {
            const message = e.hasOwnProperty('error') ? e.error.messageToUser || e.error.message : t('common:unknownFailure', {});
            setIsLoading(false)
            setErrors([message])
        }
    }

    return (
        <>
            {errors ? <ErrorHandler errors={errors}/> : null}
            <Spinner visible={isLoading}/>
            <View style={[styles.stepWrapper,
                {paddingHorizontal: signUpDetails ? 0 : mobile ? '1.5em' : '3em'},
                mobile && {height: `100%`},
                (!mobile && step == 'addPayment') && {height:  IsraelPaymentSystems.includes(paymentServiceName.toLowerCase()) ? '35em' : '31em'},
                contentOverrideStyle
            ]}>
                {stepComp}
            </View>
        </>
    );
};

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            header: {
                fontSize: '0.9rem'
            },
            addCardBtn: {
                fontSize: '0.85rem',
                color: Colors.black,
            },
            processStepsContainer: {
                flexDirection: 'row',
                borderBottomWidth: 1,
                borderBottomColor: Colors.pastDateBg,
                width: '100%',
                paddingVertical: '2em',
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5em',
            },
            singleStepWrapper: {
                gap: '0.5em',
                alignItems: 'center'
            },
            iconWrapper: {
                backgroundColor: Colors.brandedGreen,
                borderRadius: '50%',
                height: '2.3em',
                width: '2.3em',
                justifyContent: 'center',
                alignItems: 'center'
            },
            stepText: {
                fontSize: '0.9rem'
            },
            stepWrapper: {
                paddingVertical: '1em',
            },
            addPaymentMethodBtn : {
                justifyContent: 'center',
                minHeight: '0.5em',
                alignItems: 'center',
                paddingVertical: '1em',
                borderWidth: 1,
                borderColor: Colors.graySeparator,
                gap: '0.5em'
            },
            nextBtn: {
                minHeight: '1em',
                paddingVertical: '0.4em',
                width: '7em',
                borderRadius: 2,
                alignSelf: 'end',
                marginTop: '1em'
            }
        }
    })
})