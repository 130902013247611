import React from 'react';
import {connect} from "react-redux";
import {View, ScrollView, TouchableOpacity, Text} from 'react-native';
import {bindActionCreators} from "redux";
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {PurchaseEndCard} from "../../components/Stateless/MembershipDisplayer";
import {MEMBERSHIP_TYPES_CONST} from "../../constants/membershipTypes";
import {verticalScale, scale} from 'react-native-size-matters';
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import {t} from "../../services/i18n";
import ModalButton from "../../components/ModalButton";
import PriceSummary from "../../components/Shopping/PriceSummary";
import uuid from 'uuid';
import {AppText} from "../../components/Stateless/AppText";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {IconSize} from "../../constants/iconSize";
import {GraySeparator} from "../../components/Stateless/GraySeparator";
import {emptyCart} from "../../actions/shop";
import {fetchProfile} from '../../actions/user';
import {fetchFeedData} from '../../actions/home';
import {captureRef} from "react-native-view-shot";
import {openShareDialogAsync} from "../../helpers/share";
import {LARGE_ICON_SIZE} from "../../helpers/functions";


class PurchaseEnd extends React.PureComponent {


    constructor(props) {
        super(props);
        this.backToFeed = this.backToFeed.bind(this);
        this.renderCapture = this.renderCapture.bind(this);
        this.state = {hidePriceOnShare: false};
    }

    componentWillUnmount() {
        this.props.emptyCart();
    }

    backToFeed() {
        this.props.emptyCart();
        this.props.fetchFeedData();
        this.props.fetchProfile();
        this.props.navigation.navigate("Home");
    }

    async captureRefAndShare() {
        this.setState({hidePriceOnShare: true}, () => {
            this.renderCapture();
        });
    };

    renderCapture = async () => {
        setTimeout(async () => {
            const imageUri = await captureRef(this['screenCaptured']);
            await openShareDialogAsync('file://' + imageUri);
            this.setState(() => ({hidePriceOnShare: false}));
        }, 100);
    };

    renderShareCardButton = () => (
        <TouchableOpacity style={[globalStyles.stickyButtonsMarginBottom, globalStyles.avatarsRow, globalStyles.centerItems]} onPress={() => this.captureRefAndShare()}>
            <Text style={[{color: Colors.brandedBlue}, globalStyles.marginByRTL]} >{t("common:share")}</Text>
            <Icons8Generator name="forward-arrow" fill={Colors.brandedBlue} width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />
        </TouchableOpacity>
    );

    render() {
        return (
            <React.Fragment>
                <StatusBarWrap barStyle={'dark-content'} backgroundColor={Colors.screensGrayBackground}/>
                <View style={[globalStyles.flex, globalStyles.appMinimalPaddingHorizontal, {
                    backgroundColor: Colors.screensGrayBackground,
                }]}>
                    <TouchableOpacity onPress={this.backToFeed}
                                      style={{justifyContent: 'center', height: verticalScale(45)}}>
                        <Icons8Generator size={IconSize.L} fill={Colors.iconEmptyStateColor} name={'close'}/>
                    </TouchableOpacity>
                    <ScrollView
                        ref={(view) => {this['screenCaptured'] = view}}
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[{flexGrow: 1, paddingTop: verticalScale(10)}]}>
                            <View style={{flex: 1, justifyContent: 'center'}}>
                                <AppText style={[
                                    globalStyles.centerItems,
                                    globalStyles.textCenter,
                                    globalStyles.heeboMedium,
                                    {fontSize: scale(18)}]}>{t('screens:PurchaseEnd:header', {})}</AppText>
                                <View style={[{
                                    alignSelf: 'center',
                                    marginTop: verticalScale(20),
                                    marginBottom: verticalScale(5)
                                }]}>
                                    <View style={{position: 'absolute', end: 0, top: 0}}>
                                        <Icons8Generator name={'check-circle'} size={IconSize.M} fill={Colors.green}/>
                                    </View>
                                    <Icons8Generator name={'shopaholic'} fill={this.props.whiteLabelProperties.primaryColor}
                                                     size={IconSize.SPECIAL_SIZE_100}/>
                                </View>
                            {this.screen()}
                        </View>
                    </ScrollView>
                    {this.props.cart.length > 0 ? <PriceSummary/> : null}
                    {this.actionButtons()}
                </View>
            </React.Fragment>
        );
    }

    screen() {
        const {cart} = this.props;

        return (
            <View style={{flex: 1, justifyContent: 'flex-end'}}>
                <GraySeparator height={1}/>
                {cart.map(membership => {
                    const type = membership.type;
                    const color = type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? Colors.brandedGreen : Colors.brandedBlue;
                    const icon = type === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? 'membership-card' : 'two-tickets';
                    return (<PurchaseEndCard
                        key={uuid.v4()}
                        color={color}
                        icon={icon}
                        type={type}
                        membership={membership}
                        hidePrice={this.state.hidePriceOnShare}
                    />)
                })}
            </View>

        )


    }

    actionButtons() {
        return (
            <React.Fragment>
                <View style={[globalStyles.appPaddingHorizontal, {marginBottom: verticalScale(25)}]}>
                    <ModalButton
                        onPress={this.backToFeed}
                        type='primary'
                        colors={this.props.whiteLabelProperties.primaryColor}
                        text={t('screens:PurchaseEnd:toFeed', {})}
                        overrideStyle={{width: '90%', minHeight: verticalScale(30), marginTop: verticalScale(10)}}
                        textColor={Colors.white}
                    />
                </View>
                {this.renderShareCardButton()}
            </React.Fragment>
        )
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators({
        emptyCart,
        fetchFeedData,
        fetchProfile,
    }, dispatch);
};


const mapStateToProps = state => ({
    cart: state.shop.cart,
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(PurchaseEnd);
