import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import {View, ImageBackground, Image, Platform} from 'react-native';
import { AppText } from "../../components/Stateless/AppText";
import { t } from "../../services/i18n";
import { Colors } from "../../styles/Colors";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import Logo from "../../svg/custom/LogoBlack"
import ModalButton from "../../components/ModalButton";
import BgImg from "../../images/GenericsSiteBg.png"
import {isMobile} from "../../helpers/functions";
import EStyleSheet from "react-native-extended-stylesheet";
import {Link} from "react-router-dom";
import PluginFooter from "../../components/Web/PluginFooter";

const WelcomeScreen = props => {

    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const mobile = isMobile()

    return (
        <>
            <ImageBackground source={mobile ? '' : BgImg} style={styles.bgImage} imageStyle={{opacity:0.5}}>
                <View style={[styles.container, mobile ? styles.containerMobile : null]}
                      showsVerticalScrollIndicator={false}
                      contentContainerStyle={[{ flexGrow: 1 }]}>
                    <View style={[styles.content, mobile && styles.contentMobile]}>
                        <AppText style={[styles.boxName, !box?.cloudinary_image && {marginBottom: '2em'}]}>{box?.name}</AppText>
                        {box && box?.cloudinary_image && <Image style={[styles.logo, mobile ? styles.logoMobile : null]} source={box?.cloudinary_image}/>}
                        <View style={styles.buttonsWrapper}>
                            <Link to={{pathname: '/schedule', search:boxQueryString || location.search}} style={{ textDecoration: 'none' }}>
                                <View style={[styles.button, mobile ? styles.buttonMobile : null, styles.primaryBtn, {backgroundColor: Colors.lightBlack}]}><AppText style={[styles.btnText, {color: Colors.white}]}>{t('headings:book-now', {})}</AppText></View>
                            </Link>
                            <Link to={{pathname: '/shop', search:boxQueryString || location.search}} style={{ textDecoration: 'none'}}>
                                <View style={[styles.button, mobile ? styles.buttonMobile : null, styles.secondaryBtn]}><AppText style={[styles.btnText, {color: Colors.lightBlack}]}>{t('headings:start-shopping', {})}</AppText></View>
                            </Link>
                        </View>
                    </View>
                </View>
            </ImageBackground>
            <PluginFooter />
        </>
    );
};

export default WelcomeScreen;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            container: {
                flex: 1,
                alignItems: 'flex-end',
                // paddingHorizontal: '10%',
                height: () => `100% - ${EStyleSheet.value('$footerMaxHeight') + EStyleSheet.value('$mobileHeader')}`,
                justifyContent: 'center',

            },
            containerMobile: {
                alignItems: 'center',
            },
            boxName: {
                fontFamily:'heebo',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                marginBottom: '1em',
                letterSpacing: 0.7
            },
            logo: {
                width: '15em',
                height: '15em',
                marginBottom: '1em',
                borderRadius: 5
            },
            logoMobile: {
                width: scale(180),
                height: scale(180),
                marginBottom: scale(40),
            },
            button: {
                width: '20em',
                borderRadius: 3,
                marginTop: 0,
                minHeight: '3.5em',
                height: '3.5em',
                letterSpacing: 0.7,
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                fontFamily:'heebo',
                fontSize: '1.2rem',
            },
            buttonMobile: {
                width: scale(270),
                height: scale(50)
            },
            buttonsWrapper: {
                gap: '1em'
            },
            content: {
                alignItems: 'center',
                backgroundColor: Colors.siteBgLightWhiteRGBA,
                height: '100%',
                justifyContent: 'center',
                padding: '5%',
                overflow: 'auto'
            },
            contentMobile: {
                width: '100%',
                height: () => `100% - ${EStyleSheet.value('$footerMaxHeight') + EStyleSheet.value('$mobileHeader')}`,
            },
            bgImage: {
                flex: 1,
                // justifyContent: "center",
            },
            primaryBtn: {
                color: Colors.white,
                // borderColor: Colors.lightBlack,
                // borderWidth: 1
            },
            secondaryBtn: {
                color: Colors.lightBlack,
                backgroundColor: Colors.white,
                borderColor: Colors.lightBlack,
                borderWidth: 1
            },
            btnText: {
                fontFamily:'heebo',
                fontSize: '1.2rem',
                fontWeight: 'bold'
            }
        }
    })
})
