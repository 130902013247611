import React from 'react';
import {View} from 'react-native';
import {ScaledSheet, verticalScale} from "react-native-size-matters";
import {isWeb} from "../../../helpers/functions";


export const InputWrapWithBorders = props => {
    const isSite = isWeb()
    return (
        <View style={[styles.container, isSite && {paddingVertical: '1em'}]}>
            {props.children}
        </View>
    )
};


const styles = ScaledSheet.create({
    container: {
        paddingVertical: verticalScale(20),
        borderTopWidth: 0.5,
        borderBottomWidth: 0.5,
        borderColor: 'rgba(255,255,255,0.3)',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
    }
});




