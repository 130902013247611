import React, {useEffect, useState} from 'react';
import {Linking, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import moment from "moment/moment";
import {fetchCurrencySymbol, addToCart, emptyCart, setShopLocationsBox} from "../../../actions/shop";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../styles/Colors";
import ModalButton from "../../ModalButton";
import {isAMPMtimeFormat, isMobile, objHasAllProperties} from "../../../helpers/functions";
import uuid from "uuid";
import {useNavigate} from "react-router-dom";
import LoginScreen from "../../../screens/Web/LoginScreen";
import GenericSuccessModal from "../Common/GenericSuccessModal";
import ConfirmModal from "../Common/ConfirmModal";
import {apiAction} from "../../../helpers/HTTP";
import {ErrorHandler} from "../../ErrorHandler";
import Spinner from "react-native-loading-spinner-overlay";

export const SingleWorkshopHeader = (props) => {
    const {workshop, bottomSeparator, onViewMore, showViewMore, onSuccess} = props;
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const user = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.data)
    const [weekDays, setWeekDays] = useState(null);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [workshopService, setWorkshopService] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const dispatch = useDispatch();
    const mobile = isMobile();
    const navigate = useNavigate()

    useEffect(() => {
        let week = [false, false, false, false, false, false, false]
        workshop.series_events.map(dayEvent => {
            week[dayEvent.day_of_week] = true;
        })
        setWeekDays(week);
    }, [workshop]);

    useEffect(() => {
        if(!currencySymbol && location) {
            dispatch(fetchCurrencySymbol(`shop/currencySymbol/${location.id}`));
        }
    }, [currencySymbol, location]);

    useEffect(() => {
        if(workshopService) {
            if (location.hasPayments) {
                handlePayForWorkshop()
            } else {
                if (isLoggedIn) {
                    // show register to workshop with debt popup
                    setShowConfirm(true)
                } else {
                    setShowLoginPopup(true)
                }
            }
        }
    }, [workshopService]);

    const bookToWorkshopClicked = () => {
        let workshopServiceTemp = workshop.box_categories.membership_types.find(membership => membership.location_box_fk === location.id)
        addPropsToWorkshopMembership({...workshopServiceTemp}) // must be a copy!
    }

    const addPropsToWorkshopMembership = (workshopServiceTemp) => {
        workshopServiceTemp.quantity = 0;
        workshopServiceTemp.start = workshop.start_date;
        workshopServiceTemp.end = workshop.end_date;
        workshopServiceTemp.series_id = workshop.id;
        workshopServiceTemp.register_group_member = !!workshop.register_group_member
        setWorkshopService(workshopServiceTemp);
    }

    const handlePayForWorkshop = async () => {
        await dispatch(emptyCart());
        await dispatch(setShopLocationsBox({locationsBoxId: workshop.locations_box_fk, boxesId: workshop.box_fk}));
        await dispatch(addToCart(workshopService))
        navigate({
            pathname: '/cart',
            search: boxQueryString,
        });
    }

    const registerWithDebt = async () => {
        let params = {
            users_id: user.id,
            boxes_id: location.box_fk,
            cart: [workshopService],
            payment_object: {
                payment_details: {},
                payLater: true,
            }
        };
        try {
            setShowConfirm(false)
            setIsLoading(true)
            const response = await apiAction('shop/membershipPurchase', 'post', params);
            setIsLoading(false)
            if (response.hasOwnProperty('data')) {
                if (response.statusCode !== 200 || (objHasAllProperties(response.data, ['errorMessages', 'isError']) && response.data.isError !== false && response.data.errorMessages.length > 0)) {
                    setErrors(response.data.errorMessages ? response.data.errorMessages : [response.error.messageToUser])
                } else {
                    setErrors(null)
                    onSuccess(workshop)
                }
            } else {
                setIsLoading(false)
                setErrors([t('common:unknownFailure', {})])
            }
        } catch(error) {
            const message = error.hasOwnProperty('error') ? error.error.messageToUser || error.error.message : t('common:unknownFailure', {});
            setIsLoading(false)
            setErrors([message])
        }

    }

    return (
        <>
            <View style={[mobile ? headerStyles.mobileWrapper : headerStyles.wrapper, headerStyles.extraStyles, mobile && {height: 'auto'}, bottomSeparator && showViewMore && headerStyles.bottomSeparator]}>
                <View style={[headerStyles.section]}>
                    <AppText style={[headerStyles.text, headerStyles.bigText]}>{workshop.series_name}</AppText>
                </View>
                <View style={[headerStyles.section]}>
                    <AppText style={[headerStyles.bigText]}>{t('screens:workshop:guide',{})}: </AppText>
                    <AppText style={[headerStyles.regularText]}>{workshop.coach_fk ? workshop.coach.full_name: '-'}</AppText>
                </View>
                <View style={[headerStyles.section]}>
                    <AppText style={[headerStyles.regularText]}>{t('screens:workshop:date-range',{start: moment(workshop.start_date).format('ll'), end: moment(workshop.end_date).format('ll')})}</AppText>
                </View>
                <View style={[headerStyles.section]}>
                    <AppText style={[headerStyles.bigText]}>{t('screens:Purchase:price',{})}: </AppText>
                    <AppText style={[headerStyles.regularText]}>{workshop.box_categories.price !== null ? `${workshop.box_categories.price}${currencySymbol}` : '-'}</AppText>
                </View>
                <View style={[headerStyles.section, mobile ? headerStyles.weekWrapperMobile : headerStyles.weekWrapper]}>
                    {
                        weekDays && weekDays.map((value, index) => <View style={[headerStyles.weekDayCircle, mobile && !showViewMore && headerStyles.weekDayMobile, value === true && headerStyles.weekDaySelected]} key={`weekday-${workshop.id}-${index}`}><AppText style={[headerStyles.regularText, headerStyles.regularTextWeekday]}>{t(`common:dayOfWeekShort:${index}`,{})}</AppText></View>)
                    }
                </View>
                <View style={[headerStyles.section, headerStyles.actions, mobile && {marginTop: '0.5em'}]}>
                    <ModalButton
                        onPress={onViewMore}
                        text={t(!showViewMore && mobile ? 'screens:workshop:less-details' : 'screens:workshop:more-details', {})}
                        type='primary'
                        colors={Colors.white}
                        textColor={Colors.lightBlack}
                        textStyles={headerStyles.btnText}
                        overrideStyle={[headerStyles.btn, mobile && headerStyles.btnMobile, headerStyles.viewMoreBtn, !showViewMore && !mobile && headerStyles.hideShowMoreBtn]}
                    />
                    <ModalButton
                        onPress={workshop.isFull ? null : bookToWorkshopClicked}
                        text={t(workshop.isFull ? 'screens:workshop:fully-booked' : 'screens:workshop:book-now', {})}
                        type='primary'
                        colors={workshop.isFull ? Colors.fullyBooked : Colors.lightBlack}
                        textColor={workshop.isFull ? Colors.lightBlack : Colors.white}
                        textStyles={headerStyles.btnText}
                        overrideStyle={[headerStyles.btn, mobile && headerStyles.btnMobile, headerStyles.bookBtn, workshop.isFull && {cursor: 'initial'}]}
                    />
                </View>
            </View>
            {showLoginPopup && <LoginScreen show={showLoginPopup} handleSubmit={() => setShowLoginPopup(false)} setClose={() => setShowLoginPopup(false)}/>}
            {showConfirm && <ConfirmModal show={showConfirm}
                                          setClose={() => setShowConfirm(false)}
                                          handleConfirm={() => registerWithDebt()}
                                          iconName={'calendar'}
                                          color={Colors.lightBlack}
                                          confirmText={t('screens:workshop:book-workshop-debt-confirm', {})}
                                          cancelText={t('common:cancel', {})}
                                          title={t('screens:workshop:book-workshop-debt-head', {})}
                                          subHeader={t('screens:workshop:book-workshop-debt-msg', {workshop: workshop.series_name, price: workshop.box_categories.price !== null ? `${workshop.box_categories.price}${currencySymbol}` : '0'})}
            />}
            {errors ? <ErrorHandler errors={errors}/> : null}
            <Spinner visible={isLoading}/>
        </>
    );
};


export const SingleWorkshopBody = (props) => {
    const {workshop} = props;
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const isGlobal = isAMPMtimeFormat();
    const mobile = isMobile();

    return (
        <View style={[bodyStyles.wrapper, mobile && bodyStyles.wrapperMobile]}>
            {workshop.box_categories.bio !== '' &&
            <View>
                <AppText style={[bodyStyles.header]}>{t('screens:workshop:workshop-info', {})}</AppText>
                <AppText style={[bodyStyles.contentText]}>{workshop.box_categories.bio}</AppText>
            </View>
            }
            {!!workshop.register_group_member && <AppText style={[bodyStyles.contentText]} boldFont>{t('screens:workshop:group-workshop-warning',{})}</AppText>}
            <View>
                <AppText style={[bodyStyles.header, bodyStyles.separator, mobile && bodyStyles.separatorMobile]}>{t('screens:workshop:activity-days', {})}</AppText>
                <View style={[mobile ? bodyStyles.contentRowMobile : bodyStyles.contentRow]}>
                {
                    workshop.series_events.map(dayEvent => {
                        const start = moment(dayEvent.start, 'HH:mm:ss').format(isGlobal ? 'h:mm A' : 'HH:mm');
                        const end = moment(dayEvent.end, 'HH:mm:ss').format(isGlobal ? 'h:mm A' : 'HH:mm');
                        return (
                            <View style={[bodyStyles.item]} key={`body-day-desc-${uuid.v4()}`}>
                                <AppText style={[bodyStyles.itemHeader]}>{t(`common:dayOfWeek:${dayEvent.day_of_week}`, {})}:</AppText>
                                <AppText style={[bodyStyles.contentText]}>{start} - {end}</AppText>
                            </View>
                        )
                    })
                }
                </View>
            </View>
            <View>
                <AppText style={[bodyStyles.header, bodyStyles.separator, mobile && bodyStyles.separatorMobile]}>{t('screens:workshop:workshop-details', {})}</AppText>
                <View style={[mobile ? bodyStyles.contentRowMobile : bodyStyles.contentRow]}>
                    <View style={[bodyStyles.item]}>
                        <AppText style={[bodyStyles.itemHeader]}>{t('screens:workshop:location-name', {})}:</AppText>
                        <AppText style={[bodyStyles.contentText]}>{workshop.locations_box.location}</AppText>
                    </View>
                    <View style={[bodyStyles.item]}>
                        <AppText style={[bodyStyles.itemHeader]}>{t('common:forms:createToken:address', {})}:</AppText>
                        <AppText style={[bodyStyles.contentText]}>{workshop.locations_box.address}</AppText>
                    </View>
                    <View style={[bodyStyles.item]}>
                        <AppText style={[bodyStyles.itemHeader]}>{t('screens:Profile:features:phone', {})}:</AppText>
                        <TouchableOpacity onPress={() => Linking.openURL(`tel:${box?.phone}`)}><AppText style={[bodyStyles.contentText]}>{box.phone}</AppText></TouchableOpacity>
                    </View>
                    <View style={[bodyStyles.item]}>
                        <AppText style={[bodyStyles.itemHeader]}>{t('screens:workshop:payment-method', {})}:</AppText>
                        <AppText style={[bodyStyles.contentText]}>{t(`screens:workshop:${workshop.box_categories.membership_types[0].is_recurring_payment ? 'recurring-payment' : 'credit-card'}`,{num: workshop.box_categories.membership_types[0].max_installments})}</AppText>
                    </View>
                </View>
            </View>
        </View>
    );
};



const headerStyles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                alignItems: 'center',
                display: 'grid',
                gridTemplateColumns: '15% 10% 20% 5% 20% auto',
                gridColumnGap: '10px'
            },
            mobileWrapper: {
                display: 'flex',
                gap: '0.5em',
                paddingVertical: '1em'
            },
            extraStyles: {
                height: '60px',
                borderTopColor: Colors.graySeparator,
                borderTopWidth: 1
            },
            bottomSeparator: {
                borderBottomColor: Colors.graySeparator,
                borderBottomWidth: 1
            },
            text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            bigText: {
                fontSize: '0.8rem',
                fontWeight: 'bold'
            },
            regularText: {
                fontSize: '0.8rem',
            },
            section: {
                flexDirection: 'row'
            },
            btn: {
                width: '10em',
                borderRadius: 3,
                height: 30,
                minHeight: 30,
                marginTop: 0,
                justifyContent: 'center'
            },
            btnMobile: {
                width: 'unset',
                flex: 1
            },
            bookBtn: {
                zIndex: 2
            },
            btnText: {
                fontSize: '0.9rem',
                textAlign: 'center',
                color: Colors.white,
                fontFamily: 'heebo',
                fontWeight: 'bold'
            },
            viewMoreBtn: {
                borderWidth:1,
                borderColor: Colors.lightBlack
            },
            actions: {
                gap: '1em',
                placeContent: 'end'
            },
            weekDayCircle: {
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: Colors.graySeparator,
                justifyContent: 'center',
                alignItems: 'center',
            },
            weekDayMobile: {
                backgroundColor: Colors.weekDayDark,
            },
            weekDaySelected: {
                backgroundColor: Colors.weekDaySelected,
            },
            weekWrapper : {
                gap: 10
            },
            weekWrapperMobile: {
                justifyContent: 'space-between'
            },
            hideShowMoreBtn: {
                visibility: 'hidden'
            },
            regularTextWeekday: {
                fontFamily: 'heebo',
                fontWeight: 'bold'
            }
        }
    })
})

const bodyStyles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                width: '50%',
                gap: '1.5em'
            },
            wrapperMobile: {
                width: '100%',
            },
            header: {
                fontSize: '1rem',
                fontWeight: 'bold'
            },
            contentText: {
                fontSize: '0.8rem'
            },
            contentRow: {
                flexDirection: 'row',
                gap: '2em'
            },
            contentRowMobile: {
                flexDirection: 'column',
                gap: '1em'
            },
            item: {
                flexDirection: 'row',
                gap: 5
            },
            itemHeader: {
                fontSize: '0.8rem',
                fontWeight: 'bold'
            },
            separator: {
                borderBottomColor: Colors.graySeparator,
                borderBottomWidth: 1,
                paddingBottom: '0.5em',
                marginBottom: '0.5em'
            },
            separatorMobile: {
                borderBottomColor: Colors.white,
                maxWidth: '100%'
            }
        }
    })
})