import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Pause(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M12 8v34h10V8zm16 0v34h10V8zm-14 2h6v30h-6zm16 0h6v30h-6z" />
    </Svg>
  );
}

export default SvgIcons8Pause;
