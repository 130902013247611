import React from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {View} from "react-native-web";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {IconSize} from "../../../constants/iconSize";

const GenericAlert = (props) => {
    const { text, type } = props;
    const types = {
        'warning': { icon: 'warning-circle', primaryColor: Colors.alerts.warningPrimary, secondaryColor: Colors.alerts.warningSecondary},
        'error': { icon: 'error-circle', primaryColor: Colors.alerts.errorPrimary, secondaryColor: Colors.alerts.errorSecondary},
        'success': { icon: 'success-circle', primaryColor: Colors.alerts.successPrimary, secondaryColor: Colors.alerts.successSecondary},
    }
    const styles = getStyle(types[type].primaryColor, types[type].secondaryColor)
    return (
        <View style={styles.wrapper}>
            <Icons8Generator name={types[type].icon} size={IconSize.XXL} fill={types[type].primaryColor}/>
            <AppText style={styles.text}>{text}</AppText>
        </View>
    );
};

export default GenericAlert;

let getStyle = function (primaryColor, secondaryColor) {
    return EStyleSheet.create({
        ...Platform.select({
            web: {
                wrapper: {
                    padding: 10,
                    borderRadius: 6,
                    borderWidth: 1.5,
                    borderColor: primaryColor,
                    backgroundColor: secondaryColor,
                    gap: '0.7em',
                    flexDirection: 'row',
                    alignItems: 'center'
                },
                text: {
                    fontSize: '0.73em',
                }
            }
        })
    });
}