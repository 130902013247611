import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import {I18nManager as RNI18nManager} from 'react-native';
import * as config from '../../config/i18n';
import date from './date';
import plural from './plural';
import translationLoader from './translation-loader';
import calendarLocale from "../calendarLocale";
import * as Localization from 'expo-localization';
import {isWeb} from "../../helpers/functions";


export const i18n = {

    /**
     * @returns {Promise}
     */

    init:async (language) => {
       //  FileSystem.downloadAsync(
       //      'https://bitbucket.org/ArboxGit/language/raw/master/application/he.json',
       //      FileSystem.cacheDirectory + 'he.json',
       //  )
       //      .then(({ uri }) => {
       //      })
       //      .catch(error => {
       //          console.error(error);
       //      });
      // const langFile = await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'he.json');

        return new Promise((resolve, reject) => {
            i18next
                // .use(intervalPlural)
                .use(initReactI18next)
                .use(translationLoader)
                .init({
                    fallbackLng: config.fallback,
                    ns: config.namespaces,
                    defaultNS: config.defaultNamespace,
                    lng: language ? language : Localization.locale.substring(0,2),
                    interpolation: {
                        escapeValue: false,
                        format(value, format) {
                            if (value instanceof Date) {
                                return date.format(value, format);
                            }
                        }
                    },
                    react: {
                        // ...
                        hashTransKey: function(defaultValue) {
                            // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
                        },
                        defaultTransParent: 'div', // a valid react element - required before react 16
                        transEmptyNodeValue: '', // what to return for empty Trans
                        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
                        // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
                    }
                }, (error) => {
                    if (error) {
                        return reject(error);
                    }
                    plural.init(i18next).then(resolve).catch(error => reject(error));
                    calendarLocale.init(i18next.language);
                    date.init(i18next.language).then(res => console.log('res',res)).catch(error => reject(error));
                });

        });

    },



    /**
     * @param {string} key
     * @param {Object} options
     * @returns {string}
     */
    t: (key, options) => i18next.t(key, options),

    /**
     * @returns {string}
     */
    get locale() {
        return i18next.language;
    },

    /**
     * @returns {'LTR' | 'RTL'}
     */
    get dir() {
        return i18next.dir().toUpperCase();
    },

    get genderArray(){
      return [
            {
                label: t('common:female', {}),
                value: 'female',
            },
            {
                label: t('common:male', {}),
                value: 'male',
            },
        ]
    },
    /**
     * @returns {boolean}
     */
    get isRTL() {
            return RNI18nManager.isRTL;
    },



    changeLanguage(value, callback) {
        if (i18next.language !== value) {
            i18next.changeLanguage(value).then(() => {
                callback();
            });
        }
    },


    /**
     * Similar to React Native's Platform.select(),
     * i18n.select() takes a map with two keys, 'rtl'
     * and 'ltr'. It then returns the value referenced
     * by either of the keys, given the current
     * locale's direction.
     *
     * @param {Object<string,mixed>} map
     * @returns {mixed}
     */
    select(map) {
        const key = this.isRTL ? 'rtl' : 'ltr';

        return map[key];
    },


};

export const t = i18n.t;

export default i18n;
