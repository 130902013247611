import React from 'react';
import {View} from 'react-native';
import {verticalScale} from 'react-native-size-matters';
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";
import {AppText} from "../Stateless/AppText";
import {t} from "../../services/i18n";
import ModalButton from "../ModalButton";
import {apiAction} from "../../helpers/HTTP";
import Toast from "react-native-root-toast";
import {getToastDefaultConfig} from "../../helpers/functions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {removeActionFromFeeds} from "../../actions/home";
import ACTIONS from "../FeedCard/constants";
import {getThemeProperty, keys} from "../../styles/Theme";


class EpidemicForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            submitting: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async submit() {
        if (this._isMounted) {
            this.setState({submitting: true});
            try {
                const res = await apiAction(`user/epidemicForm`, 'post', {});
                this.setState({submitting: false});
                const options = getToastDefaultConfig();
                Toast.show(t(t('screens:Home:cards:epidemic-card:drawer:toast-message', {}), {}), {
                    backgroundColor: Colors.brandSuccess,
                    ...options
                });
                this.props.removeActionFromFeeds(ACTIONS.ACTION_EPIDEMIC_STATEMENT);
                this.props.closeDrawer();

            } catch (e) {
                this.setState({submitting: false});
            }
        }
    }

    render() {
        return (
            <View style={[globalStyles.marginTopBetweenElements]}>

                <AppText style={[globalStyles.heeboMedium,globalStyles.marginTopSm]}>{t('screens:Home:cards:epidemic-card:drawer:text-header', {})}</AppText>
                <AppText style={[globalStyles.heeboRegular, globalStyles.marginTopSm]}>{t('screens:Home:cards:epidemic-card:drawer:text1', {})}</AppText>
                <AppText style={[globalStyles.heeboRegular, globalStyles.marginTopSm]}>{t('screens:Home:cards:epidemic-card:drawer:text2', {})}</AppText>
                <AppText style={[globalStyles.heeboMedium,globalStyles.bold, globalStyles.marginTopBetweenTextElements]}>{t('screens:Home:cards:epidemic-card:drawer:text-bottom', {})}</AppText>


                <ModalButton
                    onPress={() => this.submit()}
                    type='primary'
                    colors={this.props.whiteLabelProperties.primaryColor}
                    text={t('screens:Home:cards:epidemic-card:drawer:approve-button', {})}
                    textColor={Colors.white}
                    submitting={this.state.submitting}
                />
                <ModalButton
                    onPress={() => this.props.closeDrawer()}
                    type='secondary'
                    overrideStyle={{marginTop: verticalScale(10)}}
                    colors={Colors.transparent}
                    text={t('screens:Home:cards:epidemic-card:drawer:not-approve-button', {})}
                    textColor={this.props.whiteLabelProperties.primaryColor}
                />
            </View>
        )
    }
}


const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            removeActionFromFeeds
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EpidemicForm);



