// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAppointmentTypes, fetchAvailabilitiesByDate} from "../../../actions/schedule";
import {globalSiteStyles, globalStyles} from "../../../styles/Global";
import {scale, verticalScale} from "react-native-size-matters";
import {Colors} from "../../../styles/Colors";
import {fetchCurrencySymbol} from "../../../actions/shop";
import {ActivityIndicator, ScrollView} from "react-native-web";
import {apiAction} from "../../../helpers/HTTP";
import moment from "moment";
import {AvailabilityCard} from "../../Availability/Card";
import {handleShareOrCopy, isSiteHebrew} from "../../../helpers/ComponentFunctionality";
import CalendarStrip from "react-native-calendar-strip";
import {isLocationIL, isMobile, LARGE_ICON_SIZE} from "../../../helpers/functions";
import DesktopChooseSlot from "./DesktopChooseSlot";
import Empty from "../../../svg/Icons8SadGhost";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {updateSiteSelectedLocation} from "../../../actions/minisite";
import SvgFullCalendar from "../../../svg/custom/FullCalendar";
import "moment/locale/he"
import {COACH_AVAILABILITY_TYPE, SPACE_AVAILABILITY_TYPE} from "../../../constants";

const ChooseSlot = (props) => {
    const {service, slotSelected, onRegisterSuccess, urlParamCategory, availabilityType} = props;
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const queryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const today = moment();
    let start = moment().add(0, 'weeks').startOf(isLocationIL() ? 'week' : 'isoWeek');
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(today);
    const [slots, setSlots] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [coachCount, setCoachCount] = useState(null);
    const [spaceCount, setSpaceCount] = useState(null);
    const [startDate, setStartDate] = useState(start);
    const dispatch = useDispatch();
    const mobile = isMobile()
    const baseLink = `${window.location.origin}${window.location.pathname}${queryString}&scheduleType=appointment&categoryId=${service?.id}`
    const [shareLink, setShareLink] = useState(baseLink);
    const [urlParamsObj, setUrlParamsObj] = useState(null);

    useEffect(() => {
        if(urlParamCategory) {
            if(urlParamCategory?.locationId) {
                const newLocation = box.locations_box.find(item => item.id == urlParamCategory?.locationId)
                dispatch(updateSiteSelectedLocation(newLocation))
            }
        }
    }, []);


    useEffect(() => {
        if(box && location) {
            fetchSlots(urlParamCategory?.selectedDate || null)
        }
    }, [box, location]);

    const fetchSlots = async (selectedDate= null) => {
        setLoading(true);
        const params = {
            locations_box_id: location?.id,
            box_categories_id: service?.id,
            isCoachAvailability: availabilityType === COACH_AVAILABILITY_TYPE
        }
        if(!selectedDate) {
            if(location?.id && service?.id) {
                selectedDate = await apiAction('availabilities/getClosestAvailability', 'post', params);
            }
        }
        if(selectedDate) {
            updateURLDeepLink({selectedDate: selectedDate instanceof moment ? selectedDate.format('YYYY-MM-DD') : selectedDate, locationId: location?.id, availabilityType: availabilityType})
            setLoading(false);
            const resDate = moment(selectedDate, 'YYYY-MM-DD')
            setDate(resDate);
            let start = resDate.clone().add(0, 'weeks').startOf(isLocationIL() ? 'week' : 'isoWeek');
            setStartDate(start);
            const slotsRes = await dispatch(fetchAvailabilitiesByDate(selectedDate, location?.id, service?.id, availabilityType === COACH_AVAILABILITY_TYPE));
            if(availabilityType === COACH_AVAILABILITY_TYPE) {
                const coachCountRes = await apiAction(`boxes/coachCount/${box.id}`, 'get', null);
                setCoachCount(coachCountRes)
            } else {
                setSpaceCount(location.spaces.length)
            }
            if(slotsRes.length) {
                setSlots(slotsRes);
                setIsEmpty(false);
            } else {
                setSlots([]);
                setIsEmpty(true);
            }

        } else {
            setIsEmpty(true);
        }
    }

    const desktopDateChanged = (newValue) => {
        fetchSlots(newValue)
    }

    const updateURLDeepLink = (params={}) => {
        setUrlParamsObj({...urlParamsObj, ...params})
        const queryString = new URLSearchParams({...urlParamsObj, ...params}).toString();
        setShareLink(`${baseLink}&${queryString}`)
    }

    return (
        <>
            {mobile ?
                <View style={styles.wrapper}>
                    {date && <CalendarStrip
                        // calendarAnimation={{type: 'sequence', duration: 30}}
                        daySelectionAnimation={{
                            type: 'border',
                            duration: 200,
                            borderWidth: 1,
                            borderHighlightColor: 'white'
                        }}
                        style={{height: EStyleSheet.value('$calendarStripHeight'), padding: 10, backgroundColor: Colors.iconBorder}}
                        calendarHeaderStyle={styles.calendarHeader}
                        dateNumberStyle={styles.dateNum}
                        dateNameStyle={{fontSize: '0.6rem'}}
                        calendarHeaderFormat='MMM YYYY'
                        highlightDateContainerStyle={{borderWidth: 1, borderColor: Colors.black}}
                        highlightDateNameStyle={{color: Colors.lightBlack, fontSize: '0.5rem'}}
                        highlightDateNumberStyle={{color: Colors.lightBlack}}
                        selectedDate={date}
                        startingDate={startDate}
                        onDateSelected={(selectedDate) => fetchSlots(selectedDate)}
                        iconLeftStyle={[isSiteHebrew() && {transform: [{rotate: '180deg'}]}]}
                        iconRightStyle={[isSiteHebrew() && {transform: [{rotate: '180deg'}]}]}
                        scrollable={false}
                        useIsoWeekday={false}
                        scrollToDate={false}
                    />}
                    {
                        loading ?
                            <ActivityIndicator size="large" color={Colors.brandedBlue} style={{margin: '3em'}}/>
                            :
                            <View style={[styles.slotsWrapper]}>
                                {
                                    isEmpty ?
                                        <View style={[globalStyles.centerItems, {gap: '1em', margin: 'auto'}]}>
                                            <SvgFullCalendar />
                                            <AppText style={{color: Colors.iconEmptyStateColor, fontSize: '1.2rem'}}>{t('screens:Schedule:no-slots-open',{})}</AppText>
                                        </View>
                                        :
                                        <View>
                                            <View style={[globalSiteStyles().siteFlexRowSpaceBetween, {paddingHorizontal: '0.5em', paddingBottom: '0.5em'}]}>
                                                <div/>
                                                <TouchableOpacity onPress={() => handleShareOrCopy(mobile, shareLink)}>
                                                    <Icons8Generator name="forward-arrow" size={LARGE_ICON_SIZE}/>
                                                </TouchableOpacity>
                                            </View>
                                            {
                                                availabilityType === COACH_AVAILABILITY_TYPE && coachCount && slots && slots.map((slot, index) => <AvailabilityCard key={`slot-time-option-${slot?.id}-${index}`} item={slot} coachCount={coachCount} handlePress={()=> slotSelected(slot)} availabilityType={COACH_AVAILABILITY_TYPE}/>)
                                            }
                                            {
                                                availabilityType === SPACE_AVAILABILITY_TYPE && spaceCount && slots && slots.map((slot, index) => <AvailabilityCard key={`slot-time-option-${slot?.id}-${index}`} item={slot} spaceCount={spaceCount} handlePress={()=> slotSelected(slot)} availabilityType={SPACE_AVAILABILITY_TYPE}/>)
                                            }
                                        </View>
                                }
                            </View>
                    }
                </View>
                :
                <DesktopChooseSlot selectedCoachParam={urlParamCategory?.coachId || null}
                                   selectedSpaceParam={urlParamCategory?.spaceId || null}
                                   sharedLink={shareLink}
                                   updateURLDeepLink={updateURLDeepLink}
                                   service={service}
                                   closestDate={date}
                                   onDateChanged={(newValue) => desktopDateChanged(newValue)}
                                   slots={slots}
                                   coachCount={coachCount}
                                   spaceCount={spaceCount}
                                   handleBookPress={(slot)=> slotSelected(slot)}
                                   onRegisterSuccess={onRegisterSuccess}
                                   availabilityType={availabilityType}

                />
            }
        </>

    );
};


export default ChooseSlot;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                flexGrow: 1
            },
            slotsWrapper: {
                marginTop: '1em',
                maxHeight: () => `100% - ${parseInt(EStyleSheet.value('$calendarStripHeight')) + parseInt(EStyleSheet.value('$mobileHeader')) + parseInt(EStyleSheet.value('$tabsMaxHeight'))}`,
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                flexGrow: 1,
            },
            calendarHeader: {
                color: Colors.calendarBlack,
                fontWeight: '300',
                fontSize: '0.9rem',
                marginBottom: 10
            },
            dateNum: {
                fontWeight: '500',
                fontSize: '0.9rem',
            },
            header: {
                fontSize: '1.5rem',
                textAlign: 'center',
                color: Colors.lightBlack,
                alignItems: 'center',
                display: 'flex'
            },
        }

    })

})