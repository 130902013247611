import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import i18n, {t} from "../../../services/i18n";
import {scale, verticalScale} from 'react-native-size-matters';
import {connect} from "react-redux";
// import DatePicker from "react-native-datepicker";
import moment from "moment/moment";
import DatePicker from "../../../components/DatePicker";

import {Input} from 'native-base';
import DateTimePicker from '@react-native-community/datetimepicker';
import {AppText} from "../AppText";
import Modal from 'react-native-modal';
import date from '../../../services/i18n/date';

/**
 * to be wrapped with redux-form Field component
 */
class ReduxDatePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }

    render() {
        const {input} = this.props;
        return (
            <View style={[{marginVertical: verticalScale(5)}]}>
                <TouchableOpacity style={{
                    borderWidth: 0,
                    borderBottomWidth: 0.5,
                    borderColor: this.props.whiteLabelProperties.primaryColor,
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }} onPress={() => this.setState({show: true})}>
                    {this.state.show && Platform.OS.toLowerCase() === 'android' ? (  <DatePicker
                        date={input.value ? input.value : new Date()}
                        onClose={(event, date) => {
                            this.setState({show: false},() =>{
                                if (date && Platform.OS.toLowerCase() !== 'ios') {
                                    input.onChange(moment(new Date(date)).format("YYYY-MM-DD"));
                                } else {
                                    if (event !== 'aborted') {
                                        input.onChange(moment(new Date(date)).format("YYYY-MM-DD"));
                                    }
                                }
                            })
                        }}
                        onChange={d => {
                        }}
                    />) : null}
                    <Modal
                        isVisible={this.state.show && Platform.OS.toLowerCase() !== 'android'}
                        hideModalContentWhileAnimating
                        animationIn="fadeIn"
                        useNativeDriver
                        animationOut="fadeOut"
                        animationInTiming={this.props.animationTiming || 500}
                        animationOutTiming={this.props.animationTiming || 500}
                        backdropTransitionInTiming={this.props.animationTiming || 500}
                        backdropTransitionOutTiming={this.props.animationTiming || 500}
                    >
                        <DatePicker
                            date={input.value ? input.value : new Date()}
                            onClose={(event, date) => {
                                if (date && Platform.OS.toLowerCase() !== 'ios') {
                                    input.onChange(moment(new Date(date)).format("YYYY-MM-DD"));
                                } else {
                                    if (event !== 'aborted') {
                                        input.onChange(moment(new Date(date)).format("YYYY-MM-DD"));
                                    }
                                }
                                this.setState({show: false})
                            }}
                            onChange={d => {
                            }}
                        />
                    </Modal>
                    <AppText style={{
                        fontSize: scale(18),
                        color: Colors.black,
                        ...globalStyles.heeboRegular,
                        marginBottom: 10,
                        marginHorizontal: 5,
                        textAlign: i18n.isRTL ? 'right' : 'left'
                    }}
                    >{input.value ? date.format(input.value, 'LL') : t('screens:Profile:features:birthday-placeholder', {})}</AppText>
                </TouchableOpacity>
            </View>
        )
    }

    // render(){
    //     const {input} = this.props;
    //     return (
    //         <View style={[{marginVertical: verticalScale(5)}]}>
    //             <DatePicker
    //                 {...input}
    //                 style={{width: '100%'}}
    //                 mode="date"
    //                 date={input.value}
    //                 onDateChange={(date) => input.onChange(moment(new Date(date)).format("YYYY-MM-DD"))}
    //                 placeholder={t('screens:Profile:features:birthday-placeholder', {})}
    //                 androidMode="default"
    //                 confirmBtnText="Confirm"
    //                 cancelBtnText="Cancel"
    //                 selected={input.value || null}
    //                 onChange={input.onChange}
    //                 customStyles={{
    //                     dateInput: {
    //                         borderWidth: 0,
    //                         borderBottomWidth: 0.5,
    //                         borderColor: this.props.whiteLabelProperties.primaryColor,
    //                         alignItems: 'flex-start',
    //                         justifyContent:'center',
    //                     },
    //                     dateText: {
    //                         ...globalStyles.heeboRegular,
    //                         fontSize: scale(15),
    //                     },
    //                     placeholderText: {
    //                         ...globalStyles.heeboRegular,
    //                         fontSize: scale(13),
    //                         color: Colors.black
    //                     },
    //                     input: {
    //                         fontSize: scale(18),
    //                         color: Colors.black,
    //                         ...globalStyles.heeboRegular,
    //                         textAlign: i18n.isRTL ? 'right' : 'left'
    //                     },
    //                 }}
    //
    //                 showIcon={false}
    //             />
    //         </View>
    //     )
    // }

}


const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});


export default connect(
    mapStateToProps,
    null,
)(ReduxDatePicker);



