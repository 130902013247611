import * as React from "react";
import Svg, {Path} from "react-native-svg";

function SvgIcons8Forward(props) {
    return (
        <Svg viewBox="0 0 50 50" fill="#18181b" {...props}>
            <Path d="M14.98 2.98c-.816 0-1.546.497-1.851 1.25a1.993 1.993 0 00.457 2.184L32.172 25 13.586 43.586a1.997 1.997 0 00-.55 1.95c.183.698.73 1.245 1.429 1.429a1.997 1.997 0 001.95-.55l20-20a2 2 0 000-2.83l-20-20a2.007 2.007 0 00-1.435-.605z" />
        </Svg>
    );
}

export default SvgIcons8Forward;
