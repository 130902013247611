// @flow
import * as React from 'react';
import {View} from "react-native";
import {ScheduleContainer as MobileScheduleHandler} from "../../components/Web/Schedule/Mobile/ScheduleContainer";
import {isMobile} from "../../helpers/functions";
import {useEffect} from "react";
import {toggleScheduleSiteReFetch} from "../../actions/minisite";
import {useDispatch, useSelector} from "react-redux";
import {ScheduleHandler as DesktopScheduleHandler} from "../../components/Web/Schedule/Desktop/ScheduleHandler";
import {fetchCurrencySymbol} from "../../actions/shop";

const ScheduleScreen = (props) => {
    const mobile = isMobile();
    const dispatch = useDispatch();
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier])
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)

    useEffect(() => {
        if(box) {
            dispatch(toggleScheduleSiteReFetch(false));
        }
    }, [box]);

    useEffect(() => {
        if(location) {
            dispatch(fetchCurrencySymbol(`shop/currencySymbol/${location.id}`));
        }
    }, [location]);

    return (
        <View style={{flex: 1}}>
            {mobile ? <MobileScheduleHandler/> : <DesktopScheduleHandler/>}
        </View>
    );
};
export default ScheduleScreen;
