import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';
import TabBarIcon from '../../components/TabBarIcon';
import ScheduleContainer from '../../containers/Schedule/ScheduleContainer';
import FriendProfileContainer from '../../containers/FriendProfileContainer';
import SearchScreen from "../../screens/SearchScreen";
import ScheduleSingleScreen from "../../screens/ScheduleSingleScreen";
import {store} from "../../config/store";
import NavigationService from "../../helpers/NavigationService";
import {MarketStack} from "../MarketStack";
import PurchaseScreen from "../../screens/PurchaseScreen";

export const ScheduleStack = createStackNavigator({
        Schedule: ScheduleContainer,
        FriendProfile: {screen: FriendProfileContainer},
        ScheduleSearch: {screen: SearchScreen},
        ScheduleSingle: {screen: ScheduleSingleScreen},
        Purchase: {screen: PurchaseScreen}
    },
    {
        defaultNavigationOptions: {
            headerBackTitle: null,
            header: null,
        },
    }
);

ScheduleStack.navigationOptions = ({navigation}) => {
    let tabBarVisible = true;

    let routeName = navigation.state.routes[navigation.state.index].routeName
    if (routeName === 'ScheduleSingle' || routeName === 'Purchase') {
        tabBarVisible = false
    }
    return {
        tabBarIcon: ({focused}) => <TabBarIcon background focused={focused} name="calendar-range"/>,
        tabBarVisible,
    };
};
