import React from 'react';
import {View} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {Colors} from '../../styles/Colors';
import HeaderTitle from "../../components/Stateless/HeaderTitle";

import {scale, ScaledSheet, verticalScale} from 'react-native-size-matters';
import {globalStyles} from "../../styles/Global";
import {AppText} from "../Stateless/AppText";
import {getThemeProperty, keys} from "../../styles/Theme";

class HeaderWrapper extends React.PureComponent {
    state = {
        inputtedText: '',
    };

    onChangeText = value => {
        this.setState({inputtedText: value});
    };

    onSubmitText = () => {
        this.props.onSubmitText(this.state.inputtedText);
    };

    onCustomArrowBehaviour = () => {
        if(this.props.customArrowBehaviour !== null){
            this.props.customArrowBehaviour();
        }
    };

    render() {
        const {
            headerColor,
            headerTitle,
            headerArrowColor,
            padding,
            noArrow,
            isPop,
            customArrowBehaviour
        } = this.props;

        return (

            <View
                style={[styles.header, {backgroundColor: headerColor ? headerColor : getThemeProperty(keys.brandedGreen)}, padding ]}>
                <View style={styles.header}>
                {noArrow === true ? null : <HeaderTitle customArrowBehaviour={customArrowBehaviour} pop={isPop} headerColor={headerArrowColor}/>}
                {headerTitle ? <AppText style={[globalStyles.heeboMedium,globalStyles.paddingStart, {color: headerArrowColor}]}>{headerTitle}</AppText> : null}
                </View>
                {this.props.children}
            </View>
        );
    }
}


export default HeaderWrapper;


const styles = ScaledSheet.create({

    header: {
        height: verticalScale(35),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },


});