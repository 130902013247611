import { ERROR_MODAL, HIDE_MODAL, SHOW_MODAL,SHOW_MODAL_SIMPLE } from './constants';

export const showModal = (modalType, cardType = null, information = null, isInfoModal,reducerActionName,type) => {
  return (dispatch, getState) => {
    const { isModalVisible } = getState().modal;
    dispatch({
      type: SHOW_MODAL,
      payload: { isModalVisible: !isModalVisible, modalType, cardType, information, isInfoModal,reducerActionName,type},
    });
  };
};
export const showModalSimple = () => {
    return (dispatch, getState) => {
        const { isModalVisible } = getState().modal;
        dispatch({
            type: SHOW_MODAL_SIMPLE,
            payload: { isModalVisible: true},
        });
    };
};
export const hideModal = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL,
    });
  };
};

export const errorModal = (message = null) => {
  return (dispatch, getState) => {
    dispatch({
      type: ERROR_MODAL,
      payload: message,
    });
  };
};
