import React from 'react';
import {View} from 'react-native';
import {AppText} from "../AppText";
import {globalStyles} from "../../../styles/Global";

const showTotalTime = ['AMRAP', 'EMOM'];


export const LogbookWorkoutType = props => {
    const section = props.section;
    const total_time = section.total_time ? section.total_time : null;
    const total_rounds = section.total_rounds ? section.total_rounds : null;

    let workoutType = section.workout_type !== null ? section.workout_type.name : null;
    if (workoutType) {
        workoutType = workoutType.replace('_', ' ').toUpperCase();
    }
    return (
        <View>
            {workoutType === null ? null : showTotalTime.includes(workoutType) ? (
                <AppText style={{color:props.textColor}}>{workoutType} {`${total_time}MIN`}</AppText>
            ) : workoutType === 'RFT' ? <AppText style={{color:props.textColor}}>{total_rounds} {workoutType}</AppText> :
                workoutType !== 'GENERAL' ? <AppText style={{color:props.textColor}}>{workoutType}</AppText> : null}
        </View>
    )
};



