// @flow
import * as React from 'react';
import {Image, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {ImageBackground, Platform} from "react-native";
import {AppText} from "../../../Stateless/AppText";
import {Colors} from "../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {globalStyles} from "../../../../styles/Global";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {t} from "../../../../services/i18n";
import {addToCart, removeFromCart, subQuantity} from "../../../../actions/shop";
import {calcItemTax, isMobile} from "../../../../helpers/functions";

const CartItem = (props) => {
    const {item} = props;
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    const activePromoCode = useSelector(state => state.shop.activePromoCode);
    const dispatch = useDispatch();
    const mobile = isMobile()
    const tax = calcItemTax(item.price, item.membership_types_taxes)

    const increaseQuantity = () => {
        dispatch(addToCart(item))
    }

    const decreaseQuantity = () => {
        if(item.quantity === 1) {
            dispatch(subQuantity(item))
            deleteItem()
        } else {
            dispatch(subQuantity(item))
        }
    }

    const deleteItem = () => {
        dispatch(removeFromCart(item))
    }

    return (
        <View style={[globalStyles.cardsShadow, styles.wrapper, mobile && {gap: '1em'}]}>
            {mobile ?
                <>
                    {item.image ?
                        <Image style={[styles.itemImg, {borderRadius: 10}]} source={item.image} alt=""/>
                        :
                        <View style={[styles.itemImg, {borderRadius: 10}]}>
                            <Icons8Generator name={'shopaholic'} size={'2em'}/>
                        </View>
                    }
                    <View style={[{width: 'calc(100% - 5em)', justifyContent: 'space-between', height: '100%', padding: '0.2em'}]}>
                        <View style={[globalStyles.flexDirectionRowSpaceBetween]}>
                            <AppText style={[styles.bigText]}>{item.name}</AppText>
                            <TouchableOpacity onPress={deleteItem}><Icons8Generator name={'trash'} fill={Colors.red} size={'1.2em'}/></TouchableOpacity>
                        </View>
                        <View style={[globalStyles.flexDirectionRowSpaceBetween, {alignItems: 'center'}]}>
                            <View style={[styles.total, {width: 'inherit'}]}>
                                <AppText style={[styles.bigText, activePromoCode && item.price_after_promo_code && {textDecoration: 'line-through'}]}>{currencySymbol}{item.price * item.quantity}</AppText>
                                {activePromoCode && item.price_after_promo_code >= 0 && <AppText style={[styles.bigText]}>{currencySymbol}{item.price_after_promo_code * item.quantity}</AppText>}
                            </View>
                            <View style={[styles.quantity, {width: 'inherit'}]}>
                                <TouchableOpacity disabled={item.type === 'workshop'} onPress={increaseQuantity} style={[styles.quantityBtn, item.type === 'workshop' && styles.disabled]}><AppText style={[{fontSize: '1.5rem'}]}>+</AppText></TouchableOpacity>
                                <AppText style={[styles.quantityText]}>{item.quantity}</AppText>
                                <TouchableOpacity disabled={item.type === 'workshop'} onPress={decreaseQuantity} style={[styles.quantityBtn, item.type === 'workshop' && styles.disabled]}><AppText style={[{fontSize: '1.5rem'}]}>-</AppText></TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </>
                :
                <>
                    <View style={[styles.itemInfo]}>
                        {item.image ?
                            <Image style={[styles.itemImg]} source={item.image} alt=""/>
                            :
                            <View style={[styles.itemImg]}>
                                <Icons8Generator name={'shopaholic'} size={'2em'}/>
                            </View>
                        }
                        <View>
                            <AppText style={[styles.bigText]}>{item.name}</AppText>
                            <AppText style={[styles.smallText]}>{currencySymbol}{item.price} x {item.quantity}</AppText>
                        </View>
                    </View>
                    <View style={[styles.quantity]}>
                        <TouchableOpacity disabled={item.type === 'workshop'} onPress={increaseQuantity} style={[styles.quantityBtn, item.type === 'workshop' && styles.disabled]}><AppText style={[{fontSize: '1.5rem'}]}>+</AppText></TouchableOpacity>
                        <AppText style={[styles.quantityText]}>{item.quantity}</AppText>
                        <TouchableOpacity disabled={item.type === 'workshop'} onPress={decreaseQuantity} style={[styles.quantityBtn, item.type === 'workshop' && styles.disabled]}><AppText style={[{fontSize: '1.5rem'}]}>-</AppText></TouchableOpacity>
                    </View>
                    <View style={[styles.total]}>
                        <AppText style={[styles.bigText, activePromoCode && item.price_after_promo_code >= 0 && {textDecoration: 'line-through'}]}>{currencySymbol}{item.price * item.quantity}</AppText>
                        {activePromoCode && item.price_after_promo_code >= 0 && <AppText style={[styles.bigText]}>{currencySymbol}{item.price_after_promo_code * item.quantity}</AppText>}
                        {tax > 0 && <AppText style={[styles.smallText]}>+{currencySymbol}{calcItemTax(item.price, item.membership_types_taxes)} {t('screens:Purchase:tax',{})}</AppText>}
                    </View>
                    <TouchableOpacity onPress={deleteItem}><Icons8Generator name={'trash'} fill={Colors.red} size={'1.2em'}/></TouchableOpacity>
                </>
            }
        </View>
    );
};

export default CartItem;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                backgroundColor: Colors.white,
                padding: '0.5em',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
            },
            itemInfo: {
                flexDirection: 'row',
                gap: '1em',
                alignItems: 'center',
                width: '50%',
                maxWidth: '50%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            itemImg: {
                backgroundColor: Colors.graySeparator,
                borderRadius: '50%',
                width: '4em',
                height: '4em',
                alignItems: 'center',
                justifyContent: 'center'
            },
            quantity: {
                flexDirection: 'row',
                gap: '0.5em',
                justifyContent: 'center',
                alignItems: 'center',
                width: '25%'
            },
            quantityBtn: {
                fontSize: '1.5rem',
                backgroundColor: Colors.graySeparator,
                borderRadius: '50%',
                width: '1em',
                height: '1em',
                justifyContent: 'center',
                alignItems: 'center',
            },
            quantityText: {
                fontSize: '1.2rem',
            },
            bigText: {
                fontSize: '0.9rem',
                maxWidth: '15em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            smallText: {
                fontSize: '0.8rem',
                color: Colors.grayText
            },
            total: {
                width: '20%',
                paddingHorizontal: '1em'
            },
            disabled: {
                opacity: '40%'
            }
        }
    })
})