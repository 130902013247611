// @flow
import * as React from 'react';
import {Image, Text, TouchableOpacity, View} from "react-native-web";
import PopupModal from "../../Common/PopupModal";
import {useState} from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {useSelector} from "react-redux";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import ModalButton from "../../../ModalButton";
import {calcItemTax, isLocationIL, isMobile} from "../../../../helpers/functions";
import {Link} from "react-router-dom";
import {isSiteHebrew} from "../../../../helpers/ComponentFunctionality";

const ProductExtendedCard = (props) => {
    const {item, dollars, cents, addToCart, isPopup} = props;
    const currencySymbol = useSelector(state => state.shop.currencySymbol)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const mobile = isMobile();
    const isRtl = isLocationIL()
    const tax = calcItemTax(item.price, item.membership_types_taxes)
    const cart = useSelector(state => state.shop.cart)

    return (
        <View style={[{height: mobile ? '100%' : 'fit-content'}, !isPopup && !mobile && {height: 'inherit'}]}>
            <View style={[mobile && isPopup && styles.mobileWrapper, !isPopup && mobile && styles.pageMobileWrapper, !isPopup && !mobile && {height: 'inherit'}]}>
                {
                    isPopup ?
                        <View style={[styles.contentWrapper, mobile && {flexDirection: 'column'}]}>
                            {item.image && <Image source={item.image} alt="" style={{width: '15em', height: '15em', borderRadius: mobile ? 10 : 0, margin: mobile ? 'auto' : 0}}/>}
                            <View style={[styles.itemInfo]}>
                                <View style={{gap: '0.5em'}}>
                                    <View style={[styles.headerWrapper]}>
                                        <AppText style={[styles.department]}>{item.departments?.name}</AppText>
                                    </View>
                                    <AppText style={[styles.description]}>{item.description}</AppText>
                                </View>
                                <View>
                                    <AppText style={[styles.contentLabel]}>{t('screens:Purchase:price',{})}</AppText>
                                    <View style={{gap: '0.2em', flexDirection: isRtl ? 'row-reverse' : 'row'}}>
                                        <AppText style={[mobile ? styles.dollarPriceMobile : styles.dollarPrice]}>{`${currencySymbol}${dollars}`}</AppText>
                                        {cents && <AppText style={[mobile ? styles.centPriceMobile : styles.centPrice]}>{cents}</AppText>}
                                    </View>
                                    {tax > 0 && <AppText style={[styles.department]}>+{currencySymbol}{calcItemTax(item.price, item.membership_types_taxes)} {t('screens:Purchase:tax',{})}</AppText>}
                                </View>
                            </View>
                        </View>
                        :
                        <View style={[{marginHorizontal: '1em'}, !isPopup && mobile && styles.scrollableContent, !isPopup && !mobile && {height: `calc(100% - ${EStyleSheet.value('$desktopShopFooterBtns')}px)`}]}>
                            <View style={[styles.pageHeaderWrapper, mobile ? {justifyContent: 'start'} : {height: 30}]}>
                                <Link to={{pathname: '/shop', search:boxQueryString || location.search}}><Icons8Generator name={isSiteHebrew() ? 'arrow' : 'arrow-rtl'} fill={Colors.lightBlack} size={'1.5em'}/></Link>
                                <AppText style={[styles.header]} boldFont>{item.name}</AppText>
                                {!mobile && <Link to={{pathname: '/cart', search:boxQueryString || location.search}} style={{textDecoration: 'none'}}><View style={[styles.headerCartWrapper]}><Icons8Generator name={'cart'} fill={Colors.lightBlack} size={'1.5em'}/><AppText style={[styles.headerCartCount]}>({cart.length})</AppText></View></Link>}
                            </View>
                            <View style={[!isPopup && !mobile && {height: `calc(100% - ${EStyleSheet.value('$desktopShopFooterBtns')}px)`, overflow: 'auto'}]}>
                                {item.image && <View style={[styles.imageCover]}><Image source={item.image} alt="" style={{width: '15em', height: '15em', borderRadius: mobile ? 10 : 0, margin: mobile ? 'auto' : 0}}/></View>}
                                <View style={[styles.pageContentWrapper]}>
                                    {item.description && <View style={[{paddingVertical: '0.5em'}]}><AppText style={[styles.pageContentText]}>{item.description}</AppText></View>}
                                    <View style={[styles.border, {paddingVertical: '0.5em'}]}>
                                        <AppText style={[styles.contentLabel]}>{t('screens:Purchase:price',{})}</AppText>
                                        <View style={{gap: '0.2em', flexDirection: 'row'}}>
                                            <AppText style={[styles.pageContentText]}>{`${currencySymbol}${dollars}`}</AppText>
                                            {cents && <AppText style={[styles.pageContentText]}>{cents}</AppText>}
                                        </View>
                                        {tax !== null && parseInt(tax) > 0 && <AppText style={[styles.pageContentText]}>+{currencySymbol}{calcItemTax(item.price, item.membership_types_taxes)} {t('screens:Purchase:tax',{})}</AppText>}
                                    </View>
                                </View>
                            </View>
                        </View>
                }
                <View style={[styles.footerActions, isPopup && styles.border, mobile && styles.mobileFooterActions, !isPopup && !mobile && styles.pageFooterBtns, !isPopup && mobile && styles.footerBtnsMobile]}>
                    <TouchableOpacity onPress={addToCart} style={[styles.addToCart]}>
                        <Icons8Generator name={'cart'} fill={Colors.lightBlack} size={'1.2em'}/>
                        <AppText style={[styles.addToCartText, {color: Colors.lightBlack}]}>{t('screens:Purchase:addToCart',{})}</AppText>
                    </TouchableOpacity>
                    <Link to={{pathname: '/cart', search:boxQueryString}} style={EStyleSheet.flatten([{textDecoration: 'none'}, (mobile || !isPopup) && {width: '100%'}])} onClick={addToCart}>
                        <View style={[styles.primaryBtn, {backgroundColor: Colors.lightBlack}, (mobile || !isPopup) && {width: '100%'}]}>
                            <AppText style={[styles.buyNowBtnText]}>{t('screens:Purchase:buyNow', {})}</AppText>
                        </View>
                    </Link>
                </View>
            </View>
        </View>
    );
};

export default ProductExtendedCard;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: 0,
                paddingVertical: 0,
                width: 'fit-content',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
            },
            contentWrapper: {
                flexDirection: 'row',
                padding: '1.5em',
                gap: '1em'
            },
            itemInfo: {
                width: '22em',
                justifyContent: 'space-between'
            },
            headerWrapper: {
                gap: '0.5em',
                alignItems: 'flex-start'
            },
            header: {
                fontSize: '1.3rem',
                fontFamily: 'heebo-bold',
            },
            department: {
                fontSize: '0.8rem',
                color: Colors.labelColor,
            },
            description: {
                fontSize: '0.8rem',
                color: Colors.grey,
                marginBottom: '1rem'
            },
            price: {
                gap: '0.2em',
                flexDirection: 'row',
            },
            dollarPrice: {
                fontSize: '1.2rem',
                fontWeight: 'bold'
            },
            centPrice: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            footerActions: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: '1em',
                gap: '1.5em',
                alignItems: 'center'
            },
            mobileFooterActions: {
                flexDirection: 'column-reverse',
            },
            primaryBtn: {
                width: '7em',
                minHeight: 'auto',
                padding: '0.5em',
                borderRadius: 2,
                marginTop: 0,
                alignItems: 'center',

            },
            addToCart: {
                flexDirection: 'row',
                gap: '0.5em',
                alignItems: 'center'
            },
            addToCartText: {
                fontSize: '0.85rem',
                fontWeight: 'bold'
            },
            mobileWrapper: {
                flexDirection: 'column',
                paddingTop: '2.5em',
                justifyContent: 'space-between',
                height: '100%'
            },
            dollarPriceMobile: {
                fontSize: '1.3rem',
                fontWeight: 'bold'
            },
            buyNowBtnText: {
                fontSize: '0.9rem',
                color: Colors.white,
                fontFamily: 'heebo',
            },
            pageHeaderWrapper: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: '2em',
                alignItems: 'center',
                gap: '0.5em'
            },
            headerCartCount: {
                fontSize: '0.8rem',
            },
            headerCartWrapper: {
                flexDirection: 'row',
                textDecoration: 'none'
            },
            imageCover: {
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center'
            },
            pageContentWrapper: {
                marginVertical: '1em'
            },
            pageContentLabel: {
                fontSize: '0.8rem',
                color: Colors.lightBlack
            },
            pageContentText: {
                fontSize: '0.85rem',
                color: Colors.lightBlack
            },
            border: {
                borderTopColor: Colors.graySeparator,
                borderTopWidth: 1,
            },
            pageFooterBtns: {
                flexDirection: 'column-reverse',
                width: '15em',
                border: 'none',
                alignSelf: 'center',
                height: () => EStyleSheet.value('$desktopShopFooterBtns'),

            },
            pageMobileWrapper: {
                height: () => `100% - ${EStyleSheet.value('$mobileHeader')}`,
                maxHeight: () => `100% - ${EStyleSheet.value('$mobileHeader')}`,
                justifyContent: 'space-between'
            },
            footerBtnsMobile: {
                height: () => EStyleSheet.value('$mobileShopFooterBtns'),
                justifyContent: 'center',
                gap: '1em'
            },
            scrollableContent: {
                height: () => `100% - ${EStyleSheet.value('$mobileHeader') + EStyleSheet.value('$mobileShopFooterBtns')}`,
                maxHeight: () => `100% - ${EStyleSheet.value('$mobileHeader') + EStyleSheet.value('$mobileShopFooterBtns')}`,
                overflow: 'auto'
            },
            contentLabel: {
                fontSize: '0.85rem',
                color: Colors.lightBlack,
                fontWeight: 700
            },
        }
    })
})