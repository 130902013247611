import * as React from "react";
import Svg, { Path,Circle,G } from "react-native-svg";

function SvgIcons8Shopaholic(props) {
  return (
      <Svg
          xmlns="http://www.w3.org/2000/svg"
          width={48}
          height={48}
          viewBox="0 0 48 48"
          {...props}
      >
          <G data-name="Shopping">
              <Path
                  fill="none"
                  stroke="#e28d40"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M35 18.5l-1-7c-.8-3.866-4.169-8-8-8-2.873 0-5 2-5 7l1 8"
              />
              <Path fill="#ffb450" d="M4 47l4-35 12 1 12-1 5 35H4z" />
              <Path fill="#e28d40" d="M41.5 41.375L37 47l-5-35 4.5 2.25 5 27.125z" />
              <Circle cx={14} cy={17} r={2} fill="#fff3f7" />
              <Circle cx={27} cy={17} r={2} fill="#fff3f7" />
              <Path fill="#ed9a40" d="M47 44l-6-2-5-28 3-2 8 32z" />
              <Path
                  fill="none"
                  stroke="#ed9a40"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 17l1-7c.8-3.866 4.169-8 8-8 2.873 0 5 2 5 7l-1 8"
              />
              <Path
                  fill="#fff3f7"
                  d="M19.642 37.923l-6.4-6.492a4.352 4.352 0 010-6.093 4.2 4.2 0 016 0l.395.4.111-.114a4.2 4.2 0 016 0 4.352 4.352 0 010 6.093z"
              />
              <Path fill="#ffb450" d="M37 47l10-3-6-2-4 5z" />
          </G>
      </Svg>
  );
}

export default SvgIcons8Shopaholic;
