import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import Modal from "react-native-modal";
import {
    View, ImageBackground,
    TouchableOpacity, Text, ActivityIndicator, Image
} from 'react-native';
import {captureRef} from "react-native-view-shot";
import {openShareDialogAsync} from "../../../helpers/share";
import {hideModal, showModal} from "../../actions";
import ModalWithTopBarImage from "../../../components/ModalWithTopBarImage";
import {ScaledSheet} from "react-native-size-matters";


class ShareModal extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    renderCapture = async () => {
        setTimeout(async () => {
            const imageUri = await captureRef(this['screenCaptured']);
            await openShareDialogAsync('file://' + imageUri);
            this.props.hideModal();
        }, 300);
    }

    render() {

        return (
            <Modal style={styles.modal}
                   isVisible={this.props.isVisible}
                   hideModalContentWhileAnimating
                   animationIn="slideInUp"
                   useNativeDriver={false}
                   animationOut="slideOutDown"
                   animationInTiming={600}
                   animationOutTiming={300}
                   coverScreen={true}
                   hasBackdrop={false}
                   onShow={this.renderCapture}>
                <View collapsable={false} ref={(view) => {
                    this['screenCaptured'] = view
                }}>
                    {this.props.sharedComponent}
                </View>
            </Modal>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        showModal,
        //hideModal,
        dispatch,
    );
};

const mapStateToProps = state => ({
    connections: state.connections.data,
    isLoading: state.connections.isLoading,
    userId: state.auth.data.id,
});

ShareModal.propTypes = {
    isModalVisible: PropTypes.bool,
    information: PropTypes.object,
    connections: PropTypes.array,
    userId: PropTypes.number,
    isLoading: PropTypes.bool,
};


export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ShareModal);

const styles = ScaledSheet.create({
    modal: {
        backgroundColor: 'white',
        margin: 0,
        alignItems: undefined,
        justifyContent: undefined,
    }
});