import {apiAction} from '../helpers/HTTP';
import {
    PATCH_WORKOUT, USER_RESULTS,
    WORKOUTS,
} from '../constants';

export const fetchWorkouts = (date) => async (dispatch) => {
    const actionType = WORKOUTS.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('logbook/workouts', 'post', {date});
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


export const patchWorkout = (response) => async (dispatch) => {
        dispatch({type: `${WORKOUTS.toUpperCase()}_SUCCESS`, payload: response});
};

export const fetchUserResults = (from,to) => async (dispatch) => {
    const actionType = USER_RESULTS.toUpperCase();
    const params = {
        from,
        to,
    };
    try {
        const response = await apiAction('logbook/userResultsByDates', 'post', params);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        return error;
    }
};

