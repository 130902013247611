import React, {Component} from 'react';
import {View, Icon} from 'native-base';
import {Image} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {Colors} from '../../../styles/Colors';
import styles from '../styles';
import {t} from "../../../services/i18n";
import {globalStyles} from "../../../styles/Global";
import AppLogo from "../../../components/Stateless/AppLogo";
import {getThemeProperty, keys} from "../../../styles/Theme";
import {AppText} from "../../../components/Stateless/AppText";
import Config from "../../../config/Config";
import {connect} from "react-redux";


class FirstLoginCompleteScreen extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.themeColor = getThemeProperty(keys.brandedGreen);
    }

    render() {
        return (
                <LinearGradient
                    colors={[this.props.whiteLabelProperties.primaryColor,this.props.whiteLabelProperties.primaryColor]}
                    style={styles.gradient}>
                    <View style={[styles.container]}>
                        {Config.whiteLabel.toLowerCase() === 'arbox' ? <AppLogo invert={true}/> : null}
                        <View
                            style={[styles.circleBorder, globalStyles.marginTopBetweenElementsBig, globalStyles.centerItems,{backgroundColor:this.themeColor}]}>
                            <Image
                                resizeMode="contain"
                                style={[styles.avatar1]}
                                source={require('../../../images/first3.png')}
                            />
                            <View style={styles.circleLighter}>
                                <Image
                                    resizeMode="contain"
                                    style={styles.avatar2}
                                    source={require('../../../images/first1.png')}
                                />
                                <View style={styles.circleMain}>
                                    {/*<Icons8Generator name={'running'} fill={Colors.white}/>*/}
                                    <Icon type='FontAwesome5' name='running'
                                          style={{color: this.props.whiteLabelProperties.primaryColorText, fontSize: 45}}/>
                                </View>
                                <Image
                                    resizeMode="contain"
                                    style={styles.avatar4}
                                    source={require('../../../images/first2.png')}
                                />
                            </View>
                            <Image
                                resizeMode="contain"
                                style={styles.avatar5}
                                source={require('../../../images/first5.png')}
                            />
                        </View>
                        <View style={globalStyles.marginTopBetweenElementsBig}>
                            <AppText style={[globalStyles.heeboMedium, globalStyles.marginTopBetweenElements, {
                                color: this.props.whiteLabelProperties.primaryColorText,
                                textAlign: 'center'
                            }]}>{t('screens:OnBoarding:startConnectionFriends', {})}</AppText>
                            <AppText style={[globalStyles.heeboRegular, {
                                color: this.props.whiteLabelProperties.primaryColorText,
                                textAlign: 'center'
                            }]}>{t('screens:OnBoarding:startConnectionFriendsText', {})}</AppText>
                        </View>
                    </View>
                </LinearGradient>
        );
    }
}



const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});



export default connect(
    mapStateToProps,
    null
)(FirstLoginCompleteScreen);

