import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8AddUserMale(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M19.875.406c-4.672.086-7.656 1.973-8.938 4.907-1.222 2.792-.949 6.32-.062 9.968-.477.559-.855 1.309-.719 2.438.149 1.23.489 2.105.969 2.718.266.336.613.368.938.532.175 1.047.468 2.093.906 2.968.25.504.535.97.812 1.344.125.168.305.266.438.407.008 1.234.011 2.261-.094 3.562-.324.785-1.082 1.418-2.313 2.031-1.269.633-2.921 1.219-4.593 1.938-1.672.718-3.39 1.593-4.75 2.968C1.109 37.563.149 39.45 0 41.938L-.063 43H25l-.656-2H2.25c.281-1.414.809-2.55 1.656-3.406C4.973 36.516 6.426 35.707 8 35.03c1.574-.676 3.23-1.21 4.688-1.937 1.457-.727 2.804-1.684 3.374-3.219l.063-.25c.152-1.676.094-2.863.094-4.313v-.593l-.532-.282c.09.047-.124-.09-.312-.343a7.261 7.261 0 01-.625-1.032c-.414-.828-.754-1.894-.844-2.75l-.094-.812-.843-.063c-.008 0-.102.012-.281-.218-.18-.23-.415-.739-.532-1.719-.097-.832.324-1.121.281-1.094l.657-.406-.188-.719c-.941-3.625-1.105-6.918-.125-9.156.977-2.23 2.969-3.633 7.125-3.719h.032c2.011.008 3.316.598 3.687 1.25l.25.406.469.063c1.39.195 2.187.754 2.75 1.531.562.778.875 1.864.968 3.063.188 2.398-.503 5.191-.937 6.5l-.25.781.688.406c-.043-.027.382.262.28 1.094-.116.98-.35 1.488-.53 1.719-.18.23-.274.218-.282.218l-.843.063-.094.813c-.094.863-.442 1.921-.844 2.75a7.44 7.44 0 01-.594 1.03c-.183.255-.375.395-.281.345l-.531.28v.595c0 1.445-.059 2.636.093 4.312v.125l.063.125c.32.863.883 1.73 1.813 2.281l1.03-1.718c-.421-.25-.698-.68-.905-1.188-.106-1.3-.102-2.328-.094-3.563.129-.14.316-.238.437-.406.274-.379.535-.843.782-1.343.425-.875.734-1.926.906-2.97.312-.163.648-.202.906-.53.48-.614.82-1.489.969-2.72.133-1.093-.235-1.816-.688-2.374.489-1.586 1.114-4.149.907-6.781-.114-1.438-.481-2.872-1.344-4.063-.79-1.094-2.07-1.89-3.688-2.219C23.981.918 22.09.406 19.906.406zM38 26c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10-10-4.465-10-10 4.465-10 10-10zm-1 4v5h-5v2h5v5h2v-5h5v-2h-5v-5z" />
    </Svg>
  );
}

export default SvgIcons8AddUserMale;
