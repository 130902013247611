import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  modalTopBarHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 13,
    fontSize: 14
  },
  topBarHeaderText: {
    color: 'white',
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0.54
  },
  modalContent: {
    backgroundColor: 'white',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    overflow: 'hidden',
    maxHeight: 500
  },
  imageStyle: {
    width: '100%',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    maxHeight: 500
  }
});
