// @flow
import * as React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import BrokenBikes from "../../../svg/custom/BrokenBikes";
import Icons8Cancel from "../../../svg/Icons8Cancel";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import PopupModal from "./PopupModal";
import {Colors} from "../../../styles/Colors";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import ModalButton from "../../ModalButton";
import EStyleSheet from "react-native-extended-stylesheet";
import {isMobile} from "../../../helpers/functions";

const ConfirmModal = (props) => {
    const {show, setClose, title, subHeader, iconName, handleConfirm, color, confirmText, cancelText} = props;
    const mobile = isMobile();

    return (
        <PopupModal show={show} setClose={setClose} overrideStyles={styles.modal}>
            <View style={[styles.wrapper, mobile && {flexGrow: 1}]}>
                <Icons8Generator name={iconName} style={styles.icon} fill={color || Colors.red} width={'4em'} height={'4em'}/>
                <AppText style={styles.header} boldFont>{title}</AppText>
                <AppText style={styles.subheader}>{subHeader}</AppText>
                <View style={styles.btns}>
                    <ModalButton
                        onPress={handleConfirm}
                        type='primary'
                        colors={color || Colors.red}
                        text={confirmText || t('common:yes-cancel',{})}
                        textColor={Colors.white}
                        overrideStyle={styles.primaryBtn}
                        textStyles={{fontWeight: 'normal'}}
                    />
                    <TouchableOpacity onPress={setClose}>
                        <AppText style={styles.closeBtn}>{cancelText || t('common:drawer:booking:dont-cancel',{})}</AppText>
                    </TouchableOpacity>
                </View>
            </View>
        </PopupModal>
    );
};

export default ConfirmModal;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: '4em',
                paddingVertical: '2em',
                width: 'fit-content'
            },
            wrapper: {
                gap: '1em',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            },
            icon: {},
            header: {
                fontSize: '1rem'
            },
            subheader: {
                fontSize: '0.9rem',
                width: '17em'
            },
            btns: {
                gap: '1em',
                width: '100%',

            },
            primaryBtn: {
                borderRadius: 3,
                width: '100%',
                minWidth: 'fit-content',
                height: 'fit-content',
                minHeight: 'fit-content',
                paddingHorizontal: '2em',
                paddingVertical: '0.3em'
            },
            closeBtn: {
                fontSize: '0.8rem',
                fontWeight: '300'
            }
        }
    })
})