import React from 'react';
import {FlatList, View, TouchableOpacity} from 'react-native';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Swipeable from 'react-native-swipeable';
import PropTypes from 'prop-types';
import FeedCard from '../FeedCard'
import {Colors} from "../../styles/Colors";
import {removeFeed} from "../../actions/home";
import {ScaledSheet,scale} from "react-native-size-matters";
import HomeEmptyComponent from "../HomeEmptyComponent";
import {globalStyles} from "../../styles/Global";
import {Icons8Generator} from "../Stateless/Icons8Generator";



class FeedList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.generateKey = this.generateKey.bind(this);
        this.swipeable = this.swipeable.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.renderEmptyContainer = this.renderEmptyContainer.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {


    }


    generateKey = (pre) => {
        return `${ pre }_${ new Date().getTime() }`;
    };

    removeItem(action, obj, direction) {
        return (
            <View
                style={[direction === 'left' ? styles.leftSwipeItem : styles.rightSwipeItem, {backgroundColor: 'transparent'}]}>
                <TouchableOpacity
                    style={{backgroundColor: Colors.red, borderRadius: 100,padding:10}}
                    onPress={() => this.props.removeFeed(obj.id, action)}>
                    <Icons8Generator name={'trash-can'} fill={Colors.white} width={scale(30)} height={scale(30)}/>
                </TouchableOpacity>
            </View>
        );
    }


    swipeable = (action, obj,index) => {
        return (
                <Swipeable
                    key={obj.id}
                    onSwipeStart={() => this.props.isSwiping(true)}
                    onSwipeRelease={() => this.props.isSwiping(false)}
                    leftButtons={[
                        this.removeItem(action, obj, 'left')
                    ]}
                    rightButtons={[
                        this.removeItem(action, obj, 'right')
                    ]}
                >
                    <FeedCard index={index} screen={this.props.screen} action={action} refresh={this.props.refresh} data={obj}/>
                </Swipeable>

        )
    };

    renderItem = (list,item, index, separators) => {
        {
            if (this.props.screen !== 'notifications' && Object.prototype.hasOwnProperty.call(item, 'swipeable')) {
                return (this.swipeable(item.action, item,index));
            }
            return <FeedCard index={index} screen={this.props.screen} action={item.action} refresh={this.props.refresh} data={item}/>
        }
    };

    renderEmptyContainer() {
        if (!this.props.isLoading) {
            switch (this.props.screen) {
                case 'notifications': {
                    return null;
                }
                case 'homeScreen':{
                    return <HomeEmptyComponent style={globalStyles.flexCenterElements}/>
                }
                default:
                    return <HomeEmptyComponent style={globalStyles.flexCenterElements}/>
            }
        }
        return null;
    }

    render() {
        const {feeds, isLoading, swipping} = this.props;
        const list = [];
        if (isLoading) {
            return (
                <View style={styles.container}>
                    {
                        [...Array(3)].map((e, index) => <FeedCard screen={this.props.screen} action={'loader'}
                                                                  data={null} key={index} refresh={this.props.refresh}/>)
                    }
                </View>
            );
        }
        Object.keys(feeds).forEach(item => {
            if (Array.isArray(feeds[item])) {
                feeds[item].map((obj) => {
                    if(!obj.hasOwnProperty('action'))
                        obj.action = item;
                    list.push(obj);
                })
            } else {
                let obj = feeds[item];
                if(!obj.hasOwnProperty('action'))
                    obj.action = item;
                list.push(feeds[item]);
            }
        });
        if (list) {
            return (
                <View styles={{flex: 1}}>
                    <FlatList
                        scrollEnabled={!swipping}
                        style={[styles.container]}
                        data={list}
                        contentContainerStyle={{flexGrow: 1, justifyContent: 'flex-start'}}
                        renderItem={({item, index, separators}) => this.renderItem(list,item, index, separators)}
                        keyExtractor={(item, index) => index.toString()}
                        ListEmptyComponent={this.renderEmptyContainer()}
                    />
                </View>
            );
        }

    }
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
    },
    leftSwipeItem: {
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingRight: 20,
    },
    rightSwipeItem: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 20,
    },

});

FeedList.propTypes = {
    feeds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    removeFeed: PropTypes.func,
    isSwiping: PropTypes.func,
    swipping: PropTypes.bool,
    isLoading: PropTypes.bool,
    screen: PropTypes.string,
};

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        removeFeed
    }, dispatch);
};


export default connect(
    null,
    mapActionsToProps
)(FeedList);

