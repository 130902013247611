import {Colors} from "../styles/Colors";
import i18n, {t} from "../services/i18n";
import {scale} from 'react-native-size-matters';
import moment from 'moment';
import {SERVER_DATE_FORMAT} from "../variableConstants";
import {Platform} from "react-native";
import {useMediaQuery} from "react-responsive"
import {Icons8Generator} from "../components/Stateless/Icons8Generator";
import React from "react";
import {store} from "../config/store";
import {MEMBERSHIP_TYPES_CONST} from "../constants/membershipTypes";
import {CANCEL_SCHEDULE_USER, INSERT_SCHEDULE_USER} from "../constants/booking";
import {APP_REFERER, PLUGIN_REFERER, SITE_REFERER} from "../constants";
import Constants from "expo-constants";
import * as Sentry from "sentry-expo";
import uuid from "uuid";

export const EXTRA_SM_ICON_SIZE = 12;
export const SMALL_ICON_SIZE = 16;
export const MEDIUM_ICON_SIZE = 20;
export const BIG_ICON_SIZE = 24;
export const LARGE_ICON_SIZE = 30;
export const LARGE_BETWEEN_HUGE_ICON_SIZE = 40;
export const HUGE_ICON_SIZE = 50;
export const EXTRA_HUGE_ICON_SIZE = 60;
export const ICON_SIZE_85 = 85;
export const ICON_SIZE_100 = 100;


export function truncateWithEllipsis(text, max) {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

export function addOpacityToColor(hex, opacity) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})` : null
}


export function membershipTextColorByStatus(status) {
    return status === 0 ? Colors.red : status === 1 ? Colors.green : Colors.yellow;
}

export function membershipButtonByStatus(status) {
    return status === 0 ? Colors.grey : Colors.brightSkyBlue
}

export function getUserFromFriendConnection(obj) {
    const relativeObj = Object.prototype.hasOwnProperty.call(obj, 'friend_connection') ? obj.friend_connection : obj;
    return relativeObj === null ? relativeObj : Object.prototype.hasOwnProperty.call(relativeObj, 'user') ? relativeObj.user : relativeObj.friend_user
}

export function filterSearch(array, key, term) {
    const termLowerCase = term.toLowerCase();
    const res = array.filter(obj => {
        return obj[key].toLowerCase().search(termLowerCase) !== -1;
    });

    return res;
}

export function convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
        const charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
}


export function getToastDefaultConfig() {
    return {
        textColor: Colors.white,
        duration: 2500,
        opacity: 0.85,
        position: -70,
        shadowColor: Colors.iconGray
    }
}

export function getSvg8IconsDefault() {
    return {
        width: scale(SMALL_ICON_SIZE),
        height: scale(SMALL_ICON_SIZE),
        fill: Colors.black
    }
}

export function getIconOnRTLWhenHasSides(right, left) {
    return i18n.isRTL ? right : left
}

export function calcMembershipEndDate(period_time_unit, period_amount, start_date) {
    let startDate = moment(start_date);
    let endDate = moment(start_date);
    if (startDate && period_amount && period_time_unit) {
        period_amount = Number(period_amount);
        switch (period_time_unit.toLowerCase()) {
            case "year":
                endDate.add(period_amount, 'y').subtract(1, 'd');
                break;
            case "week":
                endDate.add(period_amount, 'w').subtract(1, 'd');
                break;
            case "month":
                endDate.add(period_amount, 'M').subtract(1, 'd');
                break;
        }
        return endDate.format(SERVER_DATE_FORMAT);
    }
    return null;
}

export function getFirstPaymentDate(start_date, membership, box) {
    if (box.recurring_payments_charge_day === null || box.allow_relative_payment === 1 || membership.recurring_cycle !== 4) {
        return start_date;
    }
    let start_date_moment_object = moment(start_date);
    let start_date_string = start_date_moment_object.format(SERVER_DATE_FORMAT);
    let charge_day_date = start_date_moment_object.clone();
    let charge_day_date_string = charge_day_date.format(SERVER_DATE_FORMAT);
    if (charge_day_date_string >= start_date_string) {
        return charge_day_date_string;
    } else {
        return charge_day_date.add(1, 'month').clone().format(SERVER_DATE_FORMAT);
    }
}

export function calcCartItems(cart, recurring) {
    const activePromoCode = store.getState().shop.activePromoCode;
    let total_price;
    //TODO: not good with recurring, needs more QA
    if(activePromoCode) {
        total_price = recurring ? activePromoCode.totalRecurring : activePromoCode.total + activePromoCode.totalRecurring;
    } else {
        total_price = cart.reduce((sum, item) => {
            if (recurring)
                return item.is_recurring_payment === 1 ? sum + (item.price * item.quantity) : sum;
            return sum + (item.price * item.quantity);
        }, 0);
    }
    const taxes = getTaxesFromMembership(cart[0]);
    const total_tax = calcItemTax(total_price, taxes)
    total_price += total_tax;
    const sub_total = total_price - total_tax;
    return {total_price, total_tax, sub_total};
}

export function calcItemTax(price, taxes) {
    let total_tax = 0;
    if (taxes.length > 0) {
        total_tax = taxes.reduce((sum, tax) => {
            return sum + ((tax.percentage / 100) * price)
        }, 0)
    }
    return total_tax;
}

export function getTaxesFromMembership(membership) {
    return membership.membership_types_taxes.map(item => item.taxes);
}

export function compareTaxesBetweenMemberships(first, second) {
    first = getTaxesFromMembership(first);
    second = getTaxesFromMembership(second);
    return isArrayIdentical(first, second);
}

export function isArrayIdentical(a, b) {
    if (a.length !== b.length)
        return false;
    let arrA = a.sort();
    let arrB = b.sort();
    return JSON.stringify(arrA) === JSON.stringify(arrB);
}

export function objHasAllProperties(obj, props) {
    for (let i = 0; i < props.length; i++) {
        if (!obj.hasOwnProperty(props[i]))
            return false;
    }
    return true;
}

export function boxHasShop(usersBoxes, boxFk) {
    return !!usersBoxes.filter((userBox => {
        if (userBox.box_fk === boxFk) {
            if (userBox.locations_box.country_code !== 'IL')
                return true;
            if (userBox.locations_box.has_shop === true)
                return true;
        }
    })).length;
}

export function isBoxIL(usersBoxes, boxFk) {
    return usersBoxes.some((userBox => {
        if (userBox.box_fk === boxFk) {
            if (userBox.locations_box.country_code === 'IL')
                return true;
        }
    }));
}

export function isLocationIL(selectedLocation) {
    const location = selectedLocation || store.getState().minisite.boxes[store.getState().minisite.activeIdentifier].siteSelectedLocation;
    return location.country_code === 'IL'
}

export function getAchNamingTranslations(cardName) {
    switch (cardName) {
        case 'ach-s':
            return t('screens:Purchase:savings', {});
        case 'ach-c':
            return t('screens:Purchase:checking', {})
        case 'ach-individual':
            return t('screens:Purchase:individual', {})
        case 'ach-company':
            return t('screens:Purchase:company', {})
        default:
            return t('screens:Purchase:individual', {})
    }
}

export const IsraelPaymentSystems = ['icredit', 'pelecard', 'meshulam']

export function convertDashesToCamelCase(dashesString) {
    return dashesString.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    }).replace('-', '');
    ;
}


export function shortenName(name) {
    if (name) {
        return name.charAt(0) + '.';
    }
    return '';
}

export function capitalize(str) {
    if (str)
        return str.charAt(0).toUpperCase() + str.slice(1);
    return '';
}

export function slotMockDataToSchedule(data) {
    return {
        availability: data.availability,
        id: data.id,
        box_categories_id: data.box_categories.id,
        box_fk: data.box_fk,
        date: data.date,
        time: data.time,
        end_time: data.end_time,
        end_time_with_break: data.end_time_with_break,
        coach_ub_id: data.coach?.id, // Just for validations, will get data from DB
        spaces_id: data.space?.id
    }
}

export function isWeb() {
    const extra = Constants.manifest.extra;
    return Platform.OS.toLowerCase() === 'web' && extra && extra.hasOwnProperty('build_env');
}

export function isMobile() {
    const isTabletOrMobileDevice = useMediaQuery({
        maxDeviceWidth: 600,
        query: "(max-device-width: 600px)"
    });
    return isTabletOrMobileDevice;
}

export function renderArrow() {
    let icon = i18n.isRTL ? 'arrow-rtl' : 'arrow'
    return (
        <Icons8Generator name={icon} fill={Colors.black}/>
    )
}

export function isAMPMtimeFormat() {
    const timeFormat = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier].siteSelectedLocation.time_format;
    return timeFormat.toLowerCase() === 'h:mm a';
}

export function getPriceFromBoxCategories(boxCategories) {
    if (boxCategories.hasOwnProperty('membership_types') && boxCategories.membership_types.length > 0)
        if (boxCategories.membership_types[0].price > 0) //Unable to find server solution
            return boxCategories.membership_types[0].price;
    return boxCategories.price;
}

export function getPayForSlotMembershipType(schedule) {
    //Membership type must be select from correct location!!!
    const {locations_box_fk, box_categories, booking_option} = schedule;
    if (booking_option !== INSERT_SCHEDULE_USER)
        return null;//CAN NOT ALLOW PAY FOR SLOT SCHEDULE FULL
    if (box_categories.hasOwnProperty('membership_types') && box_categories.membership_types.length > 0) {
        const membershipType = box_categories.membership_types.filter(item => item.location_box_fk === locations_box_fk)
        if (membershipType)
            if (membershipType[0].price >= 0)
                return membershipType[0];
    }
    return null;
}

export function isPayForSlotSelected(selectedMembership) {
    return selectedMembership && selectedMembership.hasOwnProperty('type') && selectedMembership.type === MEMBERSHIP_TYPES_CONST.TYPE_SERVICE;
}

export function isServiceCart(cart) {
    return cart.length === 1 && cart[0].hasOwnProperty('type') && cart[0].type === MEMBERSHIP_TYPES_CONST.TYPE_SERVICE;
}
export function getReferer(refererStr) {
    const referer = refererStr.toUpperCase();
    switch (referer) {
        case SITE_REFERER:
        case PLUGIN_REFERER:
        case APP_REFERER:
            return referer;
        default:
            return null;
    }
}

export function getCartSum(cart) {
    return cart.map(item => item.price_after_promo_code >= 0 ? item.price_after_promo_code : item.price).reduce((prev, next) => prev + next, 0)
}


export function containsHTML(text) {
    if (text) {
        return text.match(/<.+?>/);
    }
    return false;
}

export function directionToTextAlign(direction) {
    return (direction === "rtl" ? "right" : direction === "ltr" && "left");
}




export function doesLocationAllowEnterDebt(locationsBox){
    return locationsBox.disable_pages_app ? locationsBox.disable_pages_app.some(item => item.area === 'schedule' && item.section_name.toLowerCase() === 'allowenterdebt') : null;
}

export function sendMessageToSentryBrowser(messageName, params, messageId) {
    const scope = new Sentry.Browser.Scope();
    scope.setExtra("payload", params);
    scope.setExtra("scope id", messageId);
    Sentry.Browser.captureMessage(messageName + '-' + messageId, scope);
    scope.clear();
}

export function cartHasWorkshop() {
    const cart = store.getState().shop.cart;
    const workshops = cart.filter(item => item.type === 'workshop')
    return workshops.length > 0
}

export function createCartDetailsInfo() {
    const cart = store.getState().shop.cart;
    const location = store.getState().minisite.boxes[store.getState().minisite.activeIdentifier].siteSelectedLocation;
    let membershipsInfo = []

    if(cart?.length === 1) {
        membershipsInfo.push(cart[0].name, t('screens:Register:starts-on', {startDate: moment(cart[0].start).format(location.date_format)}))
    } else if (cart?.length > 1) {
        membershipsInfo.push(cart[0].name, cart[1].name);
        if(cart.length > 2) {
            membershipsInfo.push(`+${cart.length - 2}`)
        }
    }
    return membershipsInfo
}

export function checkPurchaseForGroup() {
    const cart = store.getState().shop.cart;
    return cart.filter(item => item?.register_group_member === true).length > 0
}