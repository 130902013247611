import React from 'react';
import {View, StyleSheet, TextInput} from 'react-native';
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import i18n from "../../../services/i18n";
import {AppText} from "../AppText";
import {scale} from 'react-native-size-matters';
import {getThemeProperty,keys} from "../../../styles/Theme";
import {Input} from "native-base";
import FloatingLabelTextInput from '@verticalstrategy/react-native-floating-label-text-input';

/**
 * to be wrapped with redux-form Field component
 */
export default class FloatingTextInput extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    render(){
        const {input, meta , ...inputProps} = this.props;
        const error = inputProps.showError === true || meta.submitFailed === true ? meta ? meta.error : null : null;
        const validationStyles = meta ? meta.touched && meta.active
            ? {borderBottomColor:getThemeProperty(keys.brandedGreen)} : {} : {};
        const styleContainer = inputProps.hasOwnProperty('styleContainer') ? inputProps.styleContainer : {};
        const rowOverride = inputProps.hasOwnProperty('rowOverride') ? inputProps.rowOverride : {};
        return (
            <View style={[styles.row,rowOverride]}>
                <View style={[styles.column]}>
                    <FloatingLabelTextInput
                        {...inputProps}
                        containerStyle={[styles.textInputContainer,{borderBottomColor:this.props.color ? this.props.color : getThemeProperty(keys.brandedGreen)}, validationStyles,styleContainer]}
                        labelStyle={styles.textInputLabel}
                        onChangeText={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                        forwardRef
                        innerRef={x => input.ref = x}
                        autoCapitalize = 'none'
                        value={input.value}
                        style={styles.input}
                        placeholderTextColor={Colors.black}
                        blurOnSubmit={false}
                        returnKeyType={this.props.returnKeyType ? this.props.returnKeyType : 'done'}
                        onSubmitEditing={this.props.onSubmitEditing}
                    />
                    {/*<TextInput*/}
                    {/*    ref={c => (input.ref = c)}*/}
                    {/*    placeholder={input.value}*/}
                    {/*    secureTextEntry={input.name === 'password'}*/}
                    {/*    style={[styles.textInputContainer,{borderBottomColor:this.props.color ? this.props.color : getThemeProperty(keys.brandedGreen)}, validationStyles]}*/}
                    {/*    {...input}*/}
                    {/*/>*/}
                    {error ? <AppText style={globalStyles.textRed}>{error}</AppText> : null}
                </View>
            </View>
        )
    }

}


const styles = StyleSheet.create({
    container: {
        backgroundColor: '#005493',
        flex: 1,
    },

    row: {
        paddingVertical: 10,
    },
    column: {

    },

    textInputContainer: {
        minHeight: 50,
        borderBottomWidth: 0.7,
    },

    textInputLabel: {
        ...globalStyles.heeboRegular,
        fontSize: 12,
        color: Colors.black,
    },

    input: {
        paddingHorizontal: 5,
        width:'100%',
        fontSize: scale(18),
        color: Colors.black,
        ...globalStyles.heeboRegular,
        textAlign: i18n.isRTL ? 'right' : 'left'
    },
    inputContainer: {
        borderBottomWidth: 0.7,
        borderBottomColor: 'rgba(255,255,255,0.6)',
        flex: 1,

    },
    valid: {
        // borderColor: Colors.green
    },
    active: {

    },
    invalid: {
        // borderColor: Colors.redPinkColor
    },
    onFocus: {
        // borderColor: Colors.paleblue,
    }
});
