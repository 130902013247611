import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Slider(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 7c-1.852 0-3.406 1.285-3.855 3H3a1.006 1.006 0 00-.879.496 1.01 1.01 0 000 1.008c.184.312.52.5.879.496h18.145c.449 1.715 2.003 3 3.855 3 1.852 0 3.406-1.285 3.855-3H47c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496H28.855C28.406 8.285 26.852 7 25 7zm0 2c1.086 0 1.941.836 1.988 1.91a1.44 1.44 0 000 .184A1.975 1.975 0 0125 13a1.976 1.976 0 01-1.988-1.91 1.44 1.44 0 000-.184A1.975 1.975 0 0125 9zm14 12c-1.852 0-3.406 1.285-3.855 3H3a1.006 1.006 0 00-.879.496 1.01 1.01 0 000 1.008c.184.312.52.5.879.496h32.145c.449 1.715 2.003 3 3.855 3 1.852 0 3.406-1.285 3.855-3H47c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496h-4.145c-.449-1.715-2.003-3-3.855-3zm0 2c1.086 0 1.941.836 1.988 1.91a1.44 1.44 0 000 .184A1.975 1.975 0 0139 27a1.976 1.976 0 01-1.988-1.91 1.44 1.44 0 000-.184A1.975 1.975 0 0139 23zM14 35c-1.852 0-3.406 1.285-3.855 3H3a1.006 1.006 0 00-.879.496 1.01 1.01 0 000 1.008c.184.312.52.5.879.496h7.145c.449 1.715 2.003 3 3.855 3 1.852 0 3.406-1.285 3.855-3H47c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496H17.855c-.449-1.715-2.003-3-3.855-3zm0 2c1.086 0 1.941.836 1.988 1.91a1.44 1.44 0 000 .184A1.975 1.975 0 0114 41a1.976 1.976 0 01-1.988-1.91 1.44 1.44 0 000-.184A1.975 1.975 0 0114 37z" />
    </Svg>
  );
}

export default SvgIcons8Slider;
