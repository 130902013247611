// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAppointmentTypes} from "../../../actions/schedule";
import {globalSiteStyles, globalStyles} from "../../../styles/Global";
import {scale, verticalScale} from "react-native-size-matters";
import {Colors} from "../../../styles/Colors";
import {fetchCurrencySymbol} from "../../../actions/shop";
import {ActivityIndicator, ScrollView} from "react-native-web";
import {isMobile, LARGE_ICON_SIZE} from "../../../helpers/functions";
import {updateSiteSelectedLocation} from "../../../actions/minisite";
import {
    goBack,
    handleCopyLinkClick,
    handleShareClick,
    handleShareOrCopy
} from "../../../helpers/ComponentFunctionality";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {COACH_AVAILABILITY_TYPE, SPACE_AVAILABILITY_TYPE} from "../../../constants";

const ChooseService = (props) => {
    const {onSelect, tabsDisplayed, urlParamCategoryId, availabilityType} = props;
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const queryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const primaryColor = useSelector(state => state.whiteLabelProperties.primaryColor);
    const headerConfig = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.headerConfig)
    const [services, setServices] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let styles = getStyle(tabsDisplayed)
    const mobile = isMobile();
    const shareLink = `${window.location.origin}${window.location.pathname}${queryString}&scheduleType=appointment&availabilityType=${availabilityType}`

    useEffect(() => {
        if(box && location) {
            fetchServices()
        }
    }, [box, location]);

    const fetchServices = async () => {
        if((availabilityType === COACH_AVAILABILITY_TYPE && location.hasAvailability > 0)
        || (availabilityType === SPACE_AVAILABILITY_TYPE && location.hasSpaceAvailability > 0)) {
            setLoading(true)
            const servicesRes = await dispatch(fetchAppointmentTypes(box.id, location.id, availabilityType === COACH_AVAILABILITY_TYPE))
            if(urlParamCategoryId) {
                const service = servicesRes.find(service => service.id == urlParamCategoryId);
                if(service) {
                    onSelect(service)
                    return;
                }
            }
            if (servicesRes.length === 1) {
                if (headerConfig.fromBackPress) {
                    goBack(headerConfig, dispatch)
                } else {
                    onSelect(servicesRes[0])
                }
            } else {
                setLoading(false)
                setServices(servicesRes);
            }

        } else {
            const newLocation = box.locations_box.find(locationItem => locationItem.hasAvailability > 0);
            dispatch(updateSiteSelectedLocation(newLocation))
        }
    }


    return (
        <View style={[mobile ? styles.wrapper : styles.desktopWrapper]}>
            {
                loading ? <ActivityIndicator size="large" color={Colors.brandedBlue} style={{margin: '3em'}}/> :
                    <>
                            <View style={[globalSiteStyles().siteFlexRowSpaceBetween]}>
                                <AppText style={[styles.header, {textAlign: 'start', fontSize: '1.1rem', maxWidth: '100%'}]} boldFont>{t('screens:Schedule:choose-service-header', {})}</AppText>
                                <TouchableOpacity onPress={() => handleShareOrCopy(mobile, shareLink)}>
                                    <Icons8Generator name="forward-arrow" size={LARGE_ICON_SIZE}/>
                                </TouchableOpacity>
                            </View>
                        {!mobile ?
                            <View style={[styles.desktopServicesWrapper]}>
                                {
                                    services && services.map(service =>
                                        <TouchableOpacity onPress={() => onSelect(service)} key={service.id}>
                                            <View style={[globalStyles.cardContainer, styles.desktopServiceCard, {borderTopColor: service.category_color}]}>
                                                <AppText style={[globalStyles.heeboRegular, globalStyles.subTitle, styles.titleDesktop, {textAlign: 'center'}]}>{service.name}</AppText>
                                                <AppText style={[globalStyles.heeboLight, styles.titleDesktop, globalStyles.grayText, globalStyles.marginTopSm]}>{service.length} {t("timing:minutes", {})}</AppText>
                                                {service.price !== null && <AppText style={[globalStyles.heeboLight, styles.titleDesktop, globalStyles.grayText, globalStyles.marginTopSm]}>{service.price > 0 ? `${location.currency_symbol}${service.price}` : t('common:free',{})}</AppText>}
                                            </View>
                                        </TouchableOpacity>
                                    )
                                }
                            </View>
                            :
                            <View style={[mobile ? styles.servicesWrapper : styles.servicesWrapperDesktop]}>
                                {
                                    services && services.map(service =>
                                        <TouchableOpacity onPress={() => onSelect(service)} key={service.id}
                                                          style={[globalStyles.flexDirectionRow, {marginBottom: '0.5em'}]}>
                                            <View style={[
                                                globalStyles.cardsShadow,
                                                globalStyles.flexDirectionRowSpaceBetween,
                                                globalStyles.centerStartItems,
                                                mobile ? styles.serviceCard : styles.serviceCardDesktop,
                                                {borderStartColor: service.category_color || primaryColor}]}>
                                                <View
                                                    style={[mobile ? globalStyles.appPaddingHorizontal : {paddingHorizontal: '1em'}]}>
                                                    <AppText style={[globalStyles.heeboRegular, globalStyles.subTitle, !mobile && styles.titleDesktop]}>{service.name}</AppText>
                                                    <AppText style={[globalStyles.heeboLight, mobile ? globalStyles.smallTitle : styles.titleDesktop, globalStyles.grayText, globalStyles.marginTopSm]}>{service.length} {t("timing:minutes", {})}</AppText>
                                                </View>
                                                <View style={globalStyles.appPaddingHorizontal}>
                                                    <AppText
                                                        style={[globalStyles.heeboRegular, globalStyles.subTitle, !mobile && styles.titleDesktop]}>{service.price ? `${location.currency_symbol}${service.price}` : ''}</AppText>
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    )
                                }
                            </View>
                        }
                    </>
            }
        </View>
    );
}
export default ChooseService;

let getStyle = function (tabsDisplayed= true) {
    return EStyleSheet.create({
        ...Platform.select({
            web: {
                wrapper: {
                    marginHorizontal: '1em',
                    flexGrow: 1,
                },
                desktopWrapper: {
                    width: '60%',
                    maxWidth: '60%',
                    alignItems: 'center',
                    marginHorizontal: 'auto',
                    maxHeight: () => `100% - 13em`,
                    display: 'flex',
                    height: 'auto',
                    gap: '1em'

                },
                servicesWrapper: {
                    maxHeight: () => `100% - ${ EStyleSheet.value('$AppointmentTextHeaderHeight') + parseInt(tabsDisplayed ? EStyleSheet.value('$tabsMaxHeight') : 0) + parseInt(EStyleSheet.value('$mobileHeader'))}`,
                    height: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    flexGrow: 1,
                    gap: '0.2em',
                    paddingVertical: '0.5em'
                },
                servicesWrapperDesktop: {
                    maxHeight: '60%',
                    height: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    flexGrow: 1,
                    width: '100%',
                },
                header: {
                    fontSize: '1.5rem',
                    textAlign: 'center',
                    color: Colors.lightBlack,
                    maxHeight: '$AppointmentTextHeaderHeight',
                    height: '$AppointmentTextHeaderHeight',
                    alignItems: 'center',
                    display: 'flex'
                },
                serviceCard: {
                    flex: 1,
                    minHeight: '5.5em',
                    paddingVertical: '1em',
                    backgroundColor: Colors.white,
                    borderStartWidth: 3,
                    borderRadius: 2,
                },
                serviceCardDesktop: {
                    flex: 1,
                    paddingVertical: '1em',
                    backgroundColor: Colors.white,
                    borderStartWidth: 3,
                    borderRadius: 2
                },
                titleDesktop: {
                    fontSize: '0.95rem',
                    maxHeight: 100
                },
                desktopServicesWrapper: {
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    rowGap: '2em',
                    columnGap: '2em',
                    maxHeight: () => `100% - 200`,
                    overflow: 'auto'
                },
                desktopServiceCard: {
                    backgroundColor: Colors.white,
                    width: '10em',
                    height: '10em',
                    minHeight: '10em',
                    borderRadius: 3,
                    borderTopWidth: 3,
                    justifyContent: 'center',
                    alignItems: 'center',
                    shadowColor: 'rgba(0, 0, 0, 0.06)',
                    marginBottom: 'unset'
                }
            }

        })

    })
}