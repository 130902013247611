import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    TouchableOpacity,
    Dimensions,
    ActivityIndicator,
    View,
    Platform,
    StyleSheet, Text
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {Icon} from 'native-base';
import {TabView, TabBar} from 'react-native-tab-view';
import PropTypes from 'prop-types';
import {scale, verticalScale} from 'react-native-size-matters';
import moment from 'moment';
import {fetchUserBoxesAndLocations} from '../../../actions/boxes';
import styles from './styles';
import ChooseClubModal from '../../../modals/modalFlows/ChooseClubModal';
import {showModalSimple} from '../../../modals/actions';
import {selectClubId} from '../../../actions/user';
import i18n, {t} from '../../../services/i18n';
import RNPickerSelect from 'react-native-picker-select';
import Spinner from 'react-native-loading-spinner-overlay';
import ModalContainer from '../../../modals';
import ScheduleScreen from '../../../screens/Schedule/ScheduleScreen';
import DatePicker from "../../../components/DatePicker";
import {globalPickerSelectStyles, globalStyles} from "../../../styles/Global";
import {AppText} from "../../../components/Stateless/AppText";
import {StatusBarWrap} from "../../../components/Stateless/StatusBarWrap";
import {getThemeProperty, keys} from "../../../styles/Theme";
import {BIG_ICON_SIZE} from "../../../helpers/functions";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import AppointmentTypesScreen from "../../../screens/Schedule/AppointmentTypesScreen";
import {ScheduleTypes} from "../../../constants/schedule";
import {Colors} from "../../../styles/Colors";

class ScheduleContainer extends React.PureComponent {
    static navigationOptions = {
        header: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentSelectedBox: null,
            currentSelectedLocationsBox: null,
            selectedAppointmentType: null,
            index: 0,
            showDatePicker: false,
            statusbarF: true,
            isLoading: true,
            selectedBoxCategory: null
        };
        this.boxIndex = 0;
        this.locationsBoxIndex = 0;
        this.firstDay = 0;
        this.dateChanged = this.dateChanged.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this);
        this.handleMultiUserBoxes = this.handleMultiUserBoxes.bind(this);
        this.onBoxChange = this.onBoxChange.bind(this);
        this.onLocationsBoxChange = this.onLocationsBoxChange.bind(this);
        this.icon = this.icon.bind(this);
        this.getRoutes = this.getRoutes.bind(this);
        this.onBoxCategorySelect = this.onBoxCategorySelect.bind(this);
        this.displaySelectedCategory = this.displaySelectedCategory.bind(this);
    }


    async componentDidMount() {
        this.themeColor = getThemeProperty(keys.brandedGreen);
        this.didFocusListener = this.props.navigation.addListener(
            'didFocus',
            () => {
                const calendarDate = this.props.navigation.state.params?.calendarDate;
                if (calendarDate) {
                    this.dateChanged(moment(new Date(calendarDate)))
                }
                this.props.navigation.setParams({
                    tapOnTabNavigator: this.tapOnTabNavigator,
                    calendarDate: null,
                });
            },
        );
        await this.props.fetchUserBoxesAndLocations();
        this.handleMultiUserBoxes();
    }

    tapOnTabNavigator = async () => {
        this.setState({date: moment(new Date())});
    };

    componentWillUnmount() {
        this.didFocusListener.remove();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.selectedBoxId !== this.props.selectedBoxId) {
            this.setState({currentSelectedBox: this.props.selectedBoxId});
        }
    }

    handleMultiUserBoxes() {
        //TODO Bad function a lot to improve
        const user = this.props.user;
        if ((this.props.user.activeBoxes.length > 1 || this.props.user.activeBoxes.length === 0) && this.props.selectedBoxId === null) {
            this.props.showModalSimple();
            this.setState({chooseBox: true});
        } else if (this.props.user.activeBoxes.length === 1) {
            const users_boxes = user.users_boxes.find(item => item.box_fk === this.props.user.activeBoxes[0]);
            let locations_box_id = users_boxes.locations_box.id;
            this.locationsBoxIndex = this.props.boxes.find(box => box.id === this.props.user.activeBoxes[0]).locations_box.findIndex(item => item.id === locations_box_id);
            if (this.locationsBoxIndex === -1) {
                locations_box_id = this.props.boxes[0].locations_box[0].id;
                this.locationsBoxIndex = 0
            }
            this.setState({
                isLoading: false,
                currentSelectedBox: this.props.user.activeBoxes[0],
                currentSelectedLocationsBox: locations_box_id
            }, () => {
                //if it occurs we need to find this.boxIndex;
                const box = this.props.boxes.find(item => item.id === this.state.currentSelectedBox);
                const indexOf = this.props.boxes.indexOf(box);
                if (indexOf !== -1) {
                    this.boxIndex = indexOf;
                }
            });
        } else if (this.props.selectedBoxId) {
            const users_boxes = user.users_boxes.find(item => item.box_fk === this.props.selectedBoxId)
            let locations_box_id = users_boxes.locations_box.id;
            const box = this.props.boxes.find(item => item.id === this.props.selectedBoxId);
            const indexOf = this.props.boxes.indexOf(box);
            if (indexOf !== -1) {
                this.boxIndex = indexOf;
                this.locationsBoxIndex = this.props.boxes[indexOf].locations_box.findIndex(item => item.id === locations_box_id);
                if (this.locationsBoxIndex === -1) {
                    locations_box_id = this.props.boxes[indexOf].locations_box[0].id;
                    this.locationsBoxIndex = 0
                }
            }
            this.setState({
                isLoading: false,
                currentSelectedBox: this.props.selectedBoxId,
                currentSelectedLocationsBox: locations_box_id
            });
        }
    }


    dateChanged = (date) => {
        this.setState({showDatePicker: false, date: moment(date)});
    };

    toggleCalendar = () => {
        this.setState(prevState => ({showDatePicker: !prevState.showDatePicker}));
    };

    onLocationsBoxChange() {
        this.setState({currentSelectedLocationsBox: Number(this.boxes[this.boxIndex].locations_box[this.locationsBoxIndex].id)});
    }

    onBoxChange() {
        selectClubId(Number(this.boxes[this.boxIndex].id));
        this.setState({
            currentSelectedBox: Number(this.boxes[this.boxIndex].id),
            currentSelectedLocationsBox: Number(this.boxes[this.boxIndex].locations_box[this.locationsBoxIndex].id)
        }, () => this.forceUpdate());
    }


    classScheduleRoute = () => (
        <ScheduleScreen
            currentBox={this.state.currentSelectedBox}
            currentLocationsBox={this.state.currentSelectedLocationsBox}
            firstDay={this.firstDay}
            date={this.state.date}
            type={ScheduleTypes.BY_DATE}
            index={this.state.index}
        />
    );

    myClassesHistoryRoute = () => (
        <ScheduleScreen
            currentBox={this.state.currentSelectedBox}
            currentLocationsBox={this.state.currentSelectedLocationsBox}
            type={ScheduleTypes.USER_CLASSES}
            index={this.state.index}
        />
    );

    appointmentsRoute = (focus) => {
     return (
            <AppointmentTypesScreen
                currentBox={this.state.currentSelectedBox}
                currentLocationsBox={this.state.currentSelectedLocationsBox}
                selectedBoxCategory={this.state.selectedBoxCategory}
                index={this.state.index}
                focus={focus}
                onSelected={this.onBoxCategorySelect}
            />
        );
    }


    onBoxCategorySelect = (onSelectObj) => {
        this.setState({
            selectedBoxCategory: onSelectObj.boxCategories,
            boxCategoriesLength: onSelectObj.length
        })
    }

    renderDatePicker = () => {
        return (
            <DatePicker
                date={new Date()}
                onClose={(event, date) => {
                    if (date && Platform.OS.toLowerCase() !== 'ios') {
                        this.dateChanged(date);
                    } else {
                        if (event !== 'aborted') {
                            this.dateChanged(date);
                        } else {
                            this.toggleCalendar();
                        }
                    }
                }}
                onChange={d => {
                }}
            />
        );
    };

    renderTabs(box) { //TODO - RenderScene lazy loading NOT WORKING PROPERLY!
        const {index} = this.state;
        const routes = this.getRoutes(box);
        return (
            <TabView
                lazy
                bounces={false}
                swipeEnabled={false}
                navigationState={{index, routes}}
                renderScene={({route, focused}) => {
                    switch (route.key) {
                        case 'class':
                            return this.classScheduleRoute(focused);
                        case 'my-classes-history':
                            return this.myClassesHistoryRoute(focused);
                        case 'appointments':
                            return this.appointmentsRoute(focused);
                        default:
                            return null;
                    }
                }}
                onIndexChange={i => this.setState({index: i})}
                renderTabBar={props => (
                    <LinearGradient colors={[this.themeColor, this.themeColor]}>
                        <TabBar
                            {...props}
                            getLabelText={({route}) => route.title}
                            indicatorStyle={[styles.tabIndicator, {backgroundColor: this.props.whiteLabelProperties.primaryColorText}]}
                            style={styles.tabBackground}
                            renderLabel={({route, focused}) => {
                                return (
                                    <View style={{width: scale(175)}}>
                                        <AppText
                                            style={[focused ? globalStyles.heeboMedium : globalStyles.heeboRegular, {
                                                fontSize: scale(12),
                                                textAlign: 'center',
                                                color: this.props.whiteLabelProperties.primaryColorText,
                                            }]}>
                                            {route.title}
                                        </AppText>
                                    </View>
                                )
                            }
                            }
                        />
                    </LinearGradient>
                )}
                initialLayout={{width: Dimensions.get('window').width}}
            />
        );
    }


    icon(isSingle) {
        if (isSingle)
            return null;
        return <Icon name="caretdown" type="AntDesign"
                     style={{
                         color: this.props.whiteLabelProperties.primaryColorText,
                         fontSize: scale(10),
                         marginTop: 5
                     }}/>
    }


    onModalHide() {
        this.setState({chooseBox: false});
        this.handleMultiUserBoxes();
    }

    getRoutes(box) {
        let routes = [];
        const locationsBox = box.locations_box[this.locationsBoxIndex];
        if (box.scheduleTypes.hasClasses > 0) {
            routes.push({key: 'class', title: t('screens:Schedule:class-schedule', {})})
        }
        if (locationsBox.hasAvailability > 0) {
            routes.push({key: 'appointments', title: t('screens:Schedule:appointments', {})})
        }
        if (box.scheduleTypes.hasHistory > 0) {
            routes.push({key: 'my-classes-history', title: t('screens:Schedule:my-classes-history', {})})
        }
        return routes;
    }

    displaySelectedCategory() {
        const {selectedBoxCategory} = this.state;
        return (
            <View style={[globalStyles.centerItems, globalStyles.flexDirectionRow]}>
                {this.state.boxCategoriesLength > 1 ? (
                    <TouchableOpacity style={[{zIndex: 1000, maxWidth: scale(50)}]}
                                      hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
                                      onPress={() => this.setState({selectedBoxCategory: null})}>
                        <Icons8Generator name={!i18n.isRTL ? 'arrow-rtl' : 'arrow'}
                                         fill={Colors.white}/>
                    </TouchableOpacity>) : null}
                <View style={[this.state.boxCategoriesLength > 1 ? globalStyles.paddingStart : {}]}>
                    <AppText
                        style={[globalStyles.title, globalStyles.heeboMedium, {color: Colors.white}]}>{selectedBoxCategory.name}</AppText>
                    <AppText
                        style={[globalStyles.title, globalStyles.heeboMedium, {color: Colors.white}]}>{selectedBoxCategory.length} {t("timing:minutes", {}).toLowerCase()}</AppText>
                </View>
            </View>
        )
    }

    render() {
        //TOTAL MESS - Created in F Poland, override when possible.
        const {currentSelectedBox, isLoading, selectedBoxCategory} = this.state;

        const {isLoadingBoxes} = this.props;
        const {isModalVisible} = this.props.modal;
        let {boxes} = this.props;

        if (isLoadingBoxes) {
            return <View style={{flex: 1, backgroundColor: this.themeColor, maxHeight: verticalScale(125)}}><Spinner
                visible={isLoadingBoxes}/></View>
        }
        if (this.state.chooseBox === true) {
            return <ChooseClubModal
                isModalVisible={isModalVisible}
                onModalHide={() => this.onModalHide()}
            />;
        }
        if (!isLoadingBoxes && !isLoading) {
            // Crap DropDown handler
            this.boxes = boxes.map(item => {
                return {
                    ...item,
                    value: item.name,
                    locations_box: item.locations_box.map(obj => {
                            return {
                                ...obj,
                                value: obj.location
                            }
                        }
                    ),
                }
            });
            const box = this.boxes.find(item => item.id === currentSelectedBox);
            const isBoxSingle = this.boxes.length === 1;
            const isLocationsSingle = box.locations_box.length === 1;
            // const isCoachSingle = this.props.selectedBoxCoaches.length === 1;
            // const isSinglePersonalTrainer = isBoxSingle && isLocationsSingle && isCoachSingle && box.box_type_fk === PERSONAL_TRAINER_TYPE_FK;
            const isIos = Platform.OS.toLowerCase() === 'ios';
            const timezone = box.locations_box[this.locationsBoxIndex].timezone;

            if (timezone) {
                this.firstDay = timezone === 'Asia/Jerusalem' ? 0 : 1;
            }
            let {inputIOS, inputAndroid, iconContainer} = globalPickerSelectStyles;
            const temp = {
                inputIOS: {
                    ...inputIOS,
                    color: this.props.whiteLabelProperties.primaryColorText,
                },
                inputAndroid: {
                    ...inputAndroid,
                    color: this.props.whiteLabelProperties.primaryColorText,
                }
            };
            const combineStyles = StyleSheet.flatten([iconContainer, {...temp}]);
            return (

                <React.Fragment>
                    <StatusBarWrap/>
                    <LinearGradient
                        colors={[this.themeColor, this.themeColor]}>

                        <View style={styles.safeAreaViewStyle}>
                            <View style={{
                                minHeight: verticalScale(20),
                            }}>
                                {selectedBoxCategory ? this.displaySelectedCategory() : <RNPickerSelect
                                    items={this.boxes.map((option, index) => ({
                                        key: index,
                                        label: option.value,
                                        value: option.value
                                    }))}
                                    value={box.value}
                                    style={combineStyles}
                                    disabled={isBoxSingle}
                                    useNativeAndroidPickerStyle={false}
                                    onValueChange={(value, index) => {
                                        this.boxIndex = index;
                                        this.locationsBoxIndex = 0;
                                        this.setState({currentSelectedBox: this.boxes[index].id});
                                        if (Platform.OS.toLowerCase() === 'android') {
                                            this.onBoxChange();
                                        }
                                    }}
                                    onDonePress={() => {
                                        this.locationsBoxIndex = 0;
                                        this.onBoxChange();
                                    }}
                                    Icon={() => this.icon(isBoxSingle)}
                                    placeholder={{}}
                                />}
                                {isLocationsSingle || selectedBoxCategory ? null : (
                                    <RNPickerSelect
                                        items={box.locations_box.map((option, index) => ({
                                            key: index,
                                            label: option.value,
                                            value: option.value
                                        }))}
                                        value={box.locations_box[this.locationsBoxIndex].value}
                                        disabled={isLocationsSingle}
                                        style={combineStyles}
                                        useNativeAndroidPickerStyle={false}
                                        onValueChange={(value, index) => {
                                            this.locationsBoxIndex = index;
                                            this.setState({currentSelectedLocationsBox: box.locations_box[index].id});
                                            if (Platform.OS.toLowerCase() === 'android') {
                                                this.onLocationsBoxChange();
                                            }
                                        }}
                                        onDonePress={() => {
                                            this.onLocationsBoxChange();
                                        }}
                                        Icon={() => this.icon(isLocationsSingle)}
                                        placeholder={{}}
                                    />
                                )}
                            </View>
                            {
                                // && !isIos
                                <View style={globalStyles.flexDirectionRow}>
                                    {this.state.index === 0 && !isIos ?
                                        <TouchableOpacity
                                            onPress={this.toggleCalendar}
                                        >
                                            <Icons8Generator name={'calendar'} size={BIG_ICON_SIZE}
                                                             fill={this.props.whiteLabelProperties.primaryColorText}/>
                                        </TouchableOpacity> : null}
                                    {selectedBoxCategory ? null : (<TouchableOpacity
                                        style={globalStyles.paddingStartLarge}
                                        onPress={() => {
                                            this.props.navigation.navigate('ScheduleSearch', {
                                                type: 'schedule',
                                                data: {
                                                    boxes_id: this.state.currentSelectedBox,
                                                    locations_box_id: this.state.currentSelectedLocationsBox
                                                }
                                            })
                                        }}>
                                        <Icons8Generator name={'search'} width={BIG_ICON_SIZE}
                                                         height={BIG_ICON_SIZE}
                                                         fill={this.props.whiteLabelProperties.primaryColorText}/>
                                    </TouchableOpacity>)}
                                </View>

                            }

                        </View>
                    </LinearGradient>
                    {this.renderTabs(box)}
                    {this.state.showDatePicker ? this.renderDatePicker() : null}
                    {isModalVisible ? (<ModalContainer/>) : null}

                </React.Fragment>
            );
        }
        return (
            <View style={styles.containerFlexCentered}>
                <ActivityIndicator/>
            </View>
        );
    }
}

ScheduleContainer.propTypes = {
    fetchUserBoxesAndLocations: PropTypes.func,
    fetchAppointmentTypes: PropTypes.func,
    fetchCoachesByBox: PropTypes.func,
    selectedBoxId: PropTypes.number,
    boxes: PropTypes.array,
    isLoadingBoxes: PropTypes.bool,
};

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserBoxesAndLocations,
            showModalSimple,
            selectClubId,
        },
        dispatch,
    );
};

const mapStateToProps = state => {
    return ({
        selectedBoxId: state.user.selectedBoxId,
        boxes: state.boxes.locations.data,
        isLoadingBoxes: state.boxes.locations.isLoading,
        modal: state.modal,
        whiteLabelProperties: state.whiteLabelProperties,
        user: state.user.data,
        selectedBoxCoaches: state.boxes.coaches.data
    })
};

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ScheduleContainer);


