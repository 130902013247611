import React from 'react';
import {FlatList, View, RefreshControl, TouchableOpacity} from 'react-native';
import {Icon} from 'native-base';
import {ScaledSheet, scale, verticalScale} from 'react-native-size-matters';
import {SCHEDULE_ACTION} from "../../constants";
import {Colors} from "../../styles/Colors";
import ScheduleCard from "../../components/ScheduleCard"
import moment from "moment/moment";
import ChillComponent from "../ChillComponent";
import _ from 'lodash';
import {CardLoader} from "../../loaders/index";
import {globalStyles} from "../../styles/Global";
import {connect} from "react-redux";
import {SERVER_DATE_FORMAT} from "../../variableConstants";
import uuid from 'uuid';
import {AppText} from "../Stateless/AppText";
import date from "../../services/i18n/date";
import {ScheduleTypes} from "../../constants/schedule";
import {AvailabilityCard} from "../Availability/Card";
import NavigationService from "../../helpers/NavigationService";


class FlatListHandler extends React.PureComponent {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            actionTypes: ['userClasses', 'future'],
            moreData: false
        };
        this.arr = [];
        this.flatList = {};
        this.dateTypes = [ScheduleTypes.FUTURE,ScheduleTypes.PAST,ScheduleTypes.LATE_CANCELLATION,ScheduleTypes.USER_CLASSES]
        this.moreData = this.moreData.bind(this);
        this.displayDate = this.displayDate.bind(this);
        this.checkDate = this.checkDate.bind(this);
        this.getItemLayout = this.getItemLayout.bind(this);
        this.scrollToInitialPosition = this.scrollToInitialPosition.bind(this);
        this.onLayout = this.onLayout.bind(this);
        this.moreIcon = this.moreIcon.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        this.arr = [];
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.arr = [];
    }

    checkDate(date) {
        return moment(date).isSameOrAfter(moment());
    }

    getItemLayout = (data, index) => {
        const height = this.arr[index] ? this.arr[index] : 87; // 301 Is number of points that schedule card render.
        return {length: height, offset: height * index, index};
    };

    moreData(info) {
        const item = this.props.schedules[this.props.schedules.length - 1];
        if (Object.prototype.hasOwnProperty.call(item, 'scroll')) {
            this.props.fetchUserClasses(item.date_time.date,this.props.currentLocationsBox, 'down');
        }
    }

    scrollToInitialPosition() {
        if ((this.type === 'byDate' && this.props.date === moment().format(SERVER_DATE_FORMAT)) || this.type === 'userClasses') {
            let elem = _.find(this.props.schedules, (obj) => obj.booking_option !== 'past');
            if (!elem) {
                elem = _.last(this.props.schedules);
            }
            if (!elem) {
                return 0;
            }
            return _.indexOf(this.props.schedules, elem);
        }
        return 0;
    }

    displayDate(arr, index, item) {
        if (index === 0 || !(moment(arr[index].date).isSame(arr[index - 1].date))) {
            return (
                <View style={{marginBottom: verticalScale(20)}}>
                    <View
                        style={[
                            styles.dateBadgeWrapper,
                            {backgroundColor: this.checkDate(item.date_time.date) ? this.props.whiteLabelProperties.primaryColor : Colors.lightGrey},
                        ]}
                    >
                        <AppText style={[globalStyles.heeboRegular,{color: Colors.white}]}>{date.friendlyDate(item.date)}</AppText>
                    </View>
                </View>
            )
        }
        return null;

    }

    renderItem = (item, index, separators) => {
        const {type} = this.props;
        if (this.dateTypes.includes(type)) {
            return (
                <View
                    style={styles.dateHolder}
                    key={uuid.v4()}
                >
                    {this.displayDate(this.props.schedules, index, item)}
                    <View
                        onLayout={event => {
                            this.arr[index] = event.nativeEvent.layout.height;
                        }}>
                        <ScheduleCard
                            data={item}
                            reducerActionName={SCHEDULE_ACTION}
                            type={this.type}
                        />
                    </View>
                </View>
            )
        }
        if(type === ScheduleTypes.AVAILABILITIES && item !== null){
            return <AvailabilityCard coachCount={this.props.coachCount} item={item} handlePress={() => NavigationService.navigate("ScheduleSingle", {data: item})}/>
        }else{
            //type === ScheduleTypes.BY_DATE
            return (
                <View
                    key={uuid.v4()}
                    onLayout={event => {
                        this.arr[index] = event.nativeEvent.layout.height;
                    }}>
                    <ScheduleCard
                        data={item}
                        reducerActionName={SCHEDULE_ACTION}
                        type={this.type}
                    />
                </View>
            )
        }
    };


    onLayout() {
        //TODO Add onlayout condition
        const initIndex = this.scrollToInitialPosition();
        if (this.flatList && initIndex !== 0) {
            this.flatList.scrollToIndex({index: initIndex})
        }
    }

    renderHeader = () => {
        //it will show indicator at the bottom of the list when data is loading otherwise it returns null
        const item = this.props.schedules[0];
        if (item) {
            if (!this.props.isLoadingMoreClasses && Object.prototype.hasOwnProperty.call(item, 'scroll')) {
                return this.moreIcon(item, 'up');
            }
            if (!this.props.isLoadingMoreClasses) return null;
            return this.loaders();
        }
        return null;
    };
    renderFooter = () => {
        //it will show indicator at the bottom of the list when data is loading otherwise it returns null
        const item = this.props.schedules[this.props.schedules.length - 1];
        if (item) {
            if (!this.props.isLoadingMoreClasses && Object.prototype.hasOwnProperty.call(item, 'scroll')) {
                return this.moreIcon(item, 'down');
            }
            if (!this.props.isLoadingMoreClasses) return null;
            return this.loaders();
        }

    };

    loaders() {
        return (
            <View style={styles.cardContainer}>
                {
                    [...Array(2)].map((e, index) => <View style={[styles.card]} key={index}><CardLoader/></View>)
                }
            </View>
        )
    }

    moreIcon(item, direction) {
        return (
            <TouchableOpacity style={{height: scale(25), alignItems: 'center', marginVertical: scale(10)}}
                              onPress={() => this.props.fetchUserClasses(item.date_time.date,this.props.currentLocationsBox, direction)}>
                <Icon
                    name={direction === 'up' ? 'expand-less' : 'expand-more'}
                    type='MaterialIcons'
                    style={{fontSize: scale(30), color: Colors.paleblue}}

                />
            </TouchableOpacity>
        )
    }

    render() {
        const {type, schedules, isLoading} = this.props;
        if (!schedules || isLoading) {
            return (
                <View style={styles.cardContainer}>
                    {
                        [...Array(4)].map((e, index) => <View style={[styles.card]} key={index}><CardLoader/></View>)
                    }
                </View>
            )
        }
        return (
            <View style={{height: '100%', flex: 1}} onLayout={() => this.onLayout()}>
                <FlatList
                    ref={el => this.flatList = el}
                    style={[styles.container]}
                    data={schedules}
                    renderItem={({item, index, separators}) => this.renderItem(item, index, separators)}
                    keyExtractor={(item, index) => index.toString()}
                    getItemLayout={this.getItemLayout}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={this.props.onRefresh}
                        />
                    }
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[{flexGrow: 1, justifyContent: 'flex-start'},globalStyles.marginTopLarge,globalStyles.screenBottomPadding]}
                    ListEmptyComponent={<ChillComponent styles={styles.flexCenterElements}/>}
                    ListFooterComponent={this.renderFooter()}
                    ListHeaderComponent={this.renderHeader()}
                />
            </View>
        )
    }
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.viewBackground,
    },
    containerInner: {
        alignItems: 'center',
        width: '100%',
        marginBottom: scale(200),
        flex: 1,

    },
    flexCenterElements: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    cardContainer: {
        // alignSelf: 'center',
        // width: scale(320),
        // minHeight: '75@vs'
    },
    card: {
        backgroundColor: '#FFF',
        paddingTop: '23@vs',
        paddingBottom: '29@vs',
        paddingHorizontal: '32@s',
        borderRadius: 5,
        shadowOffset: {width: 0, height: 5},
        shadowColor: 'rgba(0, 0, 0, 0.08)',
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 3,
        marginTop: '20@s',
        marginBottom: '20@s',
    },
    dateBadgeWrapper: {
        borderRadius: 20,
        paddingVertical: scale(8),
        paddingHorizontal: scale(10),
        flexDirection: 'column',
    },
    dateBadgeText: {
        color: '#FFFFFF',
        fontSize: scale(12),
    },
    dateHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: scale(5)
    }
});

const mapStateToProps = state => (
    {
        whiteLabelProperties: state.whiteLabelProperties,
    }
);

export default connect(
    mapStateToProps,
    null,
)(FlatListHandler);


