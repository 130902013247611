const ACTIONS = {
    ACTION_EXPIRED_MEMBERSHIP: "expiredMembership", // 1
    ACTION_FUTURE_MEMBERSHIP: "futureMembership", // 1
    ACTION_EPIDEMIC_STATEMENT: "epidemicStatement",
    ACTION_WELCOME_CARD: "welcomeCard", // 1
    ACTION_EXPLAIN_CARD: "explainCard", // 2
    ACTION_MEDICAL_CERT_CARD: "medicalCertCard", // 2
    ACTION_RENEW_MEMBERSHIP: "renewMembership",
    ACTION_ENDING_MEMBERSHIP: "endingMembership",
    ACTION_MY_BIRTHDAY: "myBirthday", // 1
    ACTION_MY_BALLOONS: "myBalloons",
    ACTION_FRIEND_BIRTHDAY: "friendBirthday",
    ACTION_BOX_MESSAGE: "boxMessage",
    ACTION_SCHEDULE_USER_STATUS: "scheduleUserStatus",
    ACTION_USER_YEARLY_SUMMARY: "userYearlySummary",
    ACTION_LOGBOOK: "logbook",
    ACTION_SCHEDULE_UPCOMING: "scheduleUpcoming", // 2
    ACTION_SCHEDULE_CANCELLED: "scheduleCancelled", // 1
    ACTION_SCHEDULE_ENTERED_FROM_STANDBY: "scheduleEnteredFromStandby", // 3
    ACTION_THANKS_FOR_BALLOONS: 'thanksForBalloons',
    ACTION_FRIEND_SENT_BALLOON: 'friendSentBalloon',
    ACTION_FRIEND_REQUEST: "friendRequest",
    ACTION_FRIEND_REQUEST_ACCEPTED: "friendRequestAccepted",
    ACTION_SCHEDULE_INVITATION: "scheduleInvitation",
    ACTION_SCHEDULE_INVITATION_ACCEPTED: "scheduleInvitationAccepted",
    ACTION_TODAY_WORKOUT: "todayWorkout"
};

ACTIONS.PropTypes = {};

export default ACTIONS;