import React from 'react';
import {
    Dimensions,
    View,
    Text,
    TouchableOpacity,
} from 'react-native';
import {Icon} from 'native-base';
import {TabView, TabBar} from 'react-native-tab-view';
import styles from './styles';
import {Colors} from '../../../../styles/Colors';
import {scale} from 'react-native-size-matters';
import {globalStyles} from "../../../../styles/Global";
import {StatusBarWrap} from "../../../../components/Stateless/StatusBarWrap";
import {connect} from "react-redux";
import {t} from "../../../../services/i18n";
import HeaderWrapper from "../../../../components/HeaderWrapper";
import LogBookScreen from '../../../../screens/Logbook/LogBookScreen'
import {AppText} from "../../../../components/Stateless/AppText";
import {BIG_ICON_SIZE, LARGE_ICON_SIZE, MEDIUM_ICON_SIZE} from "../../../../helpers/functions";
import {Icons8Generator} from "../../../../components/Stateless/Icons8Generator";

class LogbookContainer extends React.PureComponent {

    state = {
        index: 0,
        routes: [{key: 'first', title: "Workout"}, {key: 'second', title: "Repetition Maximum"}],
        searchbar: false,
        searchbarContent: '',
        isLoading: true,

    };

    constructor(props) {
        super(props);
        this.firstDay = 0;
    }
    async componentDidMount() {

        const box = this.props.user.users_boxes.find((item => item.active === 1));
        if(box){
            const timezone = box.locations_box.timezone;
            if(timezone){
                this.firstDay = timezone === 'Asia/Jerusalem' ? 0 : 1;
            }
        }
        this.setState({isLoading: false});
    }

    renderWorkoutTab = () => {
        return (
            <WorkoutContainer/>
        );
    }

    renderRepetitionMaximumTab = () => <RepetitionMaximumContainer/>;

    renderTabs() {
        return (
            <TabView
                bounces={false}
                navigationState={this.state}
                renderScene={({route}) => {
                    switch (route.key) {
                        case 'first':
                            return this.renderWorkoutTab();
                        case 'second':
                            return this.renderRepetitionMaximumTab();
                        default:
                            return null;
                    }
                }}
                tabBarPosition='bottom'
                onIndexChange={index => this.setState({index, searchbar: false})}
                renderTabBar={props => (
                    <TabBar
                        {...props}
                        indicatorStyle={styles.tabIndicator}
                        style={styles.tabBackground}
                        renderLabel={({route, focused}) => (
                            <View
                                style={{flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                {
                                    route.key === "first" ?
                                        <Icon type='MaterialIcons' name='event-note' style={{
                                            color: focused ? Colors.slateGrey : Colors.lightGrey,
                                            fontSize: scale(14),
                                            textAlign: 'center'
                                        }}/> :
                                        <Icon type='MaterialIcons' name='fitness-center' style={{
                                            color: focused ? Colors.slateGrey : Colors.lightGrey,
                                            fontSize: scale(14),
                                            textAlign: 'center'
                                        }}/>
                                }
                                <Text style={{
                                    fontSize: scale(14), textAlign: 'center',
                                    color: focused ? Colors.slateGrey : Colors.lightGrey,
                                    marginLeft: scale(5)
                                }}>
                                    {route.title}
                                </Text>
                            </View>
                        )}
                    />
                )}
                initialLayout={{width: Dimensions.get('window').width}}
            />
        );
    }

    onSubmitText = text => {
        this.props.navigation.setParams({searchText: text});
    }


    render() {
            return (
                <React.Fragment>
                    <StatusBarWrap backgroundColor={this.props.whiteLabelProperties.logbookColor}/>
                    <HeaderWrapper
                        headerColor={this.props.whiteLabelProperties.logbookColor}
                        headerTitle={t('screens:More:logbook', {})}
                        headerArrowColor={Colors.white}
                        padding={globalStyles.appPaddingHorizontal}
                        isPop={true}>
                        <TouchableOpacity onPress={() => {
                            this.props.navigation.navigate('LogbookSearch',{type:'logbook'})
                        }}>
                            <Icons8Generator name={'search'} {...this.svg_props} width={BIG_ICON_SIZE} height={BIG_ICON_SIZE} fill={this.props.whiteLabelProperties.primaryColorText}/>
                        </TouchableOpacity>
                    </HeaderWrapper>
                    <LogBookScreen firstDay={this.firstDay}/>
                </React.Fragment>
            );
    }
}

const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    null,
)(LogbookContainer);

