import React from 'react';
import {TouchableOpacity, ActivityIndicator} from 'react-native';
import {ScaledSheet, scale, verticalScale} from 'react-native-size-matters';
import {AppText} from "../Stateless/AppText";
import {Colors} from "../../styles/Colors";
import {globalStyles} from "../../styles/Global";

class ModalButton extends React.PureComponent {
    render() {
        const buttonStyles = this.props.type ? this.props.type === 'primary' ? {backgroundColor: this.props.colors} : {
            borderWidth: 1,
            borderColor: this.props.colors,
        } : {};
        const override = this.props.overrideStyle ? this.props.overrideStyle : null;
        return (
            <TouchableOpacity disabled={this.props.submitting || this.props.disabled}
                              style={[styles.button, buttonStyles, override]}
                              onPress={this.props.onPress}>
                {
                    this.props.submitting ?
                        <ActivityIndicator color={Colors.white}/> :
                        (
                            <React.Fragment>
                                {this.props.children}
                            <AppText
                            style={[styles.text,this.props.regular ? globalStyles.heeboRegular : {}, {color: this.props.textColor},
                                this.props.textStyles ? {...this.props.textStyles} : {}]}>{this.props.text}</AppText>
                            </React.Fragment>
                        )
                }
            </TouchableOpacity>
        );
    }
}

export default ModalButton;

const styles = ScaledSheet.create({
    button: {
        minHeight: verticalScale(30),
        marginTop: verticalScale(25),
        width: scale(180),
        borderRadius: scale(3),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        // cursor: 'pointer'
    },
    text: {
        fontSize: 15,
        fontFamily: 'heebo-bold',
    },
});
