import {t} from "../services/i18n";

const invalidEmailStrings = [
    'gamil.com',
    'walka.co.il',
    'walka.com',
    '.con',
    '.om',
    '.com.il',
    '.cim',
    'gmail.co',
    'gamil.com',
    'gamai.com',
    'gmail.co.il',
    'gimal.com',
    'gnail.com',
    'gmaul.com',
    'gmal.com',
    'gmail.con',
    '1gmail.com',
    'gimal.com',
    'yahoo.co.il',
    'yhaoo.com',
    'gmail.comm',
    'gmil.com',
]

export const emailRequired = value => (value ? undefined :  t('common:validators:email', {}));

export const requiredField = value => (value ? undefined :  t('common:required', {}));

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,14}$/i.test(value)
    ? t('common:validators:emailInvalid', {})
    : undefined;

export const validateAfterEmailSymbol = value => {
    if(value){
        const str = value.split('@').pop();
        if(str.length > 2){
            if(invalidEmailStrings.includes(str))
                return t('common:validators:emailInvalid', {}) + ' - ' + str
        }
        return undefined;
    }else {
        return undefined;
    }
}

export const passwordRequired = value => (value ? undefined : t('screens:OnBoarding:passwordRequired', {}));
export const minLength = min => value =>
    value && value.length < min ? t('screens:OnBoarding:passwordMinChar', {min}) : undefined;
export const passwordMinLength = minLength(6);
export const passwordMinLength4 = minLength(4);
export const passwordsMustMatch = (value, allValues) =>
    value !== allValues.password ? t('screens:OnBoarding:passwordNotMatch', {}) : undefined;
export const required = value => (value ? undefined : t('common:required', {}));
export const lower = value => value && value.toLowerCase()
export const removeWhiteSpace = value => value && value.replace(/\s/g, '')

export const cvvValidator = value => {
    if(value.length < 3){
        return t('common:validators:cvvToShort', {})
    }else{
        return undefined;
    }
}
