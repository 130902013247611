// @flow
import * as React from 'react';
import {View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform, TouchableOpacity} from "react-native";
import PopupModal from "../Common/PopupModal";
import RegisterCta from "./RegisterCta";
import {Colors} from "../../../styles/Colors";
import LoginCta from "./LoginCta";
import {isSiteHebrew} from "../../../helpers/ComponentFunctionality";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import ForgotPasswordScreenWeb from "../../../screens/Web/ForgotPasswordScreenWeb";
import {useEffect, useState} from "react";
import RegisterHandler from "./RegisterHandler";
import {isMobile} from "../../../helpers/functions";
import {SeparatorWithText} from "../../Stateless/SeparatorWithText";
import {globalStyles} from "../../../styles/Global";

const LOGIN_OR_REGISTER = 'login/register'
const FORGOT_PASSWORD = 'forgotPassword'
const SIGN_UP = 'signUp'

const LoginOrRegisterWrapper = (props) => {
    const {show, setClose, selectedSlot, service, onRegisterSuccess, isSuccessPopup, isAppointment, gotoFullProcess} = props;
    const [step, setStep] = useState(LOGIN_OR_REGISTER);
    const [renderedStep, setRenderedStep] = useState(null);
    const [emailForResetPassword, setEmailForResetPassword] = useState(null);
    const mobile = isMobile();

    useEffect(() => {
        renderStep()
    }, [step]);

    const resetExistingMail = (email) => {
        setEmailForResetPassword(email)
        setStep(FORGOT_PASSWORD)
    }

    const handleRegisterSuccess = (res) => {
        if(res.isBooked) {
            onRegisterSuccess(res)
            setClose()
        }
    }


    const renderStep = () => {
        let comp = null;
        switch (step){
            case LOGIN_OR_REGISTER:
                comp = (
                    <View style={[mobile ? styles.wrapperMobile : styles.wrapper]}>
                        <RegisterCta style={[styles.registerSide]} onSignUpClick={() => setStep(SIGN_UP)} gotoFullProcess={gotoFullProcess}/>
                        {mobile ?
                            <SeparatorWithText textStyle={[globalStyles.helveticaSeparatorWithText, {color: Colors.thanksBorderColor}]}
                                               text="OR"
                                               color={Colors.thanksBorderColor}/>
                            :
                            <View style={[styles.separator]}/>
                        }
                        <LoginCta style={[{flex: 1}]} onLogin={setClose} onForgotPassword={() => setStep(FORGOT_PASSWORD)}/>
                    </View>
                )
                break;
            case FORGOT_PASSWORD:
                comp = (
                    <>
                        <TouchableOpacity onPress={() => setStep(LOGIN_OR_REGISTER)}
                                          style={[styles.backArrow, isSiteHebrew() && {transform: [{rotate: '180deg'}]}]}><Icons8Generator
                            name={'arrow-rtl'} size={'1em'}/></TouchableOpacity>
                        <ForgotPasswordScreenWeb show={step === FORGOT_PASSWORD} setClose={setClose} emailExternal={emailForResetPassword}/>
                    </>
                )
                break;
            case SIGN_UP:
                comp = (
                    <RegisterHandler onBack={() => setStep(LOGIN_OR_REGISTER)} onResetPassword={(email) => resetExistingMail(email)} selectedSlot={selectedSlot} service={service} onRegisterSuccess={handleRegisterSuccess} isSuccessPopup={isSuccessPopup} isAppointment={isAppointment}/>
                )
                break;
            default:
                break;
        }
        setRenderedStep(comp)
    }


    return (
        <PopupModal show={show} setClose={setClose} overrideStyles={[styles.modal,mobile && styles.modalMobile, (step === FORGOT_PASSWORD || step === SIGN_UP) && {width: '25em'}, {height: step === SIGN_UP ? 'auto' : '26em'}, (step === SIGN_UP) && {paddingVertical: 0}]}>
            {renderedStep}
        </PopupModal>
    );
};

export default LoginOrRegisterWrapper;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                width: '45em',
                paddingVertical: '2.5em',
                paddingHorizontal: '3em'
            },
            modalMobile: {
                paddingVertical: '0.5em',
                paddingHorizontal: '2em',
                height: '100%'
            },
            registerSide: {
                width: '40%'
            },
            wrapper: {
                flexDirection: 'row',
                height: '100%',
                width: '100%',
            },
            wrapperMobile: {
                flexDirection: 'column',
                height: '100%',
                width: '100%',
            },
            separator: {
                backgroundColor: Colors.thanksBorderColor,
                width: 1,
                height: '90%',
                marginHorizontal: '3em',
                alignSelf: 'center'
            },
            mobileSeparator: {
                backgroundColor: Colors.thanksBorderColor,
                width: '100%',
                height: 2,
                marginVertical: '1em',
            },
            backArrow: {
                position: 'absolute',
                top: 20,
                left: 20
            }
        }
    })
})