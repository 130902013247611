import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {Icon} from "native-base";
import {Colors} from "../../../styles/Colors";
import {isTextRTLStyles} from "../../../styles/Layout";
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import {scale} from "react-native-size-matters";
import ModalButton from "../../ModalButton";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {BIG_ICON_SIZE, HUGE_ICON_SIZE, LARGE_ICON_SIZE} from "../../../helpers/functions";


class BookingError extends React.PureComponent {


    constructor(props) {
        super(props);
        this.oneErrorMessage = this.oneErrorMessage.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    async componentDidMount() {

    }

    oneErrorMessage(error) {
        return (
            <View style={[globalStyles.flexDirectionRow, {...globalStyles.centerItems}]}>
                <AppText
                    style={[globalStyles.heeboRegular, isTextRTLStyles]}>{error.messageToUser ? error.messageToUser : error.message}
                </AppText>
            </View>
        )
    }

    handleError(error) {
        if (![514,425].includes(error.code)) {//TODO STUPID CLIENT,Cant fix for now
            return this.oneErrorMessage(error);
        } else {//Error is an array!
            if (error.messageToUser.length === 1) {
                return this.oneErrorMessage(error.messageToUser[0]);
            }
            //HANDLE BULLET RESPONSES
            return (
                error.messageToUser.map((item, i) => {
                    return (
                        <View key={i}
                              style={[globalStyles.flexDirectionRow, i !== 0 ? globalStyles.marginTopSm : {}]}>
                            <AppText
                                style={[isTextRTLStyles, globalStyles.heeboRegular]}>{i + 1}. {item.message}</AppText>
                        </View>
                    );
                })
            );
        }
    }


    button() {
        return (
            <ModalButton
                style={globalStyles.marginTopBetweenTextElements}
                onPress={() => this.props.closeDrawer()}
                type='primary'
                colors={Colors.red}
                text={t('modals:Card:got-it', {})}
                textColor={Colors.white}
            />
        )
    }

    render() {
        const {error} = this.props;
        return (
            <View>
                <View
                    style={[globalStyles.flexDirectionColumn, globalStyles.marginTopBetweenElements, globalStyles.centerItems]}>
                    <Icons8Generator name={'error'} fill={Colors.red} width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                    <AppText style={[globalStyles.heeboMedium, globalStyles.marginTopBetweenTextElements, {
                        color: Colors.red,
                        fontSize: scale(18)
                    }]}>{t('common:drawer:booking:error', {})}
                    </AppText>
                </View>
                <View style={globalStyles.marginTopBetweenTextElements}>
                    {this.handleError(error)}
                    {this.button()}
                </View>
            </View>
        );
    }
}


export default BookingError;


