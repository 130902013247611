import * as React from 'react';
import FirstPay from "../PaymentServices/firstPay";
import PaymentProcessWrap from "../PaymentServices/paymentProcessWrap";
import {isWeb} from "../../../helpers/functions";

export default class AddPaymentMethod extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const { handlePrev, createTokenSuccess, mobile, signUpFunc} = this.props;
        let {boxPaymentService} = this.props;
        //Handle bad payment service naming;
        let paymentServiceName = this.props.paymentServiceName;
        if(isWeb()){
            return <PaymentProcessWrap boxPaymentService={boxPaymentService}
                                       handlePrev={handlePrev}
                                       createTokenSuccess={createTokenSuccess}
                                       paymentServiceName={paymentServiceName.toLowerCase()}
                                       mobile={mobile}
                                       signUpFunc={signUpFunc}
            />
        }
        switch (paymentServiceName.toLowerCase()) {
            case 'firstpay_ach':
            case 'firstpay_cc':
                return <FirstPay boxPaymentService={boxPaymentService} handlePrev={handlePrev}
                                 createTokenSuccess={createTokenSuccess}/>;
            case 'stripe':
            case 'pelecard':
            case 'icredit':
                return <PaymentProcessWrap boxPaymentService={boxPaymentService}
                                           handlePrev={handlePrev}
                                           createTokenSuccess={createTokenSuccess}
                                           paymentServiceName={paymentServiceName.toLowerCase()}
                />
            default:
                return null;
        }
    }
}

