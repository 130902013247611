import * as React from "react"
import Svg, { Path, Circle } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.4 137" {...props}>
        <Path
            d="M149.15 19.35V123a12.82 12.82 0 0 1-12.8 12.8H14.05A12.82 12.82 0 0 1 1.25 123h0V19.35M149.15 19.35H1.25v-5.3A12.8 12.8 0 0 1 14 1.25h122.4a12.82 12.82 0 0 1 12.8 12.8Z"
            fill="none"
            stroke="#0c56a2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
        />
        <Circle cx={13.45} cy={10.55} r={2.6} fill="#ff3345" />
        <Circle cx={24.65} cy={10.55} r={2.6} fill="#f4c2d1" />
        <Circle cx={35.85} cy={10.55} r={2.6} fill="#00d181" />
        <Path
            fill="none"
            stroke="#ee3b49"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M9.55 29.25h10.2"
        />
        <Path
            strokeWidth={2.5}
            stroke="#f4c2d1"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            d="M9.69 38.35h9.91v86.2H9.69zM29.83 38.35h9.91v86.2h-9.91z"
        />
        <Path
            strokeWidth={2.5}
            stroke="#00d181"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            d="M49.96 38.35h9.91v86.2h-9.91z"
        />
        <Path
            strokeWidth={2.5}
            stroke="#f4c2d1"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            d="M70.09 38.35H80v86.2h-9.91zM90.23 38.35h9.91v86.2h-9.91zM110.36 38.35h9.91v86.2h-9.91zM130.49 38.35h9.91v86.2h-9.91z"
        />
        <Path
            fill="none"
            stroke="#ee3b49"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M30.95 29.25h10.2M50.25 29.25h10.2M70.25 29.25h10.2M90.85 29.25h10.1M110.15 29.25h10.2M130.35 29.25h10.2"
        />
    </Svg>
)

export default SvgComponent
