

import { createStackNavigator } from 'react-navigation-stack';
import SettingsScreen from '../../../screens/SettingsScreen';
import ChangePasswordScreen from './ChangePasswordContainer';

let SettingsNavigator;
export default SettingsNavigator = createStackNavigator({
  Settings: {
    screen: SettingsScreen,
    navigationOptions: ({ navigation }) => ({
      header: null
    }),
  },
  ChangePassword: {
    screen: ChangePasswordScreen,
    navigationOptions: ({ navigation }) => ({
      header: null
    }),
  }
},
  {
    initialRouteName: 'Settings'
  }
);

SettingsNavigator.navigationOptions = {
  header: null,
};
