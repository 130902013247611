import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Phone(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M11.84 2.988c-.77-.062-1.625.16-2.414.715-.696.485-2.196 1.676-3.598 3.024-.7.671-1.367 1.37-1.883 2.058-.52.688-.972 1.317-.945 2.23.027.82.11 3.247 1.855 6.965 1.747 3.715 5.133 8.813 11.739 15.422 6.61 6.61 11.707 9.996 15.422 11.743C35.73 46.89 38.16 46.973 38.98 47c.91.027 1.54-.426 2.227-.945.688-.52 1.387-1.184 2.059-1.883 1.343-1.402 2.535-2.902 3.02-3.598 1.105-1.574.921-3.433-.31-4.297-.773-.543-7.886-5.277-8.956-5.933-1.086-.664-2.336-.364-3.454.226-.879.465-3.257 1.828-3.937 2.22-.512-.325-2.453-1.618-6.629-5.794-4.18-4.176-5.469-6.117-5.793-6.629.39-.68 1.75-3.047 2.219-3.941.586-1.113.914-2.375.215-3.469a516.58 516.58 0 00-2.97-4.527c-1.347-2.035-2.624-3.965-2.956-4.426V4c-.434-.598-1.11-.95-1.875-1.012zm-.184 2.043c.274.035.438.145.438.145.16.222 1.562 2.324 2.906 4.355a574.596 574.596 0 012.953 4.5c.04.059.094.723-.297 1.461v.004c-.441.84-2.5 4.438-2.5 4.438l-.285.503.293.5s1.535 2.59 6.418 7.473c4.887 4.883 7.477 6.422 7.477 6.422l.5.293.503-.285s3.59-2.055 4.438-2.5c.738-.387 1.402-.336 1.48-.29.692.427 8.375 5.532 8.848 5.864.016.012.434.645-.176 1.512h-.004c-.367.527-1.57 2.054-2.824 3.36-.629.651-1.273 1.26-1.82 1.671-.547.41-1.035.54-.957.543-.852-.027-2.73-.047-6.18-1.668-3.449-1.617-8.37-4.855-14.86-11.348C11.524 25.5 8.286 20.578 6.665 17.125 5.047 13.675 5.027 11.797 5 10.949c.004.078.133-.414.543-.96.41-.548 1.016-1.196 1.668-1.825C8.52 6.91 10.043 5.707 10.57 5.34v.004c.433-.305.812-.344 1.085-.313z" />
    </Svg>
  );
}

export default SvgIcons8Phone;
