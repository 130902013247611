import React, {useEffect, useState} from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform, TextInput} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {t} from "../../../../services/i18n";

const OpenQuestion = (props) => {
    const {onSelect, answer} = props;
    const [text, setText] = useState(answer || '');

    useEffect(() => {
        if(answer) {
            setText(answer)
        }
    }, [answer]);

    const textChanged = (txt) => {
        setText(txt)
        onSelect(txt)
    }


    return (
        <>
            <TextInput multiline={true} value={text} style={[styles.input]} onChangeText={textChanged} placeholder={t('screens:DigitalForms:textAnswerPlaceholder',{})}/>
        </>
    )
}

export default OpenQuestion;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            input: {
                backgroundColor: Colors.brandedLightText,
                padding: '1em',
                borderRadius: 3,
                marginTop: '1em',
                height: '10em',
                outline: 'none'
            }
        }
    })
})
