import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8HeartWithPulse(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M14.13 4C6.34 4 0 10.367 0 18.193 0 19.52.128 20.785.354 22H2.4a18.361 18.361 0 01-.4-3.807C2 11.47 7.44 6 14.13 6c3.69 0 6.82 1.563 9.3 4.643.192.24.76.97.76.97.378.488 1.2.488 1.58 0 0 0 .564-.727.76-.968C28.972 7.605 32.202 6 35.87 6 42.56 6 48 11.47 48 18.193c0 11.6-10.541 18.689-18.238 23.864-1.806 1.214-3.44 2.538-4.766 3.642-1.059-.87-3.195-2.592-4.76-3.644C14.996 38.53 8.44 34.118 4.787 28H2.47C6.188 35.011 13.4 39.869 19.12 43.717c2.024 1.36 5.204 4.022 5.236 4.049a.951.951 0 00.618.213c.259 0 .521-.091.722-.258 1.333-1.116 3.16-2.646 5.18-4.004C38.948 38.289 50 30.857 50 18.193 50 10.367 43.661 4 35.87 4c-4.29 0-8.055 1.86-10.891 5.38C22.144 5.86 18.392 4 14.13 4zm3.36 7a1 1 0 00-.969.799l-2.8 13.607-1.785-4.758a1 1 0 00-1.83-.095L8.382 24H2a1 1 0 100 2h7a1 1 0 00.895-.553l.974-1.949 2.195 5.854a1 1 0 001.915-.15l2.459-11.948 3.078 16.926a1 1 0 001.918.18l4.054-10.544 2.076 5.536a1 1 0 001.784.177L32.555 26h4.716A2 2 0 0039 27a2 2 0 002-2 2 2 0 00-2-2 2 2 0 00-1.73 1H32a1 1 0 00-.848.47l-1.427 2.284-2.29-6.106a1 1 0 00-1.869-.007l-3.72 9.672-3.362-18.493a1 1 0 00-.994-.82z" />
    </Svg>
  );
}

export default SvgIcons8HeartWithPulse;
