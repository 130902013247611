import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Today(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M12 0c-1.094 0-2 .906-2 2v2H4c-1.07 0-2 .805-2 1.957v40.25c0 .574.332 1.129.734 1.414.403.281.84.379 1.266.379h42c.426 0 .863-.098 1.266-.379.402-.285.734-.84.734-1.414V5.957C48 4.91 47.152 4 46.082 4H40V2c0-1.094-.906-2-2-2h-2c-1.094 0-2 .906-2 2v2H16V2c0-1.094-.906-2-2-2zm0 2h2v6h-2zm24 0h2v6h-2zM4 6h6v2c0 1.094.906 2 2 2h2c1.094 0 2-.906 2-2V6h18v2c0 1.094.906 2 2 2h2c1.094 0 2-.906 2-2V6h6v7H4zm0 9h42v31H4zm28.703 5.617l-.543.84-8.144 12.637-5.344-4.836-.742-.668-1.34 1.48.738.672 7.094 6.414 9.418-14.613.543-.84z" />
    </Svg>
  );
}

export default SvgIcons8Today;
