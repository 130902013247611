import React from 'react';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../AppText";
import {TouchableOpacity, View} from 'react-native';
import {Icon} from 'native-base';
import {getIconByName} from "../../../helpers/functions";
import {Icons8Generator} from "../Icons8Generator";


export const ClassActionButton = props => {
    return (
        <View style={[props.noWrap ? {} : globalStyles.flexDirectionRow, globalStyles.cardActionButtonContainer, {justifyContent: 'space-around'}]}>
            <TouchableOpacity
                style={[globalStyles.flexDirectionRow,{justifyContent:'center',alignItems:'center'}]}
                hitSlop={{top: 10, bottom: 10, left: 80, right: 80}}
                onPress={() => props.onPress()}
            >
                <Icons8Generator name={props.iconName} fill={props.textColor}/>
                <AppText
                    style={[
                        globalStyles.heeboMedium,
                        globalStyles.paddingStart,
                        {color: props.textColor},
                        props.textOverride ? props.textOverride : {}
                    ]}
                >
                    {props.text}
                </AppText>
            </TouchableOpacity>
        </View>
    )
};



