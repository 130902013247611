import * as Calendar from 'expo-calendar';
import {t} from "./i18n";
import Toast from "react-native-root-toast";
import {getToastDefaultConfig} from "../helpers/functions";
import {Colors} from "../styles/Colors";


const calendarEvents = {

    createEvent: async function (calendar,details) {
        const permission = await this.requestPermissions();
        if (permission) {
                return await Calendar.createEventAsync(calendar.id.toString(), details);
        }
    },

    getCalenders: async function () {
        const permission = await this.requestPermissions();
        if (permission) {
            return await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
        }
        return null;
    },

    requestPermissions: async function () {
        let {status} = await Calendar.getCalendarPermissionsAsync();
        if (status !== 'granted') {
            const res = await Calendar.requestCalendarPermissionsAsync();
            status = res.status;
        }
        if(status !== 'granted'){
            const options = getToastDefaultConfig();
            Toast.show((t('common:toast:calendarNoPermission',{})), {
                backgroundColor: Colors.brandedRed,
                ...options,
                position: Toast.positions.TOP + 20
            });
        }
        return status === 'granted';
    },

    getDefaultCalendarSource: async function (primaryColor) {
        try {
            const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
            return calendars;
            if (Platform.OS.toLowerCase() === 'ios') {
                let defaultCalendars = calendars.filter(each => each.title === 'Arbox');
                if (defaultCalendars.length === 0) {
                    defaultCalendars = await this.createCalendar(calendars, primaryColor)
                }
                return defaultCalendars[0];
            } else {
                return calendars[0]
            }
        } catch (e) {
        }

    },
    createCalendar: async function (calendars, primaryColor) {
        let defaultCalendarSources = calendars.filter(each => each.source.name === 'Default');
        let defaultCalendarSource;
        //If no defaults, get first I guess!!
        if (defaultCalendarSources.length === 0) {
            defaultCalendarSource = calendars[0].source;
        } else {
            defaultCalendarSource = defaultCalendarSources[0].source;
        }
        await Calendar.createCalendarAsync({
            title: 'Arbox',
            color: primaryColor,
            entityType: Calendar.EntityTypes.EVENT,
            sourceId: defaultCalendarSource.id,
            source: defaultCalendarSource,
            name: 'Arbox',
            ownerAccount: 'personal',
            accessLevel: Calendar.CalendarAccessLevel.OWNER,
        });
        const newCalendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
        return newCalendars.filter(each => each.title === 'Arbox');
    },

    getDateRangeArray: function (from, to, format) {
      let dateRangeArray = [];
      let now = from.clone();

      while (now.isSameOrBefore(to)) {
          dateRangeArray.push(now.format(format));
          now.add(1, 'days');
      }

      return dateRangeArray;
    },

    groupClassesByDate: function (classes, dates) {
        let classesByDateArray = {}
        dates.map(date => classesByDateArray[date] = []);
        classes.map(item => {
            if(dates.includes(item.date)) {
                const temp = classesByDateArray[item.date]
                classesByDateArray[item.date] = [...temp, item]
            }
        })
        return classesByDateArray;
    },

    groupClassesByTime: function (classes, sortedArray = false) {
        let classesByTimeArray = {};
        classes.sort((a, b) => (a.time > b.time) ? 1 : (a.time === b.time) ? ((a.end_time > b.end_time) ? 1 : -1) : -1 )
        classes.map(item => {
            const hour = item.time.split(':')[0]
            const temp = classesByTimeArray[hour]
            classesByTimeArray[hour] = temp ? [...temp, item] : [item]
        })
        if(sortedArray) {
            let objToArray = Object.entries(classesByTimeArray)
            return objToArray.sort((a, b) => a[0] === b[0] ? 0 : a[0] < b[0] ? -1 : 1);
            // objToArray ---> [[07, [item1, item2, ...]],[12, [item5, item6, ...]]
        }
        return classesByTimeArray
    }

}


export default calendarEvents;

