import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';

import {getRootNavigator} from './navigation/AppNavigator';
import NavigationService from './helpers/NavigationService';
import {isWeb} from "./helpers/functions";

import {PluginEntryScreen} from "./screens/Web/PluginEntryScreen";

@connect(state => ({
    loggedIn: state.auth.loggedIn
}))
class Root extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const RootNavigator = getRootNavigator();

        return (
            <View style={[styles.container]}>
                {
                    isWeb() ?
                        <PluginEntryScreen/>
                        :
                        <RootNavigator
                            ref={navigatorRef => {
                                NavigationService.setTopLevelNavigator(navigatorRef);
                            }}
                        />}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent'
    }
});

export default Root;
