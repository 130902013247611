// @flow
import * as React from 'react';
import {Platform, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {AppText} from "../../../Stateless/AppText";
import moment from "moment";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isAMPMtimeFormat, MEDIUM_ICON_SIZE, truncateWithEllipsis} from "../../../../helpers/functions";
import {globalStyles} from "../../../../styles/Global";
import {Colors} from "../../../../styles/Colors";
import {getCapacityTextByRestrictions, hasCoachRestriction} from "../../../../helpers/DisablePages";
import TV from "../../../../svg/Icons8TvShow";
import {t} from "../../../../services/i18n";
import {TouchableOpacity} from "react-native-web";
import {DesktopAvatarImg} from "../../Common/DesktopAvatarImg";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {fetchCurrencySymbol} from "../../../../actions/shop";

export const ClassItem = (props) => {
    const {classInfo, onCardClick, price} = props;
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const currencySymbol = useSelector(state => state.shop.currencySymbol);
    const classStart = moment(classInfo.time, 'HH:mm:ss');
    const [hourStr, setHourStr] = useState('');
    const [timePeriodStr, setTimePeriodStr] = useState('');
    const disableCoachDisplay = hasCoachRestriction(classInfo);

    useEffect(() => {
        if(location) {
            if(isAMPMtimeFormat()) {
                const startTime = classStart.hour() < 12 ? classStart.format('HH:mm') : `${classStart.hour() - 12}:${moment(classStart.minutes(), 'minutes').format('mm')}`
                setHourStr(startTime);
                setTimePeriodStr(classStart.hour() < 12 ? 'am' : 'pm')
            } else {
                setHourStr(classStart.format('HH:mm'))
            }
        }

    }, [classInfo, location]);

    const getBadge = () => {
        if((classInfo.hasOwnProperty('late_cancellation') && classInfo.late_cancellation === 1)) {
            return <View style={[styles.badge, {backgroundColor: Colors.red}]}><AppText style={[styles.badgeText]}>{t('screens:Schedule:card:late-cancel',{})}</AppText></View>
        }
        else if (classInfo.user_booked) {
            return <View style={[styles.badge, {backgroundColor: Colors.brandedGreen}]}><AppText style={[styles.badgeText, {textDecoration: 'italic'}]}>{t('screens:Schedule:card:booked',{})}</AppText></View>
        }
        else if (classInfo.user_in_standby) {
            return <View style={[styles.badge, {backgroundColor: Colors.brandedGreen}]}><AppText style={[styles.badgeText, {textDecoration: 'italic'}]}>{t('screens:Schedule:card:in-waitlist',{})}</AppText></View>
        }
        else {
            return <AppText style={[styles.plus]}>+</AppText>
        }

    }

    return (
        <>
            <View style={[styles.wrapper, globalStyles.centerItems]}>
                <View style={[styles.timeSide, globalStyles.centerItems]}>
                    <AppText style={styles.hour}>{hourStr}</AppText>
                    <AppText style={styles.timePeriod}>{timePeriodStr}</AppText>
                </View>
                <TouchableOpacity style={[globalStyles.cardContainer, styles.cardWrapper]} onPress={onCardClick}>
                    <View style={[globalStyles.flexDirectionRowSpaceBetween, {alignItems: 'center'}]}>
                        <View style={[globalStyles.flexDirectionRow, {gap: '0.5em', width: '100%'}]}>
                            <AppText style={[styles.categoryName]}>{classInfo.box_categories.name}</AppText>
                            {classInfo.live_link && <TV/>}
                        </View>
                        { getBadge()}
                    </View>
                    <View style={styles.bottomInfo}>
                        {disableCoachDisplay &&
                        <View style={styles.lower}>
                            <Icons8Generator name={'name-tag'} size={'1.2em'}/>
                            <AppText style={[styles.smallInfo]}>{classInfo.coach ? classInfo.coach.full_name : '-'}</AppText>
                        </View>
                        }
                        <View style={[globalStyles.flexDirectionRowSpaceBetween]}>
                            <View style={styles.lower}>
                                <Icons8Generator name={'spots-left'} size={'1.2em'}/>
                                <AppText style={[styles.smallInfo]}>{getCapacityTextByRestrictions(classInfo)}</AppText>
                            </View>
                        </View>
                        {price !== null && currencySymbol &&
                        <View style={styles.lower}>
                            <Icons8Generator name={'ticket'} size={'1.2em'}/>
                            <AppText
                                style={[styles.smallInfo]}>{t('screens:Purchase:payNow', {price: `${currencySymbol} ${price}`})}</AppText>
                        </View>
                        }
                    </View>
                </TouchableOpacity>
            </View>
        </>

    );
};

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                flexDirection: 'row',
                width: '100%',
                gap: '0.5em',
            },
            timeSide: {
                width: '3em',
            },
            hour: {
                fontSize: '0.9rem',
                fontWeight: 'bold'

            },
            timePeriod: {
                fontSize: '0.8rem',
                fontWeight: '300',
                color: Colors.siteScheduleDay
            },
            cardWrapper: {
                backgroundColor: Colors.white,
                flex: 1,
                marginBottom: 0,
                padding: '0.8rem',
                justifyContent: 'space-between',
                position: 'relative',
                borderRadius: 8,
                gap: '1em',
                height: 'fit-content',
                minHeight: 'fit-content',
                shadowColor: 'rgba(0, 0, 0, 0.06)',
            },
            categoryName: {
                fontSize: '0.95rem',
                fontWeight: 'bold',
                width: '85%',
                maxWidth: '85%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            bottomInfo: {
                gap: '0.5em'
            },
            smallInfo: {
                fontSize: '0.8rem',
                color: Colors.lightBlack
            },
            lower: {
                flexDirection: 'row',
                gap: '0.5em'
            },
            badge: {
                borderRadius: 8.5,
                paddingHorizontal: '0.5em',
                justifyContent: 'center',

            },
            badgeText: {
                color: Colors.white,
                fontWeight: 'bold',
                fontSize: '0.7rem',
            },
            plus: {
                fontSize: '1.5rem',
                color: Colors.brandedBlue,
                fontWeight: '700',
                lineHeight: 1
            }
        }
    })
})