import * as React from "react"
import Svg, { Path,Defs } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgFillFormArbox(props) {
    return (
        <Svg viewBox="0 0 153.73 214.18" {...props}>
            <Path
                d="M126.06 27.19h8.55A13.38 13.38 0 01148 40.54v153.55a13.36 13.36 0 01-13.35 13.37H19.4A13.36 13.36 0 016 194.14V40.56a13.38 13.38 0 0113.37-13.37H28"
                fill="none"
                stroke="#0554a3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                fill="none"
                stroke="#f5c2d1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
                d="M31.6 120.99h48.6"
            />
            <Path
                d="M105.07 173l7.62 5.48 13.38-17.33m-21-14.57l7.62 5.48 13.38-17.33m-21-14.57l7.62 5.48 13.38-17.33"
                fill="none"
                stroke="#4aba7a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M31.6 173.84h48.6m-48.6-26.42h48.6"
                fill="none"
                stroke="#f5c2d1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M90.2 25.2a14.74 14.74 0 10-26.39 0H41v20.11h72.11V25.2z"
                fill="none"
                stroke="#ed3b4a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M72.89 18.68A4.12 4.12 0 1177 22.8a4.11 4.11 0 01-4.12-4.1"
                fill="#f5c2d1"
            />
        </Svg>
    )
}

export default SvgFillFormArbox
