import React, {Component} from 'react';
import {H2, View} from 'native-base';
import {LinearGradient} from 'expo-linear-gradient';
import {Colors} from '../../../styles/Colors';
import styles from '../styles';
import UserAvatarCameraEdit from '../../../components/UserAvatarCameraEdit';
import {connect} from "react-redux";
import {t} from "../../../services/i18n";
import NavigationService from "../../../helpers/NavigationService";
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../../../components/Stateless/AppText";

import {verticalScale} from 'react-native-size-matters';
import {bindActionCreators} from "redux";
import {deleteProfileImage}from "../../../actions/user";

class FirstLoginProfileScreen extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.onOpenActionSheet = this.onOpenActionSheet.bind(this);
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.user.image !== this.props.user.image) {
        //     this.forceUpdate();
        // }
    }

    onOpenActionSheet = () => {
        let options = ['Cancel', 'Camera', 'Gallery','Delete'];
        if(!this.props.user.image){
            options.pop();
        }
        const destructiveButtonIndex = 3;
        const cancelButtonIndex = 0;
        this.props.showActionSheetWithOptions(
            {
                options,
                cancelButtonIndex,
                destructiveButtonIndex,
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    NavigationService.navigate('Camera', {on_init: 'camera'});
                }
                if (buttonIndex === 2) {
                    NavigationService.navigate('Camera', {on_init: 'gallery'});
                }
                if (buttonIndex === 3) {
                    this.props.deleteProfileImage();
                }
            },
        );
    };

    render() {
        const {first_name, last_name, image} = this.props.user;
        return (
            <LinearGradient
                colors={[Colors.white, Colors.white]}
                style={styles.gradient}
            >
                <View style={[styles.container]}>
                    <AppText style={[globalStyles.marginTopBetweenElements,globalStyles.heeboMedium, styles.h1]}>{t('screens:OnBoarding:setUserPicture', {})}</AppText>
                    <AppText style={[globalStyles.marginTopBetweenElements, styles.h2,{textAlign:'center'}]}>{t('screens:OnBoarding:setUserPictureText', {})}</AppText>
                    <View style={[{alignItems: 'center', marginTop: verticalScale(75)}]}>
                        <UserAvatarCameraEdit
                            onMaskPress={this.onOpenActionSheet}
                            onPress={this.onOpenActionSheet}
                            name={`${first_name} ${last_name}`}
                            src={image}
                            size={170}
                            isEditable={true}
                            isUpdatingImage={this.props.isUpdatingImage}
                            tempImage={this.props.tempImage}
                        />
                    </View>
                    <View style={[{alignItems: 'center', marginTop: verticalScale(30)}]}>
                        <H2 style={[globalStyles.heeboMedium,globalStyles.marginTopBetweenElements, styles.h2,{fontSize:20}]}>{`${first_name} ${last_name}`}</H2>
                    </View>
                </View>
            </LinearGradient>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user.data,
    isUpdatingImage: state.picture.isLoading,
    tempImage: state.picture.tempImage,
});

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        deleteProfileImage
    }, dispatch);
};


export default connectActionSheet(connect(
    mapStateToProps,
    mapActionsToProps,
)(FirstLoginProfileScreen));



