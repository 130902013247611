import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8MembershipCard(props) {
  return (
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
          <Path d="M6 8c-2.75 0-5 2.25-5 5v24c0 2.75 2.25 5 5 5h38c1.527 0 2.781-.93 3.629-2.074C48.48 38.78 49 37.375 49 36V13c0-2.75-2.25-5-5-5zm0 2h38c1.668 0 3 1.332 3 3v23c0 .836-.379 1.93-.973 2.734C45.43 39.535 44.684 40 44 40H6c-1.668 0-3-1.332-3-3V13c0-1.668 1.332-3 3-3zm12.504 4a.996.996 0 00-.938.645l-2.523 6.625-7.094.363a.994.994 0 00-.898.691.995.995 0 00.324 1.086l5.52 4.453-1.922 6.867a1.003 1.003 0 001.507 1.114l6.02-3.867 6.023 3.867a1.001 1.001 0 001.504-1.114l-1.922-6.867 5.52-4.453a.995.995 0 00.324-1.086.994.994 0 00-.898-.691l-7.094-.363-2.523-6.625a1.003 1.003 0 00-.93-.645zm-.004 3.809l1.824 4.785a.99.99 0 00.88.64l5.128.266-3.996 3.219a1 1 0 00-.336 1.05l1.39 4.97-4.35-2.79a.989.989 0 00-1.083 0l-4.348 2.79L15 27.768a1 1 0 00-.336-1.05L10.668 23.5l5.129-.266a.99.99 0 00.879-.64zM32 33c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
      </Svg>
  );
}

export default SvgIcons8MembershipCard;
