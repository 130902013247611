// @flow
import * as React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import BrokenBikes from "../../../svg/custom/BrokenBikes";
import Icons8Cancel from "../../../svg/Icons8Cancel";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import PopupModal from "./PopupModal";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import {isMobile} from "../../../helpers/functions";

const ErrorModal = (props) => {
    const {show, setClose, title, subHeader} = props;
    const mobile = isMobile();

    return (
        <PopupModal show={show} setClose={setClose} overrideStyles={styles.modal}>
            <View style={[styles.wrapper, mobile && {flexGrow: 1, gap: '3em'}]}>
                <Icons8Cancel style={styles.closeIcon} fill={Colors.red} width={'4em'} height={'4em'}/>
                <AppText style={styles.header} boldFont>{title}</AppText>
                <AppText style={styles.subheader}>{subHeader}</AppText>
                <BrokenBikes style={styles.bike}/>
                <TouchableOpacity onPress={setClose}><AppText style={styles.closeBtn}>{t('common:drawer:close',{})}</AppText></TouchableOpacity>
            </View>
        </PopupModal>
    );
};

export default ErrorModal;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: '4em',
                paddingVertical: '2em',
                width: 'fit-content'
            },
            wrapper: {
                gap: '1em',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            },
            header: {
                fontSize: '1.3rem'
            },
            subheader: {
                fontSize: '1rem',
                width: '17em'
            },
            closeBtn: {
                color: Colors.arboxBlue,
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
            closeIcon: {
                width: '6em'
            },
            bike: {
                width: '15em'
            }
        }
    })
})