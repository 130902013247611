import * as React from "react"
import Svg, { Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgSwipeArbox(props) {
    return (
        <Svg viewBox="0 0 160.8 196.7" {...props}>
            <Path
                d="M63.8 95.5l2.7-70.7C67.1 14.3 60 7.8 52.9 9c-12.4 1.9-12.1 12.7-13.2 31.9l-1.8 35.4"
                fill="none"
                stroke="#ed3b4a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M127 182.7a55.76 55.76 0 0024.7-44.2h.1l.7-20.4"
                fill="none"
                stroke="#f5c2d1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M47.1 108l-1.9-4a16.58 16.58 0 00-30.6 12.8l.3.6s17.7 37.9 21.3 45.4c10.4 21.5 32.2 29 45.2 29.4 0 0 13.3 1.3 32.7-3"
                fill="none"
                stroke="#0554a3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M126.2 86.9l-.1 14.3M100 79.8l-.7 21.4m53.2 4.8V88a13.15 13.15 0 00-12.6-13.6h-.1a13.2 13.2 0 00-13.6 12.5v-6.1a13.15 13.15 0 00-12.6-13.6h-.1A13.13 13.13 0 00100 79.8v-5.3a12.76 12.76 0 00-12.2-13.6h-.1a12.62 12.62 0 00-8 2.4 19.53 19.53 0 00-4.2 4.9"
                fill="none"
                stroke="#4aba7a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
            <Path
                d="M12.8 29l-8.5-8.5 8.9-8.9m9.4 8.7H4.3m86.5-8.7l8.5 8.5-8.9 8.9m-9.5-8.7h18.3"
                fill="none"
                stroke="#f5c2d1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
            />
        </Svg>
    )
}

export default SvgSwipeArbox
