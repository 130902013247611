import React from 'react';
import {StyleSheet,TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../../Stateless/AppText";
import {scale} from 'react-native-size-matters';
import {BIG_ICON_SIZE} from "../../../helpers/functions";
import {Colors} from "../../../styles/Colors";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {withNavigation} from "react-navigation";
import {IconSize} from "../../../constants/iconSize";

@withNavigation
class Cart extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }


    render() {
        return (
                <TouchableOpacity style={[globalStyles.flexDirectionRow,{marginHorizontal: scale(5)}]}
                                  onPress={() => {
                                      this.props.navigation.navigate('Cart')
                                  }}>
                    <Icons8Generator name={'cart'} {...this.svg_props} size={IconSize.M}
                                     fill={this.props.fill}/>
                    <AppText style={{color:this.props.fill}}>({this.cartItemsSum()})</AppText>
                </TouchableOpacity>
        )

    }

    cartItemsSum(){
        if(this.props.cart.length > 0){
           return this.props.cart.reduce((sum, item) => {
                return sum + item.quantity
            }, 0)
        }
        return 0;
    }
}



const mapStateToProps = state => ({
    cart: state.shop.cart,
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    null,
)(Cart);


