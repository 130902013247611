import React from 'react';
import { Text, TouchableOpacity, TextInput, View } from 'react-native';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CheckBox } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import ModalButton from '../../../components/ModalButton';
import ModalSimple from '../../../components/ModalSimple';
// import { removeClassSchedule } from '../../../containers/Schedule/ScheduleContainer/actions';
import styles from './styles';
import { Colors } from '../../../styles/Colors';
import moment from 'moment';
import MembershipList from '../../../components/MembershipList';
import InviteFriendsList from '../../../components/InviteFriendsList';
import ClassInfo from '../../../components/ClassInfo';
import { isTextRTLStyles } from '../../../styles/Layout';
import { t } from '../../../services/i18n';

const MOCK_DATA = {
  date: 'October 7, 2019',
};

class RepetitionMaximumModal extends React.Component {
  state = {
    data: [],
  };

  constructor(props) {
    super(props);
  }

  closeModal() {
    this.props.showModal();
    this.setState({ data: [] });
  }

  changeValue = index => {
    return value => {
      this.setState(pS => {
        const newData = pS.data;
        newData[index] = value;
        return { data: newData };
      });
    }
  }

  saveRM = () => {
    // todo: save this data somewhere
    this.closeModal()
  }

  render() {
    const {
      isModalVisible,
      showModal,
      hideModal,
      information,
      error,
      date,
    } = this.props;


    let modalContent;
    let modalIcon;
    let modalHeadingText;
    let modalSubHeadingText;
    let modalType;
    let topBarEnabled = false;
    let headerText = '';
    let absoluteButtonText;
    let absoluteButtonOnPress;
    let absoluteButtonBorderColor;
    let absoluteButtonTextColor;
    let disablePadding;

    topBarEnabled = true;
    disablePadding = true;
    headerText = t('modals:SaveRM:title');
    modalType = 'normal';
    absoluteButtonBorderColor = Colors.brandPrimary;
    absoluteButtonTextColor = Colors.black;
    absoluteButtonText = t('modals:SaveRM:saveRM');
    absoluteButtonOnPress = this.saveRM;
    modalContent = (
      <View style={styles.contentStyle}>
        <Text style={styles.contentHeader}>{t('modals:SaveRM:header')} {MOCK_DATA.date}</Text>
        <View style={styles.contentList}>
          {this.props.information.map((entry, index) => (
            <View key={index} style={styles.contentEntry}>
              <Text style={styles.contentEntryText}>{t('modals:SaveRM:repetitionMaximum')} {index+1}</Text>
              <TextInput
                style={styles.textInput}
                onChangeText={this.changeValue(index)}
                value={this.state.data[index] || ''}
              />
            </View>
          ))}
        </View>
      </View>
    );

    return (
      <ModalSimple
        isModalVisible={isModalVisible}
        disablePadding={disablePadding}
        toggleModal={showModal}
        hideModal={hideModal}
        withTopBar={topBarEnabled}
        error={error}
        headerText={headerText}
        iconName={modalIcon}
        modalType={modalType}
        headingText={modalHeadingText}
        subHeadingText={modalSubHeadingText}
        absoluteButtonText={absoluteButtonText}
        absoluteButtonOnPress={absoluteButtonOnPress}
        absoluteButtonBorderColor={absoluteButtonBorderColor}
        absoluteButtonTextColor={absoluteButtonTextColor}
      >
        {modalContent}
      </ModalSimple>
    );
  }
}

RepetitionMaximumModal.propTypes = {
  isModalVisible: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  information: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
};

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      // removeClassSchedule,
    },
    dispatch,
  );
};

//const mapStateToProps = state => ({});

export default RepetitionMaximumModal;
