import * as React from 'react';
import {View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import LoginScreen from "../../../screens/Web/LoginScreen";
import {ErrorHandler} from "../../ErrorHandler";
import Spinner from "react-native-loading-spinner-overlay";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import GenericSuccessModal from "../Common/GenericSuccessModal";
import ProgressBar from "../Common/ProgressBar";
import {Colors} from "../../../styles/Colors";
import {optionalSteps} from "./StepOptions";
import {checkPurchaseForGroup} from "../../../helpers/functions";
import {apiAction} from "../../../helpers/HTTP";

export const flowNames = {
    EXISTING_USER_PURCHASE: 'EXISTING_USER_PURCHASE',
    BASIC_USER_REGISTER: 'BASIC_USER_REGISTER',
    GROUP_PARTICIPANT_REGISTRATION: 'GROUP_PARTICIPANT_REGISTRATION'
}

export const renderFlowWrapper = (flowName, children, mobile, specificParams) => {
    let registrationStyles = getRegistrationStyles(mobile)
    switch (flowName) {
        case flowNames.EXISTING_USER_PURCHASE:
            return <View style={[existingUserFlowStyles.wrapper, mobile && existingUserFlowStyles.mobileWrapper]}>{children}</View>
        case flowNames.BASIC_USER_REGISTER:
            if(!specificParams.onSuccessPage && specificParams.loggedIn || !specificParams.hasPayment) {
                return <AppText style={registrationStyles.bigText}>{specificParams.hasPayment ? t('screens:Register:logged-in',{}) : t('screens:Register:not-available', {})}</AppText>
            } else {
                return <>{children}</>
            }
        case flowNames.GROUP_PARTICIPANT_REGISTRATION:
            return <>{children}</>
    }
}

export const initFlowConfig = {
    EXISTING_USER_PURCHASE: (params) => initExistingUserPurchase(params),
    BASIC_USER_REGISTER: (params) => initBasicUserRegister(params),
    GROUP_PARTICIPANT_REGISTRATION: (params) => initGroupParticipantRegister(params),
}

const initExistingUserPurchase = (params) => {
    let steps = [optionalSteps.CART_DISPLAY, optionalSteps.PAYMENT_METHOD, optionalSteps.SUCCESS_PAGE]
    if(params?.hasGroup) {
        steps = [optionalSteps.CART_DISPLAY, optionalSteps.GROUP_CONNECTION, optionalSteps.PAYMENT_METHOD, optionalSteps.SUCCESS_PAGE]
    }
    return {
        steps: steps,
        name: flowNames.EXISTING_USER_PURCHASE,
        showProgressBar: false
    }
}

const initBasicUserRegister = (params) => {
    let config = {
        name: flowNames.BASIC_USER_REGISTER,
        steps: [optionalSteps.MEMBERSHIP_SELECT, optionalSteps.PERSONAL_DETAILS, optionalSteps.PAYMENT_METHOD, optionalSteps.SUCCESS_PAGE],
        showProgressBar: true,
    }
    if(params?.boxHasForm) {
        config.steps = [optionalSteps.MEMBERSHIP_SELECT, optionalSteps.PERSONAL_DETAILS, optionalSteps.DIGITAL_FORM, optionalSteps.PAYMENT_METHOD, optionalSteps.SUCCESS_PAGE]
    }
    return config
}

const initGroupParticipantRegister = (params) => {
    let steps = []
    if(!params.parentExist) {
        steps.push(optionalSteps.PERSONAL_DETAILS)
    }
    steps.push(optionalSteps.PARTICIPANT_DETAILS)
    if(params?.boxHasForm) {
        steps.push(optionalSteps.DIGITAL_FORM)
    }
    steps.push(optionalSteps.PAYMENT_METHOD, optionalSteps.SUCCESS_PAGE)
    return {
        name: flowNames.GROUP_PARTICIPANT_REGISTRATION,
        steps: steps,
        showProgressBar: true,
    }
}

const existingUserFlowStyles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                flexDirection: 'row',
                gap: '3em',
                justifyContent: 'center',
                width: '100%',
                marginHorizontal: 'auto',
                padding: '1em'
            },
            mobileWrapper: {
                gap: 0,
                flexDirection: 'column',
                marginVertical: 0,
                height: () => `100% - ${EStyleSheet.value('$mobileHeader')}`,
                overflow: 'auto',
                justifyContent: 'space-between',
                padding: 0,
                paddingHorizontal: '1em'
            },
        }
    })
})

let getRegistrationStyles = function (mobile) {
    return EStyleSheet.create({
        ...Platform.select({
            bigText: {
                fontFamily: 'coolvetica-rg',
                fontSize: '2rem',
                margin: 'auto'
            },
            wrapper: {
                flexDirection: mobile ? 'column' : 'row',
                position: 'relative',
                height: mobile ? '100%' : `100% - ${EStyleSheet.value('$desktopHeader')}`,
                backgroundColor: Colors.siteBg
            },
        })
    })
}