import React from 'react';
import {connect} from "react-redux";
import {View} from "react-native";
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import ArboxSvg from "../../../componentImages/ArboxSvg";
import {scale, verticalScale} from "react-native-size-matters";
import FitImage from 'react-native-fit-image';
import Config from '../../../../src/config/Config'
import {Asset} from 'expo-asset';


class AppLogo extends React.PureComponent {

    render() {
        return (
            <View style={globalStyles.centerItems}>
                {Config.whiteLabel.toLowerCase() === 'arbox' ? (
                    <ArboxSvg color={this.props.invert ? Colors.white : this.props.whiteLabelProperties.primaryColor}/>
                ) : (
                <FitImage
                    resizeMode="contain"
                    source={{uri: Asset.fromModule(require('../../../../assets/images/logo.png')).uri}}
                    style={[{
                        width: scale(this.props.whiteLabelProperties.logoWidth),
                        height: verticalScale(this.props.whiteLabelProperties.logoHeight)
                    }]}
                />)}
            </View>
        )
    }
}

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});

export default connect(
    mapStateToProps,
    null,
)(AppLogo);

