import {apiAction} from '../helpers/HTTP';
import {
    MEMBERSHIPS,MEMBERSHIP_SCHEDULES
} from '../constants';


export const fetchUserMembershipsByBox = (id,active = null) => async (dispatch) => {
    const actionType = MEMBERSHIPS.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const route = active ? `boxes/${id}/memberships/${active}` : `boxes/${id}/memberships/`;
        const response = await apiAction(route, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const fetchMembershipSchedules = (membership_user_id) => async (dispatch) => {
    const actionType = MEMBERSHIP_SCHEDULES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('membershipUser/schedules', 'post', {membership_user_id});
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};


