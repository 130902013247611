import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';

import ClubScreen from '../../../screens/Club/ClubScreen';
import ClubProfileScreen from '../../../screens/Club/ClubProfileScreen';
import FitnessPunchesScreen from '../../../screens/Club/FitnessPunchesScreen';

let ClubNavigator;
export default ClubNavigator = createStackNavigator({
  ClubScreen: {
    screen: ClubScreen,
    navigationOptions: ({ navigation }) => ({
      header: null
    }),
  },
  ClubProfileScreen: {
    screen: ClubProfileScreen,
    navigationOptions: ({ navigation }) => ({
      header: null
    }),
  },
  FitnessPunchesScreen: {
    screen: FitnessPunchesScreen,
    navigationOptions: ({ navigation }) => ({
      header: null
    }),
  }
});

ClubNavigator.navigationOptions = {
  header: null,
};