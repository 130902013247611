// @flow
import * as React from 'react';
import {useEffect, useState} from "react";
import moment from "moment";
import {ActivityIndicator, TouchableOpacity, View} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {AppText} from "../../Stateless/AppText";
import i18n, {t} from "../../../services/i18n";
import {Colors} from "../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import Dropdown from "../Common/Dropdown";
import {Calendar} from "react-native-calendars";
import {isLocationIL, isMobile, LARGE_ICON_SIZE} from "../../../helpers/functions";
import {updateSiteSelectedLocation} from "../../../actions/minisite";
import {apiAction} from "../../../helpers/HTTP";
import {DesktopAvatarImg} from "../Common/DesktopAvatarImg";
import {globalSiteStyles, globalStyles} from "../../../styles/Global"
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {handleShareOrCopy} from "../../../helpers/ComponentFunctionality";
import SvgFullCalendar from "../../../svg/custom/FullCalendar";
import date from "../../../services/i18n/date";
import {COACH_AVAILABILITY_TYPE, SPACE_AVAILABILITY_TYPE} from "../../../constants";

const DesktopChooseSlot = (props) => {
    const {service, closestDate, onDateChanged, slots, coachCount, handleBookPress, sharedLink, updateURLDeepLink, selectedCoachParam, spaceCount, availabilityType, selectedSpaceParam} = props;
    const siteSelectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const [dateVal, setDateVal] = useState(null);
    const [slotsByDayPeriod, setSlotsByDayPeriod] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [markedDate, setMarkedDate] = useState(dateVal);
    const [coaches, setCoaches] = useState(null);
    const [filteredSlot, setFilteredSlot] = useState(selectedSlot);
    const [noSlots, setNoSlots] = useState(false);
    const [locationsWithAppointments, setLocationsWithAppointments] = useState(null);
    const [selectedCoachId, setSelectedCoachId] = useState(null);
    const [selectedSpaceId, setSelectedSpaceId] = useState(null);
    const dispatch = useDispatch();
    const mobile = isMobile()

    useEffect(() => {
        if(availabilityType === COACH_AVAILABILITY_TYPE && coachCount && coachCount > 1) {
            fetchCoaches()
        }
    }, [coachCount, slots]);

    useEffect(() => {
        if(box) {
            const locations = box.locations_box.filter(location => availabilityType === COACH_AVAILABILITY_TYPE ? location.hasAvailability > 0 : location.hasSpaceAvailability > 0);
            setLocationsWithAppointments(locations);
        }
    }, [box]);

    useEffect(() => {
        const resDate = closestDate.format('YYYY-MM-DD');
        const marked = {};
        marked[resDate] = {selected: true, marked: true, selectedColor: Colors.lightBlack}
        setMarkedDate(marked)
        setDateVal(resDate)

    }, [closestDate]);

    useEffect(() => {
        setFilteredSlot(slots);
    }, [slots]);

    useEffect(() => {
        if(filteredSlot) {
            handleDayPeriods()
        }
    }, [filteredSlot]);

    useEffect(() => {
        //for deep link with space filter
        if(selectedSpaceParam && slots) {
            const selectedExist = siteSelectedLocation.spaces.find(space => space.id == selectedSpaceParam)
            if(selectedExist) {
                setSelectedSpaceId(selectedSpaceParam)
                filterSpaces(selectedSpaceParam)
            }
        }
    }, [selectedSpaceParam, slots]);


    const handleDayPeriods = () => {
        let morning = [];
        let afternoon = [];
        let evening = [];
        filteredSlot.map(slot => {
            const slotHour = moment(slot.time, 'HH:mm').hour();
            if(slotHour < 12) {
                morning.push(slot)
            } else if (slotHour < 18) {
                afternoon.push(slot)
            } else {
                evening.push(slot)
            }
        })
        if(filteredSlot.length === 0) {
            setNoSlots(true)
        } else {
            setNoSlots(false)
        }
        setSlotsByDayPeriod({morning, afternoon, evening});
    }

    const fetchCoaches = async () => {
        let response = await apiAction(`site/boxCoaches/${box.id}`, 'get', null);
        response.map(coach => coach.full_name = `${coach.first_name} ${coach.last_name}`)
        setCoaches(response)

        //for deep link with coach filter
        if(selectedCoachParam) {
            const selectedExist = response.find(coach => coach.id == selectedCoachParam)
            if(selectedExist) {
                setSelectedCoachId(selectedCoachParam)
                filterCoaches(selectedCoachParam)
            }
        }
    }

    const changeLocation = (locationId) => {
        const newLocation = box.locations_box.find(item => item.id == locationId)
        dispatch(updateSiteSelectedLocation(newLocation))
    }

    const filterCoaches = (coachId) => {
        updateURLDeepLink({coachId: coachId, availabilityType: availabilityType})
        let filtered
        if(coachId > 0) {
            filtered = slots?.filter(slot => slot.coach_ub_id == coachId);
        } else {
            filtered = slots
        }
        setFilteredSlot(filtered)
    }

    const filterSpaces = (spaceId) => {
        updateURLDeepLink({spaceId: spaceId, availabilityType: availabilityType})
        let filtered
        if(spaceId > 0) {
            filtered = slots.filter(slot => slot.spaces_id == spaceId);
        } else {
            filtered = slots
        }
        setFilteredSlot(filtered)
    }

    const bookAppointment = (slot) => {
        setSelectedSlot(slot)
        handleBookPress(slot)
    }

    return (
        <>
            <View style={styles.wrapper}>
                <View style={styles.filtersSide}>
                    {locationsWithAppointments?.length > 1 &&
                    <Dropdown items={locationsWithAppointments}
                              valuePropName='id'
                              labelPropName='location'
                              iconName={'location'}
                              initValue={siteSelectedLocation ? siteSelectedLocation.id : null}
                              onSelection={(value) => changeLocation(value)}
                              style={{width: '100%', backgroundColor: Colors.white, borderBottom: `1px solid ${Colors.tabSeparator}`}}
                    />}
                    {availabilityType === COACH_AVAILABILITY_TYPE && coachCount > 1 &&
                    <Dropdown items={coaches}
                              valuePropName='id'
                              labelPropName='full_name'
                              iconName={'name-tag'}
                              initValue={selectedCoachId}
                              onSelection={(value) => filterCoaches(value)}
                              selectAllOption={true}
                              defaultLabel={t('screens:Schedule:all-staff-members', {})}
                              style={{width: '100%', backgroundColor: Colors.white, borderBottom: `1px solid ${Colors.tabSeparator}`}}
                    />}
                    {availabilityType === SPACE_AVAILABILITY_TYPE && spaceCount > 1 &&
                    <Dropdown items={siteSelectedLocation.spaces}
                              valuePropName='id'
                              labelPropName='name'
                              iconName={'name-tag'}
                              initValue={selectedSpaceId}
                              onSelection={(value) => filterSpaces(value)}
                              selectAllOption={true}
                              defaultLabel={t('screens:Schedule:all-spaces', {})}
                              style={{width: '100%', backgroundColor: Colors.white, borderBottom: `1px solid ${Colors.tabSeparator}`}}
                    />}
                    <Calendar
                        onDayPress={day => {onDateChanged(day.dateString)}}
                        style={{
                            width: '100%',
                            borderWidth: 1,
                            borderColor: Colors.tabSeparator,
                        }}
                        theme={{
                            backgroundColor: Colors.white,
                            calendarBackground: Colors.white,
                        }}
                        firstDay={isLocationIL(siteSelectedLocation) ? 0 : 1} //not working
                        markedDates={markedDate}
                        renderArrow={(direction) => {
                            if (direction == "left")
                                return (
                                    <Icons8Generator name={i18n.dir === 'RTL' ? 'arrow' : 'arrow-rtl'} size={'1em'}/>
                                );
                            if (direction == "right")
                                return (
                                    <Icons8Generator name={i18n.dir === 'RTL' ? 'arrow-rtl' : 'arrow'} size={'1em'}/>
                                );
                        }}
                    />
                    <TouchableOpacity style={[globalSiteStyles().siteFlexRow]} onPress={() => handleShareOrCopy(mobile, sharedLink)}>
                        <Icons8Generator name="forward-arrow" size={LARGE_ICON_SIZE}/>
                        <AppText style={[{fontSize: '0.9rem'}]}>{t('common:copy-link', {})}</AppText>
                    </TouchableOpacity>
                </View>
                <View style={styles.slotsSide}>
                    <AppText style={styles.serviceHeader}>{`${service?.name} (${t('screens:Schedule:appointment-length', {length: service?.length})}) ${service?.price ? `- ${service.price}${siteSelectedLocation.currency_symbol}` : ''}`}</AppText>
                    {
                        slotsByDayPeriod ?
                            <>
                                <View style={styles.daySections}>
                                    {
                                        noSlots ?
                                            <View style={[globalStyles.centerItems, {gap: '1em', margin: 'auto'}]}><SvgFullCalendar /><AppText style={{color: Colors.iconEmptyStateColor, fontSize: '1.2rem'}}>{t('screens:Schedule:no-slots-open',{})}</AppText></View>
                                            :
                                            Object.keys(slotsByDayPeriod).map(period =>
                                                slotsByDayPeriod[period].length ?
                                                    <View style={styles.section} key={period}>
                                                        <AppText style={styles.timeCardText}>{t(`common:${period}`, {})}</AppText>
                                                        <View style={[(coachCount > 1 || spaceCount > 1) ? styles.sectionContentMultiCoaches : styles.sectionContentSingleCoach]}>
                                                            {slotsByDayPeriod[period].map((slot, i) =>
                                                                <TouchableOpacity onPress={() => bookAppointment(slot)} style={[styles.timeCard, (coachCount > 1 || spaceCount > 1) && styles.timeCardMultiCoaches, (selectedSlot === slot) && styles.selected]} key={`${period}-${i}`}>
                                                                    <AppText style={[styles.timeCardText,{fontWeight: 'bold'}, (coachCount > 1 || spaceCount > 1) && {width: '4em', textAlign: 'center'}, (selectedSlot === slot) && styles.selectedText]}>{date.timeFormat(slot.time, 'LT', false, false)}</AppText>
                                                                    {
                                                                        coachCount > 1 && slot.coach && availabilityType === COACH_AVAILABILITY_TYPE &&
                                                                        <>
                                                                            <View style={styles.verticalSeparator}/>
                                                                            <DesktopAvatarImg imgSrc={slot.coach.image} size={'1.5em'} credentials={(slot.coach.first_name ? slot.coach.first_name[0] : '') + (slot.coach.last_name ? slot.coach.last_name[0] : '')}/>
                                                                            <AppText style={[styles.timeCardText, (selectedSlot === slot) && styles.selectedText]}>{slot.coach.first_name} {slot.coach.last_name}</AppText>
                                                                        </>
                                                                    }
                                                                    {
                                                                        spaceCount > 1 && slot.space && availabilityType === SPACE_AVAILABILITY_TYPE &&
                                                                        <>
                                                                            <View style={styles.verticalSeparator}/>
                                                                            <AppText style={[styles.timeCardText, (selectedSlot === slot) && styles.selectedText]}>{slot.space.name}</AppText>
                                                                        </>
                                                                    }
                                                                </TouchableOpacity>)}
                                                        </View>
                                                    </View>
                                                    : null
                                            )
                                    }
                                </View>
                            </>
                            :
                            <ActivityIndicator size="large" color={Colors.brandedBlue} style={{margin: '3em'}}/>
                    }
                </View>
            </View>
        </>
    );
};

export default DesktopChooseSlot;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                paddingTop: '3em',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: '4em',
                height: '100%',
                overflow: 'auto'
            },
            filtersSide: {
                maxWidth: 250,
                width: '100%',
                gap: '0.5em',
                // marginVertical: 'auto'
            },
            slotsSide: {
                gap: '2em',
                maxWidth: 350,
                width: 350,
            },
            serviceHeader: {
                fontSize: '1.5rem',
            },
            daySections: {
                height: 500,
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: 500,
                gap: '1em'
            },
            section: {
                gap: '0.5em',
            },
            sectionContentSingleCoach: {
                flexWrap: 'wrap',
                flexDirection: 'row',
                gap: '0.5em'
            },
            sectionContentMultiCoaches: {
                flexWrap: 'unset',
                flexDirection: 'column',
                gap: '0.5em',
                width: 350
            },
            sectionText: {
                fontSize: '0.7rem',
                fontWeight: 'bold'
            },
            timeCard: {
                width: 110,
                height: 40,
                minHeight: 40,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: Colors.white,
                borderRadius: 2,
                borderColor: Colors.tabSeparator,
                borderWidth: 1,
                flexDirection: 'row',
                gap: '1em',
            },
            timeCardMultiCoaches: {
                width: '100%',
                justifyContent: 'start',
                paddingHorizontal: '1em'
            },
            timeCardText: {
                fontSize: '0.9rem',
            },
            selected: {
                borderColor: Colors.brandedBlue,
            },
            selectedText: {
                color: Colors.brandedBlue,
                fontWeight: 'bold'
            },
            bookBtn: {
                borderRadius: 2,
                width: 150,
                minWidth: 150,
                minHeight: 0,
                marginTop: 0
            },
            verticalSeparator: {
                height: '60%',
                width: 1,
                backgroundColor: Colors.graySeparator
            }
        }
    })
})