
import { StyleSheet } from 'react-native';
import { Colors } from '../../../styles/Colors';
import { scale } from 'react-native-size-matters';
import { getIsRTLStyle, isTextRTLStyles } from '../../../styles/Layout';

export default StyleSheet.create({
  modalButtons: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalContentWrapper: {
    width: '100%',
    paddingHorizontal: 30,
  },
  modalContentSingle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inviteFriends: {
    flexDirection: getIsRTLStyle('row', 'row-reverse'),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: scale(20),
  },
  inviteFriendsText: {
    marginHorizontal: scale(5),
    color: Colors.brandPrimary,
  },
  membershipListWrapper: {
    width: '100%',
  },
  membershipListText: {
    fontSize: scale(10),
    fontFamily: 'heebo-bold',
    color: Colors.modalFontColor,
    ...isTextRTLStyles,
  },
  addToCalendarText: {
    marginLeft: 20,
    fontSize: scale(12),
    ...isTextRTLStyles,
  },
  addToCalendarWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    fontSize: scale(10),
    ...isTextRTLStyles,
  },
  bold: {
    fontFamily: 'heebo-bold',
    fontWeight: '600',
    color: Colors.modalFontColor,
    ...isTextRTLStyles,
  },
});
