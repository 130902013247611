import React from 'react';
import {View, Text,StyleSheet} from 'react-native';
import {scale} from 'react-native-size-matters';
import {globalStyles} from "../../styles/Global";
import i18n, {t} from "../../services/i18n";
import {isTextRTLStyles} from "../../styles/Layout";
import {Colors} from "../../styles/Colors";
import {ClassDetails} from "../Stateless/ClassDetails";
import {AppText} from "../Stateless/AppText";

class ClassInfo extends React.Component {
    render() {
        const {box_categories} = this.props.data;
        return (
           <View>
               <ClassDetails noClassName={true} data={this.props.data}/>
               {box_categories.bio && this.props.showDescription ? (<View style={[globalStyles.flexDirectionColumn,globalStyles.marginTopBetweenTextElements]}>
                   <AppText style={[globalStyles.heeboMedium,{color: Colors.black}]}>{t('common:drawer:booking:description',{})}</AppText>
                   <AppText style={[styles.description,globalStyles.marginTopSm]}>{box_categories.bio}</AppText>
               </View>) : null}
           </View>
        );
    }
}


export default ClassInfo;


const styles = StyleSheet.create({
    classInfoWrapper: {
        flexDirection: 'column',
        paddingBottom: scale(25),
    },
    topInfo: {
        flexDirection: 'row',
        width: '100%',
    },

    topInfoText: {
        flexDirection: 'column',
        flex: 1,
        ...isTextRTLStyles,
        marginRight: i18n.isRTL ? 0 : scale(10),
        marginLeft: i18n.isRTL ? scale(10) : 0,
    },
    classTitle: {
        fontSize: scale(21),
        color: '#000000',
        ...isTextRTLStyles,
        marginBottom: scale(2),
    },
    coachTitle: {
        fontSize: scale(18),
        color: '#000000',
        ...isTextRTLStyles,
        marginBottom: scale(3),
    },
    coachDescription: {
        fontSize: scale(12),
        ...isTextRTLStyles,
        color: Colors.lightGrey,
    },
    shortDescription: {
        fontSize: scale(12),
        color: Colors.lightGrey,
        ...isTextRTLStyles,
        textAlign: 'justify',
    },
    description: {
        fontSize: scale(12),
        textAlign: 'justify',
    },
    divider: {
        borderWidth: 0.5,
        borderColor: Colors.lightGrey,
    },
});
