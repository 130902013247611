import * as React from 'react'
import {Platform, StyleSheet, Text, View} from 'react-native'
import {t} from "../../../services/i18n";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import {useState} from "react";
import EStyleSheet from "react-native-extended-stylesheet";

const ReadMore = ({ children, numLines = 2, style }) => {
    const [showAllBio, setShowAllBio] = useState(false);

    return (
        <>
            {
                showAllBio ?
                    <View style={styles.wrapper}>
                        <AppText style={style}>{children}</AppText>
                        <AppText onPress={() => setShowAllBio(false)} style={styles.btn}>
                            {t('screens:ScheduleSingle:readLess', {})}
                        </AppText>
                    </View>
                    :
                    <View style={styles.wrapper}>
                        <AppText style={style} numberOfLines={numLines}>{children}</AppText>
                        <AppText onPress={() => setShowAllBio(true)} style={styles.btn}>
                            {t('screens:ScheduleSingle:readMore', {})}
                        </AppText>
                    </View>
            }
        </>
    )
}

export default ReadMore;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            btn: {
                fontSize: '0.8rem',
                color: Colors.arboxBlue,
                fontWeight: 'bold'
            },
            wrapper: {
                flexDirection: 'column',
                // flexWrap: 'wrap',
                flexShrink: 1,
                width: 'fit-content',
                height: '100%'
            }
        }
    })
})