import React from 'react';
import {connect} from 'react-redux';
import {globalStyles} from "../../../styles/Global";
import {Field, reduxForm, submit} from 'redux-form';
import {Form} from "native-base";
import FloatingTextInput from "../../Stateless/FloatingTextInput";
import {t} from "../../../services/i18n";
import {cvvValidator, required} from "../../../helpers/Validators";
import {Button, View} from "react-native";
import {scale, verticalScale} from "react-native-size-matters";
import {Colors} from "../../../styles/Colors";
import ModalButton from "../../ModalButton";
import {InputWrapWithBorders} from "../../Stateless/InputWrapWithBroders";
import {AppText} from "../../Stateless/AppText";
import NumericInput from "react-native-numeric-input";
import uuid from 'uuid';
//TODO Change naming, it is user digital form, not create token form.


class AdditionalPaymentDetailsForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            installments: 1
        }
    }


    render() {
        const {handleSubmit, dispatch, maxInstallments} = this.props;
        return (
            <View>
                <Form onSubmit={handleSubmit} style={globalStyles.flex}>
                    <Field
                        styleContainer={{width:scale(200)}}
                        rowOverride={{alignItems:'center'}}
                        name="cvv"
                        autoFocus
                        type="number"
                        keyboardType="number-pad"
                        component={FloatingTextInput}
                        maxLength={4}
                        placeholder={t('common:forms:additionalPaymentDetailsForm:cvv', {})}
                        validate={[required, cvvValidator]}
                    />
                    {maxInstallments > 1 ?
                        <InputWrapWithBorders>
                            <AppText
                                style={[globalStyles.heeboMedium,globalStyles.marginBottomVS10]}>
                                {t('common:forms:additionalPaymentDetailsForm:maxInstallments',{max:maxInstallments})}
                            </AppText>
                            <NumericInput
                                key={uuid.v4()}
                                value={this.state.installments}
                                onChange={value => this.setState({installments: value},() => this.props.updateParent(value))}
                                onLimitReached={(isMax, msg) => console.log(isMax, msg)}
                                totalWidth={scale(205)}
                                totalHeight={50}
                                iconSize={25}
                                step={1}
                                minValue={1}
                                maxValue={maxInstallments}
                                valueType='real'
                                textColor={this.props.whiteLabelProperties.logbookColor}
                                iconStyle={{color: this.props.whiteLabelProperties.logbookColor}}
                                containerStyle={{backgroundColor: Colors.white}}
                                rightButtonBackgroundColor={Colors.white}
                                leftButtonBackgroundColor={Colors.white}/>

                        </InputWrapWithBorders>
                        : null}
                    <ModalButton
                        onPress={() => {
                            dispatch(submit('additionalPaymentDetailsForm'))
                        }}
                        type='primary'
                        colors={this.props.whiteLabelProperties.primaryColor}
                        text={t('screens:Purchase:buyNow', {})}
                        overrideStyle={{width: '90%', minHeight: verticalScale(30)}}
                        textColor={Colors.white}
                    />
                </Form>

            </View>
        )
    }

}


const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
});

AdditionalPaymentDetailsForm = reduxForm({
    form: 'additionalPaymentDetailsForm',
    enableReinitialize: true,
})(AdditionalPaymentDetailsForm);
// You have to connect() to any reducers that you wish to connect to yourself
AdditionalPaymentDetailsForm = connect(
    mapStateToProps,
)(AdditionalPaymentDetailsForm);

export default AdditionalPaymentDetailsForm;

