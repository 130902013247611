import React from 'react';
import {TextInput, TouchableOpacity} from 'react-native';
import {View, Text, Switch, Icon} from 'native-base';
import RNPickerSelect from 'react-native-picker-select';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import styles, {listStyle} from './styles';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {AppText} from "../Stateless/AppText";
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";

class SettingsRow extends React.PureComponent {
    icon(){
        return (
            <Icon name="caretdown" type="AntDesign"
                  style={{color: Colors.black, fontSize: scale(10),marginTop:verticalScale(2),marginHorizontal: scale(5)}}/>
        )
    }

    renderInput = (type, value, onChange, listValues, disabled = false) => {
        switch (type) {
            case 'PASSWORD':
                /* apparently no secureTextEntry here? */
                return <TextInput style={styles.textInput} value={value} onChangeText={onChange} editable={!disabled}/>;
            case 'LIST':
                return (
                    <RNPickerSelect
                        style={listStyle}
                        onValueChange={onChange}
                        onDonePress={this.props.onDonePress}
                        items={listValues}
                        useNativeAndroidPickerStyle={false}
                        value={value}
                        Icon={() => this.icon()}
                        placeholder={{}}
                    />
                );
            case 'BUTTON':
                return (
                    <Icons8Generator name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>
                );
            default:
                return <AppText style={styles.staticText}>{this.props.value}</AppText>;
        }
    };

    renderRow = () => {
        return (
            <View>
                <View style={styles.upperBox}>
                    <View style={styles.iconAndName}>
                        {this.props.icon && !this.props.rightIcon ?
                            <Icons8Generator name={this.props.icon}/> : null}
                        <AppText style={[styles.title,globalStyles.paddingStart]}>{this.props.title}</AppText>
                    </View>
                    <View style={styles.rightBox}>
                        {this.renderInput(this.props.type, this.props.value, this.props.onChange, this.props.listValues, this.props.disabled)}
                        {this.props.type === 'PASSWORD' ? (<Icons8Generator name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>): null}
                        {/*{this.props.icon.png && this.props.rightIcon ? (*/}
                            {/*<TouchableOpacity onPress={this.props.onPress}>*/}
                                {/*<Icon name={this.props.icon.png} style={styles.iconAlt} type={this.props.iconType}/>*/}
                            {/*</TouchableOpacity>*/}
                        {/*) : null}*/}
                    </View>
                </View>
                <View style={styles.descBox}>
                    {this.props.description ? <Text style={styles.description}>{this.props.description}</Text> : null}
                </View>
            </View>
        );
    }

    render() {
        const finalStyle = !this.props.grey ? styles.container : styles.greyContainer;
        if (this.props.type === 'BUTTON') {
            return (
                <TouchableOpacity onPress={this.props.onPress} style={finalStyle}>
                    {this.renderRow()}
                </TouchableOpacity>
            );
        }
        if (this.props.onPress) {
            return (
                <TouchableOpacity style={finalStyle} onPress={this.props.onPress}>
                    {this.renderRow()}
                </TouchableOpacity>
            );
        }
        return <View style={finalStyle}>{this.renderRow()}</View>;
    }
}

SettingsRow.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconType: PropTypes.string,
    grey: PropTypes.bool,
    description: PropTypes.string,
    type: PropTypes.oneOf(['SWITCH', 'TEXT', 'EMAIL', 'PASSWORD', 'LIST', 'BUTTON']),
    listValues: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onDonePress: PropTypes.func,
    onPress: PropTypes.func,
    buttonTitle: PropTypes.string,
    value: PropTypes.any,
};

export default SettingsRow;
