import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Clock(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm0 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25 13.39 4 25 4zm-.016 2.986A1 1 0 0024 8v14.174A3 3 0 0022 25a3 3 0 00.295 1.291l-6.002 6.002a1 1 0 101.414 1.414l6.002-6.002A3 3 0 0025 28a3 3 0 003-3 3 3 0 00-2-2.824V8a1 1 0 00-1.016-1.014z" />
    </Svg>
  );
}

export default SvgIcons8Clock;
