import {WHITE_LABEL_PROPERTIES} from "../constants";


const initialState = {
    brandedGreen: '#00D181',
    brandedGreenRGBA: 'rgba(33, 208, 131, 0.9)',
    brandedBlue: '#0052A6',
    mainTabColor: '#00D181',
    logbookColor: '#FF3345',
    logoUrl: null,
    logoWidth: 200,
    logoHeight: 100,
    primaryColorText: '#FFFFFF',
    secondaryColorText: '#FFFFFF',
    displayName: "Arbox"
};

const whiteLabelPropertiesReducer = (state = initialState, action) => {
    const whiteLabelProperties = WHITE_LABEL_PROPERTIES.toUpperCase(); // just MAKE SURE IT IS UPPER
    switch (action.type) {
        case `${whiteLabelProperties}_PENDING`:
            return {
                ...state,
            };
        case `${whiteLabelProperties}_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data.properties
            };
        }
        case `${whiteLabelProperties}_FAILED`: {
            return {
                ...state,
            };
        }

        default:
            return state;
    }
};

export default whiteLabelPropertiesReducer;

