import {
    REFERER,
    SELECTED_LOCATION,
    SITE_BOX,
    SITE_LANGUAGE,
    SITE_WHITELABEL,
    SITE_WHITELABEL_LINK,
    TOGGLE_SITE_REFETCH,
    SITE_REFERER,
    HEADER_CONFIG,
    ISRAEL_PAYMENT_RES,
    ISRAEL_PAYMENT_RES_CLEANUP,
    ADD_BOX_TO_SITE_DATA,
    CHANGE_ACTIVE_BOX,
    LOGOUT_BOX_USER,
    LOGIN_BOX_USER, UPDATE_REGISTER_PROCESS_STEP, UPDATE_QUERY_STRING, UPDATE_USER_CREATED
} from "../constants";
import {initialStateAuth} from "./auth";

const createBoxObj = (identifier, whitelabel, referer) => {
    return {
        referer: referer || SITE_REFERER,
        box: {
            isLoading: false,
            data:null,
            error: null
        },
        whitelabel: whitelabel || 'Arbox',
        siteSelectedLocation: null,
        language: null,
        appLink: {
            isLoading: false,
            data:null,
            error: null
        },
        siteReFetch: false,
        headerConfig: {
            processName: null,
            stepName: null,
            bgColor: null,
            headerText: null,
            subHeaderText: null,
            showTabs: true,
            steps: null,
            fromBackPress: false
        },
        israelPaymentRes: null,
        identifier: identifier,
        auth: initialStateAuth,
        registerStepNumber: 0,
        queryString: null,
        userCreatedTimePeriod: null
    }
}

const initialState = {
    activeIdentifier: null,
    boxes: {},
};

const minisiteReducer = (state = initialState, action) => {
    let tempBoxes = {...state.boxes}
    const activeIdentifier = state.activeIdentifier;

    switch (action.type) {
        case ADD_BOX_TO_SITE_DATA:
            const newBoxInfo = action.payload;
            const isExist = Object.keys(state.boxes)?.some(identifier => identifier === newBoxInfo.identifier)
            if(isExist) return {...state, activeIdentifier: newBoxInfo.identifier};
            else {
                const newBox = createBoxObj(newBoxInfo.identifier, newBoxInfo.whitelabel, newBoxInfo.referer)
                tempBoxes[newBoxInfo.identifier] = newBox;
            }
            return {
                ...state,
                boxes: {...tempBoxes},
                activeIdentifier: newBoxInfo.identifier
            };
        case CHANGE_ACTIVE_BOX:
            return {
                ...state,
                activeIdentifier: action.payload
            }
        case REFERER:
            tempBoxes[activeIdentifier].referer = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${SITE_BOX}_PENDING`:
            tempBoxes[action.payload].box = {
                ...tempBoxes[action.payload].box,
                isLoading: true
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${SITE_BOX}_SUCCESS`:{
            const validLocation = state.siteSelectedLocation && action.payload.locations_box.filter(location => location.id === state.siteSelectedLocation.id) > 0;
            tempBoxes[action.payload.external_url_id].box = {
                ...tempBoxes[action.payload.external_url_id].box,
                data: action.payload,
                isLoading: false
            }
            tempBoxes[action.payload.external_url_id].siteSelectedLocation = validLocation ? state.siteSelectedLocation : action.payload.locations_box[0]
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        case `${SITE_BOX}_FAILED`:
            tempBoxes[action.payload.identifier].box = {
                ...tempBoxes[action.payload.identifier].box,
                error: action.payload.error,
                isLoading: false
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        case SITE_WHITELABEL:
            tempBoxes[activeIdentifier].whitelabel = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            };
        case SELECTED_LOCATION:
            tempBoxes[activeIdentifier].siteSelectedLocation = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            };
        case SITE_LANGUAGE:
            tempBoxes[activeIdentifier].language = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${SITE_WHITELABEL_LINK}_PENDING`:
            tempBoxes[activeIdentifier].appLink = {
                ...tempBoxes[activeIdentifier].appLink,
                isLoading: true
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${SITE_WHITELABEL_LINK}_SUCCESS`:{
            tempBoxes[activeIdentifier].appLink = {
                ...tempBoxes[activeIdentifier].appLink,
                data: action.payload,
                isLoading: false
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        case `${SITE_WHITELABEL_LINK}_FAILED`:
            tempBoxes[activeIdentifier].appLink = {
                ...tempBoxes[activeIdentifier].appLink,
                error: action.payload,
                isLoading: false
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        case TOGGLE_SITE_REFETCH:
            tempBoxes[activeIdentifier].siteReFetch = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            }
        case HEADER_CONFIG:
            tempBoxes[activeIdentifier].headerConfig = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            }
        case ISRAEL_PAYMENT_RES:
            tempBoxes[activeIdentifier].israelPaymentRes = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            }
        case ISRAEL_PAYMENT_RES_CLEANUP:
            tempBoxes[activeIdentifier].israelPaymentRes = null
            return {
                ...state,
                boxes: tempBoxes,
            }
        case `${LOGOUT_BOX_USER}_SUCCESS`:
            tempBoxes[activeIdentifier].auth = initialStateAuth
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${LOGOUT_BOX_USER}_FAILED`:
            tempBoxes[activeIdentifier].auth = initialStateAuth
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${LOGIN_BOX_USER}_PENDING`:
            tempBoxes[activeIdentifier].auth = {
                ...tempBoxes[activeIdentifier].auth,
                isAuthenticating: true,
                loggedIn: false,
                error: null
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        case `${LOGIN_BOX_USER}_SUCCESS`: {
            tempBoxes[activeIdentifier].auth = {
                ...tempBoxes[activeIdentifier].auth,
                isAuthenticating: false,
                loggedIn: true,
                data: action.payload.data,
                token: action.payload.data.token
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        case `${LOGIN_BOX_USER}_FAILED`: {
            tempBoxes[activeIdentifier].auth = {
                ...tempBoxes[activeIdentifier].auth,
                isAuthenticating: false,
                loggedIn: false,
                error: action.payload.error,
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        case UPDATE_REGISTER_PROCESS_STEP: {
            if(activeIdentifier && tempBoxes[activeIdentifier]) {
                tempBoxes[activeIdentifier].registerStepNumber = action.payload
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        case UPDATE_QUERY_STRING: {
            if(activeIdentifier && tempBoxes[activeIdentifier]) {
                tempBoxes[activeIdentifier].queryString = action.payload
            }
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        case UPDATE_USER_CREATED:{
            tempBoxes[activeIdentifier].userCreatedTimePeriod = action.payload
            return {
                ...state,
                boxes: tempBoxes,
            };
        }
        default:
            return state;
    }
};

export default minisiteReducer;

