import React from 'react';
import {View,Platform} from 'react-native';
import {getStatusBarHeight} from 'react-native-status-bar-height';
import {
    StatusBar,
} from 'react-native';
import {getThemeProperty, keys} from "../../../styles/Theme";
import {isWeb} from "../../../helpers/functions";


export const StatusBarWrap = props => {
    const {backgroundColor,barStyle = "light-content"} = props;
    if(isWeb())
        return null;
    return (
        <View style={{backgroundColor: backgroundColor ? backgroundColor : getThemeProperty(keys.brandedGreen),
            height: Platform.OS === 'ios' ? getStatusBarHeight() : 0}}>
            <StatusBar
                backgroundColor={backgroundColor ? backgroundColor : getThemeProperty(keys.brandedGreen)}
                translucent={false}
                barStyle={barStyle}
            />
        </View>
    )
};

