import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Ok(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2C12.318 2 2 12.318 2 25c0 12.683 10.318 23 23 23 12.683 0 23-10.317 23-23C48 12.318 37.683 2 25 2zm10.827 14.562L24.316 33.525l-8.997-8.349a1 1 0 111.36-1.466l7.29 6.764 10.203-15.036a1.001 1.001 0 011.655 1.124z" />
    </Svg>
  );
}

export default SvgIcons8Ok;
