import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.21 87.11" {...props}>
        <Path
            fill="none"
            stroke="#0052a6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
            d="M78.44 49.08v29.05l-7.83 7.84M86.09 85.99l-7.75-7.75"
        />
        <Path
            fill="none"
            stroke="#00d181"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
            d="M70.63 85.97V49.08M30.39 45.09h55.7v40.9h-55.7z"
        />
        <Path
            fill="none"
            stroke="#0052a6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
            d="M23.02 85.99H1.13V15.75h54.25v9.27"
        />
        <Path
            fill="none"
            stroke="#f5c2d1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
            d="M36.54 57.72v-1.46M36.54 78.78V61.46M7.5 32.67v-1.46M7.5 53.73V36.41"
        />
        <Path
            d="M60.32 41.47V39.6a10 10 0 1 0-20 0v1.87"
            fill="none"
            stroke="#0052a6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
        />
        <Path
            d="M70.29 41.47V39.6a10 10 0 1 0-20 0v1.87"
            fill="none"
            stroke="#0052a6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
        />
        <Path
            d="M33.55 13v-1.9a10 10 0 1 0-20 0V13"
            fill="none"
            stroke="#ff3345"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
        />
        <Path
            d="M43.52 13v-1.9a10 10 0 1 0-20 0V13"
            fill="none"
            stroke="#ff3345"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.25}
        />
    </Svg>
)

export default SvgComponent
