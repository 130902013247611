import React, { Component } from 'react';
import {
  Container,
  Content,
  Footer,
  H1,
  View,
  Body,
  Button,
  Text,
  Header,
  Icon
} from 'native-base';
import { withNavigation } from 'react-navigation';
import { SafeAreaView } from 'react-native';
import styles from './styles';
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";

class TermsOfUse extends Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <Container>
          <AppHeader style={styles.header}>
            <H1 style={styles.h1}>ARBOX USER TERMS & AGREEMENT</H1>
            <Button
              transparent
              style={styles.xButton}
              onPress={() => this.props.navigation.goBack()}
            >
              <Icons8Generator name={'close'}/>
            </Button>
          </AppHeader>
          <Content>
            <Body style={styles.body}>
              <Text style={styles.text}>
                Introduction
                {'\n'}
                {'\n'}
                The Arboxapp.com web management system and the Arbox mobile application (for Android
                and Apple users) were designed for Crossfit box owners and Crossfit athletes. The
                following following “Agreement” together with the Privacy Policy on Arbox’s website
                (http://arboxapp.com/wp-content/uploads/2015/09/ARBOX-PRIVACY-POLICY.pdf) the Terms
                and Conditions of Use (“Terms of Use”) of this proprietary software as well as
                Arbox’s usage of data inputted by users, affiliates, and third-parties. If you do
                not understand any of these Terms of Use or have any questions, you may contact
                Arbox at: info@arboxapp.com.
                {'\n'}
                From time to time, Arbox may make changes to the Terms of Use. When changes are made
                that Arbox’s considers material, we will notify you. By continuing to use the
                Service after those changes are made, you are expressing your acceptance of those
                changes. *Please read the entire Terms of Use below before using Arbox’s services
                and acknowledging your acceptance.
                {'\n'}
                {'\n'}
                Acknowledgement of Terms of Use
                {'\n'}
                By accessing and using the Services, you acknowledge that you are over the age of
                eighteen (18) and have read, understood, and voluntarily agree to the Terms of Use.
                If you do not accept these Terms of Use, you are not authorized to use the Services.
                Arbox may modify the Terms of Use at any time and such modification will be
                effective upon posting to the website and/or mobile application or other reasonable
                notice to you.
                {'\n'}
                {'\n'}
                Definitions:
                {'\n'}
                “Arbox” is the name of the web platform and mobile application provided by Capris
                Holdings LTD company that holds all the rights on it. In this Agreement “Arbox” and
                “Capris Holdings LTD” refers to the same entity. “Affiliate” sometimes referred to
                as “you”, “your”,”user”, or any variation thereof, is defined as a Box Owner who has
                contracted with Arbox for its services, or by any other available notice to you.
                {'\n'}
                “Services” are defined as any and all services provided by Arbox to its users in any
                form or form or manner by and through its website and mobile application, or any
                other means.
                {'\n'}
                {'\n'}
                License:
                {'\n'}
                As long as you comply with the Terms of Use, Arbox grants you the right to use its
                box management web platform, including all features and Services. The System, or any
                portion of the System, may not be reproduced, duplicated, copied, modified, sold,
                resold, distributed, visited, or otherwise exploited for any commercial purpose
                without the express written approval of Arbox. This limited license grants You no
                rights in or to the intellectual property of Arbox or any other party.
                {'\n'}
                The license granted in this section is conditioned on Your compliance with the Terms
                of Use. In the event that You breach any provision of the Terms of Use, Your rights
                under this section will immediately terminate.
                {'\n'}
                User Content:
                {'\n'}
                “User Content” is any content, materials or information, other than personally
                identifiable identifiable information such as your email, address, phone number,
                that You upload or post to, or transmit, display, perform or distribute by means of,
                the Sytem, or Mobile Application, whether in connection with Your use of Services or
                otherwise.
                {'\n'}
                You hereby represent and warrant that You own all rights, title and interest in and
                to User User Content or are otherwise authorized to grant the rights provided the
                Arbox Parties under this section.
                {'\n'}
                Arbox reserves the right to (i) remove, suspend, edit or modify any User Content in
                its sole its sole discretion, including without limitation any User Content at any
                time, without notice to you and for any reason (including, but not limited to, upon
                receipt of claims or allegations from third parties or authorities relating to such
                User Content or if Arbox is concerned that you may have violated these Terms of
                Use), or for no reason at all and (ii) to remove, suspend or block any User Content
                submissions.
                {'\n'}
                Arbox also reserves the right to access, read, preserve, and disclose any
                information as Arbox as Arbox reasonably believes is necessary to (i) satisfy any
                applicable law, regulation, legal process or governmental request, (ii) enforce
                these Terms of Use, including investigation of potential violations hereof, (iii)
                detect, prevent, or otherwise address fraud, security or technical issues, (iv)
                respond to user support requests, or (v) protect the rights, property or safety of
                Arbox, its users and the public.
                {'\n'}
                No Warranty and Your Risk of Use:
                {'\n'}
                The Arbox Services are provided “AS IS”, without any express or implied warranty or
                condition of any kind whatsoever and Arbox disclaims any warranties of
                merchantability, fitness for a particular purpose or non-infringement to the fullest
                extent of the law.
                {'\n'}
                {'\n'}
                Limitation of Liability:
                {'\n'}
                To the fullest extent permitted by law, in no event and under no circumstances will
                Arbox, its Officers, Shareholders, Employees, Agents, Directors, Subsidiaries,
                Affiliates, Successors, Assignees, Assignors, Suppliers, Licensors, or Vendors, be
                liable for any direct, indirect, special, incidental, punitive, exemplary or
                consequential damages, regardless of legal theory, and without regard to whether or
                not Arbox has been warned of even the possibility of those damages, and even if a
                remedy fails of its essential purpose. You specifically acknowledge that Arbox shall
                not be liable for content or the defamatory, offensive or illegal conduct of any
                user, member or third-party and that the risk of harm or damage from the foregoing
                rests entirely with you.
                {'\n'}
                Any user or member who claims to have been damaged will be limited to the
                consideration paid for the services.
                {'\n'}
                Indemnification:
                {'\n'}
                To the fullest extent of the laws in the State of New Jersey, you agree to defend,
                indemnify and hold harmless Arbox, its parent company, subsidiaries,
                successors-in-interests, predecessors-in-interest, officers, directors, employees
                and agents from and against any and all claims, damages, obligations, losses,
                liabilities, costs, or debt and expenses, including but not limited to attorneys
                fees arising from: (1) your use and access to the Services; (2) your violation of
                any Terms of Service; (3) your violation of any third-party right, including without
                limitation any copyright, intellectual property, or privacy right; or (4) any claim
                that your Content caused caused damage to a third party, irrespective of whether or
                not Arbox is claimed or determined to be partially or wholly negligent by any
                competent Court of Law. Arbitration Provision
                {'\n'}
                You agree that any and all claims or disputes arising out of your usage of Arbox’s
                Services are Services are subject to Arbitration pursuant to the American
                Arbitration Association Rules and Regulations EXCEPT FOR (a) any claims seeking to
                enforce, protect, or determine the validity or ownership of any intellectual
                property rights, and (b) any claims related to allegations of theft, piracy or
                unauthorized use of the Arbox Services. The place of arbitration shall be
                Philadelphia, PA. The arbitration shall be governed by the laws of the State of New
                Jersey. Choice of Law and Exclusive Jurisdiction
                {'\n'}
                This Agreement and any claims or disputes shall be subject to the laws of the State
                of New New Jersey. You also agree to submit to the jurisdiction of the State and
                Federal Courts of the State of New Jersey.
                {'\n'}
                Entire Agreement and Terms of Use
                {'\n'}
                This Agreement and Terms of Use constitute all the terms and conditions agreed upon
                between you and Arbox and supersedes any prior or subsequent agreements in relation
                to the subject matter of these Agreements, whether written or oral. Any additional
                or different terms or conditions in relation to the subject matter of the Agreements
                in any written or oral communication from you to Arbox are void. You represent that
                you have not accepted the Agreements or Terms of Use in reliance on ay oral or
                written representation made by Arbox that are not contained herein.
                {'\n'}
                Severability
                {'\n'}
                Should any provision of the terms be held invalid or unenforceable for any reason or
                to any extent, such invalidity or enforceability shall not in any manner affect or
                render invalid or unenforceable the remaining provisions of the Terms of Use, and
                the application of that provisions shall be enforced to the extent permitted by law.
                {'\n'}
                General Terms
                {'\n'}
                ARBOX AGREES TO THE FOLLOWING ACTIVITIES
                {'\n'}
                ARBOX WILL EXECUTE THE AFFILIATE’S INITIAL SOFTWARE CONFIGURATION. Initial
                configuration includes the importing and creating of athletes and creation of
                programs, membership templates, and merchant integration into their Arbox system.
                Arbox will provide a ‘check-list’ of items needed and the Affiliate will complete
                and submit in a timely manner. Also, Login credentials will be created for all of
                your current Athletes. Arbox WILL PROVIDE A PERSONALIZED ONLINE TRAINING COURSE FOR
                THE AFFILIATE. Arbox will also schedule a final call with the Affiliate to discuss
                billing features. features. Arbox is committed to ensuring the Affiliate’s launch
                and each subsequent day is successful. Arbox WILL PROVIDE A DEDICATED ‘GO-LIVE’
                SUPPORT TEAM TO ALL AFFILIATES DURING THEIR FIRST 30 DAYS LIVE. Affiliates who
                contact Arbox via the ‘in-app’ support widget will automatically be directed to our
                ‘Dedicated Go-Live Support Team.’ Arbox will provide weekly email support to
                affiliates during their first 30 days - providing additional training videos and a
                check-list of features that should be utilized by the Affiliate.
                {'\n'}
                {'\n'}
                Arbox WILL BE RESPONSIVE, COURTEOUS, AND KNOWLEDGEABLE. If any member of the team
                are discourteous we encourage our Affiliates to let us know. ARBOX WILL PROVIDE 24/7
                SUPPORT FOR YOU AND YOUR ATHLETES. Affiliates can reach us directly at
                info@arboxapp.com. Affiliates can also use Arbox’s online Public Knowledge Base at
                http://support.Arbox.com for answers to general questions. Phone support is
                available via our 1 on 1 coaching sessions, Affiliates can schedule calls at
                http://www.Arbox.com/Arbox-support.html.
                {'\n'}
                {'\n'}
                THE AFFILIATE AGREES TO THE FOLLOWING ACTIVITIES
                {'\n'}
                THE AFFILIATE WILL PAY FOR ARBOX EACH MONTH. The Affiliate agrees to pay a basic fee
                of $99. The affiliate will p per any active member, per month, per affiliate, per
                location for capped at two-hundred (200) members. The price will then lock at a flat
                fee of $350.00 as long as the affiliated has two-hundred or more members.
                {'\n'}
                THE AFFILIATE IS NOT LOCKED INTO ANY LONG TERM CONTRACT. Customers can Arbox account
                at anytime with no penalty or additional fee. No returns or refunds are issued once
                account has been canceled. Arbox environment is immediately made inactive upon
                cancellation.
                {'\n'}
                {'\n'}
                THE AFFILIATE WILL BE RESPONSIVE AND COMMUNICATIVE. It is critical for the success
                the program that each box communicates with Arbox in a timely and effective manner.
                Arbox encourages all boxes to freely express any ideas, hopes, and worries to them
                at any time.
              </Text>
            </Body>
          </Content>
          <Footer style={styles.footer}>
            <View style={styles.footerInnerContainer}>
              <Button
                bordered
                block
                style={styles.button}
                onPress={() => this.props.navigation.goBack()}
              >
                <Text style={styles.buttonText}>I AGREE</Text>
              </Button>
            </View>
          </Footer>
        </Container>
      </SafeAreaView>
    );
  }
}

export default withNavigation(TermsOfUse);
