import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';
import TabBarIcon from '../../components/TabBarIcon';
import ProductScreen from '../../screens/ProductScreen';
import CartScreen from '../../screens/CartScreen';
import ShopScreen from "../../screens/ShopScreen";
import PurchaseScreen from "../../screens/PurchaseScreen";
import ClubScreen from "../../screens/Club/ClubScreen";
import {store} from "../../config/store";
import NavigationService from "../../helpers/NavigationService";
import PurchaseEnd from "../../screens/PurchaseScreen/PurchaseEnd";
//TODO trying to refractor to custom stack

const invisibleTabOnScreens = ['Purchase', 'Cart', 'Product','PurchaseEnd'];
export const MarketStack = createStackNavigator(
    {
        Shop: {
            screen: ShopScreen,
            navigationOptions: {
                headerBackTitle: null,
                header: null,
            },
        },
        Clubs: {
            screen: ClubScreen,
            navigationOptions: {
                headerBackTitle: null,
                header: null,
            },
        },
        Product: {
            screen: ProductScreen,
            navigationOptions: ({navigation}) => ({
                header: null,
                tabBarVisible: false,
            }),
        },
        Purchase: {
            screen: PurchaseScreen,
            navigationOptions: ({navigation}) => ({
                header: null,
                tabBarVisible: false,
            }),
        },
        Cart: {
            screen: CartScreen,
            navigationOptions: ({navigation}) => ({
                header: null,
                tabBarVisible: false,
            }),
        },
        PurchaseEnd: {
            screen: PurchaseEnd,
            navigationOptions: ({navigation}) => ({
                header: null,
                tabBarVisible: false,
            }),
        },
    },
    {headerMode: 'screen',
        lazy: true
    },
);

MarketStack.navigationOptions = ({navigation}) => {
    const auth = store.getState().auth;
    const icon = auth.hasShop ? "cart" : "club";
    let tabBarVisible = true;

    let routeName = navigation.state.routes[navigation.state.index].routeName
    if (invisibleTabOnScreens.includes(routeName)) {
        tabBarVisible = false
    }
    return {
        tabBarIcon: ({focused}) => <TabBarIcon focused={focused} name={icon}/>,
        tabBarOnPress: () => NavigationService.navigate(auth.hasShop ? "Shop" : "Clubs"),
        tabBarVisible,
    };
};
