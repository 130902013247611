import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8BuildingWithRooftopTerrace(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M10 3a1 1 0 00-1 1v5c-1.645 0-3 1.355-3 3v35h38V12c0-1.645-1.355-3-3-3V4a1 1 0 00-1-1H10zm1 2h4v4h-4V5zm6 0h4v4h-4V5zm6 0h4v4h-4V5zm6 0h4v4h-4V5zm6 0h4v4h-4V5zM9 11h32c.565 0 1 .435 1 1v33h-4V35H27v10H8V12c0-.565.435-1 1-1zm3 4v7h11v-7H12zm15 0v7h11v-7H27zm-13 2h7v3h-7v-3zm15 0h7v3h-7v-3zm-17 8v7h11v-7H12zm15 0v7h11v-7H27zm-13 2h7v3h-7v-3zm15 0h7v3h-7v-3zm-17 8v7h11v-7H12zm2 2h7v3h-7v-3zm15 0h7v8h-7v-8z" />
    </Svg>
  );
}

export default SvgIcons8BuildingWithRooftopTerrace;
