import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgLastYearSign(props) {
    return (
        <Svg width="294px" height="47px" viewBox="0 0 294 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <G id="Feed" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <G id="Status-New-" transform="translate(-41.000000, -244.000000)" fill-rule="nonzero">
                    <G id="Group-15" transform="translate(41.000000, 244.000000)">
                        <Path d="M34.9687531,0.00021862161 C33.8658315,0.0156500242 32.9850969,0.827432414 33.0002008,1.81465409 L33.0002008,7.18454338 C32.9900013,7.83006836 33.3689144,8.43050333 33.991847,8.75593 C34.6147795,9.08135667 35.3852205,9.08135667 36.008153,8.75593 C36.6310856,8.43050333 37.0099987,7.83006836 36.9997992,7.18454338 L36.9997992,1.81465409 C37.0072013,1.33082647 36.7954663,0.864923563 36.4128388,0.52310314 C36.0302112,0.181282717 35.5092843,-0.00733811744 34.9687531,0.00021862161 Z" id="Path" fill="#00D181"></Path>
                        <Path d="M2.45371233,0 L0,3.08804205 C0.88694909,3.80243612 1.93549202,4.59231874 3.05563859,5.4117932 L1.22685617,31.1892247 L23.0073868,16.5712064 C23.7270371,16.8715261 24.4618375,17.163895 25.2080605,17.4551572 L24.7824946,17.3547963 L32.634374,43.0473062 L49.8908735,23.7431836 C50.8245248,23.8629996 51.7643594,23.9741474 52.7203101,24.0712888 L66.0048617,47 L77.5028046,23.851264 C78.9849862,23.6816816 80.4811717,23.490053 82,23.2606762 L81.4172414,19.354304 C39.8635288,25.6298379 12.7293531,8.27653176 2.45371233,0 Z" id="Path" fill="#FF3345"></Path>
                        <Path d="M58.9839648,6 C58.2457983,6.00266774 57.569789,6.3722002 57.2273331,6.96025585 C56.8848772,7.5483115 56.9321333,8.25845623 57.3501296,8.80558966 L61.3335779,14.1788593 C61.72303,14.7224596 62.4080564,15.0351003 63.1258838,14.9968537 C63.8437112,14.9586071 64.4828914,14.5754112 64.7982375,13.9942586 C65.1135835,13.4131059 65.0561355,12.7242247 64.6479303,12.1918672 L60.664482,6.81859753 C60.2959588,6.30610638 59.6626308,5.9976109 58.9839648,6 L58.9839648,6 Z" id="Path" fill="#105DC6"></Path>
                        <Path d="M16.9249548,32.001231 C16.2909269,32.0215753 15.7054102,32.3116945 15.3476536,32.7827722 C14.989897,33.2538499 14.9025076,33.8497828 15.1122388,34.3881332 L17.1081758,39.7718073 C17.3336785,40.3805224 17.9032875,40.8348013 18.6023797,40.9634761 C19.3014718,41.0921509 20.0238048,40.8756666 20.4972103,40.3955925 C20.9706158,39.9155183 21.1231496,39.2448121 20.8973384,38.6361895 L18.9014014,33.2525153 C18.6302909,32.4834073 17.8216937,31.971486 16.9249548,32.001231 L16.9249548,32.001231 Z" id="Path" fill="#F5C2D1"></Path>
                        <Path d="M291.526356,0 C280.317893,9.27144222 265.194935,15.2067529 251.092939,18.2392791 C236.99094,21.2718075 223.807665,21.3337151 217.076494,19.3893644 L216,23.186965 C217.478509,23.6140428 219.197242,23.9220753 221.019821,24.171097 L234.128616,45 L243.77507,23.5458831 C244.516649,23.4370399 245.248432,23.3426213 246.000588,23.2178378 L265.400382,41.0480274 L269.049774,17.0698963 C269.651554,16.8427409 270.245131,16.5864002 270.843929,16.348199 L290.808693,29.1921098 L290.808693,5.52658662 C291.895316,4.72807891 292.966492,3.91150495 294,3.05660377 L291.526356,0 Z" id="Path" fill="#FF3345"></Path>
                        <Path d="M231.950853,5.00087069 C231.433799,5.01627681 230.943053,5.23236705 230.582583,5.60336146 L224.61195,11.5738502 C224.092035,12.073018 223.882602,12.8142508 224.064422,13.5116821 C224.246243,14.2091135 224.790905,14.753763 225.488353,14.9355792 C226.185802,15.1173954 226.927052,14.9079677 227.426232,14.3880648 L233.396864,8.41757602 C233.98549,7.84542138 234.162476,6.97059642 233.842541,6.21463943 C233.522607,5.45868245 232.771384,4.97667348 231.950853,5.00087069 Z" id="Path" fill="#F5C2D1"></Path>
                        <Path d="M243.901511,7.00265728 C243.17301,7.0400038 242.522949,7.47144633 242.206152,8.12785792 L240.210695,12.1147275 C239.891835,12.7517996 239.937416,13.5104156 240.330267,14.1048121 C240.723118,14.6992086 241.403555,15.0390826 242.115265,14.9964069 C242.826976,14.9537312 243.461833,14.5349893 243.780692,13.8979171 L245.776149,9.91104753 C246.105245,9.27674296 246.06942,8.51500871 245.682241,7.91432322 C245.295062,7.31363772 244.615728,6.96584451 243.901511,7.00265728 Z" id="Path" fill="#00D181"></Path>
                        <Path d="M275.090025,29.0006941 C274.07559,28.9744221 273.196484,29.6967218 273.028357,30.6946217 C272.86023,31.6925215 273.454368,32.6616215 274.421721,32.9673418 L280.329031,34.8990995 C281.398961,35.247424 282.549491,34.6649194 282.898811,33.5980391 C283.248131,32.5311587 282.663962,31.3839086 281.594032,31.0355841 L275.690699,29.1077928 C275.497003,29.0422211 275.294506,29.0061165 275.090025,29.0006941 Z" id="Path" fill="#0053A8"></Path>
                    </G>
                </G>
            </G>
        </Svg>
    );
}

export default SvgLastYearSign;
