import {PATCH_WORKOUT, USER_RESULTS, WORKOUTS} from "../constants";


const initialState = {
    isLoading: false,
    data: [],
    error: null,
    userResults: [],
};

const logbookReducer = (state = initialState, action) => {
    const workouts = WORKOUTS.toUpperCase(); // just MAKE SURE IT IS UPPER
    const userResults = USER_RESULTS.toUpperCase();
    switch (action.type) {
        case `${workouts}_PENDING`:
            return {
                ...state,
                isLoading: true
            };
        case `${workouts}_SUCCESS`:{
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        }

        case `${workouts}_FAILED`:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                data: []
            };
        case `${userResults}_SUCCESS`:{
            return {
                ...state,
                userResults: action.payload,
            };
        }
        // case `${patchWorkout}_SUCCESS`: {
        //     //TODO CHECK WHY is Array of arrays returned
        //     const replacement = action.payload[0];
        //     const workoutId = replacement[0].workout_id;
        //     const replacementIndex = state.data.forEach(arr => arr.findIndex(item => {
        //         if(item.workout_id === workoutId)
        //             return true;
        //     }))
        // }
        default:
            return state;
    }
};

export default logbookReducer;

