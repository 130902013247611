import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8ViewDetails(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M7.813 4A1 1 0 007 5v40c0 .55.45 1 1 1h34c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1H7.813zM9 6h32v38H9zm5 6v2h3v-2zm6 0v2h17v-2zm-6 8v2h3v-2zm6 0v2h17v-2zm-6 8v2h3v-2zm6 0v2h17v-2zm-6 8v2h3v-2zm6 0v2h17v-2z" />
    </Svg>
  );
}

export default SvgIcons8ViewDetails;
