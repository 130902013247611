import { StyleSheet } from 'react-native';
import { scale } from 'react-native-size-matters';
import { Colors } from '../../styles/Colors';

const styles = StyleSheet.create({
  flexContainerCentered: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  chillImage: {
    marginBottom: scale(20),
  },
  chillText: {
    color: Colors.black,
    fontSize: scale(12),
      textAlign:'center'
  },
});

export default styles;
