import {scale,verticalScale, ScaledSheet} from 'react-native-size-matters';
import {Dimensions} from 'react-native';

import {Colors} from '../../styles/Colors';

const deviceWidth = Dimensions.get('window').width;

export default ScaledSheet.create({
    container:{
        width: '80%',
        alignSelf:'center',
        flex:1,
        marginTop: verticalScale(50)

    },
    inner: {
        flex: 1,
        justifyContent: "flex-end",
    },
    gradient: {
        zIndex: 1,
        height: '100%',
        flex:1,
    },
    h1: {
        fontSize: scale(20),
        color: Colors.black,
        alignSelf:'center',
        fontWeight: '600'
    },
    h2: {
        color: Colors.black,
        fontSize: scale(14),
        lineHeight: 22,
    },
    lock: {
        fontSize: scale(80),
        alignSelf:'center',
        color: Colors.black,
    },
    inputItem: {
        width:'100%'

    },

    label: {
        color: 'rgba(255, 255, 255, 0.7)'
    },
    input: {
        color: '#FFF',
    },
    textError: {
        color: Colors.brandDanger,
    },
    textWrap: {},

    imageWrap: {
        alignItems: 'center'
    },
    formWrap: {
        flex: 1,
        justifyContent: 'center',
        width: scale(300),
        paddingTop: scale(40),
        paddingHorizontal: scale(10)
    },

    pickerText: {
        color: '#FFF',
        marginLeft: -25
    },
    pickerPlaceholder: {
        color: 'yellow'
    },
    pickerWrapper: {
        borderBottomColor: Colors.lightGrey,
        borderBottomWidth: 2,
        marginLeft: scale(10),
    },
    pickerLabel: {
        color: 'rgba(255, 255, 255, 0.7)'
    },

    textAreaLabel: {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    textLabel: {
        color: 'rgba(255, 255, 255, 0.7)',

    },
    textArea: {
        color: 'rgb(255, 255, 255)',
        paddingBottom: 5,
        borderBottomWidth: 2,
        borderBottomColor: Colors.lightGrey
    },

    badge: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        borderRadius: 100,
        position: 'absolute',
        bottom: -40,
        right: -10
    },

    circleBorder: {
        borderRadius: 1000,
        width: deviceWidth * 0.7,
        height: deviceWidth * 0.7,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,0.15)'
    },
    circleLighter: {
        borderRadius: 1000,
        width: deviceWidth * 0.5,
        height: deviceWidth * 0.5,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255 ,0.08)'
    },
    circleMain: {
        borderRadius: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.18)',
        width: deviceWidth * 0.3,
        height: deviceWidth * 0.3,
        justifyContent: 'center',
        alignItems: 'center'
    },
    stretchingExercises: {
        width: deviceWidth * 0.12
    },
    avatar1: {
        position: 'absolute',
        width: deviceWidth * 0.15,
        right: deviceWidth * -0.0,
        top: deviceWidth * -0.15
    },
    avatar2: {
        position: 'absolute',
        width: deviceWidth * 0.1,
        left: deviceWidth * -0.02,
        top: deviceWidth * -0.19
    },
    avatar3: {
        position: 'absolute',
        width: deviceWidth * 0.18,
        left: deviceWidth * -0.14,
        top: 0
    },
    avatar4: {
        position: 'absolute',
        width: deviceWidth * 0.18,
        right: deviceWidth * -0.14,
        bottom: deviceWidth * -0.2
    },
    avatar5: {
        position: 'absolute',
        width: deviceWidth * 0.15,
        left: deviceWidth * 0.11,
        bottom: deviceWidth * -0.22
    },


    btnContainer: {
        backgroundColor: "white",
        marginTop: 12,
    },
});
