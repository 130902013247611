import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8LeaderBoard(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#191919" {...props}>
          <Path d="M19 9c-1.645 0-3 1.355-3 3v6H3c-1.645 0-3 1.355-3 3v18.813c0 .55.45 1 1 1h48c.55 0 1-.45 1-1V25c0-1.645-1.355-3-3-3H34V12c0-1.645-1.355-3-3-3zm0 2h12c.555 0 1 .445 1 1v11c0 .55.45 1 1 1h14c.555 0 1 .445 1 1v13.813H2V21c0-.555.445-1 1-1h14c.55 0 1-.45 1-1v-7c0-.555.445-1 1-1zm5.813 3c-.102.5-.325.8-.625 1-.301.2-.782.313-1.282.313h-.093v1.093h1.593V22H26v-8zM9.5 23c-.898 0-1.508.195-1.906.594-.399.398-.594.918-.594 1.718v.282h1.594V25c0-.2-.008-.398.094-.5L9 24.187c.102-.101.3-.093.5-.093.5 0 .813.3.813 1v.593c0 .102-.024.305-.126.407-.101.101-.18.304-.28.406l-.407.406-.688.594c-.3.3-.613.512-.812.813-.3.199-.492.48-.594.78L7.094 30c-.102.3-.094.602-.094 1h5v-1.313H9c0-.101.094-.3.094-.5l2.094-2.093c.199-.2.304-.395.406-.594.101-.2.219-.395.219-.594.101-.199.093-.418.093-.718 0-.7-.195-1.196-.594-1.594C10.915 23.195 10.302 23 9.5 23zm31.094 4c-.7 0-1.29.195-1.688.594-.398.398-.593 1.011-.593 1.812h1.5v-.5c0-.101-.008-.304.093-.406l.282-.313c.101-.101.207-.093.406-.093.3 0 .492.02.593.218.102.102.22.383.22.782 0 .199-.094.3-.094.5-.102.199-.118.304-.22.406l-.28.313c-.102.101-.208.093-.407.093h-.5V31.5h.5c.2 0 .399-.008.5.094l.282.312c.101.102.218.207.218.407v1c0 .101.008.273-.093.374L41 34c-.102.102-.3.094-.5.094a.827.827 0 01-.688-.407c-.101-.3-.199-.574 0-.874h-1.624c0 .8.226 1.382.624 1.78.301.4.887.594 1.688.594.5 0 1.012-.085 1.313-.187.3-.2.582-.395.78-.594.2-.3.305-.511.407-.812.102-.301.094-.48.094-.782 0-.5-.082-1.011-.282-1.312-.199-.3-.624-.492-1.124-.594.398-.101.707-.293.906-.593.199-.301.312-.72.312-1.22 0-.8-.195-1.292-.593-1.593-.399-.3-1.02-.5-1.72-.5z" />
      </Svg>
  );
}

export default SvgIcons8LeaderBoard;
