// @flow
import * as React from 'react';
import {Modal} from "react-native-web";
import {View, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {scale} from "react-native-size-matters";
import {isMobile} from "../../../helpers/functions";
import Close from "../../../svg/Icons8Close";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";

const PopupModal = (props) => {
    const {show, setClose, children, overrideStyles, backgroundColor, closeColor, hideCloseBtn} = props;
    return (
        <Modal visible={show} transparent animationType='fade'>
            <View style={[styles.background]}>
                <View style={[styles.modalContainer, overrideStyles, {backgroundColor: backgroundColor ? backgroundColor : 'white'}, globalStyles.cardsShadow, isMobile() ? styles.mobileContainer : null]}>
                    <TouchableOpacity onPress={setClose} style={styles.close}>
                        {!hideCloseBtn && <Close fill={closeColor ? closeColor : Colors.defaultIconFill} style={isMobile() && styles.mobileClose}/>}
                    </TouchableOpacity>
                    {children}
                </View>
            </View>
        </Modal>
    );

}
export default PopupModal;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            background: {
                flex: 1,
                backgroundColor: 'rgba(0,0,0,0.5)',
                justifyContent: 'center',
                alignItems: 'center',
            },
            modalContainer: {
                width: '50%',
                paddingHorizontal: scale(20),
                paddingVertical: scale(20),
                borderRadius: 3,
                elevation: 20,
                overflow: 'auto'
            },
            mobileContainer: {
                width: '100%',
                flex: 1,
                borderRadius: 0,
            },
            close: {
                position: 'absolute',
                top: 20,
                right: 20,
                cursor: 'pointer',
                zIndex: 5
            },
            mobileClose: {
                width: '1.5em',
                height: '1.5em',
            }
        }
    })
})