import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import TabBarIcon from '../../components/TabBarIcon';

import HomeContainer from '../../containers/HomeContainer';
import SvgIcons8Home from "../../svg/Icons8Home";
import {getSvg8IconsDefault} from "../../helpers/functions";

export const HomeStack = createStackNavigator({
  Home: HomeContainer,
});

HomeStack.navigationOptions = {
    tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="home"/>,
};
