import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles/Colors';
import {globalStyles} from "../../../styles/Global";
import {scale, verticalScale} from "react-native-size-matters";

export default StyleSheet.create({
    container: {
        flex: 1,
        marginTop: verticalScale(50),

    },
    gradient: {
        flex: 1,
    },
    h1: {
        fontSize: 20,
        color: Colors.black,
        fontWeight: '600',
        alignSelf:'center'
    },
    h2: {
        color: Colors.black,
        fontSize: 12,
        lineHeight: 22,
        textAlign: 'center'
    },

    inputItem: {
        width: '100%'
    },
    buttonContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems:'center',
        marginBottom: verticalScale(40),
    },
    saveButton: {
        justifyContent:'center',
        borderRadius: scale(3),
        width: scale(220),
    },
    saveButtonText: {
        fontSize: scale(14),
        color: Colors.white
    },
    label: {
        color: 'rgba(255, 255, 255, 0.7)'
    },
    input: {
        color: '#FFF',
        ...globalStyles.textAlignByRTL,
    },
    textError: {
        color: Colors.brandDanger,
        // marginLeft: '5%',
        ...globalStyles.appPaddingHorizontal,
        marginTop: 5
    },
    errorText: {
        color: Colors.brandDanger,
        textAlign: 'center',
        marginVertical: 10,
    },
    lock: {

        fontSize: scale(80),
        alignSelf: 'center',
        color: Colors.black
    },
    inner: {
        flex: 1,
        justifyContent: "space-around"
    },
});
