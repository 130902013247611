import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="50pt"
        height="50pt"
        viewBox="0 0 50 50"
        {...props}
    >
        <Path
            style={{
                fill: "none",
                strokeWidth: 2,
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                stroke: "#1e1e1f",
                strokeOpacity: 1,
                strokeMiterlimit: 10,
            }}
            d="M43 40H7c-2.21 0-4-1.79-4-4V14c0-2.21 1.79-4 4-4h36c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4Zm0 0"
        />
        <Path
            style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: "#1e1e1f",
                fillOpacity: 1,
            }}
            d="M3 16h44v5H3ZM9 24h13v2H9Zm0 0"
        />
    </Svg>
)

export default SvgComponent
