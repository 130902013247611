import React from 'react';
import {View} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {verticalScale, scale} from "react-native-size-matters"
import {Colors} from "../../../styles/Colors";
import {HUGE_ICON_SIZE} from "../../../helpers/functions";
import {Icons8Generator} from "../Icons8Generator";



export const RoundIcon = props => {
    const height = props.height ? props.height : 100;
    const size = props.iconSize ? props.iconSize : HUGE_ICON_SIZE;
    const margin = props.noMargin ? {marginTop: 0} : globalStyles.marginTopBetweenElements
    return (
        <View style={[
            globalStyles.flexDirectionRow,
            globalStyles.centerItems, {
                height: scale(height), width: scale(height)
                , borderRadius: scale(height/2)
                , backgroundColor: Colors.white,
                ...margin
            }]}>
            <Icons8Generator size={size} name={props.name} fill={props.fill || Colors.black}/>
        </View>
    )
};



