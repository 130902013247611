import React from 'react';
import {View,TouchableOpacity} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {ScaledSheet, scale,verticalScale} from "react-native-size-matters"
import {AppText} from "../AppText";
import {Colors} from "../../../styles/Colors";



export const DrawerHeader = props => {
    return (
        <View
            style={[globalStyles.flexDirectionRow, {justifyContent: 'space-between'}, styles.drawerHeader]}>
            <AppText style={[globalStyles.heeboMedium, {
                color: Colors.black,
                fontSize: scale(16),
                ...(props.titleStyle)
            }]}>{props.title}</AppText>
            {props.noCloseBtn ? null : (<TouchableOpacity onPress={() => props.closeDrawer()}>
                <AppText style={[globalStyles.heeboMedium, {
                    color: Colors.black,
                    fontSize: scale(16),
                    ...(props.closeStyle)
                }]}>{props.closeText}</AppText>
            </TouchableOpacity>)}
        </View>
    )
};


const styles = ScaledSheet.create({
    drawerHeader: {
        alignItems: 'center',
        // marginTop: verticalScale(10)
    }
});
