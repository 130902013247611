// @flow
import * as React from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import EStyleSheet from "react-native-extended-stylesheet";
import i18n, {t} from "../../../services/i18n";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {BIG_ICON_SIZE, SMALL_ICON_SIZE} from "../../../helpers/functions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {registerProcessUpdateStep} from "../../../actions/minisite";

const RegistrationMobileHeader = (props) => {
    const registerStepNumber = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.registerStepNumber)
    const dispatch = useDispatch();

    const backPress = () => {
        if(registerStepNumber !== 0) {
            dispatch(registerProcessUpdateStep(registerStepNumber - 1))
        }
    }

    return (
        <View style={styles.wrapper}>
            {registerStepNumber > 0 && <TouchableOpacity onPress={backPress}><Icons8Generator name={i18n.dir === 'RTL' ? 'arrow' : 'arrow-rtl'} fill={Colors.lightBlack} size={'1.2em'}/></TouchableOpacity>}
            <AppText style={styles.mobilePageTitle} boldFont>{t('screens:Register:join-the-club', {})}</AppText>
        </View>
    );
};
export default RegistrationMobileHeader;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            mobilePageTitle: {
                fontSize: '1rem',
                color: Colors.lightBlack,
            },
            wrapper: {
                flexDirection: 'row',
                gap: '0.5em',
                alignItems: 'center'
            },
        }
    })
})