import React from 'react';
import {View} from 'react-native';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ModalSimple from '../../../components/ModalSimple';
import styles from './styles';
import ChooseClubList from '../../../components/ChooseClubList';
import {t} from '../../../services/i18n';
import {selectClubId} from '../../../actions/user';


class ChooseClubModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        }
    }

    render() {
        const {isModalVisible,onModalHide,onModalWillHide} = this.props;
        const {error} = this.state;

        const topBarEnabled = true;
        const headerText = t('modals:ChooseClub:title');
        const modalHeadingText = t('modals:ChooseClub:heading');
        const modalContent = (
            <View style={styles.modalContent}>
                <ChooseClubList clubsList={this.props.clubsList} selectedBoxId={this.props.selectedBoxId} selectClubId={this.props.selectClubId}/>
            </View>
        );

        return (
            <ModalSimple
                isModalVisible={isModalVisible}
                onModalWillHide={onModalWillHide}
                hideModal={onModalHide}
                withTopBar={topBarEnabled}
                removeCloseButton={true}
                error={error}
                headerText={headerText}
                headerColor={this.props.whiteLabelProperties.primaryColor}
                modalType=""
                headingText={modalHeadingText}
                subHeadingText=""
            >
                {modalContent}
            </ModalSimple>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            selectClubId,
        },
        dispatch,
    );
};

const mapStateToProps = state => (
    {
        clubsList: state.boxes.locations.data,
        selectedBoxId: state.user.selectedBoxId,
        modal: state.modal,
        whiteLabelProperties: state.whiteLabelProperties,
    }
);

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ChooseClubModal);
