import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Prize(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#191919" {...props}>
          <Path d="M25.043 2a.982.982 0 00-.61.254l-2.87 2.566-3.762-.836a1 1 0 00-1.164.664l-1.203 3.657-3.676 1.156a1.004 1.004 0 00-.68 1.156l.785 3.77-2.597 2.836c-.348.379-.352.96-.012 1.343l2.562 2.872-.832 3.761a1 1 0 00.66 1.164l3.657 1.203.765 2.434H16v17c0 .348.18.668.473.852.297.18.664.199.976.043L25 44.117l7.55 3.778c.313.156.68.136.977-.043.293-.184.473-.504.473-.852V29.422l.566-1.723 3.676-1.16c.488-.156.781-.652.68-1.156l-.785-3.77 2.597-2.836c.348-.379.352-.96.012-1.343l-2.562-2.872.832-3.761a1 1 0 00-.66-1.164L34.698 8.43l-1.16-3.672a1.004 1.004 0 00-1.156-.68l-3.77.785-2.836-2.597A1.024 1.024 0 0025.141 2h-.098zm.047 2.348l2.55 2.336a.99.99 0 00.88.242l3.386-.703 1.035 3.297c.098.308.336.55.641.652l3.285 1.078-.746 3.379c-.07.312.016.644.227.883l2.304 2.578-2.336 2.55a.99.99 0 00-.242.88l.703 3.386-3.297 1.035c-.308.098-.55.336-.652.641L32.031 29H32v.105l-.25.762-3.379-.746a1.006 1.006 0 00-.883.227l-2.578 2.304-2.55-2.336a.99.99 0 00-.88-.242l-3.386.703-1.035-3.297a1.007 1.007 0 00-.641-.652l-3.285-1.078.746-3.379a1.006 1.006 0 00-.227-.883l-2.304-2.578 2.336-2.55a.99.99 0 00.242-.88l-.703-3.386 3.297-1.035c.308-.098.55-.336.652-.645l1.078-3.281 3.379.75c.312.066.644-.02.883-.235zM25 8c-5.512 0-10 4.488-10 10s4.488 10 10 10 10-4.488 10-10S30.512 8 25 8zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm-3.613 21.137l2.836 2.597c.379.348.96.352 1.343.012l2.872-2.562 3.562.789v13.41l-6.55-3.278a1.01 1.01 0 00-.9 0L18 45.383V31.84z" />
      </Svg>
  );
}

export default SvgIcons8Prize;
