import React from 'react';
import {TouchableOpacity,ActivityIndicator} from 'react-native';
import {Icon} from 'native-base';
import {globalStyles} from "../../../styles/Global";
import {ScaledSheet, verticalScale, scale} from "react-native-size-matters"
import {AppText} from "../AppText";


export const ShowMoreButton = props => {
    return (
        <TouchableOpacity
            style={[
                globalStyles.flexDirectionRow,
                styles.roundContainer,
                {backgroundColor:props.backgroundColor}]}
            disabled={props.disabled} onPress={() => props.onPress()}>
            {props.text ? <AppText style={[globalStyles.heeboMedium,styles.textStyling, {color: props.textColor}]}>{props.processing ? null : props.text}</AppText> : null}
            {props.children}
        </TouchableOpacity>
    )
};


const styles = ScaledSheet.create({
    container: {
        // minHeight: verticalScale(25),
        // paddingHorizontal: scale(12),
        // borderRadius: 100,
    },
    roundContainer: {
        width: scale(40),
        height: scale(40),
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textStyling: {
        fontSize: scale(16),
        alignSelf: 'center',
        alignItems: 'center',

    }
});
