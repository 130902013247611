import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Dumbbell(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M13 9c-1.645 0-3 1.355-3 3v1H7c-1.645 0-3 1.355-3 3v5H2c-1.094 0-2 .906-2 2v4c0 1.094.906 2 2 2h2v5c0 1.645 1.355 3 3 3h3v1c0 1.645 1.355 3 3 3h3c1.645 0 3-1.355 3-3v-9h12v9c0 1.645 1.355 3 3 3h3c1.645 0 3-1.355 3-3v-1h3c1.645 0 3-1.355 3-3v-5h2c1.094 0 2-.906 2-2v-4c0-1.094-.906-2-2-2h-2v-5c0-1.645-1.355-3-3-3h-3v-1c0-1.645-1.355-3-3-3h-3c-1.645 0-3 1.355-3 3v9H19v-9c0-1.645-1.355-3-3-3zm0 2h3c.566 0 1 .434 1 1v26c0 .566-.434 1-1 1h-3c-.566 0-1-.434-1-1v-1.832a.904.904 0 000-.324V14.168a.904.904 0 000-.324V12c0-.566.434-1 1-1zm21 0h3c.566 0 1 .434 1 1v1.832a.904.904 0 000 .324v21.676a.904.904 0 000 .324V38c0 .566-.434 1-1 1h-3c-.566 0-1-.434-1-1V12c0-.566.434-1 1-1zM7 15h3v20H7c-.566 0-1-.434-1-1v-5.832a.904.904 0 000-.324v-5.676a.904.904 0 000-.324V16c0-.566.434-1 1-1zm33 0h3c.566 0 1 .434 1 1v5.832a.904.904 0 000 .324v5.676a.904.904 0 000 .324V34c0 .566-.434 1-1 1h-3zM2 23h2v4H2zm17 0h12v4H19zm27 0h2v4h-2z" />
    </Svg>
  );
}

export default SvgIcons8Dumbbell;
