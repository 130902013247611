import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8ShoppingCart(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M1.813 2a.88.88 0 00-.094.031A1.989 1.989 0 000 4a1.999 1.999 0 104 0h4.656c1.246 0 1.895.258 2.375.688.473.425.856 1.144 1.188 2.25l8 32.28c.3 1.145.601 2.325 1.437 3.282a3.91 3.91 0 001.313.969C22.383 44.164 22 45.027 22 46c0 2.2 1.8 4 4 4s4-1.8 4-4a3.93 3.93 0 00-.563-2h6.125A3.93 3.93 0 0035 46c0 2.2 1.8 4 4 4s4-1.8 4-4c0-1.027-.418-1.945-1.063-2.656A1.002 1.002 0 0041 42H25.719c-1.543 0-2.172-.328-2.594-.813-.418-.48-.672-1.32-.969-2.437v-.031L21.47 36h18.343a.996.996 0 00.938-.656l7.188-19A.99.99 0 0047 15H16.281l-2.125-8.531v-.063c-.383-1.289-.879-2.406-1.781-3.218C11.473 2.375 10.203 2 8.656 2H1.813zM16.78 17h28.782l-6.438 17H21zM26 44c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm13 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2z" />
    </Svg>
  );
}

export default SvgIcons8ShoppingCart;
