// @flow
import * as React from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";
import {isMobile} from "../../../helpers/functions";
import {AppText} from "../../Stateless/AppText";
import moment from "moment/moment";

const DatePickerField = (props) => {
    const {startDate, onChange, nameLabel, overrideStyle, ...rest} = props;
    const mobile = isMobile();

    return (
        <>
            {nameLabel && <AppText style={[styles(mobile).label]}>{nameLabel}</AppText>}
            <input type={'date'} style={EStyleSheet.flatten([styles(mobile).datepicker, overrideStyle])} value={startDate || moment()} onChange={(val) => onChange(val.target.value)} {...rest}/>
        </>
    );
};

export default DatePickerField;

const styles = (mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            datepicker: {
                backgroundColor: Colors.brandedLightText,
                borderWidth: 0,
                borderRadius: 3,
                height: 35,
                alignItems: 'center',
                padding: '0 0.5em',
                fontFamily: 'heebo',
                color: Colors.lightBlack,
                fontSize: '0.8rem'
            },
            label: {
                fontSize: mobile ? scale(12) : '0.8rem',
                color: Colors.labelColor,
                marginBottom: mobile ? verticalScale(5) : 0
            },
        }
    })
})