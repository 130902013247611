import React, {Component} from 'react';
import {Form, H1, H2, Icon, Input, Item, Label, Text, Button} from 'native-base';
import {View, TextInput, TouchableOpacity,KeyboardAvoidingView,TouchableWithoutFeedback,SafeAreaView,Keyboard} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {Field, reduxForm} from 'redux-form';
import {withNavigation} from 'react-navigation';
import {Colors} from '../../../styles/Colors';
import styles from '../styles';
import {i18n, t} from "../../../services/i18n";
import {globalStyles} from "../../../styles/Global";
import MyTextInput from "../../../components/Stateless/MyTextInput";
import FloatingTextInput from "../../../components/Stateless/FloatingTextInput";
import {AppText} from "../../../components/Stateless/AppText";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {HUGE_ICON_SIZE} from "../../../helpers/functions";

const passwordRequired = value => (value ? undefined : t('screens:OnBoarding:passwordRequired', {}));
const minLength = min => value =>
    value && value.length < min ? t('screens:OnBoarding:passwordMinChar', {min}) : undefined;
const passwordMinLength8 = minLength(6);
export const passwordsMustMatch = (value, allValues) =>
    value !== allValues.password ? t('screens:OnBoarding:passwordNotMatch', {}) : undefined;

@withNavigation
class FirstLoginChangePasswordScreen extends React.Component {
    static navigationOptions = {
        header: null,
        headerTransparent: true

    };

    constructor(props) {
        super(props);
        this.handleEye = this.handleEye.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.state = {
            eye: true
        };
    }


    handleEye() {
        this.setState({eye: !this.state.eye});
    }

    onInputChange(input) {
    }



    render() {
        const form = (
            <View>
                <Form>
                    <View style={[globalStyles.marginTopBetweenElements]}>
                        <View>
                            <TouchableOpacity style={globalStyles.iconOverTextInput} onPress={() => {this.handleEye()}}>
                                <Icon type="Feather" name={this.state.eye === true ? 'eye' : 'eye-off'} style={{
                                    fontSize: 20,
                                    color: Colors.black
                                }}/>
                                {/*<Icons8Generator name={this.state.eye === true ? 'eye' : 'eye-off'} fill={Colors.white}/>*/}
                            </TouchableOpacity>
                            <Field
                                name={"password"}
                                component={FloatingTextInput}
                                secureTextEntry={this.state.eye}
                                onChangeText={this.onInputChange()}
                                textContentType={'newPassword'}
                                placeholder={t('screens:OnBoarding:newPassword', {})}
                                validate={[passwordRequired, passwordMinLength8]}
                                returnKeyType={'go'}
                            />
                        </View>
                    </View>
                </Form>
            </View>
        );

        return (
            <KeyboardAvoidingView
                style={{flex: 1,backgroundColor: Colors.white}}>
                <LinearGradient
                    colors={[Colors.white, Colors.white]}
                    style={styles.gradient}>
                    <SafeAreaView style={[styles.container,{backgroundColor:Colors.white}]}>
                        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                            <View style={styles.inner}>
                                <View
                                    style={[globalStyles.centerItems]}>
                                    <Icons8Generator name={'lock'}  width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                                </View>
                                <AppText style={[globalStyles.heeboMedium,styles.h1, globalStyles.marginTopBetweenElements]}>{t('screens:OnBoarding:letsChangePassword', {})}</AppText>
                                <AppText style={[globalStyles.heeboRegular,styles.h2, globalStyles.marginTopBetweenElements]}>{t('screens:OnBoarding:letsChangePasswordText', {})}</AppText>
                                {form}
                                <View style={{flex: 1}}/>
                            </View>
                        </TouchableWithoutFeedback>
                    </SafeAreaView>
                </LinearGradient>
            </KeyboardAvoidingView>
        );
    }
}


export default reduxForm({
    form: 'FirstLoginChangePassword',
    destroyOnUnmount: false
})(FirstLoginChangePasswordScreen);
