import {apiAction} from '../helpers/HTTP';
import {
    NOTIFICATIONS
} from '../constants';


export const fetchNotifications = () => async (dispatch) => {
    const actionType = NOTIFICATIONS.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('messagesCenter', 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;

    }
};


