import React from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import ModalButton from "../../ModalButton";
import {t} from "../../../services/i18n";
import {Colors} from "../../../styles/Colors";
import {View} from "react-native-web";
import {SeparatorWithText} from "../../Stateless/SeparatorWithText";
import uuid from "uuid";
import {AppText} from "../../Stateless/AppText";

const GroupParticipantOptions = (props) => {
    const { connections, onConnectionClicked, onNewUserClicked } = props;

    return (
        <>
            <AppText style={styles.header} boldFont>{t('screens:workshop:participant-options',{})}</AppText>
            <View style={styles.participantsWrap}>
            {connections?.map(connection => {
                return (
                    <ModalButton
                        key={uuid.v4()}
                        onPress={() => onConnectionClicked(connection)}
                        text={connection?.full_name}
                        textColor={Colors.white}
                        type='primary'
                        colors={Colors.lightBlack}
                        overrideStyle={[styles.btn]}
                        textStyles={{fontFamily: 'heebo', fontSize: '0.9rem'}}
                    />
                )
            })}
            </View>
            <SeparatorWithText text={t('screens:Purchase:or', {})} color={Colors.separatorCircleGray} textStyle={[{fontSize: '0.8rem'}]}/>
            <ModalButton
                onPress={onNewUserClicked}
                text={t('screens:Register:new-participant', {})}
                textColor={Colors.lightBlack}
                type='primary'
                colors={Colors.white}
                overrideStyle={[styles.btn, styles.newParticipantBtn]}
                textStyles={{fontFamily: 'heebo', fontSize: '0.9rem'}}
            />
        </>
    );
};

export default GroupParticipantOptions;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            header: {
                fontSize: '1.2em',
                marginVertical: '1em'
            },
            participantsWrap: {
                gap: '1em'
            },
            btn: {
                minHeight: '1em',
                width: '100%',
                padding: '0.5em',
                marginTop: 0,
                borderRadius: 2
            },
            newParticipantBtn: {
                borderColor: Colors.lightBlack,
                borderWidth: 1
            }
        }
    })
});