import {t} from "../services/i18n";

export function hasCoachRestriction(data) {
    const disableCoachDisplay = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'coach');
    return !disableCoachDisplay;
}

export function getCapacityTextByRestrictions(data) {
    const disabledSlots = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'slotsOpen');
    const disabledArrivingAmount = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'arrivingAmount');
    //NOTE IT IS OPPOSITE
    const disableStandBy = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'standBy');
    const slots = data.max_users - data.registered;
    let text = '--';

    if (slots <= 0) {
        text = t('screens:Schedule:card:class-full', {});
    }
    if (slots > 0 && !disabledSlots) {
        if (disabledArrivingAmount) {
            text = t('screens:Schedule:card:slots', {slots});
        } else {
            text = `${data.registered}/${data.max_users} ` + t('screens:Schedule:card:bookedToClass', {});
        }
    }
    if(slots > 0 && disabledSlots && !disabledArrivingAmount) {
        text = t('screens:Schedule:card:going', {going: data.registered});
    }

    if (slots === 0 && disableStandBy) {
        if (data.hasOwnProperty('stand_by_position') && data.stand_by_position !== null) {
            text = t('screens:Schedule:card:on-waitlist-exists', {
                me: data.stand_by_position,
                waitlisted: data.stand_by
            });
        } else {
            text = t('screens:Schedule:card:on-waitlist', {waitlisted: data.stand_by});
        }
    }
    return text;
}