import React from 'react';
import {
    View,
    TouchableOpacity,
    TextInput,
    ScrollView
} from 'react-native';
import {connect} from 'react-redux';
import _, {debounce, throttle} from 'lodash';
import {ScaledSheet, verticalScale} from 'react-native-size-matters';
import {CardsLoader} from "../../loaders";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import SectionHeading from "../../components/SectionHeading";
import {globalStyles} from "../../styles/Global";
import {MEDIUM_ICON_SIZE} from "../../helpers/functions";
import {apiAction} from "../../helpers/HTTP";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import {t} from '../../services/i18n';
import {withNavigation} from "react-navigation";
import LogBookSearchResults from "../../components/LogBookSearchResults";
import FlatListHandler from "../../components/FlatListHandler";


@withNavigation
class SearchScreen extends React.PureComponent {
    state = {
        isLoading: true,
        results: null,
        selectedText: '',
        searching: false,
    };

    constructor(props) {
        super(props);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.query = this.query.bind(this);
    }

    componentDidMount = async () => {
        this.searchInput.focus();
    };

    componentDidUpdate(prevProps) {
        if (this.props.scheduleListener.update !== prevProps.scheduleListener.update && this.props.navigation.state.params.type === 'schedule') {
            const updated = this.props.scheduleListener.obj;
            this.setState(prevState => (
                {...prevState,
                results: [...prevState.results.map(item => item.id === updated.id ? {...updated} : item)]
                }));
        }
    }


    async handleTextChange(selectedText) {
        this.setState({selectedText: selectedText});
        if (selectedText.length > 1) {
            debounce(() => {
                this.setState({searching: true});
                this.query();
            }, 750)()
        } else {
            this.setState({results: null, searching: false})
        }

    }

    query() {
        try {
            if (this.state.selectedText.length > 1) {
                this.queryByType();
            } else {
                this.setState({results: null, searching: false});
            }

        } catch (error) {

        }
    }

    async queryByType() {
        switch (this.props.navigation.state.params.type) {
            case 'logbook': {
                const results = await apiAction('logbook/search', 'post', {input: this.state.selectedText});
                this.setState({results: results, searching: false});
                break;
            }
            case 'schedule': {
                const {locations_box_id, boxes_id} = this.props.navigation.state.params.data;
                const params = {
                    input: this.state.selectedText,
                    locations_box_id,
                    boxes_id
                };
                const results = await apiAction('schedule/search', 'post', params);
                this.setState({results: results.data, searching: false});
                break;
            }
            default:
                break;
        }

    }

    resultsByType() {
        const {results} = this.state;
        switch (this.props.navigation.state.params.type) {
            case 'logbook': {
                return results ? results.skills.length + results.workouts.length : 0
            }
            case 'schedule': {
                return results ? results.length : 0
            }
            default:
                return 0;
        }
    }

    getBackground() {
        switch (this.props.navigation.state.params.type) {
            case 'logbook': {
                return this.props.whiteLabelProperties.logbookColor;
            }
            default:
                return this.props.whiteLabelProperties.primaryColor;
        }
    }

    getSearchPlaceholder() {
        switch (this.props.navigation.state.params.type) {
            case 'logbook': {
                return t('screens:Logbook:search', {});
            }
            case 'schedule': {
                return t('screens:Schedule:search', {});
            }
            default:
                return '...'
        }
    }

    searchResults() {
        const {results} = this.state;
        switch (this.props.navigation.state.params.type) {
            case 'logbook': {
                return <LogBookSearchResults results={results}/>
            }
            case 'schedule': {
                return (
                    <View style={{flex: 1}}>
                        <FlatListHandler
                            schedules={results ? results : []}
                            type={'search'}
                            isLoading={false}
                        />
                    </View>
                )
            }
            default:
                return null;
        }

    }

    renderLists() {

        return (
            <View style={[globalStyles.flex]}>
                {this.state.selectedText.length > 1 ? (
                    <View style={{height: verticalScale(40)}}>
                        <SectionHeading
                            text={t('headings:search-friend-result', {
                                numOfResult: this.resultsByType(),
                                searchName: this.state.selectedText
                            })}/>
                    </View>
                ) : null}
                <ScrollView contentContainerStyle={[{flexGrow: 1}, globalStyles.screenBottomPadding]}>
                    {this.state.searching ?
                        <View style={[globalStyles.appPaddingHorizontal]}>
                            <CardsLoader items={8}/>
                        </View>
                        : null}
                    <View style={[globalStyles.flex]}>
                        {this.searchResults()}
                    </View>
                </ScrollView>
            </View>
        );
    }


    renderHeader() {
        return (
            <View style={[styles.header, {backgroundColor: this.getBackground()}]}>
                <TouchableOpacity onPress={() => this.searchInput.focus()}>
                    <Icons8Generator name={'search'} fill={this.props.whiteLabelProperties.primaryColorText}
                                     width={MEDIUM_ICON_SIZE}
                                     height={MEDIUM_ICON_SIZE}/>
                </TouchableOpacity>
                <TextInput
                    ref={(ref) => {
                        this.searchInput = ref;
                    }}
                    style={[globalStyles.heeboRegular, styles.input, globalStyles.textAlignByRTL, {color: this.props.whiteLabelProperties.primaryColorText}]}
                    onChangeText={this.handleTextChange}
                    underlineColorAndroid="transparent"
                    value={this.state.selectedText}
                    placeholder={this.getSearchPlaceholder()}
                    placeholderTextColor={this.props.whiteLabelProperties.primaryColorText}
                    keyboardType={'Web-search'}
                    autoFocus
                >
                </TextInput>
                <TouchableOpacity onPress={() => {
                    this.props.navigation.goBack();
                }}
                                  hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}>
                    <Icons8Generator name={'close'} fill={this.props.whiteLabelProperties.primaryColorText}
                                     width={MEDIUM_ICON_SIZE}
                                     height={MEDIUM_ICON_SIZE}/>
                </TouchableOpacity>
            </View>
        )
    }

    render() {
        return (
            <View style={globalStyles.flex}>
                <StatusBarWrap
                    backgroundColor={this.getBackground()}
                    barStyle={"light-content"}
                />
                {this.renderHeader()}
                {this.renderLists()}
            </View>
        );
    }
}

const styles = ScaledSheet.create({

    header: {
        height: verticalScale(60),
        ...globalStyles.appPaddingHorizontal,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 0
    },
    headerIcon: {
        color: '#FFF',
        fontSize: '24@s',
    },
    input: {
        backgroundColor: 'transparent',
        width: '75%',
        color: 'white',
        fontSize: '16@s'
    },
});

//
// const mapActionsToProps = dispatch => {
//     return bindActionCreators(
//         {
//             fetchFriendProfile
//         },
//         dispatch,
//     );
// };

const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,
    scheduleListener: state.schedule.scheduleListener,
});

export default connect(
    mapStateToProps,
    null,
)(SearchScreen);
