import {Platform} from 'react-native';
import { scale, ScaledSheet } from 'react-native-size-matters';
import {Colors} from '../../../styles/Colors';

const styles = ScaledSheet.create({
    wrapper: {
        flexDirection: 'column',
        backgroundColor: Colors.viewBackground,
        flex: 1,
    },
    container: {
        flex:1,
        backgroundColor: Colors.viewBackground,

    },
    containerInner: {
        alignItems: 'center',
        width:'100%',
        marginBottom: scale(200),
        flex: 1,

    },
    centerLoader: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    cardContainer: {
        alignSelf:'center',
        width: '90%',
    },
    card: {
        backgroundColor: '#FFF',
        width: '100%',
        paddingTop: '23@vs',
        paddingBottom: '29@vs',
        paddingHorizontal: '32@s',
        // paddingRight: getIsRTLStyle(0, '32@s'),
        borderRadius: 5,
        shadowOffset: {width: 0, height: 5},
        shadowColor: 'rgba(0, 0, 0, 0.08)',
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 3,
        marginTop: '20@s',
        marginBottom: '20@s',
        position: 'relative',
        minHeight:'75@s'
    },
    dateBadgeWrapper: {
        borderRadius: 20,
        paddingVertical: scale(8),
        paddingHorizontal: scale(10),
        flexDirection: 'column',
    },
    dateBadgeText: {
        color: '#FFFFFF',
        fontSize: scale(12),
    },
    expendableCalendar: {
        ...Platform.select({
            android: {
                paddingBottom:10
            },
        })
    }
});

export default styles;
