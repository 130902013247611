import React from 'react';
import {TouchableOpacity, View} from "react-native";
import {ScaledSheet, verticalScale} from "react-native-size-matters";
import DrawerHandler from "../../DrawerHandler";
import {AppText} from "../AppText";
import FriendsList from '../../../components/FriendsList';
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import {ShowMoreButton} from "../ShowMoreButton";
import i18n from "../../../services/i18n";


class FriendListDrawer extends React.PureComponent {
    render() {
        let more = `+${this.props.list.length - 3}`;
        if(i18n.isRTL){
            more = `${this.props.list.length - 3}+`;
        }
        return (
            <TouchableOpacity style={[globalStyles.flexDirectionRow,globalStyles.appBottomBorderRadius,globalStyles.appPaddingHorizontal
                ,{backgroundColor: Colors.white,height:verticalScale(60),alignItems:'center'}]} onPress={() => this.RBSheet.open()}>
                    <ShowMoreButton
                        textColor={Colors.white}
                        backgroundColor={Colors.showMoreContainer}
                        text={more}
                        onPress={() => this.RBSheet.open()}
                    />
                    <AppText style={[globalStyles.heeboMedium, {color: Colors.black},globalStyles.paddingStart]}>{this.props.actionText}</AppText>
                <DrawerHandler title={this.props.drawerTitle}
                               ref={el => {
                                   this.RBSheet = el ? el.ref.current : null;
                               }}>
                    <FriendsList
                        {...this.props}
                        drawer={true}
                        closeDrawer={() => this.RBSheet.close()}
                    />
                </DrawerHandler>
            </TouchableOpacity>
        )
    }
}

export default FriendListDrawer;

const styles = ScaledSheet.create({});