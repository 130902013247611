import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showModal, hideModal } from './actions';
import { modalTypes } from './modalTypes';
import ChooseClubModal from './modalFlows/ChooseClubModal';
import FriendsListModal from './modalFlows/FriendsListModal';
import BalloonsListModal from './modalFlows/BalloonsListModal';
import FriendRequestModal from './modalFlows/FriendsRequestModal';
import RepetitionMaximumModal from './modalFlows/RepetitionMaximumModal';
import PurchaseModal from './modalFlows/PurchaseModal';
import ShareModal from './modalFlows/ShareModal';


//TODO THIS WHOLE CRAP NEEDS TO BE DEPRECATED
class ModalContainer extends React.Component {
  render() {
    const { showModal, hideModal } = this.props;
    const { isModalVisible, cardType, modalType, information, isInfoModal, reducerActionName,type } = this.props.modal;
    if (modalType === modalTypes.MODAL_REPETITION_MAXIMUM) {
      return (
        <RepetitionMaximumModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          information={information}
          hideModal={hideModal}
          isInfoModal={isInfoModal}
        />
      );
    }

    if (modalType === modalTypes.MODAL_PURCHASE) {
      return (
        <PurchaseModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          information={information}
          hideModal={hideModal}
          isInfoModal={isInfoModal}
        />
      );
    }



    if (modalType === modalTypes.MODAL_SIMPLE) {
      return (
        <ChooseClubModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          hideModal={hideModal}
        />
      );
    }

    if(modalType === modalTypes.MODAL_FRIENDS_REQUEST){
      return <FriendRequestModal 
        isModalVisible={isModalVisible}
        showModal={showModal}
        information={information}
        hideModal={hideModal}

      />
    }

    if (modalType === modalTypes.MODAL_FRIENDS_LIST) {
      return (
        <FriendsListModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          information={information}
          hideModal={hideModal}
        />
      );
    }

    if (modalType === modalTypes.MODAL_BALLOONS_LIST) {
      return (
        <BalloonsListModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          information={information}
          hideModal={hideModal}
          isInfoModal={isInfoModal}
        />
      );
    }


    if (modalType === modalTypes.MODAL_SHARE) {
      return (
          <ShareModal
              isModalVisible={isModalVisible}
              showModal={showModal}
              information={information}
              hideModal={hideModal}
          />
      );
    }

    return null;
  }
}

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      showModal,
      hideModal,
    },
    dispatch,
  );
};

const mapStateToProps = state => ({
  modal: state.modal,
});

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ModalContainer);
