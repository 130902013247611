import React from 'react';
import {Text, View, StyleSheet, ActivityIndicator} from 'react-native';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalButton from '../../../components/ModalButton';
import MembershipList from '../../../components/MembershipList';
import InviteFriendsList from '../../../components/InviteFriendsList';
import isEmpty from 'lodash/isEmpty';
import {
    insert,
    deleteScheduleUser,
    deleteStandBy,
    insertSuccess,
    fetchWeeklySchedules
} from '../../../actions/schedule';
import {fetchUserMembershipsByBox} from '../../../actions/memberships';
import {Colors} from '../../../styles/Colors';
import ClassInfo from '../../../components/ClassInfo';
import {t} from '../../../services/i18n';
import Toast, {Durations} from 'react-native-root-toast';
import calendarEvents from "../../../services/calendarEvents";
import {removeFeed} from "../../../actions/home";
import {ClassDetails} from "../../../components/Stateless/ClassDetails";
import {globalStyles} from "../../../styles/Global";
import {boxHasShop, getToastDefaultConfig, HUGE_ICON_SIZE, isBoxIL} from "../../../helpers/functions";
import {scale, verticalScale} from "react-native-size-matters";
import {isTextRTLStyles} from "../../../styles/Layout";
import {MembershipSelectLoader} from "../../../loaders";
import uuid from 'uuid';

import {DrawerHeader} from "../../Stateless/DrawerHeader";
import {
    INSERT_SCHEDULE_USER,
    INSERT_STAND_BY,
    CANCEL_SCHEDULE_USER,
    CANCEL_WAIT_LIST,
    PAST,
    INFO,
    SUCCESS_OPTIONS,
    INVITE_FRIENDS,
    ADD_TO_CALENDAR
} from "../../../constants/booking";
import {AppText} from "../../Stateless/AppText";
import {AntDesign} from '@expo/vector-icons';
import {IconTextButton} from "../../Stateless/IconTextButton";
import {GraySeparator} from "../../Stateless/GraySeparator";
import {apiAction} from "../../../helpers/HTTP";
import BookingError from "./error";
import * as Linking from 'expo-linking';
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import CheckBox from "../../MembershipList/CheckBox";
import CustomCheckBox from "../../Stateless/CustomCheckBox";
import MembershipExpiredDrawer from './membershipExpiredDrawer';

const cancelArray = [CANCEL_SCHEDULE_USER, CANCEL_WAIT_LIST];

class Booking extends React.PureComponent {
    state = {
        modalStep: this.props.initStep,
        checkedMembershipIndex: 0,
        addingCalendar: false,
        addedCalendar: false,
        selectedFriends: [],
        isLoading: true,
        submitting: false,
        information: this.props.information,
        lateCancel: false,
        showCalendars: false,
        checkedIndex: 0
    };


    constructor(props) {
        super(props);
        this.checkMembership = this.checkMembership.bind(this);
        this.toggleAddToCalendar = this.toggleAddToCalendar.bind(this);
        this.toggleInviteFriends = this.toggleInviteFriends.bind(this);
        this.setSelectedFriends = this.setSelectedFriends.bind(this);
        this.moveToNextStep = this.moveToNextStep.bind(this);
        this.handleActionClick = this.handleActionClick.bind(this);
        this.addToCalendar = this.addToCalendar.bind(this);
        this.getCalenders = this.getCalenders.bind(this);
        this.chooseMembership = this.chooseMembership.bind(this);
        this.stepBack = this.stepBack.bind(this);
        this.handleScheduleCancel = this.handleScheduleCancel.bind(this);
        this.handleCancelInit = this.handleCancelInit.bind(this);
        this.lastStep = null;
        this.initBookingStatus = this.state.information.booking_option;
    }

    async componentDidMount() {
        this.handleCancelInit();
        const {information} = this.props;
        const memberships = await this.props.fetchUserMembershipsByBox(information.box_fk, 1);
        if (this.state.modalStep !== 'info' && memberships && memberships.data.length === 0) {
            this.setState({noMemberships: true})
        }
    }


    async handleCancelInit() {
        if (this.state.modalStep === 'cancelScheduleUser') {
            this.setState({isLoading: true, error: null});
            try {
                const res = await apiAction(`scheduleUser/checkLateCancel`, 'post', {schedule_id: this.state.information.id});
                this.setState({isLoading: false});
            } catch (error) {
                this.setState({isLoading: false});
                if (error.error.code === 513) {
                    this.setState({lateCancel: true});
                } else {
                    this.setState({lateCancel: false});
                    this.setState({error: error.error});
                }
            }
        }
    }

    async closeModal(toast) {
        if (toast) {
            const cancel = cancelArray.includes(this.initBookingStatus);
            const text = cancel ?
                this.initBookingStatus === CANCEL_SCHEDULE_USER ? 'common:toast:scheduleCancel' : 'common:toast:standByCancel'
                :
                this.initBookingStatus === INSERT_SCHEDULE_USER ? 'common:toast:scheduleInsert' : 'common:toast:standByInsert';
            const options = getToastDefaultConfig();
            Toast.show(t(text, {}), {
                backgroundColor: cancel ? Colors.red : Colors.brandSuccess,
                ...options
            });
            if (this.props.type === 'feed') {
                this.props.removeFeed(this.props.information.feed_id, null)
            }
            // BAD but cant block rendering.
            cancelArray.includes(this.initBookingStatus) ? null : this.props.insertSuccess(this.state.information, this.props.type);
            if (this.props.type === 'byDate') {
                const between = moment.utc(this.props.information.date);
                await this.props.fetchWeeklySchedules(between.clone().startOf('month'), between.clone().endOf('month'), this.props.information.locations_box.id);
            }
        }
        this.props.closeDrawer();
    }


    stepBack() {
        if (this.lastStep) {
            this.setState({modalStep: this.lastStep})
        } else {
            this.props.closeDrawer();
        }
    }


    checkMembership(index) {
        this.setState({checkedMembershipIndex: index});
    }

    toggleAddToCalendar() {
        this.setState(prevState => ({addToCalendarFlag: !prevState.addToCalendarFlag}));
    }

    toggleInviteFriends() {
        this.lastStep = this.state.modalStep;
        this.setState({modalStep: INVITE_FRIENDS});
    }


    setSelectedFriends(selectedFriendsArray) {
        this.setState({selectedFriends: selectedFriendsArray});
    }


    moveToNextStep() {
        this.setState({modalStep: this.state.information.booking_option});
    }


    async addToCalendar() {
        const {information} = this.props;
        const startDate = new Date(moment(information.date + " " + information.time));
        const endDate = new Date(moment(information.date + " " + information.end_time));
        const eventDetails = {
            title: information.box_categories.name,
            startDate,
            endDate,
            location: information.locations_box.location,
        };
        try {
            const event = await calendarEvents.createEvent(this.state.calenders[this.state.checkedIndex], eventDetails);
            this.setState({showCalendars: false, addingCalendar: false, addedCalendar: true})
        } catch (e) {
            const options = getToastDefaultConfig();
            Toast.show((t('common:toast:calendarReadOnly', {})), {
                backgroundColor: Colors.brandedRed,
                ...options,
                position: Toast.positions.TOP + 20
            });
        }


        // this.setState({addingCalendar: false, addedCalendar: true});
    }

    async getCalenders() {
        let calenders = await calendarEvents.getCalenders();
        //When null - user will replay as
        if (calenders !== null) {
            if (Platform.OS.toLowerCase() === 'android') {
                calenders.sort((x, y) => (x.isPrimary === y.isPrimary) ? 0 : x ? -1 : 1)
            }
            calenders = calenders.filter(item => item.allowsModifications);
            calenders = calenders.splice(0, 5);
            if (calenders.length === 0) {
                const options = getToastDefaultConfig();
                Toast.show((t('common:toast:calendarNotFound', {})), {
                    backgroundColor: Colors.brandedRed,
                    ...options,
                    position: Toast.positions.TOP + 20
                });
            } else {
                this.setState({calenders, addingCalendar: true, showCalendars: true})
            }
        }
    }


    async handleActionClick() {
        this.setState({submitting: true});
        //TODO CAN BE REDUCE
        if (cancelArray.includes(this.state.modalStep)) {
            this.handleScheduleCancel()
        } else {
            const is_schedule = this.state.information.booking_option === INSERT_SCHEDULE_USER;
            const res = await this.props.insert(this.state.information.booking_option, this.state.information.id, this.props.memberships[this.state.checkedMembershipIndex].id, this.props.type);
            const {scheduleActionError} = this.props;
            if (isEmpty(scheduleActionError)) {
                this.setState({information: res});
                is_schedule ? this.setState({modalStep: SUCCESS_OPTIONS}) : this.closeModal(true);
            } else {
                this.setState({error: scheduleActionError})
            }
        }
    }

    async handleScheduleCancel() {
        const is_schedule = this.state.information.booking_option === CANCEL_SCHEDULE_USER;
        if (is_schedule) {
            await this.props.deleteScheduleUser(this.state.information.user_booked, this.state.information.id, this.props.reducerActionName, this.props.type, this.state.lateCancel);
        } else {
            await this.props.deleteStandBy(this.state.information.user_in_standby, this.props.reducerActionName, this.props.type);
        }
        const {scheduleActionError} = this.props;
        if (isEmpty(scheduleActionError)) {
            this.closeModal(true);
        } else {
            this.setState({error: scheduleActionError})
        }
    }

    actionButton() {
        if (this.state.information.booking_option === PAST)
            return null;
        const {color, text} = this.getTextAndColors();
        return (
            <View key={uuid.v4()} styles={globalStyles.marginTopBetweenElements}>
                <ModalButton
                    onPress={() => this.handleActionClick()}
                    type='primary'
                    colors={color}
                    text={text}
                    textColor={Colors.white}
                    submitting={this.state.submitting}
                />
                {this.state.information.booking_option === CANCEL_SCHEDULE_USER || this.state.information.booking_option === CANCEL_WAIT_LIST ?
                    (
                        <ModalButton
                            onPress={() => this.props.closeDrawer()}
                            type='secondary'
                            overrideStyle={{marginTop: verticalScale(10)}}
                            colors={Colors.transparent}
                            text={t('modals:Card:keep-it-on', {})}
                            textColor={Colors.grayText}
                        />
                    ) : null}
            </View>
        )
    }

    getTextAndColors() {
        switch (this.initBookingStatus) {
            case INSERT_SCHEDULE_USER:
                return {text: t('modals:Card:confirm', {}), color: this.props.whiteLabelProperties.primaryColor};
            case CANCEL_SCHEDULE_USER:
                return {text: t('modals:CancelClass:button', {}), color: Colors.red};
            case INSERT_STAND_BY:
                return {text: t('modals:Card:add-to-waitlist', {}), color: Colors.yellow};
            case CANCEL_WAIT_LIST:
                return {text: t('modals:Card:yes-remove-me', {}), color: Colors.red};
            default:
                return '';
        }
    }

    chooseMembership() {
        const {checkedMembershipIndex} = this.state;
        const {memberships} = this.props;
        if (cancelArray.includes(this.initBookingStatus) || this.state.information.booking_option === PAST) {
            return null;
        }
        return (
            !memberships ? (<MembershipSelectLoader key={uuid.v4()} items={1}/>) : (<View key={uuid.v4()}>
                    {memberships && memberships.length > 0 ?
                        <View style={styles.wrapWithTopBorder}>
                            <AppText
                                style={styles.membershipListText}>{t('modals:BookClass:choose-membership', {})}</AppText>
                            <MembershipList
                                list={memberships}
                                checkedIndex={checkedMembershipIndex}
                                checkMembership={this.checkMembership}
                            />
                        </View> : null}
                </View>
            )
        )
    }

    getActions() {
        const {information} = this.state;
        if (information.booking_option === PAST) {
            if (information.user_booked !== null && information.live_link) {
                return (
                    <View key={uuid.v4()} style={[globalStyles.flexDirectionRowSpaceBetween, styles.wrapWithTopBorder]}>
                        <IconTextButton
                            onPress={() => Linking.openURL(information.live_link)}
                            iconType='MaterialIcons'
                            iconName='tv'
                            textColor={Colors.green}
                            iconSize={40}
                            text={t('screens:Schedule:card:online-class', {})}
                        />
                    </View>
                )
            } else {
                return null;
            }
        }
        if (information.user_booked !== null) {
            if (this.state.showCalendars) {
                return this.calendersView();
            }
            return (
                <View key={uuid.v4()} style={[globalStyles.flexDirectionRowSpaceBetween, styles.wrapWithTopBorder]}>
                    <IconTextButton
                        onPress={this.toggleInviteFriends}
                        iconType='AntDesign'
                        iconName='addusergroup'
                        textColor={Colors.black}
                        iconSize={40}
                        text={t('modals:BookClass:invite-friends', {})}
                    />
                    <IconTextButton
                        onPress={this.getCalenders}
                        iconType='AntDesign'
                        iconName='calendar'
                        textColor={this.state.addedCalendar ? Colors.green : Colors.black}
                        iconSize={40}
                        text={t('modals:BookClass:add-in-calendar', {})}
                        disabled={this.state.addingCalendar}
                    />
                </View>
            )
        }
        return this.actionButton();
    }

    calendersView() {
        return (
            <View key={uuid.v4()}>
                <AppText
                    style={styles.membershipListText}>{t('common:drawer:booking:chooseCalendar', {})}</AppText>
                {
                    this.state.calenders.map((item, index) => {
                        return (
                            <CustomCheckBox
                                key={index}
                                index={index}
                                label={item.title}
                                checked={this.state.checkedIndex === index}
                                check={() => this.setState({checkedIndex: index})}
                            />
                        )
                    })}
                <ModalButton
                    onPress={() => this.addToCalendar()}
                    type='primary'
                    colors={this.props.whiteLabelProperties.primaryColor}
                    text={t('common:drawer:booking:addToCalendar', {})}
                    textColor={Colors.white}
                    submitting={false}
                />
            </View>
        )
    }

    render() {
        const {modalStep, information} = this.state;
        const {closeDrawer, user} = this.props;

        let content, header;

        switch (modalStep) {
            case INFO:
            case INSERT_SCHEDULE_USER:
            case INSERT_STAND_BY: {
                header = (
                    <DrawerHeader
                        title={information.box_categories.name}
                        closeText={t('common:drawer:close', {})}
                        closeDrawer={() => closeDrawer()}/>);
                content = ([<ClassInfo key={uuid.v4()} data={information} showDescription={!this.state.showCalendars}/>,
                    this.state.information.booking_option === PAST ? null : <GraySeparator key={uuid.v4()} height={1}/>,
                    this.chooseMembership(), this.getActions()]);
                break;
            }
            case SUCCESS_OPTIONS:
                header = (<DrawerHeader title={information.box_categories.name} closeText={t('common:drawer:done', {})}
                                        closeDrawer={() => this.closeModal(true)}/>);
                const booked = (
                    <View key={uuid.v4()}
                          style={[globalStyles.flexDirectionRow, globalStyles.rowAlignItems, globalStyles.marginTopSm]}>
                        <AppText
                            style={[globalStyles.heeboMedium, {color: Colors.green}]}>{t('common:drawer:booking:booked', {})}</AppText>
                        <AntDesign name="checkcircle" size={scale(14)} color={Colors.green}
                                   style={[globalStyles.paddingStart]}/>
                    </View>
                );
                content = ([booked, <ClassDetails key={uuid.v4()} noClassName={true} data={information}/>,
                    this.state.information.booking_option === PAST ? null : <GraySeparator key={uuid.v4()} height={1}/>,
                    this.getActions()]);
                break;
            case INVITE_FRIENDS:
                header = (<DrawerHeader title={t('modals:BookClass:invite-friends', {})}
                                        closeText={t('common:drawer:back', {})}
                                        closeDrawer={() => this.stepBack()}/>);
                content = (
                    <InviteFriendsList
                        bookedUsers={information.booked_users}
                        maxInvites={information.free}
                        scheduleId={information.id}
                        closeDrawer={this.props.closeDrawer}
                        friendConnection={user.friend_connection}
                    />
                );
                break;
            case CANCEL_WAIT_LIST:
            case CANCEL_SCHEDULE_USER:
                header = (
                    <View
                        style={[globalStyles.flexDirectionColumn, globalStyles.marginTopBetweenElements, globalStyles.marginBottomVS10, globalStyles.centerItems]}>
                        <Icons8Generator name={'trash-can'} fill={Colors.red} width={HUGE_ICON_SIZE}
                                         height={HUGE_ICON_SIZE}/>
                    </View>
                );
                const details = (
                    <ClassDetails key={uuid.v4()} data={information}>
                        {modalStep === CANCEL_SCHEDULE_USER ? (
                            <View style={[globalStyles.marginTopBetweenElements, globalStyles.flexDirectionColumn]}>
                                {this.state.lateCancel === true ? <AppText
                                    style={globalStyles.heeboMedium}>{t('modals:CancelClass:non-refundable', {})}</AppText> : <AppText
                                    style={globalStyles.heeboMedium}>{t('modals:CancelClass:are-you-sure-text', {})}</AppText>}
                            </View>
                        ) : null}
                    </ClassDetails>
                );
                content = ([details, this.actionButton()]);
                break;
            default:
                return null;
        }
        return (
            this.state.error ?
                <BookingError error={this.state.error} closeDrawer={this.props.closeDrawer}/> : 
            this.state.noMemberships ? (
                <MembershipExpiredDrawer 
                    closeDrawer={this.props.closeDrawer} 
                    phone={this.state.information.box.phone} 
                    boxHasShop={boxHasShop(this.props.user.users_boxes, this.props.user.lastEndedMembership.box_fk)}
                    lastEndedMembership={user.lastEndedMembership}
                    isIL={isBoxIL(this.props.user.users_boxes, this.props.user.lastEndedMembership.box_fk)}
                />
            ) :
            (
                <React.Fragment>
                    {header}
                    {content}
                </React.Fragment>
            )
        );
    }
}


const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserMembershipsByBox,
            insert,
            insertSuccess,
            removeFeed,
            deleteScheduleUser,
            deleteStandBy,
            fetchWeeklySchedules
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    memberships: state.memberships.data,
    loadingMemberships: state.memberships.isLoading,
    scheduleActionLoading: state.schedule.action.isLoading,
    scheduleActionError: state.schedule.action.error.error,
    whiteLabelProperties: state.whiteLabelProperties,
    user: state.user.data,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(Booking);


const styles = StyleSheet.create({
    wrapWithTopBorder: {
        width: '98%',

    },
    membershipListText: {
        fontSize: scale(10),
        fontFamily: 'heebo-bold',
        color: Colors.modalFontColor,
        ...isTextRTLStyles,
    },
    bold: {
        fontFamily: 'heebo-bold',
        fontWeight: '600',
        color: Colors.modalFontColor,
        ...isTextRTLStyles,
    },

});