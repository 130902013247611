import React from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addToCart, removeFromCart} from "../../../actions/shop";
import {ScaledSheet, verticalScale, scale} from 'react-native-size-matters';
import {Colors} from "../../../styles/Colors";
import {globalStyles} from "../../../styles/Global";
import {getToastDefaultConfig} from "../../../helpers/functions";
import {t} from "../../../services/i18n";
import Toast from "react-native-root-toast";
import {withNavigation} from "react-navigation";
import ModalButton from "../../ModalButton";
import {ErrorHandler} from "../../ErrorHandler";

@withNavigation
class PurchaseActions extends React.PureComponent {

    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);

        this.state = {
            errors: false
        }
    }

    async componentDidMount() {}

    render() {
        const {errors}  = this.state;
        return (
            <View style={[globalStyles.stickyButtonsMarginBottom, {
                justifyContent: 'flex-end',
                paddingTop: verticalScale(10),
            }]}>
                {errors ? <ErrorHandler errors={errors}/> : null }
                <ModalButton
                    onPress={() => this.addToCart(true)}
                    type='primary'
                    colors={this.props.whiteLabelProperties.primaryColor}
                    text={t('screens:Purchase:buyNow',{})}
                    overrideStyle={{width: '90%', minHeight: verticalScale(30)}}
                    textColor={Colors.white}
                />
                <ModalButton
                    onPress={() => this.addToCart()}
                    type='secondary'
                    colors={Colors.white}
                    text={t('screens:Purchase:addToCart',{})}
                    overrideStyle={{minHeight: verticalScale(30),marginTop: verticalScale(10)}}
                    textColor={this.props.whiteLabelProperties.primaryColor}
                    textStyles={globalStyles.paddingStart}
                />
            </View>
        )
    }


    async addToCart(purchase) {
        try {
            const res = await this.props.addToCart(this.props.membership);
            const options = getToastDefaultConfig();
            if(res === true){
                if(purchase){
                    this.props.navigation.navigate('Cart');
                }else{
                    Toast.show(t('screens:Purchase:itemAddedToCart', {}), {
                        backgroundColor: Colors.brandSuccess,
                        ...options,
                        position: Toast.positions.TOP + 20
                    });
                }
            }else{
                this.setState({isLoading: false,errors:  [t('screens:Purchase:addToCartFailureTaxes', {name: this.props.membership.name})]})
            }
        } catch (e) {
            console.log('add to cart err', e);
        }
    }

    async removeFromCart() {
        await this.props.removeFromCart(this.props.membership);
    }
}


const styles = ScaledSheet.create({
    button: {
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            addToCart,
            removeFromCart,
        },
        dispatch,
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties,
    cart: state.shop.cart,
    addingToCart: state.shop.addingToCart,
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(PurchaseActions);


