import React from 'react';
import {Colors} from "../../../styles/Colors";
import {scale, verticalScale} from "react-native-size-matters";
import {globalStyles} from "../../../styles/Global";
import {TabView, TabBar} from 'react-native-tab-view';
import {AppText} from "../AppText";
import {View} from 'react-native';
import {TAB_VIEW_WIDTH} from "../../../variableConstants";

export const TabBarCustom = props => {
    const initialLayout = props.initialLayout ? props.initialLayout : TAB_VIEW_WIDTH;
    const overrideColorOnFocus = props.overrideColorOnFocus ?  props.overrideColorOnFocus : {};
    return (
        <TabBar
            {...props}
            getLabelText={({route}) => route.title}
            indicatorStyle={[{backgroundColor: props.indicatorColor}]}
            style={[{backgroundColor: props.backgroundColor},
                props.topBorder ? globalStyles.appTopBorderRadius : {},
                props.tabStyleProps ? {...props.tabStyleProps} : {}]}
            renderLabel={({route, focused}) => {
                return (
                    <View style={[{width: scale(initialLayout/props.numOfItems)}]}>
                        <AppText
                            style={[focused ? globalStyles.heeboMedium : globalStyles.heeboLight, {
                                textAlign: 'center',
                            },{...props.textProps}, focused ? overrideColorOnFocus : {}]}>
                            {route.title}
                        </AppText>
                    </View>
                )}}
        />
    )
};



