import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        fill="#191919"
        width="1em" height="1em"
        {...props}
    >
        <Path d="M0 9v2h50V9Zm0 15v2h50v-2Zm0 15v2h50v-2Z" />
    </Svg>
)

export default SvgComponent
