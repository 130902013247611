import React from 'react';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../AppText";
import {ActivityIndicator, TouchableOpacity} from 'react-native';
import {scale} from 'react-native-size-matters';
import {Icons8Generator} from "../Icons8Generator";
import {IconSize} from "../../../constants/iconSize";


export const IconTextButton = props => {
    return (
            <TouchableOpacity
                disabled={props.disabled ? props.disabled : false}
                style={[globalStyles.flexDirectionColumn,{flex:1,justifyContent:'center',alignItems:'center'}]}
                onPress={() => props.onPress()}
            >
                {props.submitting ? <ActivityIndicator  size="large" style={[globalStyles.activityIndicatorStyle, globalStyles.activityIndicatorStyleAbsolute]}/> : null}
                <Icons8Generator name={props.iconName} fill={props.textColor} size={props.iconSize || IconSize.XXL}/>
                <AppText
                    style={[
                        globalStyles.marginTopSm,
                        globalStyles.heeboRegular,
                        globalStyles.textCenter,
                        {color: props.textColor,
                            fontSize: scale(12)
                        },
                        props.textOverride  ? props.textOverride : {}
                    ]}
                >
                    {props.text}
                </AppText>
            </TouchableOpacity>
    )
};



