import * as React from 'react';
import { Text, View,StyleSheet,TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { scale,verticalScale,ScaledSheet } from 'react-native-size-matters';
import PropTypes from 'prop-types';
import { Colors } from '../../styles/Colors';
import {globalStyles} from "../../styles/Global";
import {isTextRTLStyles} from "../../styles/Layout";
import {AppText} from "../Stateless/AppText";
import {Icons8Generator} from "../Stateless/Icons8Generator";

class SectionHeading extends React.PureComponent {
  render() {
    return (
      <View style={[styles.headingBar,this.props.borderTop ? globalStyles.appTopBorderRadius : null]}>
        <AppText style={[globalStyles.heeboMedium,styles.headingBarText]}>{this.props.text}</AppText>
          {this.props.onPress && !this.props.isInEditMode ? (<TouchableOpacity
              onPress={() => {
                  this.props.onPress()
              }}
              style={styles.clickAbleIcon}
          >
              <Icons8Generator name={'pencil'} fill={Colors.black}/>
          </TouchableOpacity>) : null}
      </View>
    );
  }
}

SectionHeading.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string
};

export default SectionHeading;

const styles = ScaledSheet.create({
    headingBar: {
        flexDirection: 'row',
        flex:1,
        height:verticalScale(50),
        alignItems:'center',
        backgroundColor: '#fafafa',
        paddingHorizontal: scale(16),
    },
    headingBarIcon: {

        // marginRight: scale(5),
    },
    headingBarText: {
        fontSize: scale(12),
        color:Colors.black,
        textTransform: 'capitalize',
        ...isTextRTLStyles,
    },
    clickAbleIcon:{
        flexDirection:'row',
        justifyContent:'flex-end',
        flex:1,
        width:scale(30)
    }
});