import React from 'react';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../AppText";
import {View} from 'react-native';
import {ScaledSheet, verticalScale,scale} from "react-native-size-matters";
import {Colors} from "../../../styles/Colors";


export const SeparatorWithText = props => {
    return (
        <View style={[globalStyles.flexDirectionRow, styles.row, ...(props.style||[])]}>
            <View style={{height:0.5,flex:1,backgroundColor: props.color}}/>
            <AppText style={[globalStyles.heeboRegular,{color: Colors.grayText,marginHorizontal: scale(10)}, ...(props.textStyle||[])]}>{props.text}</AppText>
            <View style={{height:0.5,flex:1,backgroundColor: props.color}}/>
        </View>
    )
};


const styles = ScaledSheet.create({
    row: {
        height: verticalScale(40),
        alignItems:'center',
        justifyContent: 'space-between'
    }
});




