import * as React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {View, TouchableOpacity, Text} from 'react-native';
import {scale, verticalScale, ScaledSheet} from 'react-native-size-matters';
import {bindActionCreators} from 'redux'

import {friendRelationAction} from '../../actions/friend'
import {addFriend, fetchProfile} from '../../actions/user'
import {t} from '../../services/i18n';
import {Colors} from "../../styles/Colors";
import {FRIENDS_OF_FRIEND_ACTION} from "../../constants";
import {getUserFromFriendConnection} from "../../helpers/functions";
import {globalStyles} from "../../styles/Global";
import {AppText} from "../Stateless/AppText";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {getThemeProperty, keys} from "../../styles/Theme";


const DEFAULT_WARNING_ICON_COLOR = '#ed3269';
const DEFAULT_YELLOW_ICON_COLOR = 'rgba(255, 197, 6, 1)';
const DEFAULT_ADD_FRIEND_ICON = 'account-plus';
const DEFAULT_REMOVE_FRIEND_ICON = 'account-check';
const DEFAULT_SENT_FRIEND_ICON = 'call-made';
const DEFAULT_APPROVE_FRIEND_ICON = 'call-received';


// TODO: mocked onPress while adding/removing friend and clicking on club
class FriendsStatusAction extends React.Component {
    state = {
        buttonDisabled: false,
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.friendAction = this.friendAction.bind(this);

    }


    async componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async friendAction(friend, action) {
        if (action !== null) {
            // friend profile handles on different reducer!
            const actionType = this.props.reducerName;
            if (this._isMounted) {
                this.setState({buttonDisabled: true});
                let id = friend.id;
                if (actionType === FRIENDS_OF_FRIEND_ACTION) {
                    const user = getUserFromFriendConnection(friend);
                    if (user === null)
                        return null;
                    id = user.id;
                }
                const response = await this.props.friendRelationAction(actionType, id, action, this.props.user.id);
                //TODO it's a bad call, needs to find a more proper handler
                this.props.fetchProfile();
            }
            if (this._isMounted) {
                this.setState({buttonDisabled: false});
            }
        }
    }

    //TODO THIS IS WAY TO MESSY, CLEANUP IS NEEDED!
    render() {
        // is Profile button design is like isModal, but reducers responses isn't.
        const {friendConnection, textButtons, displaySmall} = this.props;
        const status = friendConnection.our_relation !== null ? friendConnection.our_relation.status : null;
        let action = null;
        let {textButton, iconButton, actionIconColor, actionIcon} = '';
        switch (status) {
            case 0: {
                // Request exist we'll need to know who created action
                if (friendConnection.our_relation.my_request) {
                    textButton = t('modals:ClassParticipants:sent-friend');
                    iconButton = DEFAULT_SENT_FRIEND_ICON;
                    actionIcon = DEFAULT_SENT_FRIEND_ICON;
                    actionIconColor = DEFAULT_YELLOW_ICON_COLOR;
                    action = 'abortFriendRequest';
                } else {
                    textButton = t('screens:FriendProfile:approve-friend');
                    iconButton = DEFAULT_APPROVE_FRIEND_ICON;
                    actionIcon = DEFAULT_APPROVE_FRIEND_ICON;
                    actionIconColor = this.props.whiteLabelProperties.primaryColor;
                    action = 'approveFriend';
                }
                break;
            }
            case 1: {
                // in modal we cant UNFRIEND!
                if (displaySmall) {
                    textButton = t('modals:ClassParticipants:is-friend');
                    action = null;
                    iconButton = DEFAULT_REMOVE_FRIEND_ICON;
                    actionIcon = DEFAULT_REMOVE_FRIEND_ICON;
                    actionIconColor = DEFAULT_WARNING_ICON_COLOR;

                } else {
                    textButton = t('screens:FriendProfile:unfriend');
                    iconButton = 'minus-circle';
                    action = 'removeFriend';
                    actionIcon = DEFAULT_ADD_FRIEND_ICON;
                    actionIconColor = this.props.whiteLabelProperties.primaryColor;
                }

                break;
            }
            default: {
                textButton = t('screens:FriendProfile:add-friend');
                iconButton = DEFAULT_ADD_FRIEND_ICON;
                action = 'addFriend';
                actionIcon = DEFAULT_ADD_FRIEND_ICON;
                actionIconColor = this.props.whiteLabelProperties.primaryColor;
                break;
            }

        }

        if (displaySmall === false && textButtons) {
            actionIcon = this.props.whiteLabelProperties.primaryColor;
            actionIconColor = Colors.white;
            if (status === 0 && friendConnection.our_relation.my_request) {
                actionIcon = this.props.whiteLabelProperties.primaryColor;
            }
        }

        if (displaySmall) {
            if (status !== 1)
                return null;
            return (
                <View>
                    <Icons8Generator name='user-following' fill={Colors.black}/>
                </View>
            )
        }

        return (
            // is Profile button design is like isModal, but reducers responses isn't.
            textButtons ? (
                    <TouchableOpacity
                        disabled={this.state.buttonDisabled}
                        small
                        style={[styles.editButton, {backgroundColor: actionIconColor}]}
                        onPress={() => {
                            this.friendAction(friendConnection, action)
                        }}
                    >
                        <Icons8Generator name={iconButton} fill={this.props.whiteLabelProperties.primaryColor}/>
                        <AppText
                            style={[globalStyles.heeboMedium, globalStyles.paddingStart, styles.editText, displaySmall ? {color: Colors.white} : {color: actionIcon}]}>{textButton}</AppText>
                    </TouchableOpacity>
                ) :
                (
                    <View>
                        <TouchableOpacity
                            onPress={() => {
                                this.friendAction(friendConnection, action)
                            }}
                            style={[styles.actionButton, {backgroundColor: actionIconColor}]}
                        >
                            <Icons8Generator name={actionIcon} fill={Colors.white}/>
                        </TouchableOpacity>
                    </View>
                )

        );
    }
}

FriendsStatusAction.propTypes = {
    friendConnection: PropTypes.object,
    textButtons: PropTypes.bool,
    friendRelationAction: PropTypes.func,
    displaySmall: PropTypes.bool,
    reducerName: PropTypes.string,
};
const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties,

});

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        friendRelationAction,
        addFriend,
        fetchProfile,
    }, dispatch);
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(FriendsStatusAction);

const styles = ScaledSheet.create({
    actionButton: {
        borderRadius: scale(3),
        alignItems: 'center',
        justifyContent: 'center',
        width: scale(30),
        height: scale(30)
    },
    editButton: {
        height: verticalScale(38),
        borderRadius: scale(3),
        paddingHorizontal: scale(15),
        alignItems: 'center',
        flexDirection: 'row'
    },
    editText: {
        fontSize: scale(13),
    },
    editIcon: {
        fontSize: scale(18),
    },
});