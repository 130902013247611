import React, {Component} from 'react';
import {Button, Icon, Text} from 'native-base';
import {View} from 'react-native';
import {withNavigationFocus} from 'react-navigation';
import {connect} from 'react-redux';
import {Colors} from '../../styles/Colors';
import styles from "../../components/LoginForm/styles";
import {i18n, t} from "../../services/i18n";

import {AppText} from "../../components/Stateless/AppText";
import {globalStyles} from "../../styles/Global";
import {ScaledSheet, scale, verticalScale} from "react-native-size-matters"
import {getThemeProperty, keys} from "../../styles/Theme";

const mapStateToProps = state => state;

@withNavigationFocus
@connect(mapStateToProps)
class CarouselFooterScreen extends Component {
    constructor(props) {
        super(props);
        this.nextHandler = this.nextHandler.bind(this);
    }

    componentDidMount() {
        this.themeColor = getThemeProperty(keys.brandedGreen);
        this.themeColorRGBA = getThemeProperty(keys.brandedGreenRGBA)
    }


    nextHandler(currentSlideName, nextSlideName) {
        this.props.onButtonClick(currentSlideName, nextSlideName);
    }


    render() {
        const childRouters = Object.keys(this.props.router.childRouters).filter(item => item !== 'Camera').map(key => key);
        const {routes} = this.props.navigation.state;
        const currentSlideName = routes[routes.length - 1].routeName;
        const indicatorsLength = childRouters.length;
        const lastSlideName = childRouters[childRouters.length - 1];
        const isLastSlide = lastSlideName === currentSlideName;
        const nextSlideName = childRouters[childRouters.indexOf(currentSlideName) + 1] || lastSlideName;
        const isValidateCurrentScreen =
            this.props.form[currentSlideName] && !!this.props.form[currentSlideName].syncErrors;
        const renderIndicator = childRouters.map(key => {
            if (key === currentSlideName) {
                return (
                    <Icon
                        key={key}
                        type="Octicons"
                        name="primitive-dot"
                        style={{color: isLastSlide ? Colors.white : this.themeColor, fontSize: 23, marginHorizontal: 5}}
                    />
                );
            }
            return (
                <Icon
                    key={key}
                    type="Octicons"
                    name="primitive-dot"
                    style={{
                        color: isLastSlide ? Colors.lightWhiteRGBA : this.themeColorRGBA,
                        fontSize: 15,
                        marginHorizontal: 5
                    }}
                />
            );
        });
        return (

            <View
                style={[{
                    flexDirection: 'column', flex: 0,
                    justifyContent: "flex-end",
                    backgroundColor: isLastSlide ? this.themeColor : Colors.transparent
                }, globalStyles.appPaddingHorizontal]}
            >
                {this.props.error ? (
                    <AppText style={styles.errorText}>
                        {this.props.error.messageToUser !== null ? this.props.error.messageToUser : this.props.error.message}
                    </AppText>
                ) : null}
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 20
                    }}
                >
                    {renderIndicator}
                </View>
                <Button
                    light
                    disabled={isValidateCurrentScreen || this.props.submitting}
                    onPress={() => {
                        isLastSlide
                            ? this.props.navigation.navigate('HomeStack')
                            : this.nextHandler(currentSlideName, nextSlideName);
                    }}
                    style={{
                        marginBottom: verticalScale(30),
                        justifyContent: 'center',
                        alignSelf: 'center',
                        flexDirection: 'row',
                        width: '80%',
                        borderRadius: scale(3),
                        opacity: !isValidateCurrentScreen ? 1 : 0.6,
                        backgroundColor: isLastSlide ? Colors.white : this.themeColor
                    }}
                >
                    <AppText
                        style={[globalStyles.heeboMedium, {
                            color: isLastSlide ? this.themeColor : Colors.white,
                        }]}
                    >
                        {isLastSlide ? t('common:letsGo', {}) : t('common:next', {})}
                    </AppText>
                </Button>
            </View>
        );
    }
}

export default CarouselFooterScreen;
