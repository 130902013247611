import React from 'react';
import {scale} from 'react-native-size-matters';
import {RoundIcon} from "../RoundIcon";
import UserAvatar from 'react-native-user-avatar';


export const RoundedImageOrIcon = props => {
    const {displayImage,obj,imageSize} = props;
    return (
        displayImage ? (
                <UserAvatar size={scale(imageSize ? imageSize : 60 )}
                            name={obj.first_name + ' ' + obj.last_name}
                            src={obj.image ? obj.image : null}
                />
            ) : <RoundIcon height={scale(imageSize ? imageSize : 60)} noMargin={true} name={'gymnastics'}/>
    )
};



