import React, {Component} from 'react';
import {Form, H1, H2, Icon, Input, Item, Label, Text, Button} from 'native-base';
import {
    View,
    TouchableOpacity,
    KeyboardAvoidingView,
    SafeAreaView,
    TouchableWithoutFeedback,
    Keyboard
} from 'react-native';
import {Field, reduxForm, reset} from 'redux-form';
import {store} from "../../../config/store";
import {LinearGradient} from 'expo-linear-gradient';

import {withNavigation} from 'react-navigation';
import {Colors} from '../../../styles/Colors';
import styles from './styles';
import {formValueSelector} from 'redux-form';  // ES6
import AppHeader from "../../../components/Stateless/AppHeader";

import {t} from "../../../services/i18n";
import {apiAction} from "../../../helpers/HTTP";
import {globalStyles} from "../../../styles/Global";

const minLength = min => value =>
    value && value.length < min ? t('screens:OnBoarding:passwordMinChar', {min}) : undefined;
const passwordMinLength8 = minLength(6);

import {
    passwordRequired
} from '../../../helpers/Validators';
import FloatingTextInput from "../../../components/Stateless/FloatingTextInput";
import {AppText} from "../../../components/Stateless/AppText";
import Toast from "react-native-root-toast";
import {getToastDefaultConfig, HUGE_ICON_SIZE} from "../../../helpers/functions";
import {LinearWrapper} from "../../../components/Stateless/LinerWrapper";
import {FormButton} from "../../../components/Stateless/FormButton";
import MoveToBottom from "../../../components/Stateless/MoveToBottom";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {getThemeProperty, keys} from "../../../styles/Theme";

@withNavigation
class ChangePasswordScreen extends Component {
    static navigationOptions = {
        header: null
    };

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            error: null,
            eye: true,
            eye_current: true
        };
        this.submit = this.submit.bind(this);
    }


    async submit() {
        this.setState({submitting: true, error: null});
        try {
            const selector = formValueSelector('ChangePassword');
            const state = store.getState();
            const values = selector(state, 'password', 'currentPassword');
            const data = {
                old_password: values.currentPassword,
                new_password: values.password
            };
            const res = await apiAction(`user/changePassword`, 'post', {...data});
            this.props.dispatch(reset('ChangePassword'));
            this.setState({submitting: false, error: null}, () => {
                const options = getToastDefaultConfig();
                Toast.show(t('screens:More:settings:passwordChanged', {}), {
                    backgroundColor: Colors.brandSuccess,
                    ...options
                });
                this.props.navigation.goBack();
            });
        } catch (error) {
            this.setState({submitting: false, error: error.error});
        }
    }

    handleEye(current) {
        if (current) {
            this.setState({eye_current: !this.state.eye_current});
        } else {
            this.setState({eye: !this.state.eye});
        }
    }

    componentDidMount(){
        this.themeColor = getThemeProperty(keys.brandedGreen);
    }

    render() {
        return (
            <LinearWrapper
                backgroundStart={Colors.white}
                backgroundEnd={Colors.white}
                barStyle="light-content"
                spinner={this.state.submitting}>
                <AppHeader headerColor={this.themeColor}/>
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <View
                        style={[{flex: 1}, globalStyles.marginTopBetweenElementsBig, globalStyles.appPaddingHorizontal]}>
                        <View>
                            <View
                                style={[globalStyles.centerItems]}>
                                <Icons8Generator name={'lock'}  width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                            </View>
                            <AppText
                                style={[globalStyles.heeboMedium, styles.h1, globalStyles.marginTopBetweenElements]}>{t('screens:More:settings:account:updatePassword', {})}</AppText>
                            <Form>
                                <View style={[globalStyles.marginTopBetweenElements]}>
                                    <TouchableOpacity style={globalStyles.iconOverTextInput}
                                                      onPress={() => {
                                                          this.handleEye(true)
                                                      }}>
                                        <Icon type="Feather"
                                              name={this.state.eye_current === true ? 'eye' : 'eye-off'}
                                              style={{
                                                  fontSize: 20,
                                                  color: Colors.black
                                              }}/>
                                    </TouchableOpacity>
                                    <Field
                                        name="currentPassword"
                                        secureTextEntry={this.state.eye_current}
                                        component={FloatingTextInput}
                                        placeholder={t('screens:OnBoarding:currentPassword', {})}
                                        validate={[passwordRequired]}
                                    />
                                </View>
                                <TouchableOpacity style={globalStyles.iconOverTextInput}
                                                  onPress={() => {
                                                      this.handleEye(false)
                                                  }}>
                                    <Icon type="Feather"
                                          name={this.state.eye === true ? 'eye' : 'eye-off'}
                                          style={{
                                              fontSize: 20,
                                              color: Colors.black
                                          }}/>
                                </TouchableOpacity>
                                <Field
                                    name={"password"}
                                    component={FloatingTextInput}
                                    secureTextEntry={this.state.eye}
                                    placeholder={t('screens:OnBoarding:newPassword', {})}
                                    validate={[passwordRequired, passwordMinLength8]}
                                />
                            </Form>
                        </View>
                        {
                            MoveToBottom(
                                <View style={[globalStyles.appPaddingHorizontal]}>
                                    <FormButton onPress={() => this.submit()} text={t('common:save', {})}/>
                                </View>
                            )
                        }

                    </View>
                </TouchableWithoutFeedback>

            </LinearWrapper>

        )
    }
}

export default reduxForm({
    form: 'ChangePassword',
    destroyOnUnmount: false
})(ChangePasswordScreen);
