// @flow
import * as React from 'react';
import PopupModal from "./Common/PopupModal";
import {Image, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import {AppText} from "../Stateless/AppText";
import {scale, verticalScale} from "react-native-size-matters";
import User from "../../svg/Icons8MaleUser";
import {useDispatch, useSelector} from "react-redux";
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {logoutUser} from "../../actions/auth";
import {t} from "../../services/i18n";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import EStyleSheet from "react-native-extended-stylesheet";
import {ActivityIndicator, Linking} from "react-native-web";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {SITE_REFERER} from "../../constants";
import {apiAction} from "../../helpers/HTTP";
import {changeSiteLang} from "../../helpers/ComponentFunctionality";

export const MobileNavigationMenu = (props) => {
    const {show, setClose, handleShowLogin} = props;
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const userProfile = useSelector(state => state.user.data)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const referer = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.referer)
    const appLinkFromStore = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.appLink.data)
    const selectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const dispatch = useDispatch()
    const [actions, setActions] = useState([]);
    const [logging, setLogging] = useState(false);
    const [appLink, setAppLink] = useState(null);
    const [isSite, setIsSite] = useState(referer);

    const handleLogout = async () => {
        setLogging(true)
        dispatch(logoutUser())
    }

    useEffect(() => {
        if(appLinkFromStore) {
            setAppLink(appLinkFromStore)
        }
    }, [appLinkFromStore]);

    useEffect(() => {
        if(!isLoggedIn) {
            setLogging(false)
        }
    }, [isLoggedIn]);

    useEffect(() => {
        setIsSite(referer === SITE_REFERER)
    }, [referer]);

    const changeLang = (lang) => {
        changeSiteLang(lang, dispatch, true)
    }

    const routeClicked = (routeName) => {
        if(routeName === location.pathname) {
            location.href = `${window.location.origin}${window.location.pathname}${queryString}`
        }
        setClose()
    }

    useEffect(() => {
        const actions = [
            {
                icon: 'home',
                text: t('headings:home', {}),
                separator: false,
                parent: props =>
                    <Link to={{pathname: '/', search:boxQueryString || location.search}} style={{textDecoration: 'none'}} onClick={() => routeClicked('/')}>
                        <View style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                            <Icons8Generator style={styles.menuIcon} name={'home'}/>
                            {props.children}
                        </View>
                    </Link>,
                show: isSite
            },
            {
                icon: 'calendar-booked',
                text: t('screens:Schedule:title', {}),
                separator: false,
                parent: props =>
                    <Link to={{pathname: '/schedule', search:boxQueryString || location.search}} style={{textDecoration: 'none'}} onClick={() => routeClicked('/schedule')}>
                        <View style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                            <Icons8Generator style={styles.menuIcon} name={'calendar-booked'}/>
                            {props.children}
                        </View>
                    </Link>,
                show: isSite
            }, {
                icon: 'shopaholic',
                text: t('headings:online-shop', {}),
                separator: true,
                parent: props =>
                    <Link to={{pathname: '/shop', search:boxQueryString || location.search}} style={{textDecoration: 'none'}} onClick={() => routeClicked('/shop')}>
                        <View style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                            <Icons8Generator style={styles.menuIcon} name={'shopaholic'}/>
                            {props.children}
                        </View>
                    </Link>,
                show: isSite
            }, {
                icon: 'mobile-phone',
                text: t('headings:download-app', {}),
                separator: true,
                parent: props =>
                    <TouchableOpacity onPress={()=> Linking.openURL(appLink)} style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                        <Icons8Generator style={styles.menuIcon} name={'mobile-phone'}/>
                        {props.children}
                     </TouchableOpacity>,
            }, {
                icon: 'mobile-phone',
                text: box?.phone,
                separator: false,
                parent: props =>
                    <TouchableOpacity onPress={() => Linking.openURL(`tel:${box?.phone}`)} style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                        <Icons8Generator style={styles.menuIcon} name={'mobile-phone'}/>
                        {props.children}
                    </TouchableOpacity>,

            }, {
                icon: 'email',
                text: box?.email,
                separator: true,
                parent: props =>
                    <TouchableOpacity onPress={() => Linking.openURL(`mailto:${box?.email}?subject=SendMail&body=Description`)} style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                        <Icons8Generator style={styles.menuIcon} name={'email'}/>
                        {props.children}
                    </TouchableOpacity>,

            },
            {
                icon: 'lang-blob',
                text: '',
                separator: false,
                parent: props =>
                    <>
                        {selectedLocation?.country_code.toUpperCase() ===  'IL' &&
                        <View style={[globalStyles.flexDirectionRow, styles.btnWrapper]}>
                            <Icons8Generator name={'lang-blob'} size={'1.5em'}/>
                            <View style={[styles.singleInfo, {gap: '0.5em'}]}>
                                <TouchableOpacity onPress={() => changeLang('he')}>
                                    <AppText style={[styles.text]}>עברית</AppText>
                                </TouchableOpacity>
                                <AppText style={[styles.text]}>/</AppText>
                                <TouchableOpacity onPress={() => changeLang('en')}>
                                    <AppText style={[styles.text]}>English</AppText>
                                </TouchableOpacity>
                            </View>
                        </View>
                        }
                    </>,

            }
        ];
        setActions(actions)
    }, [box]);


    return (
        <PopupModal show={show} setClose={setClose}>
            <View style={styles.wrapper}>
                {box?.cloudinary_image && <Link to={{pathname: '/', search:boxQueryString || location.search}} onClick={() => routeClicked('/')}><Image style={[styles.logo]} source={box?.cloudinary_image}/></Link>}
                <View style={[globalStyles.flexDirectionRow, styles.userWrapper]}>
                    {logging ? <ActivityIndicator color={Colors.brandedBlue}/> :
                        <>
                        {isLoggedIn && userProfile.image ?
                            <img style={{width: scale(60), height: scale(60), borderRadius: '50%'}}
                                 src={userProfile.image}/>
                            :
                            <User style={styles.mobileUserImg} fill={Colors.iconEmptyStateColor}/>
                        }
                        <View>
                            <AppText style={styles.userTitle}>{isLoggedIn ? userProfile.full_name : t('common:drawer:guest',{})}</AppText>
                            {
                                isLoggedIn ?
                                    <TouchableOpacity onPress={handleLogout}><AppText
                                        style={styles.userAction}>{t('screens:More:logout', {})}</AppText></TouchableOpacity>
                                    :
                                    <View style={[globalStyles.flexDirectionRow]}>
                                        <TouchableOpacity onPress={handleShowLogin}><AppText style={styles.userAction}>{t('screens:Login:login', {})}</AppText></TouchableOpacity>
                                    </View>
                            }
                        </View>
                        </>
                    }
                </View>
                <View style={styles.menuWrapper}>
                    <View style={styles.separatorStyle}/>
                    {actions.map(action => {
                        const Parent = action.parent;
                        if(action.show === false) return null;
                        return  <React.Fragment key={`action-${action.text}`}>
                                    <View style={[globalStyles.flexDirectionRow, styles.btnWrapper]} >
                                        <Parent>
                                            <AppText style={styles.btnText}>{action.text}</AppText>
                                        </Parent>
                                    </View>
                                    { action.separator && <View style={styles.separatorStyle}/>}
                                </React.Fragment>
                    })}
                </View>
            </View>
        </PopupModal>
    );
};

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                paddingHorizontal: scale(15)
            },
            logo: {
                width: scale(80),
                height: scale(80),
                marginBottom: verticalScale(10),
                borderRadius: 5
            },
            userWrapper: {
                gap: scale(15),
                alignItems: 'center',
                marginBottom: verticalScale(20)
            },
            mobileUserImg: {
                width: scale(60),
                height: scale(60)
            },
            userTitle: {
                fontSize: scale(20)
            },
            separatorStyle: {
                height: 1,
                width: '90%',
                backgroundColor: Colors.listSeparator
            },
            menuWrapper: {
                gap: verticalScale(30)
            },
            btnWrapper: {
                gap: scale(25),
                alignItems: 'center'
            },
            menuIcon: {
                width: scale(25),
                height: scale(25)
            },
            btnText: {
                fontSize: scale(15)
            },
            verticalseparator: {
                width: 1,
                height: "auto",
                backgroundColor: Colors.iconEmptyStateColor,
                marginHorizontal: scale(20)
            },
            userAction: {
                color: Colors.blue
            },
            singleInfo: {
                flexDirection: "row",
                gap: '1em',
                alignItems: 'center',
            },
        }
    })
})