import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';
import {createBottomTabNavigator} from 'react-navigation-tabs';
import {HomeStack} from './HomeStack';
import {ProfileStack} from './ProfileStack';
import {ScheduleStack} from './ScheduleStack';
import {MarketStack} from './MarketStack';
import {MoreStack} from './MoreStack';
import CameraContainer from '../containers/CameraContainer';
import {Colors} from "../styles/Colors";

const getScreenRegisteredFunctions = navState => {
    // When we use stack navigators.
    // Also needed for react-navigation@2
    const {routes, index, params} = navState;
    if (navState.hasOwnProperty('index')) {
        return getScreenRegisteredFunctions(routes[index]);
    }
    // When we have the final screen params
    else {
        return params;
    }
};


const tabNavigator = createBottomTabNavigator(
    {
        HomeStack,
        ProfileStack,
        ScheduleStack,
        MarketStack,
        MoreStack,

    },
    {
        tabBarOptions: {
            showLabel: false,
            style: {
                borderTopColor: Colors.graySeparator,
                borderTopWidth:0.3
            },
        },
        defaultNavigationOptions: ({navigation}) => ({
            tabBarOnPress: ({navigation, defaultHandler}) => {
                if (navigation && navigation.isFocused()) {
                    const screenFunctions = getScreenRegisteredFunctions(navigation.state);
                    if (screenFunctions && typeof screenFunctions.tapOnTabNavigator === 'function') {
                        screenFunctions.tapOnTabNavigator()
                    }
                }
                switch (navigation.state.routeName) {
                    case 'HomeStack': {
                        navigation.navigate("HomeScreen");
                        break;
                    }
                    case 'ProfileStack': {
                        navigation.navigate("Profile");
                        break;
                    }
                    case 'ScheduleStack': {
                        navigation.navigate("Schedule");
                        break;
                    }
                    case 'MoreStack': {
                        navigation.navigate("More");
                        break;
                    }
                }
                defaultHandler()
            },
        }),
    }
);

export default createStackNavigator(
    {
        tabNavigator,
        Camera: {
            screen: CameraContainer,
            navigationOptions: {
                tabBarVisible: false
            }
        },
    },
    {
        headerMode: 'none',
        lazy: true
    }
);
