import React from 'react';
import {View} from 'react-native';
import { ScaledSheet, verticalScale } from 'react-native-size-matters';
import { t } from '../../../services/i18n';
import { Colors } from '../../../styles/Colors';
import { globalStyles } from '../../../styles/Global';
import MembershipExpiredContent from '../../MembershipExpiredContent';
import ModalButton from '../../ModalButton';
import { DrawerHeader } from '../../Stateless/DrawerHeader';
class MembershipExpiredDrawer extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const {phone, lastEndedMembership, boxHasShop, isIL} = this.props;
        const iconSize = 30;
        return (
        <View>
            <DrawerHeader
                title={t("screens:Home:cards:expired-card:drawerTitle")}
                closeText={t('common:drawer:close', {})}
                closeDrawer={() => this.props.closeDrawer()}
            />
            <MembershipExpiredContent 
                phone={phone} 
                membershipType={lastEndedMembership.membership_types} 
                iconSize={iconSize} 
                boxHasShop={boxHasShop}
                isIL={isIL}
                onLinkToShop={this.props.closeDrawer}
            />
        </View>
        );
    }
}

export default MembershipExpiredDrawer;


