import * as React from 'react';
import Modal from 'react-native-modal';
import PropTypes from 'prop-types';
import {View, Icon} from 'native-base';
import {Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, FlatList, SafeAreaView} from 'react-native';
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';
import {Colors} from '../../styles/Colors';
import ModalButton from '../ModalButton';
import {scale} from 'react-native-size-matters';
import {t} from "../../services/i18n";
import {globalStyles} from "../../styles/Global";
import {UnicodeHelper} from "../Stateless/UnicodeHelper";
import i18n from "../../services/i18n";
import {isTextRTLStyles} from "../../styles/Layout";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {getThemeProperty, keys} from "../../styles/Theme";


const DEFAULT_ICON_SIZE = 50;
const DEFAULT_HEADING_ICON_SIZE = scale(15);
const DEFAULT_HEADING_TEXT_COLOR = 'white';

class ModalSimple extends React.PureComponent {

    componentDidMount(){
        this.themeColor = getThemeProperty(keys.brandedGreen);
    }

    renderHeading() {
        const {modalType, error} = this.props;
        let color = null;
        if (modalType) {
            switch (modalType) {
                case 'success':
                    color = Colors.brandSuccess;
                    break;
                case 'danger':
                    color = Colors.brandDanger;
                    break;
                case 'warning':
                    color = Colors.brandWarning;
                    break;
                default:
                    color = Colors.brandPrimary;
            }

            if (error) {
                color = Colors.brandDanger;
            }
            return (
                <React.Fragment>
                    <View style={styles.topIcon}>
                        <Icon
                            type={error ? 'Feather' : this.props.iconType || 'FontAwesome'}
                            name={error ? 'info' : this.props.iconName}
                            size={this.props.iconSize || DEFAULT_ICON_SIZE}
                            color={error ? Colors.errorIconColor : color}
                            style={[error ? {color: Colors.brandDanger} : {color}, this.props.iconSize ? {fontSize: this.props.iconSize} : {fontSize: DEFAULT_ICON_SIZE}]}
                        />
                    </View>
                    <View style={styles.textWrapper}>
                        <Text style={[{color: Colors.grayText}, styles.headingText]}>
                            {error ? t('common:errorHeader') : this.props.headingText}
                        </Text>
                        {error ? this.handleError(error) : this.props.subHeadingText}
                    </View>
                </React.Fragment>
            );
        }
        return null;

    }

    oneErrorMessage(error) {
        return (
            <View style={[globalStyles.flexDirectionRow, styles.errorContainer, {...globalStyles.centerItems}]}>
                <Text
                    style={[globalStyles.heeboRegular, isTextRTLStyles]}> {error.messageToUser ? error.messageToUser : error.message}
                </Text>
            </View>
        )
    }

    handleError(error) {
        if (![514,425].includes(error.code)) {//TODO STUPID CLIENT,Cant fix for now
           return this.oneErrorMessage(error);
        } else {
            if (error.messageToUser.length === 1) {
                return this.oneErrorMessage(error.messageToUser[0]);
            }
            //HANDLE BULLET RESPONSES
            return (
                error.messageToUser.map((item, i) => {
                    return (
                        <View key={i}
                              style={[globalStyles.flexDirectionRow, styles.errorContainer, globalStyles.textAlignByRTL]}>
                            <Icon style={[styles.iconStyles, {color: Colors.errorIconColor, fontSize: 17}]}
                                  type="Feather" name="info"/>
                            <Text style={[isTextRTLStyles, globalStyles.heeboRegular]}><UnicodeHelper/>{item.message}
                            </Text>
                        </View>
                    );
                })
            );
        }
    }

    renderWithTopBarHeading() {
        const {headingText} = this.props;
        return (
            <View style={globalStyles.centerItems}>
                <Text style={styles.withTopBarHeading}>{headingText}</Text>
            </View>
        );
    }

    renderModalContent() {
        const {error, hideModal, withTopBar, disablePadding} = this.props;
        if (error) {
            return (
                <View
                    style={[
                        styles.modalContent,
                        withTopBar ? styles.borderRadiusMinimal : styles.borderRadiusFull,

                    ]}
                >
                    {this.renderHeading()}
                    <ModalButton
                        textColor={Colors.brandPrimary}
                        text={t('modals:Card:got-it', {})}
                        onPress={hideModal}/>
                </View>
            );
        }
        return (
            <View
                style={[
                    styles.modalContent,
                    withTopBar ? styles.borderRadiusMinimal : styles.borderRadiusFull,
                    disablePadding ? {paddingVertical: 5} : {}
                ]}
            >
                {withTopBar ? this.renderWithTopBarHeading() : this.renderHeading()}
                <View style={styles.childrenContent}>{this.props.children}</View>
            </View>
        );
    }

    renderTopBar() {
        return (
            <View
                style={[
                    {backgroundColor: this.props.headerColor || this.themeColor},
                    styles.modalTopBarHeader, globalStyles.appPaddingHorizontal
                ]}
            >
                <Text style={[styles.topBarHeaderText]}>{this.props.headerText}</Text>
                {this.props.removeCloseButton ? null :
                    (
                        <TouchableWithoutFeedback onPress={this.props.hideModal}>
                            <View style={{justifyContent: 'center', zIndex: 100, height: scale(20), width: scale(40)}}>
                                <Icons8Generator name={'close'}/>
                                {/*<MaterialCommunityIcons*/}
                                    {/*name="close"*/}
                                    {/*size={this.props.headerIconSize || DEFAULT_HEADING_ICON_SIZE}*/}
                                    {/*color={this.props.headerIconColor || DEFAULT_HEADING_TEXT_COLOR}*/}
                                {/*/>*/}
                            </View>
                        </TouchableWithoutFeedback>
                    )
                }
            </View>
        );
    }

    renderAbsoluteButton() {
        if (this.props.absoluteButtonText && this.props.absoluteButtonOnPress) {
            return (
                <TouchableOpacity
                    style={[
                        styles.absoluteButton,
                        {borderColor: this.props.absoluteButtonBorderColor || Colors.brandPrimary},
                    ]}
                    onPress={this.props.absoluteButtonOnPress}
                >
                    <Text
                        style={{color: this.props.absoluteButtonTextColor, ...isTextRTLStyles}}>{this.props.absoluteButtonText}</Text>
                </TouchableOpacity>
            );
        }
        return null;
    }

    render() {
        const {withTopBar} = this.props;
        return (
            <View
                style={[
                    styles.modalWrapper,
                    withTopBar ? styles.borderRadiusMinimal : styles.borderRadiusFull,
                ]}
            >
                <Modal
                    isVisible={this.props.isModalVisible}
                    hideModalContentWhileAnimating
                    animationIn="slideInUp"
                    useNativeDriver={false}
                    animationOut="slideOutDown"
                    animationInTiming={300}
                    animationOutTiming={300}
                    backdropTransitionInTiming={300}
                    backdropTransitionOutTiming={300}
                    onModalHide={this.props.hideModal}
                    onBackdropPress={this.props.hideModal}
                >
                    <View style={styles.positionRelative}>
                        {withTopBar ? this.renderTopBar() : null}
                        {this.renderModalContent()}
                        {withTopBar ? this.renderAbsoluteButton() : null}
                    </View>
                </Modal>
            </View>
        );
    }
}

ModalSimple.propTypes = {
    hideModal: PropTypes.func,
    isModalVisible: PropTypes.bool,
    withTopBar: PropTypes.bool,
    absoluteButtonText: PropTypes.string,
    absoluteButtonOnPress: PropTypes.func,
    absoluteButtonBorderColor: PropTypes.string,
    absoluteButtonTextColor: PropTypes.string,
    headerIconSize: PropTypes.number,
    headerIconColor: PropTypes.string,
    headerText: PropTypes.string,
    headerColor: PropTypes.string,
    children: PropTypes.any,
    error: PropTypes.instanceOf(Object),
    headingText: PropTypes.string,
    subHeadingText: PropTypes.any,
    iconName: PropTypes.string,
    iconType: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    modalType: PropTypes.string,
    disablePadding: PropTypes.bool,
};

export default ModalSimple;


const styles = StyleSheet.create({
    modalContent: {
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingVertical: 30,
        width: '100%',
        paddingHorizontal: 30,
        position: 'relative',
    },
    topIcon: {
        paddingBottom: 15,
        alignSelf: 'center'
    },
    modalWrapper: {
        borderRadius: 5,
        position: 'relative',
    },
    textWrapper: {
        flexDirection: 'column',
    },
    headingText: {
        fontSize: 15,
        fontFamily: 'heebo-bold',
        textAlign: 'center',
        ...isTextRTLStyles,
    },
    subHeadingText: {
        fontSize: 12,
        fontStyle: 'normal',

        letterSpacing: 0.38,
        color: Colors.modalFontColor,
        ...isTextRTLStyles,
    },
    childrenContent: {
        width: '100%',
    },
    absoluteButton: {
        position: 'absolute',
        borderRadius: 20,
        borderWidth: 2,
        bottom: -20,
        backgroundColor: 'white',
        paddingVertical: 10,
        paddingHorizontal: 20,
        right: 20,
    },
    modalTopBarHeader: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        paddingHorizontal: 16,
        paddingVertical: 13,
        fontSize: 14,
    },
    topBarHeaderText: {
        color: 'white',
        fontFamily: 'heebo-bold',
        fontSize: scale(15),
        ...isTextRTLStyles,
    },
    withTopBarHeading: {
        color: Colors.grayText,
        fontSize: 14,
        maxWidth: '75%',
        fontFamily: 'heebo-bold',
        textAlign: 'center',
        ...isTextRTLStyles,
    },
    borderRadiusFull: {
        borderRadius: 5,
    },
    borderRadiusMinimal: {
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    positionRelative: {
        position: 'relative'
    },
    errorContainer: {
        paddingHorizontal: 30,
        paddingVertical: 3
    },
    iconStyles: {
        marginLeft: i18n.isRTL ? 0 : 5,
        marginRight: i18n.isRTL ? 5 : 0,
        marginBottom: 5,
    },
    modalButton: {
        marginTop: 30,
        paddingTop: 30,
    }

});
