import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8SquaredMenu(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M6 4c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2V6c0-1.093-.907-2-2-2H6zm15 0c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2V6c0-1.093-.907-2-2-2h-8zm15 0c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2V6c0-1.093-.907-2-2-2h-8zM6 6h8v8H6V6zm15 0h8v8h-8V6zm15 0h8v8h-8V6zM6 19c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2v-8c0-1.093-.907-2-2-2H6zm15 0c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2v-8c0-1.093-.907-2-2-2h-8zm15 0c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2v-8c0-1.093-.907-2-2-2h-8zM6 21h8v8H6v-8zm15 0h8v8h-8v-8zm15 0h8v8h-8v-8zM6 34c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2v-8c0-1.093-.907-2-2-2H6zm15 0c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2v-8c0-1.093-.907-2-2-2h-8zm15 0c-1.093 0-2 .907-2 2v8c0 1.093.907 2 2 2h8c1.093 0 2-.907 2-2v-8c0-1.093-.907-2-2-2h-8zM6 36h8v8H6v-8zm15 0h8v8h-8v-8zm15 0h8v8h-8v-8z" />
    </Svg>
  );
}

export default SvgIcons8SquaredMenu;
