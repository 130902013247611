// @flow
import * as React from 'react';
import {useSelector} from "react-redux";
import {TouchableOpacity, View} from "react-native-web";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import ModalButton from "../../ModalButton";
import {Colors} from "../../../styles/Colors";
import Wave from "../../../svg/custom/HelloArbox";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {isMobile} from "../../../helpers/functions";
import User from "../../../svg/Icons8MaleUser";
import {globalSiteStyles, globalStyles} from "../../../styles/Global";
import {Link} from "react-router-dom";

const RegisterCta = (props) => {
    const {onSignUpClick, gotoFullProcess} = props;
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const mobile = isMobile()

    return (
        <>
        {
            mobile ?
                <TouchableOpacity onPress={onSignUpClick} style={[styles.mobileWrapper]}>
                    <View style={[styles.mobileContent]}>
                        <AppText style={[styles.header]}>{t('screens:Login:my-first-time-here', {})}</AppText>
                        <AppText style={[{fontSize: '0.9rem'}]}>{t('screens:Login:first-time-customers', {})}</AppText>
                        {
                            gotoFullProcess ?
                                <Link to={{pathname: '/register', search: boxQueryString || location.search}} style={{ textDecoration: 'none' }}>
                                    <View style={[globalSiteStyles(mobile).siteBlackBtn, styles.primaryBtn, {marginTop: '1em', width: '100%'}]}>
                                        <AppText style={[styles.linkBtn]}>{t('screens:Login:signUp', {})}</AppText>
                                    </View>
                                </Link>
                                :
                                <ModalButton
                                    onPress={onSignUpClick}
                                    text={t('screens:Login:signUp', {})}
                                    type='primary'
                                    colors={Colors.white}
                                    textColor={Colors.lightBlack}
                                    overrideStyle={[globalSiteStyles(mobile).siteBlackBtn, styles.primaryBtn, {
                                        marginTop: '1em',
                                        width: '100%'
                                    }]}
                                    textStyles={{'fontFamily': 'heebo'}}
                                />
                        }
                    </View>
                </TouchableOpacity>
                :
                <View style={[{justifyContent: 'space-between', height: '100%'}, props.style]}>
                    <View style={[styles.content]}>
                        <AppText style={[styles.header]}>{t('screens:Login:my-first-time-here', {})}</AppText>
                        <View style={[styles.actions]}>
                            <AppText style={[styles.subHeader]}>{t('screens:Login:whyShouldISignup', {})}</AppText>
                            {
                                gotoFullProcess ?

                                    <Link to={{pathname: '/register', search: boxQueryString || location.search}} style={{ textDecoration: 'none' }}>
                                        <View style={[globalSiteStyles(mobile).siteBlackBtn, styles.primaryBtn]}>
                                            <AppText style={[styles.linkBtn]}>{t('screens:Login:signUp', {})}</AppText>
                                        </View>
                                    </Link>
                                    :
                                    <ModalButton
                                        onPress={onSignUpClick}
                                        text={t('screens:Login:signUp', {})}
                                        type='primary'
                                        colors={Colors.white}
                                        textColor={Colors.lightBlack}
                                        overrideStyle={[globalSiteStyles(mobile).siteBlackBtn, styles.primaryBtn]}
                                        textStyles={{'fontFamily': 'heebo', fontSize: '1rem'}}
                                        boldFont
                                    />
                            }
                        </View>
                    </View>
                    <Wave width={'4.5em'} height={'4.5em'}/>
                </View>
        }
        </>
    );
};

export default RegisterCta;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            header: {
                fontFamily: 'heebo',
                fontSize: '1.3rem',
                color:  Colors.lightBlack
            },
            subHeader: {
                fontSize: '0.85rem',
                color: Colors.grey
            },
            primaryBtn: {
                width: '9em',
                minHeight: 'auto',
                marginTop: 0,
                alignSelf: 'start',
                justifyContent: 'center',
                alignItems: 'center'
            },
            content: {
                gap: '1.3em'
            },
            actions: {
                gap: '1.5em'
            },
            registerSide: {
                width: '10em'
            },
            mobileWrapper: {
                gap: '2em',
                justifyContent: 'center',
                marginTop: '1em'
            },
            mobileContent: {
                // borderColor: Colors.graySeparator,
                // borderWidth: 1,
                padding: '1.3em',
                gap: '0.5em'
            },
            linkBtn: {
                fontFamily: 'heebo',
                fontSize: '0.95rem',
                color: Colors.lightBlack
            }
        }
    })
})