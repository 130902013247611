import React from 'react';
import {connect} from 'react-redux';
import {globalStyles} from "../../../styles/Global";
import {AppText} from "../../Stateless/AppText";
import {scale} from 'react-native-size-matters';
import {View} from 'react-native';
import {t} from "../../../services/i18n";
import {GraySeparator} from "../../Stateless/GraySeparator";
import {Colors} from "../../../styles/Colors";
import uuid from 'uuid';
import {calcCartItems, getTaxesFromMembership} from "../../../helpers/functions";

class PriceSummary extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        this.setState({isLoading: true});

    }


    render() {
        const {cart} = this.props;
        const paymentObject = calcCartItems(cart,false);
        const taxes = getTaxesFromMembership(cart[0]);
        return (
            <View style={[globalStyles.appMinimalPaddingHorizontal]}>
                <GraySeparator height={1}/>
                <View style={[globalStyles.appMinimalPaddingHorizontal]}>
                    {this.priceRow( t('screens:Purchase:totalPayment', {}), paymentObject.total_price.toFixed(2), true)}
                    {paymentObject.total_tax !== 0 && taxes.length > 0 ? (
                        <View style={[globalStyles.marginTopMedium]}>
                            {this.priceRow(t('screens:Purchase:subTotal', {}), paymentObject.sub_total.toFixed(2), false)}
                            {taxes.map(tax => {
                                return this.priceRow(tax.name, ((tax.percentage / 100) * paymentObject.sub_total).toFixed(2), false)
                            })}
                        </View>
                    ) : null}
                </View>
                <GraySeparator height={1}/>
                {this.handleRecurring()}
            </View>
        )
    }

    priceRow(text, price, isHeader) {
        const style = !isHeader ? {color: Colors.grayText} : {};
        return (
            <View key={uuid.v4()} style={[globalStyles.flexDirectionRow,globalStyles.marginTopSm]}>
                <AppText
                    style={[globalStyles.heeboRegular, {flex: 2},style]}>{text}
                </AppText>
                <AppText
                    style={[globalStyles.heeboRegular, {flex: 3},style]}>{this.props.currencySymbol}{price}
                </AppText>
            </View>
        )
    }

    handleRecurring() {
        const recurringObject = calcCartItems(this.props.cart,true)
        return recurringObject.total_price > 0 ? (
            <AppText style={[globalStyles.appMinimalPaddingHorizontal,globalStyles.marginBottomVS10,globalStyles.heeboLight,{fontSize:scale(12)}]}>{t('screens:Cart:recurringNote', {amount: this.props.currencySymbol + recurringObject.total_price.toFixed(2)})}</AppText>) : null
    }
}


const mapStateToProps = state => ({
    currencySymbol: state.shop.currencySymbol,
    cart: state.shop.cart,

});

export default connect(
    mapStateToProps,
    null,
)(PriceSummary);


