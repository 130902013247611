import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Input, Item, Label, Icon, H1, H2, Text} from 'native-base';
import {
    Image,
    ScrollView,
    KeyboardAvoidingView,
    SafeAreaView,
    View,
    TextInput,
    Button,
    TouchableWithoutFeedback,
    Keyboard
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {Field, reduxForm} from 'redux-form';
import {Colors} from '../../../styles/Colors';
import styles from '../styles';
import {t} from '../../../services/i18n';
import {globalStyles} from "../../../styles/Global";
import FloatingTextInput from "../../../components/Stateless/FloatingTextInput";
import {AppText} from "../../../components/Stateless/AppText";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {HUGE_ICON_SIZE} from "../../../helpers/functions";
import ReduxDatePicker from "../../../components/Stateless/ReduxDatePicker";
import DatePicker from "react-native-datepicker";
import moment from 'moment';
const required = value => (value ? undefined : t('common:required', {}));
const maxLength = (max, error) => value => (value && value.length > max ? error : undefined);
const max = 140;
const aboutMaxLength140 = maxLength(max, t('screens:OnBoarding:bioMaxChar', {max}));



class FirstLoginChangeNameAndBioScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: null
        }
    }

    componentDidMount(){
        this.setState({date: this.props.initialValues.birthday})
    }
    render() {
        const form = (
            <Form>
                <Field
                    name={"first_name"}
                    component={FloatingTextInput}
                    placeholder={t('screens:OnBoarding:firstName', {})}
                    validate={[required]}
                />
                <View>
                    <Field
                        name={"last_name"}
                        component={FloatingTextInput}
                        placeholder={t('screens:OnBoarding:lastName', {})}
                        validate={[required]}
                    />
                </View>
                <View>
                    <Field
                        name={"birthday"}
                        component={ReduxDatePicker}
                        placeholder={t('screens:Profile:features:birthday-placeholder', {})}
                    />
                </View>
                <View>
                    <Field
                        name={"bio"}
                        component={FloatingTextInput}
                        placeholder={t('screens:OnBoarding:bioPlaceholder', {})}
                        multiline
                    />
                </View>
            </Form>
        );

        return (
            <KeyboardAvoidingView
                style={{flex: 1, backgroundColor: Colors.white}}>
                <LinearGradient
                    colors={[Colors.white, Colors.white]}
                    style={styles.gradient}>
                    <SafeAreaView style={styles.container}>
                        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                            <View style={styles.inner}>
                                <View
                                    style={[globalStyles.centerItems]}>
                                    <Icons8Generator name={'user'} width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                                </View>
                                <AppText
                                    style={[globalStyles.heeboMedium, styles.h1, globalStyles.marginTopBetweenElements]}>{t('screens:OnBoarding:personalDetailsTitle', {})}</AppText>
                                {form}
                                <View style={{flex: 1}}/>
                            </View>
                        </TouchableWithoutFeedback>
                    </SafeAreaView>
                </LinearGradient>
            </KeyboardAvoidingView>

        );
    }
}


FirstLoginChangeNameAndBioScreen = reduxForm({
    form: 'FirstLoginNameAndBio',
    enableReinitialize: true
})(FirstLoginChangeNameAndBioScreen);

// You have to connect() to any reducers that you wish to connect to yourself
FirstLoginChangeNameAndBioScreen = connect(
    state => ({
        initialValues: state.user.data, // pull initial values from account reducer
        whiteLabelProperties: state.whiteLabelProperties,
    }),
    {}               // bind account loading action creator
)(FirstLoginChangeNameAndBioScreen);

export default FirstLoginChangeNameAndBioScreen

