import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8BuildingWithRooftopTerraceFilled(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M10 3a1 1 0 00-1 1v5c-1.654 0-3 1.346-3 3v35h22V36h9v11h7V12c0-1.654-1.346-3-3-3V4a1 1 0 00-1-1H10zm1 2h4v4h-4V5zm6 0h4v4h-4V5zm6 0h4v4h-4V5zm6 0h4v4h-4V5zm6 0h4v4h-4V5zM13 16h9v5h-9v-5zm15 0h9v5h-9v-5zM13 26h9v5h-9v-5zm15 0h9v5h-9v-5zM13 36h9v5h-9v-5z" />
    </Svg>
  );
}

export default SvgIcons8BuildingWithRooftopTerraceFilled;
