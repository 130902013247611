import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Synchronize(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 5C13.965 5 5 13.965 5 25c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879 0-9.953 8.047-18 18-18 6.246 0 11.727 3.18 14.957 8H33a1.006 1.006 0 00-.879.496 1.01 1.01 0 000 1.008c.184.312.52.5.879.496h10V7a1.003 1.003 0 00-1.016-1.016c-.55.012-.992.465-.984 1.016v6.012C37.348 8.148 31.54 5 25 5zm18.984 18.984c-.55.012-.992.465-.984 1.016 0 9.953-8.047 18-18 18-6.246 0-11.73-3.18-14.957-8H17c.36.008.695-.184.879-.492.18-.313.18-.7 0-1.012-.184-.309-.52-.5-.879-.496H8.445a1.072 1.072 0 00-.386 0H7v10c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-6.016C12.648 41.848 18.46 45 25 45c11.035 0 20-8.965 20-20a1.003 1.003 0 00-1.016-1.016z" />
    </Svg>
  );
}

export default SvgIcons8Synchronize;
