import {LocaleConfig} from 'react-native-calendars';


const calendarLocale = {
    init: function (language) {
        if (language === undefined || language === 'en') {
            //keep default
            return true;
        }
        switch (language) {
            case
            'he':
                this.hebrew();
                break;
            default:
                return true;
        }
    },

    hebrew: function () {
        LocaleConfig.locales['he'] = {
            monthNames: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
            monthNamesShort: ['ינו׳.', 'פבו׳.', 'מא׳', 'אפר׳', 'מאי', 'יוני', 'יולי.', 'אוג׳', 'ספט׳.', 'אוק׳.', 'נוב׳.', 'דצ׳.'],
            dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
            dayNamesShort: ['א׳','ב׳','ג׳','ד׳','ה׳','ו׳','ש׳'],
            today: 'היום'
        };
        LocaleConfig.defaultLocale = 'he';
    }
};


export default calendarLocale;

