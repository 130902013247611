import React from 'react'
import { View, StyleSheet } from 'react-native'
import {verticalScale} from 'react-native-size-matters';
import uuid from 'uuid';

function MoveToBottom(component) {
    return (
        <View   key={uuid.v4()} style={styles.container}>
            {component}
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: verticalScale(50)
    }
});
export default MoveToBottom