import React from 'react';
import {Platform, TouchableOpacity} from 'react-native';
import DatePicker from "../DatePicker";
import date from '../../services/i18n/date';
import Modal from 'react-native-modal';
import {AppText} from "../Stateless/AppText";
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {verticalScale,scale} from 'react-native-size-matters';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {IconSize} from "../../constants/iconSize";


export default class DatePickerWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        // TODO REMOVE THIS.PROPS FROM STATE
        this.state = {
            edit: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (this.props.startDate !== prevProps.startDate) {
            this.init();
        }
    }

    init() {
        this.setState({
            startDate: new Date(this.props.startDate),
            dateString: this.props.startDate
        })
    }

    render() {
        const {onDatePickerClose, minimumDate} = this.props;
        const {startDate, dateString} = this.state;
        let view, datepicker = undefined;
        view = (
            <TouchableOpacity style={[globalStyles.flexDirectionRowSpaceBetween,
                {alignItems:'center',backgroundColor: Colors.iconBorder,height: verticalScale(30),paddingHorizontal: scale(8)}]}
                              onPress={() => this.setState({edit: true})}>
                <AppText
                    style={[globalStyles.heeboRegular, {color: Colors.black}]}>{date.format(dateString,'LL')}</AppText>
                <Icons8Generator size={IconSize.XS} name={'arrow-down'} fill={Colors.black}/>
            </TouchableOpacity>
        );
        if (this.state.edit) {
            //TODO HANDLE THIS CRAP BETTER
            if (Platform.OS.toLowerCase() === 'android') {
                datepicker = <DatePicker
                    date={startDate}
                    minimumDate={minimumDate}
                    onClose={(event, date) => {
                        this.setState({edit: false}, () => {
                            if (date && Platform.OS.toLowerCase() !== 'ios') {
                                onDatePickerClose(date);
                            } else {
                                if (event !== 'aborted') {
                                    onDatePickerClose(date);
                                }
                            }
                        })
                    }}
                    onChange={d => {
                    }}
                />
            } else {
                datepicker = <Modal
                    isVisible={this.state.edit}
                    hideModalContentWhileAnimating
                    animationIn="fadeIn"
                    useNativeDriver={false}
                    animationOut="fadeOut"
                    animationInTiming={this.props.animationTiming || 500}
                    animationOutTiming={this.props.animationTiming || 500}
                    backdropTransitionInTiming={this.props.animationTiming || 500}
                    backdropTransitionOutTiming={this.props.animationTiming || 500}
                >
                    <DatePicker
                        date={startDate}
                        minimumDate={minimumDate}
                        onClose={(event, date) => {
                            if (date && Platform.OS.toLowerCase() !== 'ios') {
                                this.setState({date});
                                onDatePickerClose(date);
                            } else {
                                if (event !== 'aborted') {
                                    onDatePickerClose(date);
                                }
                            }
                            this.setState({edit: false})
                        }}
                        onChange={d => {
                        }}
                    />
                </Modal>
            }
        }

        return [view, datepicker];
    }

}

