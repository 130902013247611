export const WEB_VIEW_LISTENER = `

(function () {
	// init
	var init = function () {
		// message handler
		if (window.addEventListener) window.addEventListener("message", processMessage, false);
		else window.attachEvent("onmessage", processMessage);
		
	}

	// START: MESSAGE HANDLING

	// processMessage
	var processMessage = function (event) {
	//A way to reduce application multi call.
	           if(event.data.hasOwnProperty('action') && event.data.action === 'payments_wrap'){
	           			  window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
	           } 
	}

	
	// documentReady
	var documentReady = function () {
		// readyState
		var state = document.readyState;
		// init on ready
		(state === 'interactive' || state === 'complete') ? init() : document.addEventListener('DOMContentLoaded', init);
	};

	// documentReady
    documentReady();

})();`;