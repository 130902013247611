import React from 'react';
import {TouchableWithoutFeedback, View, Keyboard, TouchableOpacity,Platform, Animated} from 'react-native';
import {Colors} from '../../styles/Colors';

import LoginForm from '../../components/LoginForm';
import {t} from '../../services/i18n';
import {AppText} from "../../components/Stateless/AppText";
import AppLogo from "../../components/Stateless/AppLogo";
import * as Linking from 'expo-linking';
import {globalStyles} from "../../styles/Global";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import {LinearWrapper} from "../../components/Stateless/LinerWrapper";

const MARGIN_TOP = scale(140);
const MARGIN_TOP_SM = scale(30);
const MARGIN_BOTTOM = scale(40);
const MARGIN_BOTTOM_SM = scale(10);


class LoginScreen extends React.PureComponent {
    static navigationOptions = {
        header: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            hide: false
        };
        this.marginTop = new Animated.Value(MARGIN_TOP);
        this.marginBottom = new Animated.Value(MARGIN_BOTTOM);

    }

    componentDidMount() {
        if (Platform.OS.toLowerCase() === 'ios') {
            this.keyboardWillShowSub = Keyboard.addListener('keyboardWillShow', this.keyboardWillShow);
            this.keyboardWillHideSub = Keyboard.addListener('keyboardWillHide', this.keyboardWillHide);
        } else {
            this.keyboardWillShowSub = Keyboard.addListener('keyboardDidShow', this.keyboardWillShow);
            this.keyboardWillHideSub = Keyboard.addListener('keyboardDidHide', this.keyboardWillHide);
        }
    }

    componentWillUnmount() {
        this.keyboardWillShowSub.remove();
        this.keyboardWillHideSub.remove();
    }

    keyboardWillShow = (event) => {

        this.setState({hide: true});

        Animated.parallel([
            Animated.timing(this.marginTop, {
                duration: event.duration,
                toValue: MARGIN_TOP_SM,
                useNativeDriver: false, // <-- Add this
            }),
            Animated.timing(this.marginBottom, {
                duration: event.duration,
                toValue: MARGIN_BOTTOM_SM,
                useNativeDriver: false, // <-- Add this
            })
        ]).start();
    };


    keyboardWillHide = (event) => {
        this.setState({hide: false});
        Animated.parallel([
            Animated.timing(this.marginTop, {
                duration: event.duration,
                toValue: MARGIN_TOP,
                useNativeDriver: false, // <-- Add this


            }),
            Animated.timing(this.marginBottom, {
                duration: event.duration,
                toValue: MARGIN_BOTTOM,
                useNativeDriver: false, // <-- Add this

            })
        ]).start();
    };


    render() {
        return (
            <LinearWrapper backgroundStart={Colors.white} backgroundEnd={Colors.white} barStyle={'dark-content'}>
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <View style={globalStyles.flex}>
                        <View style={[globalStyles.appPaddingHorizontal, globalStyles.flex]}>
                            <Animated.View style={[styles.logo, globalStyles.centerItems, {
                                alignSelf: 'center',
                                marginTop: this.marginTop,
                                marginBottom: this.marginBottom
                            }]}>
                                <AppLogo invert={false}/>
                            </Animated.View>
                            <LoginForm/>
                        </View>
                        {this.state.hide === false ? (<View style={{flexShrink: 1}}>
                            <TouchableOpacity
                                style={[{marginBottom: verticalScale(20), alignSelf: 'center'}]}
                                onPress={() => this.props.navigation.navigate('ForgotPassword')}>
                                <AppText
                                    style={[globalStyles.brandedLightText, {fontSize: scale(14)}]}>{t('screens:Login:forgot-password', {})}</AppText>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[globalStyles.centerItems, {marginBottom: verticalScale(15)}]}
                                onPress={() => Linking.openURL('https://arboxassets.s3.eu-central-1.amazonaws.com/Docs/Arbox+Privacy+Policy.pdf')}>
                                <AppText
                                    style={[globalStyles.brandedLightText, {fontSize: scale(14)}]}>{t('screens:Login:terms', {})}</AppText>
                            </TouchableOpacity>
                        </View>) : null}
                    </View>
                </TouchableWithoutFeedback>
            </LinearWrapper>

        );
    }
}


export default LoginScreen;


const styles = ScaledSheet.create({});