// @flow
import * as React from 'react';
import {Platform, StyleSheet, View} from "react-native";
import {Picker} from "@react-native-picker/picker";
import {Colors} from "../../../styles/Colors";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {useEffect, useState} from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import {t} from "../../../services/i18n";

const Dropdown = (props) => {
    const {input, overrideProps, items, valuePropName, labelPropName, iconName, onSelection, initValue, selectAllOption, defaultLabel} = props;
    const [selected, setSelected] = useState(initValue);

    useEffect(() => {
        setSelected(initValue)
    }, [initValue]);

    const selectionChanged = (value) => {
        setSelected(value);
        onSelection(value);
    }

    return (
        <View style={[styles.wrapper, props.style]} {...overrideProps}>
            {iconName && <Icons8Generator name={iconName} width={'1.2em'} height={'1.2em'}/>}
            <Picker style={styles.picker} selectedValue={input ? input.value : selected} onValueChange={input ? input.onChange : (itemValue) => selectionChanged(itemValue)}>
                {
                    selectAllOption && <Picker.Item label={defaultLabel || t('common:select', {})}
                                                    value={0}
                                                    key={`dropdown-item-select-all`}
                    />
                }
                {
                    items && items.map(item =>
                    <Picker.Item label={item[labelPropName]}
                                 value={item[valuePropName]}
                                 key={`dropdown-item-${item[labelPropName]}-${item[valuePropName]}`}
                    />)
                }
            </Picker>
        </View>
    );
};

export default Dropdown;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                backgroundColor: Colors.brandedLightText,
                border: 'none',
                width: '8em',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: '0.5em',
                paddingVertical: '0.5em',
                gap: '0.5em',
            },
            picker: {
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '0.8rem',
                fontWeight: '300',
                width: '100%'
            }
        }
    })
})