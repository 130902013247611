import React from 'react';
import {ScrollView,View} from 'react-native';
import PropTypes from 'prop-types';
import ClubsList from '../../../components/ClubsList';
import {t} from '../../../services/i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchUserBoxes} from '../../../actions/boxes';
import Spinner from 'react-native-loading-spinner-overlay';
import {globalStyles} from "../../../styles/Global";
import HeaderWrapper from "../../../components/HeaderWrapper";
import {LinearWrapper} from "../../../components/Stateless/LinerWrapper";
import {Colors} from "../../../styles/Colors";


class ClubScreen extends React.PureComponent {
    state = {
        isLoading: true,
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount()  {
        const boxes = await this.props.fetchUserBoxes();
        if (this.props.navigation.state.params && this.props.navigation.state.params.box_id) {
            const club = boxes.data.find(item => item.box_fk === this.props.navigation.state.params.box_id);
            if (club) {
                this.props.navigation.navigate("ClubProfileScreen", {club});
            }
        }
        if (boxes.data && boxes.data.length === 1) {
            const club = boxes.data[0];
            this.props.navigation.navigate("ClubProfileScreen", {club});
        }
        this.setState(
            {clubsList: boxes.data},
            () => this.setState({isLoading: false}),
        );
    };

    render() {
        const {isLoading} = this.state;
        return (
            <LinearWrapper
                backgroundStart={this.props.whiteLabelProperties.primaryColor}
                backgroundEnd={this.props.whiteLabelProperties.primaryColor}
                barStyle="light-content"
                spinner={false}>
                <HeaderWrapper
                    headerTitle={t('headings:your-clubs', {})}
                    headerArrowColor={this.props.whiteLabelProperties.primaryColorText}
                    noArrow={false}
                    padding={globalStyles.appPaddingHorizontal}
                    isPop={true}
                />
                <View style={[globalStyles.flex,{backgroundColor: Colors.white}]}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                    >
                        {this.state.clubsList ? <ClubsList list={this.state.clubsList} fromMoreSection={true}/> :
                            <Spinner visible={isLoading}/>}
                    </ScrollView>
                </View>
            </LinearWrapper>
        );

    }
}

ClubScreen.propTypes = {
    navigation: PropTypes.object.isRequired,
    fetchUserBoxes: PropTypes.func,
    user: PropTypes.object,
};

const mapActionsToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserBoxes
        },
        dispatch
    );
};

const mapStateToProps = state => ({
    whiteLabelProperties: state.whiteLabelProperties
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(ClubScreen);
