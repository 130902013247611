import React from 'react';
import {View} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {ScaledSheet, verticalScale, scale} from "react-native-size-matters"
import {AppText} from "../AppText";
import {Colors} from "../../../styles/Colors";
import UserAvatar from 'react-native-user-avatar';
import uuid from 'uuid';


export const ImageTextRow = props => {
    const upperStyles = props.upperTextStyles ? {...props.upperTextStyles} : {};
    const bottomStyles = props.bottomTextStyles ? {...props.bottomTextStyles} : {};
    return (
        <View
            style={[globalStyles.flexDirectionRow]}>
            {props.noImage ? null :( <UserAvatar
                key={uuid.v4()}
                size={scale(props.imageSize)}
                name={props.imageName}
                src={props.src ? props.src : null}
            />)}
            <View style={[globalStyles.flexDirectionColumn, props.noImage ? {} : globalStyles.paddingStartLarge,props.bottomText ? {} : globalStyles.centerItems]}>
                <AppText
                    style={[globalStyles.heeboMedium, {color: Colors.black},upperStyles]}>{props.upperText}</AppText>
                {props.bottomText ? <AppText
                    style={[globalStyles.heeboRegular,{marginTop: verticalScale(2)},bottomStyles]}>{props.bottomText}</AppText> : null}
            </View>
        </View>
    )
};


const styles = ScaledSheet.create({

});
