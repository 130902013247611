// @flow
import * as React from 'react';
import {Platform, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import ChooseService from "./ChooseService";
import {useEffect, useState} from "react";
import ChooseSlot from "./ChooseSlot";
import {useDispatch, useSelector} from "react-redux";
import {authenticateUserAfterRegistration, updateHeaderConfig} from "../../../actions/minisite";
import {
    BOOK_APPOINTMENT_PROCESS, BOOK_APPOINTMENT_PROCESS_SPACES_OPTIONAL,
    BOOKED_DESKTOP_STEP,
    CHOOSE_SERVICE_STEP,
    CHOOSE_SLOT_STEP, COACH_AVAILABILITY_TYPE,
    COACH_OR_SPACE_STEP, SPACE_AVAILABILITY_TYPE
} from "../../../constants";
import {t} from "../../../services/i18n";
import {BookingCard} from "../Schedule/Desktop/BookingCard";
import SuccessModal from "../Common/SuccessModal";
import LoginScreen from "../../../screens/Web/LoginScreen";
import {TouchableOpacity} from "react-native-web";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {AppText} from "../../Stateless/AppText";
import {isMobile} from "../../../helpers/functions";
import {getCoachOrSpaceSelectionComponent, isSiteHebrew} from "../../../helpers/ComponentFunctionality";
import moment from "moment";
import LoginOrRegisterWrapper from "../Registration/LoginOrRegisterWrapper";
import GenericSuccessModal from "../Common/GenericSuccessModal";
import {fetchProfile} from "../../../actions/user";

const AppointmentHandler = (props) => {
    const {tabsDisplayed, onBackPress, urlParamCategory} = props;
    const [selectedService, setSelectedService] = useState(null);
    const [itemToRender, setItemToRender] = useState(null);
    const [openBookingCard, setOpenBookingCard] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [userFromRegister, setUserFromRegister] = useState(null);
    const [availabilityType, setAvailabilityType] = useState(null);
    const headerConfig = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.headerConfig)
    const isLoggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const siteSelectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const referer = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.referer)

    const dispatch = useDispatch();
    const mobile = isMobile();
    const isHebrew = isSiteHebrew();

    useEffect(() => {
        if(siteSelectedLocation) {
            let headerObj = {}
            if (siteSelectedLocation.hasSpaceAvailability && siteSelectedLocation.hasAvailability) {
                headerObj = {
                    processName: BOOK_APPOINTMENT_PROCESS_SPACES_OPTIONAL,
                    stepName: COACH_OR_SPACE_STEP,
                    steps: [COACH_OR_SPACE_STEP, CHOOSE_SERVICE_STEP, CHOOSE_SLOT_STEP]
                }
            } else if (siteSelectedLocation.hasAvailability && !siteSelectedLocation.hasSpaceAvailability) {
                setAvailabilityType(COACH_AVAILABILITY_TYPE)
                headerObj = {
                    processName: BOOK_APPOINTMENT_PROCESS,
                    stepName: CHOOSE_SERVICE_STEP,
                    steps: [CHOOSE_SERVICE_STEP, CHOOSE_SLOT_STEP]
                }
            } else {
                setAvailabilityType(SPACE_AVAILABILITY_TYPE)
                headerObj = {
                    processName: BOOK_APPOINTMENT_PROCESS,
                    stepName: CHOOSE_SERVICE_STEP,
                    steps: [CHOOSE_SERVICE_STEP, CHOOSE_SLOT_STEP]
                }
            }
            dispatch(updateHeaderConfig(headerObj))
            setItemToRender(renderStep())
        }
    }, [siteSelectedLocation]);

    useEffect(() => {
        if(headerConfig && headerConfig.processName?.includes(BOOK_APPOINTMENT_PROCESS)) {
            setItemToRender(renderStep())
        }
    }, [headerConfig, isLoggedIn]);

    useEffect(() => {
        if(urlParamCategory && siteSelectedLocation) {
            handleDeepLink()
        }
    }, [urlParamCategory, siteSelectedLocation]);

    const handleDeepLink = () => {
        let headerObj = {}
        if(urlParamCategory.scheduleType) { //if the url is a deep lick (we have scheduleType prop) but we don't have availabilityType prop (aka old url), we set it to coach availability by default
            const type = urlParamCategory.availabilityType ?? COACH_AVAILABILITY_TYPE
            setAvailabilityType(type)
            const firstStep = siteSelectedLocation.hasAvailability && siteSelectedLocation.hasSpaceAvailability && !urlParamCategory.availabilityType ? COACH_OR_SPACE_STEP : CHOOSE_SERVICE_STEP
            headerObj = {
                processName: type === SPACE_AVAILABILITY_TYPE ? BOOK_APPOINTMENT_PROCESS_SPACES_OPTIONAL : BOOK_APPOINTMENT_PROCESS,
                stepName: firstStep,
                steps: siteSelectedLocation.hasAvailability && siteSelectedLocation.hasSpaceAvailability ? [COACH_OR_SPACE_STEP, CHOOSE_SERVICE_STEP, CHOOSE_SLOT_STEP] : [CHOOSE_SERVICE_STEP, CHOOSE_SLOT_STEP],
                headerText: t('screens:Schedule:service-selection-step-header',{})
            }
            dispatch(updateHeaderConfig(headerObj))
            setItemToRender(renderStep())
        }
    }

    const serviceSelected = (service) => {
        setSelectedService(service);
        const headerObj = {processName: headerConfig.processName,
            stepName: CHOOSE_SLOT_STEP,
            steps: headerConfig.steps,
            bgColor: service.category_color,
            headerText: service.name,
            subHeaderText: `${t('screens:Schedule:appointment-length',{length: service.length})} ${service.price ? `- ${service.price}${siteSelectedLocation.currency_symbol}` : ''}`,
        }
        dispatch(updateHeaderConfig(headerObj))
    }

    const slotSelected = (slot) => {
        setShowLogin(false)
        setSelectedSlot(slot);
        if(isLoggedIn) {
            setOpenBookingCard(true);
        } else {
            setShowLogin(true)
        }
    }

    const availabilityTypeSelected = (isSpace = false) => {
        setAvailabilityType(isSpace ? SPACE_AVAILABILITY_TYPE : COACH_AVAILABILITY_TYPE)
        dispatch(updateHeaderConfig({processName: headerConfig.processName, stepName: CHOOSE_SERVICE_STEP, steps: headerConfig.steps, headerText: t('screens:Schedule:service-selection-step-header',{})}))
    }

    const renderStep = () => {
        switch (headerConfig.stepName) {
            case COACH_OR_SPACE_STEP:
                return getCoachOrSpaceSelectionComponent(() => availabilityTypeSelected(), () => availabilityTypeSelected(true))
            case CHOOSE_SERVICE_STEP:
                return <ChooseService onSelect={(service) => serviceSelected(service)} tabsDisplayed={tabsDisplayed} urlParamCategoryId={urlParamCategory?.categoryId} availabilityType={availabilityType}/>;
            case CHOOSE_SLOT_STEP:
                return <ChooseSlot service={selectedService} slotSelected={(slot) => slotSelected(slot)} onRegisterSuccess={bookingClosed} urlParamCategory={urlParamCategory} availabilityType={availabilityType}/>;
            case BOOKED_DESKTOP_STEP:
                return null;
            default:
                return null;
        }
    }

    const bookingClosed = (res) => {
        setSelectedSlot(res)
        setOpenBookingCard(false)
        if(res.isBooked) {
            if(res.user) {
                setUserFromRegister(res.user)
            }
            dispatch(updateHeaderConfig({processName: headerConfig.processName, stepName: BOOKED_DESKTOP_STEP, steps: headerConfig.steps}))
            setShowSuccess(true)
        } else {
            setShowSuccess(false)
        }
    }

    const closeSuccess = async () => {
        if(userFromRegister) {
            await dispatch(authenticateUserAfterRegistration(userFromRegister))
            await dispatch(fetchProfile())
        }
        dispatch(updateHeaderConfig({processName: headerConfig.processName, stepName: CHOOSE_SERVICE_STEP, steps: headerConfig.steps}));
        setShowSuccess(false)
    }

    return (
        <>
            <View style={styles.wrapper}>
                {!mobile && onBackPress && referer.toLowerCase() === 'plugin' &&
                    <TouchableOpacity onPress={onBackPress} style={styles.pageHeader}>
                        <Icons8Generator name={isHebrew ? 'arrow' : 'arrow-rtl'} size={'1em'}/>
                        <AppText style={styles.pageHeaderText}>{t('screens:Schedule:booking-appointment',{})}</AppText>
                    </TouchableOpacity>
                }
                {itemToRender}
            </View>
            {openBookingCard && <BookingCard classInfo={selectedSlot} show={openBookingCard} setClose={(res) => bookingClosed(res)} showBooked={false}/>}
            {showSuccess && <GenericSuccessModal show={showSuccess}
                                                 iconName={'calendar-booked'}
                                                 setClose={closeSuccess}
                                                 title={t('screens:ScheduleSingle:onBooked:successTitle', {})}
                                                 subHeader={t('common:drawer:booking:dont-forget-msg', {
                                                            session: t('screens:Schedule:appointment', {}),
                                                            coach: selectedSlot.coach?.full_name,
                                                            date: moment(`${selectedSlot.date} ${selectedSlot.time}`, 'YYYY-MM-DD HH:mm').format('LLLL')
                                                        })}
                                                 isPopup={mobile} />
            }
            {showLogin && selectedService?.membership_types.length ?
                <LoginOrRegisterWrapper isAppointment={true} show={showLogin} setClose={() => setShowLogin(false)} selectedSlot={selectedSlot} onRegisterSuccess={bookingClosed}/>
                :
                <LoginScreen show={showLogin} handleSubmit={() => setShowLogin(false)} setClose={() => setShowLogin(false) } handleForgotPassword={() => console.log('forgot password')}/>
            }
        </>
    );
};
export default AppointmentHandler;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                maxHeight: () => `100% - ${90}`,
            },
            pageHeader: {
                flexDirection: 'row',
                gap: '1em',
                maxHeight: 50,
                alignItems: 'center',
                margin: '1em'
            },
            pageHeaderText: {
                fontSize: '0.9rem',
                fontWeight: 'bold'
            },
        }
    })
})