import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8PersonalTrainer(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2c-4.406 0-8 3.594-8 8 0 4.406 3.594 8 8 8 4.406 0 8-3.594 8-8 0-4.406-3.594-8-8-8zm0 2c3.324 0 6 2.676 6 6s-2.676 6-6 6-6-2.676-6-6 2.676-6 6-6zm-5.016 15.984c-.05.004-.097.008-.148.016H18c-1.86 0-3.41.648-4.547 1.367-1.137.719-1.848 1.469-2.187 1.918-1.883 2.5-6.063 9.356-6.063 9.356a.054.054 0 00-.012.015c-.761 1.305-1.21 2.574-1.128 3.871.082 1.293.726 2.504 1.82 3.594l4.617 4.621c1.316 1.317 3.371 1.367 4.82.278l.184 2.07a1.003 1.003 0 001.578.742c.293-.21.453-.562.414-.922l-1.504-17a1 1 0 00-1.805-.5l-4.289 5.88c-.289.398-.246.948.102 1.296l4.328 4.328a1.69 1.69 0 010 2.414 1.686 1.686 0 01-2.41 0l-4.621-4.621c-.863-.863-1.192-1.57-1.238-2.312-.047-.739.21-1.618.863-2.73.016-.028 4.273-6.966 5.941-9.177v-.004c.125-.164.754-.855 1.657-1.425C15.426 22.489 16.61 22 18 22h1.332l4.219 10.125-1.516 4.043a.619.619 0 00.402.8l2.903.997a.673.673 0 00.844-.383l1.777-4.75a.617.617 0 00-.398-.8l-.938-.325L30.668 22H32c1.39 0 2.574.488 3.48 1.059.903.57 1.532 1.261 1.657 1.425v.004c1.668 2.211 5.925 9.149 5.941 9.176.652 1.113.91 1.992.863 2.73-.046.743-.375 1.45-1.238 2.313l-4.621 4.621a1.686 1.686 0 01-2.41 0 1.69 1.69 0 010-2.414L40 36.586a.998.998 0 00.098-1.297l-4.285-5.879a1 1 0 00-1.805.5l-1.504 17c-.04.36.121.711.414.922a1.003 1.003 0 001.578-.742l.184-2.07c1.449 1.09 3.504 1.039 4.82-.278l4.621-4.62c1.09-1.09 1.734-2.302 1.816-3.595.083-1.297-.367-2.566-1.128-3.87-.004-.009-.008-.012-.012-.016 0 0-4.18-6.856-6.063-9.356-.34-.45-1.05-1.2-2.187-1.918C35.41 20.648 33.859 20 32 20H20.156c-.058-.008-.113-.016-.172-.016zM21.5 22h7L25 30.398zm-7.266 10.742l.512 5.758-2.726-2.723zm21.532 0l2.214 3.035-2.726 2.723z" />
    </Svg>
  );
}

export default SvgIcons8PersonalTrainer;
