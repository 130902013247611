import {apiAction} from '../helpers/HTTP';
import {
    FEED,
    MESSAGES_CENTER_COUNT, REMOVE_ACTION_FROM_FEED, REMOVE_FEED, REMOVE_MESSAGES_CENTER_FROM_FEED
} from '../constants';
import ACTIONS from "../components/FeedCard/constants";


export const fetchFeedData = () => async (dispatch) => {
    const actionType = FEED.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('user/feed', 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;

    }
};

export const fetchNotificationsCount = () => async (dispatch) => {
    const actionType = MESSAGES_CENTER_COUNT.toUpperCase();
    try {
        const response = await apiAction('messagesCenter/count', 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
    }
};

export const removeFeed = (id, action) => async (dispatch) => {
    const actionType = REMOVE_FEED.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`, payload: action});
        const response = await apiAction('user/feed', 'delete', {id,action});
        if(action === ACTIONS.ACTION_BOX_MESSAGE){
            dispatch({type: `${REMOVE_MESSAGES_CENTER_FROM_FEED}_SUCCESS`, payload: response});
        }else{
            dispatch({type: `${actionType}_SUCCESS`, payload: response});
        }
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;

    }
};
export const removeActionFromFeeds = (action) => async (dispatch) => {
    const actionType = REMOVE_ACTION_FROM_FEED.toUpperCase();
    try {
        dispatch({type: `${actionType}_SUCCESS`, payload: action});
        return response;
    } catch (error) {
       //TODO  MAYBE LOG
    }
};

