import React, {useEffect, useState} from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {generateProgressBarObj, optionalSteps, stepRendering} from './StepOptions'
import {flowNames, initFlowConfig, renderFlowWrapper} from "./FlowOptions";
import {
    checkPurchaseForGroup,
    createCartDetailsInfo,
    getCartSum,
    isMobile,
    sendMessageToSentryBrowser
} from "../../../helpers/functions";
import {emptyCart} from "../../../actions/shop";
import {t} from "../../../services/i18n";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Platform} from "react-native";
import {globalStyles} from "../../../styles/Global";
import {Colors} from "../../../styles/Colors";
import {
    authenticateUserAfterRegistration,
    registerProcessUpdateStep,
    updateUserCreatedFlag
} from "../../../actions/minisite";
import LoginScreen from "../../../screens/Web/LoginScreen";
import {ErrorHandler} from "../../ErrorHandler";
import Spinner from "react-native-loading-spinner-overlay";
import moment from "moment";
import {View} from "react-native-web";
import ProgressBar from "../Common/ProgressBar";
import {AppText} from "../../Stateless/AppText";
import {apiAction} from "../../../helpers/HTTP";
import {fetchProfile} from "../../../actions/user";
import uuid from "uuid";


const PurchaseFlowsHandler = (props) => {
    const { } = props;
    const user = useSelector(state => state.user.data)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const boxQueryString = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.queryString)
    const stepNumber = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.registerStepNumber)
    const loggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation);
    const cart = useSelector(state => state.shop.cart)

    const [flowConfig, setFlowConfig] = useState(null);
    const [stepComponent, setStepComponent] = useState(null);
    const [personalDetails, setPersonalDetails] = useState(null);
    const [participantDetails, setParticipantDetails] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progressBarSteps, setProgressBarSteps] = useState(null);
    const [zeroPurchase, setZeroPurchase] = useState(false);
    const [digitalForm, setDigitalForm] = useState(null);
    const [groupConnections, setGroupConnections] = useState(null);
    const [selectedConnection, setSelectedConnection] = useState(null);

    const mobile = isMobile()
    const styles = getStyle(mobile)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    //<editor-fold desc="use effect">

    useEffect(() => {
        if(box) {
            init()
        }
    }, [window.location.pathname, box]);

    useEffect(() => {
        if(flowConfig) {
            renderStep(flowConfig.steps[stepNumber])
            if (flowConfig.showProgressBar) { // process bar steps has to rerender each step so the labels will update
                generateProgressBarSteps()
            }
        }
    }, [stepNumber, flowConfig]);

    useEffect(() => {
        if(flowConfig?.steps[stepNumber] !== optionalSteps.SUCCESS_PAGE) {
            if(flowConfig?.name === flowNames.BASIC_USER_REGISTER && loggedIn) {
                clearAndNavigate('/cart')
            } else if(flowConfig?.name === flowNames.EXISTING_USER_PURCHASE) {
                loggedIn ? init() : clearAndNavigate();
            }
            else if(flowConfig?.name === flowNames.GROUP_PARTICIPANT_REGISTRATION) {
                clearAndNavigate('/cart')
            }
        }
    }, [loggedIn]);

    useEffect(() => {
        if(user && flowConfig?.steps[stepNumber] !== optionalSteps.SUCCESS_PAGE) {
            init()
        }
    }, [user]);


    useEffect(() => {
        if(flowConfig) {
            const amount = getCartSum(cart)
            setZeroPurchase(amount === 0)
            handleCartChange()
        }
    }, [cart, flowConfig]);

    useEffect(() => {
        if(loggedIn && groupConnections?.length === 0) {
            window.location.pathname === '/register' ? init() : clearAndNavigate('/register')
        }
    }, [groupConnections]);


    useEffect(() => {
        if(flowConfig?.steps.includes(optionalSteps.PERSONAL_DETAILS) && personalDetails) {
            updateStep()
        }
    }, [personalDetails]);

    useEffect(() => {
        if(flowConfig?.steps.includes(optionalSteps.PARTICIPANT_DETAILS) && participantDetails) {
            updateStep()
        }
    }, [participantDetails]);

    useEffect(() => {
        if(flowConfig?.steps.includes(optionalSteps.DIGITAL_FORM) && digitalForm) {
            updateStep()
        }
    }, [digitalForm]);

    useEffect(() => {
        if(flowConfig?.steps.includes(optionalSteps.GROUP_CONNECTION) && selectedConnection) {
            updateStep()
        }
    }, [selectedConnection]);
    //</editor-fold>

    //<editor-fold desc="initializations">

    const init = async () => {
        updateStep(false, 0)
        const pathName = window.location.pathname;
        let flowName = null
        let params = {}
        switch (pathName) {
            case '/register':
                flowName = checkPurchaseForGroup() ? flowNames.GROUP_PARTICIPANT_REGISTRATION : flowNames.BASIC_USER_REGISTER
                params = {boxHasForm: !!box.digital_form_id, parentExist: loggedIn}
                break;
            case '/cart':
                const connections = await handleGroupConnectionInFlow()
                params = {hasGroup: connections.length > 0}
                flowName = flowNames.EXISTING_USER_PURCHASE
                break;
        }
        const config = initFlowConfig[flowName](params)
        setFlowConfig(config)
    }

    const renderStep = (stepName) => {
        const params = generateStepParams(stepName)
        setStepComponent(stepRendering[stepName](params))
    }

    const generateStepParams = (stepName) => {
        switch(stepName) {
            case optionalSteps.MEMBERSHIP_SELECT:
                return null;
            case optionalSteps.PERSONAL_DETAILS:
                return getPersonalDetailsStepParams();
            case optionalSteps.PARTICIPANT_DETAILS:
                return getParticipantDetailsStepParams();
            case optionalSteps.CART_DISPLAY:
                return getCartDisplayParams();
            case optionalSteps.GROUP_CONNECTION:
                return getGroupConnectionStepParams();
            case optionalSteps.DIGITAL_FORM:
                return getDigitalFormParams();
            case optionalSteps.PAYMENT_METHOD:
                return getPaymentStepParams();
            case optionalSteps.SUCCESS_PAGE:
                return getSuccessPageParams()
        }
    }

    const generateProgressBarSteps = () => {
        let progressSteps = [];
        flowConfig.steps.map(step => {
            const stepObj = generateProgressBarObj({personalDetails, participantDetails})[step]
            if(stepObj) progressSteps.push(stepObj)
        })
        setProgressBarSteps(progressSteps);
    }

    //</editor-fold>

    //<editor-fold desc="get step params">

    const getPersonalDetailsStepParams = () => {
        switch(flowConfig.name) {
            case flowNames.BASIC_USER_REGISTER:
            case flowNames.GROUP_PARTICIPANT_REGISTRATION:
                return  {
                    data: {details: personalDetails},
                    actions: {
                        onLogin: () => setShowLogin(true),
                        onSubmit: (info) => setPersonalDetails(info),
                        onBack: () => {
                            dispatch(emptyCart())
                            updateStep(true)
                        }
                    },
                    toggles: {showAdditionalPhoneField: flowConfig.name === flowNames.GROUP_PARTICIPANT_REGISTRATION, mobile: mobile}
                }
        }
    }

    const getParticipantDetailsStepParams = () => {
        switch(flowConfig.name) {
            case flowNames.GROUP_PARTICIPANT_REGISTRATION:
                return  {
                    data: {details: participantDetails},
                    actions: {
                        onSubmit: (info) => setParticipantDetails(info),
                        onBack: () => {
                            updateStep(true)
                        }
                    },
                    toggles: {mobile: mobile}
                }
        }
    }

    const getCartDisplayParams = () => {
        switch(flowConfig.name) {
            case flowNames.EXISTING_USER_PURCHASE:
                return  {
                    actions: {goToCheckout: updateStep}
                }
        }
    }

    const getGroupConnectionStepParams = () => {
        switch(flowConfig.name) {
            case flowNames.EXISTING_USER_PURCHASE:
                return  {
                    data: {
                        connections: groupConnections
                    },
                    actions: {
                        onConnectionClicked: (connection) => setSelectedConnection(connection),
                        onNewUserClicked: () => clearAndNavigate('/register')
                    },
                    toggles: {mobile: mobile}
                }
        }
    }

    const getDigitalFormParams = () => {
        switch(flowConfig.name) {
            case flowNames.BASIC_USER_REGISTER:
            case flowNames.GROUP_PARTICIPANT_REGISTRATION:
                return  {
                    actions: {
                        onLater: (info) => setDigitalForm(info),
                        submitReplacement: (info) => setDigitalForm(info),
                        onBack: () => updateStep(true),
                        returnOnNoForm: updateStep
                    },
                    stylingOverrides: {
                        wrapperStyles: [mobile ? styles.stepWrapperRegistration : styles.formMobileWrapper]
                    }
                }
        }
    }

    const getPaymentStepParams = () => {
        switch(flowConfig.name) {
            case flowNames.EXISTING_USER_PURCHASE:
                return {
                    actions: {
                        onSuccess: onPurchaseSuccess,
                        onBack: () => updateStep(true)
                    },
                    stylingOverrides: {wrapperStyles: [mobile ? styles.paymentsWrapperMobile : styles.paymentsWrapper]},
                    toggles: { groupInfo: getGroupInfo(), showCartSummary: !mobile}
                }
            case flowNames.BASIC_USER_REGISTER:
            case flowNames.GROUP_PARTICIPANT_REGISTRATION:
                return {
                    actions: {
                        handleCreateUser: handleCreateUser,
                        onBack: () => updateStep(true)
                    },
                    stylingOverrides: {wrapperStyles: [globalStyles.cardsShadow, styles.stepWrapperRegistration], paymentStyles: []},
                    toggles: {signUpDetails: personalDetails || participantDetails, finishProcessBeforeToken: true, showCartSummary: false}
                }
        }
    }

    const getSuccessPageParams = () => {
        dispatch(emptyCart())
        switch(flowConfig.name) {
            case flowNames.EXISTING_USER_PURCHASE:
            case flowNames.GROUP_PARTICIPANT_REGISTRATION:
                return {
                    subHeader: t('modals:Buy:texts:success-receipt-msg', {email: user.email}),
                    onClose: () => navigate({pathname: '/shop', search: boxQueryString}),
                    isPopup: mobile
                }
            case flowNames.BASIC_USER_REGISTER:
                return {
                    title: t('screens:Register:successPage:welcome', {firstName: personalDetails?.firstName}),
                    subHeader: t('screens:Register:successPage:welcome-subheader', {}),
                    onClose: () => navigate({pathname: '/schedule', search: boxQueryString}),
                    isPopup: mobile
                }
        }
    }

    const getWrapperParams = () => {
        let params = {}
        switch(flowConfig.name) {
            case flowNames.EXISTING_USER_PURCHASE:
                break;
            case flowNames.BASIC_USER_REGISTER:
                params.loggedIn = loggedIn
                params.hasPayment = location?.hasPayments
                params.flowConfig = flowConfig
                params.onSuccessPage = flowConfig.steps[stepNumber] === optionalSteps.SUCCESS_PAGE
                break;
        }
        return params;
    }

    //</editor-fold>

    //<editor-fold desc="generic behavior">

    const handleCartChange = () => {
        switch (flowConfig.name) {
            case flowNames.BASIC_USER_REGISTER:
                if(cart && cart.length > 0) {
                    updateStep(false, 1) // if membership was selected, go to next step. specific step because of registration from shop!
                }
                break;
        }
    }

    const updateStep = (isPrev = false, specificStep = null) => {
        if(specificStep !== null) {
            return dispatch(registerProcessUpdateStep(specificStep))
        } else {
            if (flowConfig.steps) {
                if (isPrev && stepNumber !== 0) {
                    dispatch(registerProcessUpdateStep(stepNumber - 1))
                } else if (!isPrev && (stepNumber !== (flowConfig.steps.length - 1))) {
                    dispatch(registerProcessUpdateStep(stepNumber + 1))
                } else {
                    clearAndNavigate('/cart')
                }
            }
        }
    }

    const clearAndNavigate = (route) => {
        setFlowConfig(null)
        dispatch(registerProcessUpdateStep(0))
        if(route) {
            navigate({
                pathname: route,
                search: boxQueryString,
            })
        } else {
            window.location.reload()
        }
    }

    //</editor-fold>

    //<editor-fold desc="EXISTING_USER_PURCHASE behavior">

    const onPurchaseSuccess = () => {
        updateStep()
        dispatch(emptyCart())
    }

    const handleGroupConnectionInFlow = async () => {
        if(loggedIn && Object.keys(user).length && checkPurchaseForGroup()) {
            try {
                const userBox = user.users_boxes.find(userBox => userBox.box_fk === box.id)
                let connections = await apiAction(`workshops/getUserGroup/${userBox.ub_id}`, 'get');
                connections = connections.map(connection => {return {full_name: `${connection.user_box.first_name} ${connection.user_box.last_name}`, ub_id: connection.user_box.id, user_id: connection.user_box.user_fk}})
                setGroupConnections(connections);
                return connections
            } catch(error) {
                console.log('what', error)
                setErrors([error.error.messageToUser])
            }
        }
        return []
    }
    //</editor-fold>

    //<editor-fold desc="BASIC_USER_REGISTER behavior">

    const closeLoginAfterError = () => {
        updateStep(false,0)
        window.location.reload()
    }

    const handleCreateUser = async (values, paymentObject) => {
        setIsLoading(true)
        let params = {
            tokenInfo: Object.keys(values).length ? values : null,
            payment_object: paymentObject,
            box_fk: location.box_fk,
            locations_box_fk: location.id,
            parent: getParentDetails(),
            child: getChildDetails(),
            hasPayments: !zeroPurchase,
            cart: cart,
            digitalFormInfo: digitalForm,
        };
        try {
            const messageId = uuid.v4()
            sendMessageToSentryBrowser("in-createUserFromSite-message", JSON.stringify(params), messageId)
            const response = await apiAction('site/createUserFromSite', 'post', params);
            sendMessageToSentryBrowser("in-createUserFromSite-message-response", JSON.stringify(response), messageId)
            if(response.user) {
                updateStep() // this is specific for zero purchase fallback (otherwise, the next step will be payment)
                dispatch(updateUserCreatedFlag(moment().add(2, 'hours')))
                dispatch(authenticateUserAfterRegistration(response.user))
                dispatch(fetchProfile())
                setIsLoading(false)
            }

        } catch(error) {
            setErrors([error.error.messageToUser])
            setIsLoading(false)
        }
    }

    const getParentDetails = () => {
        if(loggedIn && user) {
            return  { id: user.id}
        } else {
            return {
                first_name: personalDetails.firstName,
                last_name: personalDetails.lastName,
                phone: personalDetails.phone,
                additional_phone: personalDetails.additionalPhone,
                email: personalDetails.email,
                gender: personalDetails.gender,
                birthday: personalDetails.birthDate,
                leadId: personalDetails.leadId || null,
            }
        }
    }

    const getChildDetails = () => {
        if(participantDetails) {
            return  {
                first_name: participantDetails.firstName,
                last_name: participantDetails.lastName,
                phone: participantDetails.phone,
                personal_id: participantDetails.id,
                gender: participantDetails.gender,
                birthday: participantDetails.birthDate,
            }
        } else {
            return null
        }
    }
    //</editor-fold>

    //<editor-fold desc="GROUP_PARTICIPANT_REGISTRATION behavior">
    const getGroupInfo = () => {
        if(selectedConnection) {
            return {child_id: selectedConnection.user_id}
        } else {
            return null;
        }
    }
    //</editor-fold>


    return (
        <>
            {showLogin && <LoginScreen show={showLogin} handleSubmit={closeLoginAfterError} setClose={closeLoginAfterError} handleForgotPassword={() => console.log('forgot password')}/>}
            {errors ? <ErrorHandler errors={errors} setClose={() => setShowLogin(true)}/> : null}
            <Spinner visible={isLoading}/>
            <>
                {
                    progressBarSteps && flowConfig?.showProgressBar && flowConfig.steps[stepNumber] !== optionalSteps.SUCCESS_PAGE ?
                        <View style={[styles.wrapper]}>
                            <View style={[styles.progressBar]}>
                                <ProgressBar processSteps={progressBarSteps} stepNumber={stepNumber}/>
                            </View>
                            <View style={[!mobile && styles.contentWrapper]}>
                                {!mobile && <AppText style={[styles.stepHeader]}>{progressBarSteps[stepNumber]?.label}</AppText>}
                                {renderFlowWrapper(flowConfig.name, stepComponent, mobile, getWrapperParams())}
                            </View>
                        </View>
                        :
                        <>{flowConfig && renderFlowWrapper(flowConfig.name, stepComponent, mobile, getWrapperParams())}</>
                }
            </>
        </>
    );
};

export default PurchaseFlowsHandler;

let getStyle = function (mobile) {
    return EStyleSheet.create({
        ...Platform.select({
            web: {
                wrapper: {
                    flexDirection: mobile ? 'column' : 'row',
                    position: 'relative',
                    height: mobile ? '100%' : `100% - ${EStyleSheet.value('$desktopHeader')}`,
                    backgroundColor: Colors.siteBg,
                    overflow: 'auto'
                },
                progressBar: {
                    padding: mobile ? '1em' : 0,
                    backgroundColor: mobile ? Colors.white : 'unset',
                    height: mobile ? '$progressBarMobile' : 'unset',
                    marginHorizontal: mobile ? 0 : '3em',
                    position: mobile ? 'unset' : 'absolute',
                    top: '25%',
                    left: '3%',
                    width: mobile ? '100%' : 'unset'
                },
                contentWrapper: {
                    marginHorizontal: 'auto',
                    marginTop: '4em',
                    width: '55%',
                    marginInlineStart: '30%'
                },
                stepHeader: {
                    fontFamily: 'coolvetica-rg',
                    fontSize: '1.3rem',
                    alignSelf: 'center',
                    marginBottom: '2em'
                },
                paymentsWrapper: {
                    width: '30%'
                },
                paymentsWrapperMobile: {
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%'
                },
                stepWrapperRegistration: {
                    width: mobile ? '100%' : '28em',
                    marginHorizontal: 'auto',
                    backgroundColor: Colors.white,
                    paddingHorizontal: '2em',
                    borderRadius: 3,
                    marginTop: mobile ? '1em' : 0,
                    height: mobile ? `100% - ${EStyleSheet.value('$progressBarMobile') + EStyleSheet.value('$mobileHeader')}` : 'unset',
                    overflow: 'auto'
                },
                formMobileWrapper: {
                    height: 400,
                    width: 430,
                    marginHorizontal: 'auto'
                }
            }
        })
    })
}