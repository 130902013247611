// @flow
import * as React from 'react';
import {View} from "react-native-web";
import {AppText} from "../../Stateless/AppText";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {useSelector} from "react-redux";
import {isMobile} from "../../../helpers/functions";
import {t} from "../../../services/i18n";

const ProgressBar = (props) => {
    const {processSteps, stepNumber} = props;
    const primaryColor = useSelector(state => state.whiteLabelProperties.primaryColor);
    const mobile = isMobile();

    return (
        <View style={[mobile && styles().wrapperMobile]}>
            <View style={[styles(primaryColor).contentWrapper, mobile && styles().contentWrapperMobile]}>
                {
                    processSteps.map((step, index) =>
                        <View style={[styles().stepWrapper, mobile && {flex:1}, (index === processSteps.length - 1) && styles().lastCircle]} key={`progress-bar-${step.label}-${index}`}>
                            <View style={[styles().infoWrap]}>
                                {
                                    !mobile &&
                                    <View style={[styles().stepInfo]}>
                                        <AppText style={[styles().stepNumber, stepNumber === index ? styles().activeStepNumber : null]}>{t('common:step', {stepNumber: index + 1})}</AppText>
                                        <AppText style={[styles().stepHeader, stepNumber === index ? styles().activeStepHeader : null]}>{step.label}</AppText>
                                        <View style={[styles().subheadersWrapper]}>
                                            {step.subheaders.map(subheader => <AppText style={[styles().subheaderText]} key={`text-progressbar-${subheader}`}>{subheader}</AppText>)}
                                        </View>
                                    </View>
                                }
                                <View style={[styles(primaryColor).circle, {backgroundColor: stepNumber >= index ? primaryColor : Colors.progressBar}]}/>
                            </View>
                            {
                                index !== processSteps.length - 1 &&
                                <>
                                    <View style={[mobile ? styles(primaryColor).stepBarMobile : styles(primaryColor).stepBar, {backgroundColor: stepNumber >= index ? primaryColor : Colors.progressBar}]}/>
                                    <View style={[mobile ? styles(primaryColor).stepBarMobile : styles(primaryColor).stepBar, {backgroundColor: stepNumber > index ? primaryColor : Colors.progressBar}]}/>
                                </>
                            }
                        </View>
                    )
                }
            </View>
            {
                mobile && processSteps &&
                <View style={[styles().mobileStepInfo]}>
                    <AppText style={[styles().stepHeader,styles().stepHeaderMobile, styles().activeStepHeader]}>{processSteps[stepNumber].label}</AppText>
                    <AppText style={[styles().stepNumber, styles().stepNumberMobile, styles().activeStepNumber]}>{t('common:step', {stepNumber: stepNumber + 1})}</AppText>
                </View>
            }
        </View>
    );
};
export default ProgressBar;


const styles = (primaryColor, mobile) => EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapperMobile: {
                height:'100%',
                justifyContent: 'space-between'
            },
            contentWrapper: {
                marginBottom: '2em',
                marginRight: '10em',
            },
            contentWrapperMobile: {
                marginBottom: 0,
                flexDirection: 'row',
                width: '100%',
                marginRight: 0,
            },
            stepWrapper: {
                alignItems: 'center',
                flexDirection: 'inherit',
            },
            circle: {
                width: 13,
                height: 13,
                backgroundColor: primaryColor,
                borderRadius: '50%',
            },
            stepNumber: {
                fontSize: '0.8rem',
                color: Colors.labelColor
            },
            activeStepNumber: {
                color: Colors.grayText
            },
            stepHeader: {
                fontSize: '1rem',
                width: 'max-content',
                fontFamily: 'coolvetica-rg',
                color: Colors.grayText,
            },
            subheaderText: {
                fontSize: '0.75rem',
                width: 'max-content',
                color: Colors.grayText,
            },
            subheadersWrapper: {
                marginTop: '0.2em'
            },
            activeStepHeader: {
                color: Colors.lightBlack
            },
            stepBar: {
                width:  4,
                height: '3em',
                backgroundColor: primaryColor,
            },
            stepBarMobile: {
                height: 3,
                flex: 1,
                backgroundColor: primaryColor,
            },
            infoWrap: {
                position: 'relative',
            },
            stepInfo: {
                position: 'absolute',
                left: '1.5em',
            },
            lastCircle: {
                maxWidth: 'fit-content',
                margin: 'auto'
            },
            stepHeaderMobile: {
                fontSize: '1rem',
            },
            stepNumberMobile: {
                fontSize: '0.8rem',
            }
        }
    })
})