import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgEmpty(props) {
  return (
      <Svg width={36} height={38} viewBox="0 0 36 38" {...props}>
          <G fillRule="nonzero" fill="none">
              <Path
                  d="M31 4c-.404-1.504-1.344-3-3-3H8C6.343 1 5.424 2.467 5 4L0 25v9h36v-9L31 4z"
                  fill="#FFB74D"
              />
              <Path
                  d="M31 4c-.404-1.504-1.344-3-3-3H8C6.343 1 5.424 2.467 5 4L0 25v9a3 3 0 003 3h30c1.656 0 3-1.344 3-3v-9L31 4zm-24.063.502C7.026 4.188 7.405 3 8 3h20c.514 0 .905.927 1.062 1.494L33.944 25H24a6 6 0 01-12 0H2.056L6.937 4.502z"
                  fill="#FFCC80"
              />
          </G>
      </Svg>
  );
}

export default SvgEmpty
