import React from 'react';
import {getSvg8IconsDefault} from "../../../helpers/functions";
import * as Svg from '../../../svg'
import {AppText} from "../AppText";

export const Icons8Generator = props => {
    const {name} = props;
    let svgProps = {...getSvg8IconsDefault(), ...props};
    if (props.size) {
        svgProps.width = props.size;
        svgProps.height = props.size;
    }

    try {
        switch (name) {
            case 'eye':
                return <Svg.Icons8Eye {...svgProps} />;
            case 'camera':
                return <Svg.Icons8EditPropery {...svgProps} />;
            case 'email':
                return <Svg.Icons8Envelope {...svgProps} />;
            case 'web':
                return <Svg.Icons8Website {...svgProps} />;
            case 'phone-out':
                return <Svg.Icons8OutgoingCall {...svgProps} />;
            case 'phone':
                return <Svg.Icons8Phone {...svgProps} />;
            case 'clubs':
            case 'fitness-center':
                return <Svg.Icons8BuildingWithRooftopTerrace {...svgProps} />;
            case 'person-pin':
                return <Svg.Icons8PersonalTrainer {...svgProps} />;
            case 'people':
                return <Svg.Icons8UserAccount {...svgProps} />;
            case 'close':
                return <Svg.Icons8Delete {...svgProps} />;
            case 'check':
                return <Svg.Icons8Checkmark {...svgProps} />;
            case 'cake':
                return <Svg.Icons8CuteCake {...svgProps} />;
            case 'clock-outline':
            case 'clock':
                return <Svg.Icons8Clock {...svgProps} />;
            case 'ghost':
                return <Svg.Icons8SadGhost {...svgProps} />;
            case 'check-circle':
            case 'ok':
                return <Svg.Icons8Ok {...svgProps} />;
            case 'refresh':
                return <Svg.Icons8Synchronize {...svgProps} />;
            case 'call-received':
            case 'arrow-up':
                return <Svg.Icons8CollapseArrow {...svgProps} />;
            case 'arrow-down':
                return <Svg.Icons8ExpandArrow {...svgProps} />;
            case 'user-following':
                return <Svg.Icons8CheckedUserMale {...svgProps} />;
            case 'remove-user':
                return <Svg.Icons8DeleteUserMale {...svgProps} />;
            case 'dots-horizontal':
                return <Svg.Icons8More {...svgProps} />;
            case 'link':
                return <Svg.Icons8Handshake {...svgProps} />;
            case 'handshake-heart':
                return <Svg.Icons8HandshakeHeart {...svgProps} />;
            case 'edit-property':
                return <Svg.Icons8EditProperty {...svgProps} />;
            case 'balloon':
                return <Svg.Icons8PartyBalloon {...svgProps} />;
            case 'date-range':
            case 'date-ranges':
                return <Svg.Icons8Calendar3 {...svgProps} />;
            case 'calendar':
                return <Svg.Icons8Calendar {...svgProps} />;
            case 'calendar-filled':
                return <Svg.Icons8CalendarFilled {...svgProps} />;
            case 'calendar-booked':
                return <Svg.Icons8CalendarBooked {...svgProps} />;
            case 'calendar-delete':
                return <Svg.Icons8CalendarDelete {...svgProps} />;
            case 'addusergroup':
            case 'account-plus':
                return <Svg.Icons8AddUserMale {...svgProps} />;
            case 'plus':
                return <Svg.Icons8PlusMath {...svgProps} />;
            case 'remove-class':
            case 'trash':
                return <Svg.Icons8TrashCan {...svgProps} />;
            case 'spots-left':
                return <Svg.Icons8FullTank {...svgProps} />;
            case 'waiting-list':
                return <Svg.Icons8AddList {...svgProps} />;
            case 'book-class':
                return <Svg.Icons8PlusMath {...svgProps} />;
            case 'status-heart':
            case 'skill':
                return <Svg.Icons8HeartWithPulse {...svgProps} />;
            case 'status-clock':
                return <Svg.Icons8Clock {...svgProps} />;
            case 'status-calendar':
            case 'today':
                return <Svg.Icons8Today {...svgProps} />;
            case 'status-average':
            case 'line-chart':
                return <Svg.Icons8LineChart {...svgProps} />;
            case 'sleep':
                return <Svg.Icons8Relax {...svgProps} />;
            case 'logbook':
                return <Svg.Icons8Dumbbell {...svgProps} />;
            case 'logout':
                return <Svg.Icons8LogoutRoundedUp {...svgProps} />;
            case 'settings':
                return <Svg.Icons8Settings {...svgProps} />;
            case 'error':
                return <Svg.Icons8RoadConstruction {...svgProps} />;
            case 'view-profile':
                return <Svg.Icons8ResumeWebsite {...svgProps} />;
            case 'call':
                return <Svg.Icons8OutgoingCall {...svgProps} />;
            case 'lock':
                return <Svg.Icons8Lock {...svgProps} />;
            case 'unlock':
                return <Svg.Icons8PadLock {...svgProps} />;
            case 'edit':
                return <Svg.Icons8EditProperty{...svgProps} />;
            case 'pencil':
                return <Svg.Icons8PencilDrawing{...svgProps} />;
            case 'arrow':
                return <Svg.Icons8Forward {...svgProps} />;
            case 'arrow-rtl':
                return <Svg.Icons8Back {...svgProps} />;
            case 'trash-can':
                return <Svg.Icons8TrashCan {...svgProps} />;
            case 'tv':
                return <Svg.Icons8TvShow {...svgProps} />;
            case 'full-tank':
                return <Svg.Icons8FullTank {...svgProps} />;
            case 'search':
                return <Svg.Icons8Search {...svgProps} />;
            case 'notification':
                return <Svg.Icons8Notification {...svgProps} />;
            case 'two-tickets':
            case 'session-shop':
            case 'sessions':
                return <Svg.Icons8TwoTickets {...svgProps} />;
            case 'pause':
                return <Svg.Icons8Pause {...svgProps} />;
            case 'to-do':
                return <Svg.Icons8ToDo {...svgProps} />;
            case 'user':
                return <Svg.Icons8User {...svgProps} />;
            case 'preferences':
                return <Svg.Icons8Slider {...svgProps} />;
            case 'info':
                return <Svg.Icons8About {...svgProps} />;
            case 'call-made':
                return <Svg.Icons8PaperPlane {...svgProps} />;
            case 'gymnastics':
                return <Svg.Icons8Gymnastics {...svgProps} />;
            case 'print':
                return <Svg.Icons8Print {...svgProps} />;
            case 'view-details':
                return <Svg.Icons8ViewDetails {...svgProps} />;
            case 'puzzled':
                return <Svg.Icons8Puzzled {...svgProps} />;
            case 'prize':
                return <Svg.Icons8Prize {...svgProps} />;
            case 'restart':
                return <Svg.Icons8Restart {...svgProps} />;
            case 'time':
                return <Svg.Icons8Time {...svgProps} />;
            case 'leader-board':
                return <Svg.Icons8LeaderBoard {...svgProps} />;
            case 'friends':
                return <Svg.Icons8Friends {...svgProps} />;
            case 'plan-shop':
            case 'membership-card':
                return <Svg.Icons8MembershipCard {...svgProps} />;
            case 'cart':
                return <Svg.Icons8ShoppingCart {...svgProps} />;
            case 'minos':
            case 'subtract':
                return <Svg.Icons8Substract {...svgProps} />;
            case 'bank':
            case 'ach':
                return <Svg.Icons8BankBuilding {...svgProps} />;
            case 'card':
                return <Svg.Icons8CreditCard {...svgProps} />;
            case 'credit-card':
                return <Svg.Icons8CreditCardBlack {...svgProps} />;
            case 'shopaholic':
                return <Svg.Icons8Shopaholic {...svgProps} />;
            case 'item-shop':
            case 'shopaholic-filled':
                return <Svg.Icons8ShopaholicFilled {...svgProps} />;
            case 'shopaholic-colored':
                return <Svg.Icons8ShopaholicColored {...svgProps} />;
            case 'forward-arrow':
                return <Svg.Icons8ForwardArrow {...svgProps} />;
            case 'couch':
                return <Svg.Icons8Couch {...svgProps} />;
            case 'location':
                return <Svg.Icons8Location {...svgProps} />;
            case 'couple':
                return <Svg.Icons8Couple {...svgProps} />;
            case 'payCard':
                return <Svg.Icons8PayCard {...svgProps} />;
            case 'mobile-phone':
                return <Svg.Icons8MobilePhone {...svgProps} />;
            case 'download':
                return <Svg.Icons8Download {...svgProps} />;
            case 'name-tag':
                return <Svg.Icons8NameTag {...svgProps} />;
            case 'ticket':
                return <Svg.Icons8Ticket {...svgProps} />;
            case 'home':
                return <Svg.Icons8Home {...svgProps} />;
            case 'menu':
                return <Svg.Icons8HamburgerMenu {...svgProps} />;
            case 'lang-blob':
                return <Svg.Icons8LanguageBlob {...svgProps} />;
            case 'error-circle':
                return <Svg.Icons8Error {...svgProps} />;
            case 'warning-circle':
                return <Svg.Icons8Warning {...svgProps} />;
            case 'success-circle':
                return <Svg.Icons8Success {...svgProps} />;
            default:
                return <AppText>{props.name}</AppText>
        }


    } catch (e) {
    }
};