// @flow
import * as React from 'react';
import {Platform, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import moment from "moment";
import {Colors} from "../../../../styles/Colors";
import CalendarStrip from 'react-native-calendar-strip';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import SingleDayContainer from "./SingleDayContainer";
import {isLocationIL} from "../../../../helpers/functions";
import {isSiteHebrew} from "../../../../helpers/ComponentFunctionality";
import {updateHeaderConfig} from "../../../../actions/minisite";
import {GROUP_SESSION_PROCESS} from "../../../../constants";

const GroupSession = (props) => {
    const {tabsDisplayed} = props;
    const today = moment()
    let start = moment().add(0, 'weeks').startOf(isLocationIL() ? 'week' : 'isoWeek');
    const [selectedDate, setSelectedDate] = useState(today);
    const [startDate, setStartDate] = useState(start);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateHeaderConfig({processName: GROUP_SESSION_PROCESS}))

    }, []);

    const onDateSelected = (newDate) => {
        setSelectedDate(newDate.format('YYYY-MM-DD'))
        let start = newDate.clone().add(0, 'weeks').startOf(isLocationIL() ? 'week' : 'isoWeek');
        setStartDate(start)
    }

    return (
        <View style={styles.wrapper}>
            <View style={styles.calendar}>
                <CalendarStrip
                    scrollable={false}
                    style={{height: EStyleSheet.value('$calendarStripHeight'), padding: 10, backgroundColor: Colors.iconBorder}}
                    calendarHeaderStyle={styles.calendarHeader}
                    dateNumberStyle={styles.dateNum}
                    dateNameStyle={{fontSize: '0.7rem'}}
                    calendarHeaderFormat='MMM YYYY'
                    highlightDateContainerStyle={{borderWidth: 1, borderColor: Colors.black}}
                    highlightDateNameStyle={{color: Colors.lightBlack, fontSize: '0.6rem'}}
                    highlightDateNumberStyle={{color: Colors.lightBlack}}
                    selectedDate={selectedDate}
                    startingDate={startDate}
                    onDateSelected={onDateSelected}
                    iconLeftStyle={[isSiteHebrew() && {transform: [{rotate: '180deg'}]}]}
                    iconRightStyle={[isSiteHebrew() && {transform: [{rotate: '180deg'}]}]}
                    useIsoWeekday={false}
                    scrollToDate={false}
                />
            </View>
            <SingleDayContainer date={selectedDate} tabsDisplayed={tabsDisplayed}/>
        </View>
    );
};
export default GroupSession;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {},
            calendar: {
                marginBottom: '0.7em'
            },
            calendarHeader: {
                color: Colors.calendarBlack,
                fontWeight: '300',
                fontSize: '0.9rem',
                marginBottom: 5
            },
            dateNum: {
                fontWeight: '500',
                fontSize: '0.95rem',
                // marginTop: '0.5em'
            }
        }
    })
})