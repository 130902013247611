import React from 'react';
import {TouchableOpacity, View} from "react-native";
import {withNavigation} from "react-navigation";
import i18n from "../../../services/i18n";
import {Icons8Generator} from "../Icons8Generator";
import {getThemeProperty, keys} from "../../../styles/Theme";


class HeaderTitle extends React.PureComponent {
    render() {
        return (
            <TouchableOpacity style={{zIndex: 10000}}
                              hitSlop={{top: 20, bottom: 20, left: 100, right: 100}}
                              onPress={() => {
                                  this.props.customArrowBehaviour  ? this.props.customArrowBehaviour() : this.props.pop ? this.props.navigation.pop() : this.props.navigation.goBack();
                              }}>
                <Icons8Generator name={!i18n.isRTL ? 'arrow-rtl' : 'arrow'}
                                 fill={this.props.headerColor ? this.props.headerColor : getThemeProperty(keys.brandedGreen)}/>
            </TouchableOpacity>
        )
    }
}

export default withNavigation(HeaderTitle);
