import {apiAction} from '../helpers/HTTP';
import {
    PROFILE,
    UPDATE_PROFILE,
    PATCH_AUTH_DETAILS,
    CHANGE_LANGUAGE,
    SELECT_BOX_ID,
    REMOVE_FRIEND_FROM_USER,
    ADD_FRIEND_TO_USER, DELETE_PROFILE_IMAGE, SET_ACTIVE_USERS_BOXES, LOGOUT_USER,

} from '../constants';
import {HIDE_MODAL} from "../modals/constants";


export const fetchProfile = () => async (dispatch) => {

    const actionType = PROFILE.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`user/profile`, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const patchUpdateProfileResponse = (user) => async (dispatch) => {
    dispatch({type: `${UPDATE_PROFILE}_SUCCESS`, payload: user});
};

export const removeFriend = (id) => async (dispatch) => {
    dispatch({type: `${REMOVE_FRIEND_FROM_USER}_SUCCESS`, payload: {id}});
};

export const addFriend = (data) => async (dispatch) => {
    dispatch({type: `${ADD_FRIEND_TO_USER}_SUCCESS`, payload: data});
};

export const updateProfile = (user) => async (dispatch) => {
    const actionType = UPDATE_PROFILE.toUpperCase();
    const patchAuthDetails = PATCH_AUTH_DETAILS.toUpperCase();
    // destructure for sending slim json.
    const {first_name, last_name, bio, birthday, height, weight, gender, phone} = user;
    const data = {first_name, last_name, bio, birthday, height, weight, gender, phone};
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`user/updateUserDetails`, 'patch', data);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        // IF WE'RE HERE WE NEED TO PATCH AUTH, IT HAS USER "STATIC DATA"
        // TODO REMOVE, keep only token, id, firstLogin
        dispatch({type: `${patchAuthDetails}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const selectClubId = clubId => {
    const actionType = SELECT_BOX_ID.toUpperCase();
    return dispatch => {
        dispatch({type: `${actionType}_PENDING`});
        dispatch({
            type: `${actionType}_SUCCESS`,
            payload: clubId,
        });
        dispatch({type: HIDE_MODAL});
    };
};

export const changeLanguage = (language) => async (dispatch) => {
    const actionType = CHANGE_LANGUAGE.toUpperCase();
    const data = {language};
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(`user/settings/changeLanguage`, 'patch', data);
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }

};


export const deleteProfileImage = () => async (dispatch) => {
    const actionType = DELETE_PROFILE_IMAGE.toUpperCase();
    try {
        const response = await apiAction(`user/deleteProfileImage`, 'delete', {});
        dispatch({type: `${actionType}_SUCCESS`, payload: response.data});
        return response;
    } catch (error) {
        return error;
    }

};

export const setActiveUsersBoxes = (usersBoxes) => async (dispatch) => {
    const actionType = SET_ACTIVE_USERS_BOXES.toUpperCase();
    try {
        dispatch({type: `${actionType}_SUCCESS`, payload: usersBoxes});
    } catch (error) {
        return error;
    }

};

export const logoutUserProfile = () => async (dispatch) => {
        dispatch({type: LOGOUT_USER});
};