import React from 'react';
import {globalStyles} from "../../../styles/Global";
import { View } from 'react-native';
import {verticalScale} from 'react-native-size-matters';

export const GraySeparator = props => {
    const override = props.overrideStyle ? props.overrideStyle : null;
    const height = props.height ? props.height : verticalScale(1);
    const customStyle = props.style || [];
    return (
        <View style={[globalStyles.graySeparator,{ height },override ? override : null, ...customStyle]}/>
    )
};



