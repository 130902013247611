import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgFinger(props) {
  return (
      <Svg width={139} height={156} viewBox="0 0 139 156" {...props}>
          <G fill="none" fillRule="evenodd">
              <G fillRule="nonzero">
                  <Path
                      d="M107.617 74.825v-3.159H69.852l-.047-44.224c0-3.494-3.452-9.442-9.746-9.442-6.295 0-9.09 6.283-9.09 9.442v75.788L37.202 90.528c-4.374-4.397-11.54-3.86-15.225.578-3.887 4.397-2.92 11.23.975 15.128l17.914 17.98C50.665 134.845 65.324 138 75.026 138h21.649c16.05 0 29.825-12.812 29.825-28.913V74.822l-18.883.003z"
                      fill="#FFCC80"
                  />
                  <Path
                      d="M69.5 93.81c0 5.058 4.275 9.19 9.5 9.19s9.5-4.135 9.5-9.19V71.184C88.5 66.128 84.225 62 79 62s-9.5 4.132-9.5 9.184V93.81zm19 0c0 5.058 4.275 9.19 9.5 9.19s9.5-4.135 9.5-9.19V71.184c0-5.056-4.275-9.184-9.5-9.184s-9.5 4.132-9.5 9.184V93.81zm19-.026c0 5.056 4.275 9.19 9.5 9.19s9.5-4.134 9.5-9.19V74.338c0-5.056-4.275-9.184-9.5-9.184s-9.5 4.131-9.5 9.184v19.446z"
                      fill="#FFCC80"
                  />
                  <Path
                      d="M53.706 27.65c.039-3.71 2.924-6.688 6.469-6.65 3.528.042 6.366 3.088 6.325 6.787-.036 3.714-12.83 3.572-12.794-.137m-28.811 75.931a7.084 7.084 0 01-.557-10.07c2.662-2.929 7.217-3.166 10.176-.538 2.963 2.635-6.657 13.25-9.62 10.608"
                      fill="#FFF"
                  />
              </G>
              <Path
                  stroke="#F5C2D1"
                  strokeWidth={10}
                  strokeLinecap="round"
                  d="M132 36h-27M42 36H15"
              />
              <Path
                  d="M1.789 34.606l17.764-8.882a1 1 0 011.447.894v17.764a1 1 0 01-1.447.894L1.789 36.394a1 1 0 010-1.788z"
                  fill="#F5C2D1"
              />
              <Path
                  stroke="#F5C2D1"
                  strokeWidth={10}
                  strokeLinecap="round"
                  d="M89 36h-9"
              />
          </G>
      </Svg>
  );
}

export default SvgFinger;
