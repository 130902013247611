// @flow
import * as React from 'react';
import {TouchableOpacity, View} from "react-native-web";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {AppText} from "../../../Stateless/AppText";
import {globalStyles} from "../../../../styles/Global";
import {updateHeaderConfig} from "../../../../actions/minisite";
import {isMobile} from "../../../../helpers/functions";

const ScheduleOptions = (props) => {
    const {options, header} = props;
    const dispatch = useDispatch();
    const mobile = isMobile();

    useEffect(() => {
        dispatch(updateHeaderConfig({}))
    }, []);

    return (
        <View style={[mobile ? styles.mobileWrapper : styles.wrapper]}>
            <View style={[mobile ? styles.mobileContentWrapper : styles.contentWrapper]}>
                <AppText style={[mobile ? styles.mobileHeader : styles.header]} boldFont>{header}</AppText>
                <View style={[mobile ? styles.mobileCardsWrapper : styles.cardsWrapper]}>
                    {options.map((option, index) => {
                        const Img = option.img;
                        return (
                                <TouchableOpacity onPress={option.onCardPress} style={[globalStyles.cardContainer, mobile ? styles.mobileCard : styles.card]} key={`schedule-potion-${index}`}>
                                    <Img width={mobile ? '2em' : '6em'} height={mobile ? '2em' : '6em'}/>
                                    <AppText style={styles.label} boldFont>{option.label}</AppText>
                                </TouchableOpacity>
                            )}
                    )}
                </View>
            </View>
        </View>
    );
};

export default ScheduleOptions;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                alignItems: 'center',
                height: '100%',
                maxHeight: '100%'
            },
            mobileWrapper: {
              padding: '1em'
            },
            header: {
                fontSize: '1.5rem',
                textAlign: 'center',

            },
            cardsWrapper: {
                gap: '1em',
                flexDirection: 'row',
            },
            card: {
                marginBottom: 0,
                padding: '2.5em',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.5em',
                width: '15em',
                minHeight: '17em',
                shadowColor: 'rgba(0, 0, 0, 0.06)',
            },
            mobileCard: {
                marginBottom: 0,
                padding: '2em',
                width: '100%',
                height: 'auto',
                minHeight: 'auto',
                shadowColor: 'rgba(0, 0, 0, 0.06)',
                gap: '1.5em',
                flexDirection: 'row',
                alignItems: 'center'
            },
            label: {
                fontSize: '0.9rem',
                fontWeight: 'bold',
                textAlign: 'center'
            },
            mobileHeader: {
                fontSize: '0.8rem',
            },
            contentWrapper: {
                marginHorizontal:'auto',
                gap: '2em',
                marginTop: '3em',
                alignItems: 'center'
            },
            mobileContentWrapper: {
                gap: '2em',
            },
            mobileCardsWrapper: {
                gap: '1em',
            }
        }
    })
})