

import React, { Component } from 'react';
import {Text } from 'react-native';
import {UnicodeHelper} from "../UnicodeHelper";
import { ScaledSheet,scale } from 'react-native-size-matters';
import {globalStyles} from "../../../styles/Global";
import {Colors} from "../../../styles/Colors";

export class AppText extends Component {
    render() {
        return (
            <Text {...this.props} style={[globalStyles.directionText,styles.myAppText, this.props.style, this.props.boldFont && {fontFamily: 'heebo-bold'}]}><UnicodeHelper/>{this.props.children}</Text>
        )
    }
}

const styles = ScaledSheet.create({
    myAppText: {
        fontFamily: 'heebo-light',
        fontSize: scale(13),
        flexWrap:'wrap',
        alignItems: 'flex-start',
        maxWidth: scale(280),
        color:Colors.black,
    }
});


