import { StyleSheet, Dimensions } from 'react-native';
import { Colors } from '../../styles/Colors';
import { scale } from 'react-native-size-matters';
import { horizontalRTLFlip, isTextRTLStyles } from '../../styles/Layout';

export default StyleSheet.create({
  inviteFriendsListWrapper: {
    height: Dimensions.get('screen').height / 2.5,
  },
  searchInput: {
    paddingLeft: 5,
    paddingBottom: 3,
    ...isTextRTLStyles,
  },
  searchInputIcon: {
    color: Colors.lightGrey,
    fontSize: scale(12),
    ...horizontalRTLFlip,
  },
  checkmarkIcon: {
    color: '#FFFFFF',
    fontSize: scale(10),
  },
  listHeaderWrapper: {
    paddingVertical: scale(10),
  },
  listHeader: {
    flexDirection: 'row',
    width: '100%',
    borderBottomWidth: 1,
    borderColor: Colors.lightGrey,
  },
  avatar: {
    marginRight: 10,
  },
  friendRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: scale(5),
    marginBottom: scale(10),
  },
  friendsPickerList: {
    maxHeight: Dimensions.get('screen').height / 3,
  },
  friendRowLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  friendRowLeftText: {
    ...isTextRTLStyles,
  },
  checkboxWrapper: {
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    width: scale(15),
    height: scale(15),
    alignItems: 'center',
    justifyContent: 'center',
    ...horizontalRTLFlip,
  },
  invitesIndicator: {
    color: Colors.lightGrey,
    fontSize: scale(8),
    marginVertical: scale(10),
    textAlign: 'center',
    ...isTextRTLStyles,
  },
});
