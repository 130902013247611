// version: 4 - Was the first version of app.
// version: 5 - Tax related version - outside of Israel Payments.
// version: 6 - Added Israel payment system on app.
// version: 7 - Support 4
// version: 8 - Add yearly summary feed
// version: 9 - Change your activity from 30 days to monthly



const ConfigDefaults = {
    version: 9,
};

export default ConfigDefaults;