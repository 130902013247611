import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoginScreen from '../../screens/LoginScreen';
import { authenticateUser } from '../../actions/auth';

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      authenticateUser,
    },
    dispatch,
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(LoginScreen);
