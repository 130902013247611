import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import forgotPasswordReducer from '../containers/ForgotPasswordContainer/reducer';
import friendReducer from '../reducers/friend';
import modalReducer from '../modals/reducer';
import homeReducer from '../reducers/home';
import boxesReducer from "../reducers/boxes";
import membershipsReducer from "../reducers/memberships";
import userReducer from "../reducers/user";
import scheduleReducer from "../reducers/schedule";
import pictureReducer from "../reducers/picture";
import authReducer from "../reducers/auth";
import notificationsReducer from "../reducers/notifications";
import connectionsReducer from "../reducers/connections";
import whiteLabelPropertiesReducer from "../reducers/whiteLabelProperties";
import logbookReducer from "../reducers/logbook";
import shopReducer from "../reducers/shop";
import minisiteReducer from "../reducers/minisite";



export default combineReducers({
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    form: formReducer,
    picture: pictureReducer,
    friend: friendReducer,
    schedule: scheduleReducer,
    modal: modalReducer,
    home: homeReducer,
    boxes: boxesReducer,
    memberships: membershipsReducer,
    user: userReducer,
    connections: connectionsReducer,
    notifications: notificationsReducer,
    whiteLabelProperties: whiteLabelPropertiesReducer,
    logbook: logbookReducer,
    shop: shopReducer,
    minisite: minisiteReducer,
});

