import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Icons8ForwardArrow(props) {
  return (
      <Svg viewBox="0 0 50 50" fill="#000000" {...props}>
          <Path d="M 28.875 11 C 28.371094 11.0625 27.996094 11.492188 28 12 L 28 19.0625 C 16.488281 19.414063 10.28125 25.507813 7.21875 31.5625 C 4.113281 37.699219 4.003906 43.683594 4 43.875 C 4 43.875 4 43.9375 4 43.9375 L 4 44 C 3.984375 44.550781 4.417969 45.015625 4.96875 45.03125 C 5.519531 45.046875 5.984375 44.613281 6 44.0625 C 6 44.0625 6 44 6 44 C 6.007813 43.90625 6.230469 40.878906 9.09375 37.65625 C 11.90625 34.496094 17.394531 31.308594 28 31.09375 L 28 38 C 28 38.382813 28.21875 38.734375 28.566406 38.902344 C 28.914063 39.070313 29.324219 39.023438 29.625 38.78125 L 45.625 25.78125 C 45.863281 25.589844 46 25.304688 46 25 C 46 24.695313 45.863281 24.410156 45.625 24.21875 L 29.625 11.21875 C 29.414063 11.046875 29.144531 10.96875 28.875 11 Z M 30 14.09375 L 43.40625 25 L 30 35.90625 L 30 30 C 30 29.449219 29.550781 29 29 29 C 17.246094 29 10.910156 32.613281 7.59375 36.34375 C 7.46875 36.484375 7.398438 36.609375 7.28125 36.75 C 7.703125 35.375 8.257813 33.90625 9 32.4375 C 11.917969 26.671875 17.488281 21 29 21 C 29.550781 21 30 20.550781 30 20 Z"/>
      </Svg>
  );
}

export default Icons8ForwardArrow;
