import React from 'react';
import {globalStyles} from "../../../styles/Global";
import { View } from 'react-native';
import {verticalScale,scale} from 'react-native-size-matters';
import {Colors} from "../../../styles/Colors";
import {AppText} from "../AppText";
import {HUGE_ICON_SIZE} from "../../../helpers/functions";
import {Icons8Generator} from "../Icons8Generator";
import ModalButton from "../../ModalButton";


export const DrawerDeleteLayout = props => {

    const {onRemoveButton,onCancelButton,headerText,infoText,removeButtonText,cancelButtonText,submitting} = props;
    return (
        <View style={[globalStyles.flexDirectionColumn, globalStyles.centerItems]}>
            <Icons8Generator name={'trash-can'} fill={Colors.red} width={HUGE_ICON_SIZE}
                             height={HUGE_ICON_SIZE}/>
            <AppText style={[globalStyles.heeboMedium,globalStyles.marginTopBetweenElements]}>{headerText}</AppText>
            <AppText style={[globalStyles.heeboRegular,globalStyles.marginTopMedium]}>{infoText}</AppText>
            <View  styles={globalStyles.marginTopBetweenElements}>
                <ModalButton
                    onPress={onRemoveButton}
                    type='primary'
                    colors={Colors.red}
                    text={removeButtonText}
                    overrideStyle={{width: scale(240),minHeight:verticalScale(30)}}
                    textColor={Colors.white}
                    submitting={submitting}
                />
                <ModalButton
                    onPress={onCancelButton}
                    type='secondary'
                    overrideStyle={{marginTop: verticalScale(10)}}
                    colors={Colors.transparent}
                    text={cancelButtonText}
                    textColor={Colors.red}
                />
            </View>
        </View>
    )
};



