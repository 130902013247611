import {
    LOGIN,
    LOGOUT,
    PATCH_AUTH_DETAILS,
    CHANGE_LANGUAGE,
    DEVICE_TOKEN,
    HAS_SHOP,
    LOGOUT_BOX_USER, LOGIN_BOX_USER
} from "../constants";
import {apiAction} from "../helpers/HTTP";
import {isWeb} from "../helpers/functions";


export const logoutUser = () => {
    return async dispatch => {
        const actionType = LOGOUT.toUpperCase();
        const actionTypeSite = LOGOUT_BOX_USER.toUpperCase();
        try {
            dispatch({type: `${actionType}_PENDING`});
            const response = await apiAction('user/logout', 'post', null);
            dispatch({type: `${actionType}_SUCCESS`, payload: response});
            if(isWeb()) dispatch({type: `${actionTypeSite}_SUCCESS`, payload: response});
            return response;
        } catch (error) {
            dispatch({type: `${actionType}_FAILED`, payload: error});
            if(isWeb()) dispatch({type: `${actionTypeSite}_FAILED`, payload: error});
            return error;
        }
    };
};
export const logoutUserSuccess = () => {
   return async dispatch => {
       const actionType = LOGOUT.toUpperCase();
       const actionTypeSite = LOGOUT_BOX_USER.toUpperCase();
       dispatch({type: `${actionType}_SUCCESS`, payload: true});
       if(isWeb()) dispatch({type: `${actionTypeSite}_SUCCESS`, payload: true});
   }
};

export const authenticateUser = (email, password) => {
    return async dispatch => {
        const actionType = LOGIN.toUpperCase();
        const actionTypeSite = LOGIN_BOX_USER.toUpperCase();
        try {
            dispatch({type: `${actionType}_PENDING`});
            if(isWeb()) dispatch({type: `${actionTypeSite}_PENDING`});
            const response = await apiAction('user/login', 'post', {email, password});
            dispatch({type: `${actionType}_SUCCESS`, payload: response});
            if(isWeb()) dispatch({type: `${actionTypeSite}_SUCCESS`, payload: response});
            return response;
        } catch (error) {
            dispatch({type: `${actionType}_FAILED`, payload: error});
            if(isWeb()) dispatch({type: `${actionTypeSite}_FAILED`, payload: error});
            return error;
        }
    };
};

export const patchUserDetails = (data) => {
    return async dispatch => {
        const actionType = PATCH_AUTH_DETAILS.toUpperCase();
        return dispatch({type: `${actionType}_SUCCESS`, payload: data});
    };
};

export const deviceToken = (token) => {
    return async dispatch => {
        const actionType = DEVICE_TOKEN.toUpperCase();
        return dispatch({type: `${actionType}_SUCCESS`, payload: token});
    };
};

export const hasStore = (value) => {
    return async dispatch => {
        const actionType = HAS_SHOP.toUpperCase();
        return dispatch({type: `${actionType}_SUCCESS`, payload: value});
    };
};
