import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import CheckBox from './CheckBox';
import {t} from "../../services/i18n";
import {isMobile, isWeb} from "../../helpers/functions";

class MembershipList extends React.Component {
  renderList() {
    const { checkedIndex, list } = this.props;
    if (list && list.length !== 0) {
      return list.map((item, index) => {
        let isChecked = false;
        if (checkedIndex === index) {
          isChecked = true;
        }
        return (
          <CheckBox
            index={index}
            id={item.id}
            data={item}
            label={item.membership_types ? item.membership_types.name : t('screens:ClubProfile:membershipStaffMember',{})}
            active={item.active}
            checked={isChecked}
            check={() => this.props.checkMembership(index, item.id)}
          />
        );
      });
    }
    return <ActivityIndicator />;
  }

  render() {
    return <View style={[{width: '100%'}, isWeb() && {paddingHorizontal: '2em', paddingVertical: this.props.mobile ? 0 : '0.6em'}]}>{this.renderList()}</View>;
  }
}

export default MembershipList;
