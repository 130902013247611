// @flow
import * as React from 'react';
import {useEffect, useState} from "react";
import ProductExtendedCard from "./ProductExtendedCard";
import MembershipExtendedCard from "./MembershipExtendedCard";
import {useDispatch} from "react-redux";
import {addToCart} from "../../../../actions/shop"
import Spinner from "react-native-loading-spinner-overlay";
import EStyleSheet from "react-native-extended-stylesheet";
import PopupModal from "../../Common/PopupModal";
import {Platform} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {isMobile} from "../../../../helpers/functions";
import Toast from "react-native-root-toast";
import {t} from "../../../../services/i18n";

const ExtendedCardHandler = (props) => {
    const {show, setClose, item, dollars, cents, isFromRegister, isPopup} = props;
    const [component, setComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const mobile = isMobile();

    const handleAddToCart = async () => {
        setIsLoading(true)
        await dispatch(addToCart(item))
        setIsLoading(false)
        if(setClose) {
            setClose()
        } else {
            Toast.show(t('screens:Purchase:itemAddedToCart',{}), {
                duration: Toast.durations.SHORT,
                position: Toast.positions.TOP,
                animation: true,
                hideOnPress: true,
                backgroundColor: Colors.brandedGreen
            })
        }
    }

    useEffect(() => {
        let Comp = null;
        if(item.type === 'plan' || item.type === 'session') {
            Comp = <MembershipExtendedCard item={item} dollars={dollars} cents={cents} show={show} setClose={setClose} addToCart={handleAddToCart} isFromRegister={isFromRegister} isPopup={isPopup}/>
        } else if(item.type === 'item') {
            Comp = <ProductExtendedCard item={item} dollars={dollars} cents={cents} show={show} setClose={setClose} addToCart={handleAddToCart}/>
        }
        setComponent(Comp);
    }, []);

    return (
        <>
            <Spinner visible={isLoading}/>
            {
                !isPopup ?
                    <>{component}</>
                    :
                    <PopupModal show={show} setClose={setClose} overrideStyles={EStyleSheet.flatten([styles.modal, {height: mobile ? '100%' : 'fit-content'}])}>
                        {component}
                    </PopupModal>
            }
        </>
    );
};

export default ExtendedCardHandler;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: 0,
                paddingVertical: 0,
                width: 'fit-content',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
        }
    })
})