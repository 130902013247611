// @flow
import * as React from 'react';
import {Platform, StyleSheet, View} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import {t} from "../../../../services/i18n";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {HUGE_ICON_SIZE, ICON_SIZE_85, isMobile} from "../../../../helpers/functions";
import EStyleSheet from "react-native-extended-stylesheet";

const EmptyState = (props) => {
    const {isRestricted, date, time} = props;
    const mobile = isMobile();

    return (
        <View style={styles.wrapper}>
            <AppText style={[styles.text, mobile && styles.textMobile]}>{t(isRestricted ? "general:spoilerAlert" : "general:siteNoClasses", {})}</AppText>
            <Icons8Generator name={isRestricted ? 'time' : 'couch'} size={mobile ? ICON_SIZE_85 : HUGE_ICON_SIZE} fill={Colors.iconEmptyStateColor} style={{marginBottom: '1em'}}/>
            <AppText style={[styles.text, mobile && styles.textMobile]}>{t(isRestricted ? "general:siteScheduleRevealMsg" : "general:dayOff", {date: date, time:time})}</AppText>
        </View>
    );
};
export default EmptyState;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                alignItems: 'center',
                gap: '1em',
                width: '80%',
                textAlign: 'center'

            },
            text: {
                color: Colors.siteScheduleDay,
                fontSize: '0.8rem',
            },
            textMobile: {
                fontSize: '1rem',
            }
        }
    })
})