import GenericSuccessModal from "../Common/GenericSuccessModal";
import * as React from "react";
import {View} from "react-native-web";
import PaymentHandlerContainer from "../Shop/Payment/PaymentHandler";
import CartSummary from "../Shop/Cart/CartSummary";
import EStyleSheet from "react-native-extended-stylesheet";
import CartDisplay from "../Shop/Cart/CartDisplay";
import {globalStyles} from "../../../styles/Global";
import PersonalDetails from "../Registration/PersonalDetails";
import {Platform} from "react-native";
import {Colors} from "../../../styles/Colors";
import {produce} from 'immer'
import {t} from "../../../services/i18n";
import {checkPurchaseForGroup, createCartDetailsInfo, isMobile} from "../../../helpers/functions";
import moment from "moment";
import PlanSelectionStep from "../Registration/FullProcess/PlanSelectionStep";
import FormHandler from "../Forms/FormHandler";
import ParticipantDetails from "../Registration/ParticipantDetails";
import GroupParticipantOptions from "../Workshop/GroupParticipantOptions";

export const optionalSteps = {
    MEMBERSHIP_SELECT: 'MEMBERSHIP_SELECT',
    PERSONAL_DETAILS: 'PERSONAL_DETAILS',
    PARTICIPANT_DETAILS: 'PARTICIPANT_DETAILS',
    CART_DISPLAY: 'CART_DISPLAY',
    GROUP_CONNECTION: 'GROUP_CONNECTION',
    DIGITAL_FORM: 'DIGITAL_FORM',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    SUCCESS_PAGE: 'SUCCESS_PAGE',
}

export const generateProgressBarObj = (info) => {
    const isGroupPurchase = checkPurchaseForGroup()
    return {
        MEMBERSHIP_SELECT: {
            label: t('screens:Register:select-your-plan',{}),
            subheaders: createCartDetailsInfo()
        },
        PERSONAL_DETAILS: {
            label: isGroupPurchase ? t('screens:workshop:parent-details',{}) : t('screens:Register:personal-details',{}),
            subheaders: info.personalDetails ? [`${info.personalDetails.firstName} ${info.personalDetails.lastName}, ${moment().diff(info.personalDetails.birthDate, 'years')}`, info.personalDetails.email] : []
        },
        PARTICIPANT_DETAILS: {
            label: t('screens:Register:participant-details',{}),
            subheaders: info.participantDetails ? [`${info.participantDetails.firstName} ${info.participantDetails.lastName}, ${info.participantDetails.id}`] : []
        },
        DIGITAL_FORM: {
            label: t('screens:Register:agreement',{}),
            subheaders: []
        },
        PAYMENT_METHOD: {
            label: t('screens:Register:payment',{}),
            subheaders: []
        },
        SUCCESS_PAGE: null
    }
}

const initialParams = {
    actions: {},
    stylingOverrides: {},
    toggles: {},
    data: {}
}


export const stepRendering = {
    MEMBERSHIP_SELECT: (props) => renderMembershipSelect(props),
    PERSONAL_DETAILS: (props) => renderPersonalDetailsForm(props),
    PARTICIPANT_DETAILS: (props) => renderParticipantDetailsForm(props),
    CART_DISPLAY: (props) => renderCartItems(props),
    GROUP_CONNECTION: (props) => renderGroupConnections(props),
    DIGITAL_FORM: (props) => renderDigitalForm(props),
    PAYMENT_METHOD: (props) => renderPaymentProcess(props),
    SUCCESS_PAGE: (props) => renderSuccessPage(props)
}

const renderMembershipSelect = (props) => {
    return <PlanSelectionStep/>
}

const renderPersonalDetailsForm = (props) => {
    const styles = getStyle(props.toggles.mobile);
    const defaultParams = produce(initialParams, draft => {
        draft.stylingOverrides.wrapperStyles = [globalStyles.cardsShadow, styles.personalDetailsWrapper],
        draft.toggles.isFromFullProcess = true,
        draft.toggles.showAdditionalPhoneField = false,
        draft.data.details = null
    })
    const params = handleParams(defaultParams, props)
    return (
        <View style={EStyleSheet.flatten(params.stylingOverrides.wrapperStyles)}>
            <PersonalDetails isFromFullProcess={params.toggles.isFromFullProcess} onBack={params.actions.onBack} onSubmit={params.actions.onSubmit} onLogin={params.actions.onLogin} onResetPassword={params.actions.onResetPassword} details={params.data.details} showAdditionalPhoneField={params.toggles.showAdditionalPhoneField}/>
        </View>
    )
}

const renderParticipantDetailsForm = (props) => {
    const styles = getStyle(props.toggles.mobile);
    const defaultParams = produce(initialParams, draft => {
            draft.stylingOverrides.wrapperStyles = [globalStyles.cardsShadow, styles.personalDetailsWrapper],
                draft.data.details = null
        })
    const params = handleParams(defaultParams, props)
    return (
        <View style={EStyleSheet.flatten(params.stylingOverrides.wrapperStyles)}>
            <ParticipantDetails onBack={params.actions.onBack} onSubmit={params.actions.onSubmit} details={params.data.details}/>
        </View>
    )
}

const renderCartItems = (props) => {
    const defaultParams = produce(initialParams, draft => {
        draft.actions.goToCheckout = null
    })
    const params = handleParams(defaultParams, props)
    return <CartDisplay goToCheckout={params.actions.goToCheckout}/>
}

const renderGroupConnections = (props) => {
    const styles = getStyle(props.toggles.mobile);
    const defaultParams = produce(initialParams, draft => {
        draft.stylingOverrides.wrapperStyles = styles.participantWrapper
    })
    const params = handleParams(defaultParams, props)
    return (
        <View style={EStyleSheet.flatten(params.stylingOverrides.wrapperStyles)}>
            <GroupParticipantOptions connections={params.data.connections} onConnectionClicked={params.actions.onConnectionClicked} onNewUserClicked={params.actions.onNewUserClicked}/>
        </View>
    )
}

const renderDigitalForm = (props) => {
    const defaultParams = produce(initialParams, draft => {
    })
    const params = handleParams(defaultParams, props)
    return (
        <View style={EStyleSheet.flatten(params.stylingOverrides.wrapperStyles)}>
            <FormHandler onLater={params.actions.onLater} submitReplacement={params.actions.submitReplacement} onBack={params.actions.onBack} returnOnNoForm={params.actions.returnOnNoForm}/>
        </View>
    )
}

const renderPaymentProcess = (props) => {
    const styles = getStyle();
    const defaultParams = produce(initialParams, draft => {
        draft.toggles = {
            showCartSummary: true,
            isInPaymentProcess: true,
            signUpDetails: null,
            finishProcessBeforeToken: false,
            groupInfo: null
        },
        draft.stylingOverrides = {
            paymentStyles: [styles.paymentStyle],
            wrapperStyles: []
        }
        draft.actions = {
            handleCreateUser: null,
            goToCheckout: null
        }
    })
    const params = handleParams(defaultParams, props)
    return (
        <>
            <View style={EStyleSheet.flatten(params.stylingOverrides.wrapperStyles)}>
                <PaymentHandlerContainer contentOverrideStyle={EStyleSheet.flatten(params.stylingOverrides.paymentStyles)}
                                         show={true}
                                         setClose={() => console.log("someone closed payment handler")}
                                         onBack={params.actions.onBack}
                                         isPopup={false}
                                         onProcessSuccess={params.actions.onSuccess}
                                         handleCreateUser={params.actions.handleCreateUser}
                                         signUpDetails={params.toggles.signUpDetails}
                                         finishProcessBeforeToken={params.toggles.finishProcessBeforeToken}
                                         groupInfo={params.toggles.groupInfo}
                />
            </View>
            {params.toggles.showCartSummary &&
            <View style={[{marginTop: '2em'}]}><CartSummary goToCheckout={params.actions.goToCheckout} isInPaymentProcess={params.toggles.isInPaymentProcess}/></View>}
        </>
    )
}

const renderSuccessPage = (props) => {
    const defaultParams = {
        title: t('modals:Buy:purchase-completed', {}),
        subHeader: '',
        iconName: 'shopaholic',
        onClose: null,
        isPopup: false
    }
    const params = {...defaultParams, ...props}
    return  (
        <GenericSuccessModal show={true}
                             iconName={params.iconName}
                             setClose={params.onClose}
                             title={params.title}
                             subHeader={params.subHeader}
                             isPopup={params.isPopup} />
    )
}

const handleParams = (defaultParams, overrides) => {
    return produce(defaultParams, draft => {
        draft.toggles = { ...defaultParams.toggles, ...overrides.toggles },
        draft.stylingOverrides = { ...defaultParams.stylingOverrides, ...overrides.stylingOverrides },
        draft.actions = { ...defaultParams.actions, ...overrides.actions }
        draft.data = { ...defaultParams.data, ...overrides.data }
    })
}

let getStyle = function (mobile) {
    return EStyleSheet.create({
        ...Platform.select({
            web: {
                paymentStyle: {
                    paddingHorizontal: 0
                },
                personalDetailsWrapper: {
                    width: mobile ? '100%' : '28em',
                    marginHorizontal: 'auto',
                    backgroundColor: Colors.white,
                    paddingHorizontal: '2em',
                    borderRadius: 3,
                    marginTop: mobile ? '1em' : 0,
                    height: mobile ? `100% - ${EStyleSheet.value('$progressBarMobile') + EStyleSheet.value('$mobileHeader')}` : 'unset',
                    overflow: 'auto'
                },
                participantWrapper: {
                    width: mobile ? '100%' : '25em'
                }
            }
        })
    })
}
