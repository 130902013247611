import React, {useRef, useEffect, useState} from 'react';
import {Text, View, StyleSheet, Platform} from 'react-native';
import {Icon} from "native-base";
import {Colors} from "../../styles/Colors";
import {isTextRTLStyles} from "../../styles/Layout";
import {globalStyles} from "../../styles/Global";
import {AppText} from "../Stateless/AppText";
import {t} from "../../services/i18n";
import {scale} from "react-native-size-matters";
import ModalButton from "../ModalButton";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {BIG_ICON_SIZE, HUGE_ICON_SIZE, isMobile, isWeb, LARGE_ICON_SIZE} from "../../helpers/functions";
import DrawerHandler from "../DrawerHandler";
import PopupModal from "../Web/Common/PopupModal";
import EStyleSheet from "react-native-extended-stylesheet";



export const ErrorHandler = (props) => {
    const {setClose} = props;
    const [show, setShow] = useState(false);
    let RBSheet = useRef(null);
    const isDesktopSite = isWeb() && !isMobile()

    useEffect(() => {
        if(isDesktopSite) {
            setShow(true)
        } else {
            RBSheet?.open();
        }
    },[props.errors])

    const onClose = () => {
        setShow(false)
        setClose()
    }

   return (
       <> {
           isDesktopSite ?
               <PopupModal show={show} setClose={onClose} overrideStyles={[styles.modal]}>
                   <View
                       style={[globalStyles.flexDirectionColumn, globalStyles.centerItems]}>
                       <Icons8Generator name={'error'} fill={Colors.red} width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                       <AppText style={[globalStyles.heeboMedium, {
                           marginTop: '1em',
                           color: Colors.red,
                           fontSize: '1.3rem'
                       }]}>{t('common:drawer:booking:error', {})}
                       </AppText>
                   </View>
                   <View style={[styles.errorsWrapper]}>
                       {handleError(props.errors, isDesktopSite)}
                   </View>
               </PopupModal>
               :
               <DrawerHandler noMargin={false}
                              title={props.title ? props.title : undefined}
                              closeOnPressMask={true}
                              ref={el => RBSheet = el ? el.ref.current : null}>
                   <View>
                       <View
                           style={[globalStyles.flexDirectionColumn, globalStyles.marginTopBetweenElements, globalStyles.centerItems]}>
                           <Icons8Generator name={'error'} fill={Colors.red} width={HUGE_ICON_SIZE} height={HUGE_ICON_SIZE}/>
                           <AppText style={[globalStyles.heeboMedium, globalStyles.marginTopBetweenTextElements, {
                               color: Colors.red,
                               fontSize: scale(18)
                           }]}>{t('common:drawer:booking:error', {})}
                           </AppText>
                       </View>
                       <View style={globalStyles.marginTopBetweenTextElements}>
                           {handleError(props.errors)}
                           <ModalButton
                               style={globalStyles.marginTopBetweenTextElements}
                               onPress={() => RBSheet.close()}
                               type='primary'
                               colors={Colors.red}
                               text={t('modals:Card:got-it', {})}
                               textColor={Colors.white}
                           />
                       </View>
                   </View>
               </DrawerHandler>
       }
       </>

   )


}

const handleError = (errors, isDesktopSite = false) => {
    if(errors.length === 1){
        return (
            <View style={[globalStyles.flexDirectionRow, {...globalStyles.centerItems}]}>
                <AppText
                    style={[!isDesktopSite && globalStyles.heeboRegular, isTextRTLStyles, isDesktopSite && styles.errorText]}>{errors[0]}
                </AppText>
            </View>
        )
    }else{
        return (
            errors.map((message, i) => {
                return (
                    <View key={i}
                          style={[globalStyles.flexDirectionRow, i !== 0 ? globalStyles.marginTopSm : {}]}>
                        <AppText
                            style={[isTextRTLStyles, !isDesktopSite && globalStyles.heeboRegular, isDesktopSite && styles.errorText]}>{i + 1}. {message}</AppText>
                    </View>
                );
            })
        );
    }
}


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                width: '25em',
                paddingVertical: '2em',
                paddingHorizontal: '2em'
            },
            errorsWrapper: {
                marginTop: '1em',
            },
            errorText: {
                fontSize: '0.95rem'
            }
        }
    })
})