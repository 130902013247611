import React from 'react';
import {useSelector} from "react-redux";
import {View} from "react-native-web";
import {t} from "../../../services/i18n";
import {Colors} from "../../../styles/Colors";
import ModalButton from "../../ModalButton";
import {AppText} from "../../Stateless/AppText";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {isMobile} from "../../../helpers/functions";

const MyComponent = (props) => {
    const {onAgree} = props;
    const selectedLocation = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation);
    const mobile = isMobile();

    return (
        <View style={[styles.wrapper]}>
            <View style={[styles.contentWrapper]}>
                <View style={[styles.headerWrapper]}><AppText style={[styles.header]}>{t('modals:CancelClass:cancellation-policy',{})}</AppText></View>
                <AppText style={[styles.content]}>{selectedLocation.cancellation_policy}</AppText>
            </View>
            {onAgree && <View style={[styles.btnWrapper]}>
                <ModalButton
                    onPress={onAgree}
                    text={t('screens:Register:agree', {})}
                    type='primary'
                    colors={Colors.lightBlack}
                    textColor={Colors.white}
                    overrideStyle={[styles.btn, mobile && styles.mobileBtn]}
                    textStyles={{'fontFamily': 'heebo'}}
                />
            </View>
            }
        </View>
    );
};

export default MyComponent;


const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                height: '100%',
                justifyContent: 'space-between',
            },
            headerWrapper: {
                justifyContent: 'center',
                alignItems: 'center',
                borderBottomWidth: 2,
                borderBottomColor: Colors.progressBar,
                paddingBottom: '1em'
            },
            header: {
                fontSize: '1.2rem',
                textAlign: 'center',
            },
            content: {
                fontSize: '1rem',
                paddingVertical: '0.95rem'
            },
            btn: {
                borderRadius: 3,
                width: '7em',
                minHeight: 'auto',
                paddingVertical: '0.4em',
                marginTop: 0,
            },
            mobileBtn: {
                width: '100%'
            }
        }
    })
})