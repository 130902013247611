import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChangePasswordScreen from '../../../../screens/Settings/ChangePasswordScreen';

const mapActionsToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ChangePasswordScreen);
