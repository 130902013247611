import React from 'react';
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';
import {ScaledSheet, scale, verticalScale} from 'react-native-size-matters';
import {globalStyles} from "../../styles/Global";
import RBSheet from "react-native-raw-bottom-sheet";
import {Colors} from "../../styles/Colors";
import {t} from '../../services/i18n';
import {DrawerHeader} from "../Stateless/DrawerHeader";
import {DrawerBar} from "../Stateless/DrawerBar";

class DrawerHandler extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    render() {
        const defaultCustomContainer = this.props.overrideCustom ? this.props.overrideCustom : {...globalStyles.drawerBorderTop,
            ...globalStyles.appPaddingHorizontal,
            maxHeight: verticalScale(550),
            height: 'auto'}
        return (
            <RBSheet
                ref={this.ref}
                openDuration={250}
                closeDuration={200}
                animationType={this.props.animationType ? this.props.animationType : 'slide'}
                closeOnDragDown={false}
                onClose={this.props.onClose}
                closeOnPressMask={this.props.closeOnPressMask}
                customStyles={{
                    container:defaultCustomContainer,
                    wrapper: {
                        backgroundColor: 'rgba(0,0,0,0.25)',
                    },
                    draggableIcon: {
                        backgroundColor: Colors.graySeparator,
                        height: verticalScale(4),
                        width: scale(40)
                    }
                }}>
                <View>
                    <DrawerBar/>
                    {this.props.title ? <DrawerHeader
                                                      title={this.props.title}
                                                      noCloseBtn={this.props.noCloseBtn}
                                                      closeText={t('common:drawer:close', {})}
                                                      closeDrawer={() => {this.ref.current.close(); this.props.onClose?.()}}/> : null}
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={[this.props.noMargin ? {} : styles.scrollViewMargin]}>
                        <TouchableOpacity activeOpacity={1}>
                            {this.props.children}
                            <View style={{height: verticalScale(20)}}/>
                        </TouchableOpacity>
                    </ScrollView>
                </View>
            </RBSheet>
        )
    }
}

const styles = ScaledSheet.create({
    scrollViewMargin: {
        paddingBottom: verticalScale(30)
    },
    bottomSheetContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.black
    },
});

export default DrawerHandler;

