import { StyleSheet, Platform } from 'react-native';
import { Colors } from '../../styles/Colors';
import { scale } from 'react-native-size-matters';
import { horizontalRTLFlip } from '../../styles/Layout';

export default StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    width: scale(15),
    height: scale(15),
    borderWidth: 1,
    borderColor: Colors.modalFontColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkmark: {
    fontSize: scale(10),
    color: Colors.white,
    // ...horizontalRTLFlip
  },
  addToCalendarText: {
    marginLeft: scale(5),
    fontSize: scale(12),
    fontFamily: 'heebo',
  },
  checked: {
    backgroundColor: Colors.brandSuccess,
  },
});
