import {scale,verticalScale, ScaledSheet} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import {globalStyles} from "../../styles/Global";

export default ScaledSheet.create({
    v2IndicatorWrap: {paddingHorizontal:scale(10),borderRadius:100},
    v2IndicatorText: {...globalStyles.heeboMedium,fontSize:scale(11),color:Colors.white},
    text:{...globalStyles.heeboLight,color:Colors.black,fontSize:scale(10)},
    moreContainer:{borderRadius: 100, width: scale(16),  height: scale(16),backgroundColor:Colors.blue}
});
