// @flow
import * as React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import {AppText} from "../../Stateless/AppText";
import {Colors} from "../../../styles/Colors";
import {useEffect, useState} from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import {useSelector} from "react-redux";
import {isMobile} from "../../../helpers/functions";
import {Icons8Generator} from "../../Stateless/Icons8Generator";

export const Tab = (props) => {
    const {name, children} = props;

    return (
        <>
            {children}
        </>
    )
}

const CustomTabs = (props) => {
    const {children, backgroundColor, wrapperStyle, selectedTabName, onTabChanged} = props;
    const [tabNames, setTabNames] = useState([]);
    const [tabsContent, setTabsContent] = useState({});
    const [selectedTab, setSelectedTab] = useState(null);
    const headerConfig = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.headerConfig)
    const primaryColor = useSelector(state => state.whiteLabelProperties.primaryColor);
    const mobile = isMobile();

    useEffect(() => {
        let headers = [];
        let content = {};
        React.Children.forEach(children, (element) => {
            if(React.isValidElement(element)) {
                const {name, iconName} = element.props
                headers.push({name, iconName});
                content[name] = element.props.children;
            }
        })
        setTabNames(headers);
        setSelectedTab(selectedTabName || headers[0].name);
        setTabsContent({ ...content });
    }, [children]);

    const changeTab = (name) => {
        setSelectedTab(name);
        if(onTabChanged) {
            onTabChanged(name);
        }
    };

    return  (
        <>
            <View
                style={[styles().wrapper, mobile && {backgroundColor: backgroundColor ? backgroundColor : Colors.white}, !headerConfig?.showTabs ? {maxHeight: 0} : null, !mobile && styles().webTabsWrapper, wrapperStyle]}>
                {
                    tabNames.map(nameObj =>
                        <TouchableOpacity onPress={() => changeTab(nameObj.name)}
                                          style={[styles().tabWrapper,
                                              !mobile && styles().notSelected,
                                              mobile && {flex: 1},
                                              (selectedTab === nameObj.name) ? (mobile ? styles().selected :  styles(primaryColor).selectedWeb) : null,
                                              {width: `calc(100% / ${tabNames.length})`},
                                              nameObj.iconName && {flexDirection: 'row', gap: '1em'}
                                          ]}
                                          key={`custom-tab-${nameObj.name}`}>
                            {nameObj.iconName && <Icons8Generator name={nameObj.iconName} size={'1em'} fill={Colors.lightBlack}/>}
                            <AppText style={[styles().text, !mobile && styles().webText, !mobile && selectedTab === nameObj.name && styles(primaryColor).webTextSelected]} boldFont={selectedTab === nameObj.name}>{nameObj.name}</AppText>
                        </TouchableOpacity>
                    )
                }
            </View>
            <View>
                {
                    Object.keys(tabsContent).map(tab => selectedTab === tab ? <View key={`mobile-tab-value${tab}`}>{tabsContent[tab]}</View> : null)
                }
            </View>
        </>
    )
};

CustomTabs.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName];

        let error = null;
        React.Children.forEach(prop, function (child) {
            if (child.type !== Tab) {
                error = new Error(
                    "`" + componentName + "` children should be of type `TabPane`."
                );
            }
        });
        return error;
    }
};

export default CustomTabs;

const styles = (mobile, primaryColor) => EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                marginTop: -1,
                maxHeight: 50,
                alignItems: 'center',
            },
            tabWrapper: {
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingVertical: '0.5em',
            },
            text: {
                color: Colors.lightBlack,
                fontSize: '0.8rem',
                textAlign: 'center',
                fontWeight: 'bold'
            },
            webText: {
                color: Colors.lightBlack,
                fontSize: '0.85rem',
                paddingHorizontal:5
            },
            webTextSelected: {
                color: Colors.lightBlack,
                paddingHorizontal:5
            },
            selected: {
                borderBottomColor: Colors.lightBlack,
                borderBottomWidth: 3,
                paddingHorizontal:5
            },
            notSelected: {
                borderBottomColor: Colors.tabSeparator,
                borderBottomWidth: 2,
                paddingVertical: 0,
                height: 30
            },
            selectedWeb: {
                borderBottomColor: Colors.lightBlack,
                borderBottomWidth: 3,
            }
        }
    })
})