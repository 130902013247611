import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FriendProfileScreen from '../../screens/FriendProfileScreen';
import { fetchFriendProfile,friendRelationAction } from '../../actions/friend';

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      fetchFriendProfile,
      friendRelationAction,
    },
    dispatch
  );
};

const mapStateToProps = state => ({
  friend: state.friend
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(FriendProfileScreen);
