import * as React from "react"
import Svg, { Path,Circle } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgCoronaArbox(props) {
    return (
        <Svg viewBox="0 0 756.9 1000" {...props}>
            <Path
                d="M432 988.3c-75.2 0-144.8-32.5-196.1-91.6-6.9-8-13.9-16.5-20.6-25.3a62.73 62.73 0 01-12.7-38V617.7a12.18 12.18 0 0112.2-12.2 12.18 12.18 0 0112.2 12.2v215.7a38.06 38.06 0 007.7 23.2c6.5 8.4 13.1 16.5 19.7 24.1 46.6 53.6 109.8 83.2 177.8 83.2s131.1-29.5 177.8-83.2c6.6-7.6 13.2-15.7 19.7-24.1a37.85 37.85 0 007.7-23.2V621.2a12.2 12.2 0 1124.4 0v212.2a62.3 62.3 0 01-12.7 38c-6.8 8.8-13.7 17.3-20.6 25.3-51.7 59.1-121.3 91.6-196.5 91.6z"
                fill="#0554a4"
            />
            <Path
                d="M433.7 724.6a238.28 238.28 0 01-38.6-3.1c-3.2-.5-6-1.1-8.8-1.6l-.7-.1a343.89 343.89 0 01-171.3-87.2 74.61 74.61 0 01-12.7-15.9l-63.2-105.2a12.18 12.18 0 1120.9-12.5l63.2 105.2a51.36 51.36 0 008.5 10.7 320.05 320.05 0 00159.2 81l.7.1c2.6.5 5.2 1 7.8 1.4a218.57 218.57 0 0069.9.1c3-.5 5.6-1 8.2-1.5l.7-.1a320.46 320.46 0 00159.2-81 51.36 51.36 0 008.5-10.7L708.3 499a12.18 12.18 0 0120.9 12.5L666 616.7a71.63 71.63 0 01-12.7 15.9A344.7 344.7 0 01482 719.8l-.7.1c-2.8.5-5.6 1.1-8.5 1.5a235.25 235.25 0 01-39.1 3.2zM645.9 864.7a12 12 0 01-7.4-2.5 12.22 12.22 0 01-2.2-17.1c.4-.5 29.4-39.2 58.3-131.4a12.21 12.21 0 1123.3 7.3c-30.4 97-60.9 137.3-62.2 138.9a12.3 12.3 0 01-9.8 4.8zM218.3 864.7a12.06 12.06 0 01-10.8-6.6c-.3-.6-30.5-60-60.7-136.2a12.16 12.16 0 0122.6-9c29.8 75.2 59.5 133.5 59.8 134.1a12.16 12.16 0 01-5.3 16.4 13 13 0 01-5.6 1.3z"
                fill="#0554a4"
            />
            <Circle cx={537.8} cy={564} r={16.5} fill="#0554a4" />
            <Circle cx={328.6} cy={564} r={16.5} fill="#0554a4" />
            <Path
                d="M613.4 537.1a10.31 10.31 0 01-8.6-4.7c-31.5-49.7-115.6-26.3-116.4-26a10.1 10.1 0 01-5.6-19.4 196.17 196.17 0 0153.4-6.5c39.5.7 69.1 14.9 85.8 41.2a10.17 10.17 0 01-3.1 14 11.82 11.82 0 01-5.5 1.4zM258.1 512.2a9.71 9.71 0 01-3.7-.7 10 10 0 01-5.7-13.1c24.3-61.9 113.3-65.9 117.1-66.1a10.16 10.16 0 01.8 20.3c-.8 0-79.6 3.7-99 53.2a10.42 10.42 0 01-9.5 6.4z"
                fill="#ee3b49"
            />
            <Path
                d="M200.7 400.6a10.49 10.49 0 01-6.9-2.7 10.06 10.06 0 01-3.3-7.5V303a10.12 10.12 0 018.6-10 1611 1611 0 01478.3 0 10 10 0 018.6 10v87.4a10.13 10.13 0 01-11.1 10.1c-144.1-14-303.4-14-473.5 0-.1.1-.4.1-.7.1zm10.2-88.7v67.7c162.9-12.9 315.8-12.9 455-.2v-67.5a1590.93 1590.93 0 00-455 0z"
                fill="#49b97b"
            />
            <Path
                d="M149.6 290.9a12.22 12.22 0 01-2.6-.3C78.4 276 45.7 236.2 30.4 205.3c-16.6-33.3-16.7-63.2-16.7-64.4a12.14 12.14 0 0119.1-10c4.3 2.9 106.4 71.2 219.7-40 126-123.7 298.4-64.4 300.2-63.8a12.15 12.15 0 01-8.1 22.9c-1.8-.6-160-54.6-275 58.2-95.9 94.1-188 71.7-228.8 54.1 6.8 31.2 30.3 87.1 111.5 104.5a12.17 12.17 0 019.4 14.4 12.46 12.46 0 01-12.1 9.7z"
                fill="#f4c2d1"
            />
        </Svg>
    )
}

export default SvgCoronaArbox
