// @flow
import * as React from 'react';
import {Platform, StyleSheet, View} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import EStyleSheet from "react-native-extended-stylesheet";

const DayHeader = (props) => {
    const {dayName, dateNumber, isToday} = props;

    return (
        <View style={styles.wrapper}>
            <AppText style={styles.dayName}>{dayName}</AppText>
            <AppText style={[styles.dateNumber, isToday ? styles.today : null]}>{dateNumber}</AppText>
        </View>
    );
};
export default DayHeader;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            wrapper: {
                backgroundColor: Colors.white,
                padding: '0.5em',
                gap: '0.2em',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottomWidth: 1,
                borderBottomColor: Colors.siteScheduleBorder,
                borderEndColor: Colors.siteScheduleBorder,
                borderEndWidth: 1
            },
            dayName: {
                fontSize: '0.7rem',
                color: Colors.siteScheduleDay
            },
            dateNumber: {
                fontSize: '1.2rem',
                fontWeight: 'bold'
            },
            today: {
                backgroundColor: Colors.lightBlack,
                borderRadius: '50%',
                height: '1.5em',
                width: '1.5em',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                color: Colors.white
            }
        }
    })
})