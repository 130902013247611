import {
    WHITE_LABEL_PROPERTIES
} from "../constants";
import {apiAction} from "../helpers/HTTP";


export const getProperties = () => {
    return async dispatch => {
        const actionType = WHITE_LABEL_PROPERTIES.toUpperCase();
        try {
            dispatch({type: `${actionType}_PENDING`});
            const response = await apiAction('whiteLabel/properties', 'post', null);
            dispatch({type: `${actionType}_SUCCESS`, payload: response});
            return response;
        } catch (error) {
            dispatch({type: `${actionType}_FAILED`, payload: error});
            return error;
        }
    };
};
