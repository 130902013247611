import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {scale, verticalScale} from 'react-native-size-matters';
import {Icon} from 'native-base';
import UserAvatar from 'react-native-user-avatar';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import styles from './style';
import uuid from 'uuid';
import {cardTypes} from './cardTypes';
import {modalTypes} from '../../modals/modalTypes';
import {showModal} from '../../modals/actions';
import {fetchFriendProfile} from '../../actions/friend';
import {Colors} from '../../styles/Colors';
import {t} from '../../services/i18n';
import i18n from '../../services/i18n';
import {globalStyles} from "../../styles/Global";
import date from '../../services/i18n/date';
import {ClassActionButton} from "../Stateless/ClassActionButton";
import FriendsListModal from "../../modals/modalFlows/FriendsListModal";
import DrawerHandler from "../DrawerHandler";
import {AppText} from "../Stateless/AppText";
import Booking from "../ScheduleFlow/Booking"
import {
    CANCEL_SCHEDULE_USER,
    CANCEL_WAIT_LIST,
    INFO,
    INSERT_SCHEDULE_USER,
    INSERT_STAND_BY
} from "../../constants/booking";
import {TextButton} from "../Stateless/TextButton";
import {FitImageTextRow} from "../Stateless/FitImageTextRow";
import * as Linking from 'expo-linking';
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {EXTRA_SM_ICON_SIZE, LARGE_ICON_SIZE, MEDIUM_ICON_SIZE, SMALL_ICON_SIZE} from "../../helpers/functions";
import {getThemeProperty, keys} from "../../styles/Theme";
import ScheduleCardV2 from "../ScheduleCardV2";
import {hasCoachRestriction} from "../../helpers/DisablePages";


class ScheduleCard extends React.PureComponent {
    state = {
        howManyUsersToDisplay: this.props.howManyUsersToDisplay || 5,
        drawerAction: null
    };

    constructor(props) {
        super(props);
        this.listViewAction = this.listViewAction.bind(this);
    }

    componentDidMount() {
        this.assignScheduleCardType();
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.assignScheduleCardType();
        }
    }

    listViewAction() {
        const {data} = this.props;
        this.props.showModal(modalTypes.MODAL_SIMPLE, this.state.cardType, data, 'info', this.props.reducerActionName, this.props.type)
    }

    assignScheduleCardType() {
        const {data} = this.props;
        const action = data.booking_option;
        switch (data.booking_option) {
            case 'past': {
                this.setState({
                    cardType: cardTypes.BOOK.type,
                    cardColor: Colors.lightGrey,
                    actionButtonText: '',
                    showIndicator: true,
                });
                break;
            }
            case 'insertScheduleUser':
            case 'insertStandby': {
                this.setState({
                    cardType: cardTypes.BOOK.type,
                    cardColor: action === 'insertScheduleUser' ? cardTypes.BOOK.color : cardTypes.WAITLIST.color,
                    actionButtonText: action === 'insertScheduleUser' ? t('modals:BookClass:title') : t('modals:AddToWaitlist:title'),
                    showIndicator: false,
                });
                break;
            }
            case 'cancelScheduleUser':
            case 'cancelWaitList': {
                this.setState({
                    cardType: cardTypes.BOOKED.type,
                    cardColor: action === 'cancelScheduleUser' ? cardTypes.BOOKED.color : cardTypes.WAITLISTED.color,
                    actionButtonText: action === 'cancelScheduleUser' ? t('modals:CancelClass:title') : t('modals:RemoveFromWaitlist:title'),
                    showIndicator: true,
                });
                break;
            }
            default:
                break;
        }

    }

    renderUsers() {
        const {data} = this.props;
        const disable = data.disable_pages_app ? data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'whoIsBooked') : null;
        if (disable) {
            return null;
        }
        const usersToDisplay =
            data.booked_users && data.booked_users.slice(0,  this.state.howManyUsersToDisplay);
        const moreAvatarsLength =
            data.booked_users && data.booked_users.length - usersToDisplay.length;
        if (data.booked_users) {
            return (
                <View>
                    <TouchableOpacity onPress={() => {
                        this.setState({drawerAction: 'friendsList'}, () => this.RBSheet.open())
                    }}>
                        <View style={globalStyles.avatarsRow}>
                            {this.renderUserAvatars(usersToDisplay)}
                            {!!moreAvatarsLength && (
                                <View
                                    style={[globalStyles.moreAvatars, i18n.isRTL ? {marginLeft: scale(8)} : {marginRight: scale(8)}]}>
                                    <AppText style={styles.moreAvatarsText}>+{moreAvatarsLength}</AppText>
                                </View>
                            )}
                        </View>
                    </TouchableOpacity>
                </View>
            );
        }
        return null;
    }

    renderUserAvatars(usersToDisplay) {
        if (usersToDisplay) {
            return (
                <React.Fragment>
                    {usersToDisplay.map(user => {
                        return (
                            <View style={i18n.isRTL ? {marginLeft: scale(8)} : {marginRight: scale(8)}} key={user.id}>
                                <UserAvatar
                                    size={scale(35)}
                                    name={`${user.full_name_shorten}`}
                                    src={user.image ? user.image : null}
                                />
                            </View>
                        );
                    })}
                </React.Fragment>
            );
        }
        return null;
    }

    renderSlots() {
        const {data} = this.props;
        const disable = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'slotsOpen');
        const slots = data.max_users - data.registered;

        if (disable) {
            if (slots === 0) {
                return (<View key={uuid.v4()} style={styles.informationRow}>
                    <Icons8Generator name={'full-tank'}/>
                    <AppText
                        style={[styles.informationText]}>{t('screens:Schedule:card:class-full', {})}</AppText>
                </View>)
            }
            return null;
        }
        if (slots > 0) {
            return (
                <TouchableOpacity key={uuid.v4()} style={styles.informationRow}
                                  onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}>
                    <Icons8Generator name={'full-tank'}/>
                    <AppText style={styles.informationText}>{t('screens:Schedule:card:slots', {slots})}</AppText>
                </TouchableOpacity>
            );
        }
        //NOTE IT IS OPPISITE
        const disableStandBy = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'standBy');
        if (slots === 0 && disableStandBy) {
            return (
                <View key={uuid.v4()} style={styles.informationRow}>
                    <Icons8Generator name={'full-tank'}/>
                    {data.hasOwnProperty('stand_by_position') && data.stand_by_position !== null ?
                        (<AppText style={[styles.informationText]}>{t('screens:Schedule:card:on-waitlist-exists', {
                            me: data.stand_by_position,
                            waitlisted: data.stand_by
                        })}</AppText>)
                        : (<AppText
                            style={[styles.informationText]}>{t('screens:Schedule:card:on-waitlist', {waitlisted: data.stand_by})}</AppText>)}
                </View>
            );
        }
        return null;
    }

    renderCoach() {
        const {data} = this.props;
        const disableCoachDisplay = hasCoachRestriction(data)
        if (data.coach && !disableCoachDisplay) {
            return (
                <TouchableOpacity key={uuid.v4()} style={styles.informationRow}
                                  onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}>
                    <Icons8Generator name={'user'}/>
                    <AppText
                        style={styles.informationText}>{data.coach ? data.coach.full_name : '--'} {data.second_coach ? `& ${data.second_coach.full_name}` : null}</AppText>
                </TouchableOpacity>
            );
        }
        return null;

    }

    renderParticipants() {
        const {data} = this.props;
        const disable = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'arrivingAmount');
        if (disable) {
            return null;
        }
        if (data.registered) {
            return (
                <TouchableOpacity key={uuid.v4()} style={styles.informationRow}
                                  onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}>
                    <Icons8Generator name={'people'}/>
                    <AppText
                        style={styles.informationText}>{t('screens:Schedule:card:going', {going: data.registered})}
                    </AppText>
                </TouchableOpacity>
            );
        }
        return null;
    }

    // onPress={() =>  Linking.openURL(data.live_link)}
    renderOnlineClass() {
        const {data} = this.props;
        if (data.live_link) {
            return (
                <TouchableOpacity key={uuid.v4()} style={styles.informationRow}
                                  onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}>
                    <Icons8Generator name={'tv'}/>
                    <AppText style={styles.informationText}>{t('screens:Schedule:card:online-class', {})}</AppText>
                </TouchableOpacity>
            )
        }
        return null;

    }

    renderTimeAndPlace() {
        const {data, feedView} = this.props;
        if (data.time && data.end_time) {
            return (
                <TouchableOpacity key={uuid.v4()} style={styles.informationRow}
                                  onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}>
                    <Icons8Generator name={'clock-outline'}/>
                    <AppText style={styles.informationText}>
                        {feedView ? date.format(data.date, 'LL') + ', ' : null}
                        {date.timeFormat(data.time, 'LT')} - {date.timeFormat(data.end_time, 'LT')}
                    </AppText>
                </TouchableOpacity>
            );
        }
        return null;
    }


    renderClassName() {
        const {data} = this.props;
        return (
            <View>
                <TouchableOpacity
                    key={uuid.v4()}
                    style={styles.titleBox}
                    onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}>
                    <AppText style={styles.title}>{data.box_categories.name}</AppText>
                </TouchableOpacity>
            </View>
        );
    }

    renderActionButton() {
        const {actionButtonText, cardType} = this.state;
        const {data, reducerActionName} = this.props;
        if (this.props.screen === 'notifications')
            return null;
        let iconType, iconName, textColor;
        if (cardType === cardTypes.BOOKED.type) {
            iconType = 'MaterialCommunityIcons';
            iconName = 'trash-can';
            textColor = Colors.red;
        } else {
            iconType = 'AntDesign';
            iconName = 'plus';
            textColor = Colors.black;
        }
        if (reducerActionName === null)
            return null;
        return (
            <View
                style={[globalStyles.flexDirectionRow, globalStyles.cardActionButtonContainer, {
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }]}>
                <ClassActionButton
                    onPress={() => this.setState({drawerAction: data.booking_option}, () => this.RBSheet.open())}
                    iconType={iconType}
                    iconName={iconName}
                    textColor={textColor}
                    text={actionButtonText}
                />
                {data.booking_option === CANCEL_SCHEDULE_USER && data.live_link ? (
                    <ClassActionButton
                        onPress={() => Linking.openURL(data.live_link)}
                        iconType={"MaterialIcons"}
                        iconName={"tv"}
                        textColor={Colors.black}
                        text={t('screens:Schedule:live_link', {})}
                    />
                ) : null}
            </View>
        );
    }


    renderIndicator() {
        const {data} = this.props;
        if (data.late_cancellation === 1) {
            return (
                <View key={uuid.v4()}
                      style={[styles.indicatorWrapper, {backgroundColor: Colors.red}]}>
                    <AppText style={{
                        color: Colors.white,
                        fontSize: scale(13)
                    }}>{t('screens:Schedule:card:late-cancel', {})}</AppText>
                </View>
            );
        }
        if (data.user_booked) {
            return (
                <View key={uuid.v4()}
                      style={[styles.indicatorWrapper, {backgroundColor: Colors.green}]}>
                    <AppText style={{
                        color: Colors.white,
                        fontSize: scale(13)
                    }}>{t('screens:Schedule:card:booked', {})}</AppText>
                </View>
            );
        } else if (data.user_in_standby) {
            return (
                <View key={uuid.v4()}
                      style={[styles.indicatorWrapper, {backgroundColor: Colors.yellow}]}>
                    <AppText style={{
                        color: Colors.white,
                        fontSize: scale(13)
                    }}>{t('screens:Schedule:card:in-waitlist', {})}</AppText>
                </View>
            );
        }
        return  null;
    }

    renderColorLabel() {
        const {cardColor} = this.state;
        return <View style={[styles.mark, {backgroundColor: cardColor}]}/>
    }

    renderListView() {
        const {data} = this.props;
        let buttonBackground = this.props.whiteLabelProperties.primaryColor;
        let buttonText = t('screens:FriendProfile:schedule-join', {});
        let textColor = Colors.white;
        switch (data.booking_option) {
            case CANCEL_SCHEDULE_USER: {
                buttonBackground = Colors.transparent;
                buttonText = t('screens:FriendProfile:schedule-joined', {});
                textColor = this.props.whiteLabelProperties.primaryColor;
                break;
            }
            case CANCEL_WAIT_LIST: {
                buttonText = t('screens:FriendProfile:schedule-waitlisted', {});
                buttonBackground = Colors.transparent;
                textColor = Colors.yellow;
                break;
            }
            case INSERT_STAND_BY: {
                buttonBackground = Colors.yellow;
                buttonText = t('screens:FriendProfile:schedule-join', {});
                textColor = Colors.white;
                break;
            }
            default:
                break;
        }
        const bottomText = `${date.format(data.date, 'MM/DD')} ${date.timeFormat(data.time, 'LT')}`;
        return (
            <TouchableOpacity

                onPress={() => {
                    this.setState({drawerAction: INFO}, () => this.RBSheet.open())
                }}
                style={[globalStyles.textImageContainer]}>
                <FitImageTextRow
                    imageName={data.box_categories.name}
                    imageSize={40}
                    src={data.box.cloudinary_image ? data.box.cloudinary_image : data.locations_box.logo}
                    noImage={true}
                    upperText={data.box_categories.name}
                    bottomText={bottomText}
                />
                <View>
                    <TextButton
                        onPress={() => this.setState({drawerAction: INFO}, () => this.RBSheet.open())}
                        backgroundColor={buttonBackground}
                        textColor={textColor}
                        text={buttonText}
                    />
                </View>
            </TouchableOpacity>
        )
    }

    getContent() {
        const {data, reducerActionName, type} = this.props;
        switch (this.state.drawerAction) {
            case "friendsList": {
                return (
                    <FriendsListModal
                        information={data}
                        closeDrawer={() => this.closeDrawer()}
                    />
                )
            }
            case INSERT_SCHEDULE_USER:
            case INSERT_STAND_BY:
            case INFO:
            case CANCEL_SCHEDULE_USER:
            case CANCEL_WAIT_LIST: {
                return (
                    <Booking information={data}
                             initStep={this.state.drawerAction}
                             closeDrawer={() => this.closeDrawer()}
                             reducerActionName={reducerActionName}
                             type={type}
                    />
                )
            }
        }
    }

    closeDrawer() {
        if (this.RBSheet) {
            this.RBSheet.close()
        }
    }


    render() {
        const {data, feedView, listView, onlyUsers} = this.props;
        return (
            <View key={data.id}>
                {/*TODO THIS seems to be a better practice with less code, but for time reasons is now off*/}
                {this.state.drawerAction ? (
                    <DrawerHandler key={uuid.v4()} noMargin={false}
                                   title={this.state.drawerAction === 'friendsList' ? t('common:drawer:booked', {}) : null}
                                   closeOnPressMask={!(this.state.drawerAction === INSERT_SCHEDULE_USER)}
                                   ref={el => this.RBSheet = el ? el.ref.current : null}>
                        {this.getContent()}
                    </DrawerHandler>
                ) : null}
                {listView ? this.renderListView() : feedView ? (
                    <View style={feedView ? null : globalStyles.cardContainer}>
                        {/*{feedView ? null : this.renderColorLabel()}*/}
                        <View style={globalStyles.card}>
                            {feedView ? this.props.children : null}
                            {onlyUsers !== true ? [this.renderIndicator(), this.renderTimeAndPlace()] : null}
                            {this.renderClassName()}
                            <View style={styles.indentation}>
                                {onlyUsers !== true ? [this.renderCoach(), this.renderSlots(), this.renderParticipants(), this.renderOnlineClass()] : null}
                                {this.renderUsers()}
                            </View>
                        </View>
                        {data.booking_option === 'past' ? null : this.renderActionButton()}
                    </View>) : <ScheduleCardV2 data={data}/>}
            </View>


        );
    }
}

ScheduleCard.propTypes = {
    data: PropTypes.object.isRequired,
    fetchFriendProfile: PropTypes.func,
    howManyUsersToDisplay: PropTypes.number,
    reducerActionName: PropTypes.string,
    type: PropTypes.string,
    showModal: PropTypes.func,
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchFriendProfile,
            showModal,
        },
        dispatch,
    );

const mapStateToProps = state => ({whiteLabelProperties: state.whiteLabelProperties});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScheduleCard);
