import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8Calendar3(props) {
  return (
    <Svg width="1em" height="1em"  fill="#18181B" viewBox="0 0 1066 1112" {...props}>
      <Path
        d="M231.74 0c-25.347 0-46.349 20.995-46.349 46.333v46.334H46.348c-9.415 0-19.734 1.448-29.601 8.325C6.88 107.87 0 121.535 0 134.204v932.458c0 11.583 3.983 23.438 12.311 32.035C20.55 1107.385 32.68 1112 44.447 1112h977.106c11.768 0 23.898-4.615 32.136-13.303 8.328-8.597 12.311-20.452 12.311-32.035V134.204c0-12.67-6.88-26.334-16.747-33.212-9.867-6.877-20.186-8.325-29.6-8.325H880.608V46.333C880.609 20.995 859.607 0 834.26 0h-46.348c-25.346 0-46.348 20.995-46.348 46.333v46.334h-417.13V46.333C324.435 20.995 303.433 0 278.087 0h-46.348zm0 46.333h46.347v139h-46.348v-139zm556.173 0h46.348v139h-46.348v-139zM46.348 139H185.39v46.333c0 25.339 21.002 46.334 46.348 46.334h46.348c25.346 0 46.348-20.995 46.348-46.334V139h417.13v46.333c0 25.339 21.002 46.334 46.348 46.334h46.348c25.346 0 46.348-20.995 46.348-46.334V139h139.043v162.167H46.348V139zm0 208.5h973.304v718.167H46.348V347.5z"
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default SvgIcons8Calendar3;
