import React, {useEffect, useState} from 'react';
import {View, TouchableOpacity} from "react-native-web";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";
import {Colors} from "../../../../styles/Colors";
import {AppText} from "../../../Stateless/AppText";
import {Icons8Generator} from "../../../Stateless/Icons8Generator";
import {isMobile} from "../../../../helpers/functions";

const MultiChoiceQuestion = (props) => {
    const {answerOptions, isOneChoice, onSelect, answers} = props;
    const [selectedAnswers, setSelectedAnswers] = useState(answers || []);
    const mobile = isMobile();

    useEffect(() => {
        answerOptions.map(answer => answer.checked = false)
    }, []);

    useEffect(() => {
        if(answers) {
            setSelectedAnswers(answers)
        }
    }, [answers]);


    const answerSelected = (answer) => {
        const answerIndex = selectedAnswers.findIndex(a => a.id === answer.id);
        let newSelectedArr = [...selectedAnswers]
        if(isOneChoice) {
            answer.checked = true;
            newSelectedArr[0] = answer;
        } else {
            if(answerIndex === -1) {
                answer.checked = true;
                newSelectedArr.push(answer)
            } else {
                answer.checked = false;
                newSelectedArr.splice(answerIndex, 1)
            }
        }
        setSelectedAnswers(newSelectedArr)
        onSelect(newSelectedArr)
    }

    return (
        <View style={[styles.questionWrapper, mobile && styles.questionWrapperMobile]}>
            {
                answerOptions.map(answer => {
                    const isSelected = selectedAnswers.findIndex(a => a.id === answer.id) !== -1;
                    return (
                        <TouchableOpacity onPress={() => answerSelected(answer)} style={[styles.answerBtn, mobile && styles.answerBtnMobile]} key={`multi-choice-answer-${answer.id}-${answer.answer_text}`}>
                            <AppText style={[styles.answerText,isSelected && {fontWeight: 'bold'}, mobile && {fontSize: '1.1rem'}]}>{answer.answer_text}</AppText>
                            <View style={[styles.checkboxWrapper, mobile && styles.checkboxWrapperMobile, isOneChoice && {borderRadius: '50%'}, isSelected && styles.selectedAnswer]}>
                                <Icons8Generator name={'check'} size={mobile ? '13px' : '10px'} fill={Colors.white}/>
                            </View>
                        </TouchableOpacity>
                    )})
            }
        </View>
    )
};

export default MultiChoiceQuestion;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            questionWrapper: {
                gap: '0.5em',
                marginTop: '1.5em',
                height: '100%',
                width: '100%',
                maxHeight: '-webkit-fill-available',
                marginBottom: '1.5em',
                overflow: 'auto'
            },
            questionWrapperMobile: {
                height: 'auto',
                gap: '1em'
            },
            answerBtn: {
                width: '100%',
                borderRadius: 2,
                borderWidth: 1,
                borderColor: Colors.answerBorder,
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                paddingHorizontal: '1em',
                paddingVertical: '0.7em'
            },
            answerBtnMobile: {
                paddingVertical: '1em',
                backgroundColor: Colors.white
            },
            answerText: {
                color: Colors.lightBlack,
                fontWeight: '300',
                fontSize: '0.9rem',
                maxWidth: '90%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            },
            checkboxWrapper: {
                backgroundColor: Colors.white,
                borderColor: Colors.separatorCircleGray,
                borderWidth: 1,
                height: 15,
                width: 15,
                borderRadius: 3,
                justifyContent: 'center',
                alignItems: 'center',
            },
            checkboxWrapperMobile: {
                height: 21,
                width: 21,
            },
            selectedAnswer: {
                backgroundColor: Colors.lightBlack,
                borderColor: Colors.white
            },
        }
    })
})

