import React from 'react';
import {View,TextInput} from 'react-native';
import {globalStyles} from "../../styles/Global";
import {Colors} from "../../styles/Colors";
import {scale, ScaledSheet, verticalScale} from "react-native-size-matters";
import {AppText} from "../Stateless/AppText";
import {t} from "../../services/i18n";
import i18n from "../../services/i18n";


export default class MinSecHandler extends React.PureComponent {

    constructor(props) {
        super(props);

     }

     render(){
        const {active,updateValue} = this.props;
     return(
         <View style={[globalStyles.flexDirectionRow,i18n.isRTL ? {flexDirection: 'row-reverse'} : {}]}>
             <View style={[globalStyles.flexDirectionColumn]}>
                 <TextInput
                     style={styles.textInput}
                     maxLength={2}
                     placeholder={'0'}
                     keyboardType='number-pad'
                     onChangeText={(text) => updateValue('min',text)}
                     value={active.min.toString()}
                 />
                 <AppText
                     style={[globalStyles.heeboRegular, globalStyles.textWhite,globalStyles.marginTopSm,{textAlign:'center'}]}>{t('screens:Logbook:minute', {})}</AppText>
             </View>
             <View style={{width: scale(40),alignItems:'center'}}>
                 <View style={{
                     width: scale(10),
                     height: scale(10),
                     marginTop: verticalScale(12),
                     backgroundColor: Colors.white,
                 }}/>
                 <View style={{
                     width: scale(10),
                     height: scale(10),
                     backgroundColor: Colors.white,
                     marginTop: verticalScale(10)
                 }}/>
             </View>
             <View style={[globalStyles.flexDirectionColumn]}>
                 <TextInput
                     style={styles.textInput}
                     placeholder={'0'}
                     maxLength={2}
                     keyboardType='number-pad'
                     onChangeText={(text) => updateValue('sec',text)}
                     value={active.sec.toString()}
                 />
                 <AppText
                     style={[globalStyles.heeboRegular, globalStyles.textWhite, globalStyles.marginTopSm,{textAlign:'center'}]}>{t('screens:Logbook:second', {})}</AppText>
             </View>
         </View>
     )

     }
}


const
    styles = ScaledSheet.create({
        textInput: {
            width: scale(60),
            height: scale(60),
            fontSize: scale(20),
            backgroundColor: Colors.white,
            color: Colors.black,
            textAlign: 'center'
        }
    });
