export const PAST = 'past';
export const CANCEL_SCHEDULE_USER = 'cancelScheduleUser';
export const CANCEL_WAIT_LIST = 'cancelWaitList';
export const INSERT_SCHEDULE_USER = 'insertScheduleUser';
export const INSERT_STAND_BY = 'insertStandby';
export const INFO = 'info';
export const SUCCESS_OPTIONS = 'successOptions';
export const INVITE_FRIENDS = 'inviteFriends';
export const ADD_TO_CALENDAR = 'addToCalendar';

