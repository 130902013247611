import {apiAction} from '../helpers/HTTP';
import {
    ADD_TO_CART, CURRENCY_SYMBOL, EMPTY_CART, PATCH_MEMBERSHIP_START_DATE,
    REMOVE_FROM_CART, SET_SHOP_LOCATIONS_BOX,
    SHOP, SUB_QUANTITY, TAXES, VALIDATE_PROMO_CODE
} from '../constants';

export const fetchShopAbleItems = (route) => async (dispatch) => {
    const actionType = SHOP.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(route, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const fetchTaxes = (route) => async (dispatch) => {
    const actionType = TAXES.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(route, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const fetchCurrencySymbol = (route) => async (dispatch) => {
    const actionType = CURRENCY_SYMBOL.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction(route, 'get', null);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        // return response;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const addToCart = (membership) => async (dispatch) => {
    const actionType = ADD_TO_CART.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`, payload: membership});//Pending will decide if can by added to cart;
        dispatch({type: `${actionType}_SUCCESS`, payload: membership});
        return true;
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};
export const removeFromCart = (membership) => async (dispatch) => {
    const actionType = REMOVE_FROM_CART.toUpperCase();
    try {
        // dispatch({type: `${actionType}_PENDING`});
        dispatch({type: `${actionType}_SUCCESS`, payload: membership});
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};
export const patchMembershipStartDate = (payload) => async (dispatch) => {
    const actionType = PATCH_MEMBERSHIP_START_DATE.toUpperCase();
    try {
        dispatch({type: `${actionType}_SUCCESS`, payload: payload});
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};
export const emptyCart = () => async (dispatch) => {
    const actionType = EMPTY_CART.toUpperCase();
    try {
        dispatch({type: `${actionType}_SUCCESS`, payload: []});
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const subQuantity = (membership) => async (dispatch) => {
    const actionType = SUB_QUANTITY.toUpperCase();
    try {
        dispatch({type: `${actionType}_SUCCESS`, payload: membership});
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const setShopLocationsBox = (payload) => async (dispatch) => {
    const actionType = SET_SHOP_LOCATIONS_BOX.toUpperCase();
    try {
        dispatch({type: `${actionType}_SUCCESS`, payload: payload});
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};

export const validatePromoCode = (payload) => async (dispatch) => {
    const actionType = VALIDATE_PROMO_CODE.toUpperCase();
    try {
        dispatch({type: `${actionType}_PENDING`});
        const response = await apiAction('promoCode/validate', 'post', payload);
        dispatch({type: `${actionType}_SUCCESS`, payload: response});
        return response
    } catch (error) {
        dispatch({type: `${actionType}_FAILED`, payload: error});
        return error;
    }
};