const tintColor = '#2f95dc';

export const Colors = {

    //RE-BRAND APP
    //White label props
    brandedGreen:  '#00D181',
    brandedGreenRGBA: 'rgba(33, 208, 131, 0.9)',
    brandedBlue: '#0052A6',
    brandedRed:  '#FF3345',
    brandedPink:  '#F5C2D1',
    mainTabColor:   '#00D181',
    //White label props

    separatorCircleGray:'#D3D3D3',
    screensGrayBackground:'#F7F7F7',
    brandedGraySelected: '#d8d8d8',
    brandedLightText: '#f6f6f6',
    lightWhiteRGBA: 'rgba(255,255,255,0.5)',
    siteBgLightWhiteRGBA: 'rgba(255,255,255,0.8)',
    logbookPrizeColor: '#F7B500',
    // BEFORE RE-BRAND!!!
    backgroundOverlayRGBA: 'rgba(0, 0, 0, 0.25)',
    backgroundGradientColorAbove: '#0099DC',
    backgroundGradientColorBottom: '#00B6DC',
    backgroundGradientColorBottomTwo: '#00c8ee',

    backgroundGradientMembershipScreenColorStart: '#58CF98',
    backgroundGradientMembershipScreenColorEnd: '#94E0BD',

    brandedGreenCardRGBA: 'rgba(0, 209, 129, 0.5)',

    iconEmptyStateColor: '#A2A0A0',

    // copied from typescript version
    brandPrimary: '#44A6F8',
    brandLight: '#74bcf9',
    brandInfo: '#62B1F6',
    brandSuccess: '#1fcd7c',
    brandDanger: '#FF5757',
    brandWarning: '#ffc506',
    brandSidebar: '#252932',

    grey: '#666666',
    darkWhite: '#FAFAFA',
    veryLightGrey: '#EFEEF3',
    lightGrey: '#BABABA',
    light: '#FFF',
    listSeparator: '#fafafa',
    iconBorder: '#F6F6F6',
    clubPlaceholderColor: '#f6f6f6',
    brightSkyBlue: '#0db6ff',
    labelColor: '#a4a4a4',
    slateGrey: '#58616d',
    redPinkColor: '#FF3345',
    red: '#FF3345',
    blue: '#0052A6',

    // from expo starter
    tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    tabBar: '#fefefe',
    errorBackground: 'red',
    errorText: '#fff',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: tintColor,
    noticeText: '#fff',

    bottomTabTint: '#000000',
    white: '#FFFFFF',
    dropdownBackground: '#00A6DC',
    viewBackground: '#fbfbfb',
    modalFontColor: '#919293',

    // card color
    lightBlack: '#343434',
    bottomBorderLabelBlue: '#44A6F8',
    bottomBorderLabelRed: '#FF3345',
    bottomBorderLabelGreen: '#1fcd7c',
    clubMsgCardBtnColor: '#27a3ba',
    reminderCardBtnColor: '#0de1ae',
    birthdayCardTitleColor: '#6d819c',
    birthdayCardContentColor: '#FF3345',
    friendBirthdayCardBtnColor: '#FF3345',
    epidemicColor: "#D17497",


    thanksBorderColor: "#E3E3E3",
    thanksBackgroundColor: "#F7F8F9",
    thanksHeartColor: "#58616D",
    // Arbox colors start here, above is Poland shit

    purple: '#8175FE',
    paleblue: '#08ABF3',
    textPaleblue: '#44A6F8',
    green: '#00D181',
    yellow: '#ffc506',
    transparent: 'transparent',
    orange: "#FA6400",
    greenText: "#7BBB5E",
    grayText: "#858585",
    errorIconColor: "#D04C4F",
    graySeparator: "#F0F0F0",
    black: 'black',
    purpleColor: "#F550C4",
    futureColor: "#FFA835",
    arboxBlue: "#0053A8",


    showMoreContainer: 'rgba(0, 153, 220, 0.4)',

    //ICONS
    iconGray: "#DEDFE0",
    iconBlue: "#0099DC",


    //Headers
    grayHeader: '#636363',
    sectionHeaderColor: '#fafafa',

    siteScheduleBorder: '#e0e0e0',
    siteScheduleDay: '#7b7b7b',
    pastDateBg: '#f5f5f5',
    calendarBlack: '#202124',
    defaultIconFill: '#18181b',
    siteBg: '#f9f9f9',
    darkGrayContent: '#383838',
    tabSeparator: "#e1e1e1",
    progressBar: "#D9D9D9",

    selectedAnswerBg: "#E2E2E2",
    answerBorder: "#eeeeee",
    btnBlue:"#0053FF",
    weekDaySelected: '#ECF7FF',
    weekDayDark: '#EAEAEA',
    fullyBooked: '#F1F1F1',

    alerts: {
        warningPrimary: '#FFC400',
        errorPrimary: '#DC2626',
        successPrimary: '#35A34A',
        warningSecondary: '#FFFAED',
        errorSecondary: '#FBCACA',
        successSecondary: '#BBF7D0',
    }



};
