import React from 'react';
import {View} from 'react-native';
import {globalStyles} from "../../../styles/Global";
import {verticalScale, scale} from "react-native-size-matters"
import {AppText} from "../AppText";
import {Colors} from "../../../styles/Colors";
import FitImage from 'react-native-fit-image';
import {Icons8Generator} from "../Icons8Generator";
import {BIG_ICON_SIZE} from "../../../helpers/functions";


export const FitImageTextRow = props => {
    const upperStyles = props.upperTextStyles ? {...props.upperTextStyles} : {};
    const bottomStyles = props.bottomTextStyles ? {...props.bottomTextStyles} : {};
    return (
        <View
            style={[globalStyles.flexDirectionRow]}>
            {props.noImage || !props.src ? (   <View style={globalStyles.clubImagePlaceholder}>
                <Icons8Generator name={'clubs'} width={BIG_ICON_SIZE} height={BIG_ICON_SIZE} fill={Colors.black}/>
            </View>) : (<FitImage
                resizeMode="contain"
                source={{uri: props.src}}
                style={[globalStyles.cardRadius, {width: scale(40), height: scale(40), borderRadius: 100}]}
            />)}
            <View
                style={[globalStyles.flexDirectionColumn, globalStyles.paddingStartLarge, props.bottomText ? {} : globalStyles.centerItems]}>
                <AppText
                    style={[globalStyles.heeboMedium, {color: Colors.black}, upperStyles,{maxWidth:scale(175)}]} numberOfLines={1}>{props.upperText}</AppText>
                {props.bottomText ? <AppText
                    style={[globalStyles.heeboRegular, {marginTop: verticalScale(2)}, bottomStyles]}>{props.bottomText}</AppText> : null}
            </View>
        </View>
    )
};



