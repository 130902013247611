import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import UserAvatar from 'react-native-user-avatar';
import {
    ScrollView,
    RefreshControl,
    View,
    TouchableOpacity,

} from 'react-native';
import PropTypes from 'prop-types';
import {scale, verticalScale, ScaledSheet} from 'react-native-size-matters';
import FeedList from '../../../components/FeedList'
import {t} from '../../../services/i18n';
import {showModal} from '../../../modals/actions'
import {fetchFeedData, fetchNotificationsCount} from '../../../actions/home'
import {fetchProfile} from '../../../actions/user';
import {globalStyles} from "../../../styles/Global";
import {StatusBarWrap} from "../../../components/Stateless/StatusBarWrap";
import notificationTokenService from "../../../services/notificationTokenService";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../../components/Stateless/AppText";
import i18n from "../../../services/i18n";
import Config from "../../../config/Config";
import {getSvg8IconsDefault, LARGE_ICON_SIZE} from "../../../helpers/functions";
import {Icons8Generator} from "../../../components/Stateless/Icons8Generator";
import {getThemeProperty, keys} from "../../../styles/Theme";


class HomeScreen extends React.Component {
    static navigationOptions = ({navigation, navigationOptions}) => ({
        header: null,
    });

    state = {
        statusbarF: true,
        messagesCount: 0,
        isSwiping: false,
        awaitUser: true,
        currentlyOpenSwipeable: null,
        statusBarBackground: getThemeProperty(keys.brandedGreen),
        barStyle: 'light-content'
    };

    constructor(props) {
        super(props);
        this.imageIndex = 0;
        this.init = this.init.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.isSwiping = this.isSwiping.bind(this);
        this.svg_props = getSvg8IconsDefault();
    }

    componentDidUpdate(prevPros){
        if(prevPros.user.image !== this.props.user.image){
            this.imageIndex++;
        }
    }
    async componentDidMount() {
        this.setState({awaitUser : true});
        this.themeColor = getThemeProperty(keys.brandedGreen);
        this.didFocusListener = this.props.navigation.addListener(
            'didFocus',
            () => {
                this.props.navigation.setParams({
                    tapOnTabNavigator: this.tapOnTabNavigator
                });
            },
        );
        await this.props.fetchProfile();
     //   notificationTokenService.registerForPushNotificationsAsync();
        this.init();
    };

    componentWillUnmount() {
        this.didFocusListener.remove();
    }


    isSwiping(value) {
        this.setState({isSwiping: value})
    }

    async init() {
        this.props.fetchFeedData();
        this.props.fetchNotificationsCount();
        this.setState({awaitUser : false});

    }

    onRefresh() {
        this.init();
    }

    handleScroll = (e) => {
        const {currentlyOpenSwipeable} = this.state;
        if (e.nativeEvent.contentOffset.y > 260) {
            if (this.state.statusBarBackground !== Colors.white || this.state.barStyle !== 'dark-content') {
                this.setState({statusBarBackground: Colors.white, barStyle: "dark-content"})
            }
        }
        if (e.nativeEvent.contentOffset.y < 260 && (this.state.statusBarBackground !== this.themeColor || this.state.barStyle !== 'light-content')) {
            this.setState({statusBarBackground: this.themeColor, barStyle: "light-content"})
        }

        if (currentlyOpenSwipeable) {
            currentlyOpenSwipeable.recenter();
        }
    };

    tapOnTabNavigator = () => {
        if (this.scrollView) {
            this.scrollView.scrollTo({x: 0, y: 0, animated: true,useNativeDriver: false});
        }
    };

    render() {
        const {isLoading} = this.props;
        const {feeds} = this.props;
        const {currentlyOpenSwipeable,awaitUser} = this.state;
        //Trying to handle swipe - NOT WORKING GOOD AT ALL!
        const itemProps = {
            onOpen: (event, gestureState, swipeable) => {
                if (currentlyOpenSwipeable && currentlyOpenSwipeable !== swipeable) {
                    currentlyOpenSwipeable.recenter();
                }

                this.setState({currentlyOpenSwipeable: swipeable});
            },
            onClose: () => this.setState({currentlyOpenSwipeable: null})
        };

        return (
            <View style={{flex: 1}}>
                <StatusBarWrap backgroundColor={this.state.statusBarBackground}
                               barStyle={this.state.barStyle}/>
                <ScrollView
                    scrollEnabled={!(this.state.isSwiping)}
                    onScroll={this.handleScroll}
                    scrollEventThrottle={17} // for better performance
                    contentContainerStyle={[globalStyles.screenBottomPadding]}
                    ref={ref => (this.scrollView = ref)}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={this.onRefresh}
                        />
                    }
                >
                    <View style={[styles.gradient,{backgroundColor:this.themeColor}]}>
                        <View
                            style={[styles.header, globalStyles.marginTopBetweenElements, globalStyles.appPaddingHorizontal]}>
                            <TouchableOpacity style={[styles.headerButton]} onPress={() => {
                                this.props.navigation.navigate("FriendSearchScreen")
                            }}>
                                <Icons8Generator name={'search'} {...this.svg_props} width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} fill={this.props.whiteLabelProperties.primaryColorText}/>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.headerButton]} onPress={() => {
                                this.props.navigation.navigate("Notification")
                            }}>
                                <Icons8Generator name={'notification'} {...this.svg_props} width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} fill={this.props.whiteLabelProperties.primaryColorText}/>
                                <View style={[styles.notificationBadge,{backgroundColor: this.themeColor,borderColor:this.props.whiteLabelProperties.primaryColorText}]}>
                                    <AppText style={[styles.numberOfNotification,{color: this.props.whiteLabelProperties.primaryColorText}]}>{this.props.messagesCount}</AppText>
                                </View>
                            </TouchableOpacity>
                        </View>

                        {!this.props.isUserLoading && this.props.user.full_name ? (
                            <View style={[globalStyles.centerItems, globalStyles.marginFromStatusBar]}>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate("Profile")}>
                                    <UserAvatar size={scale(80)}
                                                key={this.imageIndex}
                                                name={this.props.user.full_name}
                                                src={this.props.user.image ? this.props.user.image : null}
                                    />
                                </TouchableOpacity>
                                <View>
                                    <AppText style={[globalStyles.heeboMedium, {
                                        fontSize: scale(16),
                                        textAlign: 'center',
                                        color: this.props.whiteLabelProperties.primaryColorText}, globalStyles.directionText, globalStyles.marginTopBetweenTextElements]}> {t('screens:Home:hey')} {this.props.user.first_name}</AppText>
                                    <AppText style={[globalStyles.heeboRegular, {
                                        textAlign: 'center',
                                        color: this.props.whiteLabelProperties.primaryColorText
                                    }, globalStyles.directionText]}>{t('screens:Home:welcomeTo', {boxName: this.props.whiteLabelProperties.displayName})}</AppText>
                                </View>
                            </View>
                        ) : null
                        }
                    </View>
                    <View style={{marginTop: -verticalScale(60)}}>
                        <FeedList isSwiping={this.isSwiping}
                                  swipping={this.state.isSwiping}
                                  isLoading={isLoading || awaitUser}
                                  itemProps={itemProps}
                                  refresh={this.onRefresh}
                                  feeds={feeds}/>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const mapActionsToProps = dispatch => {
    return bindActionCreators({
        showModal,
        fetchFeedData,
        fetchNotificationsCount,
        fetchProfile
    }, dispatch);
};

const mapStateToProps = state => ({
    ...state,
    user: state.user.data,
    isUserLoading: state.user.isLoading,
    isLoading: state.home.isLoading,
    feeds: state.home.data,
    messagesCount: state.home.messagesCount,
    whiteLabelProperties: state.whiteLabelProperties,
    modal: state.modal,
});

HomeScreen.propTypes = {
    navigation: PropTypes.object.isRequired,
    fetchNotificationsCount: PropTypes.func,
    user: PropTypes.object,
    fetchFeedData: PropTypes.func,
    fetchProfile: PropTypes.func,
    feeds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isLoading: PropTypes.bool,
    messagesCount: PropTypes.number,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(HomeScreen);

const styles = ScaledSheet.create({

    gradient: {
        flex: 1,
        height: verticalScale(260),
    },
    layout: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },

    header: {
        position: 'absolute',
        flexDirection: 'row',
        end: 0
    },
    headerIcon: {
        color: '#FFF',
        fontSize: '24@s',
        transform: [{
            scaleX: i18n.isRTL ? -1 : 1,
        }],
    },
    notificationBadge: {
        position: 'absolute',
        zIndex:100000,
        end: scale(-8),
        bottom:scale(0),
        height: scale(15),
        width: scale(15),
        borderWidth: '1@s',
        borderColor: Colors.white,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
    },
    numberOfNotification: {
        flexDirection: 'row',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0.5,
        top: 0.5,
        fontSize: scale(7),
        color: Colors.white,
    },
    headerButton: {
        width: scale(35),
        alignItems: 'flex-end',
    }
});