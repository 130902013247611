export const CONST = {
    QUESTION_TYPES: ["questionYesNo", "questionYesNoAdv", "questionMultipleChoice", "questionOpen"],
    SIGNATURE_OPTIONS_ARRAY: [{id: 1, name: 'digitalSignature'}, {id: 2, name: 'digitalSignatureMarkV'}],
    TYPE_PERSONAL_DETAILS: 'personalDetails',
    TYPE_CHILD_DETAILS: 'childDetails',
    TYPE_FORM_TYPES: 'formTypes',
    TYPE_SIGNED_PROPERTIES: 'signedProperties',
    TYPE_SIGN_OPTIONS: 'signOptions',
    TYPE_QUESTIONS: 'questions',
    TYPE_PICKERS: ['birthday', 'signatureDate']

};


export const DISPLAYABLE_PROPERTIES_TYPE = [
    CONST.TYPE_QUESTIONS, CONST.TYPE_PERSONAL_DETAILS, CONST.TYPE_SIGNED_PROPERTIES
];
