import React from 'react';
import {View, StyleSheet, TouchableOpacity, Dimensions, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import FitImage from 'react-native-fit-image';
import {MEMBERSHIP_TYPES_CONST} from "../../../constants/membershipTypes";
import {AppText} from "../../Stateless/AppText";
import {globalStyles} from "../../../styles/Global";
import {scale, verticalScale} from 'react-native-size-matters';
import {withNavigation} from 'react-navigation';
import Price from "../Price/index";
import {Colors} from "../../../styles/Colors";
import {TabBarCustom} from "../../Stateless/TabBarCustom";
import {TabView} from 'react-native-tab-view';
import {t} from "../../../services/i18n";
import uuid from 'uuid';
import {
    ICON_SIZE_85,
} from "../../../helpers/functions";

import {
    MembershipCard,
    MembershipDeparments, ProductImageOrIcon,
} from "../../Stateless/MembershipDisplayer";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {IconSize} from "../../../constants/iconSize";


@withNavigation
class MembershipAndItemsWrapper extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            index: 0,
            routes: [{key: MEMBERSHIP_TYPES_CONST.TYPE_PLAN, title: t('screens:Shop:membershipsTab', {})},
                {key: MEMBERSHIP_TYPES_CONST.TYPE_SESSION, title: t('screens:Shop:sessionsTab', {})},
            ],
        }
    }

    async componentDidMount() {
    }


    render() {
        if (this.props.selectedType === MEMBERSHIP_TYPES_CONST.SELECT_TYPE_ITEMS)
            return this.itemsLayout();
        return this.membershipsLayout();
    }

    itemsLayout() {
        return (
            <ScrollView contentContainerStyle={[{flexGrow: 1}]}>

                <View
                    style={[globalStyles.appMinimalPaddingHorizontal, {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                    }]}>
                    {
                        this.props.items.item.map((membership, index) => {
                            return (
                                <View style={[{width: scale(160), marginTop: scale(15)}]} key={index}>
                                    <TouchableOpacity
                                        onPress={() => this.props.navigation.navigate("Product", {membership: membership})}
                                        style={{borderRadius: 100}}>
                                        <View
                                            style={[globalStyles.cardsShadow, styles.iconOrImageWrap, globalStyles.centerItems]}>
                                            <ProductImageOrIcon membership={membership}
                                                                size={IconSize.SPECIAL_SIZE_100}/>
                                        </View>
                                    </TouchableOpacity>
                                    <AppText
                                        style={[globalStyles.heeboMedium, globalStyles.marginTopSm]}>{membership.name}</AppText>
                                    <View style={{marginBottom: verticalScale(5)}}>
                                        <MembershipDeparments membership={membership}/>
                                    </View>
                                    <Price membership={membership}/>
                                </View>
                            )
                        })}
                </View>
            </ScrollView>
        );

    }

    membershipsLayout() {
        const {index, routes} = this.state;
        if (this.props.items[MEMBERSHIP_TYPES_CONST.TYPE_SESSION].length === 0 || this.props.items[MEMBERSHIP_TYPES_CONST.TYPE_PLAN].length === 0) {
            //Here we know we DO NOT need TAB VIEW
            if (this.props.items[MEMBERSHIP_TYPES_CONST.TYPE_SESSION].length === 0)
                return this.handleTab(MEMBERSHIP_TYPES_CONST.TYPE_PLAN);
            return this.handleTab(MEMBERSHIP_TYPES_CONST.TYPE_SESSION);
        }
        const initialLayout = {
            height: 0,
            width: Dimensions.get('window').width,
        };
        return (
            <TabView
                navigationState={{index, routes}}
                initialLayout={initialLayout}
                renderScene={({route, focused}) => {
                    switch (route.key) {
                        default:
                            return this.handleTab(route.key);
                    }
                }}
                onIndexChange={i => this.setState({index: i})}
                renderTabBar={(props) => <TabBarCustom {...props}
                                                       numOfItems={2}
                                                       topBorder={false}
                                                       backgroundColor={Colors.white}
                                                       indicatorColor={this.props.whiteLabelProperties.primaryColor}
                                                       overrideColorOnFocus={{color: this.props.whiteLabelProperties.primaryColor}}
                                                       textProps={{fontSize: scale(12), color: Colors.black}}/>}
            />
        )
    }

    handleTab(key) {
        const data = this.props.items[key];
        const color = key === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? Colors.brandedGreen : Colors.brandedBlue;
        const icon = key === MEMBERSHIP_TYPES_CONST.TYPE_PLAN ? 'membership-card' : 'two-tickets';
        return (
            <ScrollView contentContainerStyle={[{flexGrow: 1}]}>
                <View style={[globalStyles.marginTopLarge, globalStyles.appMinimalPaddingHorizontal]}>
                    {data.map((membership) => {
                        return (
                            <MembershipCard key={uuid.v4()}
                                            onPress={() => this.props.navigation.navigate("Product", {membership: membership})}
                                            color={color} icon={icon} type={key} membership={membership}/>
                        )
                    })}
                </View>
            </ScrollView>
        )
    }

}


const mapActionsToProps = dispatch => {
        return bindActionCreators(
            {},
            dispatch,
        );
    }
;

const mapStateToProps = state => (
    {
        items: state.shop.items,
        whiteLabelProperties: state.whiteLabelProperties,
    }
);

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(MembershipAndItemsWrapper);


const styles = StyleSheet.create(
    {

        membershipCard: {
            backgroundColor: '#EFFFF9',
            width: '25%',
        },
        iconOnCard: {
            transform: [{rotate: '-45deg'}],
            position: 'absolute',
            left: -10,
            bottom: -12,
            zIndex: 10,
            elevation: 10
        },
        iconOrImageWrap: {
            width: scale(160),
            height: scale(160),
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: Colors.white,
        }
    });