// @flow
import * as React from 'react';
import {View} from "react-native-web";
import {Icons8Generator} from "../../Stateless/Icons8Generator";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";
import {t} from "../../../services/i18n";
import EStyleSheet from "react-native-extended-stylesheet";
import {Platform} from "react-native";

const ShopEmptyState = (props) => {
    return (
        <View style={[styles.emptyState]}>
            <Icons8Generator name={'shopaholic'} size={'10em'} fill={Colors.separatorCircleGray}/>
            <AppText style={[{fontSize: '0.9rem'}]}>{t('screens:Logbook:noResultsFound',{})}</AppText>
        </View>
    );
};
export default ShopEmptyState;

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            emptyState: {
                margin: 'auto',
                alignItems: 'center',
                gap: '2em'
            }
        }
    })
})