import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';

const styles = StyleSheet.create({
  containerFlex: {
    flex: 1,
    backgroundColor: Colors.tabBar
  },
  containerFlexCentered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabIndicator: {
    backgroundColor: Colors.tintColor,
  },
  tabBackground: {
    backgroundColor: 'transparent',
  },
});

export default styles;

