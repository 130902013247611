import React from 'react';
import { TouchableOpacity, View, Text,StyleSheet } from 'react-native';
import {scale,vericalScale} from "react-native-size-matters";
import { Icon } from 'native-base';
import {AppText} from "../Stateless/AppText";
import {Colors} from "../../styles/Colors";
import {isTextRTLStyles} from "../../styles/Layout";
import {t} from "../../services/i18n";
import date from '../../services/i18n/date';
import {globalStyles} from "../../styles/Global";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {getThemeProperty, keys} from "../../styles/Theme";
import {PayForSlotCard} from "../Shared/PayForSlotCard";
import {MEMBERSHIP_TYPES_CONST} from "../../constants/membershipTypes";
import {isWeb} from "../../helpers/functions";

class CheckBox extends React.Component {

  getMembershipData(){
    const {data} = this.props;
    let endDate,text = null;
    if(data.active === 2){
          text = t('screens:ClubProfile:membershipStartsAt', {startDate: date.format(data.start, 'LL')});
    }else if(data.membership_types && data.membership_types.type === 'session'){
      const session = t('screens:ClubProfile:membershipListSessionsLeft',{sessionsLeft: data.sessions_left});
        if(data.end){
            endDate = `, ${t('screens:ClubProfile:membershipEndsAt', {endDate: date.format(data.end, 'LL')})}`;
        }
        text = [session,endDate];
    }else{
        if(data.end){
            text = t('screens:ClubProfile:membershipEndsAt', {endDate: date.format(data.end, 'LL')});
        }else{
            text = t('screens:ClubProfile:membershipNoEndDate', {});
        }
    }
    return text;
  }

  componentDidMount(){
  }

  render() {
    const { check, checked, label, active, index, data,id} = this.props;
    if(data.type === MEMBERSHIP_TYPES_CONST.TYPE_SERVICE)
        return (
            <PayForSlotCard
                onPress={() => check(index)}
                checked={checked}
                membershipType={data}
            />
        )
    return (
      <TouchableOpacity onPress={() => check(index, id)}>
        <View style={[styles.checkBoxWrapper, checked ? styles.itemChecked : {}, isWeb() && {marginVertical: '0.3em', paddingVertical: '0.5em'}]}>
          {checked ? (
            <View style={styles.checkmarkWrapper}>
                <Icons8Generator name={'check-circle'} fill={Colors.green}/>
            </View>
          ) : null}
          <View style={styles.item}>
            <AppText style={[globalStyles.heeboRegular,styles.label, isWeb() && {fontSize: '0.85rem'}]}>{label}</AppText>
              <AppText style={[globalStyles.paddingStart,globalStyles.heeboRegular,styles.active, isWeb() && {fontSize: '0.85rem'}]}>{active === 2 ? `(${t('common:membership:status2',{})})` : null}</AppText>
          </View>
            <View style={styles.item}>
                <AppText style={[globalStyles.heeboLight,globalStyles.smallTitle, isWeb() && {fontSize: '0.85rem'}]}>{this.getMembershipData()}</AppText>
            </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default CheckBox;


const styles = StyleSheet.create({
    checkBoxWrapper: {
        position: 'relative',
        borderWidth: 1,
        borderColor: Colors.lightGrey,
        paddingHorizontal: scale(15),
        width: '100%',
        paddingVertical: scale(8),
        marginVertical: scale(5),
        borderRadius: 2,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemChecked: {
        borderColor: Colors.brandSuccess,
        backgroundColor: Colors.viewBackground,
    },
    checkmarkWrapper: {
        backgroundColor: '#FFFFFF',
        top: scale(-5),
        right: scale(-5),
        position: 'absolute',
    },
    checkmark: {
        fontSize: scale(15),
        color: Colors.brandSuccess,
    },
    label: {
        fontSize: scale(15),
    },
    active: {
        fontSize: scale(12),
        color: Colors.futureColor,
    },
});