import * as React from 'react';
import PropTypes from 'prop-types';
import * as Linking from 'expo-linking';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import {scale} from 'react-native-size-matters';
import {Colors} from '../../styles/Colors';
import {withNavigation} from 'react-navigation';
import {t} from '../../services/i18n';
import i18n from "../../services/i18n";
import {globalStyles} from "../../styles/Global";
import {FitImageTextRow} from "../Stateless/FitImageTextRow";
import {Icons8Generator} from "../Stateless/Icons8Generator";
import {EXTRA_SM_ICON_SIZE, MEDIUM_ICON_SIZE} from "../../helpers/functions";


// TODO: mocked onPress callClub/gotoClub
class ClubsList extends React.Component {


    constructor(props) {
        super(props);
        this.callClub = this.callClub.bind(this);
        this.goToClub = this.goToClub.bind(this);
    }

    callClub(phone) {
        const phoneString = phone.replace(/[ -]/g, '');
        Linking.openURL(`tel://${phoneString}`);
    }

    goToClub(club) {
        this.props.navigation.navigate("ClubProfileScreen", {club});
    }

    renderRowContent(club, borderBottomStyle) {
        const image = club.box.cloudinary_image ? club.box.cloudinary_image : club.locations_box.logo;
        return (
            <TouchableOpacity style={[borderBottomStyle, globalStyles.textImageContainer]}
                              onPress={() => this.goToClub(club)}>
                <FitImageTextRow
                    imageName={club.box.name}
                    imageSize={40}
                    src={image}
                    upperText={club.box.name}
                    bottomText={club.active ? t('screens:Profile:active', {}) : t('screens:Profile:inactive', {})}
                    bottomTextStyles={{color: club.active ? Colors.brandSuccess : Colors.red}}
                />
                <View style={styles.actionWrapper}>
                    <TouchableOpacity disabled={!club.box.phone}
                                      onPress={() => Linking.openURL(`tel://${club.box.phone}`)}>
                        <Icons8Generator name={'phone-out'} width={MEDIUM_ICON_SIZE} height={MEDIUM_ICON_SIZE}/>
                    </TouchableOpacity>
                    <View style={globalStyles.paddingStart}>
                        <Icons8Generator style={globalStyles.paddingStart}  width={EXTRA_SM_ICON_SIZE} height={EXTRA_SM_ICON_SIZE} name={i18n.isRTL ? 'arrow-rtl' : 'arrow'}/>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }

    renderListElement(club, index) {
        let borderBottomStyle = null;
        if (this.props.list.length - 1 === index) {
            borderBottomStyle = styles.bottomBorderRadius;
        }
        return (
            <View style={[borderBottomStyle]} key={club.box.id}>
                {this.renderRowContent(club, borderBottomStyle)}
            </View>
        );
    }

    render() {
        const {list} = this.props;
        return (
            <View style={styles.listWrapper}>
                {list.map((club, index) => this.renderListElement(club, index))}
            </View>
        );
    }
}

ClubsList.propTypes = {
    list: PropTypes.instanceOf(Array),
};

export default withNavigation(ClubsList);

const styles = StyleSheet.create({
    listWrapper: {backgroundColor: 'white'},
    listElement: {
        paddingHorizontal: scale(16),
        backgroundColor: Colors.white,
        paddingVertical: scale(16),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    clubWholeWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    clubInfoWrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: scale(10)
    },
    nameWrapper: {
        flexDirection: 'row'
    },
    name: {
        fontSize: scale(16),
        marginBottom: scale(3),
    },
    clubWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    clubName: {
        color: Colors.brandPrimary,
        letterSpacing: scale(0.38),
        fontSize: scale(11),
    },
    clubIcon: {
        marginRight: scale(2)
    },
    actionWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    itemSeparator: {
        width: '100%',
        height: scale(1),
        backgroundColor: '#fafafa'
    },
    emptyList: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    actionButton: {
        borderRadius: 100,
        width: scale(35),
        height: scale(35),
        paddingVertical: scale(11),
        paddingHorizontal: scale(12),
        alignItems: 'center',
        justifyContent: 'center'
    },
    expandButton: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: scale(15)
    },
    expandButtonCircle: {
        marginRight: scale(10),
        borderRadius: 100,
        padding: scale(7),
        backgroundColor: `${Colors.brandPrimary}33`, // new 8-bit hex color with alpha notation
        alignItems: 'center',
        justifyContent: 'center'
    },
    expandButtonText: {
        textTransform: 'uppercase',
        fontSize: scale(16)
    },
    expandButtonTextCircle: {
        color: Colors.brandPrimary
    },

    clubImagePlaceholder: {
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        width: scale(40),
        height: scale(40),
        backgroundColor: Colors.clubPlaceholderColor
    },
    bottomBorderRadius: {
        borderBottomLeftRadius: scale(8),
        borderBottomRightRadius: scale(8)
    },
    arrowIcon: {
        transform: [{
            scaleX: i18n.isRTL ? -1 : 1,
        }],
    },
    boxContactsIcons: {
        paddingHorizontal: scale(10),
        color: Colors.black,
        fontSize: scale(20)
    },
});

