import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgIcons8PartyBalloon(props) {
  return (
    <Svg viewBox="0 0 50 50" fill="#18181b" width="1em" height="1em" {...props}>
      <Path d="M25 2c-7.168 0-13 5.832-13 13 0 3.582 1.383 7.95 3.656 11.531 1.918 3.02 4.524 5.532 7.594 6.25L22.5 35h1.188c-.04.121-.083.254-.125.375-.239.684-.563 1.441-.563 2.438 0 1.273.594 2.355 1.094 3.343.5.989.906 1.887.906 2.344 0 1.973-.781 2.875-.781 2.875a1 1 0 101.562 1.25S27 46.059 27 43.5c0-1.242-.598-2.266-1.094-3.25-.496-.984-.906-1.898-.906-2.438 0-.57.18-1.042.438-1.78.105-.302.214-.645.312-1.032h1.75l-.75-2.219c3.07-.718 5.676-3.23 7.594-6.25C36.617 22.95 38 18.582 38 15c0-7.168-5.832-13-13-13zm0 2c6.086 0 11 4.914 11 11 0 3.043-1.273 7.176-3.344 10.438C30.586 28.698 27.793 31 25 31c-2.793 0-5.586-2.3-7.656-5.563C15.274 22.177 14 18.044 14 15c0-6.086 4.914-11 11-11z" />
    </Svg>
  );
}

export default SvgIcons8PartyBalloon;
