// @flow
import * as React from 'react';
import {ActivityIndicator, Platform, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {useEffect, useState} from "react";
import {fetchTodaySchedules} from "../../../../actions/schedule";
import {useDispatch, useSelector} from "react-redux";
import ClassItem from "../Desktop/ClassItem";
import EmptyState from "../Desktop/EmptyState";
import {Colors} from "../../../../styles/Colors";
import {toggleScheduleSiteReFetch} from "../../../../actions/minisite";
import {fetchCurrencySymbol} from "../../../../actions/shop";


const SingleDayContainer = (props) => {
    const {date, tabsDisplayed} = props;
    const [classes, setClasses] = useState(null);
    const [fetching, setFetching] = useState(false);
    const loggedIn = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.auth.loggedIn)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const reFetch = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteReFetch)
    const dispatch = useDispatch();
    let styles = getStyle(tabsDisplayed)

    useEffect(() => {
        if(reFetch) {
            fetchDateClasses()
            dispatch(toggleScheduleSiteReFetch(false));
        }
    }, [reFetch]);

    useEffect(() => {
        if(date && location) {
            fetchDateClasses()
        }
    }, [date, location,loggedIn]);

    const fetchDateClasses = async () => {
        setFetching(true)
        setClasses(null)
        const classes = await dispatch(fetchTodaySchedules(date, location.id, null, loggedIn));
        setClasses(classes.data)
        setFetching(false)
    }

    return (
        <View style={[styles.wrapper, (fetching || classes && classes.length === 0) && styles.emptyWrapper]}>
            {fetching && <ActivityIndicator size="large" color={Colors.brandedBlue}/>}
            {
                classes ?
                    classes.length > 0 ?
                    classes.map(classItem => <ClassItem key={`class-item-mobile-${classItem.id}`} classInfo={classItem}/>)
                    :
                    <EmptyState isRestricted={false}/>
                    : null
            }
        </View>
    );
};
export default SingleDayContainer;

let getStyle = function (tabsDisplayed= true) {
    return EStyleSheet.create({
        ...Platform.select({
            web: {
                wrapper: {
                    flexGrow: 1,
                    gap: '0.8em',
                    paddingHorizontal: '0.7em',
                    maxHeight: () => `100% - ${parseInt(EStyleSheet.value('$calendarStripHeight')) + parseInt(tabsDisplayed ? EStyleSheet.value('$tabsMaxHeight') : 0) + parseInt(EStyleSheet.value('$mobileHeader'))}`,
                    height: '100%',
                    overflow: 'auto',

                },
                emptyWrapper: {
                    alignItems: 'center',
                    justifyContent: 'center'
                },
            }
        })
    })
}