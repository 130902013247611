// @flow
import * as React from 'react';
import {View} from "react-native";
import User from "../../../svg/Icons8MaleUser";
import {Colors} from "../../../styles/Colors";
import {AppText} from "../../Stateless/AppText";

export const DesktopAvatarImg = (props) => {
    const {imgSrc, size, credentials} = props;
    return (
        <>
            {imgSrc ?
                <img style={{width: size, height: size, borderRadius: '50%'}} src={imgSrc}/>
                :
                <View style={{ alignItems: 'center', justifyContent: 'center', width: size, height: size, borderRadius: '50%', backgroundColor: Colors.brandedBlue}}>
                    <AppText style={{color:Colors.white, fontSize: '0.7rem', fontWeight: 'bold'}}>{credentials}</AppText>
                </View>
            }
        </>
    );
};