import React from 'react';
import {View} from 'react-native';
import MoreSectionListItem from '../../components/MoreSectionListItem';
import {Colors} from '../../styles/Colors';
import {t} from '../../services/i18n';
import HeaderWrapper from "../../components/HeaderWrapper";
import {StatusBarWrap} from "../../components/Stateless/StatusBarWrap";
import {globalStyles} from "../../styles/Global";
import {connect} from "react-redux";


class MoreScreen extends React.PureComponent {
    state = {
        statusbarF: true,
        hasLogbook: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        const hasLogbook = this.props.user.users_boxes.some(ub => {
            if (ub.locations_box.disable_pages_app) {
                return ub.locations_box.disable_pages_app.some(obj => {
                    return obj.area.toLowerCase() === 'sidebar' && obj.section_name.toLowerCase() === 'logbook';
                })
            }
        });
        this.setState({hasLogbook});
        this.focusListener = this.props.navigation.addListener("didFocus", () => {
            this.setState({statusbarF: true})
        });
    };

    componentWillUnmount = async () => {
        this.focusListener.remove();
        // this.blurListener.remove();
    }

    onLogout() {
        this.props.logoutUser();
    }

    gotoClubsScreen() {
        this.props.navigation.navigate('Clubs');
    }

    gotoLogbookScreen() {
        this.props.navigation.navigate('Logbook')
    }

    gotoSettingsScreen() {
        this.props.navigation.navigate('Settings')
    }

    render() {

        return (
            <React.Fragment>
                <StatusBarWrap/>
                <HeaderWrapper
                    headerTitle={t('screens:More:title', {})}
                    headerArrowColor={this.props.whiteLabelProperties.primaryColorText}
                    padding={globalStyles.appPaddingHorizontal}
                    noArrow={true}
                    isPop={true}
                />
                <View>
                    <MoreSectionListItem name={'clubs'} txt={t('screens:More:clubs:title', {})} width={23} height={24}
                                         onPress={() => this.gotoClubsScreen()}/>
                    {this.state.hasLogbook ? (
                        <MoreSectionListItem txt={t('screens:More:logbook', {})} name={'logbook'} width={23} height={23}
                                             onPress={() => this.gotoLogbookScreen()}/>) : null}
                    <MoreSectionListItem name={'settings'} txt={t('screens:More:settings:button')} width={19}
                                         height={20} onPress={() => this.gotoSettingsScreen()}/>
                    <MoreSectionListItem name={'logout'} txt={t('screens:More:logout')} width={19} height={18}
                                         onPress={() => this.onLogout()}/>
                </View>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user.data,
    whiteLabelProperties: state.whiteLabelProperties
});


export default connect(
    mapStateToProps,
    null,
)(MoreScreen);

