// @flow
import * as React from 'react';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ScheduleContainer} from "./ScheduleContainer";
import ScheduleOptions from "./ScheduleOptions";
import ScheduleWeekView from "../../../../svg/custom/ScheduleWeekView";
import {t} from "../../../../services/i18n";
import Clock from "../../../../svg/custom/Clock";
import Workshop from "../../../../svg/custom/Workshop";
import AppointmentHandler from "../../Appointment/AppointmentHandler";
import WorkshopPage from "../../Workshop/WorkshopPage";
import {COACH_AVAILABILITY_TYPE} from "../../../../constants";

export const ScheduleHandler = (props) => {
    const scheduleTypes = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data?.scheduleTypes)
    const box = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const [pageComponent, setPageComponent] = useState(null);

    useEffect(() => {
        if(scheduleTypes) {
            renderPage()
        }
    }, [scheduleTypes]);

    const showSchedule = () => {
        setPageComponent(<ScheduleContainer onBackPress={renderPage}/>)
    }

    const showAppointment = () => {
        setPageComponent(<AppointmentHandler onBackPress={renderPage}/>)
    }

    const showWorkshops = () => {
        setPageComponent(<WorkshopPage onBackPress={renderPage}/>)
    }

    const renderPage = () => {
        const scheduleType = params?.scheduleType
        const hasAvailability = box.locations_box.filter(location => location.hasAvailability > 0);
        const hasWorkshops = box.locations_box.filter(location => location.hasWorkshops > 0);
        const hasSpaceAvailability = box.locations_box.filter(location => location.hasSpaceAvailability > 0);
        const components = getComponentsArray(scheduleTypes.hasClasses, hasAvailability.length, hasWorkshops.length, hasSpaceAvailability.length)
        setPageComponent(renderSpecificScheduleType(scheduleType, scheduleTypes.hasClasses, hasAvailability.length, hasWorkshops.length, components, hasSpaceAvailability.length))
        if(components.length === 1) {
            components[0].onCardPress()
        }
    }

    const getComponentsArray = (classesCount, availabilityCount, workshopCount, spaceAvailabilityCount) => {
        let components = []

        if(classesCount > 0) {
            components.push(
                {
                    img: ScheduleWeekView,
                    label: t('screens:Schedule:see-schedule', {}),
                    onCardPress: () => showSchedule()
                })
        }
        if(availabilityCount > 0 || spaceAvailabilityCount > 0) {
            components.push(
                {
                    img: Clock,
                    label: t('screens:Schedule:book-appointment', {}),
                    onCardPress: () => showAppointment()
                }
            )
        }
        if(workshopCount > 0) {
            components.push(
                {
                    img: Workshop,
                    label: t('screens:Schedule:book-workshop', {}),
                    onCardPress: () => showWorkshops()
                }
            )
        }
        return components;
    }

    const renderSpecificScheduleType = (scheduleType, classesCount, availabilityCount, workshopCount, components, spaceAvailabilityCount) => {
        let optionsScreen = <ScheduleOptions options={components}/>;
        switch (scheduleType) {
            case 'schedule':
                return classesCount > 0 ? <ScheduleContainer/> : optionsScreen;
            case 'appointment':
                return (availabilityCount > 0 || spaceAvailabilityCount > 0) ? <AppointmentHandler urlParamCategory={params}/> : optionsScreen;
            case 'workshops':
                return workshopCount > 0 ? <WorkshopPage /> : optionsScreen;
            default:
                return optionsScreen
        }
    }

    return (
        <>
            {pageComponent}
        </>
    );
};
