import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {scale, verticalScale, ScaledSheet} from 'react-native-size-matters';
import {t} from '../../services/i18n';
import SvgEmpty from "../../svg/custom/Empty";
import {AppText} from "../Stateless/AppText";
import {globalStyles} from "../../styles/Global";
import NavigationService from "../../helpers/NavigationService";

class HomeEmptyComponent extends React.PureComponent {
    render() {
        return (

            <View style={styles.flexContainerCentered}>
                <View>
                    <SvgEmpty width={scale(100)} height={verticalScale(75)}/>
                </View>
                <AppText
                    style={[globalStyles.heeboMedium, globalStyles.marginTopBetweenElements]}>{t('screens:Home:feedEmptyTitle', {})}</AppText>
                <TouchableOpacity onPress={() => NavigationService.navigate('Schedule')}>
                    <AppText style={[globalStyles.marginTopSm,globalStyles.textCenter]}>
                        {t('screens:Home:feedEmptyMessage', {})}
                    </AppText>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = ScaledSheet.create({
    flexContainerCentered: {
        alignItems: 'center',
        marginTop: verticalScale(100),
    },

});

export default HomeEmptyComponent;
