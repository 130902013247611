import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {cleanupIsraelPaymentRes, updateIsraelPaymentRes} from "../../actions/minisite";
import {useLocation} from "react-router";
import {store} from "../../config/store";


//TODO Handle errors? - May be think of a more dynamic way to get keys
const IFrameResponse = (props) => {
    const storedItem = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier].israelPaymentRes)
    const { search } = useLocation();

    useEffect(() => {
        let url = new URLSearchParams(search);
        let params = Object.fromEntries(new URLSearchParams(location.search));
        if (storedItem != null) {
            switch (storedItem.payment_service.toLowerCase()) {
                case 'pelecard':
                    params.id = url.get("Token");
                    break;
                case 'icredit':
                    params.id = storedItem.CustomerTransactionId;
                    break;
                default:
                    sendMessage(params, 'create_token_error')
                    break;
            }
            sendMessage(params, 'create_token_success')

        } else {
            sendMessage(params, 'create_token_error')
        }

    }, [])

    const sendMessage = (params, msg = '') => {
        window.parent.window.postMessage(
            {
                channel: 'arbox',
                action: 'payments_wrap',
                payload: params || {},
                message: msg
            },
            '*'
        );
    }
    return null;
}


export default IFrameResponse;
