// @flow
import * as React from 'react';
import {Platform, StyleSheet, View} from "react-native";
import ForgotPasswordScreen from "../ForgotPasswordScreen";
import {
    EXTRA_HUGE_ICON_SIZE,
    getToastDefaultConfig,
    HUGE_ICON_SIZE,
    isMobile,
    LARGE_BETWEEN_HUGE_ICON_SIZE
} from "../../helpers/functions";
import {Colors} from "../../styles/Colors";
import PopupModal from "../../components/Web/Common/PopupModal";
import {Icons8Generator} from "../../components/Stateless/Icons8Generator";
import {AppText} from "../../components/Stateless/AppText";
import {t} from "../../services/i18n";
import TextInputField from "../../components/Web/Common/TextInputField";
import {useState} from "react";
import ModalButton from "../../components/ModalButton";
import {useSelector} from "react-redux";
import {apiAction} from "../../helpers/HTTP";
import {email as notEmail, validateAfterEmailSymbol} from "../../helpers/Validators";
import Toast from "react-native-root-toast";
import EStyleSheet from "react-native-extended-stylesheet";
import {scale} from "react-native-size-matters";
import Spinner from "react-native-loading-spinner-overlay";
import ErrorModal from "../../components/Web/Common/ErrorModal";
import {globalSiteStyles, globalStyles} from "../../styles/Global";

const ForgotPasswordScreenWeb = (props) => {
    const {show, setClose, emailExternal} = props
    const [email, setEmail] = useState(emailExternal ? emailExternal : '');
    const whiteLabelProperties = useSelector(state => state.whiteLabelProperties)
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const mobile = isMobile()

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            if(email && email !== '' && !notEmail(email) && !validateAfterEmailSymbol(email)) {
                setInvalidEmailError(false);
                const res = await apiAction(`user/resetPassword`, 'post', {'email': email});
                setSubmitting(false)
                setEmailSent(true)
            } else {
                setInvalidEmailError(true);
            }
        } catch (error) {
            setSubmitting(false)
            setError(error.error)
        }
    }

    return (
        <>
            <View style={styles.wrapper}>
                <Spinner visible={submitting}/>
                <View style={styles.wrapper}>
                    <Icons8Generator name={'unlock'} size={mobile ? EXTRA_HUGE_ICON_SIZE : LARGE_BETWEEN_HUGE_ICON_SIZE} fill={Colors.black}/>
                    <AppText style={mobile ? styles.textMobile : styles.text}>{t(emailSent ? 'screens:ForgotPassword:success' : 'screens:ForgotPassword:title', {})}</AppText>
                    <AppText style={[styles.explain, mobile ? styles.explainMobile : null, {marginBottom: '2em'}]}>{t(emailSent ? 'screens:ForgotPassword:success-explain' : 'screens:ForgotPassword:explain', {email: email})}</AppText>
                    {
                        emailSent ?
                            <View style={{marginBottom: '1em'}}>
                                <View style={styles.separator}></View>
                                <View style={{alignItems: 'start', gap: '0.5em', marginVertical: '1.5em'}}>
                                    <AppText style={mobile ? styles.explainMobile : styles.explain}>{t('screens:ForgotPassword:cant-find-it', {})}</AppText>
                                    <View style={styles.checkRow}>
                                        <Icons8Generator name={'check'} size={'1em'}/>
                                        <AppText style={mobile ? styles.explainMobile : styles.explain}>{t('screens:ForgotPassword:right-email', {})}</AppText>
                                    </View>
                                    <View style={styles.checkRow}>
                                        <Icons8Generator name={'check'} size={'1em'}/>
                                        <AppText style={mobile ? styles.explainMobile : styles.explain}>{t('screens:ForgotPassword:check-spam', {})}</AppText>
                                    </View>
                                </View>
                            </View>
                            :
                            <>
                                <TextInputField isPassword={false} nameLabel="Email" placeHolder="Your Email" value={email} keyboardType={'email'} onChange={setEmail} overrideStyles={{'width':'100%'}}/>
                                {invalidEmailError && <AppText style={styles.error}>{t('common:validators:emailInvalid', {})}</AppText>}
                            </>
                    }
                </View>
                <ModalButton onPress={emailSent ? setClose : handleSubmit}
                             text={t(emailSent ? 'screens:ForgotPassword:go-to-login' : 'screens:ForgotPassword:button', {})}
                             overrideStyle={[globalSiteStyles(mobile).siteBlackBtn, styles.btn]}
                             colors={Colors.white}
                             type='primary'
                             textColor={Colors.lightBlack}
                             textStyles={{
                                 'fontFamily': 'heebo',
                                 'fontSize': '0.9rem',
                             }}
                />
            </View>
            {error && <ErrorModal show={error !== null}
                                               setClose={() => setError(null)}
                                               title={t('screens:ForgotPassword:mail-send-fail', {})}
                                               subHeader={t('screens:ForgotPassword:mail-send-fail-explain', {})}
            />}
        </>
    );
};

export default ForgotPasswordScreenWeb

const styles = EStyleSheet.create({
    ...Platform.select({
        web: {
            modal: {
                paddingHorizontal: '2em',
                paddingVertical: '2em',
                width: '20em',
                height: 'fit-content',
            },
            wrapper: {
                alignItems: 'center',
                width: '100%',
                boxSizing: 'border-box',
                // flex: 1
            },
            text: {
                fontSize: '1.3rem',
                marginVertical: '1.2em',
                fontFamily: 'coolvetica-rg',
                textAlign: 'center'
            },
            textMobile: {
                fontSize: '1rem',
                marginVertical: '1.5em',
                fontFamily: 'coolvetica-rg',
                textAlign: 'center'

            },
            btn: {
                width: '100%',
                marginTop: 0,
            },
            error: {
                color: Colors.red,
                fontSize: '0.8rem'
            },
            explain: {
                fontSize: '0.8rem',
                fontWeight: '300',
                textAlign: 'center'
            },
            explainMobile: {
                fontSize: '0.9rem',
            },
            checkRow: {
                flexDirection: 'row',
                gap: '1em'
            },
            separator: {
                height: 1,
                width: '100%',
                backgroundColor: Colors.graySeparator,
            }
        }
    })
})
