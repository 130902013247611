// @flow
import * as React from 'react';
import GroupSession from "./GroupSession";
import CustomTabs , {Tab} from "../../Common/CustomTabs";
import {View} from "react-native";
import {t} from "../../../../services/i18n";
import AppointmentHandler from "../../Appointment/AppointmentHandler";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateHeaderConfig} from "../../../../actions/minisite";
import {
    BOOK_APPOINTMENT_PROCESS,
    CHOOSE_SERVICE_STEP,
    CHOOSE_SLOT_STEP,
    GROUP_SESSION_PROCESS
} from "../../../../constants";
import {Colors} from "../../../../styles/Colors";
import ScheduleOptions from "../Desktop/ScheduleOptions";
import WorkshopPage from "../../Workshop/WorkshopPage";
import ScheduleWeekView from "../../../../svg/custom/ScheduleWeekView";
import Clock from "../../../../svg/custom/Clock";
import Workshop from "../../../../svg/custom/Workshop";
import uuid from "uuid";

export const ScheduleContainer = (props) => {
    const scheduleTypes = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.box.data?.scheduleTypes)
    const location = useSelector(state => state.minisite.boxes[state.minisite.activeIdentifier]?.siteSelectedLocation)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const [selectedTabName, setSelectedTabName] = useState(null);
    const [pageComponent, setPageComponent] = useState(null);

    useEffect(() => {
        handleDeepLink()
    }, []);


    useEffect(() => {
        if(scheduleTypes) {
            renderPage()
        }
    }, [scheduleTypes, location]);

    const handleDeepLink = () => {
        const scheduleType = params?.scheduleType
        if(scheduleType === 'schedule') {
            setSelectedTabName(t('screens:Schedule:group-session', {}))
        } else if(scheduleType === 'appointment') {
            setSelectedTabName(t('screens:Schedule:book-a-session', {}))
        }else if(scheduleType === 'workshops') {
            setSelectedTabName(t('screens:Schedule:book-workshop', {}))
        }
    }

    const renderPage = () => {
        const components = getComponentsArray(scheduleTypes.hasClasses, location.hasAvailability, location.hasWorkshops, location.hasSpaceAvailability);
        if(components.length > 1) {
            const tabs =
                <>
                    <CustomTabs backgroundColor={Colors.white} selectedTabName={selectedTabName}>
                        {
                            components.map(tab => <Tab name={tab.name} key={uuid.v4()}>{tab.component}</Tab>)
                        }
                    </CustomTabs>
                </>
            setPageComponent(tabs);
        } else if(components.length === 1) {
            setPageComponent(components[0].component);
        } else {
            setPageComponent(null);
        }
    }

    const getComponentsArray = (classesCount, availabilityCount, workshopCount, spaceAvailabilityCount) => {
        const showTabs = [classesCount, availabilityCount, workshopCount, spaceAvailabilityCount].filter(num => num > 0).length > 1; //count how many tabs. if bigger then 1 -> true, else -> false.
        let components = [];
        if(classesCount > 0) {
            components.push({
                name: t('screens:Schedule:group-session', {}),
                component: <GroupSession tabsDisplayed={showTabs}/>
            })
        }
        if(availabilityCount > 0 || spaceAvailabilityCount > 0) {
            components.push({
                name: t('screens:Schedule:book-a-session', {}),
                component: <AppointmentHandler tabsDisplayed={showTabs} urlParamCategory={params}/>
            })
        }
        if(workshopCount > 0) {
            components.push({
                name: t('screens:Schedule:book-workshop', {}),
                component: <WorkshopPage tabsDisplayed={showTabs}/>
            })
        }
        return components;
    }

    return (
        <>
            {pageComponent}
        </>
    );
};